import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Health_KMC_Dispensaries  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
<img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass">  </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
      <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_services_Health_Home'> {t("Health Home")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('458')}> {t("Dengue - Status-Appeal to Citizens")} </a>
                 </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('459')}> {t("Immunisation centers")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('460')}> {t("Health Units")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Malaria_CLinics'> {t("Malaria Clinics")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Maternity_Homes'> {t("Maternity Homes")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Health_KMC_Dispensaries'> {t("KMC Dispensaries")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Chest_Clinincs'> {t("KMC Chest Clinics")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WaterTestDuplicateReceipt.jsp'> {t("Water Test Reprint e-Receipt")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Desease_control'> {t("Desease Control")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Fees_Charges'> {t("Fees & Charges")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_MTMTB_Hospital'> {t("MTMTB Hospital")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Slaughter_House'> {t("Slaughter House")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Ambulances'> {t("Ambulance")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Antirabies_VACCINATION_Center'> {t("Antirabies Vaccination Centre")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Designation_Activities'>{t("Designation Activity")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_KMDA_Health_Projects'> {t("KMDA Health Projects")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BurningGhatStatus.jsp'> {t("Burning Ghat Status")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Land_Records_Burial_Grounds'>{t("Land Records Of Burial Grounds")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Dept'> {t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('461')}> {t("Information on FSS Act-License / Registration in KMC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('462')}> {t("Locations of Slaughtering of Animals on the Occasion of ID-UD-ZOHA(BAKRI-ID) ON 10.07.2022, 11.07.2022 & 12.07.2022")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('469')}> {t("Swasthya Bandhab Sharad Samman")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('470')}> {t("Details of UPHC")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('466')}> {t("List of Enlisted Agencies for Carrying Covid Bodies")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('471')}> {t("Immersion training by ECHO India for KMC Health staff")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('472')}> {t("KMC Participation and Experiences at ECHO India's IPC Dissemination Event : Mumbai")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'>
                    {t("Application/Payment for Potable Water Analysis")}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("KMC Dispensariess")} </h3>
              </div>
              <div className="health-dept-table table2">
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"
                    >
                      <td width="10%" className="text-white tble-brder-left">
                        {t("Sl.No")}
                      </td>
                      <td width="27%" className="text-white">
                        {t("Name")}
                      </td>
                      <td width="43%" className="text-white">
                        {t("Address")}
                      </td>
                      <td width="12%" className="text-white">
                        {t("Ward")}
                      </td>
                      <td width="10%" className="text-white tble-brder-right">
                        {t("Borough")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="5%" align="center">
                        1
                      </td>
                      <td width="27%">{t("Chitpur Dispensary")}</td>
                      <td width="43%">{t("3, Gopal Mukherjee Road, Kol-2")}</td>
                      <td width="12%" align="center">
                        6
                      </td>
                      <td width="13%" align="center">
                        I
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="5%" align="center">
                        2
                      </td>
                      <td width="27%">{t("Tallah Pumping Station Dispensary")}</td>
                      <td width="43%">{t("71, B.T. Road")}</td>
                      <td width="12%" align="center">
                        5
                      </td>
                      <td width="13%" align="center">
                        I
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="5%" align="center">
                        3
                      </td>
                      <td width="27%">{t("Hatibagan Dispensary")}</td>
                      <td width="43%">{t("72/1, Grey St., Kol-6")}</td>
                      <td width="12%" align="center">
                        11
                      </td>
                      <td width="13%" align="center">
                        II
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">4</td>
                      <td>{t("Narkeldanga Dispensary")}</td>
                      <td>{t("109 Narkeldanga Dispensary")}</td>
                      <td align="center">30</td>
                      <td align="center">III</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">5</td>
                      <td>{t("Ultadanga Dispensary")}</td>
                      <td>{t("123, Ultadanga Main Road, Kol-14")}</td>
                      <td align="center">14</td>
                      <td align="center">III</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">6</td>
                      <td>{t("Rajabazar Dispensary")} </td>
                      <td>{t("40, Canal West Road")} </td>
                      <td align="center">29</td>
                      <td align="center">III</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">7</td>
                      <td>{t("Baithakkhana Dispensary")}</td>
                      <td>{t("92 Baithakkhana Road, Kol")}</td>
                      <td align="center">37</td>
                      <td align="center">V</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">8</td>
                      <td>{t("Nafar Koley Dispensary")}</td>
                      <td>7, Nafar Koley Road, Kol</td>
                      <td align="center">36</td>
                      <td align="center">V</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">9</td>
                      <td>{t("Hazi Md Mohasin Sq Dispensary")}</td>
                      <td>{t("21, Road, Hazi Md Mohasin Sq, Kol")}</td>
                      <td align="center">61</td>
                      <td align="center">VI</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">10</td>
                      <td>{t("E.W.S. Dispensary")}</td>
                      <td>{t("149, A.J.C. Bose Road, Kol")}</td>
                      <td align="center">55</td>
                      <td align="center">VI</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">11</td>
                      <td>{t("Taltala Dispensary")}</td>
                      <td>{t("3, Girish Bose Road")}</td>
                      <td align="center">&nbsp;</td>
                      <td align="center">VI</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">12</td>
                      <td>{t("Central Dispensary")}</td>
                      <td>{t("KMC Main Dispensary, 5, S.N. Banerjee Road")}</td>
                      <td align="center">46</td>
                      <td align="center">VI</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">13</td>
                      <td>{t("Dhapa Kachari Bari Dispensary")}</td>
                      <td>{t("Dhapa Road, Kol")}</td>
                      <td align="center">58</td>
                      <td align="center">VII</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">14</td>
                      <td>{t("Gobra Dispensary")}</td>
                      <td>{t("59 Christopher Road, Kol")}</td>
                      <td align="center">58</td>
                      <td align="center">VII</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">15</td>
                      <td>{t("Tangra Dispensary")}</td>
                      <td>{t("48K, D.C. Dey Road, Kol")}</td>
                      <td align="center">57</td>
                      <td align="center">VII</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">16</td>
                      <td>{t("New Tangra Dispensary")}</td>
                      <td>{t("12, Gobinda Khatick Road, Kol")}</td>
                      <td align="center">58</td>
                      <td align="center">VII</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">17</td>
                      <td>{t("Silver Jubilee Dispensary")}</td>
                      <td>{t("117, B.B.Chatterjee Road, Kol-42")}</td>
                      <td align="center">67</td>
                      <td align="center">VII</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">18</td>
                      <td>{t("Bhowanipore Dispensary")}</td>
                      <td>{t("26,D.G.Road, Kol-29")}</td>
                      <td align="center">71</td>
                      <td align="center">VII</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">19</td>
                      <td>{t("Tiljala Dispensary")}</td>
                      <td>{t("9B, Tiljala Shibtala Lane, Kol")}</td>
                      <td align="center">65</td>
                      <td align="center">VII</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">20</td>
                      <td>{t("Kalighat Dispensary")}</td>
                      <td>{t("240, Kalighat Road, Kol-26")}</td>
                      <td align="center">83</td>
                      <td align="center">IX</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">21</td>
                      <td>{t("Chetla Dispensary")}</td>
                      <td>{t("29/1, Chetla Central Road, Kol")}</td>
                      <td align="center">82</td>
                      <td align="center">IX</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">22</td>
                      <td>{t("Khidirpore Dispensary")}</td>
                      <td>{t("15, Dent Mission Road, Kol-23")}</td>
                      <td align="center">77</td>
                      <td align="center">IX</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">23</td>
                      <td>{t("Garden Reach Dispensary")}</td>
                      <td>{t("P-3, Prince Dilcour Jan Lane")}</td>
                      <td align="center">134</td>
                      <td align="center">XV</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Health_KMC_Dispensaries