import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const KMCCrematorium  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
     <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      
     <div className="container">        
        <div className="section-main-heading2">         
          <h4 className="fontSizeClass"> </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        
        <div className="col-lg-12">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Name of the Burning Ghat")} </h3>
              </div>
              <div className="health-dept-table">
                <table className="table table-bordered officials m-ta-2">
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"                    >
                      <td width="7%" className="text-white tble-brder-left">
                      {t("Sl. No")}		
                      </td>
                      <td width="33%" className="text-white">
                      {t("Name")}	
                      </td>
                      <td width="12%" className="text-white tble-brder-right">
                      {t("Borough")}		
                      </td>
                      <td width="12%" className="text-white tble-brder-right">
                      {t("Address")}	
                      </td>
                      <td width="12%" className="text-white tble-brder-right">
                      {t("Phone No")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="10%" align="center">
                      1
                      </td>
                      <td width="10%" align="center">
                      Shanagar Buring Ghat	
                      </td>
                      <td width="10%" align="center">
                      IX	
                      </td>
                      <td width="60%" align="center">
                      113,Tollygunge Road,kolkata-700026			
                      </td>
                      <td width="10%" align="center">
                      24666602	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="10%" align="center">
                      2
                      </td>
                      <td width="10%" align="center">
                      Nimtola Burning Ghat		
                      </td>
                      <td width="10%" align="center">
                      II	
                      </td>
                      <td width="60%" align="center">
                      2p,Strand Bank Road,kolkata-6				
                      </td>
                      <td width="10%" align="center">
                      22691955	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="10%" align="center">
                      3
                      </td>
                      <td width="10%" align="center">
                      Sri Sri Ramkrishna mahasmasan Burning Ghat		
                      </td>
                      <td width="10%" align="center">
                      I	
                      </td>
                      <td width="60%" align="center">
                      5-Chandra Kumar Roy lane,kolkata - 700036				
                      </td>
                      <td width="10%" align="center">
                      25583484	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="10%" align="center">
                      4
                      </td>
                      <td width="10%" align="center">
                      Kashimitra Burning Ghat		
                      </td>
                      <td width="10%" align="center">
                      I	
                      </td>
                      <td width="60%" align="center">
                      1-Stand Bank road,kolkata-700006				
                      </td>
                      <td width="10%" align="center">
                      25542967	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="10%" align="center">
                      5
                      </td>
                      <td width="10%" align="center">
                      Garia Adi Mahasansan Burning Ghat		
                      </td>
                      <td width="10%" align="center">
                      X	
                      </td>
                      <td width="60%" align="center">
                      138, Boral Main Road, kolkata - 84				
                      </td>
                      <td width="10%" align="center">
                      24357317
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="10%" align="center">
                      6
                      </td>
                      <td width="10%" align="center">
                      Sirity Burning Ghat		
                      </td>
                      <td width="10%" align="center">
                      XII	
                      </td>
                      <td width="60%" align="center">
                      B.L.Saha Road, kolkata - 41				
                      </td>
                      <td width="10%" align="center">
                      24032013
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="10%" align="center">
                      7
                      </td>
                      <td width="10%" align="center">
                      Birjunala Burning Ghat		
                      </td>
                      <td width="10%" align="center">
                      XV	
                      </td>
                      <td width="60%" align="center">
                      Z-20,Lenin Road,kolkata - 44				
                      </td>
                      <td width="10%" align="center">
                      24890156
	
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Name of the Hindu Burial Ground")} </h3>
              </div>
              <div className="health-dept-table " style={{width:"100%"}}>
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"                    >
                      <td width="10%" className="text-white tble-brder-left">
                      {t("Sl. No")}		
                      </td>
                      <td width="25%" className="text-white">
                      {t("Name")}	
                      </td>
                      <td width="20%" className="text-white tble-brder-right">
                      {t("Borough")}		
                      </td>
                      <td width="30%" className="text-white tble-brder-right">
                      {t("Address")}	
                      </td>
                      <td width="10%" className="text-white tble-brder-right">
                      {t("Phone No")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="10%" align="center">
                      1
                      </td>
                      <td width="10%" align="center">
                      Bhatchala Hindu Burial Ground		
                      </td>
                      <td width="10%" align="center">
                      Area: 49 Bigha Borough IX		
                      </td>
                      <td width="60%" align="center">
                      Budge Budge Trank Road, Kolkata - 700 088				
                      </td>
                      <td width="10%" align="center">
                      9433081669	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="10%" align="center">
                      2
                      </td>
                      <td width="10%" align="center">
                      Muraripukur Hindu Burial Ground			
                      </td>
                      <td width="10%" align="center">
                      Area: 10 Bigha Borough III		
                      </td>
                      <td width="60%" align="center">
                      2,Romakanta Sen Lane,kolkata - 700067					
                      </td>
                      <td width="10%" align="center">
                      23562100	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="10%" align="center">
                      3
                      </td>
                      <td width="10%" align="center">
                      Topsia Hindu Burial Ground			
                      </td>
                      <td width="10%" align="center">
                      Area:15 Bighas Borough VIII		
                      </td>
                      <td width="60%" align="center">
                      1-Topsia Road,(South),kolkata - 700046					
                      </td>
                      <td width="10%" align="center">
                      23434312	
                      </td>
                    </tr>
                   
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Name of the Muslim Burial Ground")} </h3>
              </div>
              <div className="health-dept-table" style={{width:"100%"}}>
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"                    >
                      <td width="7%" className="text-white tble-brder-left">
                      {t("Sl. No")}		
                      </td>
                      <td width="33%" className="text-white">
                      {t("Name")}	
                      </td>
                      <td width="12%" className="text-white tble-brder-right">
                      {t("Borough")}		
                      </td>
                      <td width="12%" className="text-white tble-brder-right">
                      {t("Address")}	
                      </td>
                      <td width="12%" className="text-white tble-brder-right">
                      {t("Phone No")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="10%" align="center">
                      1
                      </td>
                      <td width="10%" align="center">
                      Bagmari Muslim Burial Ground			
                      </td>
                      <td width="5%" align="center">
                      -		
                      </td>
                      <td width="65%" align="center">
                      52, Bagmari Road. Kolkata-700 054					
                      </td>
                      <td width="10%" align="center">
                      23216867	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="10%" align="center">
                      2
                      </td>
                      <td width="10%" align="center">
                      Gobra Muslim Burial Ground				
                      </td>
                      <td width="5%" align="center">
                      -		
                      </td>
                      <td width="65%" align="center">
                      20, Mohendra Roy Lane. Kolkata- 700 046	                      </td>
                      <td width="10%" align="center">
                      23280290	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="10%" align="center">
                      3
                      </td>
                      <td width="10%" align="center">
                      Soloana Muslim Burial Ground				
                      </td>
                      <td width="5%" align="center">
                      -		
                      </td>
                      <td width="65%" align="center">
                      70/E/1, Ekbalpur Road. Kolkata- 700 023						
                      </td>
                      <td width="10%" align="center">
                      24494990	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="10%" align="center">
                      4
                      </td>
                      <td width="10%" align="center">
                      Garden Reach Muslim Burial Ground				
                      </td>
                      <td width="5%" align="center">
                      -		
                      </td>
                      <td width="65%" align="center">
                      Q 608,Slaughter House Road.Garden Reach, Kolkata - 700024					
                      </td>
                      <td width="10%" align="center">
                      24690157	
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Name of the Christian Burial Ground")} </h3>
              </div>
              <div className="health-dept-table " style={{width:"100%"}}>
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"                    >
                      <td width="7%" className="text-white tble-brder-left">
                      {t("Sl. No")}		
                      </td>
                      <td width="33%" className="text-white">
                      {t("Name")}	
                      </td>
                      <td width="12%" className="text-white tble-brder-right">
                      {t("Borough")}		
                      </td>
                      <td width="12%" className="text-white tble-brder-right">
                      {t("Address")}	
                      </td>
                      <td width="12%" className="text-white tble-brder-right">
                      {t("Phone No")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="10%" align="center">
                      1
                      </td>
                      <td width="10%" align="center">
                      Christian Burial Ground				
                      </td>
                      <td width="5%" align="center">
                      -		
                      </td>
                      <td width="70%" align="center">
                      Mullick Bazar, A.J.C Bose Road, Kolkata						
                      </td>
                      <td width="5%" align="center">
                      -	
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>
  )
}

export default KMCCrematorium