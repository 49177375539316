import { React, useState } from 'react'
import data from "./ListData.json"

function List(props) {
    const [clickInput, setClickInput] = useState("");

    const filteredData = data.filter((el) => {
        if (props.input === '') {
            return el;
        }
        else {
            return el.text.includes(props.input)
        }
    })

    return (
        <ul>
            {filteredData.map((item) => (
                <a href={item.text}><li value={item.text} key={item.id}>{item.text}</li></a>
            ))}
        </ul>
    )
}

export default List