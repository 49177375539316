import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Drainage_Drainage_Pumping_station = () => {
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> </h4>
        </div>
      </div>
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Home'>{t("Drainage Home")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageNetworkMap.jsp' target="_blank">
                    {t("Ward wise Drainage Network Map")}   </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Fees_Charges'>
                    {t("Fees & Charges")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Drainage_Drainage_Pumping_station'>
                    {t("Drainage Pumping Station")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/List_of_Sewage_Treatment_Plants'>
                    {t("Sewage Treatment Plants")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageFee.jsp' target="_blank">
                    {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageDemandPrint.jsp' target="_blank">
                    {t("Print Unpaid Bill")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalDrainagePayment.jsp' target="_blank">
                    {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DCDuplicateReceipt.jsp' target="_blank">
                    {t("Reprint e-Receipt")}  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DCDuplicateReceipt.jsp' target="_blank">
                    {t("List of KMC Drainage Activity")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Faqs'> {t("FAQs")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Sewerage_Drainage_Dept'>{t("Contact Details")}</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("List of Drainage Pumping Stations")} </h3>
              </div>
              <div className="mayor-table pump-list-tble">
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"
                    >
                      <td width="10%" className="text-white tble-brder-left">
                        {t("Sl. No")}.
                      </td>
                      <td width="30%" className="text-white">
                        {t("Name of the Pumping Station")}
                      </td>
                      <td width="20%" className="text-white">
                        {t("No of Pumps")}
                      </td>
                      <td width="15%" className="text-white">
                        {t("Telephone No")}.
                      </td>
                      <td width="25%" className="text-white tble-brder-right">
                        {t("Head of the department")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>1.</td>
                      <td align="left">{t("Maniktala Drainage PS")}</td>
                      <td>05</td>
                      <td>2350-1166</td>
                      <td rowSpan={16} valign="center">
                        {t("Soumen Mukherjee")}
                        <br />
                        {t("Ex.Engr.(E)")}
                        <br />
                        {t("Mob : 9836305380")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>2.</td>
                      <td align="left">{t("Belgachia")} </td>
                      <td>02</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>3 a.</td>
                      <td align="left">{t("Beerpara(Old Station)")}</td>
                      <td>02</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>3 b.</td>
                      <td align="left">{t("Beerpara(New Station)")}</td>
                      <td>06</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>4.</td>
                      <td align="left">{t("Ultadanga Siphon")}</td>
                      <td>01</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>5.</td>
                      <td align="left">{t("Maniktala Siphon")}</td>
                      <td>01</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>6.</td>
                      <td align="left">{t("Marcus Square DPS")}</td>
                      <td>02</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>7.</td>
                      <td align="left">{t("PS-I (beside Ramkrishna Mahasasan)")}</td>
                      <td>04</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>8.</td>
                      <td align="left">{t("PS-II (KP Singh Road)")}</td>
                      <td>04</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>9.</td>
                      <td align="left">{t("PS-III (14/4, BT Road)")}</td>
                      <td>04</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>10.</td>
                      <td align="left">{t("Bangur MPS")}</td>
                      <td>05</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>11a.</td>
                      <td align="left">{t("Duttabagan(Old Station)")}</td>
                      <td>06</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>11b.</td>
                      <td align="left">{t("Duttabagan(New Station)")}</td>
                      <td>06</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>12.</td>
                      <td align="left">{t("Cossipore")}</td>
                      <td>03</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>13.</td>
                      <td align="left">{t("R.K Ghosh Road")}</td>
                      <td>03</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>14.</td>
                      <td align="left">{t("Jagannath ghat")}</td>
                      <td>04</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>15.</td>
                      <td align="left">{t("Dhapa Lock Drainage PS")}</td>
                      <td>09</td>
                      <td>2323-5536</td>
                      <td rowSpan={19} valign="center">
                        {t("Biswajit Bala")}
                        <br />
                        {t("Ex. Engineer (M)")}
                        <br />
                        {t("Mob : 9433923082")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>16.</td>
                      <td align="left">{t("Topsia DPS")}</td>
                      <td>07</td>
                      <td>2343-4236</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>17.</td>
                      <td align="left">{t("Ultadanga DPS")}</td>
                      <td>11</td>
                      <td>2359-4621</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>18.</td>
                      <td align="left">{t("Kamdahari MPS")}</td>
                      <td>12</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>19.</td>
                      <td align="left">{t("Ultadanga Underpass PS")}</td>
                      <td>02</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>20.</td>
                      <td align="left">{t("Ultadanga Storm PS")}</td>
                      <td>03</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>21.</td>
                      <td align="left">{t("Patuli")}</td>
                      <td>05</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>22.</td>
                      <td align="left">{t("Maniktala Underpass")}</td>
                      <td>02</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>23.</td>
                      <td align="left">{t("Hatisur")} </td>
                      <td>05</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>24.</td>
                      <td align="left">{t("Topsia Point 'A'")}</td>
                      <td>07</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>25.</td>
                      <td align="left">{t("Chak Garia")}</td>
                      <td>06</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>26.</td>
                      <td align="left">{t("Baghajatin (PS1)")}</td>
                      <td>03</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>27.</td>
                      <td align="left">{t("Jora Bridge (PS2)")}</td>
                      <td>06</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>28.</td>
                      <td align="left">{t("Rajdanga (PS3)")}</td>
                      <td>06</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>29.</td>
                      <td align="left">{t("Nonadanga (PS4)")}</td>
                      <td>06</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>30.</td>
                      <td align="left">{t("Ambedkar Bridge")}</td>
                      <td>08</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>31.</td>
                      <td align="left">{t("Kasba")}</td>
                      <td>05</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>32.</td>
                      <td align="left">{t("EE-1 Minor")}</td>
                      <td>06</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>33.</td>
                      <td align="left">{t("Chowlpatty")}</td>
                      <td>05</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>34.</td>
                      <td align="left">{t("Palmer Bridge Drainage PS")}</td>
                      <td>13</td>
                      <td>
                        2251-2402 <br /> 2251-0053
                      </td>
                      <td rowSpan={14} valign="center">
                        {t("Biswanath Das")}
                        <br />
                        {t("Ex.Engr (M)")}
                        <br />
                        {t("Mob : 9830109712")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>35.</td>
                      <td align="left">{t("Thanthania")}</td>
                      <td>02</td>
                      <td>2354-3748</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>36.</td>
                      <td align="left">{t("LS-3 (Sirity)")}</td>
                      <td>05</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>37.</td>
                      <td align="left">{t("LS-2 (James Long Sarani)")}</td>
                      <td>04</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>38.</td>
                      <td align="left">{t("Chingrighata")}</td>
                      <td>07</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>39.</td>
                      <td align="left">{t("Pagladanga")}</td>
                      <td>08</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>40.</td>
                      <td align="left">{t("Kuliatangra")}</td>
                      <td>06</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>41.</td>
                      <td align="left">{t("Thakery Road")}</td>
                      <td>05</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>42.</td>
                      <td align="left">{t("Sasisekhar")}</td>
                      <td>05</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>43.</td>
                      <td align="left">{t("Hastings")}</td>
                      <td>05</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>44.</td>
                      <td align="left">{t("Charu Avenue")}</td>
                      <td>04</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>45.</td>
                      <td align="left">{t("Tollygunge Golf Club")}</td>
                      <td>04</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>46.</td>
                      <td align="left">{t("Naktala")}</td>
                      <td>03</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>47.</td>
                      <td align="left">{t("Santinagar")}</td>
                      <td>03</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>48a.</td>
                      <td align="left">{t("Ballygunge Drainage PS")}</td>
                      <td>11</td>
                      <td>2344-9142</td>
                      <td rowSpan={12} valign="center">
                        {t("Swapan Pal")}
                        <br />
                        {t("Ex.Engr (E)")}
                        <br /> Mob : 9433191165
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>48b.</td>
                      <td align="left">{t("BDPS Deeper Sump")}</td>
                      <td>08</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>49.</td>
                      <td align="left">{t("Jodhpur Park DPS")}</td>
                      <td>05</td>
                      <td>2483-8457</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>50.</td>
                      <td align="left">{t("Mominpur")}</td>
                      <td>08</td>
                      <td>2449-6630</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>51.</td>
                      <td align="left">{t("Chetla Lock PS")}</td>
                      <td>04</td>
                      <td>2400-6243</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>52.</td>
                      <td align="left">{t("Nimakmahal PS")}</td>
                      <td>02</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>53.</td>
                      <td align="left">{t("Kalighat DPS")}</td>
                      <td>02</td>
                      <td>2439-8857</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>54.</td>
                      <td align="left">{t("Southern Avenue")}</td>
                      <td>04</td>
                      <td>2465-0408</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>55.</td>
                      <td align="left">{t("Gokhel Road")}</td>
                      <td>02</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>56.</td>
                      <td align="left">{t("LS-9 (Near Kantapukur Morgue)")}</td>
                      <td>04</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>57.</td>
                      <td align="left">{t("LS-10 (Near Majherhat Bridge)")}</td>
                      <td>03</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>58.</td>
                      <td align="left">{t("CPT Canal")}</td>
                      <td>06</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>59.</td>
                      <td align="left">{t("Jinjira Bazar")}</td>
                      <td>05</td>
                      <td>-</td>
                      <td rowSpan={11} valign="center">
                        {t("Sanjoy Halder")}
                        <br />
                        {t("Ex.Engr (E)")}
                        <br /> Mob : 9433203667
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>60a.</td>
                      <td align="left">{t("Santoshpur MPS")}</td>
                      <td>05</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>60b.</td>
                      <td align="left">{t("Santoshpur (New)")}</td>
                      <td>06</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>61.</td>
                      <td align="left">{t("Trenching Grround")}</td>
                      <td>03</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>62.</td>
                      <td align="left">{t("Dhankheti Khal")}</td>
                      <td>05</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>63.</td>
                      <td align="left">{t("Sakuntala Park (Node C)")}</td>
                      <td>06</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>64.</td>
                      <td align="left">{t("Behala Flying Club")}</td>
                      <td>08</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>65.</td>
                      <td align="left">{t("Padirhati")}</td>
                      <td>06</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>66.</td>
                      <td align="left">{t("New Monikhal,Akra Phatak")}</td>
                      <td>08</td>
                      <td>-</td>
                      {/*<td rowspan="8" valign="center" >Supriyo Sengupta<BR>Ex.Engr (E)<BR> Mob : 9830311559</td>*/}
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>67.</td>
                      <td align="left">{t("Begore Khal Ps")}</td>
                      <td>09</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>68.</td>
                      <td align="left">{t("Joka Ps")}</td>
                      <td>11</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>69.</td>
                      <td align="left">{t("Kudghat L.S-5")}</td>
                      <td>06</td>
                      <td>-</td>
                      <td rowSpan={8} valign="center">
                        {t("Supriyo Sengupta")}
                        <br />
                        {t("Ex.Engr (E)")}
                        <br /> Mob : 9830311559
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>70.</td>
                      <td align="left">{t("Rathtala L.S-1A")}</td>
                      <td>03</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>71.</td>
                      <td align="left">{t("Usha Gate L.S-1B")}</td>
                      <td>03</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>72.</td>
                      <td align="left">{t("Bashdroni L.S-2A")}</td>
                      <td>03</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>73.</td>
                      <td align="left">{t("Kalibari L.S-2B")}</td>
                      <td>04</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>74.</td>
                      <td align="left">{t("Canal Road LS")}</td>
                      <td>03</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>75.</td>
                      <td align="left">{t("Buroshibtala L.S-1")}</td>
                      <td>04</td>
                      <td>-</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td>76.</td>
                      <td align="left">{t("Keorapukur MPS")}</td>
                      <td>05</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Drainage_Drainage_Pumping_station