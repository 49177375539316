import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Requirements_For_Obtaining_CE  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />      
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
      <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
              <li className="nav-item">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Certificate of Enlistment")} <i class="bi bi-chevron-down"></i> </button>
                    <div id="demo" className="collapse">
                        <a href='https://www.kmcgov.in/KMCPortal/downloads/725_MA_Revised_Enlistment_Trade_Calling.pdf' target="_blank"> {t("Guidline")} </a>
                        <a href='https://www.kmcgov.in/KMCPortal/downloads/TradeLicenceEoDBProcedure.pdf' target="_blank"> {t("Procedure")} </a>               
                    </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Requirements_For_Obtaining_CE'> {t("Requirements for CE")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/List_of_unit_offices'> {t("List of Unit Offices (Licence)")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/TradeLicenseInformation.jsp'> {t("View CE Information")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Application Registration(For New CE)")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Application For Change(For Existing CE)")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Application For Closure(For Existing CE)")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Renewal Application Registration(For Existing Defaulter CE)")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Application Search Status(For New CE)")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/LicenseDemand.jsp'> {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/LicenseUnpaidDemandSearchPrint.jsp'> {t("Print Unpaid Demand")}</a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Current_Circular_Notes_Guidelines'>  {t("Current Circular Notes & Guidelines")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Duplicate_Demand_Issuing_Offices'>  {t("Duplicate Demand Issuing Offices")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('655')}> {t("Fees & Charges")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Licence_download_form'> {t("Download Forms")}</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" target="_blank" href='https://www.kmcgov.in/KMCPortal/KMCPortalLicensePaymentAction.do'> {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" target="_blank" href='https://www.kmcgov.in/KMCPortal/jsp/LicenseDuplicateReceipt.jsp'> {t("Reprint e-Receipt cum CE")} </a>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Licence_Dept'>  {t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('656')}> {t("Online Survey for Industry Sector Key Performance Indicators under Vision, Mission Exercise")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('657')}> {t("Policy for use of pet Coke and Furnace Oil as a Fuel in the State of West Bengal")} </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
         <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass"> {t("Requirements For Obtaining CE")} </h3>
            </div>
            <div className="col-lg-12 about-head1">
              <h4 className="fontSizeClass">
                
                {t("Only two documents are required for New CE. (All copies shall be self-attested)")}
              </h4>
              <p className="fontSizeClass">
                
                <strong>(i) {t("Identity proof documents")}:</strong> <br />
               {t(" Any Photo Identity Documents (EPIC/AADHAAR/DRIVING LICENCE/PASSPORT etc.) issued by Government authority as Identity proof as well as proof of residential proof of applicant.")}
              </p>
              <p className="fontSizeClass">
                
                <strong>(ii) {t("Proof of ownership/occupancy")}:</strong> <br />
                {t("Anyone of the following documents as occupancy proof of business address.")}
              </p>
              <p className="fontSizeClass">
                 {t("Property tax bill/property tax receipt/ Deed of Conveyance/Lease deed/Sublease deeds/Leave and license agreement/Rent receipt/Rent Agreement/No objection certificate if it is rent free/Electricity bill/Telephone bill/in respects of the place of business and in the name of applicant.")}
              </p>
              <p className="fontSizeClass">
                
                <strong> {t("Note I")} : </strong> {t("For trades where statutory certificates, licenses, permissions, permits are required to be Obtained by traders, production of such certificates, licenses, permissions, permits are not required Prior to and/or at the time of issuance/renewal of Certificates.")}
              </p>
              <p className="fontSizeClass">
                <strong> {t("Note II")} . </strong> {t("However, such enlistment or renewal thereof shall not absolve such person from any liability to take out any licence under any other law like Fire license/Fire No objection Certificate/Pollution Control Board Certificate/Clinical or Health Certificates/Excise(liquor) Deptt./RBI/SEBI/Customs/Police/Import Export etc. and alike for the time being in force.")}
              </p>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Requirements_For_Obtaining_CE