import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Urgent_Notice  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container " >
        <div className="row">
        <div className="col-lg-3">
        <div className="site-menu ">           
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link" to='/Kmc_school_home'> {t("KMC Schools Home")} </Link> </li>
                <li className="nav-item"><a className="nav-link" target="_blank" href='https://www.kmcgov.in/KMCPortal/jsp/KMCSchoolsList.jsp'> {t("List Of Schools")} </a> </li>
                <li className="nav-item"><Link className="nav-link active1" to='/Urgent_Notice'> {t("Urgent Notice")} </Link> </li>
                <li className="nav-item"><Link className="nav-link" to='/KMC_School_Faq'> {t("FAQs")} </Link> </li>
                <li className="nav-item"><Link className="nav-link" target="_blank" to='/Education_Dept'> {t("Contact Details")} </Link></li>
             </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title"><h3 className="fontSizeClass"> {t("Urgent Notice")} </h3></div>
                  <div className='py-3' style={{background:"#eeeeee", textAlign:"center", width:"100%",borderRadius:"10px"}}>
                  <div className="my-3"><strong>{t("Result of Evaluation of presentations given by bidders : Computer-aided Learning")}</strong></div>
                  <div className="my-3"><strong>{t("Result of Evaluation of Technical Bids : Computer-aided Learning at KMC Primary Schools")}</strong></div>
                  <div className="my-3"><strong>{t("Further Clarifications : Computer Aided Learning")}</strong></div>
                  <div className="my-3"><strong>{t("Response to Queries : Computer Aided Learning")}</strong></div>
                  <div className="my-3"><strong>{t("Invitation of EoI for Computer Aided Learning")}</strong></div>
    
                  </div> 
          </div>
        </div>
      </div>
        </div>
    </div>
  </section>
  
    </>
  )
}

export default Urgent_Notice