import React from 'react';
import { Link } from "react-router-dom";


const Drainage_Sewerage_Treatment_Plants  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> Sewage Treatment Plants </h4>
        </div>
      </div>
    </div>
  </section>
 
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/KMC_Drainage_Activity'>
                    Drainage Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Ward_wise_drainage_map'>
                    Ward wise Drainage Network Map
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Fees_Charges'>
                    Fees Charges
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Drainage_Pumping_station'>
                    Drainage Pumping Station
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Drainage_Sewerage_Treatment_Plants'>
                    
                    Sewerage Treatment Plants
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Check_Demands_Payable'>
                    
                    Check Demands Payable
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Print_Unpaid_Bill'>
                    
                    Print Unpaid Bill
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Make_Online_Payment'>
                    
                    Make Online Payment
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Reprint_E_Receipt'>
                    
                    Reprint e-Receipt
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/KMC_Drainage_Activity'>
                    
                    List of KMC Drainage Activity
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/KMC_Drainage_Activity'>
                    FAQ's
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Sewerage_Drainage_Dept'>
                    
                    Contact Details
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> List of Sewage Treatment Plants</h3>
              </div>
              <div className="mayor-table swrg-tble">
                <table className="table table-bordered officials">
                  <tbody>
                    <tr bgcolor="#302ba0" tabIndex={0}>
                      <td
                        width="10%"
                        rowSpan={2}
                        style={{
                          color: "#fff",
                          borderLeft: "1px solid #302ba0"
                        }}
                        align="center"
                        valign="middle"
                      >
                        Sl. No.
                      </td>
                      <td
                        width="37%"
                        rowSpan={2}
                        align="center"
                        style={{ color: "#fff" }}
                      >
                        Name
                      </td>
                      <td
                        width="23%"
                        rowSpan={2}
                        align="center"
                        style={{ color: "#fff" }}
                      >
                        Phone No
                      </td>
                      <td
                        colSpan={2}
                        rowSpan={2}
                        align="center"
                        style={{
                          color: "#fff",
                          borderRight: "1px solid #302ba0"
                        }}
                      >
                        
                        Officer In-Charge
                      </td>
                    </tr>
                    <tr>
                      {/* <td width="20%" style="padding:3">Office</td>
              <td width="13%" style="padding:3">Resi.</td> */}
                    </tr>
                    <tr
                      align="center"
                      valign="middle"
                      bgcolor="#fff"
                      tabIndex={0}
                    >
                      <td>1.</td>
                      <td align="center" valign="middle">
                        Gardenreach STP
                      </td>
                      <td align="center">-</td>
                      <td align="center">
                        Kajal Roy, Ex.Engr.(E)
                        <br />
                        Mob : 9874272283
                      </td>
                    </tr>
                    <tr
                      align="center"
                      valign="middle"
                      bgcolor="#fff"
                      tabIndex={0}
                    >
                      <td>2.</td>
                      <td align="center" valign="middle">
                        Bangur STP
                      </td>
                      <td align="center">-</td>
                      <td align="center">
                        Tapan Sarkar, Ex.Engr.(E)
                        <br />
                        Mob : 8013763737
                      </td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>3.</td>
                      <td align="center" valign="middle">
                        South Suburban (Keorapukur) STP
                      </td>
                      <td align="center">-</td>
                      <td align="center">
                        Supriyo Sengupta, Ex.Engr.(E)
                        <br />
                        Mob : 9830311559
                      </td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>4.</td>
                      <td align="center" valign="middle">
                        Baghajatin STP
                      </td>
                      <td align="center">- </td>
                      <td align="center">
                        Pradip Nandy, Ex.Engr (M)
                        <br />
                        Mob : 9433112634
                      </td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>5.</td>
                      <td align="center" valign="middle">
                        
                        Hatisur STP
                      </td>
                      <td align="center">- </td>
                      <td align="center">
                        Pradip Nandy, Ex.Engr (M)
                        <br />
                        Mob : 9433112634
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Drainage_Sewerage_Treatment_Plants