import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Dashboard2 = () => {
    const { t, i18n, ready } = useTranslation();
  return (
    <>
        <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>    
        <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
        </div>
        </section>
        <section className="section inner-pad1">
            <div className="container-fluid">
                <h5>{t("EODB DYNAMIC DASHBOARD")}</h5>
                <div className="row">
                <div className="col-lg-12 pb-0 pt-0">
                    <div className="desh-bo-2">
                    <div id="accordion">
                        <div className="row">
                        <div className="card col-lg-2">
                            <div className="card-header" id="heading2">
                            <h5 className="mb-0">
                                <button
                                className="btn btn-link collapsed active"
                                data-toggle="collapse"
                                data-target="#collapse2"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                                >
                                {t("Apply")} <i className="bi bi-chevron-right" />
                                </button>
                            </h5>
                            </div>
                            <div
                            id="collapse2"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                            >
                            <div className="card-body m-3">
                                {t("Apply through Single Window System with user Id")}
                            </div>
                            </div>
                        </div>
                        <div className="card col-lg-2">
                            <div className="card-header" id="heading3">
                            <h5 className="mb-0">
                                <button
                                className="btn btn-link collapsed"
                                data-toggle="collapse"
                                data-target="#collapse3"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                                >
                                {t("Joint Site Inspection")}
                                <i className="bi bi-chevron-right" />
                                </button>
                            </h5>
                            </div>
                            <div
                            id="collapse3"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                            >
                            <div className="card-body m-3">
                                {t("KMC and All relevant External Agencies will inspect on a single date and upload JSI report within stipulated time")}
                            </div>
                            </div>
                        </div>
                        <div className="card col-lg-2">
                            <div className="card-header" id="heading4">
                            <h5 className="mb-0">
                                <button
                                className="btn btn-link collapsed"
                                data-toggle="collapse"
                                data-target="#collapse4"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                                >
                                {t("NOC")} <i className="bi bi-chevron-right" />
                                </button>
                            </h5>
                            </div>
                            <div
                            id="collapse4"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                            >
                            <div className="card-body m-3">
                                {t("KMC and All relevant External Agencies will upload NOC within stipulated time")}
                            </div>
                            </div>
                        </div>
                        <div className="card col-lg-2">
                            <div className="card-header" id="heading5">
                            <h5 className="mb-0">
                                <button
                                className="btn btn-link collapsed"
                                data-toggle="collapse"
                                data-target="#collapse5"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                                >
                                {t("Scrutiny")} <i className="bi bi-chevron-right" />
                                </button>
                            </h5>
                            </div>
                            <div
                            id="collapse5"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                            >
                            <div className="card-body m-3">
                                {t("After receipt of NOC, Plan case to be scrutinized by Building Department within stipulated time")}.
                            </div>
                            </div>
                        </div>
                        <div className="card col-lg-2">
                            <div className="card-header" id="heading6">
                            <h5 className="mb-0">
                                <button
                                className="btn btn-link collapsed"
                                data-toggle="collapse"
                                data-target="#collapse6"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                                >
                                {t("Process")} <i className="bi bi-chevron-right" />
                                </button>
                            </h5>
                            </div>
                            <div
                            id="collapse6"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                            >
                            <div className="card-body m-3">
                                {t("Plan case to be processed under KMC Act 1980 & prevailing KMC Building Rules within stipulated time.")}
                            </div>
                            </div>
                        </div>
                        <div className="card col-lg-2">
                            <div className="card-header" id="heading6a">
                            <h5 className="mb-0">
                                <button
                                className="btn btn-link collapsed"
                                data-toggle="collapse"
                                data-target="#collapse6a"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                                >
                                {t("Approval/Rejection")} <i className="bi bi-chevron-right" />
                                </button>
                            </h5>
                            </div>
                            <div
                            id="collapse6a"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                            >
                            <div className="card-body m-3">
                                {t("Plan case to be approved upon submission of fees / rejected by Standard Reasons of Rejection within stipulated time")}.
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-12 pb-0 pt-0">
                    <div className="row">
                    <div className="col-lg-6 pb-0 pt-0 desh-bo-ser">
                        <div className="card ">
                        <div className="card-header" id="heading7">
                            <h5 className="mb-0">
                            <button
                                className="btn btn-link collapsed colr1"
                                data-toggle="collapse"
                                data-target="#collapse7"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                {t("CHECKLIST")} <i className="bi bi-chevron-down" />
                            </button>
                            </h5>
                        </div>
                        <div
                            id="collapse7"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                        >
                            <div className="card-body m-3 des2-list">
                            <ul>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/EODB_Workflow.pdf"
                                    target="_blank4"
                                >
                                    {t("EODB WORK FLOW")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/Proforma_Appointment_Technical_Person.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("PROFORMA FOR APPOINTMENT OF TECHNICAL PERSON")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/PLUMBER_UNDERTAKING.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("PROFORMA FOR APPOINTMENT OF PLUMBER")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/EODB_CAD_Drawing_Final_Instruction.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("INSTRUCTION SET FOR CAD DRAWINGS")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/FORMAT_SINGLE_JOINT_SITE_INSPECTION.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("JOINT SITE INEPECTION FORMAT(CAF1)")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/JOINT_SITE_INSPECTION_CAF2.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("JOINT SITE INEPECTION FORMAT(CAF2)")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/IGR_Non_Insisting_of_KMC_Consent_29022020.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("IGR- NON INSISTING OF KMC CONSENT")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/Road_alignment_survey.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("SURVEY ROAD ALIGNMENT")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/KMDA_Alignment_Drawing.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("KMDA ALIGNMENT DRAWINGS")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/ULCRA.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("LIST OF WARDS AFFECTED BY ULCRA")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/STANDARD_REASONS_OF_REJECTION.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("STANDARD REASONS OF REJECTIONS")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/GMAIL_IDs_OF_BR_EX_ENGINEERs.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("EMAIL IDs OF EXECUTIVE ENGINEERS")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/EMAIL%20ID%20OF%20SURVEY%20DEPARTMENT.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("EMAIL IDs OF SURVEY DEPARTMENT")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/EMAIL%20IDs%20OF%20KMDA.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("EMAIL IDs OF KMDA")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/KIT_Schemes_Alignments.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("KIT SCHEMES AND ALIGNMENTS")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/OC_15_16_DG_15_03032016.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("AIRPORT UNDERTAKING FORMAT")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/WEST_BENGAL_RTPS_ACT_2013.jpeg"
                                    target="_blank"
                                >
                                    
                                    {t("WEST BENGAL RTPS ACT 2013")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/User_Manual.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("USER MANUAL")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/User_Manual.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("FAQ")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/EODB_CAF_PROCEDURE_05_01_2021.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("HOW TO APPLY THROUGH CAF")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/E_Undertaking_07012021.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("E-UNDERTAKING")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/EODB_PLINTH_CAF_PROCEDURE_14_01_2022.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("HOW TO APPLY THROUGH PLINTH LEVEL CAF")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/E_Undertaking_Plinth_14_01_2022.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("FORMAT FOR PLINTH LEVEL UNDERTAKING")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/Soil%20Test%20Undertaking.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("UNDERTAKING FOR MOSTLY COVERED PLOT UNDERTAKING FOR COMPLIANCE OF")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/Pcb_Compliance_Undertaking_Waste_Water.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("UNDERTAKING FOR COMPLIANCE OF GUIDELINES OF PCB, GoWB FOR WASTE WATER GENERATION OR STP")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/APPLY_THROUGH_CAF2_CC_OC.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("HOW TO APPLY THROUGH CAF2 (FOR CC & OC)")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/UNDERTAKING_KMC_DRAINAGE_WS.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("UNDERTAKING FOR DRAINAGE (NON-SEWER AREA) AND/OR WATER SUPPLY (ALTERNATE SOURCE REQUIRED)")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/UNDERTAKING_KMC_DRAINAGE_STP.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("UNDERTAKING FOR DRAINAGE FOR BUILT-UP AREA - 5000M")}
                                </Link>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading8">
                            <h5 className="mb-0">
                            <button
                                className="btn btn-link collapsed colr3"
                                data-toggle="collapse"
                                data-target="#collapse8"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                {t("ONLINE BUILDING PERMIT")} <i className="bi bi-chevron-down" />
                            </button>
                            </h5>
                        </div>
                        <div
                            id="collapse8"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                        >
                            <div className="card-body m-3 des2-list">
                            <ul>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/BuildingPlanSubmission.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("COMMON APPLICATION FORM (CAF) FOR BUILDING PERMIT U/S 393A")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/BuildingPlanSubmissionStatus.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("BUILDING PLAN DRAWING RE- UPLOAD")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/PlancaseSortfallDoc.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("BUILDING PLAN SHORTFALL DOCUMENT UPLOAD(BUILDING DEPT.)")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/BuildingCafOutSideAgencySortfallDoc.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("BUILDING PLAN SHORTFALL DOCUMENT UPLOAD(OTHER DEPT.)")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/BuildingCafOutSideAgencySortfallView.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("LIST OF SHORTFALL DOCUMENT REQUIRED(OTHER DEPT.)")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/BuildingStrucPlanStatus.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("BUILDING STRUCTURAL DESIGN RE-UPLOAD")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/JSIReport.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("JOINT SITE INSPECTION REPORT")}
                                </Link>
                                </li>
                                <li>
                                <Link to="about:blank#blocked">
                                    
                                    {t("BUILDING SANCTION DOWNLOAD FILES")}
                                </Link>
                                </li>
                                <li>
                                <Link to='/'> {t("VIEW NOC FOR BUILDING PERMIT")}</Link>
                                </li>
                                <ul className="des2-list-2">
                                <li>
                                    <Link to='/'> {t("KMDA/ KIT")} </Link>
                                </li>
                                <li>
                                    <Link to='/'> {t("WBF&ES : FSR Drawing")} </Link>
                                </li>
                                <li>
                                    <Link to='/'> {t("WBF&ES : FSR Document")} </Link>
                                </li>
                                <li>
                                    <Link to='/'> {t("NMA")} </Link>
                                </li>
                                <li>
                                    <Link to='/'> {t("AAI")} </Link>
                                </li>
                                <li>
                                    <Link to='/'> {t("WBPCB")} </Link>
                                </li>
                                <li>
                                    <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/WaterSupplySanctionOrderForCAF.jsp"
                                    target="_blank"
                                    >
                                    
                                    {t("WATER SUPPLY SANCTION ORDER (Form II)")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/'> {t("ENGINEERING/ DRAINAGE, KMC")} </Link>
                                </li>
                                <li>
                                    <Link to='/'> {t("SURVEY OBSERVATION REPORT, KMC")} </Link>
                                </li>
                                <li>
                                    <Link to='/'>
                                    
                                    {t("ULC APPLICATION STATUS & NOC DOWNLOAD")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/'> {t("MBC RESOLUTION, KMC")} </Link>
                                </li>
                                </ul>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading9">
                            <h5 className="mb-0">
                            <button
                                className="btn btn-link collapsed colr4"
                                data-toggle="collapse"
                                data-target="#collapse9"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                {t("CITIZEN SEARCH")} <i className="bi bi-chevron-down" />
                            </button>
                            </h5>
                        </div>
                        <div
                            id="collapse9"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                        >
                            <div className="card-body m-3 des2-list">
                            <ul>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/PlanProposalStatus.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("SANCTION STATUS BY PLANCASE NO")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/PlancaseInformationSearch.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("SANCTION STATUS BY ASSESSEE NO OR WARD,STREET,PREMISE NO")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/ULCApplicationStatus.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("ULC APPLICATION STATUS")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/BuildingEODBPlanCaseSearch.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("EODB SANCTION LIST")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/KMCFeesCalculationAction.do"
                                    target="_blank"
                                >
                                    
                                    {t("ONLINE FEE CALCULATOR")}
                                </Link>
                                </li>
                                <li>
                                <Link to='/'> {t("CONSOLIDATED DEMAND FOR BUILDING PERMIT")} </Link>
                                </li>
                                <ul className="des2-list-2">
                                <li>
                                    <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/CafNocPaymentHome.jsp"
                                    target="_blank"
                                    >
                                    
                                    {t("EXTERNAL AGENCIES")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/'> {t("KMC DEPARTMENTS")} </Link>
                                </li>
                                </ul>
                                <ul className="des2-list-3">
                                <li>
                                    <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/BuildingEodbDemandPrint.jsp"
                                    target="_blank"
                                    >
                                    
                                    {t("DEMAND PRINT")}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingPayment.jsp"
                                    target="_blank"
                                    >
                                    
                                    {t("MAKE PAYMENT")}
                                    </Link>
                                </li>
                                </ul>
                                <li>
                                <Link to='/'> {t("RECEIPT OF PAYMENT FOR BUILDING PERMIT")} </Link>
                                </li>
                                <ul className="des2-list-2">
                                <li>
                                    <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/CafDuplicateReceipt.jsp"
                                    target="_blank"
                                    >
                                    
                                    {t("EXTERNAL AGENCIES")}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingReceiptReprint.jsp"
                                    target="_blank"
                                    >
                                    
                                    {t("KMC DEPARTMENTS")}
                                    </Link>
                                </li>
                                </ul>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingEODBCAF2SanctionStatus.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("CC APPLICATION STATUS BY CC CASE NUMBER")}
                                </Link>
                                </li>
                                <li>
                                <Link to='/'> {t("CONSOLIDATED DEMAND FOR CC & OC")} </Link>
                                </li>
                                <ul className="des2-list-2">
                                <li>
                                    <Link to='/'> {t("EXTERNAL AGENCIES")} </Link>
                                </li>
                                <li>
                                    <Link to='/'> {t("KMC DEPARTMENTS")} </Link>
                                </li>
                                </ul>
                                <ul className="des2-list-3">
                                <li>
                                    <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/BuildingDemandPrint.jsp"
                                    target="_blank"
                                    >
                                    
                                    {t("DEMAND PRINT")}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingPayment.jsp"
                                    target="_blank"
                                    >
                                    
                                    {t("MAKE PAYMENT")}
                                    </Link>
                                </li>
                                </ul>
                                <li>
                                <Link to='/'>
                                    
                                    {t("RECEIPT OF PAYMENT FOR CC& OC (UNDER DEVELOPMENT)")}
                                </Link>
                                </li>
                                <ul className="des2-list-2">
                                <li>
                                    <Link to='/'> {t("EXTERNAL AGENCIES")} </Link>
                                </li>
                                <li>
                                    <Link to='/'> {t("KMC DEPARTMENTS")} </Link>
                                </li>
                                </ul>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading14">
                            <h5 className="mb-0">
                            <button
                                className="btn btn-link collapsed colr5"
                                data-toggle="collapse"
                                data-target="#collapse14"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                {t("ONLINE PLINTH LEVEL INTIMATION")}
                                <i className="bi bi-chevron-down" />
                            </button>
                            </h5>
                        </div>
                        <div
                            id="collapse14"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                        >
                            <div className="card-body m-3 des2-list">
                            <ul>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/BuildingPlinthCmplDeclaration.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("COMMON APPLICATION FORM (CAF) FOR PLINTH LEVEL PERMISSION")}
                                </Link>
                                </li>
                                <li>
                                <Link to='/'> {t("PLINTH LEVEL INSPECTION REMARKS")} </Link>
                                </li>
                                <li>
                                <Link to='/'> {t("VIEW NOC")} </Link>
                                </li>
                            </ul>
                            <ul className="des2-list-2">
                                <li>
                                <Link to='/'> {t("WBPCB")} </Link>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading15">
                            <h5 className="mb-0">
                            <button
                                className="btn btn-link collapsed colr6"
                                data-toggle="collapse"
                                data-target="#collapse15"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                {t("REFORMS")} <i className="bi bi-chevron-down" />
                            </button>
                            </h5>
                        </div>
                        <div
                            id="collapse15"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                        >
                            <div className="card-body m-3 des2-list">
                            <ul>
                                <li>
                                <Link to='/'> {t("EODB Circulars")} </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/RTPSA_BLDG.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("RTPSA")}
                                </Link>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6 pb-0 pt-0 desh-bo-ser">
                        <div className="card">
                        <div className="card-header" id="heading10">
                            <h5 className="mb-0">
                            <button
                                className="btn btn-link collapsed colr2"
                                data-toggle="collapse"
                                data-target="#collapse10"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                {t("RELATED LINKS")} <i className="bi bi-chevron-down" />
                            </button>
                            </h5>
                        </div>
                        <div
                            id="collapse10"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                        >
                            <div className="card-body m-3 des2-list">
                            <ul>
                                <li>
                                <Link
                                    to="https://nocas2.aai.aero/nocas/CCZMPage.html"
                                    target="_blank"
                                >
                                    
                                    {t("CCZM OF AAI")}
                                </Link>
                                </li>
                                <li>
                                <Link to="https://igbc.in/igbc/" target="_blank">
                                    
                                    {t("IGBC")}
                                </Link>
                                </li>
                                <li>
                                <Link to="http://kmdaonline.org/" target="_blank">
                                    
                                    {t("KMDA/ KIT")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.wb.gov.in/404-page.aspx?aspxerrorpath=/departments-fire-and-emergency-services.aspx"
                                    target="_blank"
                                >
                                    
                                    {t("WBF&ES")}
                                </Link>
                                </li>
                                <li>
                                <Link to="http://www.nma.gov.in/" target="_blank">
                                    
                                    {t("NMA")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://wbpower.gov.in/directorate-officersstiregulatory-authorities/"
                                    target="_blank"
                                >
                                    
                                    {t("DIRECTORATE OF ELECTRICITY")}
                                </Link>
                                </li>
                                <li>
                                <Link to="http://www.wbpcb.gov.in/" target="_blank">
                                    
                                    {t("WBPCB")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://wbregistration.gov.in/(S(k2d1j24tycgxxuykzoxaiego))/index.aspx"
                                    target="_blank"
                                >
                                    
                                    {t("DIRECTORATE OF REGISTRATION & STAMP REVENUE")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.wburbanservices.gov.in/"
                                    target="_blank"
                                >
                                    
                                    {t("UD&MA")}
                                </Link>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading11">
                            <h5 className="mb-0">
                            <button
                                className="btn btn-link collapsed colr7"
                                data-toggle="collapse"
                                data-target="#collapse11"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                {t("ONLINE CC & OC")} <i className="bi bi-chevron-down" />
                            </button>
                            </h5>
                        </div>
                        <div
                            id="collapse11"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                        >
                            <div className="card-body m-3 des2-list">
                            <ul>
                                <li>
                                <Link to='/'>
                                    
                                    {t("COMMON APPLICATION FORM (CAF) FOR CC& OC")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingEODBCAF2ShortfallDetails.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("LIST OF SHORTFALL DOCUMENT(S) REQUIRED")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingEODBCAF2ShortfallUpload.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("BUILDING PLAN SHORTFALL DOCUMENT(S) UPLOAD")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingEODBCAF2DrawingReupload.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("CAD DRAWING RE- UPLOAD")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingEODBCAF2JSIDetails.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("JOINT SITE INSPECTION DETAILS")}
                                </Link>
                                </li>
                                <li>
                                <Link to='/'> {t("VIEW NOC FOR CC&OC")} </Link>
                                </li>
                                <li>
                                <Link to='/'> {t("WBF&ES")} </Link>
                                </li>
                                <li>
                                <Link to='/'> {t("FORM C (DIRECTORATE OF ELECTRICITY)")} </Link>
                                </li>
                                <li>
                                <Link to='/'> {t("WBPCB")} </Link>
                                </li>
                                <li>
                                <Link to='/'> {t("WATER SUPPLY/ KMC")} </Link>
                                </li>
                                <li>
                                <Link to='/'> {t("ENGINEERING/ DRIANAGE, KMC")} </Link>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading12">
                            <h5 className="mb-0">
                            <button
                                className="btn btn-link collapsed colr8"
                                data-toggle="collapse"
                                data-target="#collapse12"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                {t("MISCELLENEOUS")} <i className="bi bi-chevron-down" />
                            </button>
                            </h5>
                        </div>
                        <div
                            id="collapse12"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                        >
                            <div className="card-body m-3 des2-list">
                            <ul>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/MC_Circular_12_2021_2022.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("Construction of Buiilding Permit (As per Minicipal Commissioner's Circilar No : 12 of 2021-2022) [Empowerment of Technical Professionals for according sanction of Low Risk Buildings under EoDB Costruction Permit]")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/downloads/EODB_CAF_PROCEDURE_17_09_2022.pdf"
                                    target="_blank"
                                >
                                    
                                    {t("HOW TO APPLY THROUGH CAF VIDE MCS CIR. NO. 12")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/BuildingPlanSubmissionSpl.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("APPLICATION FOR CONSTRUCTION OF BUILDING PERMIT")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/DrawingDataView.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("VIEW DRAWING DATA")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/BlockWiseReport.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("BLOCKWISE SUMMARY REPORT")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/BuildingInfringementProcess.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("INFRINGEMENT PROCESS")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/BuildingDemandScreen.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("DEMAND GENERATION")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingPayment.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("PAYMENT")}
                                </Link>
                                </li>
                                <li>
                                <Link
                                    to="https://www.kmcgov.in/KMCPortal/jsp/BuildingSanctionProcess.jsp"
                                    target="_blank"
                                >
                                    
                                    {t("SANCTION")}
                                </Link>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading13">
                            <h5 className="mb-0">
                            <button
                                className="btn btn-link collapsed colr9"
                                data-toggle="collapse"
                                data-target="#collapse13"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                {t("ALERT NOTICE")} <i className="bi bi-chevron-down" />
                            </button>
                            </h5>
                        </div>
                        <div
                            id="collapse13"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                        >
                            <div className="card-body m-3 des2-list">
                            <ul>
                                <li>
                                {t("The CCZM of Kolkata is not currently available on NOCAS due to revition process and the same will be available shortly as par notificition available in http:// nocas2.aai.aero/nocas/ Therefore for application made through EoDB, if your proposal attracts CCZM, you are required to approach directly to Airport Authority of India to obtain NOC for height clearance from AAI. This mechanism will continue till the issue is resolved")}.
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>

  )
}

export default Dashboard2