import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MunicipalHistory = () => {
  const { t, i18n, ready } = useTranslation();
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
 
      <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> {t("Municipal_history_heading")} </h4>
        </div>
      </div>
    </div>
  </section>

  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  ">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item">
                    <Link className="nav-link active1 fontSizeClass" to='/MunicipalHistory'>                      
                    {t("Menu_item5")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/MayorsOfKolkata'>                      
                     {t("Mayors of Kolkata")}
                    </Link>
                  </li>
                </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass">{t("Municipal_history_heading")}</h3>
              </div>
              <div className="col-lg-12 about-head1">
                <p className="fontSizeClass">
                {t("Municipal_history_text")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 about-head2">
                <p className="fontSizeClass">
                  {t("Municipal_history_text1")}
                </p>
                <p className="fontSizeClass">
                 {t("Municipal_history_text3")}
                </p>
                <p className="fontSizeClass">
                {t("Municipal_history_text4")}
                </p>
                <p className="fontSizeClass">
                {t("Municipal_history_text5")}
                </p>
                <p className="fontSizeClass">
                {t("Municipal_history_text6")}
                </p>
                <p className="fontSizeClass">
                {t("Municipal_history_text7")}
                </p>
                <p className="fontSizeClass">
                {t("Municipal_history_text8")}
                </p>
                <p className="fontSizeClass">
                {t("Municipal_history_text9")}
                </p>
                <p className="fontSizeClass">
                {t("Municipal_history_text10")}
                </p>
                <p className="fontSizeClass">
                {t("Municipal_history_text11")}
                </p>
                <p className="fontSizeClass">
                {t("Municipal_history_text12")}
                </p>
                <p className="fontSizeClass">
                {t("Municipal_history_text13")}
                </p>
                <p className="fontSizeClass">
                {t("Municipal_history_text14")}
                </p>
                <p className="fontSizeClass">
                {t("Municipal_history_text15")}
                </p>
                <p className="fontSizeClass">
                {t("Municipal_history_text16")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default MunicipalHistory