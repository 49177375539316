import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Rti_act_2005_pfd  = () => {
  const { t, i18n, ready } = useTranslation();
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
        
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
        <div className="container">
          
          <div className="section-main-heading2">
            
            <h4 className="fontSizeClass"> </h4>
          </div>
        </div>
      </div>
    </section>
    {/* End Slider */}
    <section className="section inner-pad1">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
              <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/RTI_List_of_State_Public_Information_Officers'>           
                    {t("List of State Public Information Officers")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/RTI_List_of_1st_Appellate_Authorities'>             
                    {t("List of 1st Appellate Authorities")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/RTI_Important_Points_for_the_RTI_Applicants'>                   
                    {t("Important Points for the RTI Applicants")}
                  </Link>
                </li>

                <li className="nav-item">                
                 <a className="nav-link fontSizeClass" target={'_blank'} href="https://www.kmcgov.in/KMCPortal/downloads/RTISPECIMEN_FORM.pdf">
                        {t("Specimen Form of Application Seeking Information")}
                  </a>               
                </li>

                <li className="nav-item">
                  <a className="nav-link fontSizeClass" target={'_blank'} href="https://www.kmcgov.in/KMCPortal/downloads/MC_Circular75_27_03_2018.pdf">
                        {t(" Mpl.Commissioner's Circular No. 75 of 2018 dated 24.03.2018 for mode of deposition of fees under RTI Act,2005 & WBRTI Rules 2006")}
                  </a>                  
                </li>

                <li className="nav-item">
                  <a className="nav-link fontSizeClass" target={'_blank'} href="https://www.kmcgov.in/KMCPortal/downloads/RTI_TR_FORM_7.pdf">
                        {t(" T.R Form No. 7 (Challan for Deposit of money in the account of GOVERNMENT OF WEST BENGAL)")}
                  </a>                  
                </li>              

                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/RTI_Act_2005'>                   
                    {t("Proactive disclosure of organization details as mandated u/s 4 of RTI Act,2005")}
                  </Link>
                </li>

                <li className="nav-item">
                  <a className="nav-link fontSizeClass" target={'_blank'} href="https://www.kmcgov.in/KMCPortal/downloads/WBRTI_RULES_2006.pdf">
                        {t(" Amendments in the WBRTI Rules 2006 ")}
                  </a>                  
                </li> 

                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/RTI_Office_Order_Circular'>                 
                    {t("OFFICE ORDER/ CIRCULAR")}
                  </Link>
                </li>
                
              </ul>
              </nav>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2 bas">
                  <h3 className="fontSizeClass"> {t(" Provident Fund : ")}</h3>
                </div>
                <div className='rtinner'>
                    <li><a href='https://www.kmcgov.in/KMCPortal/downloads/GPF_2_11_07_2013.pdf' target={'_blank'}> General Provident Fund Regulations </a></li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  
  )
}

export default Rti_act_2005_pfd