import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Building_OfficeOreder_2020 = () => {
    const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
    <section id="hero" className="hero hero2">
    <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
          <div className="facebook" rel="ebInfo" title="facebook">
            <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>
      
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
         </div>
    </section>
    {/* End Slider */}
    <section className="section inner-pad1">
      <div className="container " >
        <div className="row">
          <div className="col-lg-3">
          <div className="site-menu  min-height-400">
              <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
                
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Officer_Order'> {t("DG(BLDG)'s Office Orde No.of 2021-22")}</Link>
                </li>
                <li className="nav-item">
                 <Link className="nav-link active1 fontSizeClass" to='/Building_OfficeOreder_2020'> {t("DG(BLDG)'s Office Orde No.of 2020-21")} </Link>
                </li>
                <li className="nav-item">
                 <Link className="nav-link fontSizeClass" to='/Building_OfficeOreder_2019'> {t("DG(BLDG)'s Office Orde No.of 2019-20")}</Link>
                </li>
                <li className="nav-item">
                 <Link className="nav-link fontSizeClass" to='/Building_OfficeOreder_2018'> {t("DG(BLDG)'s Office Orde No.of 2018-19")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_OfficeOreder_2017'> {t("DG(BLDG)'s Office Orde No.of 2017-18")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_OfficeOreder_2016'> {t("DG(BLDG)'s Office Orde No.of 2016-17")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_OfficeOreder_2015'> {t("DG(BLDG)'s Office Orde No.of 2015-16")} </Link>
                </li>
                <li className="nav-item">
                 <Link className="nav-link fontSizeClass" to='/Building_OfficeOreder_2014'> {t("DG(BLDG)'s Office Orde No.of 2014-15")} </Link>
                </li>
                <li className="nav-item">
                 <Link className="nav-link fontSizeClass" to='/-Building_OfficeOreder_2013'> {t("DG(BLDG)'s Office Orde No.of 2013-14")} </Link>
                </li>
                <li className="nav-item">
                 <Link className="nav-link fontSizeClass" to='/Building_OfficeOreder_2012'> {t("DG(BLDG)'s Office Orde No.of 2012-13")} </Link>
                </li>
                <li className="nav-item">
                 <Link className="nav-link fontSizeClass" to='/Building_OfficeOreder_2011'> {t("DG(BLDG)'s Office Orde No.of 2011-12")} </Link>
                </li>
                <li className="nav-item">
                 <Link className="nav-link fontSizeClass" to='/Building_OfficeOreder_2010'> {t("DG(BLDG)'s Office Orde No.of 2010-11")} </Link>
                </li>

              </ul>
              </nav>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2 bas">
                  <h3 className="fontSizeClass"> {t("Office Order/ 2020")}</h3>
                </div>
                <div className="mayor-table health-dept-table">
                  <table
                    width="95%"
                    border={0}
                    align="center"
                    cellPadding={2}
                    cellSpacing={2}
                    className="table table-bordered officials"
                  >
                    <tbody>
                      <tr bgcolor="#302ba0" align="center" valign="middle">
                        <td width="28%" className="text-white tble-brder-left">
                          {t("Office Order No")}
                        </td>
                        <td width="17%" className="text-white">
                          {t("Date")}
                        </td>
                        <td width="55%" className="text-white tble-brder-right">
                          {t("Subject")}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a href='https://www.kmcgov.in/KMCPortal/downloads/Bldg2020_OO_06_07_12_2021.pdf' target={'_blank'}>  {t("DG(BLDG)'s Office Order No. 06 of 2020-2021")}	</a>
                        </td>
                        <td> 25/02/2020	</td>
                        <td>{t("Procedure to issue notice for Section 411 on Dangerous building")}</td>
                      </tr>
                      <tr>
                        <td>
                          <a href='https://www.kmcgov.in/KMCPortal/downloads/Bldg2020_OO_05_07_12_2021.pdf' target={'_blank'}>
                          {t("DG(BLDG)'s Office Order No. 05 of 2020-2021")}	
                          </a>
                        </td>
                        <td> 24/02/2020	</td>
                        <td>{t("Non discloser of complainant name")}</td>
                      </tr>
                      <tr>
                        <td>
                          <a href='https://www.kmcgov.in/KMCPortal/downloads/Bldg2020_OO_04_07_12_2021.pdf' target={'_blank'}> {t("DG(BLDG)'s Office Order No. 04 of 2020-2021")}	</a>
                        </td>
                        <td> 24/02/2020	</td>
                        <td>{t("Non refusals of Summons of any court")}</td>
                      </tr>
                      <tr>
                        <td>
                          <a href='https://www.kmcgov.in/KMCPortal/downloads/Bldg2020_OO_03_07_12_2021.pdf' target={'_blank'}> {t("DG(BLDG)'s Office Order No. 03 of 2020-2021")}	</a>
                        </td>
                        <td> 	04/02/2020	</td>
                        <td>{t("Monthly Report on case pending in MM court but regularized")}</td>
                      </tr>
                      <tr>
                        <td>
                          <a href='https://www.kmcgov.in/KMCPortal/downloads/Bldg2020_OO_02_07_12_2021.pdf' target={'_blank'}> {t("DG(BLDG)'s Office Order No. 02 of 2020-2021")}	</a>
                        </td>
                        <td> 	03/11/2020	</td>
                        <td>{t("List of premises rejected by Competent Authority, land ceiling")}</td>
                      </tr>

                      <tr>
                        <td>
                          <a href='https://www.kmcgov.in/KMCPortal/downloads/Bldg2020_OO_01_07_12_2021.pdf' target={'_blank'}> {t("DG(BLDG)'s Office Order No. 01 of 2020-2021")}	</a>
                        </td>
                        <td> 16/09/2020	</td>
                        <td>{t("How to manage a file")}</td>
                      </tr>

                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
    )
}

export default Building_OfficeOreder_2020