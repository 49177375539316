import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Car_Parking_Rate_Chart  = () => { 
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
    <section id="hero" className="hero hero2">
      <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
      <div className="col-lg-3">
      <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Car_Parking_Home'> {t("Car Parking Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Car_Parking_Rate_Chart'> {t("Rate Chart")}</Link>
                </li>
                <li className="nav-item ">
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Notice")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo" className="collapse">                        
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('228')}>1. {t("Notice 1")} </a>
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('229')}>2. {t("Notice 2")} </a>  
                      </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/CheckListAuthorisedParking.jsp'> {t("List of Parking Lots")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/CarParkingFee.jsp'> {t("Check Demands Payable")} </a>
                  </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/ParkingDemandPrint.jsp'> {t("Print Unpaid Bill")} </a>
                 </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCCarParkingPayment.jsp'> {t("Make Online Payment")} </a>
                  </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/CPDuplicateReceipt.jsp'> {t("Reprint e-Receipt")} </a>
                  </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Car_Parking_FAQ'> {t("FAQs")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('230')}> {t("Taxi Parking in KMC's Free Parking Stretches (Yellow boarded area)")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('231')}> {t("Car Parking Policy")} </a>
                  </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('232')}> {t("Parking Tender Policy")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('233')}> {t("Proposed enhancement of fee car parking space in KMC area")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('234')}> {t("Phalpatty area & rates")} </a>
                  </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('235')}> {t("Corri Notice for correction of Short Car Parking Tender 2018")} </a>
                 </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('236')}> {t("Extension of payment date of demands of May-18")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('237')}> {t("Usage of Mobile apps (Bandhu)")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('238')}> {t("Proposed enhancement of night fee car parking spaces in KMC area")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('239')}> {t("List of Defaulters")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" target={'_blank'} href='https://www.kmcgov.in/KMCPortal/downloads/List_Defaulters_07_11_2023.pdf'> {t("List of Defaulters up to 31-10-2023")} </a>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Car_Parking_Dept'> {t("Contact Details")} </Link>                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
              <div className="inner-title">
              <h3 className="fontSizeClass"> {t("Chart of Rates for Day and Night Parking ( 2022- 2023)")} </h3>
              </div>
              <div id="accordion">
                <div className="card">
                <div className="card-header" id="heading1">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                I. {t("Rate Chart for Day Parking (7 A.M. - 10 P.M.)")} 
                </button>
                </div>
                <div id="collapse1" className="collapse show" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <div className=" health-dept-table">
                            <table width="95%" border={0} align="center" cellPadding={2} cellSpacing={2} className="table table-bordered officials" >
                            <tbody>
                                <tr bgcolor="#302ba0" tabIndex={0} align="center" valign="middle" >
                                <td width="25%" rowSpan={3} className="text-white tble-brder-left" >
                                    {t("Category of Parking space")}
                                </td>
                                <td colSpan={3} className="text-white tble-brder-right" >
                                    {t("Rate per hour or part thereof (in Rs.)")}
                                </td>
                                </tr>
                                <tr bgcolor="#302ba0" tabIndex={0} align="center" valign="middle" >
                                <td colSpan={3} className="text-white tble-brder-right"  >
                                    {t("Type of vehicle")}
                                </td>
                                </tr>
                                <tr
                                bgcolor="#302ba0"
                                tabIndex={0}
                                align="center"
                                valign="middle"
                                >
                                <td width="25%" className="text-white">
                                    {t("Two Wheeler (Motorized)")}
                                </td>
                                <td width="25%" className="text-white">
                                    {t("Car / Van / Mini bus")}
                                </td>
                                <td
                                    width="25%"
                                    className="text-white tble-brder-right"
                                >
                                    {t("Bus / Lorry")}
                                </td>
                                </tr>
                                <tr tabIndex={0} align="center" valign="top">
                                <td>{t("All Category")}</td>
                                <td>5</td>
                                <td>10</td>
                                <td>20</td>
                                </tr>
                            </tbody>
                            </table>
                            <br />
                            
                        </div>                      
                    </div>
                </div>
                </div>
                <div className="card">
                <div className="card-header" id="heading2">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                II. {t("Rate Chart for Night parking")} (10 P.M. - 7 A.M.)
                </button>
                </div>
                <div id="collapse2" className="collapse " data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <div className=" health-dept-table">
                        <table
                          width="95%"
                          border={0}
                          align="center"
                          cellPadding={2}
                          cellSpacing={2}
                          className="table table-bordered officials"
                        >
                          <tbody>
                            <tr
                              bgcolor="#302ba0"
                              tabIndex={0}
                              align="center"
                              valign="middle"
                            >
                              <td
                                width="25%"
                                rowSpan={3}
                                className="text-white tble-brder-left"
                              >
                                {t("Category of Parking space")}
                              </td>
                              <td
                                colSpan={3}
                                className="text-white tble-brder-right"
                              >
                                {t("Rate per night in Rs.")}
                              </td>
                            </tr>
                            <tr
                              bgcolor="#302ba0"
                              tabIndex={0}
                              align="center"
                              valign="middle"
                            >
                              <td
                                colSpan={3}
                                className="text-white tble-brder-right"
                              >
                                {t("Type of vehicle")}
                              </td>
                            </tr>
                            <tr
                              bgcolor="#302ba0"
                              tabIndex={0}
                              align="center"
                              valign="middle"
                            >
                              <td width="25%" className="text-white">
                                {t("Two Wheeler (Motorized)")}
                              </td>
                              <td width="25%" className="text-white">
                                {t("Car / Van / Mini bus")}
                              </td>
                              <td
                                width="25%"
                                className="text-white tble-brder-right"
                              >
                                {t("Bus / Lorry")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="center" valign="top">
                              <td>{t("All Category")}</td>
                              <td>10</td>
                              <td>30</td>
                              <td>60</td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        
                      </div>                    
                    </div>
                </div>
                </div>
                <div className="card">
                <div className="card-header" id="heading3">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                III. {t("Rate Chart for Exclusive car parking by KMC itself-For 24 Hours")}
                </button>
                </div>
                <div id="collapse3" className="collapse " data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <p className="fontSizeClass">{t("Rs.30,000/- per car per annum")}.</p>             
                    </div>
                </div>
                </div>
                <div className="card">
                <div className="card-header" id="heading4">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                IV. {t("Rate Chart for Night Parking(by KMC itself)")}
                </button>
                </div>
                <div id="collapse4" className="collapse " data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <div className=" health-dept-table">
                        <table
                          width="95%"
                          border={0}
                          align="center"
                          cellPadding={2}
                          cellSpacing={2}
                          className="table table-bordered officials"
                        >
                          <tbody>
                            <tr
                              bgcolor="#302ba0"
                              tabIndex={0}
                              style={{ color: "white", fontWeight: "bold" }}
                              align="center"
                              valign="middle"
                            >
                              <td className="text-white tble-brder-left">
                                {t("Sl. No")}.
                              </td>
                              <td className="text-white">{t("Category")}</td>
                              <td
                                colSpan={2}
                                className="text-white tble-brder-right"
                              >
                                {t("Per vehicle Per Month")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="center" valign="top">
                              <td>1</td>
                              <td>
                                {t("Upto four Wheelers(Taxi/Ola/Uber/Private Cars etc)")}
                              </td>
                              <td colSpan={2}>500.00</td>
                            </tr>
                            <tr tabIndex={0} align="center" valign="top">
                              <td>2</td>
                              <td>{t("Public carriers/LGV")}</td>
                              <td colSpan={2}>600.00</td>
                            </tr>
                            <tr tabIndex={0} align="center" valign="top">
                              <td>3</td>
                              <td>{t("Bus/Lorry/HGV")}</td>
                              <td colSpan={2}>1000.00</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>                  
                    </div>
                </div>
                </div>
                <div className="card">
                <div className="card-header" id="heading5">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                V. {t("Rate Chart for Park-O-Mat (Rowdan St. site) of KMC")}
                </button>
                </div>
                <div id="collapse5" className="collapse " data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <p className="fontSizeClass">
                        {t("The parking fees of the Park-O-Mat will be Rs35/- per hour(for four wheelers; for both day and night) and Rs 11/-per hour(for two wheelers; for both day and night)")}
                        <br />
                        {t("The rate for parking fees of the Park-O-Mat on monthly basis will be Rs 3,450/- (for 24 hours)")}
                        <br />
                        {t("Rs 2,300/-for 12 hours during day time and Rs.1,725/- for 12 hours during night time(for four wheelers)")}
                        <br />
                        {t("The rate for parking fees of the Park-O-Mat on monthly basis for two wheelers will be Rs 1,150/- for 12 hour during day time and Rs. 900/- for 12 hours during night time")}
                      </p>                
                    </div>
                </div>
                </div>
                <div className="card">
                <div className="card-header" id="heading6">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                VI. {t("Rate Chart for Exclusive car parking by KMC itself")}-{t("(For Day only :7 A.M to 10 P.M)")}
                </button>
                </div>
                <div id="collapse6" className="collapse " data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <p className="fontSizeClass">{t("Rs.24,000/- per car per annum")}</p>                       
                    </div>
                </div>
                </div>
            </div>
            <br />
            <p className="fontSizeClass">
                {t("N.B : IT and other charges will be applicable as per norms in case of Sl No III,IV and V. It has also been decided & directed by the KMC Authority to convey that no schedule of rates will be changed in this matter")}
            </p>  
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Car_Parking_Rate_Chart