import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Important_Contacts  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Contact_Us_Home'> {t("Contact Us Home")} </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Important_Contacts' target="_blank"> {t("Important Contacts")} </Link>
                 </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/MMIC'> {t("MMIC")}</Link></li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Controlling_Officers'> {t("Controlling Officers")}</Link></li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Councillors'> {t("Councillors")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Borough_Committee'> {t("Borough Committee")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Borough_Committee'> {t("Borough Offices")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCWard.jsp'> {t("Know Your Ward")}</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
         <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass"> {t("Important Contacts")} </h3>
            </div>
            <div className="col-lg-12 about-head1">
              <div className="mayor-table2 min-height-400">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th scope="row">
                        
                        <strong> {t("CHAIRPERSON")} </strong>
                      </th>
                      <td>
                        
                        <strong> {t("SMT MALA ROY")} </strong> <br />
                        {t("Telephone")} : Office (Direct) 2286-1112 EPABX: 2286-1000
                        Extn. 2401, Int. Com.205
                      </td>
                    </tr>
                    <tr>
                      <td>
                        
                        <strong> {t("MAYOR")} </strong>
                      </td>
                      <td>
                        
                        <strong> {t("FIRHAD HAKIM")} </strong> <br />
                        {t("Telephone")} : {t("Office (Direct)")} 2286-1111/1211 Fax
                        :2286-1311/1011 EPABX:2286-1000, Extn. 2471, Int.
                        Com.201 <br />
                        {t("Website")} :
                        <Link
                          to="https://www.kmcgov.in/KMCPortal/jsp/KMCPortalHome1.jsp"
                          target="_blank"
                        >
                          
                          www.kmcgov.in
                        </Link>
                        <br />
                        {t("e-mail")} :
                        <Link to="mailto: mayor@kmcgov.in " target="_blank">
                          
                          mayor@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        
                        <strong> {t("DY. MAYOR")} </strong>
                      </td>
                      <td>
                        
                        <strong> {t("SRI ATIN GHOSH")} </strong> <br />
                        {t("Telephone : Office (Direct)")} 2286-1117/1120 EPABX :
                        2286-1000 Extn. 2472, Int. Com.217,203 <br />
                        {t("e-mail")} :
                        <Link to="mailto: dymayor@kmcgov.in" target="_blank">
                          
                          dymayor@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        
                        <strong> {t("Municipal Commissioner")} </strong>
                      </td>
                      <td>{t("BINOD KUMAR, I.A.S. (WB: 1996)")}</td>
                      <td>
                        
                        {t("Phone")} : 2286-1234/1034(D), 2286-1000 Extn. 2531/2474
                        Int. Com. : 235 Fax :033-2286-1434/1334 e-mail :
                        <Link to="mailto: mc@kmcgov.in" target="_blank">
                          
                          mc@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        
                        <strong>
                          
                          {t("Special Municipal Commissioner (Development,General)")}
                        </strong>
                      </td>
                      <td> {t("SOMNATH DE, WBCS(Exe.)")} </td>
                      <td>
                        
                        Phone : 2286-1271, 2252-0003,2286-1000 Extn. 2603, Int.
                        Com. : 271 <br />
                        {t("e-mail")} :
                        <Link to="mailto:jmc_dev@kmcgov.in" target="_blank">
                          
                          jmc_dev@kmcgov.in
                        </Link>
                        <br />
                        {t("e-mail")} :
                        <Link to="mailto:jmc_gen@kmcgov.in" target="_blank">
                          
                          jmc_gen@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        
                        <strong>
                          
                          {t("Special Municipal Commissioner (Revenue,Personnel & Supply)")}
                        </strong>
                      </td>
                      <td> {t("SOMNATH DE, WBCS(Exe.)")} </td>
                      <td>
                        
                        {t("Phone")} : 2286-1126(D), 2286-1000 Extn. 2872 Int. Com. :
                        304 Fax :033-2252-2678 Mobile : 9051043014 <br />
                        e-mail :
                        <Link to="mailto:splmc_rev@kmcgov.in" target="_blank">
                          
                          splmc_rev@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        
                        <strong> {t("Project Director,KEIIP")} </strong>
                      </td>
                      <td> {t("VIBHU GOEL, I.A.S.")} </td>
                      <td>{t("Phone")} : 2283-0544(D) </td>
                    </tr>
                    <tr>
                      <td>
                        
                        <strong> {t("Municipal Secretary")} </strong>
                      </td>
                      <td> {t("HARIHAR PRASAD MANDAL , WBCS(Exe.)")} </td>
                      <td>
                        
                        {t("Phone")} : 2286-1265(D), 2286-1000 Extn. 2402, Int. Com. :
                        265 Fax :2286-1150 e-mail :
                        <Link to="mailto:secykmc@gmail.com" target="_blank">
                          
                          secykmc@gmail.com
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
         </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Important_Contacts