import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Heritage_Conservation_Architect  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
 
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          {/* <h4 className="fontSizeClass"> Drainage Pumping Stations </h4> */}
        </div>
      </div>
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
              <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/HeritageBuildingHome'> {t("Heritage Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Exemption_of_Tax'> {t("Exemption of Tax")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Heritage_Buildings'> {t("Heritage Buildings")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Heritage_Conservation_Architect'> {t("Conservation Architect")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('473')}>{t("Composition of the Heritage Conservation Committee")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Proceedings_of_HCC'> {t("Proceedings of HCC")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('474')}>{t("Action Plan")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('475')}>{t("Parking Policy")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('476')}>{t("Measures for prevention of landfill fire")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWisePond.jsp'>  {t("Ward wise Pond")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWiseHeritageBuilding.jsp'> {t("Ward Wise Heritage Building")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Environment_Heritage_Dept'> {t("Contact Details")} </Link>
                </li>

                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Activities_NCAP.pdf' target={'_blank'}> {t("Activities Permitted under NCAP")} </a>
                </li>

                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Swachh Vayu Survekshan")} <i className="bi bi-chevron-down" /></button>
                    <div id="demo2" className="collapse">
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessed_CPCB_scores_23_09_2023.xlsx'>1. {t("Self assessed scores & CPCB scores")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessment_reports_Kolkatacity_23_09_2023.pdf'>2. {t("Self assessment reports of Kolkata city2023")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Swachh_Vayu_Survekshan_23_09_2023.pdf'>3. {t("Swachh Vayu Survekshan 2023 Rank")} </a>
                    </div>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/airquality/citizenreport.do' target={'_blank'}> {t("Air quality status")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/emis/category.do' target={'_blank'}> {t(" Industry categorisation")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/District_Environment_ KMC2021_23_09_2023.pdf' target={'_blank'}> {t("FINAL District Environment plan KMC 2021")} </a>
                </li>


              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="">
            <div className="row">
              <div className="inner-title2 bas" style={{marginLeft:"10px"}}>
                <h3 className="fontSizeClass"> {t("List of Conservation Architect")} </h3>
              </div>
              <div className="mayor-table pump-list-tble Heritage_Conservation_Architect">
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr
                      bgcolor="302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"
                    >
                      <td width="10%" className="text-white tble-brder-left">
                        <b>{t("Sl. No")}.</b>
                      </td>
                      <td width="20%" className="text-white">
                        <b>{t("Name")}</b>
                      </td>
                      <td width="25%" className="text-white">
                        <b>{t("Address")}</b>
                      </td>
                      <td width="18%" className="text-white">
                        <b>{t("Phone No")}.</b>
                      </td>
                      <td width="26%" className="text-white tble-brder-right">
                        <b>{t("Email/Website")}</b>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">1.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Dr. Shivashish Bose</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          50, Bishnupalli
                          <br />
                          PO-Purba Putiary
                          <br />
                          Kolkata-700093
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +913324313125
                          <br />
                          +919231898501(M)
                        </p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          {/* <Link to="mailto:shivashishbose@yahoo.co.in">
                            shivashishbose@yahoo.co.in
                          </Link> */}
                          <Link to="mailto:shivashishbose@nic.com">
                            shivashishbose@nic.com
                          </Link>
                        </p>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">2.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Sri Anjan Mitra</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          8, Central Road, Jadavpur
                          <br />
                          Kolkata-700032
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +913324143822(O)
                          <br />
                          +919830024055(M)
                        </p>
                      </td>
                      <td align="center">
                        <Link to="mailto:appropriatealternative@nic.com">
                          appropriatealternative@nic.com
                        </Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">3.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Sri Manish Chakraborty</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          Continuity
                          <br />
                          AA-171A, Salt Lake
                          <br />
                          Kolkata-700064
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +913323596303
                          <br />
                          +919903013373(M)
                        </p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:archeritage@nic.com">
                            archeritage@nic.com
                          </Link>
                          {/* <Link to="mailto:archeritage@hotmail.com">
                            archeritage@hotmail.com
                          </Link> */}
                        </p>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">4.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Sri Akhil Ranjan Sarkar</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          BE-169, Salt Lake
                          <br />
                          Kolkata-700064
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +913323370130
                          <br />
                          +919831169034(M)
                        </p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:akhilsarkar@nic.com">
                            akhilsarkar@nic.com
                          </Link>
                        </p>
                        {/* <p className="fontSizeClass">
                          <Link to="mailto:akhilsarkar@gmail.com">
                            akhilsarkar@gmail.com
                          </Link>
                        </p> */}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">5.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Sri Ranjit Gupta</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          131/27, N.S.C. Bose Road
                          <br />
                          Kolkata-700040
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">+919831095076(M)</p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:ranajit@nic.com">
                            ranajit@nic.com
                          </Link>
                          {/* <Link to="mailto:ranajit@cal.vsnl.net.in">
                            ranajit@cal.vsnl.net.in
                          </Link> */}
                        </p>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">6.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Smt Nilina Deb Lal</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          Alleya and Associates
                          <br />
                          28/7, Sahapur Colony (East), 3rd Floor
                          <br />
                          New Alipore, Kolkata-700053
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +913332965656
                          <br />
                          +919830086482
                        </p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:nilina@nic.com">
                            nilina@nic.com
                          </Link>
                        </p>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">7.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Mr Dulal Chandra Mukhopadhyay</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          Dulal Mukherjee &amp; Associates
                          <br />
                          28B, Shakespeare Sarani
                          <br />
                          Kolkata-700017
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +913322407602
                          <br />
                          +913322870382
                          <br />
                          +913322870388
                        </p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:dmakolkata@nic.com">
                            dmakolkata@nic.com
                          </Link>
                        </p>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">8.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Mr Ashish Sharan Lal</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          Alleya and Associates
                          <br />
                          28/7, Sahapur Colony (East)
                          <br />
                          3rd Floor
                          <br />
                          Kolkata-700053
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +913332965656
                          <br />
                          +919830125446
                        </p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:sharanlal@nic.com">
                            sharanlal@nic.com
                          </Link>
                        </p>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">9.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Mr Partha Ranjan Das</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          Partha Das &amp; Associates
                          <br />
                          AE-377, Sector-I, Salt Lake
                          <br />
                          Kolkata-700064
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +913323587614
                          <br />
                          +913365445274
                          <br />
                          +919830047410
                        </p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:prdas@nic.com">
                            prdas@nic.com
                          </Link>
                        </p>
                        {/* <p className="fontSizeClass">
                          <Link to="mailto:prdas@rediffmail.com">
                            prdas@rediffmail.com
                          </Link>
                        </p> */}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">10.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Mr Kalyan Chakrabarti</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          494, Purba Phoolbagan Road
                          <br />
                          Ganguli Bagan
                          <br />
                          Kolkata-700086
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +9133221 20795,96,97
                          <br />
                          +919831045284(M)
                        </p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:mohona66@nic.com">
                            mohona66@nic.com
                          </Link>
                          {/* <Link to="mailto:mohona66@yahoo.com">
                            mohona66@yahoo.com
                          </Link> */}
                        </p>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">11.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Mr Anindya Basu</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          82/7D, Ballygunge Place
                          <br />
                          Kolkata-700019
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +913365155500
                          <br />
                          +913324401327
                          <br />
                          +919830267891(M)
                        </p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:abassoc@nic.com">
                            abassoc@nic.com
                          </Link>
                          {/* <Link to="mailto:abassoc@gmail.com">
                            abassoc@gmail.com
                          </Link> */}
                        </p>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">12.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Mr Asoke Chakraborty</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          Asoke Deep Consultants
                          <br />
                          36-B, Block-B, New Alipore
                          <br />
                          Kolkata-700053
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +913324570035
                          <br />
                          +919433053537(M)
                        </p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:asokechakrab@nic.in">
                            asokechakrab@nic.in
                          </Link>
                          {/* <Link to="mailto:asokechakrab@dataone.in">
                            asokechakrab@dataone.in
                          </Link> */}
                        </p>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">13.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Mr Arindam Malakar</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          M/s Srijani
                          <br />
                          B-70, Narkelbagan, Garia
                          <br />
                          Kolkata-700084
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +913324054935
                          <br />
                          +919433056427
                        </p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:amalakar@nic.com">
                            amalakar@nic.com
                          </Link>
                          {/* <Link to="mailto:amalakar@srijani.com">
                            amalakar@srijani.com
                          </Link> */}
                        </p>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">14.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Mr Pratik Manna</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          M/s Srijani
                          <br />
                          B-70, Narkelbagan, Garia
                          <br />
                          Kolkata-700084
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +913324054935
                          <br />
                          +919433056428
                        </p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:pmanna@nic.com">
                            pmanna@nic.com
                          </Link>
                          {/* <Link to="mailto:pmanna@srijani.com">
                            pmanna@srijani.com
                          </Link> */}
                        </p>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">15.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Mr Ashish Acharjee</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          P-171/1, CIT Scheme-VIIM
                          <br />
                          Ultadanga
                          <br />
                          Kolkata-700054
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +919163365044
                          <br />
                          +913323217320/21
                        </p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:ashish.acharjee@nic.com">
                            ashish.acharjee@nic.com
                          </Link>
                          {/* <Link to="mailto:ashish.acharjee@gmail.com">
                            ashish.acharjee@gmail.com
                          </Link> */}
                        </p>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">16.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Ms Sukanya Dasgupta</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          M/s Sukanya &amp; Associates
                          <br />
                          FD-222, Sector-III, Salt Lake
                          <br />
                          Kolkata-700106
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +913323343501
                          <br />
                          +919831246936(M)
                        </p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:sukanya@nic.com">
                            sukanya@nic.com
                          </Link>
                          {/* <Link to="mailto:sukanya@sukanya-associates.com">
                            sukanya@sukanya-associates.com
                          </Link> */}
                        </p>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">17.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Mr Sanjoy Kumar Poddar</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          391/128, Prince Anwar Shah Road
                          <br />
                          1st Floor
                          <br />
                          Kolkata-700068
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          +913324836489
                          <br />
                          +913324836490
                          <br />
                          +919831016577(M)
                        </p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:skpsanjoy@nic.in">
                            skpsanjoy@nic.in
                          </Link>
                          {/* <Link to="mailto:skpsanjoy@dataone.in">
                            skpsanjoy@dataone.in
                          </Link> */}
                        </p>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">18.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Mr Rajat Roy</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          Flat - 7A, Jay Jayanti
                          <br />
                          2, Mandeville Gardens
                          <br />
                          Kolkata-700019
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">+919810006311</p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:shalgram@nic.com">
                            shalgram@nic.com
                          </Link>
                          {/* <Link to="mailto:shalgram@gmail.com">
                            shalgram@gmail.com
                          </Link> */}
                        </p>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">
                        <p className="fontSizeClass">19.</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">Ms Kalyani Roy</p>
                      </td>
                      <td>
                        <p className="fontSizeClass">
                          29/A,Prafulla Nagar
                          <br />
                          Kolkata-700074
                        </p>
                      </td>
                      <td>
                        <p className="fontSizeClass">+919433303148</p>
                      </td>
                      <td align="center">
                        <p className="fontSizeClass">
                          <Link to="mailto:shalgram@nic.com">
                            kalr0778@nic.com
                          </Link>
                          {/* <Link to="mailto:shalgram@gmail.com">
                            kalr0778@gmail.com
                          </Link> */}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Heritage_Conservation_Architect