import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Car_Parking_FAQ  = () => { 
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
   
  <>
  <section id="hero" className="hero hero2">
      <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      
    </div>
  </section>
  {/* End Slider */}
  
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Car_Parking_Home'> {t("Car Parking Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Car_Parking_Rate_Chart'> {t("Rate Chart")}</Link>
                </li>
                <li className="nav-item ">
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Notice")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo" className="collapse">                        
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('228')}>1. {t("Notice 1")} </a>
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('229')}>2. {t("Notice 2")} </a>  
                      </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/CheckListAuthorisedParking.jsp'> {t("List of Parking Lots")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/CarParkingFee.jsp'> {t("Check Demands Payable")} </a>
                  </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/ParkingDemandPrint.jsp'> {t("Print Unpaid Bill")} </a>
                 </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCCarParkingPayment.jsp'> {t("Make Online Payment")} </a>
                  </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/CPDuplicateReceipt.jsp'> {t("Reprint e-Receipt")} </a>
                  </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Car_Parking_FAQ'> {t("FAQs")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('230')}> {t("Taxi Parking in KMC's Free Parking Stretches (Yellow boarded area)")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('231')}> {t("Car Parking Policy")} </a>
                  </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('232')}> {t("Parking Tender Policy")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('233')}> {t("Proposed enhancement of fee car parking space in KMC area")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('234')}> {t("Phalpatty area & rates")} </a>
                  </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('235')}> {t("Corri Notice for correction of Short Car Parking Tender 2018")} </a>
                 </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('236')}> {t("Extension of payment date of demands of May-18")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('237')}> {t("Usage of Mobile apps (Bandhu)")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('238')}> {t("Proposed enhancement of night fee car parking spaces in KMC area")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('239')}> {t("List of Defaulters")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" target={'_blank'} href='https://www.kmcgov.in/KMCPortal/downloads/List_Defaulters_07_11_2023.pdf'> {t("List of Defaulters up to 31-10-2023")} </a>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Car_Parking_Dept'> {t("Contact Details")} </Link>                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
              <div className="inner-title">
              <h3 className="fontSizeClass"> {t("Dept of Parking :: FAQs")}</h3>
              </div>
              <div id="accordion">
              
              <div className="card">
                <div className="card-header" id="headingOne">
                  <button className="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  1. {t("What are the key activities of the department")}?
                  </button>
                </div>
                <div id="collapseOne" className="collapse show" data-parent="#accordion">
                  <div className="card-body m-3 fontSizeClass m-3 fontSizeClass">
                  <p className="fontSizeClass">
                      {t("The key activities of the Assessment Department are")},
                      <br />
                      ● {t("To provide safe car parking facilities to citizens")}
                      <br />
                      ● {t("Generate revenues for KMC by way of parking fees")}
                      <br />
                    </p>                          
                  </div>
                </div>
              </div>
              <div className="card">
              <div className="card-header" id="heading2">
              <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
              2. {t("What are the contact details of the parking department")}?
              </button>
              </div>
              <div id="collapse2" className="collapse " data-parent="#accordion">
                  <div className="card-body m-3 fontSizeClass">
                  <p className="fontSizeClass">
                      {t("The contact details of the parking department are")}, 
                      {t("CM Parking, 5, S.N. Banerjee Road, Kolkata 70003, Phone: 22861000, Ext-2829")}
                    </p>                    
                  </div>
              </div>
              </div>
              <div className="card">
              <div className="card-header" id="heading3">
              <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
              3. {t("What can I do if parking space is not maintained well or lacks basic amenities")}?
              </button>
              </div>
              <div id="collapse3" className="collapse " data-parent="#accordion">
                  <div className="card-body m-3 fontSizeClass">
                  <p className="fontSizeClass">
                      
                      {t("In the event that basic amenities are unavailable at parking locations please write details of issues to Supervisor Parking, 5, S.N. Banerjee Road, Kolkata 70003 or call Phone: 22861000, Ext 2823")}.
                      <br />
                    </p>
                    <p className="fontSizeClass"></p>
                    <p className="fontSizeClass">
                      {t("We would be happy to assist you and resolve issues as soon as possible")}.
                    </p>           
                  </div>
              </div>
              </div>
              <div className="card">
              <div className="card-header" id="heading4">
              <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
              4. {t("How will I get feedback from KMC regarding resolution of my complaint")}?
              </button>
              </div>
              <div id="collapse4" className="collapse " data-parent="#accordion">
                  <div className="card-body m-3 fontSizeClass">
                  <p className="fontSizeClass">                       
                      {t("At the time KMC receives a parking complaint, they take name, address details, nature of complaint etc. from citizens. KMC believes that it is very important to interact with complainants. Once KMC has resolved parking issues, it ensures that a letter is sent to the address provided")}.
                    </p>                  
                  </div>
              </div>
              </div>
              <div className="card">
              <div className="card-header" id="heading5">
              <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
              5. {t("How safe are vehicles parked at KMC maintained parking areas")}?
              </button>
              </div>
              <div id="collapse5" className="collapse " data-parent="#accordion">
                  <div className="card-body m-3 fontSizeClass">
                  <p className="fontSizeClass">
                      {t("KMC designated parking areas have personnel provided by fee car parking societies. These persons are physically present at parking sites throughout parking timings. They personally inspect parking areas and ensure that such areas are safe for vehicles parked. KMC however does not take any guarantee for the safety")}.
                    </p>                
                  </div>
              </div>
              </div>
              <div className="card">
              <div className="card-header" id="heading6">
              <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
              6. {t("What should I do if I notice my vehicle has been tampered at fee parking areas")}?
              </button>
              </div>
              <div id="collapse6" className="collapse " data-parent="#accordion">
                  <div className="card-body m-3 fontSizeClass">
                  <p className="fontSizeClass">
                      {t("In the unlikely event that a vehicle has been tampered in a KMC designated car-parking area, please contact us at the address provided above or call the KMC parking department at 22861000 (Extension - 2829). If your vehicle has been tampered on account of negligence by parking attendant we will take suitable action against the parking society personnel. We suggest that, in addition to the above measure, you may kindly complaint to the local police authorities. You can also contact the fee car parking societies whose details have been provided in the website")}.
                    </p>                     
                  </div>
              </div>
              </div>
              <div className="card">
              <div className="card-header" id="heading7">
              <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
              7. {t("How can I ensure that a particular collector is authorized by KMC")}?
              </button>
              </div>
              <div id="collapse7" className="collapse " data-parent="#accordion">
                  <div className="card-body m-3 fontSizeClass">
                  <p className="fontSizeClass">                        
                      {t("The parking department provides identity cards to authorized collectors through their fee car parking societies. Please ensure that the person collecting cash from you is wearing this identity card on person. If you have any doubt on a parking collector, please call us at")}
                      {t("22861000, Extension - 2829")}
                    </p>                      
                  </div>
              </div>
              </div>
              <div className="card">
              <div className="card-header" id="heading8">
              <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
              8. {t("Once documents are submitted, what happens next")}?
              </button>
              </div>
              <div id="collapse8" className="collapse " data-parent="#accordion">
                  <div className="card-body m-3 fontSizeClass">
                  <p className="fontSizeClass">
                      ● {t("Please ensure that an authorized parking collector is present at the site")}.
                      <br />
                      ● {t("Please ensure that you pay parking charges as per the rate board chart that is provided at all locations")}.
                      <br />● {t("Please make sure that you park in such a way that other parkers can remove their vehicles easily")}
                    </p>                      
                  </div>
              </div>
              </div>
              <div className="card">
              <div className="card-header" id="heading9">
              <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
              9. {t("What are the common complaints & their corresponding resolution time")}?
              </button>
              </div>
              <div id="collapse9" className="collapse " data-parent="#accordion">
                  <div className="card-body m-3 fontSizeClass">
                  <p className="fontSizeClass">
                      
                      {t("The most common complaints are")},
                      <br />
                      ● {t("Scarcity of parking space")}
                      <br />
                      ● {t("Excess Parking charges")}
                      <br />
                      ● {t("Misbehavior by parking attendant")}
                      <br />
                      {t("Please contact Supervisor Parking at 22861000")} –
                      {t("Extension – 2829 with any of these complaints")}. 
                      {t("Issues related to excess parking charges & misbehavior are resolved within 15 days of complaint. Issues related to scarcity of parking space take longer depending on the parking location")}.
                    </p>                       
                  </div>
              </div>
              </div>
              </div>  
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Car_Parking_FAQ