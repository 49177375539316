import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "react-multi-carousel/lib/styles.css";
import '../src/components/i18n'
import {
    BrowserRouter as Router,
    Routes,
    Route
  } from 'react-router-dom';
  import "../src/components/i18n"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
      <App />
    </Router>
);
