import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import SearchBar from './SearchBar'
import axios from "axios";

function Header() {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const { t, i18n, ready } = useTranslation();
  const [click, setClick] = React.useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  const changeLanguage = (event) => {
    let lng=event.target.value
    i18n.changeLanguage(lng);
  };

const increaseFontSizeBy1px =() => {
  var el = document.getElementsByClassName('fontSizeClass');
  for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize + 1) + 'px';
  }
};
const decreaseFontSizeBy1px =() => {
  var el = document.getElementsByClassName('fontSizeClass');
  for (let i = 0; i < el.length; i++) {
  var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
  var fontSize = parseFloat(style); 
  el[i].style.fontSize = (fontSize - 1) + 'px'; 
  }
};

  return (
     <>
   <header id="header" className="header fixed-top" data-scrollto-offset={0}>
  <div className="header1">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-6 lg">
          <Link
            to=""
            className="logo align-items-center scrollto float-start"
          >
            <img src="assets/img/logo.png" alt="alt-image" />
          </Link>
          <div className="header-office_name">
            <p className="text_bengali"> কলকাতা পৌরসংস্থা </p>
            <p className="text_english"> Kolkata Municipal Corporation </p>

          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 hid">
          <div className="search">
            <form>
            <SearchBar/>
            </form>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6">
          <div className="d-flex head-part1">
            <div className="login-1">
                <div class="dropdown">
                  <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" >
                  {t("Login")} <span class="bi bi-chevron-down arrow_icon"></span>
                  </button>
                  <div class="dropdown-menu">
                    <a className="dropdown-item fontSizeClass" href="https://kmcdev.ddns.net/employee/language-selection" target="_blank" rel="noreferrer"> {t("Employee")} </a>
                    <a className="dropdown-item fontSizeClass" href="https://kmcdev.ddns.net/citizen/language-selection" target="_blank" rel="noreferrer"> {t("Citizen")} </a>
                  </div>
                </div>
                      
          </div>            
          
              <div className="fontSizeClass">
              <div className="font-site">
                <span className="d-flex">
                <button class="btn btn-link fontSizeClass text-white p-0" onClick={increaseFontSizeBy1px} style={{fontSize:"18px"}}>
                  <i class="bi bi-plus-circle"></i>		        
                </button>   
                  <Link to='/portal' className="text-large" style={{marginTop:"5px"}}>
                  <i class="bi bi-fonts"></i> 
                  </Link>
                  <button class="btn btn-link fontSizeClass text-white p-0" onClick={decreaseFontSizeBy1px} style={{fontSize:"18px"}}>
                  <i class="bi bi-dash-circle"></i>
                  </button>                  
                </span>
              </div>
              </div>
                     
          <div class="lang-set">
              <select name="language" id="language" className="language" onChange={changeLanguage}>
                <option value="en">{t("English")}</option>
                <option value="bn">{t("Bengali")}</option>
              </select>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="header2 megamenu1">
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light sticky-top">
        {/* <Link class="navbar-brand" to='/'>Mega Menu</Link> */}
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#mobile_nav"
          aria-controls="mobile_nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          
        <i class="bi bi-list" ></i>
        </button>
        <div className={click ? "collapse navbar-collapse" : "collapse navbar-collapse " } id="mobile_nav">
          <ul className="navbar-nav mt-2 mt-lg-0"></ul>
          <ul className="navbar-nav navbar-light"> 
            <li className="nav-item">
              <Link className="nav-link" to='/portal' onClick={handleClick}> {t("Home")} </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Quick_Links" onClick={handleClick}> {t("Quick Links")}</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Dashboard" onClick={handleClick}> {t("EODB Dashboard")} </Link>
            </li>
            <li className="nav-item dropdown megamenu-li dmenu">
              <Link className="nav-link dropdown-toggle" to="" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> {t("Online Payment")}</Link>
              <div className="dropdown-menu megamenu sm-menu border-top" aria-labelledby="dropdown01">
                <div className="row">
                  <div className="col-sm-6 col-lg-3 border-right mb-4">
                    <h6> {t("Property Tax")} </h6>
                    <a className="dropdown-item" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssesmentAllUnpaidDemand.jsp" target="_blank" onClick={handleClick}> {t("All Bill")}</a>
                    <a className="dropdown-item" href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/MiscellaneousSuspensePay.jsp" target="_blank" onClick={handleClick}> {t("Suspense")} </a>
                    <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/LOIDemandWaiverFiftyPrint.jsp'  target="_blank" onClick={handleClick}> {t("Upto 50% Interest Waive LOI")}</a>
                    <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'  target="_blank"onClick={handleClick}> {t("UAA Online Submission")}</a>
                  </div>
                  <div className="col-sm-6 col-lg-3 border-right mb-4">
                    <a className="dropdown-item navcol" href="https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingPayment.jsp" target="_blank"onClick={handleClick}> {t("Building")}</a>
                    <a className="dropdown-item navcol" href="https://www.kmcgov.in/KMCPortal/KMCPortalLicensePaymentAction.do"  target='_blank' onClick={handleClick}> {t("CE (Licence Dept.)")}</a>
                    <a className="dropdown-item navcol" href="https://www.kmcgov.inKMCPortal/jsp/SorDemandPayment.jsp" target='_blank' onClick={handleClick}> {t("Ch. Valuer & Surveyor")}</a>
                    <br />
                    <h6>{t("Market")}</h6>
                    <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/MarketPayment.jsp' onClick={handleClick}> {t("Regular Demand")} </a>
                    <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/MarketMiscPayDemand.jsp' onClick={handleClick}> {t("Misclennious Demand")} </a>
                  </div>
                  <div className="col-sm-6 col-lg-3 border-right mb-4">
                    <h6>{t("Amusement")}</h6>
                    <li className="nav-item"><a class="nav-link" onClick={(e)=>fileDownload('50')}> {t("Amusement Fees")} </a> </li>                    <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp' target="_blank" onClick={handleClick}> {t("Casual Demand Generation")} <br /> {t("and payment")} </a>
                  </div>
                  <div className="col-sm-6 col-lg-3 mb-4">
                    <Link className="dropdown-item navcol" to='/Car_Parking_Home' onClick={handleClick}> {t("Parking")} </Link>
                    <Link className="dropdown-item navcol" to='/KMC_Advertisement' onClick={handleClick}> {t("Advertisement")} </Link>
                    <Link className="dropdown-item navcol" to='/Water_Supply' onClick={handleClick}> {t("Water Supply")}</Link>
                    <Link className="dropdown-item navcol" to='/Drainage_Home' onClick={handleClick}> {t("Drainage")} </Link>
                    <Link className="dropdown-item navcol" to='/KMC_Bustee_services' onClick={handleClick}> {t("Bustee")}</Link>
                  </div>
                </div>
              </div>
            </li>
            
            <li className="nav-item">
            <Link className="nav-link" to="/RTI_List_of_1st_Appellate_Authorities" onClick={handleClick}> {t("RTI")} </Link>
            </li>

            <li className="nav-item dmenu dropdown">
              <Link
                className="nav-link dropdown-toggle" id="navbarDropdown"
                role="button" data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"> {t("Tender")} </Link>
              <div
                className="dropdown-menu sm-menu" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item" to='/List_Of_Tenders' onClick={handleClick}> {t("Tender")}</Link>
                <Link className="dropdown-item" to='/Tender_Batra' onClick={handleClick}> {t("Tender Barta")}</Link>
                <a className="dropdown-item" onClick={(e)=>fileDownload('284')}> {t("Tender Form Format")} </a>
              </div>
            </li>
            {/*========*/}
            <li className="nav-item dropdown megamenu-li dmenu">
              <Link
                className="nav-link dropdown-toggle" id="dropdown01"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"> {t("Online Services")}</Link>
              <div
                className="dropdown-menu megamenu sm-menu border-top"
                aria-labelledby="dropdown01">
                <div className="row">
                  <div className="col-sm-6 col-lg-3 border-right mb-4">
                    {/* <h6> Ecommerce </h6> */}
                    <a className="dropdown-item ser-log1" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'  target="_blank" onClick={handleClick}> {t("eKMC 1.0 Login")}</a>
                    <Link className="dropdown-item" to='/KMC_Advertisement' onClick={handleClick}> {t("Advertisement")}</Link>
                    <Link className="dropdown-item" to='/Amusement' onClick={handleClick}> {t("Amusement")}</Link>
                    <Link className="dropdown-item" to='/Assessment_Collection_Assessment_Home' onClick={handleClick}> {t("Assessment Collection")} </Link>
                    <Link className="dropdown-item" to='/Birth_Death_Ragistration' onClick={handleClick}> {t("Birth/Death Registration")} </Link>
                    <Link className="dropdown-item" to='/Building_Sanction_Building_Home' onClick={handleClick}> {t("Building Sanction")} </Link>
                    <Link className="dropdown-item" to='/KMC_Bustee_services' onClick={handleClick}> {t("Bustee Services")}</Link>
                  </div>
                  <div className="col-sm-6 col-lg-3 border-right mb-4">
                    <Link className="dropdown-item" to='/Car_Parking_Home' onClick={handleClick}> {t("Car Parking")}</Link>
                    <Link className="dropdown-item" to='/Central_records_home' onClick={handleClick}> {t("Central Records")}</Link>
                    <Link className="dropdown-item" to='/CH_VS_Dept_Download_Forms' onClick={handleClick}> {t("CH.VS Dept")}</Link>
                    <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalDrainagePayment.jsp'> {t("Drainage")}</a>
                    <Link className="dropdown-item" to='/Kmc_school_home' onClick={handleClick}> {t("Education")}</Link>
                    <Link className="dropdown-item" to='/Engineering_Home' onClick={handleClick}> {t("Engineering (Civil)")} </Link>
                  </div>
                  <div className="col-sm-6 col-lg-3 border-right mb-4">
                    <Link className="dropdown-item" to='/Health_services_Health_Home' onClick={handleClick}> {t("Health Services")}</Link>
                    <Link className="dropdown-item" to='/HeritageBuildingHome' onClick={handleClick}> {t("Environment & Heritage")}</Link>
                    <Link className="dropdown-item" to='/Contact_Us_Home' onClick={handleClick}>{t("Information Technology")}</Link>
                    <Link className="dropdown-item" to='/Kmc_school_home' onClick={handleClick}> {t("KMCP Schools")} </Link>
                    <Link className="dropdown-item" to='/Certificate_of_Enlistment' onClick={handleClick}> {t("Licence")} </Link>
                    <Link className="dropdown-item" to='/Lighting_Home' onClick={handleClick}> {t("Lighting")}</Link>
                  </div>
                  <div className="col-sm-6 col-lg-3 mb-4">
                    <Link className="dropdown-item" to='/KMC_market_list' onClick={handleClick}> {t("Market")} </Link>
                    <Link className="dropdown-item" to='/Parks_and_gardens' onClick={handleClick}> {t("Parks & Squares")}</Link>
                    <Link className="dropdown-item" to='/Drainage_Home' onClick={handleClick}>{t("Sewerage & Drainage")}</Link>
                    <Link className="dropdown-item" to='/Social_welfare_Download_Forms' onClick={handleClick}> {t("Social Welfare & Urban")} <br /> {t("Poverty Alleviation")}</Link>
                    <Link className="dropdown-item" to='/Solid_wastes_home' onClick={handleClick}> {t("Solid Waste Management")}</Link>
                    <Link className="dropdown-item" to='/Water_Supply' onClick={handleClick}> {t("Water Supply")}</Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.kmcgov.in/KMCPortal/jsp/UserRegistration.jsp" target="_blank" onClick={handleClick}>{t("Register KYC")}</a>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Contact_Us_Home" onClick={handleClick}> {t("Contact")} </Link>
            </li>
            <li className="nav-item dmenu dropdown">
              <Link
                className="nav-link dropdown-toggle" id="navbarDropdown"
                role="button" data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                {t("More")} </Link>
              <div
                className="dropdown-menu sm-menu" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item fontSizeClass" to="/MC_Circular_2022_23">{t("Circulars")}</Link>
                <a className="dropdown-item" href='' onClick={handleClick}> {t("Submenu 2")}</a>
                <a className="dropdown-item" href='' onClick={handleClick}> {t("Submenu 3")} </a>
              </div>
            </li>
          </ul>
          
        </div>
      </nav>
    </div>
  </div>
</header>

    </>
   
  );
}

export default Header;