import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const MC_Circular_2019_20 = () => {
  const fileDownload=(fileStoreId)=>
        {
          
          let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
           axios
              .post(url,{"fileId": fileStoreId, "userId": "392" })
              .then((res) => {
                  console.log("response is --- ", res);
      
                  if(res && res.data && res.status === 200) {
                    const base64 = res.data[0] && res.data[0].base64_data;
                    const pdfData = 'data:application/pdf;base64,'+base64;
      
                    let html = '<html>' +
                        '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                        '<body>' +
                        '<iframe src="' + pdfData + '"></iframe>' +
                        '</body></html>';
                        console.log(html);
                    let a = window.open();
                    a.document.write(html);
                }
              })
              .catch((e) => {
                  // setLoader(false);
                  console.log("The error is ", e);
              })
        }
  const { t, i18n, ready } = useTranslation();
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
    const [DocumentService, setDocumentService] = React.useState(false);
    const [LicenseService, setLicenseService] = React.useState(false);
    
    const LicenseServiceToggle = () => {
      setLicenseService(!LicenseService);
    };
    const DocumentServiceToggle = () => {
        setDocumentService(!DocumentService);
    };
  return (
<>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
  <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      </div>
    </section>
    <section className="section inner-pad1">
      <div className="container " >
        <div className="row">
          <div className="col-lg-3">
          <div className="site-menu  min-height-400">
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <button type="button" class="btn nav-link pr-0" data-toggle="collapse" data-target="#demo">{t("Municipal Commissioner CIRCULAR")}<i className="bi bi-chevron-down " /></button>
                    <div id="demo" class="collapse">
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2024_25'> {t("MC's Circular")} 2024-25 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2023_24'> {t("MC's Circular 2023-24")}</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2022_23'> {t("MC's Circular 2022-23")}</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2021_22'> {t("MC's Circular")} 2021-22</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2020_21'>{t("MC's Circular")} 2020-21</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass active1" to='/MC_Circular_2019_20'>{t("MC's Circular")} 2019-20</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2018_19'> {t("MC's Circular")} 2018-19</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2017_18'>{t("MC's Circular 2017-18")}</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2016_17'>{t("MC's Circular")} 2016-17</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2015_16'>{t("MC's Circular")} 2015-16</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2004_05'>{t("MC's Circular")} 2004-05</Link></li>
                    </div>
                  </li><li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Municipal_secretary_circular'>{t("MUNICIPAL SECRETARY'S CIRCULAR")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/CMF_Communique_2022_23'>{t("C M F & A's Circular and Communique - 2022-23")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Chief_Manager_Circular'>{t("Chief Manager (P)'s Circular")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Govt_memo'>{t("Govt Memo")}</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1558')}> {t("West Bengal Right to Public Service Act,2013")} </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1559')}>{t("Guidelines of Section 199(2) of KMC Act,1980 (as amended in 2019)")}  </a>
                  </li>
                  <li className="nav-item-list ">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("COMPENDIUM OF IMPORTANT DEPARTMENTAL CIRCULARS / ORDERS")}<i className="bi bi-chevron-down " /></button>
                      <div id="demo1" className="collapse">                        
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>1. Financial Year 2010-11 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>2. Financial Year 2011-12 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('870')}>3. Financial Year 2012-13 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('925')}>4. Financial Year 2013-14 </a>   
                      </div>
                  </li>
                  
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2 bas">
                  <h3 className="fontSizeClass"> {t("Circular")} </h3>
                </div>
                <div className="health-dept-table mayor-table" style={{width:"100%"}}>
                  <table
                    width="95%"
                    border={0}
                    
                    cellPadding={2}
                    cellSpacing={2}
                    className="table table-bordered officials" >
                    <tbody>
                      <tr
                        bgcolor="#302ba0"
                        tabIndex={0}
                        
                        valign="middle">
                        <td width="35%" className="text-white tble-brder-left">
                        {t("Circular No")}		
                        </td>
                        <td width="20%" className="text-white">
                        {t("Date")}		
                        </td>
                        <td width="45%" className="text-white tble-brder-right">
                        {t("Subject")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1045')}> MC's Circular No.190 of 2019-20 </a>
                        </td>
                        <td >
                        21-March-2020		
                        </td>
                        <td >
                        Preventive measure taken by the Authority of the Kolkata Municipal Corporation to prevent the spread of Novel Coronavirus(Covid -19)
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1046')}>MC's Circular No.189 of 2019-20</a>
                        </td>
                        <td >
                        18-March-2020					
                        </td>
                        <td >
                        Control Room of KMC to tackle Corona Virus issues.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1047')}>MC's Circular No.188 of 2019-20	</a>
                        </td>
                        <td >18-March-2020	</td>
                        <td >
                        Quick Response Team of KMC to tackle Corona VIrus ISsues.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1048')}>MC's Circular No.187 of 2019-20</a>
                        </td>
                        <td >
                        17-March-2020					
                        </td>
                        <td >
                        Awareness on the prevention of infection spread through Novel CORONA Virus
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1049')}>MC's Circular No.186 of 2019-20	</a>
                        </td>
                        <td >
                        17-March-2020					
                        </td>
                        <td >
                        Revision of pension and pensionary benefits in respect of the pensioners/ family pensioners of the Kolkata Municipal Corpotation.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1050')}>MC's Circular No.185 of 2019-20</a>
                        </td>
                        <td >17-March-2020</td>
                        <td >
                        Constitution of High Powered Advisory Committee on environmental issues for are under the jurisdiction of KMC.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1051')}>MC's Circular No.184 of 2019-20</a>
                        </td>
                        <td >14-March-2020				
                        </td>
                        <td >
                        Constitution of a Value Engineering Cell to explore new operation techiques in the field of engineering
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1052')}>MC's Circular No.183 of 2019-20</a>
                        </td>
                        <td >
                        12-March-2020		                        </td>
                        <td >
                        Optimum utilization of contractual manpower directly engaged or engaged through agencies in KMC.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1053')}>MC's Circular No.182 of 2019-20</a>
                        </td>
                        <td >
                        12-March-2020				
                        </td>
                        <td >
                        Municipal Commissioner's Circular No. 182 of 2019-20

                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1054')}>MC's Circular No.181 of 2019-20</a>
                        </td>
                        <td >11-March-2020	</td>
                        <td >
                        Modifiation of Circular No.179 of 2019-20 dated 07-03-2020 regarding Preparation of outreach programme on implementation of various indicators under EoDB-2021.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1055')}>MC's Circular No.180 of 2019-20</a>
                        </td>
                        <td >11-March-2020	</td>
                        <td >
                        Framing of Recruitment Regulations for the post of 'Director General' under Soild Waste Management department under The Kolkata Municipal Corporation.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1056')}>MC's Circular No.179 of 2019-20</a>
                        </td>
                        <td >07-March-2020	 </td>
                        <td >
                        PPreparation of outreach programme on implementation of various indicators under EoDB-2021.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1057')}>MC's Circular No.178 of 2019-20</a>
                        </td>
                        <td >06-March-2020	 </td>
                        <td >
                        Procurement of Contracts as well as Consultancy Services - Transparency in Evaluation of Bids
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1058')}>MC's Circular No.177 of 2019-20</a>
                        </td>
                        <td >06-March-2020	 </td>
                        <td >
                        Modification in the Notification issued from UD & MA Department, Govt. of West Bengal vide No. 935/MA/O/C-4/IM-31/2015 (Pt.-III)dtd. 19th December'2018-RoW permission regarding
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1059')}>MC's Circular No.176 of 2019-20	</a>
                        </td>
                        <td >04-March-2020	 </td>
                        <td >Disposal of Pending Files under section 393A of the KMC Act 1980</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1060')}>MC's Circular No.175 of 2019-20</a>
                        </td>
                        <td >04-March-2020	 </td>
                        <td >Reduction of Procedure for sanction and completion cum Occupancy of Building</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1061')}>MC's Circular No.174 of 2019-20	</a>
                        </td>
                        <td >
                        04-March-2020	                        </td>
                        <td >
                        Risk based classification of the building on the basis of risk factors
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1062')}>MC's Circular No.173 of 2019-20</a>
                        </td>
                        <td >04-March-2020	 </td>
                        <td >
                        Management of Building Department
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1063')}>MC's Circular No.172 of 2019-20</a>
                        </td>
                        <td >04-March-2020	 </td>
                        <td >
                        Amendments made in Building Rule regarding Quality of Construction
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1064')}>MC's Circular No.171 of 2019-20</a>
                        </td>
                        <td >
                        04-March-2020	                         </td>
                        <td >
                        Reduction of Timeline for sanction and Completion cum Occupancy of Building
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1065')}>MC's Circular No. 170 of 2019-20</a>
                        </td>
                        <td >29-Feb-2020	</td>
                        <td >
                        Municipal Commissioner's Circular No. 170 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1066')}>MC's Circular No. 169 of 2019-20	</a>
                        </td>
                        <td >25-Feb-2020	</td>
                        <td >
                        Municipal Commissioner's Circular No. 169 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1067')}>MC's Circular No. 168 of 2019-20</a>
                        </td>
                        <td >22-Feb-2020</td>
                        <td >Submission of Reports in with NGT Orders </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1068')}>MC's Circular No. 167 of 2019-20</a>
                        </td>
                        <td >22-Feb-2020	 </td>
                        <td >
                        Pending issues wiht UD & MA Department, Govt. of West Bengal
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1069')}>MC's Circular No. 166 of 2019-20</a>
                        </td>
                        <td >20-Feb-2020 </td>
                        <td >
                        Revised Circular on Cleaning and proper illumination in bus stands
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1070')}>MC's Circular No. 165 of 2019-20</a>
                        </td>
                        <td >
                        18-Feb-2020	                        </td>
                        <td >
                        To ensure proper maintenance of all Parks and Squares located in different parts of the City.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1071')}>MC's Circular No. 164 of 2019-20</a>
                        </td>
                        <td >18-Feb-2020	</td>
                        <td >
                        Submission of monthly progress report in the matter of Hon'ble NGT, O.A. No. 673 of 2018, dated 06.12.2019
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1072')}>MC's Circular No. 163 of 2019-20</a>
                        </td>
                        <td >15-Feb-2020 </td>
                        <td >
                        Municipal Commissioner's Circular No. 163 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1073')}>MC's Circular No. 162 of 2019-20</a>
                        </td>
                        <td >
                        13-Feb-2020	                        </td>
                        <td >
                        NGT matter SWM Rules
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1074')}>MC's Circular No. 161 of 2019-20</a>
                        </td>
                        <td >10-Feb-2020	</td>
                        <td >
                        Management of E-waste within the KMC area.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1075')}>MC's Circular No. 160 of 2019-20</a>
                        </td>
                        <td >10-Feb-2020</td>
                        <td >
                        Preparation of Draft Policy on ground water extraction and recharging of ground water.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1076')}>MC's Circular No. 159 of 2019-20</a>
                        </td>
                        <td >10-Feb-2020 </td>
                        <td >Preparation of action plan on Air Quality Management of Kolkata.</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1077')}>MC's Circular No. 158 of 2019-20</a>
                        </td>
                        <td >10-Feb-2020					
                        </td>
                        <td >
                        Cleaning and proper illuination in bus stands
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1078')}>MC's Circular No. 157 of 2019-20</a>
                        </td>
                        <td >10-Feb-2020	</td>
                        <td >
                        Detection of leakage in water supply pipelines
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1079')}> MC's Circular No. 156 of 2019-20	</a>
                        </td>
                        <td >07-Feb-2020 </td>
                        <td >
                        Data mining of the database of Assessent-Collection Department and Advertisement Department.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1080')}>MC's Circular No. 155 of 2019-20</a>
                        </td>
                        <td >07-Feb-2020</td>
                        <td >
                        Modification in the format of "No Outstandung Certificates" issued by Assessment Collection Departent.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1081')}>MC's Circular No. 154 of 2019-20</a>
                        </td>
                        <td >07-Feb-2020	</td>
                        <td >
                        Online submission and processing of A-75 forms under Assessment- Collection Department.
                       </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1082')}>MC's Circular No. 153 of 2019-20	</a>
                        </td>
                        <td >07-Feb-2020	</td>
                        <td >Issuance of Birth Certificates, Death Certificates and Special Registration of Death Certificates. </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1083')}>MC's Circular No. 152 of 2019-20</a>
                        </td>
                        <td >06-Feb-2020	</td>
                        <td >
                        Redesignation of the post of superintending Medical Officer (General) under hospital & Dispensaries in pay Band 4A- Rs.15,600 - 42,000/- plus Grade pay Rs. 6,600/- under Health Deptt., KMC to the post of Executive Health Officer in pay Band 4A-Rs. 15,600 - 42,000/- plus Geade pay Rs. 6,600/- under Health Deptt., KMC
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1084')}>MC's Circular No. 149 of 2019-20	</a>
                        </td>
                        <td >01-Feb-2020</td>
                        <td >
                        Guidelines for engagement of hired vehicles in KMC in continuation od M.C's Circular No. 102 of 2019-20, dated 08.11.2019
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1085')}>MC's Circular No. 148 of 2019-20</a>
                        </td>
                        <td >01-Feb-2020</td>
                        <td >Recharging of ground water</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1087')}>MC's Circular No. 147 of 2019-20</a>
                        </td>
                        <td >
                        28-Jan-2020	                        </td>
                        <td >
                        Modification of Municipal Commissioner Circular No. 145 of 2019-20, dated - 21/01/2020 regarding the constitution of a committee to review the development project and several issues of the markets.
                       </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1088')}>MC's Circular No. 146 of 2019-20</a>
                        </td>
                        <td >24-Jan-2020</td>
                        <td >
                        Municipal Commissioner's Circular No. 146 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1089')}>MC's Circular No. 145 of 2019-20	</a>
                        </td>
                        <td >21-Jan-2020</td>
                        <td >
                        Committee to review the development project and several issues of the markets.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1090')}>MC's Circular No. 144 of 2019-20	</a>
                        </td>
                        <td >
                        15-Jan-2020	                        </td>
                        <td >Municipal Commissioner's Circular No. 144 of 2019-20 </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1091')}>MC's Circular No. 143 of 2019-20</a>
                        </td>
                        <td >14-Jan-2020</td>
                        <td >Municipal Commissioner's Circular No. 143 of 2019-20</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1092')}>MC's Circular No. 142 of 2019-20	</a>
                        </td>
                        <td >14-Jan-2020	 </td>
                        <td >Monitoring and Supervision of Civil Volunteers in K.M.C.</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1093')}>MC's Circular No. 141 of 2019-20	</a>
                        </td>
                        <td >14-Jan-2020	</td>
                        <td >Municipal Commissioner's Circular No. 141 of 2019-20</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1094')}>MC's Circular No. 140 of 2019-20</a>
                        </td>
                        <td >08-Jan-2020</td>
                        <td > Municipal Commissioner's Circular No. 140 of 2019-20</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1095')}>MC's Circular No. 139 of 2019-20</a>
                        </td>
                        <td >07-Jan-2020	</td>
                        <td >Municipal Commissioner's Circular No. 139 of 2019-20 </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1096')}>MC's Circular No. 138 of 2019-20	</a>
                        </td>
                        <td >06-Jan-2020	</td>
                        <td >
                        Manpower Deployment.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1097')}>MC's Circular No. 137 of 2019-20</a>
                        </td>
                        <td >06-Jan-2020	</td>
                        <td >
                        Introduction of a format for renewal of staff/ personnel of different departments engaged on contractual basis ( either direct contractual or through agency) and new engagement of such contractual staff thereof.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1098')}>MC's Circular No. 136 of 2019-20</a>
                        </td>
                        <td >04-Jan-2020	</td>
                        <td >Speedy disposal of the prayers for job under special Regulation.</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1099')}>MC's Circular No. 135 of 2019-20	</a>
                        </td>
                        <td >04-Jan-2020	</td>
                        <td >
                        Pay & Use Toilets
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1100')}>MC's Circular No. 134 of 2019-20</a>
                        </td>
                        <td >03-Jan-2020	</td>
                        <td >
                        Formattion of a Committee to review and reconstruct the existing Recruitment Regulations of the multifarious cardres of KMC
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1101')}>MC's Circular No. 133 of 2019-20</a>
                        </td>
                        <td >02-Jan-2020	</td>
                        <td >
                        Portial modification of mpl. commissionar's Circular No 49 of 2019-20 dated 30.07.2019
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1102')}>MC's Circular No. 123 of 2019-20</a>
                        </td>
                        <td >
                        10-dec-2019	                        </td>
                        <td >
                        Revised Project Review Meeting and Revenue Meeting Schedule
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1103')}>MC's Circular No. 122 of 2019-20</a>
                        </td>
                        <td >10-dec-2019	</td>
                        <td >
                        Municipal Commissioner's Circular No. 122 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1104')}>MC's Circular No. 121 of 2019-20</a>
                        </td>
                        <td >10-dec-2019	</td>
                        <td >
                        Signature of CME/DG on work oders above Rs. 1.00 crore
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1105')}>MC's Circular No. 120 of 2019-20</a>
                        </td>
                        <td >03-dec-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 120 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1106')}>MC's Circular No. 119 of 2019-20	</a>
                        </td>
                        <td >03-dec-2019	</td>
                        <td >Modification of the Circular of Municipal Commissionar no. 133 of 2019-20 regarding "strict monitoring of some revenue earning departments" </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1107')}>MC's Circular No. 118 of 2019-20	</a>
                        </td>
                        <td >29-nov-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 118 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1108')}>MC's Circular No. 117 of 2019-20	</a>
                        </td>
                        <td >29-nov-2019</td>
                        <td >Municipal Commissioner's Circular No. 117 of 2019-20</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1109')}>MC's Circular No. 116 of 2019-20</a>
                        </td>
                        <td >29-nov-2019	</td>
                        <td >
                        Formation of Committee for preparation revised BPL survey form
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1110')}>MC's Circular No. 132 of 2019-20	</a>
                        </td>
                        <td >24-dec-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 132 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1111')}>MC's Circular No. 131 of 2019-20	</a>
                        </td>
                        <td >24-dec-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 131 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1112')}>MC's Circular No. 130 of 2019-20	</a>
                        </td>
                        <td >23-dec-2019</td>
                        <td >
                        Ease of Doing Business
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1113')}>MC's Circular No. 129 of 2019-20</a>
                        </td>
                        <td >20-dec-2019	</td>
                        <td >
                        Mentioning of value or amount in both figures and in words.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1114')}>MC's Circular No. 128 of 2019-20	</a>
                        </td>
                        <td >18-dec-2019	</td>
                        <td >
                        Sending of ATR for complaints/grievances received through 'Talk to Mayor' programme and Whatsapp.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1115')}>MC's Circular No. 127 of 2019-20	</a>
                        </td>
                        <td >14-dec-2019</td>
                        <td >
                        Invitation of Public Representatives/ Dignitaries in functions of Kolkata Municipal Corporation.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1116')}>MC's Circular No. 126 of 2019-20	</a>
                        </td>
                        <td >14-dec-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 126 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1117')}>MC's Circular No. 125 of 2019-20	</a>
                        </td>
                        <td >13-dec-2019</td>
                        <td >
                        Guidelines for using Community Hall
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1118')}>MC's Circular No. 124 of 2019-20	</a>
                        </td>
                        <td >13-dec-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 124 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1119')}>MC's Circular No. 114 of 2019-20	</a>
                        </td>
                        <td >23-nov-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 114 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1120')}>MC's Circular No. 113 of 2019-20	</a>
                        </td>
                        <td >19-nov-2019</td>
                        <td >
                        Strict monitoring of some revenue earning departments
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1122')}>MC's Circular No. 112 of 2019-20</a>
                        </td>
                        <td >18-nov-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 112 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1123')}>MC's Circular No. 111 of 2019-20</a>
                        </td>
                        <td >18-nov-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 111 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1124')}>MC's Circular No. 110 of 2019-20	</a>
                        </td>
                        <td >18-nov-2019</td>
                        <td >
                        Development of software for issuance of online Trade Licence for industry under the Ease of Doing Business (EoDB) Reforms.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1125')}>MC's Circular No. 109 of 2019-20	</a>
                        </td>
                        <td >18-nov-2019</td>
                        <td >
                        Online application for water supply connection, drainage connection and building sanction plan for industry under EoDB reforms.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1126')}>MC's Circular No. 108 of 2019-20	</a>
                        </td>
                        <td >16-nov-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 108 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1127')}>MC's Circular No. 107 of 2019-20</a>
                        </td>
                        <td >16-nov-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 107 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1128')}>MC's Circular No. 106 of 2019-20	</a>
                        </td>
                        <td >16-nov-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 106 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1129')}>MC's Circular No. 105 of 2019-20	</a>
                        </td>
                        <td >16-nov-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 105 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1130')}>MC's Circular No. 104 of 2019-20</a>
                        </td>
                        <td >13-nov-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 104 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1131')}>MC's Circular No. 103 of 2019-20</a>
                        </td>
                        <td >13-nov-2019	</td>
                        <td >
                        Modification of Municipal Commissioner's Circular No. 68 of 2018-19 dated 25.09.2018 regarding R.TI matters related to KMC
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1132')}>MC's Circular No. 102 of 2019-20	</a>
                        </td>
                        <td >08-nov-2019</td>
                        <td >
                        Revised guidelines for Hired cars
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1133')}>MC's Circular No. 101 of 2019-20</a>
                        </td>
                        <td >08-nov-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 101 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1134')}>MC's Circular No. 100 of 2019-20	</a>
                        </td>
                        <td >08-nov-2019</td>
                        <td >
                        Arrangements for combating cyclone storm "Bulbul"
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1135')}>MC's Circular No. 99 of 2019-20	</a>
                        </td>
                        <td >07-nov-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 99 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1136')}>MC's Circular No. 98 of 2019-20</a>
                        </td>
                        <td >24-oct-2019</td>
                        <td >
                        Deployment of officials at the different ghats on the river Hooghly and ponds in added areas.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1137')}>MC's Circular No. 97 of 2019-20</a>
                        </td>
                        <td >24-oct-2019</td>
                        <td >
                        Civic arrangement during the ceremony of Chhat Puja - 2019.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1138')}>MC's Circular No. 96 of 2019-20</a>
                        </td>
                        <td >24-oct-2019</td>
                        <td >
                        Modification to the existing Recruitment Regulations for the post of Sub-Registrar in the pay scale PB - 4 (f9,000 - f40,500) + GP f4,700/- under Health Department, KMC
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1139')}>MC's Circular No. 95 of 2019-20</a>
                        </td>
                        <td >23-oct-2019</td>
                        <td >
                        Time limit for allowing completion of tender formalities for works above 50 lakh.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1140')}>MC's Circular No. 94 of 2019-20</a>
                        </td>
                        <td >23-oct-2019</td>
                        <td >
                        Report on pending issues with the UD & MA Department and Finance Department, Government of West Bengal.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1141')}>MC's Circular No. 93 of 2019-20	</a>
                        </td>
                        <td >21-oct-2019</td>
                        <td >Municipal Commissioner's Circular No. 93 of 2019-20</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1142')}>MC's Circular No. 92 of 2019-20</a>
                        </td>
                        <td >19-oct-2019</td>
                        <td >
                        Modification of Recruitment Regulations for the post of Additional CMLO and Deputy CMLO of Legal Services under Kolkata Municipal Corporation
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1143')}>MC's Circular No. 91 of 2019-20</a>
                        </td>
                        <td >19-oct-2019</td>
                        <td >
                        Nomination of Nodal Officer of KMC to address the various matters received from CMRO, Nabanna through the dedicated CMO Public Grievance Management System portal.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1144')}>MC's Circular No. 90 of 2019-20</a>
                        </td>
                        <td >19-oct-2019</td>
                        <td >
                        Nodal Officer for implementation of WBRTPS Act 2013 in KMC.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1145')}>MC's Circular No. 89 of 2019-20</a>
                        </td>
                        <td >01-oct-2019</td>
                        <td >
                        West Bengal Urban Wage Employment Scheme 2011-12
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1146')}>MC's Circular No. 88 of 2019-20</a>
                        </td>
                        <td >27-Sep-2019</td>
                        <td >
                        Amendment of modalities for filling up of vacancies in the posts of unskilled labour under Engineering Departments (Non-Conservancy)..
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1147')}>MC's Circular No. 87 of 2019-20	</a>
                        </td>
                        <td >27-Sep-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 87 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1148')}>MC's Circular No. 86 of 2019-20</a>
                        </td>
                        <td >27-Sep-2019</td>
                        <td >
                        Arrangement to be made at different Ghats along the river Ganga and also at different waterbodies for 'Tarpan' on Mahalaya i.e. 28.09.2019 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1149')}>MC's Circular No. 85 of 2019-20</a>
                        </td>
                        <td >24-Sep-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 85 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1150')}>MC's Circular No. 84 of 2019-20</a>
                        </td>
                        <td >24-Sep-2019</td>
                        <td >
                        Urgent shifting of people residing under the Tala Bridge and Chitpur Bridge. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1151')}>MC's Circular No. 83 of 2019-20</a>
                        </td>
                        <td >24-Sep-2019</td>
                        <td >
                        Demolition of Foot Over Bridges at Phul Patty and Armenian Ghat .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1152')}>MC's Circular No. 82 of 2019-20</a>
                        </td>
                        <td >21-Sep-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 82 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1153')}>MC's Circular No. 81 of 2019-20</a>
                        </td>
                        <td >18-Sep-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 81 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1154')}>MC's Circular No. 80 of 2019-20</a>
                        </td>
                        <td >17-Sep-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 80 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1155')}>MC's Circular No. 79 of 2019-20</a>
                        </td>
                        <td >16-Sep-2019</td>
                        <td >
                        Emergent instruments/apparatus arrangements during festive days (most particularly from (October,211d, 2019 to October, 13th , 2019)) 24 x 7 arrangements .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1156')}>MC's Circular No. 78 of 2019-20</a>
                        </td>
                        <td >16-Sep-2019</td>
                        <td >
                        Emergent and superior-level personnel arrangements during festive days (most particularly from October 2"d, 2019 to October 13t\ 2019) .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1157')}>MC's Circular No. 77 of 2019-20	</a>
                        </td>
                        <td >12-Sep-2019</td>
                        <td >
                        Corrigendum of Municipal Commissioner's circular no. 69 of 2019-20 dated 30/08/2019, regarding the concession for booking Community hall of KMC. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1158')}>MC's Circular No. 76 of 2019-20	</a>
                        </td>
                        <td >09-Sep-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 76 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1159')}>MC's Circular No. 75 of 2019-20</a>
                        </td>
                        <td >09-Sep-2019</td>
                        <td >
                        Form 1 and Form 4 to be maintained under WBRTPS Act 2013 and WBRTPS Rules 2013. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1160')}>MC's Circular No. 74 of 2019-20</a>
                        </td>
                        <td >09-Sep-2019</td>
                        <td >
                        Entrusting Sri Amlan Lahiri (EID:20386), Manager, SWM-II(at District III Garage) department, with the duties at Environment and Heritage Department as 'Manager(E&H)' in order to deal with the files relating to the Assessment-Collection and Building. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1161')}>MC's Circular No. 73 of 2019-20</a>
                        </td>
                        <td >04-Sep-2019</td>
                        <td >
                        Guideline on posting including administrative posting of an employee under all K.M.C. Common Cadre Services transferred on promotion for the sake of transparency in public services as per the decisions taken at the meeting of Mayor-in-Council on 24.07.2019 vide M-1-C Item No.MOA - 85.9 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1162')}>MC's Circular No. 72 of 2019-20</a>
                        </td>
                        <td >31-Aug-2019</td>
                        <td >
                        Requirement of fund during the current Financial Year. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1163')}>MC's Circular No. 71 of 2019-20</a>
                        </td>
                        <td >31-Aug-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 71 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1164')}>MC's Circular No. 83 of 2019-20</a>
                        </td>
                        <td >31-Aug-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 70 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1165')}>MC's Circular No. 69 of 2019-20</a>
                        </td>
                        <td >30-Aug-2019</td>
                        <td >
                        Cancellation of Municipal Commissioner's Circular No. 143 of 2018-19 dated 05/03/2019, regarding the concession for booking of Community Hall of KMC. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1166')}>MC's Circular No. 68 of 2019-20</a>
                        </td>
                        <td >29-Aug-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 68 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1167')}>MC's Circular No. 67 of 2019-20</a>
                        </td>
                        <td >29-Aug-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 67 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1168')}>MC's Circular No. 66 of 2019-20</a>
                        </td>
                        <td >29-Aug-2019</td>
                        <td >
                        Formation of Committee to finalize the terms and condition of Agreement of Foot Over Bridge which will be Constructed as CSR activity .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1169')}>Formation of Committee to finalize the terms and condition of Agreement of Foot Over Bridge which will be Constructed as CSR activity .</a>
                        </td>
                        <td >28-Aug-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 65 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1170')}>MC's Circular No. 64 of 2019-20</a>
                        </td>
                        <td >22-Aug-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 64 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1171')}>MC's Circular No. 63 of 2019-20</a>
                        </td>
                        <td >22-Aug-2019</td>
                        <td >
                        Nomination of two Nodal Officers of KMC to address the various matters received from CMRO, Nabanna through UD&MA Department .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1172')}>MC's Circular No. 62 of 2019-20</a>
                        </td>
                        <td >22-Aug-2019</td>
                        <td >
                        Formation of Screening Committee for contractual appointment/engagement in KMC. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1173')}>MC's Circular No. 61 of 2019-20</a>
                        </td>
                        <td >22-Aug-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 61 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1174')}>MC's Circular No. 60 of 2019-20</a>
                        </td>
                        <td >19-Aug-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 60 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1175')}>MC's Circular No. 59 of 2019-20</a>
                        </td>
                        <td >19-Aug-2019</td>
                        <td >
                        Development of Waterbody Management Information System WBMIS) in KMC. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1176')}>MC's Circular No. 58 of 2019-20</a>
                        </td>
                        <td >10-Aug-2019</td>
                        <td >
                        Public Grievance/ Suggestions in KMC area. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1177')}>MC's Circular No. 57 of 2019-20</a>
                        </td>
                        <td >08-Aug-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 57 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1178')}>MC's Circular No. 56 of 2019-20	</a>
                        </td>
                        <td >08-Aug-2019</td>
                        <td >
                        Distribution of saplings of plant in KMC programmes. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1179')}>MC's Circular No. 55 of 2019-20	</a>
                        </td>
                        <td >08-Aug-2019	</td>
                        <td >
                        15 Point Programme of Hon'ble Mayor of KMC for Senior Citizens. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1180')}>MC's Circular No. 53 of 2019-20	</a>
                        </td>
                        <td >07-Aug-2019</td>
                        <td >
                        Illegal stacking of building materials on road and footpath. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1181')}>MC's Circular No. 52 of 2019-20	</a>
                        </td>
                        <td >06-Aug-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 52 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1182')}>MC's Circular No. 51 of 2019-20	</a>
                        </td>
                        <td >02-Aug-2019	</td>
                        <td >
                        Municipal Commissioner's Circular No. 51 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1183')}>MC's Circular No. 50 of 2019-20	</a>
                        </td>
                        <td >01-Aug-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 50 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1184')}>MC's Circular No. 49 of 2019-20</a>
                        </td>
                        <td >30-Jul-2019</td>
                        <td >
                        Negotiation Committee.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1185')}>MC's Circular No. 48 of 2019-20</a>
                        </td>
                        <td >30-Jul-2019</td>
                        <td >
                        Corrigendum of Municipal Commissioner's Circular No. 21 of 2019-20 dated 24.05.2019 regarding Procurement of materials/articles/instruments/services etc compulsorily through GeM w.e.f. 01.06.2019. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1186')}>MC's Circular No. 47 of 2019-20</a>
                        </td>
                        <td >30-Jul-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 47 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1187')}>MC's Circular No. 46 of 2019-20</a>
                        </td>
                        <td >30-Jul-2019</td>
                        <td >
                        Invitation of tender and issuance of work order. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1188')}>MC's Circular No. 45 of 2019-20</a>
                        </td>
                        <td >30-Jul-2019</td>
                        <td >
                        Constitution of a Committee to review the proposals of increase in salary of the contractual and re-employed employees in KMC. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1189')}>MC's Circular No. 44 of 2019-20</a>
                        </td>
                        <td >30-Jul-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 44 of 2019-20 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1190')}>MC's Circular No. 43 of 2019-20</a>
                        </td>
                        <td >29-Jul-2019</td>
                        <td >
                        Action plans for River Rejuvenation. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1191')}>MC's Circular No. 42 of 2019-20</a>
                        </td>
                        <td >25-Jul-2019</td>
                        <td >
                        Addendum to Municipal Commissioner's Circular No. 39 of 2019-2020 dated 19.07.2019 regarding constitution of dedicated Cell for Inspection of Water Bodies. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1192')}>MC's Circular No. 41 of 2019-20</a>
                        </td>
                        <td >25-Jul-2019</td>
                        <td >
                        Corrigendum of Municipal Commissioner's Circular No.38 of 2019-20 regarding e­Tender and procurement through GeM. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1193')}>MC's Circular No. 40 of 2019-20</a>
                        </td>
                        <td >25-Jul-2019</td>
                        <td >
                        Corrigendum of Municipal Commissioner's Circular No.38 of 2019-20 regarding e­Tender and procurement through GeM. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1194')}>MC's Circular No. 41 of 2019-20</a>
                        </td>
                        <td >22-Jul-2019	</td>
                        <td >
                        Guidelines for exemption of fully Government-Aided Schools located within the urisdiction of Kolkata Municipal Corporation from payment of Property Tax. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1195')}>MC's Circular No. 39 of 2019-20</a>
                        </td>
                        <td >19-Jul-2019</td>
                        <td >
                        Constitution of dedicated Cell for Inspection of Water Bodies. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1194')}>MC's Circular No. 38 of 2019-20	</a>
                        </td>
                        <td >19-Jul-2019	</td>
                        <td >
                        e-Tender and Procurement through GeM. .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1195')}>MC's Circular No. 37 of 2019-20	</a>
                        </td>
                        <td >17-Jul-2019</td>
                        <td >
                        Developmental works, civic services and health services of KMC in the bordering areas under the jurisdiction of adjoining municipalities.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1196')}>MC's Circular No. 36 of 2019-20</a>
                        </td>
                        <td >13-Jul-2019	</td>
                        <td >
                        Verification of GeM bills before submission to the Payment Authority (PAO) in GeM
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1197')}>MC's Circular No. 35 of 2019-20</a>
                        </td>
                        <td >11-Jul-2019</td>
                        <td >
                        Appointment through Special Regulation
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1198')}>MC's Circular No. 34 of 2019-20	</a>
                        </td>
                        <td >11-Jul-2019	</td>
                        <td >
                        Collection of GST upon booking of Community Halls of KMC
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1199')}>MC's Circular No. 33 of 2019-20	</a>
                        </td>
                        <td >11-Jul-2019</td>
                        <td >
                        Slum Development Work.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1200')}>MC's Circular No. 32 of 2019-20</a>
                        </td>
                        <td >09-Jul-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 32 of 2019-20.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1202')}>MC's Circular No. 31 of 2019-20	</a>
                        </td>
                        <td >09-Jul-2019	</td>
                        <td >
                        Proper illumination and cleaning of areas around Temples and Temple ghats.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1203')}>MC's Circular No. 30 of 2019-20	</a>
                        </td>
                        <td >04-Jul-2019	</td>
                        <td >
                        Municipal Commissioner's Circular No. 30 of 2019-20.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1204')}>MC's Circular No. 29 of 2019-20	</a>
                        </td>
                        <td >03-Jul-2019</td>
                        <td >
                        Mandatory Presence of Controlling Officers/DGs during Talk to Mayor" Programme.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1205')}>MC's Circular No. 28 of 2019-20	</a>
                        </td>
                        <td >03-Jul-2019</td>
                        <td >
                        Action against complaints received in regard to certain civic amenities.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1206')}>MC's Circular No. 27 of 2019-20	</a>
                        </td>
                        <td >03-Jul-2019	</td>
                        <td >
                        Municipal Commissioner's Circular No. 27 of 2019-20.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1207')}>MC's Circular No. 26 of 2019-20	</a>
                        </td>
                        <td >01-Jul-2019	</td>
                        <td >
                        Framing of Recrui_tment Regulations for the post of 'Director General' under Solid Waste Management department, K.M.C.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1208')}>MC's Circular No. 25 of 2019-20</a>
                        </td>
                        <td >01-Jul-2019	</td>
                        <td >
                        Municipal Commissioner's Circular No. 25 of 2019-20.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1209')}>MC's Circular No. 24 of 2019-20	</a>
                        </td>
                        <td >22-Jun-2019	</td>
                        <td >
                        Municipal Commissioner's Circular No. 24 of 2019-20.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1210')}>MC's Circular No. 23 of 2019-20	</a>
                        </td>
                        <td >13-Jun-2019	</td>
                        <td >
                        Receiving and dispatch files
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1211')}>MC's Circular No. 22 of 2019-20	</a>
                        </td>
                        <td >12-Jun-2019	</td>
                        <td >
                        Municipal Commissioner's Circular No. 22 of 2019-20.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1212')}>MC's Circular No. 21 of 2019-20</a>
                        </td>
                        <td >24-May-2019</td>
                        <td >
                        Procurement of materials/articles/instruments/services etc compulsorily through GeM w.e.f. 01.06.2019.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1213')}>MC's Circular No. 19 of 2019-20</a>
                        </td>
                        <td >11-May-2019</td>
                        <td >
                        Emergency arrangements during the ensuing Nor'wester/rainy season in the next few months of 2019
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1214')}>MC's Circular No. 18 of 2019-20	</a>
                        </td>
                        <td >11-May-2019</td>
                        <td >
                        Generation and Distribution Schedule to be prepared by Water Supply Department of KMC.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1215')}>MC's Circular No. 17 of 2019-20	</a>
                        </td>
                        <td >10-May-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 17 of 2019-20.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1216')}>MC's Circular No. 16 of 2019-20	</a>
                        </td>
                        <td >10-May-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 16 of 2019-20.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1217')}>MC's Circular No. 15 of 2019-20	</a>
                        </td>
                        <td >10-May-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 15 of 2019-20.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1218')}>MC's Circular No. 14 of 2019-20	</a>
                        </td>
                        <td >10-May-2019	</td>
                        <td >
                        Restriction of deployment of workers working under WBUES and UWEP during the summer months..
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1219')}>MC's Circular No. 13 of 2019-20</a>
                        </td>
                        <td >04-May-2019</td>
                        <td >
                        Withdrawal of KMC Officials deployed at Control rooms.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1220')}>MC's Circular No. 12 of 2019-20	</a>
                        </td>
                        <td >02-May-2019</td>
                        <td >
                        Arrangements for combating cyclonic storm "FANI".
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1221')}>MC's Circular No. 11 of 2019-20	</a>
                        </td>
                        <td >02-May-2019	</td>
                        <td >
                        Disbursement of terminal benefits and pension- Expedite thereof.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1222')}>MC's Circular No. 10 of 2019-20	</a>
                        </td>
                        <td >30-April-2019	</td>
                        <td >
                        Civic arrangement to combat Cyclone FANI.".
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1223')}>MC's Circular No. 09 of 2019-20	</a>
                        </td>
                        <td >23-April-2019	</td>
                        <td >
                        Renaming of "PMU Department" as "Environment & Heritage Department".
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1224')}>MC's Circular No. 08 of 2019-20	</a>
                        </td>
                        <td >23-April-2019	</td>
                        <td >
                        Compliance of Hon'ble NGT (Principal Bench) order in connection with Case No. O.A. No. 606 of 2018 O.A. No. 673 of 2018 and O.A. No. 681 of 2018.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1225')}>MC's Circular No. 07 of 2019-20	</a>
                        </td>
                        <td >23-April-2019	</td>
                        <td >
                        Municipal Commissioner's Circular No. 07 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1226')}>MC's Circular No. 06 of 2019-20	</a>
                        </td>
                        <td >18-April-2019	</td>
                        <td >
                        Municipal Commissioner's Circular No. 06 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1227')}>MC's Circular No. 05 of 2019-20	</a>
                        </td>
                        <td >13-April-2019	</td>
                        <td >
                        Publication of list of land schedules.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1228')}>MC's Circular No. 04 of 2019-20	</a>
                        </td>
                        <td >13-April-2019	</td>
                        <td >
                        Disaster Management.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1229')}>MC's Circular No. 03 of 2019-20	</a>
                        </td>
                        <td >13-April-2019	</td>
                        <td >
                        Corrigendum to M.C's Circular no.150 of 2018-19, dated 26.03.2019 regarding Integration of all Collection Centers.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1230')}>MC's Circular No. 02 of 2019-20	</a>
                        </td>
                        <td >05-April-2019	</td>
                        <td >
                        Desilting of existing storm water network at different location within Baranagar Municipal area.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1231')}>MC's Circular No. 01 of 2019-20	</a>
                        </td>
                        <td >02-April-2019	</td>
                        <td >
                        Municipal Commissioner's Circular No. 01 of 2019-20
                        </td>
                      </tr>
                      </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>  )
}

export default MC_Circular_2019_20