import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Health_Fees_Charges  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="img" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
      <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_services_Health_Home'> {t("Health Home")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('458')}> {t("Dengue - Status-Appeal to Citizens")} </a>
                 </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('459')}> {t("Immunisation centers")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('460')}> {t("Health Units")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Malaria_CLinics'> {t("Malaria Clinics")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Maternity_Homes'> {t("Maternity Homes")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_KMC_Dispensaries'> {t("KMC Dispensaries")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Chest_Clinincs'> {t("KMC Chest Clinics")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WaterTestDuplicateReceipt.jsp'> {t("Water Test Reprint e-Receipt")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Desease_control'> {t("Desease Control")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Health_Fees_Charges'> {t("Fees & Charges")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_MTMTB_Hospital'> {t("MTMTB Hospital")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Slaughter_House'> {t("Slaughter House")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Ambulances'> {t("Ambulance")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Antirabies_VACCINATION_Center'> {t("Antirabies Vaccination Centre")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Designation_Activities'>{t("Designation Activity")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_KMDA_Health_Projects'> {t("KMDA Health Projects")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BurningGhatStatus.jsp'> {t("Burning Ghat Status")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Land_Records_Burial_Grounds'>{t("Land Records Of Burial Grounds")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Dept'> {t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('461')}> {t("Information on FSS Act-License / Registration in KMC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('462')}> {t("Locations of Slaughtering of Animals on the Occasion of ID-UD-ZOHA(BAKRI-ID) ON 10.07.2022, 11.07.2022 & 12.07.2022")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('469')}> {t("Swasthya Bandhab Sharad Samman")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('470')}> {t("Details of UPHC")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('466')}> {t("List of Enlisted Agencies for Carrying Covid Bodies")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('471')}> {t("Immersion training by ECHO India for KMC Health staff")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('472')}> {t("KMC Participation and Experiences at ECHO India's IPC Dissemination Event : Mumbai")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'>
                    {t("Application/Payment for Potable Water Analysis")}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Schedule of Fees and Charges for the year 2023 - 2024")} </h3>
              </div>
              <div className="mayor-table health-dept-table1">
                <br />
                <table
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table"
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"
                    >
                      <td width="5%" className="text-white tble-brder-left">
                        &nbsp;
                      </td>
                      <td width="65%" valign="middle" className="text-white">
                        <b>{t("Item")}</b>
                      </td>
                      <td width="15%" className="text-white">
                        <b>{t("Figures in Rupees")}</b>
                      </td>
                      <td width="15%" />
                    </tr>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      style={{ color: "white", fontWeight: "bold" }}
                      align="center"
                      valign="top"
                    >
                      <td colSpan={4} className="text-white tble-brder-left">
                        I. {t("Birth or Death Registration Certificate")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td colSpan={4}>
                        1. {t("")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>
                        a) {t("Any birth or death event of which information is given within 21 days of occurrence")}
                      </td>
                      <td align="center">{t("Free of cost")}</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>b){t("Any birth or death event of which information is given after 21 days but within 30 days of occurrence")}</td>
                      <td align="center">1/- (Late Fee)</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>c){t("Any birth or death event of which information is given after 30 days but within one year of occurrence")}</td>
                      <td align="center">2/- (Late Fee)</td>
                      <td>{t("")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>d) {t("Any birth or death event which has not been registered within one year of its occurrence")}</td>
                      <td align="center">Free</td>
                      <td>
                        {t("")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>e){t("Searching fee per year for non-entry certificate (when definite date and place are not given)")}</td>
                      <td align="center">300.00</td>
                      <td>{t("")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>
                        f) {t("Certificate of non-entry (in-form) in respect of one person")}
                      </td>
                      <td align="center">500.00</td>
                      <td>per copy</td>
                    </tr>
                    
                    <tr bgcolor="#fff">
                      <td colSpan={4}>&nbsp;</td>
                    </tr>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      style={{ color: "white" }}
                      align="center"
                      valign="top"
                    >
                      <td colSpan={4} className="text-white tble-brder-left">
                        II. {t("Fees under Food Safety & Standards Act, 2006 (On line Payment)")}
                        <br />
                      </td>
                    </tr>
                    <tr align="left" valign="top" tabIndex={0}>
                      <td colSpan={2}>
                        <span tabIndex={0}>a) {t("Fees for Registration")} </span>
                        <br />
                        <span tabIndex={0}>
                          b) {t("Fees for Licence issued by State Licensing Authority")}
                        </span>
                        <br />
                        &nbsp;&nbsp;&nbsp;
                        <span tabIndex={0}>(1) {t("Manufacture/Miller")}</span>
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span tabIndex={0}>
                          
                          i) {t("Above 1MT per day Production or 10,001 to 50,000 LPD")} <br />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {t("of milk or 501 to 2500 MT of milk solids per annum")}
                        </span>
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span tabIndex={0}>
                          
                          ii) {t("Below 1 MT of Production or 501 to 10,000 LPD of milk ")}<br />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {t("or 2.5 MT to 500 MT of milk solids per annum")}
                        </span>
                        <br />
                        &nbsp;&nbsp;&nbsp;
                        <span tabIndex={0}>2) {t("Hotels - 3 Star and above")}</span>
                        <br />
                        &nbsp;&nbsp;&nbsp;
                        <span tabIndex={0}>
                          3) {t("All Food Service providers including restaurants/boarding houses")}, <br />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {t("clubs etc. serving food, Canteens(Schools, Colleges, Office")},
                          <br />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("institutions)")},
                          {t("Caterers, Banquet Halls with food catering arrangements")}, <br />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("food")}
                          {t("vendor like dabba wallas etc")}.
                        </span>
                        <br />
                        &nbsp;&nbsp;&nbsp;
                        <span tabIndex={0}>
                          4) {t("Any other Food Business Operator")}
                          <br />
                        </span>
                      </td>
                      <td colSpan={2} tabIndex={0} align="center">
                        {t("100/-per annum")}
                        <br />
                        <br />
                        <br />
                        {t("5000/-per annum")}
                        <br />
                        <br />
                        {t("3000/-per annum")}
                        <br />
                        <br />
                        {t("5000/-per annum")}
                        <br />
                        <br />
                        {t("2000/-per annum")}
                        <br />
                        <br />
                        <br />
                        {t("2000/-per annum")}&nbsp;
                      </td>
                    </tr>
                    <tr align="left" valign="top" tabIndex={0}>
                      <td align="center">Note</td>
                      <td colSpan={3}>
                        {t("For duplicate copy of Registration Certificate / License 10% of the applicable Licence fee is to be paid")}.
                      </td>
                    </tr>
                    <tr bgcolor="#fff">
                      <td colSpan={4}>&nbsp;</td>
                    </tr>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      style={{ color: "white", fontWeight: "bold" }}
                      align="center"
                      valign="top"
                    >
                      <td colSpan={4} className="text-white tble-brder-left">
                        III. {t("Slaughter House")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">1.</td>
                      <td colSpan={3}>{t("Slaughtering Fees")} :</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>a) {t("Buffalo")}</td>
                      <td align="center">70.00</td>
                      <td>{t("Per Animal")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>b) {t("Cow and Bullock")}</td>
                      <td align="center">70.00</td>
                      <td>{t("Per Animal")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>c) {t("Sheep")}</td>
                      <td align="center">20.00</td>
                      <td>{t("Per Animal")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>d) Goat</td>
                      <td align="center">20.00</td>
                      <td>{t("Per Animal")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>e) Pig (Below 20 kg)</td>
                      <td align="center">50.00</td>
                      <td>{t("Per Animal")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>f) Pig (21-45 kg) </td>
                      <td align="center">80.00</td>
                      <td>{t("Per Animal")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>f) Pig (above 45 kg) </td>
                      <td align="center">100.00</td>
                      <td>{t("Per Animal")}</td>
                    </tr>

                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">2.</td>
                      <td colSpan={3}>{t("Fees for Sale of Skin")} :</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>a) {t("Buffalo, Cow and Bullock")}</td>
                      <td align="center">30.00</td>
                      <td>{t("Per Animal")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>b) {t("Sheep and Goat")}</td>
                      <td align="center">20.00</td>
                      <td>{t("Per Animal")}</td>
                    </tr>

                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">3.</td>
                      <td>{t("Permit Fees for purchase")}</td>
                      <td align="center"> </td>
                      <td>{t("")}</td>
                    </tr>                    
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"></td>
                      <td>{t("a) Hoof & Horn")}</td>
                      <td align="center"> 4000/- </td>
                      <td>{t("Per annum")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"></td>
                      <td>{t("b) Hides")}</td>
                      <td align="center"> 2000/- </td>
                      <td>{t("Per annum")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"></td>
                      <td>{t("c) Offals")}</td>
                      <td align="center"> 700/- </td>
                      <td>{t("Per annum")}</td>
                    </tr>

                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">4.</td>
                      <td>{t("Pen rent for Pig")}</td>
                      <td align="center"> </td>
                      <td>{t(" ")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"></td>
                      <td>{t("a) Small Pen")}</td>
                      <td align="center"> 10/- </td>
                      <td>{t("Per animal per day ")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"></td>
                      <td>{t("b) Large Pen")}</td>
                      <td align="center"> 20/- </td>
                      <td>{t("Per animal per day ")}</td>
                    </tr>


                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">5.</td>
                      <td>
                        {t("Stallage fees")}
                      </td>
                      <td align="center"> </td>
                      <td>{t("")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> </td>
                      <td>  {t("a) Cow, Bullock & Buffalo")}  </td>
                      <td align="center"> 30/- </td>
                      <td>{t("Per animal")}</td>
                    </tr>


                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">6.</td>
                      <td>{t("Slaughtering Permit")}</td>
                      <td align="center"> </td>
                      <td>{t(" ")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"></td>
                      <td>{t("a) Cattle")}</td>
                      <td align="center">600/- </td>
                      <td>{t("Per annum")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"></td>
                      <td>{t("b) Sheep & Goat")}</td>
                      <td align="center">350/- </td>
                      <td>{t("Per annum")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"></td>
                      <td>{t("c) Pig")}</td>
                      <td align="center">700/- </td>
                      <td>{t("Per annum")}</td>
                    </tr>


                    <tr bgcolor="#fff">
                      <td colSpan={4}>&nbsp;</td>
                    </tr>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      style={{ color: "white", fontWeight: "bold" }}
                      align="center"
                      valign="top"
                    >
                      <td colSpan={4} className="text-white tble-brder-left">
                        IV. {t(" Rate of Dhobikhana")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">1.</td>
                      <td>{t("Stone rent")}</td>
                      <td colSpan={2} align="center">{t("200/- per month")}</td>                     
                    </tr>

                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">2.</td>
                      <td>{t("Servants licence fee")}</td>
                      <td colSpan={2} align="center">{t("50/- per month")}</td>                     
                    </tr>

                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">3.</td>
                      <td>{t("Rent of Coal Shop")}</td>
                      <td colSpan={2} align="center">{t("250/- per month")}</td>
                    </tr>

                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">4.</td>
                      <td>{t("Rent of soap & soda shop")}</td>
                      <td colSpan={2} align="center">{t("250/- per month")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">5.</td>
                      <td>{t("Rent of tea stall")}</td>
                      <td colSpan={2} align="center">{t("120/- per month")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">6.</td>
                      <td>{t("Rent of Pan & bidi Shop")}</td>
                      <td colSpan={2} align="center">{t("100/- per month")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">7.</td>
                      <td>{t("Penalty")}</td>
                      <td colSpan={2} align="center">{t("30/- per month")}</td>
                    </tr>

                    <tr bgcolor="#fff">
                      <td colSpan={4}>&nbsp;</td>
                    </tr>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      style={{ color: "white", fontWeight: "bold" }}
                      align="center"
                      valign="top"
                    >
                      <td colSpan={4} className="text-white tble-brder-left">
                        V. {t("Burial Ground (Hindu & Muslim)")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">1.</td>
                      <td colSpan={3}>{t("Burial fees")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> </td> 
                      <td>{t("a) Fees for dead body above 12 yrs. of age")}</td>
                      <td colSpan={2}> 100/- </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> </td> 
                      <td>{t("b) Fees for dead body upto 12 yrs. of age")}</td>
                      <td colSpan={2}> 50/- </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">2.</td>
                      <td colSpan={2}>{t("Masonry Grave Charges")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> </td>
                      <td>{t("a) Fees for structural maintenance of old masonry grave ")}</td>
                      <td colSpan={2}> 1000/- </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> </td>
                      <td>{t("b) Permission for const. of new masonry grave")}</td>
                      <td> 5000/- </td>
                      <td> Depending on size- </td>
                    </tr>
                    <tr bgcolor="#fff">
                      <td colSpan={4}>&nbsp;</td>
                    </tr>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      style={{ color: "white", fontWeight: "bold" }}
                      align="center"
                      valign="top"
                    >
                      <td colSpan={4} className="text-white tble-brder-left">
                        VI. {t("Cremation of dead bodies")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">1.</td>
                      <td>{t("In Electric Crematoriums")}</td>                    
                      <td colSpan={2}> </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> </td>                   
                      <td>{t("a) Up to 12 yrs. of age")}</td>                    
                      <td> 100/- </td>
                      <td rowSpan={2}> (Addl. Rs. 30/- for pollution control devices wherever applicable) </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                       <td align="center"> </td>                      
                      <td>{t("b) Above 12 yrs. of age")}</td>                    
                      <td colSpan={2}> 220/- </td>                    
                    </tr>

                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">2.</td>
                      <td>{t("In Wooden Crematorium")}</td>                    
                      <td colSpan={2}>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> </td>
                      <td>{t("a) Up to 12 yrs. of age")}</td>                    
                      <td colSpan={2}> 20/- </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> </td>
                      <td>{t("b) Above 12 yrs. of age")}</td>                    
                      <td colSpan={2}> 220/- </td>
                    </tr>

                    
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      style={{ color: "white", fontWeight: "bold" }}
                      align="center"
                      valign="top"
                    >
                      <td colSpan={2} className="text-white tble-brder-left">
                      VII. {t("Preservation of Dead Body in Mortuary at Topsia Hindu Burial Ground (Peace World)")}
                      </td>
                      <td align="center" className="text-white"> 1000/- </td>
                      <td colSpan={2} className="text-white">{t("per body per 24 hours")}</td>
                    </tr>

                    <tr bgcolor="#fff">
                      <td colSpan={2}>&nbsp;</td>
                    </tr>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      style={{ color: "white", fontWeight: "bold" }}
                      align="center"
                      valign="top"
                    >
                      <td colSpan={4} className="text-white tble-brder-left">
                       VIII. {t("Motor Ambulance Service")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">1.</td>
                      <td>
                        {t("Fees for motor ambulance services in the case of patients conveyed within the jurisdiction of the")}:
                        <br />
                        {t("Kolkata Municipal Corporation:For carrying patients to and from Hospitals, Nursing Homes and other places for treatment etc.")}
                      </td>
                      <td align="center">200.00</td>
                      <td>{t("Per trip")}</td>
                    </tr>

                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">2.</td>
                      <td>
                        {t("In the case of patients conveyed outside the jurisdiction of the Kolkata Municipal Corporation to Netaji Subhas Chandra Bose International Airport or To Howrah Station")}
                      </td>
                      <td align="center">10/-</td>
                      <td>{t("Per KM")}.</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">Note :</td>
                      <td colSpan={3}>
                        {t("If more than one patient is conveyed by the same Ambulance in the same trip to the same Hospital, or to the same place only one charge will be made. Mayor / Deputy Mayor / MIC / Mpl. Commissioner / Spl. Mpl. Commissioner / Jt. Mpl. Commissioner / CMHO will have the discretion to reduce or remit the charges in special cases, but the permission of the Mayor / Deputy Mayor / MIC / Mpl. Commissioner / Spl. Mpl. Commissioner / Jt. Mpl. Commissioner / CMHO is to be obtained in writing by the party prior to the use of the ambulances and the same to be handed over to the driver who will carry the patient and the driver will submit it in the ambulance office on the following day or post facto approval is to be obtained.")}.
                      </td>
                    </tr>
                    <tr bgcolor="#fff">
                      <td colSpan={4}>&nbsp;</td>
                    </tr>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      style={{ color: "white", fontWeight: "bold" }}
                      align="center"
                      valign="top"
                    >
                      <td colSpan={4} className="text-white tble-brder-left">
                      IX. {t("Vector Control Service")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">1.</td>
                      <td colSpan={3}>
                        {t("Cleaning edges and removal of water-hyacinth for Tank, Dobas & Jheels")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>a) {t("For cleaning the edges")}</td>
                      <td align="center">60.00</td>
                      <td> {t("per 100 sq. ft")}.</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>
                        b) {t("For cleaning water-hyacinth or other aquatic vegetations")}
                      </td>
                      <td align="center">60.00</td>
                      <td> {t("per 100 sq. ft")}.</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">2.</td>
                      <td colSpan={3}>
                        {t("Treatment of mosquito breeding areas with larvicides (including cost of labour & materials)")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>a) {t("For Tank, Doba, Jheels, Ditches, Drains")}</td>
                      <td align="center">120.00</td>
                      <td> {t("per 100 sq. ft")}.</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>
                        b) {t("For Masonry Tanks, Cisterns, Receptacles (Serviceable or unserviceable)")}
                      </td>
                      <td align="center">25.00</td>
                      <td> {t("per day per application")}.</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>
                        c) {t("For ditches and low lands, choked service drains (private)")}
                      </td>
                      <td align="center">25.00</td>
                      <td> {t("per 100 sq. ft./running feet")}.</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">3.</td>
                      <td colSpan={3}>
                        {t("Charges for removing mosquitogenic condition at construction sites by promoters or developers or from the premises of big private institutions")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>{t("Type of chemical used")}</td>
                      <td align="center">{t("cost per Sq.m./per day")}</td>
                      <td align="center">&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>a) {t("Bti-WP")}</td>
                      <td align="center">Rs. 3/-</td>
                      <td align="center">&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">&nbsp;</td>
                      <td>b) {t("Temephos 50% EC")}</td>
                      <td align="center">Rs. 2.5/-</td>
                      <td align="center">&nbsp;</td>
                    </tr>
                    <tr bgcolor="#fff">
                      <td colSpan={4}>&nbsp;</td>
                    </tr>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      style={{ color: "white", fontWeight: "bold" }}
                      align="center"
                      valign="top"
                    >
                      <td colSpan={4} className="text-white tble-brder-left">
                        X. {t("Maternity Home Service")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">1.</td>
                      <td>{t("Admission Charges for each patient")}</td>
                      <td align="center">Nil</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      style={{ color: "white", fontWeight: "bold" }}
                      align="center"
                      valign="top"
                    >
                      <td colSpan={4} className="text-white tble-brder-left">
                        XI. {t("Food And Water Sanitation")}
                      </td>
                    </tr>
                    {/* <tr bgcolor="#302ba0" style="color:white; font-weight:bold;" align="center" valign="top"> */}
                    <tr tabIndex={0}>
                      <td align="left" colSpan={4}>
                        {t("Portable Water analysis (Private)")}
                        <br />
                        {t("Rate for Portable Water Tests")} :-
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td rowSpan={6} align="center">
                        a
                      </td>
                      <td>i) {t("Alkalinity")}</td>
                      <td align="center" rowSpan={6} colSpan={2}>
                        <br /> <br />
                        <br />
                        {t("Rs 600/")}-
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>ii) {t("Salinity")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>iii) {t("TH, Cah & MgH")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>iv) {t("Sulphate")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>v) {t("PH & Turbidity")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>vi) {t("TDS")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">b</td>
                      <td>{t("Arsenic")}</td>
                      <td align="center" colSpan={2}>
                        Rs. 900/-
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">c</td>
                      <td>{t("Iron")}</td>
                      <td align="center" colSpan={2}>
                        Rs. 600/-
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">d</td>
                      <td>{t("Bacteriological Test")}</td>
                      <td align="center" colSpan={2}>
                        Rs. 600/-
                      </td>
                    </tr>
                    <tr bgcolor="#fff">
                      <td colSpan={4}>&nbsp;</td>
                    </tr>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      style={{ color: "white", fontWeight: "bold" }}
                      align="center"
                      valign="top"
                    >
                      <td colSpan={4} className="text-white tble-brder-left">
                        XII. {t("Hearse Van Service")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">a.</td>
                      <td>
                        {t("(From Body Loading Point to Releasing Point)")}
                        <br />
                        {t("Maximum 2 Hours")}
                      </td>
                      <td align="center">Rs. 200/-</td>
                      <td> &nbsp; </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">b.</td>
                      <td>{t("For Extra Haltage Charge per Hour")}</td>
                      <td align="center">Rs. 50/-</td>
                      <td> &nbsp; </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Health_Fees_Charges