import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Health_Designation_Activities  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
<img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass">  </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
      <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link  fontSizeClass" to='/Health_services_Health_Home'> {t("Health Home")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('458')}> {t("Dengue - Status-Appeal to Citizens")} </a>
                 </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('459')}> {t("Immunisation centers")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('460')}> {t("Health Units")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Malaria_CLinics'> {t("Malaria Clinics")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Maternity_Homes'> {t("Maternity Homes")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_KMC_Dispensaries'> {t("KMC Dispensaries")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Chest_Clinincs'> {t("KMC Chest Clinics")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WaterTestDuplicateReceipt.jsp'> {t("Water Test Reprint e-Receipt")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Desease_control'> {t("Desease Control")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Fees_Charges'> {t("Fees & Charges")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_MTMTB_Hospital'> {t("MTMTB Hospital")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Slaughter_House'> {t("Slaughter House")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Ambulances'> {t("Ambulance")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Antirabies_VACCINATION_Center'> {t("Antirabies Vaccination Centre")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Health_Designation_Activities'>{t("Designation Activity")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_KMDA_Health_Projects'> {t("KMDA Health Projects")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BurningGhatStatus.jsp'> {t("Burning Ghat Status")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Land_Records_Burial_Grounds'>{t("Land Records Of Burial Grounds")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Dept'> {t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('461')}> {t("Information on FSS Act-License / Registration in KMC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('462')}> {t("Locations of Slaughtering of Animals on the Occasion of ID-UD-ZOHA(BAKRI-ID) ON 10.07.2022, 11.07.2022 & 12.07.2022")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('469')}> {t("Swasthya Bandhab Sharad Samman")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('470')}> {t("Details of UPHC")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('466')}> {t("List of Enlisted Agencies for Carrying Covid Bodies")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('471')}> {t("Immersion training by ECHO India for KMC Health staff")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('472')}> {t("KMC Participation and Experiences at ECHO India's IPC Dissemination Event : Mumbai")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'>
                    {t("Application/Payment for Potable Water Analysis")}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Designation Activity")} </h3>
              </div>
              <div className="mayor-table health-dept-table1">
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"
                    >
                      <td width="30%" className="text-white tble-brder-left">
                        {t("Designation")}
                      </td>
                      <td width="70%" className="text-white tble-brder-right">
                        {t("Activity")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>{t("Public analyst (positional requirment)")}</td>
                      <td>
                        {t("Preliminary checking of sample")}
                        <br />
                        {t("Sample allotment to assistants")}
                        <br />
                        {t("Initial testing of colour, coarseness, odour testing")}
                        <br />
                        {t("Providing support during detailed testing of sample")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>
                        {t("Deputy analyst /asst analyst / mhaii/ lab junior / gda i")}
                      </td>
                      <td>
                        {t("Checking seal and sample condition")} <br />
                        {t("Check local health authority slip")}
                        <br />
                        {t("Check memorandum no. with l.h.a and accept the sample for further testing")}
                        <br />
                        {t("Assigning lab registration number to sample")}
                        <br />
                        {t("Record of fi name, sl. no. of lha slip, date and time of recording")}
                        <br />
                        {t("Re – entering the lab registration no in the peon book")}
                        <br />
                        {t("Detailed testing of sample and observations")}
                        <br />
                        {t("Compilation of inference in the pa register Preparation of the report sheet")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>
                        {t("Supervisor /ha/uda")}
                        <br />
                        {t("Typist")}
                        <br />
                        {t("Deputy analyst")}
                      </td>
                      <td>
                        {t("Checking of typed report")}
                        <br />
                        {t("personnel function")} <br /> {t("no of reports typed")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>{t("Asst. analyst/mhaii/lab junior")}</td>
                      <td>
                        {t("Sample collection")}
                        <br />
                        {t("Testing of sample")}
                        <br />
                       {t("Bacteriological testing of sample – testing through membrane filter technique")}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <table
                  width="94%"
                  border={0}
                  cellSpacing={2}
                  cellPadding={2}
                  className="table table-bordered officials"
                  tabIndex={0}
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"
                    >
                      <td width="15%" className="text-white tble-brder-left">
                        {t("Designation")}
                      </td>
                      <td width="26%" className="text-white">
                        {t("Activities")}
                      </td>
                      <td width="10%" className="text-white">
                        {t("Frequency")}
                      </td>
                      <td width="10%" className="text-white">
                        {t("Volume")}
                      </td>
                      <td width="10%" className="text-white">
                        {t("Time taken (min)")}
                      </td>
                      <td width="10%" className="text-white">
                        {t("Total hours")}
                      </td>
                      <td width="49%" className="text-white tble-brder-right">
                        {t("Remarks")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>
                        {t("Analyst/asst analyst/mhaii,")} <br />
                        {t("gdai/ii")}
                      </td>
                      <td>
                        {t("Collection of daily samples of water, sand, gravel, alumn etc")}
                      </td>
                      <td>{t("Daily")} </td>
                      <td>&nbsp; </td>
                      <td>{t("540 Minuts Per Day")}</td>
                      <td>{t("Yearly 1827")} </td>
                      <td>
                        {t("All kinds of daily samples collection was done by 3 persons for 3 hours each.")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>&nbsp;</td>
                      <td>
                        {t("Testing of 40 water samples on basis of 75 parameters")}
                      </td>
                      <td>{t("Yearly")} </td>
                      <td>8120 </td>
                      <td>6 </td>
                      <td>812 </td>
                      <td>
                        {t("4 hours to test 40 river water samples daily. hence, for each sample it takes about 6 minutes")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>&nbsp;</td>
                      <td>{t("Testing of river water sample")} </td>
                      <td>{t("Yearly")} </td>
                      <td>680 </td>
                      <td>45 </td>
                      <td>510 </td>
                      <td>
                        {t("4 samples daily for a period of 8 months and 2 times daily for a period of 4 months. time taken to analyse 4 sample are 3 hours, so time taken per sample is 45 min")}.
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>&nbsp;</td>
                      <td>{t("Sand Testing")} </td>
                      <td>{t("Yearly")} </td>
                      <td>1624 </td>
                      <td>22.5 </td>
                      <td>609 </td>
                      <td>
                        {t("Test of medium / course sand, daily test of 8 samples – takes 3 hours to test, per sample it takes 22.5 minutes")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>&nbsp;</td>
                      <td>{t("Gravel testing")}</td>
                      <td>{t("Yearly")} </td>
                      <td>1015 </td>
                      <td>12 </td>
                      <td>203 </td>
                      <td>
                        {t("Test of 415 mm and 1525 mm garavel – 10 samples for every alternate days. time taken for 10 samples is 2 hours, so per sample time is 12 minutes")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>&nbsp;</td>
                      <td>{t("Determination of alumn and chlorine doses")}</td>
                      <td>{t("Yearly")} </td>
                      <td>1015 </td>
                      <td>12 </td>
                      <td>203 </td>
                      <td>
                        {t("Regular testing of raw and liquid alum of 5 samples. time taken to analyse 5 sample is 60 minutes")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>&nbsp;</td>
                      <td>
                        {t("Bacteriological/microbiological testing of drinking water")}
                      </td>
                      <td>{t("Yearly")} </td>
                      <td>406 </td>
                      <td>30 </td>
                      <td>203 </td>
                      <td>
                        {t("Daily testing of 2 water samples through filter membrane technique time taken is 30 minutes per sample")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>&nbsp;</td>
                      <td>
                        {t("Weekly river water sample testing on basis of 30 parameters")}
                      </td>
                      <td>{t("Yearly")} </td>
                      <td>185 </td>
                      <td>24 </td>
                      <td>73.8 </td>
                      <td>
                        {t("Weekly testing of 5 samples of river, settled water etc. 5 samples take 2 hours to test, so time taken per sample is 24 min")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>&nbsp;</td>
                      <td>{t("Report writing of all samples")}</td>
                      <td>{t("Yearly")} </td>
                      <td>1255 </td>
                      <td>30 </td>
                      <td>628 </td>
                      <td>{t("6 Reports daily and weekly one report")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>&nbsp;</td>
                      <td>
                        <b>
                          {t("Total Hours")}
                          <b />
                        </b>
                      </td>
                      <td>&nbsp; </td>
                      <td>&nbsp; </td>
                      <td>&nbsp; </td>
                      <td>
                        <b>5068 </b>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Health_Designation_Activities