import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Central_records_fee  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
 
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
        <div className="container"> <div className="section-main-heading2"></div></div>             
      </div>       	 		 
    </section>	  
    {/* End Slider */}
   
    <section className="section inner-pad1">
      <div className="container " >
          <div className="row">
          <div className="col-lg-3">
            <div className="site-menu ">           
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Central_records_home'> {t("Central Records Home")} </Link> </li>
                  <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Central_records_fee'> {t("Fees")} </Link> </li>
               </ul>
              </nav>	   
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
            <div className="row">
            <div className="mayor-table m-ta-2">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <table>
                          <tbody>
                            <tr
                              bgcolor="302ba0"
                              tabIndex={0}
                              align="left"
                              style={{ height: 38 }}
                              valign="middle"
                            >
                              <td>
                                1.
                              </td>
                              <td colSpan={2} style={{ color: "#fff" }}>
                                {t("Searching Fees of Assessment Register From Record Dept")}
                              </td>
                              <td
                                colSpan={2}
                                align="center"
                                style={{ color: "#fff" }}
                              >
                                {t("Figures in Rs")}.
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">A)</td>
                              <td colSpan={2}>{t("For Non-Bustee")}</td>
                              <td colSpan={2}>&nbsp;</td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="center">&nbsp;</td>
                              <td width="5%" align="center">
                                (i)
                              </td>
                              <td width="50%" align="left">
                                {t("having annual valuation upto Rs. 18,000")} /-
                              </td>
                              <td colSpan={2} align="center">
                                {t("1,250.00 Per Assessee")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="center">&nbsp;</td>
                              <td align="center">(ii)</td>
                              <td align="left">
                                {t("having Annual valuation above Rs 18,000/-")}
                              </td>
                              <td colSpan={2} align="center">
                                {t("2,500.00 Per Assessee")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">B)</td>
                              <td colSpan={2}>
                                {t("For Bustee/Hut/Colony Irrespective of A.V")}.
                              </td>
                              <td align="center" colSpan={2}>
                                {t("150.00 Per Assessee")}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5} bgcolor="FFFFFF">
                                &nbsp;
                              </td>
                            </tr>
                            <tr
                              bgcolor="302ba0"
                              tabIndex={0}
                              style={{ height: 38 }}
                              valign="middle"
                            >
                              <td align="center" style={{ color: "#fff" }}>
                                2.
                              </td>
                              <td colSpan={2} style={{ color: "#fff" }}>
                                {t("Updated Certified copy of Assessment Book")}
                              </td>
                              <td
                                colSpan={2}
                                align="center"
                                style={{ color: "#fff" }}
                              >
                                {t("Figures in Rs.")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">A)</td>
                              <td colSpan={2}>{t("For Non-Bustee")}</td>
                              <td colSpan={2}>&nbsp;</td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td>&nbsp;</td>
                              <td align="center">(i)</td>
                              <td>{t("having annual valuation upto Rs. 12,000/-")}</td>
                              <td colSpan={2} align="center">
                                {t("650.00 Per Assessee")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td>&nbsp;</td>
                              <td align="center">(ii)</td>
                              <td>
                                {t("having annual valuation above Rs. 12,000/- but up to Rs. 18,000/-")}
                              </td>
                              <td colSpan={2} align="center">
                                {t("1,250.00 Per Assessee")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td>&nbsp;</td>
                              <td align="center">(iii)</td>
                              <td>
                                {t("having annual valuation above Rs. 18,000/-")}
                              </td>
                              <td colSpan={2} align="center">
                                {t("2,500.00 Per Assessee")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">B)</td>
                              <td colSpan={2}>
                                {t("For Bustee/Hut/Colony Irrespective of A.V.")}
                              </td>
                              <td align="center" colSpan={2}>
                                {t("150.00 Per Assessee")}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5}>&nbsp;</td>
                            </tr>
                            <tr
                              bgcolor="302ba0"
                              tabIndex={0}
                              style={{ height: 38 }}
                              valign="middle"
                            >
                              <td align="center" style={{ color: "#fff" }}>
                                3.
                              </td>
                              <td colSpan={2} style={{ color: "#fff" }}>
                                {t("General Search")}
                              </td>
                              <td
                                colSpan={2}
                                style={{ color: "#fff" }}
                                align="center"
                              >
                                {t("Figures in Rs")}.
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">A)</td>
                              <td colSpan={2}>{t("For each file")}</td>
                              <td colSpan={2} align="center">
                                250.00
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">B)</td>
                              <td colSpan={2}>
                                {t("For each Meeting proceedings in respect of one item")}
                              </td>
                              <td colSpan={2} align="center">
                                250.00
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">C)</td>
                              <td colSpan={2}>
                                {t("For certified copies having 100 words or part thereof")}
                              </td>
                              <td colSpan={2} align="center">
                                250.00
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">D)</td>
                              <td colSpan={2}>
                                {t("For Expeditious fees for above cases")}
                              </td>
                              <td colSpan={2} align="center">
                                250.00
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">E)</td>
                              <td colSpan={2}>
                                {t("For Expeditious fees for cases prior to 1965")}
                              </td>
                              <td colSpan={2} align="center">
                                400.00
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5}>&nbsp;</td>
                            </tr>
                            <tr
                              bgcolor="302ba0"
                              tabIndex={0}
                              style={{ height: 38 }}
                              valign="middle"
                            >
                              <td align="center" style={{ color: "#fff" }}>
                                4.
                              </td>
                              <td colSpan={2} style={{ color: "#fff" }}>
                                {t("Fees for searching Trade & Profession Demand Register")}
                              </td>
                              <td
                                colSpan={2}
                                align="center"
                                style={{ color: "#fff" }}
                              >
                                {t("Figures in Rs")}.
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">A)</td>
                              <td colSpan={2}>
                                {t("Fees for searching Trade & Profession Demand Register in respect of one entry")}
                              </td>
                              <td colSpan={2} align="center">
                                {t("150.00 Per Assessee")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">B)</td>
                              <td colSpan={2}>
                                {t("Fees for certified extract in respect of one entry (with searching fees, ordinary delivery)")}
                              </td>
                              <td colSpan={2} align="center">
                                {t("200.00 Per Assessee")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">C)</td>
                              <td colSpan={2}>
                                {t("Fees for certified extract in respect of one entry (with searching fees, urgent delivery)")}
                              </td>
                              <td colSpan={2} align="center">
                                {t("400.00 Per Assessee")}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5}>&nbsp;</td>
                            </tr>
                            <tr
                              bgcolor="302ba0"
                              tabIndex={0}
                              style={{ height: 38 }}
                              valign="middle"
                            >
                              <td align="center" style={{ color: "#fff" }}>
                                5.
                              </td>
                              <td colSpan={2} style={{ color: "#fff" }}>
                                {t("Fees for Inspection of Building Sanctioned Plan")}
                              </td>
                              <td
                                colSpan={2}
                                style={{ color: "#fff" }}
                                align="center"
                              >
                                {t("Figures in Rs.")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">A)</td>
                              <td colSpan={2}>
                                {t("Inspection by owner in respect of sanctioned plan")}
                              </td>
                              <td colSpan={2} align="center">
                                {t("650.00 Per Assessee")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">B)</td>
                              <td colSpan={2}>
                                {t("Inspection by other than owner for one sanction plan (permission to be obtained from the office of D.G.Bldg.)")}
                              </td>
                              <td colSpan={2} align="center">
                                {t("1,250.00 Per Assessee")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">C)</td>
                              <td colSpan={2}>
                                {t("Inspection by owner in respect of sanctioned plan with paper")}
                              </td>
                              <td colSpan={2} align="center">
                                {t("750.00 Per Assessee")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">D)</td>
                              <td colSpan={2}>
                                {t("Inspection by other than owner for one sanctioned plan with paper")}
                              </td>
                              <td colSpan={2} align="center">
                                {t("1,500.00 Per Assessee")}
                              </td>
                            </tr>
                            <tr align="center" valign="top" tabIndex={0}>
                              <td colSpan={5}>
                                <i>{t("Note")} : </i>{t("Rs. 100/- to be paid extra as searching fee")}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5}>&nbsp;</td>
                            </tr>
                            <tr
                              bgcolor="302ba0"
                              tabIndex={0}
                              style={{ height: 38 }}
                              valign="middle"
                            >
                              <td align="center" style={{ color: "#fff" }}>
                                6.
                              </td>
                              <td colSpan={2} style={{ color: "#fff" }}>
                                {t("Form No. (Name)")}
                              </td>
                              <td
                                width="20%"
                                style={{ color: "#fff" }}
                                align="center"
                              >
                                {t("Rate Per Form")}
                              </td>
                              <td
                                width="15%"
                                style={{ color: "#fff" }}
                                align="center"
                              >
                                {t("Remarks")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">A)</td>
                              <td colSpan={2}>{t("A-42 (Mutation Form)")}</td>
                              <td align="center">{t("Free")}</td>
                              <td rowSpan={5} valign="middle">
                                {t("Can be downloaded from Website")}
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">B)</td>
                              <td colSpan={2}>A-75</td>
                              <td align="center">{t("Free")}</td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">C)</td>
                              <td colSpan={2}>{t("Contractors Bill Forms")}</td>
                              <td align="center">{t("Free")}</td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">D)</td>
                              <td colSpan={2}>
                                {t("P-I (Petty Improvement, CIVIL)")}
                              </td>
                              <td align="center">{t("Free")}</td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">E)</td>
                              <td colSpan={2}>
                                {t("P-I (Petty Improvement, LIGHTING)")}
                              </td>
                              <td align="center">{t("Free")}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
            </div>
          </div>
          
        </div>
          </div>
      </div>
    </section>
    
      </>
  )
}

export default Central_records_fee