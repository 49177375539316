import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";

const Water_Supply_Faqs  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
 
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link  fontSizeClass" to='/Water_Supply'> Water Supply Home</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/PlumberDetails.jsp'>Find Plumbers</a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass " to='/Download_Forms'>Download Forms </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass " to='/Water_Supply_Fee_Charges'>Fees and Charges</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WaterFee.jsp'> Check Demands Payable</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WaterSupplyDemandPrint.jsp'>Print Unpaid Bill</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/KMCPortalWaterSupplyPaymentAction.do'> Make Online Payment</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WSDuplicateReceipt.jsp'> Reprint e-Receipt</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WSPaymentStatus.jsp'>Check Demand Status </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Water_Supply_Faqs'> FAQs </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Water_Supply_Dept'>Contact </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Water_For_Fire_Fighter'> Water For Fire Fighters</Link>
                </li>
                <li className="nav-item"><a className="fontSizeClass " onClick={(e)=>fileDownload('246')}> Action Plan to reduce water losses to less than 20% </a> </li>  
                
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title"><h3 className="fontSizeClass"> Water Supply : FAQs </h3>
              </div>
                  <div id="accordion" className='min-height-400'>
                  <div className="card">
                    <div className="card-header" id="heading1">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                    1. What are the key activities of the Department?
                    </button>
                    </div>
                    <div id="collapse1" className="collapse show" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">The objective of the water supply department Kolkata Municipal Corporation (KMC) is to provide uninterrupted water supply to the residents of Kolkata. The key activities undertaken by the department are:
                            a.Generation: The river Hoogly is the main source of water supply for Kolkata. After drawing water from the river, KMC treats the water at Indira Gandhi Water Treatment Plant (WTP) of capacity 260 million gallons per day at Palta Water Works. Water from this water works is sent to Tallah pumping station for temporary storage and onward distribution to the City.
                            b.Distribution: Water supply department has divided the city into 7 zones based on hydraulic pattern and best engineering practices. The seven zones are : Central, North, South, West, Jadavpur Unit, SSU/GRU, and Tube Well (TW). Water is supplied through water pipe network to all over the City for ultimate utilisation by domestic as well as ICI Consumers.
                            c.Other Works: Water Supply Department also supplies water for shipping, supply through tankers, regular use and emergency handling of maintenance works.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading2">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                      2. How can I get a water supply connection for my new house?
                    </button>
                    </div>
                    <div id="collapse2" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      Different steps for getting water supply connection :
                        a.Contact the Assistant Engineer of the local office of the Water Supply Department.
                        b.Collect the list of plumbers from the local Section office or you may download the same from the KMC website.
                        c. Select a plumber and the plumber will prepare the estimate. The user should apply in the prescribed format (Annex F) and place it before the Assistant Engineer for sanction.
                        d. After sanction, deposit the amount and get connection.
                        e. Fee includes Deposit Money, Water Charges, Connection Fees, Road Opening Fees, Road Restoration Fees etc.
                      </div>                      
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading3">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                      3. Where can I procure the water supply application form? Do I have to pay for it?
                    </button>
                    </div>
                    <div id="collapse3" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      You can procure the application form available in net from the Headquarters or download it from the internet or take a xerox copy of the form from your plumber.

                    </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                      4. What documents are required for submission along with application form?
                    </button>
                    </div>
                    <div id="collapse4" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      You would be required to submit the following documents:-
                        1. Xerox copy of building sanction plan
                        2. Receipt of the payment of building sanction fees
                        3. No Objection Certificate from the assessment and collection department
                        4. Plumber “Niyog patra”  </div>                  </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading5">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                      5. Where should I deposit the application form and the required attachments?
                    </button>
                    </div>
                    <div id="collapse5" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      Kindly deposit the documents at your concerned Section office. The Section office would inspect your premises, prepare an estimate of expenses and hand over the application form duly signed by the Assistant Engineer to the citizen or his agent i.e. the plumber.
                     </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading6">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                      6. Where should I make the payment?
                    </button>
                    </div>
                    <div id="collapse6" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      You can only make the payment for the water supply connection at eKolkata centre or the KMC Borough office or C.M.O. Building (5, S. N. Banerjee Road)
                      </div>
                    </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading7">

                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                      7. What would be the components of the water supply connection charges for building construction purposes?
                    </button>
                    </div>
                    <div id="collapse7" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">You would be required to pay connection fees, restoration charges and road opening charges. The size of ferrule & connection fees are calculated as per given in the following tables.</p>
                        <div className="mayor-table2 depart">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="table-warning table-bor-2">
                            <th scope="col"> Building </th>
                            <th className="water-tab1" scope="col">Ferrule Size</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">Upto 1st. storied	</td>
                            <td> 10 mm </td>
                          </tr>
                          <tr>
                            <td scope="row">Upto 2nd storied</td>
                            <td>15 mm</td>
                          </tr>
                          <tr>
                            <td scope="row">Upto 3nd storied</td>
                            <td> 22 mm </td>
                          </tr>
                          <tr>
                            <td scope="row">Upto 4th storied and above	</td>
                            <td> 25 mm </td>
                          </tr>
                        </tbody>
                      </table>
                        </div>

                        <div className="mayor-table2 depart">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="table-warning table-bor-2">
                            <th scope="col"> Sl.No. </th>
                            <th scope="col">Particulars</th>
                            <th scope="col">Amount(Rs)(For each ferrule connection)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">1</td>
                            <td> If Height of building = 8.5 meters </td>
                            <td>400</td>
                          </tr>
                          <tr>
                            <td scope="row">2</td>
                            <td>If Height of building less than 8.5 meters and less than 14.5 meters	</td>
                            <td>1000</td>
                          </tr>
                          <tr>
                            <td scope="row">3</td>
                            <td>If Height of building less than 14.5 meters	</td>
                            <td>20000</td>
                          </tr>
                        </tbody>
                      </table>
                        </div>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading8">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
                      9. How would the fees be calculated for new connection in case of domestic use?
                    </button>
                    </div>
                    <div id="collapse8" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">Based on the ferrule size you would be required to pay connection fees. You would also be required to pay road restoration charges and road opening charges. Kindly refer to the following table.</p>
                        <div className="mayor-table2 depart">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="table-warning table-bor-2">
                            <th scope="col"> Ferrule Size </th>
                            <th className="water-tab1" scope="col">Connection fee for domestic or other than I.C.I. connections (Rs.)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">10 mm</td>
                            <td>1500 + Road restoration charges + Road opening charges</td>
                          </tr>
                          <tr>
                            <td scope="row">15 mm</td>
                            <td>2000 + Road restoration charges + Road opening charges</td>
                          </tr>
                          <tr>
                            <td scope="row">20 mm</td>
                            <td>4500 + Road restoration charges + Road opening charges</td>
                          </tr>
                          <tr>
                            <td scope="row">25 mm</td>
                            <td>6000 + Road restoration charges + Road opening charges</td>
                          </tr>
                        </tbody>
                      </table>
                        </div>
                      </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading9">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
                    10. How would the fees be calculated for new connection for commercial, Industrial or Institutional use?
                    </button>
                    </div>
                    <div id="collapse9" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">Based on the ferrule size you would be required to pay connection fees. You would also be required to pay road restoration charges and road opening charges. Kindly refer to the following table.</p>
                        <div className="mayor-table2 depart">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="table-warning table-bor-2">
                            <th scope="col"> Ferrule Size </th>
                            <th className="water-tab1" scope="col">Connection fee for domestic or other than I.C.I. connections (Rs.)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">10 mm</td>
                            <td>5000 + Road restoration charges + Road opening charges</td>
                          </tr>
                          <tr>
                            <td scope="row">15 mm</td>
                            <td>7000 + Road restoration charges + Road opening charges</td>
                          </tr>
                          <tr>
                            <td scope="row">20 mm</td>
                            <td>8500 + Road restoration charges + Road opening charges</td>
                          </tr>
                          <tr>
                            <td scope="row">25 mm</td>
                            <td>10000 + Road restoration charges + Road opening charges</td>
                          </tr>
                        </tbody>
                      </table>
                        </div>
              </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading10">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse10" aria-expanded="true" aria-controls="collapse10">
                    11. What would be the monthly charges for commercial water supply at different Ferrule sizes?
                    </button>
                    </div>
                    <div id="collapse10" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <p className="fontSizeClass">Based on the ferrule size you would be required to pay monthly charges in case you have an I.C.I. connection. Please refer to the following table.</p>
                        <div className="mayor-table2 depart">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="table-warning table-bor-2">
                            <th scope="col"> Ferrule Size </th>
                            <th className="water-tab1" scope="col">Connection fee for domestic or other than I.C.I. connections (Rs.)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">10.00 mm</td>
                            <td>525</td>
                          </tr>
                          <tr>
                            <td scope="row">15.00 mm</td>
                            <td>1200</td>
                          </tr>
                          <tr>
                            <td scope="row">20.00 mm</td>
                            <td>2000</td>
                          </tr>
                          <tr>
                            <td scope="row">25.00 mm</td>
                            <td>3000</td>
                          </tr>
                        </tbody>
                      </table>
                        </div></div>                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading11">
                      
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                      12.What would be charges for consumption of water through bulk meter be calculated?
                    </button>
                    </div>
                    <div id="collapse11" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <p className="fontSizeClass">a) The current fee structure for water consumption through Bulk Meter is: For domestic purpose: Rs. 7/- per kilo-litre</p><br />
                      <p className="fontSizeClass">b) For Industrial, Commercial and Institutional purpose: Rs. 15/- per kilo-litre</p>
                      </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading12">
                      
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse12" aria-expanded="true" aria-controls="collapse12">
                      13. How can I apply for reconnection of water supply or change of pipe or change of ferrule size?
                    </button>
                    </div>
                    <div id="collapse12" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      Kindly apply at the concerned Section office and pay the requisite fees.</div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading13">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse13" aria-expanded="true" aria-controls="collapse13">
                      14. Whom should I contact for a water tanker and how much would it cost me?
                    </button>
                    </div>
                    <div id="collapseThree" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">In order to get a water tanker in case of temporary shortage of water supply or on special occasions, kindly contact: Assistant Engineer (Mains), at H.P.L., 6, Convent Road, Ph: 2265-5785, 2265-5845. You would be required to pay Rs. 450 for each tanker of 3600 L capacity. This service is available between 7 a.m. to 6 p.m.</p>
                      </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading14">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse14" aria-expanded="true" aria-controls="collapse14">
                      15. Whom should I contact for installation of new tube-wells or repair of damaged tube-wells?
                    </button>
                    </div>
                    <div id="collapse14" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      Kindly contact the Executive Engineer, Tubewell, 5, S.N. Banerjee Road, Kolkata – 700 013, Phone: 2286-1100-08, Extension 2567.</div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading15">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse15" aria-expanded="true" aria-controls="collapse15">
                      16. What are my duties as a citizen with respect to water supply? What may happen if I fail to perform my duties?
                      </button>
                    </div>
                    <div id="collapse15" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <p className="fontSizeClass">The following are the duties of citizen w.r.t water<br />
                        a. All the parts of the pipelines within each premises should be protected and taken proper care of, to prevent the flow of dirty underground water. If required, you may have to install a new pipeline in place of your old existing damaged one at your own cost with the permission of the Corporation.<br />
                        b. Lids of underground and overhead reservoirs should be properly covered and the water flow should be properly maintained so that there is no damage. At least once in a week water should be emptied and bleached to prevent them from becoming the breeding place of germs and mosquitoes.<br />
                        If you fail to perform your duties in either of cases then, KMC may decide to disconnect your water supply.</p>
                      </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading16">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse16" aria-expanded="true" aria-controls="collapse16">
                      17. What are the expected timelines of performance of the following activities?
                      </button>
                    </div>
                    <div id="collapse16" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <div className="mayor-table2 depart">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="table-warning table-bor-2">
                            <th scope="col">Sl. No</th>
                            <th scope="col">Activity</th>
                            <th scope="col">Approximate response time</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Inspection of premises after application form is submitted at Section office</td>
                            <td>3 Days</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Issuance of demand notice after completion of inspection</td>
                            <td>3 Days</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Sanction Certificate for water connection after receipt of payment	</td>
                            <td>1 Days</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Processing of application for reconnection of water supply	</td>
                            <td>3 Days</td>
                          </tr>
                        </tbody>
                      </table>
                        </div>
                      </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading17">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse17" aria-expanded="true" aria-controls="collapse17">
                      18. What should I do in case of an excessive delay in the approval of water supply connection?
                      </button>
                    </div>
                    <div id="collapse17" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <p className="fontSizeClass">In the unlikely event of delay in approval kindly contact the Deputy Chief engineer, Water Supply at the KMC, Headquarters. Ph.No. 22861100-08, Extn: 2573.</p>
                      </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading18">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse18" aria-expanded="true" aria-controls="collapse18">
                      19. Whom should I contact for non-supply of water or to lodge complaints for supply of unhygienic water?
                      </button>
                    </div>
                    <div id="collapse18" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">For non-supply of water, kindly contact the Assistant Engineer of the concerned office at your locality. Please refer to the following table.</p>
                      <div className="mayor-table2 depart">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="table-warning table-bor-2">
                            <th scope="col">Sl. No</th>
                            <th scope="col">Activity</th>
                            <th scope="col">Approximate response time</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Inspection of premises after application form is submitted at Section office</td>
                            <td>3 Days</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Issuance of demand notice after completion of inspection</td>
                            <td>3 Days</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Sanction Certificate for water connection after receipt of payment	</td>
                            <td>1 Days</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Processing of application for reconnection of water supply	</td>
                            <td>3 Days</td>
                          </tr>
                        </tbody>
                      </table>
                        </div>
                      </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading19">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse19" aria-expanded="true" aria-controls="collapse19">
                      19. Whom should I contact for non-supply of water or to lodge complaints for supply of unhygienic water?
                      </button>
                    </div>
                    <div id="collapse19" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">For non-supply of water, kindly contact the Assistant Engineer of the concerned office at your locality. Please refer to the following table.</p>
                      <div className="mayor-table2 depart">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="table-warning table-bor-2">
                            <th scope="col">Sl. No</th>
                            <th scope="col">Name of the Local Office</th>
                            <th scope="col">Ward No.</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Cossipur Section, 10, B.T. Road, Kolkata-700002 Ph: 2558 8553	</td>
                            <td>1, 2, 3, 4, 5, 6</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Hatibagan Section, 79, Bidhan Sarani, Kolkata-700006 Ph: 2543 1602	</td>
                            <td>7, 8, 9, 10, 11, 12, 15</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Brindaban Bose Section, 28, Brindaban Bose Lane, Kolkata-700006, KMC Building	</td>
                            <td>16, 17, 18, 19, 20</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>B. K. Pal Section,105, B. K. Pal Avenue, (Near the B.K. Pal Park), Kolkata-700005	</td>
                            <td>21, 22, 23, 24, 25</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Hedua Section, 6, Azad Hind Bagh, Hedua Square, Kolkata-700006	</td>
                            <td>26, 27, 28, 38, 39</td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Section (Maniktala) 109, Narkeldanga Main Road,Kolkata-700011, Ph: 2364 9252	</td>
                            <td>13, 14, 29, 30, 31, 32, 33, 34, 35</td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>Section 'E' 1, Neel Madhab Sen Lane(Near Md. Ali Park), Kolkata-700073	</td>
                            <td>41, 42, 43, 44, 45</td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>Section 'F' 22, Surya Sen Street, Kolkata-700012, (Near Borough V office), Ph:2219 2399	</td>
                            <td>36, 37, 40, 48, 49, 50</td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>Section 'G', 23, Princep Street,Kolkata-700072	</td>
                            <td>46, 47, 51</td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>Section 'H' Hazi Md. Mohsin Square (Goltalab), Kolkata-700016	</td>
                            <td>52, 53, 61, 62, 63</td>
                          </tr>
                          <tr>
                            <td>11</td>
                            <td>Section 'I', 13, Dilkhusha Street (Near Park Circus Tram Depot), Kolkata-700017	</td>
                            <td>64, 65</td>
                          </tr>
                          <tr>
                            <td>12</td>
                            <td>Section 'J', P-65, Dr. Sundari Mohan Avenue, Kolkata-700014 KMC Office	</td>
                            <td>54, 55, 56, 57, 58, 59, 60</td>
                          </tr>
                          <tr>
                            <td>13</td>
                            <td>Picnic Garden, 105/2, Prasanna Naskar Lane(Near Colony Bazar), Kolkata-700039, Ph: 2334 3900	</td>
                            <td>66, 67</td>
                          </tr>
                          <tr>
                            <td>14</td>
                            <td>Section 'K', 212, Rashbehari Avenue(Gariahat Market, 3rd Floor), Kolkata-700025	</td>
                            <td>68, 69, 72, 84, 85, 86, 87, 90</td>
                          </tr>
                          <tr>
                            <td>15</td>
                            <td>Section 'L', 125,Hazra Road (At Hazra Road and Harish Mukherjee Road Crossing), ,Kolkata-700026 Ph: 2455 7978	</td>
                            <td>70, 71, 73, 74, 82, 83, 88</td>
                          </tr>
                          <tr>
                            <td>16</td>
                            <td>Section 'M', 39,Circular Garden Reach Road (At Sudhir Bose Road and C.G.R.D. Road Junction), Kolkata-700024	</td>
                            <td>75, 76, 77, 78, 79, 80</td>
                          </tr>
                          <tr>
                            <td>17</td>
                            <td>Section 'Tollygunge', Opposite Golf Green Daily Market, Uday Sankar Sarani, Kolkata-700045 Ph: 2483 6604	</td>
                            <td>81, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100</td>
                          </tr>
                          <tr>
                            <td>18</td>
                            <td>97 & 98, Garfa Main Road, Borough - XII Office, Kolkata - 700 075	</td>
                            <td>103, 104, 105, 106, 107, 108, 109</td>
                          </tr>
                          <tr>
                            <td>19</td>
                            <td>Baghajatin Market Complex, Unit-III, Baghajatin Station Road, Kolkata - 700 086	</td>
                            <td>101, 102, 110, 111, 112, 113, 114</td>
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>S.S. Unit, Behala, 516, D.H. Road, Borough-XIII and XIV Office, Kolkata - 700 034	</td>
                            <td>115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132</td>
                          </tr>
                          <tr>
                            <td>21</td>
                            <td>G.R. Unit, E/3, Circular Garden Reach Road, Borough - XV Office, Kolkata - 700 024	</td>
                            <td>133, 134, 135, 136, 137, 138, 139, 140, 141</td>
                          </tr>
                        </tbody>
                      </table>
                        </div>
                      </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading20">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse20" aria-expanded="true" aria-controls="collapse20">
                      20. How do we obtain permission for registration of existing and new power driver tubewells?
                      </button>
                    </div>
                    <div id="collapse20" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">All Account holders of the power driven tubewells within the KMC area are requested to collect the prescribed Form No.6 from the Tubewell Department, CMO Building, 2nd floor, 5, S N Banerjee Road, Kolkata 700013 for registration of their existing tubewell(s) under the provision of 'West Bengal Ground Water Resources (Management, Control and Regulation) Act, 2005'
                            For new power driven tubewell(s), permission should be obtained from State Water Investigation Directorate (SWID) and necessary license taken from Kolkata Municipal Corporation by depositing the requisite license fees.
                            Address of SWID - Bikash Bhavan, 5th Floor, Kolkata 700 019.</p>
                      </div>
                      </div>
                    </div>

                  </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
    {/*   */}
  </section>
  
    </>
  )
}

export default Water_Supply_Faqs