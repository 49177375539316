import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Advertisement_citizen_login  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
        <section className="section inner-pad1">
        <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dig-login1">
                <form>
                  <div className="log-form">
                    <div className="container">
                      <div className="row">
                        <h3 className="fontSizeClass">{t("Citizen Login")}</h3>
                        <p className="fontSizeClass">{t("tWelcome back! Please login to your account")}.</p>
                        <div className="col-xl-12 col-md-12 mb-12 form-group pt-2">                    
                          <input type="tel" className="form-control" placeholder="{t('User ID')}" aria-label aria-describedby="basic-addon1" />
                        </div>
                        <h6 className="orbtn">{t("OR")}</h6>
                        <div className="col-xl-12 col-md-12 mb-12 form-group pt-2">                    
                          <input type="tel" className="form-control" placeholder="{t('Mobile Number')}" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" aria-label aria-describedby="basic-addon1" />
                        </div>
                        <div className="col-xl-12 col-md-12 mb-12 form-group pt-4">                    
                          <input type="tel" className="form-control" placeholder="{t('Password')}" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" aria-label aria-describedby="basic-addon1" />
                        </div>
                        <div className="login__field">
                          <div className="form-group"> 
                            <span className="fontSizeClass"> <Link to> {t("Forgot Password")} </Link> <p /> </span>        
                          </div>
                        </div>
                        <div className="login__field"> 
                          <div className="form-group continu"> 
                            <Link to="index.html">  {t("Log In")} </Link>
                            <Link to> {t("Register")} </Link>                      
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>           
          </div>
        </div>
      </section>
    </>
  )
}

export default Advertisement_citizen_login