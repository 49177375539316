import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Amusement_important_officials = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const [MoreServices, setMoreServices] = React.useState(false);

  const MoreServicesToggle = () => {
    setMoreServices(!MoreServices);
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
  
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">           
        <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
              <li className="nav-item">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Certificate of Enlistment")} <i class="bi bi-chevron-down"></i> </button>
                    <div id="demo" className="collapse">
                        <a href='https://www.kmcgov.in/KMCPortal/downloads/725_MA_Revised_Enlistment_Trade_Calling.pdf' target="_blank"> {t("Guidline")} </a>
                        <a href='https://www.kmcgov.in/KMCPortal/downloads/TradeLicenceEoDBProcedure.pdf' target="_blank"> {t("Procedure")} </a>               
                    </div>
                </li>  
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Advertisement_Dept'> {t("Advertisement Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Amusement_Dept'> {t("Amusement Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Assessment_Dept'> {t("Assessment Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/'> {t("Building Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Bustee_Dept'> {t("Bustee Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Car_Parking_Dept'> {t("Car Parking Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Chief_Eng_Dept'> {t("Chief Eng Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Ch_VS_Dept'> {t("Ch. VS Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Sewerage_Drainage_Dept'> {t("Sewerage Drainage Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Education_Dept'> {t("Education Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Electricity_Dept'> {t("Electricity Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Finance_Dept'> {t("Finance Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Health_Dept'>  {t("Health Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Internal_Audit_Dept'> {t("Internal Audit Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/IT_Dept'> {t("I.T. Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Law_Dept'> {t("Law Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Licence_Dept'> {t("Licence Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Lighting_Dept'> {t("Lighting Dept")}</Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Market_Dept'> {t("Market Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Mpl_Secretary_Dept'> {t("Mpl.Secretary's Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Personnel_Dept'>  {t("Personnel Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Parks_Square_Dept'>  {t("Parks & Square Dept")}. </Link> </li>	

                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Environment_Heritage_Dept'>  {t("Environment & Heritage Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Roads_Dept'>  {t("Roads Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Social_Welfare_Urban_Dept'> {t("Social Welfare & Urban Proverty Alleviation Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Solid_Waste_Dept'>  {t("Solid Waste Mgmt. Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Town_Planning_Development_Dept'>  {t("Town Planning & Development Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Treasury_Dept'>  {t("Treasury Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Water_Supply_Dept'>  {t("Water Supply Dept")} </Link> </li>									
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas"><h3 className="fontSizeClass"> {t("Important Officials of Advertisement Department")}</h3></div>		   
                <table className="table table-bordered officials">
                  <tbody><tr bgcolor="#302ba0" tabIndex={0}>
                      <td width="10%" rowSpan={2} style={{"color":"#fff","border-left":"1px solid #302ba0"}} align="center" valign="middle">{t("Sl. No")}.</td>
                      <td width="23%" rowSpan={2} style={{"color":"#fff"}}>{t("Name")}</td>
                      <td width="22%" rowSpan={2} style={{"color":"#fff"}}>{t("Designation")}</td>
                      <td colSpan={2} rowSpan={2} style={{"color":"#fff","border-right":"1px solid #302ba0"}}> {t("Office Phone No")}.</td>
                    </tr>
                    <tr>
                      {/* <td width="20%" style="padding:3">Office</td>
					<td width="13%" style="padding:3">Resi.</td> */}
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff" tabIndex={0}>
                      <td>1.</td>
                      <td align="left">Mukul Ranjan Barai</td>
                      <td align="left">Chief Manager</td>
                      <td align="left">2286-1000, <br /> Extn. 2306</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff" tabIndex={0}> 
                      <td>2.</td>
                      <td align="left">Supriya Ghosh</td>
                      <td align="left">Manager</td>
                      <td align="left">2252-0430 <br />
                        2286-1000, Extn. 2310</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>3.</td>
                      <td align="left">Rabindranath Halder	</td>
                      <td align="left">Dy.Manager</td>
                      <td align="left">2286-1000, Extn. 2928</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>4.</td>
                      <td align="left">Partha Dey	</td>
                      <td align="left">Sr.Amusement Officer	</td>
                      <td align="left">2286-1000, Extn. 2511 </td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>5.</td>
                      <td align="left">Rupa Paul	</td>
                      <td align="left">Sr.Amusement Officer	</td>
                      <td align="left">2286-1000, Extn. 2608</td>
                    </tr> 
                  </tbody></table>
            </div> 
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Amusement_important_officials