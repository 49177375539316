import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";

const Water_Supply_Fee_Charges = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
 
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link  fontSizeClass" to='/Water_Supply'> Water Supply Home</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/PlumberDetails.jsp'>Find Plumbers</a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass " to='/Download_Forms'>Download Forms </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Water_Supply_Fee_Charges'>Fees and Charges</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WaterFee.jsp'> Check Demands Payable</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WaterSupplyDemandPrint.jsp'>Print Unpaid Bill</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/KMCPortalWaterSupplyPaymentAction.do'> Make Online Payment</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WSDuplicateReceipt.jsp'> Reprint e-Receipt</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WSPaymentStatus.jsp'>Check Demand Status </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Water_Supply_Faqs'> FAQs </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Water_Supply_Dept'>Contact </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Water_For_Fire_Fighter'> Water For Fire Fighters</Link>
                </li>
                <li className="nav-item"><a className="fontSizeClass " onClick={(e)=>fileDownload('246')}> Action Plan to reduce water losses to less than 20% </a> </li>  
                
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title"><h3 className="fontSizeClass">Schedule of Fees and Charges for the Year of 2023 - 2024 </h3>
              </div>
              <div className="mayor-table">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="table-warning table-bor-2">
                            <th style={{width:"15%"}}> Sl No. </th>
                            <th width="55%">Description of Item	</th>
                            <th width="30%">Figures in Rs.</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Charge for supply of wholesome/filtered water 3,600 to 4000 liters by lorry & tanker within a distance of 8 km. from the loading point<br />
                                (Payment to be made at least 24 hours in advance at the Cash Counter of the Deptt and the supply depends on availability of lorry.)</td>
                            <td>450.00 per Trip</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>- Do - for supply at distance more than 8 km. additional charge per k.m. or part thereof.</td>
                            <td>60.00</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>For any religious, social or public functions which are not meant for earning monetary profit, supply of water may be made free at the discretion of the EEWS but the transportation &other costs per trip within a distance of 8 km. from the centre of the city is to be deposited at least 24 hours in advance. Supply would depend on the availability of lorry, tanker etc.</td>
                            <td>250.00 L.S./Trip</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>- Do - for supply at a distance beyond 8 km. additional charge per km. or part thereof.</td>
                            <td>30.00</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Charge for carrying, placing and fixing reserve tanks (1800 ltrs) within the city limit for one day.</td>
                            <td>300.00</td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>- Do - charge for each additional day.</td>
                            <td>90.00</td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>Charge for detaining hired lorry for more than one hour from its arrival at the place of delivery.</td>
                            <td>100.00 per hour or part thereof</td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>Filtered water from domestic connection if used partly for non-domestic purpose with necessary permission.</td>
                            <td>Half the usual ferrule connection rate for non-domestic purpose</td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>a) If filtered water from domestic connection is fully used for non-domestic purposes.</td>
                            <td>Full ferrule rate prescribed for non-domestic purposes</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>b) If filtered water from domestic connection is used more than half of the premises either horizontally or vertically for non domestic purpose with necessary permission</td>
                            <td>Full ferrule rate prescribed for non-domestic purpose</td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>Filling of tankers with filtered water (other than Govt. tankers) from diff. Boosting Stations for Organisations located in Kolkata</td>
                            <td>200.00 per tanker</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>- Do- out side Kolkata</td>
                            <td>250.00 per tanker</td>
                          </tr>                          
                          <tr>
                            <td>11</td>
                            <td><strong>Meter Rent</strong><br />
                                a)   Any Size upto 20 mm dia<br />
                                b)   Size 25 mm dia to 32 mm dia<br />
                                c)   Size 40 mm dia<br />
                                d)   Size 50 mm dia<br />
                                e)   Size 75 mm dia to 100 mm dia<br />
                                f)    Size 150 mm. dia<br />
                                g)   Size 200 mm dia<br />
                                h)   Size 250 mm dia & above</td>
                            <td>&nbsp;<br />100.00 per month<br />175.00 per month<br />200.00 per month <br />400.00 per month<br />
                            500.00 per month<br />750.00 per month<br />1000.00 per month<br />1100.00 per month</td>
                          </tr>
                          <tr>
                            <td>12</td>
                            <td><strong>Meter Testing Fee</strong></td>
                            <td>10.00 per 1000 Liters</td>
                          </tr>
                          <tr>
                            <td>13</td>
                            <td><strong>Security Deposit for Connection with Meter	</strong><br />
                                a)   Any Size upto 20 mm. dia<br />
                                b)   Size 25 mm. dia. to 40 mm. dia<br />
                                c)   Size 50 mm. dia<br />
                                d)   Size 75 mm. dia to 100 mm. dia<br />
                                f)   Size 150 mm. dia<br />
                                g)   Size 200 mm dia<br />
                                h)   Size 250 mm dia & above</td>
                            <td>&nbsp;<br />7,000.00<br />7,500.00<br />10,000.00 <br />20,000.00<br />25,000.00<br />30,000.00<br />32,500.00</td>
                          </tr>
                          <tr>
                            <td>14</td>
                            <td><strong>Security Deposit for I.C.I./ Non-Domestic Ferrule Connection	</strong><br />
                            a)   For 10 mm. dia size<br />b)   For above 10 mm. dia size</td>
                            <td>5,000.00<br />7,000.00</td>
                          </tr>
                          </tbody>
                          </table>
                          
                            <p>Note: Every new applicant for Meter connection or ICI Ferrule connection has to pay an interest-free Security Deposit as above for each Meter & Ferrule connection which is refundable when he settles his account and ceases to be a consumer</p>
                          
                          <table className="table table-bordered">
                        <thead>
                          <tr className="table-warning table-bor-2">
                            <th style={{width:"15%"}}> Sl No. </th>
                            <th width="55%">Description of Item	</th>
                            <th width="30%">Figures in Rs.</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>15</td>
                            <td>BULK SUPPLY OF WATER THROUGH METERS u/S 262, 263, 264 & 265</td>
                            <td> Water Charges</td>
                          </tr>
                          <tr>
                            <td><strong>Bulk domestic:</strong><br />
                            (Including Housing complexes (both rental & ownership) or owned by State & Central Govt. & other public bodies as well as private housing estates with less than25% of non-residential use)</td>
                            <td>Rs. 6.00 Per K. L.</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td><strong>Government Hospitals</strong></td>
                            <td>Rs. 7.00 Per K. L.</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td><strong>Bulk ICI</strong><br />(Private Hospt., Defence Estab. (other than housing complexes) Railway Estab. & other Commercial Estab.	</td>
                            <td>Rs. 16.00 Per K. L</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td><strong>Multistoried Buildings</strong><br />(With 25% or more commercial areas)	</td>
                            <td><p className="fontSizeClass">Rs. 13.00 Per K. L.</p></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Other than KMC area</td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>a) Domestic	</td>
                            <td>Rs. 12.00 Per K. L</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>b) ICI	</td>
                            <td>Rs. 18.00 Per K. L</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>c) Domestic + Partly Commercial	</td>
                            <td>Rs. 15.00 Per K. L</td>
                            <td></td>
                          </tr>
                          <tr><td>Bills will be raised monthly. Meter rent will be collected in addition to above charges.</td></tr>
                          <tr>
                            <td>Description of Item	</td>
                            <td>Figures in Rupees</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>16</td>
                            <td>Connection Fees for connection with Meter	</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>a)   Any size upto 20 mm Dia<br />
                                b)   Size 25 mm Dia to 40 mm Dia<br />
                                c)   Size 50 mm Dia<br />
                                d)   Size 75 mm Dia to 100 mm Dia<br />
                                e)   Size 150 mm Dia<br />
                                f)    Size 200 mm Dia<br />
                                g)   Size 250 mm Dia and above</td>
                            <td><p className="fontSizeClass">7,000.00<br />
                                    7,500.00<br />
                                    10,000.00<br />
                                    20,000.00<br />
                                    25,000.00<br />
                                    30,000.00<br />
                                    32,500.00</p></td>
                                    <td></td>
                          </tr>
                          <tr>
                            <td>17</td>
                            <td>Connection fee for effecting new single filtered water/ unfiltered water supply for Domestic or other than I.C.I. Connections (Ferrule Connection).</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>a) 10 mm<br />
                                b) 15 mm<br />
                                c) 20 mm<br />
                                d) 25 mm</td>
                            <td> Rs. 1500.00 + Rd. Restoration Charges<br />
                                Rs. 2000.00 + Rd. Restoration Charges<br />
                                Rs. 4500.00 + Rd. Restoration Charges<br />
                                Rs. 6000.00 + Rd. Restoration charges<br />
                                & Rd opening Fee.</td>
                                <td></td>
                          </tr>
                          <tr>
                            <td>18</td>
                            <td>Connection fee for changing of broken ferrule of any existing connection for Domestic or other than I.C.I. Connection</td>
                            <td>250.00 + Road Resto. charges & Road opening fee.</td>
                          </tr>
                          <tr>
                            <td>19</td>
                            <td>Building Construction Connection Fee	</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>	
                                a)   Upto 8.5 meter height of Building.<br />
                                b)   More than 8.5 Mtr.<br />
                                c)   Above 14.5 meter height of Building</td>
                            <td>4000.00 for each ferrule connection + Road Restoration ch, & Road Opening Fee<br />
                                Rs. 10000.00 + -Do- -Do-<br />
                                Rs. 20000.00 + -Do- -Do-</td>
                          </tr>
                          <tr>
                            <td>20</td>
                            <td><strong>Connection fees for I.C.I./Non-Domestic ferrule connection	</strong></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>a)   10 mm. dia Ferrule<br />
                                b)   15 mm dia Ferrule<br />
                                c)   20 mm. dia Ferrule<br />
                                d)   25 mm dia Ferrule</td>
                            <td>5000.00<br />
                                7000.00<br />
                                8500.00<br />
                                10,000.00</td>
                          </tr>
                          <tr>
                            <td>21</td>
                            <td>For ICI or non-domestic connection where there is no trade licence</td>
                            <td>10,000.00<br />(in addition to normal ICI connection charges)</td>
                          </tr>
                          <tr>
                            <td>22</td>
                            <td>a)    Fee for changing of Ferrule, Stop-cock or bend or fee or any water fitting of existing connection for I.C.I. or Commercial purposes & building construction connections.<br />
                                b)   Fee for cleansing of any type of ferrule or stop cock</td>
                            <td>Rs. 1000.00 + Rd. restoration charges & Rd. opening Fee<br />  Rs. 500.00</td>
                          </tr>
                          <tr>
                            <td>23</td>
                            <td>Connection fee for shifting, enlargement of any existing ferrule connection for domestic supply and building construction connection</td>
                            <td>Same as conversion/utilization (domestic). For ICI, rate will be same as new ICI connection</td>
                          </tr>

                          <tr>
                            <td>Note:</td>
                            <td colSpan={2}> 1. Connection fee non-refundable for effecting new water connection for sale & supply of filtered water & unfiltered water for I.C.I. connections).
                              2. I.C.I. stands for Industrial, Commercial & Institutional connections. 
                              3. Rate and fees for additional connection for domestic purpose will be as per SI. No. 24 & 20 respectively. For this purpose Trade License will not be required. </td>
                            </tr>

                          
                          <tr>
                            <td>24</td>
                            <td colSpan={2}><strong style={{textAlign:"center"}}>USE OF FILTERED WATER FOR BUSINESS/NON-DOMESTIC/BUILDING PURPOSES THROUGH INDEPENDENT FERRULE CONNECTION</strong></td>         
                          </tr>
                          <tr>
                            <td colSpan={3}><p style={{textAlign:"center"}}>Charge/Rate for using filtered water through independent ferrule connection for Business/Non-Domestic purposes in "Kolkata"</p></td>
                          </tr>
                          
                          <tr className="table-warning">                         
                            <th> Ferrule size </th>
                            <th colSpan={2}> Figures in Rs. per month </th>
                          </tr>                       
                          <tr>
                            <td> a)   3.18 m.m. (1/8")	</td>
                            <td colSpan={2}>225.00</td>
                          </tr>
                          <tr>
                            <td> b)   4.76 m.m. (3/16")</td>
                            <td colSpan={2}>275.00</td>
                          </tr>
                          <tr>
                            <td>  c)   6.35 m.m. (1/4")	</td>
                            <td colSpan={2}>325.00</td>
                          </tr>
                          <tr>
                            <td> d)   10.00 m.m. (3/8")</td>
                            <td colSpan={2}>525.00</td>
                          </tr>
                          <tr>
                            <td>  e)   11.10 m.m. (7/16")</td>
                            <td colSpan={2}>650.00</td>
                          </tr>
                          <tr>
                            <td>  f)    12.10 m.m. (1/2")	</td>
                            <td colSpan={2}>750.00</td>
                          </tr>
                          <tr>
                            <td>  g)   15.00 m.m. (above 1/4")	</td>
                            <td colSpan={2}>1200.00</td>
                          </tr>
                          <tr>
                            <td>  h)   20.00 m.m (3/4")	</td>
                            <td colSpan={2}>2000.00</td>
                          </tr>
                          <tr>
                            <td>  i)    25.00 m.m (1")	</td>
                            <td colSpan={2}>3000.00</td>
                          </tr>
                      
                          <tr>
                            <td colSpan={3}>Note: From 1-4-87 the minimum size of ferrule for building construction purposes will be 10 m.m.</td>
                          </tr>
                          <tr>
                            <td>25</td>
                            <td colSpan={2}><strong>USE OF UNFILTERED WATER FOR OTHER THAN PRIVY FLUSHING PURPOSES</strong></td>
                          </tr>
                          <tr><td>a)Charge/Rate for using unfiltered water through independent ferrule connection for purposes other than Sec. 272(1) & 272(2) of the Act.</td></tr>
                          <tr><div className="mayor-table2 depart">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="table-warning table-bor-2">
                            <th scope="col">Size of ferrule in mm.</th>
                            <th scope="col">Size of pipe in mm.</th>
                            <th scope="col">Other than section 272 (1) & 272 (2) (Rs. per month)</th>
                            <th scope="col">Building Works etc. (Rs. per month)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>12.70 mm. (1/2")</td>
                            <td>12.70</td>
                            <td>50.00</td>
                            <td>75.00</td>
                          </tr>
                          <tr >
                            <td>12.70 mm</td>
                            <td>15.00</td>
                            <td>55.00</td>
                            <td>80.00</td>
                        </tr>
                        <tr >
                            <td>12.70 mm</td>
                            <td>20.00</td>
                            <td>60.00</td>
                            <td>95.00</td>
                        </tr>
                        <tr >
                            <td>12.70 mm</td>
                            <td>25.00</td>
                            <td>70.00</td>
                            <td>105.00</td>
                        </tr>
                        <tr >
                            <td>15.00 mm (above 1/2")</td>
                            <td>15.00</td>
                            <td>75.00</td>
                            <td>110.00</td>
                        </tr>
                        <tr >
                            <td>15.00 mm</td>
                            <td>20.00</td>
                            <td>80.00</td>
                            <td>125.00</td>
                        </tr>
                        <tr >
                            <td>15.00 mm</td>
                            <td>25.00</td>
                            <td>95.00</td>
                            <td>145.00</td>
                        </tr>
                        <tr >
                            <td>15.00 mm</td>
                            <td>32.00</td>
                            <td>100.00</td>
                            <td>150.00</td>
                        </tr>
                        <tr >
                            <td>15.00 mm</td>
                            <td>40.00</td>
                            <td>105.00</td>
                            <td>155.00</td>
                        </tr>
                        <tr >
                            <td>15.00 mm</td>
                            <td>50.00</td>
                            <td>120.00</td>
                            <td>170.00</td>
                        </tr>
                        <tr >
                            <td>19.05 mm (3/2")</td>
                            <td>20.00</td>
                            <td>125.00</td>
                            <td>195.00</td>
                        </tr>
                        <tr >
                            <td>19.05 mm</td>
                            <td>25.00</td>
                            <td>200.00</td>
                            <td>300.00</td>
                        </tr>
                        <tr >
                            <td>19.05 mm</td>
                            <td>32.00</td>
                            <td>250.00</td>
                            <td>375.00</td>
                        </tr>
                        <tr >
                            <td>19.05 mm</td>
                            <td>40.00</td>
                            <td>295.00</td>
                            <td>435.00</td>
                        </tr>
                        <tr >
                            <td>19.05 mm</td>
                            <td>50.00</td>
                            <td>330.00</td>
                            <td>500.00</td>
                        </tr>
                        <tr >
                            <td>25.40 mm (1")</td>
                            <td>25.00</td>
                            <td>335.00</td>
                            <td>505.00</td>
                        </tr>
                        <tr >
                            <td>25.40 mm</td>
                            <td>32.00</td>
                            <td>375.00</td>
                            <td>560.00</td>
                        </tr>
                        <tr >
                            <td>25.40 mm</td>
                            <td>40.00</td>
                            <td>455.00</td>
                            <td>680.00</td>
                        </tr>
                        <tr >
                            <td>25.40 mm</td>
                            <td>50.00</td>
                            <td>520.00</td>
                            <td>780.00</td>
                        </tr>

                        </tbody>
                      </table>
                            </div></tr>
                            <tr><td>Note: The charge/rate for the use of u.f.w. through ferrule connection for market & bazar in the city 'Kolkata' shall be as above.</td></tr>
                          <tr>
                            <td></td>
                            <td>b)    -Do- through meter</td>
                            <td>Rs. 0.50 per 1000 liters + Meter rent</td>
                          </tr>
                          <tr>
                            <td>26</td>
                            <td>Unfiltered water connection for privy flusing purpose in market & bazar in the city 'Kolkata, if used partly for washing, cleansing stalls, passages etc. of the market with necessary permission.</td>
                            <td>Half the usual ferrule connection rate for purposes other than section 272(1) &272(2) of the Act.</td>
                          </tr>
                          <tr>
                            <td>27</td>
                            <td>Unfiltered water connection for garden purpose in the city 'Kolkata'.</td>
                            <td>For continuous supply double the usual ferrule connection rate</td>
                          </tr>
                          <tr>
                            <td>28</td>
                            <td><strong>Unfiltered water connection in premises for provision to fight fire u/S. 272 (3) of the Act.	</strong></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td><strong>Size of u.f.w. connection</strong><br />
                            a) 75 mm (3")<br />
                            b) 80 mm (above 3")<br />
                            c) 100 mm (4")<br />
                            d) 125 mm (5")<br />
                            e) 150 mm (6")</td>
                            <td><strong>Figures in Rs.<br />
                            100.00 per annum<br />
                            110.00 per annum<br />
                            175.00 per annum<br />
                            275.00 per annum<br />
                            375.00 per annum</strong></td>
                          </tr>
                          <tr>
                            <td>29</td>
                            <td>Use of u.f.w. from ground hydrant for sinking tubewell of 40 mm (11/2") & exceeding 40 mm.</td>
                            <td>Rs. 250.00 per T/W & security deposit Rs. 250.00</td>
                          </tr>
                          <tr>
                            <td>30</td>
                            <td>Wet-Work charge, for using Filtered/Unfiltered Water (To be collected by Building Deptt. as per its schedule)</td>
                          </tr>
                          <tr><td><strong style={{textAlign:"center"}}>MISCELLANEOUS</strong></td></tr>
                          <tr>
                            <td>31</td>
                            <td>Road opening Fee for every time permission is issued for effecting each of filtered or unfiltered water connection.</td>
                            <td>According to Schedule of rates of Chief Engineer's Department</td>
                          </tr>
                          <tr>
                            <td>32</td>
                            <td>Road restoration charges.</td>
                            <td>-Do-</td>
                          </tr>
                          <tr>
                            <td>33</td>
                            <td>Blue prints for water fittings.</td>
                            <td>Rs. 20.00 per copy</td>
                          </tr>
                          <tr>
                            <td>34</td>
                            <td>Fee for supplying the schedule of rate/fee/or charges for water connection etc.</td>
                            <td>Rs. 20.00 per copy</td>
                          </tr>
                          <tr>
                            <td>35</td>
                            <td>Charge for cutting off or turning off water supply done under Sec. 275(1) of the Act or on other provision for non-compliance of any notice, requisitions, intimation, failure to pay in full the amount due or on any other fault as would be determined by the E.E.W.S.</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>(a)    For other than I.C.I. or commercial connections.<br />(b)    For I.C.I. or commercial connections and building construction connections.</td>
                            <td>Rs. 2000 + Rd. restoration charge<br />Rs. 5000 + Rd. restoration charge</td>
                          </tr>
                          <tr><td><strong>If the owner or occupier fails to pay the above , it is to be recovered as an arrear of tax under the Act.</strong></td></tr>
                          <tr>
                            <td>36</td>
                            <td>Observation charge & issuing certificate as regards the existence or alignment of water mains or other fitings.</td>
                            <td>Rs. 800.00</td>
                          </tr>
                          <tr>
                            <td>37</td>
                            <td>Restoration fee for restoring the connection lying cut off at stop cock/ferrule at the instance of the consumer (if done by Department/Plumber)</td>
                            <td>Rs. 300.00 + Road restoration charges & Road opening Fee</td>
                          </tr>
                          <tr><td><strong style={{textAlign:"center"}}>FEES FOR RESTORATION OF CONNECTION</strong></td></tr>
                          <tr>
                            <td>38</td>
                            <td>Restoration fee for restoring the connection lying cut off for the fault of the consumer e.g. non-compliance of notice, non-payment of full amount due or other faults under the KMC Act. '80.</td>
                            <td>Rs. 400.00 + Road restoration charge & Road opening Fee</td>
                          </tr>
                          <tr>
                            <td>39</td>
                            <td>Charge for the application form for obtaining private water supply connection.</td>
                            <td>Rs. 50.00 each</td>
                          </tr>
                          <tr><td><strong style={{textAlign:"center"}}>CONVERSION/UTILIZATION</strong></td></tr>
                          <tr>
                            <td>40</td>
                            <td>Fees for conversion/utilization of the existing Building construction/connection to/for Domestic purpose when there is any change in ferrule size* (shifting, enlargement also).</td>
                            <td>Rs. 1000.00 + Rd restoration Charge & Road opening fee will be charged in addition to Fees to be charged</td>
                          </tr>
                          <tr>
                            <td>41</td>
                            <td>-Do- from Domestic to ICI/ non-Domestic Building construction purpose.</td>
                            <td>Rate will be same as per new ICI connection</td>
                          </tr>
                          <tr><td>* This fee would not be applicable if the same ferrule which was allowed for building construction purpose is retained.</td></tr>
                          <tr><td>* For water supply connection/restoration of road is also involved width of road cutting shall be considered as 1.0 mtr.</td></tr>
                          <tr><td><strong style={{textAlign:"center"}}>LICENCE FEES FOR TUBEWELLS U/S 248</strong></td></tr>
                          <tr>
                            <td>42</td>
                            <td>Annual licence fee on each tubewell sunk by private individuals, industrials, Co-operative societies and tubewell sunk in business premises :-</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td><strong>Description</strong><br />
                                (a)     upto 50 mm. (2" dia well pipe)<br />
                                (b)    upto 80 mm. (3"    dia well pipe)<br />
                                (c)    upto 100 mm. (4" dia well pipe)<br />
                                (d)    upto 150 mm. (6" dia well pipe)<br />
                                (e)    Above 150 mm.</td>
                            <td><strong>Figures in Rupees</strong><br />
                            Rs. 500.00 per annum<br />
                            Rs. 3,000.00 per annum<br />
                            Rs. 4,500.00 per annum<br />
                            Rs. 8,000.00 per annum<br />
                            Rs. 12,000.00 per annum</td>
                          </tr>
                          <tr><td>Provided that the tubewell sunk by private individual upto 50 m.m. (2") dia and not run by power may be exempted from tubewell licence fees under Section 248. In such case one time sanction fee of Rs. 450.00 be charged for domestic purpose.</td></tr>
                          <tr><td>Provided further that in the case of Tubewell sunk by a private individual upto 50 m.m. (2") Dia. and run by power, no exemption from tubewell licence fee will be allowed. Security deposit of an amount of Rs. 300.00 to be deposited with the Kolkata Municipal Corporation.</td></tr>

                          <tr>
                            <td>43</td>
                            <td>Inspection fees for inside water supply systems :</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td><strong>Description of Building	</strong><br />
                            (a)    For One-Storied<br />
                            (b)    For above One Storied upto Two-Storied<br />
                            (c)    For above Two-Storied upto Three-Storied<br />
                            (d)    For above Three-Storied upto Four-Storied<br />
                            (e)    For above Four-Storied upto Five-Storied<br />
                            (f)     For above Five-Storied upto Six-Storied<br />
                            (g)    For Seven-Storied<br />
                            (h)    For each additional floor above Seven-Storied.</td>
                            <td><strong>Figures in Rupees</strong><br />
                            Rs. 200.00<br />
                            Rs. 300.00<br />
                            Rs. 500.00<br />
                            Rs. 2000.00<br />
                            Rs. 3000.00<br />
                            Rs. 4000.00<br />
                            Rs. 6000.00<br />
                            Rs. 2200.00</td>
                          </tr>
                          <tr><td><strong style={{textAlign:"center"}}>WATER SUPPLY TO SHIPPING DEPARTMENT</strong></td></tr>

                          <tr>
                            <td>Rs. 350.00 per M. T. (1000 litres) or part there of. Advance payment is to be realized except from the Indian Navy. Schedule Hours of Supply : 6.00 AM to 2.00 PM. & 2.00 PM. to 10.00 PM.</td>
                          </tr>
                        </tbody>
                      </table>
                        </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
    
  </section>
  
    </>
  )
}

export default Water_Supply_Fee_Charges