import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Know_Your_Ward  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
      <div className="container">      
        <div className="section-main-heading2">        
          <h4 className="fontSizeClass"></h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Contact_Us_Home'> {t("Contact Us Home")} </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Important_Contacts' target="_blank"> {t("Important Contacts")} </Link>
                 </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/MMIC'> {t("MMIC")}</Link></li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Controlling_Officers'> {t("Controlling Officers")}</Link></li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Councillors'> {t("Councillors")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Borough_Committee'> {t("Borough Committee")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Borough_Committee'> {t("Borough Offices")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCWard.jsp'> {t("Know Your Ward")}</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass"> {t("Know Your Ward")} </h3>
            </div>
            <div className="about-head1">
              <div className="dig-login1 dig-login2">
                <form>
                  <div className="log-form inner-log-form">
                    <h3 className="fontSizeClass"> {t("List of KMC Wards")} </h3>
                    <div className="row">
                      <div className="col-lg-6 col-md-12 form-group pt-2">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          
                          {t("Ward No:")} :
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={12345678}
                          aria-label="Username"
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 form-group pt-2">
                        <div className="form-group continu">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            
                            &nbsp;
                          </label>
                          <button className="btn btn-primary" type="button">
                            
                            {t("Search")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Know_Your_Ward