import React,{useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { generateHash } from './CommonFunction';
import { mapToOrigin } from './httpConstant';

const Social_Welfare_Urban_Dept = () => {
  const [tableData, setTableData]= useState([])
  const getPortalContact = async () => {
    try {
        let shaKey = await mapToOrigin("SHA_KEY");
        let app_id = await mapToOrigin("APP_ID");
    
        const generatedHash = generateHash(shaKey);
        const url = "https://kmcdev.ddns.net/tender-be-service/getPortalContact";
        let reqData = {
            hashInfo: {
                hashTk: generatedHash,
                appId: "ekmchr",
            },
            itemInfo: {
                department: "791001",
            }
        };

        axios
            .post(url, { reqData })
            .then((res) => {
                if (res.data) {
                    if (res.status === 200) {
                        console.log("test", res)
                        if (res.data.data && res.data.data.length) {
                          setTableData (res.data.data);
                            let roles = res.data.data;
                            let rolesArray = [];
                            for (let i = 0; i < roles.length; i++) {
                                let roleObj = {
                                    value: roles[i].id,
                                    label: roles[i].name,
                                    designation: roles[i].designation,
                                    office_no: roles[i].office_no,
                                    photo: roles[i].photo,
                                };
                                rolesArray.push(roleObj);
                            }
                           // setContactList(rolesArray);
                            console.log("contactlist", rolesArray)
                        }
                    }
                }
            })
            .catch((e) => { });
    } catch (err) { }
};
useEffect(()=>{
  getPortalContact();    
},[])
  const { t, i18n, ready } = useTranslation();
  return (
    <div className="fontSizeClass">
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"> <h4 className="fontSizeClass"></h4></div></div>             
    </div>       	 		 
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">           
          <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
              <li className="nav-item">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Certificate of Enlistment")} <i class="bi bi-chevron-down"></i> </button>
                    <div id="demo" className="collapse">
                        <a href='https://www.kmcgov.in/KMCPortal/downloads/725_MA_Revised_Enlistment_Trade_Calling.pdf' target="_blank"> {t("Guidline")} </a>
                        <a href='https://www.kmcgov.in/KMCPortal/downloads/TradeLicenceEoDBProcedure.pdf' target="_blank"> {t("Procedure")} </a>               
                    </div>
                </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Advertisement_Dept'> {t("Advertisement Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Amusement_Dept'> {t("Amusement Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Assessment_Dept'> {t("Assessment Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/'> {t("Building Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Bustee_Dept'> {t("Bustee Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Car_Parking_Dept'> {t("Car Parking Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Chief_Eng_Dept'> {t("Chief Eng Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Ch_VS_Dept'> {t("Ch. VS Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Sewerage_Drainage_Dept'> {t("Sewerage Drainage Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Education_Dept'> {t("Education Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Electricity_Dept'> {t("Electricity Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Finance_Dept'> {t("Finance Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Health_Dept'>  {t("Health Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Internal_Audit_Dept'> {t("Internal Audit Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/IT_Dept'> {t("I.T. Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Law_Dept'> {t("Law Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Licence_Dept'> {t("Licence Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Lighting_Dept'> {t("Lighting Dept")}</Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Market_Dept'> {t("Market Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Mpl_Secretary_Dept'> {t("Mpl.Secretary's Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Personnel_Dept'>  {t("Personnel Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Parks_Square_Dept'>  {t("Parks & Square Dept")}. </Link> </li>	

                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Environment_Heritage_Dept'>  {t("Environment & Heritage Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Roads_Dept'>  {t("Roads Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Social_Welfare_Urban_Dept'> {t("Social Welfare & Urban Proverty Alleviation Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Solid_Waste_Dept'>  {t("Solid Waste Mgmt. Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Town_Planning_Development_Dept'>  {t("Town Planning & Development Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Treasury_Dept'>  {t("Treasury Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Water_Supply_Dept'>  {t("Water Supply Dept")} </Link> </li>									
              </ul>
            </nav> 
          </div>
        </div>
        <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2"><h3 className="fontSizeClass"> {t("Important Officials of Social Welfare & Urban Proverty Alleviation Dept")}. </h3></div>		   
            <div className="col-lg-12 about-head1">
              <div className="mayor-table2 mayor-table">		 	   
                <table className="table table-bordered"> 			
                  <thead>
                  <tr className="table-warning table-bor-2">				 
                      <th className="bd-wid" scope="col"> {t("Sl. No")}. </th>
                      <th scope="col"> {t("Name")} </th>
                      <th scope="col"> {t("Designation")} </th>
                      <th scope="col"> {t("Office Phone No")}. </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableData && tableData.map((item,index)=>{
                        return(
                          <tr>
                          <th scope="row"> {index+1} </th>
                          <td> {item.name} </td>
                          <td> {item.designation} </td>
                          <td> {item.office_no} </td>
                        </tr>
                        );
                      })
                    
                    }
                  </tbody>
                </table>
              </div>
            </div>		  
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>
</div>
  )
}

export default Social_Welfare_Urban_Dept