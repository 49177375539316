import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const MC_Circular_2016_17  = () => {
  const fileDownload=(fileStoreId)=>
        {
          
          let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
           axios
              .post(url,{"fileId": fileStoreId, "userId": "392" })
              .then((res) => {
                  console.log("response is --- ", res);
      
                  if(res && res.data && res.status === 200) {
                    const base64 = res.data[0] && res.data[0].base64_data;
                    const pdfData = 'data:application/pdf;base64,'+base64;
      
                    let html = '<html>' +
                        '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                        '<body>' +
                        '<iframe src="' + pdfData + '"></iframe>' +
                        '</body></html>';
                        console.log(html);
                    let a = window.open();
                    a.document.write(html);
                }
              })
              .catch((e) => {
                  // setLoader(false);
                  console.log("The error is ", e);
              })
        }
  const { t, i18n, ready } = useTranslation();
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const [DocumentService, setDocumentService] = React.useState(false);
  const [LicenseService, setLicenseService] = React.useState(false);

  const LicenseServiceToggle  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
    setLicenseService(!LicenseService);
  };
  const DocumentServiceToggle  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
    setDocumentService(!DocumentService);
    };
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
  <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      </div>
    </section>
    <section className="section inner-pad1">
      <div className="container " >
        <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <button type="button" class="btn nav-link pr-0" data-toggle="collapse" data-target="#demo">{t("Municipal Commissioner CIRCULAR")}<i className="bi bi-chevron-down " /></button>
                    <div id="demo" class="collapse">
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2024_25'> {t("MC's Circular")} 2024-25 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2023_24'> {t("MC's Circular")}2023-24</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2022_23'> {t("MC's Circular")}2022-23</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2021_22'> {t("MC's Circular")} 2021-22</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2020_21'>{t("MC's Circular")} 2020-21</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2019_20'>{t("MC's Circular")} 2019-20</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2018_19'> {t("MC's Circular")} 2018-19</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2017_18'>{t("MC's Circular")}2017-18</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass active1" to='/MC_Circular_2016_17'>{t("MC's Circular")} 2016-17</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2015_16'>{t("MC's Circular")} 2015-16</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2004_05'>{t("MC's Circular")} 2004-05</Link></li>
                    </div>
                  </li><li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Municipal_secretary_circular'>{t("MUNICIPAL SECRETARY'S CIRCULAR")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/CMF_Communique_2022_23'>{t("C M F & A's Circular and Communique - 2022-23")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Chief_Manager_Circular'>{t("Chief Manager (P)'s Circular")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Govt_memo'>{t("Govt Memo")}</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1558')}> {t("West Bengal Right to Public Service Act,2013")} </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1559')}>{t("Guidelines of Section 199(2) of KMC Act,1980 (as amended in 2019)")}  </a>
                  </li>
                  <li className="nav-item-list ">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("COMPENDIUM OF IMPORTANT DEPARTMENTAL CIRCULARS / ORDERS")}<i className="bi bi-chevron-down " /></button>
                      <div id="demo1" className="collapse">                        
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>1. Financial Year 2010-11 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>2. Financial Year 2011-12 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('870')}>3. Financial Year 2012-13 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('925')}>4. Financial Year 2013-14 </a>   
                      </div>
                  </li>
                  
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2 bas">
                  <h3 className="fontSizeClass"> {t("Circular")} </h3>
                </div>
                <div className="health-dept-table mayor-table">
                  <table
                    width="95%"
                    border={0}
                    align="center"
                    cellPadding={2}
                    cellSpacing={2}
                    className="table table-bordered officials" >
                    <tbody>
                      <tr
                        bgcolor="#302ba0"
                        tabIndex={0}
                        align="center"
                        valign="middle">
                        <td width="35%" className="text-white tble-brder-left">
                        {t("Circular No")}		
                        </td>
                        <td width="20%" className="text-white">
                        {t("Date")}		
                        </td>
                        <td width="45%" className="text-white tble-brder-right">
                        {t("Subject")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1444')}>MC's Circular No. 84 of 2016-17	 </a>
                        </td>
                        <td align="center">
                        31-March-2017		
                        </td>
                        <td align="center">
                        File arrangement in proper order by the departments/offices of the KMC
                        </td>
                      </tr>                    
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1445')}>MC's Circular No. 83 of 2016-17</a>
                        </td>
                        <td align="center">
                        17-March-2017			
                        </td>
                        <td align="center">
                        Amendments in Employees State Insurance(Central) Rules,1950
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1446')}>MC's Office Order No. 15 of 2016-17</a>
                        </td>
                        <td align="center">
                        14-March-2017		
                        </td>
                        <td align="center">
                        Municipal Commissioner's Office Order No. 15 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1447')}>MC's Circular No. 82 of 2016-17</a>
                        </td>
                        <td align="center">
                        14-March-2017		
                        </td>
                        <td align="center">
                        Corrigendum to Mpl.Commissioner's Circular No 71 of 2016-17 dated 15.02.217 regarding the meeting Schedule to review new projects and schemes
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1448')}> MC's Circular No. 81 of 2016-17</a>
                        </td>
                        <td align="center">
                        14-March-2017		
                        </td>
                        <td align="center">
                        Municipal Commissioner's Circular No. 81 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1449')}>MC's Circular No. 79 of 2016-17</a>
                        </td>
                        <td align="center">
                        01-March-2017		
                        </td>
                        <td align="center">
                        Municipal Commissioner's Circular No. 79 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1450')}>MC's Circular No. 78 of 2016-17</a>
                        </td>
                        <td align="center">
                        23-February-2017		
                        </td>
                        <td align="center">
                        Municipal Commissioner's Circular No. 78 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1451')}>MC's Circular No. 77 of 2016-17</a>
                        </td>
                        <td align="center">23-February-2017		
                        </td>
                        <td align="center">Municipal Commissioner's Circular No. 77 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1452')}> MC's Circular No. 76 of 2016-17 </a>
                        </td>
                        <td align="center">20-February-2017	
                        </td>
                        <td align="center">
                        Municipal Commissioner's Circular No. 76 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1453')}>MC's Circular No. 75 of 2016-17</a>
                        </td>
                        <td align="center">20-February-2017	
                        </td>
                        <td align="center">
                        Municipal Commissioner's Circular No. 75 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1454')}> MC's Circular No. 74 of 2016-17	</a>
                        </td>
                        <td align="center">18-February-2017	
                        </td>
                        <td align="center">
                        Revised norms for acceptance of L1/H1 bid/single bid when the no of qualified bidders during 2nd call is less than 3
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1455')}>MC's Circular No. 72 of 2016-17</a>
                        </td>
                        <td align="center">15-February-2017	
                        </td>
                        <td align="center">
                        Municipal Commissioner's Circular No. 72 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1456')}>MC's Circular No. 68 of 2016-17 </a>
                        </td>
                        <td align="center">16-January-2017	
                        </td>
                        <td align="center">
                        Implementation of SOMOBYATHI Scheme
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1457')}>MC's Circular No. 65 of 2016-17</a>
                        </td>
                        <td align="center">27-December-2016	
                        </td>
                        <td align="center">
                        Municipal Commissioner's Circular No. 65 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1458')}> MC's Circular No. 64 of 2016-17 </a>
                        </td>
                        <td align="center">MC_Circuler64_24_12_2016	
                        </td>
                        <td align="center">
                        22-December-2016	</td>                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1459')}>SUO-MOTU DECISION Item No. MOA-33.5</a>
                        </td>
                        <td align="center">02-December-2016	
                        </td>
                        <td align="center">
                        SUO-MOTU DECISION Item No. MOA-33.5
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1460')}> MC's Circular No. 63 of 2016-17	</a>
                        </td>
                        <td align="center">13-December-2016	
                        </td>
                        <td align="center">Municipal Commissioner's Circular No. 63 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1461')}>MC's Circular No. 62 of 2016-17</a>
                        </td>
                        <td align="center">10-December-2016	
                        </td>
                        <td align="center">Municipal Commissioner's Circular No. 62 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1462')}>MC's Circular No. 61 of 2016-17</a>
                        </td>
                        <td align="center">10-December-2016	
                        </td>
                        <td align="center">Municipal Commissioner's Circular No. 61 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1463')}>MC's Circular No. 58 of 2016-17</a>
                        </td>
                        <td align="center">26-November-2016	
                        </td>
                        <td align="center">
                        Municipal Commissioner's Circular No. 58 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1464')}>MC's Circular No. 57 of 2016-17	</a>
                        </td>
                        <td align="center">
                        25-November-2016	
                        </td>
                        <td align="center">
                        Municipal Commissioner's Circular No. 57 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1465')}>MC's Circular No. 56 of 2016-17	</a>
                        </td>
                        <td align="center">25-November-2016	
                        </td>
                        <td align="center">Municipal Commissioner's Circular No. 56 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1466')}>MC's Circular No. 55 of 2016-17	</a>
                        </td>
                        <td align="center">23-November-2016	
                        </td>
                        <td align="center">
                        Municipal Commissioner's Circular No. 55 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1467')}>MC's Circular No. 54 of 2016-17	</a>
                        </td>
                        <td align="center">22-November-2016	
                        </td>
                        <td align="center">
                        Advance Drawal of Salary/Remuneration or Wages and payment in cash
 
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1468')}>MC's Circular No. 53 of 2016-17	</a>
                        </td>
                        <td align="center">15-November-2016	
                        </td>
                        <td align="center">Municipal Commissioner's Circular No. 53 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1469')}>MC's Circular No. 52 of 2016-17</a>
                        </td>
                        <td align="center">12-November-2016	
                        </td>
                        <td align="center">Municipal Commissioner's Circular No. 52 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1470')}>MC's Circular No. 47 of 2016-17	</a>
                        </td>
                        <td align="center">08-November-2016	
                        </td>
                        <td align="center">Municipal Commissioner's Circular No. 47 of 2016-17</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1471')}>MC's Circular No. 43 of 2016-17	</a>
                        </td>
                        <td align="center">04-October-2016	
                        </td>
                        <td align="center">Municipal Commissioner's Circular No. 43 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1472')}>MC's Circular No. 39 of 2016-17	</a>
                        </td>
                        <td align="center">28-September-2016	
                        </td>
                        <td align="center">Municipal Commissioner's Circular No. 39 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1473')}>MC's Circular No. 38 of 2016-17	</a>
                        </td>
                        <td align="center">28-September-2016	
                        </td>
                        <td align="center">
                        Municipal Commissioner's Circular No. 38 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1474')}>MC's Circular No. 34 of 2016-17	</a>
                        </td>
                        <td align="center">15-September-2016	
                        </td>
                        <td align="center">
                        Partial modification of Municipal Commissioner's Circular No. 31 of 2016-17 in connection with lodging case in the Municipal Magistrate Court by the conservancy (SWM) Department
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1475')}>Municipal Secretary's Circular No. 27 of 2016-17	</a>
                        </td>
                        <td align="center">28-September-2016	
                        </td>
                        <td align="center">Municipal Secretary's Circular No. 23 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1476')}>Municipal Secretary's Circular No. 23 of 2016-17</a>
                        </td>
                        <td align="center">09-September-2016	
                        </td>
                        <td align="center">Municipal Secretary's Circular No. 23 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1477')}> Municipal Secretary's Circular No. 22 of 2016-17 </a>
                        </td>
                        <td align="center">05-September-2016		
                        </td>
                        <td align="center">Municipal Secretary's Circular No. 22 of 2016-17</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1478')}>MC's Circular No. 33 of 2016-17	</a>
                        </td>
                        <td align="center">03-September-2016		
                        </td>
                        <td align="center">Municipal Commissioner's Circular No. 33 of 2016-17

                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1479')}>MC's Circular No. 32 of 2016-17	</a>
                        </td>
                        <td align="center">01-September-2016		
                        </td>
                        <td align="center">Audit Authority
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1480')}>MC's Circular No. 30 of 2016-17	</a>
                        </td>
                        <td align="center">30-August-2016		
                        </td>
                        <td align="center">Online receipt and refund of EMD of e-procurement through State Government e-procurement portal
                       </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1481')}>MC's Circular No. 28 of 2016-17	</a>
                        </td>
                        <td align="center">30-August-2016		
                        </td>
                        <td align="center">Municipal Commissioner's Circular No. 28 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1482')}>CMFA'S Circular no 05 of 2016-17	</a>
                        </td>
                        <td align="center">24-August-2016		
                        </td>
                        <td align="center">CMFA'S Circular no 05 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1483')}>MC's Circular No. 27 of 2016-17	</a>
                        </td>
                        <td align="center">22-August-2016		
                        </td>
                        <td align="center">Municipal Commissioner's Circular No. 27 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1484')}>MC's Circular No. 26 of 2016-17	</a>
                        </td>
                        <td align="center">19-August-2016		
                        </td>
                        <td align="center">Emergent instruments/apparatus arrangements during festive days (most particularly from October, 07th, 2016 to October, 16th,2016) 24*7 arrangements
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1485')}>MC's Circular No. 25 of 2016-17	</a>
                        </td>
                        <td align="center">19-August-2016	
                        </td>
                        <td align="center">
                        Emergent and superior-level personnel arrangements during festive days(most particularly from October, 07th, 2016 to October, 16th,2016)
                       </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1487')}>MC's Circular No. 12 of 2016-17	</a>
                        </td>
                        <td align="center">02-Jun-2016	
                        </td>
                        <td align="center">Preparation of an Administrative Calendar for implemaentation of schemes under the different departments of KMC.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1488')}> MC's Circular No. 11 of 2016-17	</a>
                        </td>
                        <td align="center">30-May-2016	
                        </td>
                        <td align="center">Municipal Commissioner's Circular No. 11 of 2016-17
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1489')}>MC's Circular No. 10 of 2016-17	</a>
                        </td>
                        <td align="center">30-May-2016		
                        </td>
                        <td align="center">
                        Municipal Commissioner's Circular No. 10 of 2016-17.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1490')}> MC's Circular No. 09 of 2016-17	</a>
                        </td>
                        <td align="center">28-May-2016		
                        </td>
                        <td align="center">
                        Prosecution of offender for depositing or through of rubbish including building rubbish,filthy,waste,offensive matter & polluted matter at any public street,Municipal drain,public place,land belonging to the Corporation or any unoccupied land or on the bank of water course .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1491')}>MC's Circular No. 06 of 2016-17	</a>
                        </td>
                        <td align="center">26-May-2016		
                        </td>
                        <td align="center">
                        Revised Schedule of the Monthly Review Meeting.
                        </td>
                      </tr>
                      </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default MC_Circular_2016_17