import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const CMF_Communique_2022_23 = () => {
  const fileDownload=(fileStoreId)=>
    {

      let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
        axios
          .post(url,{"fileId": fileStoreId, "userId": "392" })
          .then((res) => {
              console.log("response is --- ", res);

              if(res && res.data && res.status === 200) {
                const base64 = res.data[0] && res.data[0].base64_data;
                const pdfData = 'data:application/pdf;base64,'+base64;

                let html = '<html>' +
                    '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                    '<body>' +
                    '<iframe src="' + pdfData + '"></iframe>' +
                    '</body></html>';
                    console.log(html);
                let a = window.open();
                a.document.write(html);
            }
          })
          .catch((e) => {
              // setLoader(false);
              console.log("The error is ", e);
          })
    }
  const { t, i18n, ready } = useTranslation();
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const [DocumentService, setDocumentService] = React.useState(false);
  const [LicenseService, setLicenseService] = React.useState(false);

  const LicenseServiceToggle = () => {
    setLicenseService(!LicenseService);
  };
  const DocumentServiceToggle = () => {
    setDocumentService(!DocumentService);
    };
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
  <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      </div>
    </section>
    <section className="section inner-pad1">
      <div className="container " >
        <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <button type="button" class="btn nav-link pr-0" data-toggle="collapse" data-target="#demo">{t("Municipal Commissioner CIRCULAR")}<i className="bi bi-chevron-down " /></button>
                    <div id="demo" class="collapse">
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2024_25'> {t("MC's Circular")} 2024-25 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2023_24'> {t("MC's Circular")}2023-24</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2022_23'> {t("MC's Circular")}2022-23</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2021_22'> {t("MC's Circular")} 2021-22</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2020_21'>{t("MC's Circular")} 2020-21</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2019_20'>{t("MC's Circular")} 2019-20</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2018_19'> {t("MC's Circular")} 2018-19</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2017_18'>{t("MC's Circular")}2017-18</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2016_17'>{t("MC's Circular")} 2016-17</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2015_16'>{t("MC's Circular")} 2015-16</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2004_05'>{t("MC's Circular")} 2004-05</Link></li>
                    </div>
                  </li><li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Municipal_secretary_circular'>{t("MUNICIPAL SECRETARY'S CIRCULAR")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/CMF_Communique_2023_24'>{t("C M F & A's Circular and Communique - 2023-24")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass active1" to='/CMF_Communique_2022_23'>{t("C M F & A's Circular and Communique - 2022-23")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Chief_Manager_Circular'>{t("Chief Manager (P)'s Circular")}</Link>
                  </li>
                  <li className="nav-item-list ">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("COMPENDIUM OF IMPORTANT DEPARTMENTAL CIRCULARS / ORDERS")}<i className="bi bi-chevron-down " /></button>
                      <div id="demo" className="collapse">                        
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>1. {t("Financial Year 2010-11")} </a>
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>2. {t("Financial Year 2011-12")} </a>
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('870')}>3. {t("Financial Year 2012-13")} </a>
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('925')}>4. {t("Financial Year 2013-14")} </a>
                      </div>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Govt_memo'>{t("Govt Memo")}</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1558')}> {t("West Bengal Right to Public Service Act,2013")} </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1559')}>{t("Guidelines of Section 199(2) of KMC Act,1980 (as amended in 2019)")}  </a>
                  </li>
                  <li className="nav-item-list ">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("COMPENDIUM OF IMPORTANT DEPARTMENTAL CIRCULARS / ORDERS")}<i className="bi bi-chevron-down " /></button>
                      <div id="demo1" className="collapse">                        
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>1. Financial Year 2010-11 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>2. Financial Year 2011-12 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('870')}>3. Financial Year 2012-13 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('925')}>4. Financial Year 2013-14 </a>   
                      </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2 bas">
                  <h3 className="fontSizeClass"> {t("C M F & A's Circular and Communique' - 2022-23")} </h3>
                </div>
                <div className="health-dept-table mayor-table" style={{width:"100%"}}>
                  <table
                    width="95%"
                    border={0}
                    align="center"
                    cellPadding={2}
                    cellSpacing={2}
                    className="table table-bordered officials" >
                    <tbody>
                      <tr
                        bgcolor="#302ba0"
                        tabIndex={0}
                        align="center"
                        valign="middle">
                        <td width="35%" className="text-white tble-brder-left">
                        {t("Circular No")}		
                        </td>
                        <td width="20%" className="text-white">
                        {t("Date")}		
                        </td>
                        <td width="45%" className="text-white tble-brder-right">
                        {t("Subject")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1543')}> {t("C M F & A's Circular No. 18 of 2022-23")}	 </a>
                        </td>
                        <td align="center">{t("24-march-2023")}</td>
                        <td align="center">{t("Documents required for making adjustment of Advance Payment")}</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1544')}> {t("C M F & A's Circular No. 17 of 2022-23")} </a>
                        </td>
                        <td align="center">{t("06-february-2023")}	
                        </td>
                        <td align="center">{t("All Controlling Officers/HODs are requested to ensure submission of the bill to the IFUs in respect works/suppies within 25/02/2023")}
                        </td>
                      </tr>                    
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1545')}>{t("C M F & A's Circular No. 16 of 2022-23")}	</a>
                        </td>
                        <td align="center">{t("09-December-2022")}	
                        </td>
                        <td align="center">{t("Enhancement of Financial sanction limit of Sri Harihar Prasad Mandal,Municipal Secretary")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1546')}>{t("C M F & A's Circular No. 14 of 2022-23")}</a>
                        </td>
                        <td align="center">{t("03-November-2022")}	
                        </td>
                        <td align="center">{t("Discontinuation of benefit due to 5 (five) years post-training service for Teachers working at KMCP School under Education Dept., KMC")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1547')}> {t("C M F & A's Circular No. 13 of 2022-23")} </a>
                        </td>
                        <td align="center">{t("17-October-2022")}		
                        </td>
                        <td align="center">{t("All Controlling Officers/HODs/Br. Ex. Engineers are requested to submit the Budget Estimate of 2023-24 and Revised Estimate for 2022-23")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1548')}>{t("C M F & A's Communique' No. 04 of 2022-23")}	</a>
                        </td>
                        <td align="center">{t("20-September-2022")}		
                        </td>
                        <td align="center">{t("Modification of CMF&A's Circular No. 11 of 2022-23, Dated 06.08.2022")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1549')}>{t("C M F & A's Communique' No. 03 of 2022-23")}	</a>
                        </td>
                        <td align="center">{t("15- September-2022")}		
                        </td>
                        <td align="center">
                        {t("Earnest Money deposit of previous years")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1550')}>{t("C M F & A's Circular No. 12 of 2022-23")}	</a>
                        </td>
                        <td align="center">{t("23-August-2022")}		
                        </td>
                        <td align="center">
                        {t("All concerned are hereby requested to ensure deduction / collection of Tax at source as per prescribed rates in respect of payment / collection from the Payees of KMC (other than Salary) during the F.Y. 2022-23 under different sections of TDS / TCS provisions of Income Tax Act, 1961")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1551')}> {t("C M F & A's Communique' No. 2 of 2022-23")}	</a>
                        </td>
                        <td align="center">{t("20- August-2022")}		
                        </td>
                        <td align="center">{t("Regarding revised GST from 12% to 18% with effect from 18.07.2022 for Works Contract and framing of guideline of processing of Works file")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1552')}> {t("C M F & A's Circular No. 11 of 2022-23")}	</a>
                        </td>
                        <td align="center">{t("06- August-2022")}	
                        </td>
                        <td align="center">{t("It has been decided to stop issuance of Cheques in favour of KMC Officers, with immediate effect, except under unavoidable circumstances.")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("C M F & A's Circular No. 09 of 2022-23")}</a>
                        </td>
                        <td align="center">{t("30-July-2022")}	
                        </td>
                        <td align="center">{t("E-tender is mandatory for works and procurement of value of Rs. 1 lakh and above")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}>{t("C M F & A's Communique' No. 01 of 2022-23")}	</a>
                        </td>
                        <td align="center">{t("22-July-2022")}	
                        </td>
                        <td align="center">{t("Clarification of CMF&A's Circular No. 06 of 2022-23, Dated 14.07.2022")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}>{t("C M F & A's Circular No. 07 of 2022-23")}</a>
                        </td>
                        <td align="center">{t("21- July-2022")}	
                        </td>
                        <td align="center">{t("In reference to the Chief Manager (P)'s Circular No. 45/VIII/2021-22 dated 26.11.2021 and Memorandum No. 681/UDMA-25011(11)/18/2020-LSG, dated 28.06.2022 of Department of Urban Development and Municipal Affairs, GoWB, regarding upgradation of Scale of Pay of Deputy Chief Municipal Law Officer")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("C M F & A's Circular No. 06 of 2022-23")} </a>
                        </td>
                        <td align="center">{t("14- July-2022")}	
                        </td>
                        <td align="center">{t("Implementation of Memorandum No. 2320-F(Y), Dated 07.06.2022 on acceptance of Tender")}
                        </td>
                      </tr>
                      </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default CMF_Communique_2022_23