import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


import { textAlign } from '@mui/system';

const About_Kolkata = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const [ProjectService, setProjectService] = React.useState(false);
  const [MoreOneService, setMoreOneService] = React.useState(false);
  const [MoreTwoService, setMoreTwoService] = React.useState(false);
  const [MoreThreeService, setMoreThreeService] = React.useState(false);

  const ProjectServiceToggle = () => {
    setProjectService(!ProjectService);
  };
  const MoreOneServiceToggle = () => {
    setMoreOneService(!MoreOneService);
  };
  const MoreTwoServiceToggle = () => {
    setMoreTwoService(!MoreTwoService);
  };
  const MoreThreeServiceToggle = () => {
    setMoreThreeService(!MoreThreeService);
  };
  const { t, i18n, ready } = useTranslation();

  return (
    <>  
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"> <h4 className="fontSizeClass">{t("About_Kolkata")} </h4></div></div>             
    </div>       	 		 
  </section>	
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  ">           
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link active1" to='/About_Kolkata'> {t("About Kolkata")} </Link> </li>
                <li className="nav-item"><Link className="nav-link act" to='/map'> {t("Map")} </Link></li>
                <li className="nav-item"><Link className="nav-link" to='/History_Of_Kolkata'> {t("History")}</Link></li>
                <li className="nav-item">
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Places of Interest")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo" className="collapse">                        
                          <Link className="nav-link" to='/Art_Galleries'> 1. {t("Art Galleries")}</Link>
                          <Link className="nav-link" to='/Amusement_Parks'> 2. {t("Amusement Park")} </Link>
                          <Link className="nav-link" to='/Architecture'> 3. {t("Architecture")} </Link>
                          <Link className="nav-link" to='/Other_Noteable_place'> 4. {t("Other Notable Places")} </Link>
                      </div>
                </li>
                <li className="nav-item"><Link className="nav-link" to='/Basic_statestics'> {t("Basic Statistics")} </Link> </li>  
                <li className="nav-item"><Link className="nav-link" to='/UEVRP_Home'> {("UEVRP")} </Link></li>   
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass">{t("About_kolkata_heading")}</h3>
              </div>
              <div className="col-lg-12 about-head1">
                <h5 className="fontSizeClass"> <strong>{t("About_kolkata_subheadings")}</strong> </h5>		   
                <p className="fontSizeClass"> <strong> {t("About_kolkata_textbold")} </strong> </p>		
              </div>
              <div className="about-img3 mt-4">
                <img src="assets/img/History1.jpg" className="img-fluid" alt /> 
                <p className="fontSizeClass">{t("About_kolkata_text")}</p>
                <strong> 
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("More")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo1" className="collapse">                        
                          <p className="fontSizeClass" style={{fontWeight:"400"}}>{t("At first a smattering of mud huts,the settlement soon began to see the building of pucca houses. When the British leased the villages from the Sabarna Raychoudhuris for Rs 1300 a year, the settlement built an unostentatious fort for protection. After the turmoil of court intrigues and the usurpation of power, Calcutta began to grow and thrive. But the records of the time describes it as a 'pestilential city.' No lighting.Unmetalled roads. Untreated water. Open drains. High Mortality rate. Yet the city attracted many")}.</p> 
                          <p className="fontSizeClass" style={{fontWeight:"400",textAlign:"justify"}}>{t("In the early years,there was no civic or municipal authority in Calcutta.A Mayor's Court with judicial functions was established in 1726 by a Royal Charter. It undertook some civic work in order to attract dwellers to the city. On 12th August 1765 the East India Company was granted the Diwani of Bengal. This bestowed judicial and revenue-collecting functions upon it, and also implied a moral obligation to provide civic services. The demand for municipal services grew after 1773, when Calcutta was elevated as the capital of British India.Consequently, a sketchy conservancy system and a tiny police force were established.The city's governance was put in the hands of the Collector.In 1794, municipal administration was shifted from the Collector to the Justices of the Peace for the Town.It comprised of the Governor General, the members of his Council and the Judges of the Supreme Court. The Justices met the expenditure for conservancy and policing from a tax on houses and licence fees for the sale of liquor. The amount of house taxes realised in 1819 was a little over Rs 2.5 lakh")}.</p>
                          <p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("In the early part of the 19th Century the Governor-Generals tried to raise funds for improvements in the City by running Lotteries. These Lotteries funded new roads, improved old roads,dug tanks,and built a Town Hall")}.</p>
                          <p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("From the middle of the 19th Century attempts and experiments began to be made to establish a Municipal Corporation for Calcutta. It was felt that the city had grown and needed proper and specialised management to deal with its problems.Besides, now the 'second city of the Empire' needed to have improved infrastructure,and systems to enable it to solve its own problems")}.</p>
                          <p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("In 1847 the electoral system was introduced for the first time and the Justices were replaced by a Board of 7 paid members, four of whom were elected by the rate payers. The Board was authorised to purchase and hold property for improvement of the town, and to maintain roads and drains in proper state. In 1852, this Board was replaced by a new one of four members, of whom two were appointed by the Government and two were elected. Taxes were levied on housing, lighting, horses and vehicular traffic")}.</p>
                          <p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("In 1863 a new body was formed which elected its own Vice Chairman, and had a regular Health Officer, Engineer, Surveyor, Tax Collector and Assessor. It was during this time that the drainage and water supply were largely developed. The New Market was established in 1874, and the Municipal Slaughter House in1866. Footpaths were made along with the main roads, and many other improvements carried out. The house tax was raised to a maximum of ten percent. In 1876 a new Corporation was created with 72 Commissioners. 48 of the Commissioners were elected by ratepayers and 24 appointed by the Government. At this time the two railway terminals at Howrah and Sealdah were connected by Harrison Road. Changes continued in 1888 and 1899. The most important changes were instituted by the Act of 1923, sponsored by Rashtraguru Surendranath Bannerjee as the first Minister for Local Self-Government in Bengal")}.</p>
                          <p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("This Act liberalised the constitution on democratic lines, with the powers enlarged, and the fetters of Government control relaxed. A large area comprising Maniktala, Cossipur, Chitpur and Garden Reach, as well as the New Dock Extension area, was added to form 'Greater Calcutta.' Women were enfranchised for the first time under this Act. The Calcutta Municipal Corporation as a mark of respect, named the road on which the Central Corporation Office is located after him")}.</p>
                          <p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("The Emblem of the Corporation came into existence in 1896. It represented two Adjutant Birds holding in their beaks serpents and carrying a Crown on their shoulders. The new emblem of the corporation representing the new aspirations of the city was adopted on 22 February 1961")}.</p>
                      </div>
                </strong>  			 
              </div>		   
            
              <div className="about-img3 mt-4">
              <img src="assets/img/History2.jpg" className="img-fluid" alt /> 
              <p className="fontSizeClass">{t("About_kolkata_text1")}

                <strong>
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("More")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo2" className="collapse">                        
                      <p className="fontSizeClass" style={{fontWeight:"400",textAlign:"justify"}}>{t("A major amendment was introduced in 1984 to remove certain procedural difficulties in the matters of assessment and valuation of lands and buildings, recovery of taxes etc. Most significant was the introduction of 'Mayor-in-Council, with all the attributes of the Cabinet form of government. This was a unique step in the area of Municipal administration in India")}.</p>
                      <p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("The objective of the new Act was to make the Corporation more efficient and effective. The change in the Municipal Authority ensured that there would be three authorities")}: <br /> a) {t("The Corporation")} b) <br />  {t("The Mayor-in-Council, and c) The Mayor")}.</p><p className="fontSizeClass">
                      </p><p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("The number of members of the Calcutta Municipal Corporation was to be 141 which elected Councillors from the 141 wards")}.</p>
                      <p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("After amalgamation of Joka with the number of members of the Calcutta Municipal Corporation was to be 144 which elected Councillors from the 144 wards")}.</p>
                      <p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("The Corporation as the apex body discharges its function through the Mayor-in-Council, consisting of the Mayor, Deputy Mayor and 10 other elected members of the CMC")}.</p>
                      <p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("The Mayor and the Chairman would be elected by the members who would hold office for 5 years")}.</p>
                      <p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("The Corporation would group the wards of the Corporation into Boroughs. Each Borough should have a Committee consisting of the Councillors elected from the respective wards of the Borough. The Councillors would elect one of them as the chairperson of the said Borough. The Borough Committees should be subject to the general supervision of the Mayor-in-Council, and look after functions such as water supply, drainage, collection and removal of solid waste, disinfection and health immunisation, bustee services, lighting, repairs of certain categories of roads, maintenance of parks, drains and gullies")}.</p>
                      <p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("The Act of 1980 thus formed the framework of the modern Corporation with the Municipal Commissioner as the Principal Executive Officer subject to the control and supervision of the Mayor as the Chief Executive Officer. He is assisted by a group of Senior Civil Officers comprising Joint Municipal Commissioners, Deputy Municipal Commissioners, Chief Engineers, Controller of Municipal Finance and Accounts, Chief Municipal Auditor and Municipal Secretary")}.</p>
                      <p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("The 74th Constitutional Amendment, 1992 makes provision for appropriate and adequate representation of all elements of society. The Amendment provided reservation of seats for the scheduled castes and tribes and for women. By the same Amendment, the State Legislatures would endow the CMC with the power to draw up plans for economic development and social justice. It was given the responsibility to plan for economic development and for improvement, upgradation and promotion of the social, cultural, educational and aesthetic aspects of life for the citizens of the City")}.</p>
                      <p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("Subsequent to the renaming of the city in 2001, the Corporation is now known as the Kolkata Municipal Corporation")}.</p>
                      <p className="fontSizeClass"style={{fontWeight:"400",textAlign:"justify"}}>{t("The ball which set rolling somewhen in the 18th Century is still traversing a path of changes and reforms to make Kolkata its denizen’s pride")}.</p>
                      </div>
                </strong>							
              </p> 				 
            </div>
            </div> 
          </div>
        </div>
        </div>
    </div>
  </section>
    </>
  )
}

export default About_Kolkata