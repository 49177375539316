import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios"; 

const E_Purashree = () => {
  const { t, i18n, ready } = useTranslation();
    const increaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
          var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
          var fontSize = parseFloat(style); 
          el[i].style.fontSize = (fontSize + 1) + 'px';
        }
      };
      const fileDownload = (fileStoreId) => {
        let url = `https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`;
        axios
          .post(url, { fileId: fileStoreId, userId: "392" })
          .then((res) => {
            console.log("response is --- ", res);

            if (res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = "data:application/pdf;base64," + base64;

              let html =
                "<html>" +
                "<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>" +
                "<body>" +
                '<iframe src="' +
                pdfData +
                '"></iframe>' +
                "</body></html>";
              console.log(html);
              let a = window.open();
              a.document.write(html);
            }
          })
          .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
          });
      };
      const decreaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
        var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
        var fontSize = parseFloat(style); 
        el[i].style.fontSize = (fontSize - 1) + 'px'; 
        }
      };
  return (
    <>
      <section id="hero" className="hero hero2">
        <div
          className="social-media wow slideInDown"
          style={{ visibility: "visible", animationName: "slideInDown" }}
        >
          <div className="facebook" rel="ebInfo" title="facebook">
            <a
              href="https://www.facebook.com/KolkataMunicipalCorporation.official"
              target="_blank"
            >
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a
              href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp"
              target="_blank"
            >
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" href="" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>

        <div className="carousel-item active">
          <img
            src="assets/img/inner-bg2.png"
            className="img-fluid"
            alt="image"
          />
          <div className="container">
            <div className="section-main-heading2">
              <h4 className="fontSizeClass"> {t("E Publication")} </h4>
            </div>
          </div>
        </div>
      </section>
      {/* End Slider */}
      <section className="section inner-pad1">
        <div className="container ">
          <div className="row">
            <div className="col-lg-3">
              <div className="site-menu  ">
                <nav className="sidebar card py-2">
                  <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/E_News_Letter"
                      >
                        {" "}
                        {t("E-News Letter")}{" "}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link active1 fontSizeClass"
                        to="/E_Purashree"
                      >
                        {" "}
                        {t("E-Purashree")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Archive">
                        {" "}
                        {t("Archive")}{" "}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Monsoon_Book"
                      >
                        {" "}
                        {t("Monsoon Book")}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-9 ">
              <div className="dig-login1 dig-form ">
                <div className="min-height-400">
                  <div className="inner-title2">
                    <h3 className="fontSizeClass"> {t("E Publication")} </h3>
                  </div>
                  <div className="col-lg-12 about-head1">
                    <div className="inner-title3">
                      <h3 className="fontSizeClass">
                        {" "}
                        {t("KMC Publications")}{" "}
                      </h3>
                    </div>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_062023_I&noPage=40"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : 01 JULY 2022
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_052023_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : 16 JUNE 2022
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_052023_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : 01 JUNE 2022
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_042023_II&noPage=44"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : 16 MAY 2022
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_042023_I&noPage=44"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : 01 MAY 2022
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_032023_II&noPage=44"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : 16 APRIL 2022
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_032023_I&noPage=40"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : 01 APRIL 2022
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_022023_II&noPage=44"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : 16 MARCH 2022
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_012023_I&noPage=16"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : 16 FEBRUARY 2022
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_012023_II&noPage=44"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : 16 JANUARY 2022
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_072022_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : 01 DECEMBER 2021
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_092021_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : 01 SEPTEMBER 2021
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_052021_I&noPage=40"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : 05 MAY 2021
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_022021_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : 01 FEBRUARY 2021
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_012021_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : 01 JANUARY 2021
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_122020_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 DECEMBER - 15 DECEMBER 2020
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_112020_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 NOVEMBER - 15 NOVEMBER 2020
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_092020_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 SEPTEMBER - 15 SEPTEMBER 2020
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_082020_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 AUGUST - 15 AUGUST 2020
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_072020_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 JULY - 15 JULY 2020
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_032020_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 MARCH - 31 MARCH 2020
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_032020_I&noPage=64"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 MARCH - 15 MARCH 2020
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_022020_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 FEBRUARY - 29 FEBRUARY 2020
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_022020_I&noPage=84"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 FEBRUARY - 15 FEBRUARY 2020
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_122019_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 DEC - 31 DEC 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_122019_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 DEC - 15 DEC 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_112019_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 NOV - 30 NOV 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_112019_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 NOV - 15 NOV 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_092019_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 SEP - 15 SEP 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_082019_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 AUG - 31 AUG 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_082019_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 AUG - 15 AUG 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_072019_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 JULY - 31 JULY 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_072019_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 JULY - 15 JULY 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_062019_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 JUNE - 31 JUNE 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_062019_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 JUNE - 15 JUNE 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_052019_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 MAY - 31 MAY 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_042019_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :1 MAY - 15 MAY 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_042019_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 APRIL - 30 APRIL 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_032019_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 APRIL - 15 APRIL 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_022019_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 MARCH - 31 MARCH 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_022019_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 MARCH - 15 MARCH 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_012019_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 FEBRUARY - 28 FEBRUARY 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_012019_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 FEBRUARY - 15 FEBRUARY 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_122018_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 JANUARY - 31 JANUARY 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_122018_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 JANUARY - 15 JANUARY 2019
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_112018_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 DECEMBER - 31 DECEMBER 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_092018_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 DECEMBER - 15 DECEMBER 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_092018_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 NOVEMBER - 30 NOVEMBER 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_082018_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 SEPTEMBER - 30 SEPTEMBER 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_082018_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 SEPTEMBER - 30 SEPTEMBER 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_072018_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 AUGUST - 31 AUGUST 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_072018_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 AUGUST - 15 AUGUST 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_062018_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 JULY - 31 JULY 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_062018_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 JULY - 15 JULY 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_052018_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 JUNE - 30 JUNE 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_052018_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 JUNE - 15 JUNE 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_042018_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 MAY - 31 MAY 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_042018_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 MAY - 15 MAY 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_032018_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 APRIL - 30 APRIL 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_042018_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 APRIL - 15 APRIL 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_032018_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 MARCH - 31 MARCH 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_032018_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 MARCH - 15 MARCH 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_022018_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 FEBRUARY - 28 FEBRUARY 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_022018_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 FEBRUARY - 15 FEBRUARY 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_012018_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 JANUARY - 15 JANUARY 2018
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_122017_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 DECEMBER - 31 DECEMBER 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_112017_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 NOVEMBER - 30 NOVEMBER 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_112017_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 NOVEMBER - 15 NOVEMBER 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_102017_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 OCTOBER - 31 OCTOBER 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_092017_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 SEPTEMBER - 15 SEPTEMBER 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_082017_II&noPage=182"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 AUGUST - 31 AUGUST 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_082017_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 AUGUST - 15 AUGUST 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_072017_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 JULY - 31 JULY 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_072017_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 JULY - 15 JULY 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_062017_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 JUN - 30 JUN 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_062017_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 JUN - 15 JUN 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_052017_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 MAY - 31 MAY 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_052017_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 MAY - 15 MAY 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_042017_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 APRIL - 30 APRIL 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_042017_I&noPage=152"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 APRIL - 15 APRIL 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_032017_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 MARCH - 31 MARCH 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_032017_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 MARCH - 15 MARCH 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_022017_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 FEBRUARY - 28 FEBRUARY 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_022017_I&noPage=180"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 FEBRUARY - 15 FEBRUARY 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_012017_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 JANUARY - 31 JANUARY 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_012017_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 JANUARY - 15 JANUARY 2017
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_122016_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 DECEMBER - 31 DECEMBER 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_122016_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 DECEMBER - 15 DECEMBER 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_112016_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 NOVEMBER - 16 NOVEMBER 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_092016_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 SEPTEMEBR - 30 SEPTEMEBR 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_092016_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 SEPTEMEBR - 15 SEPTEMEBR 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_082016_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 AUGUST - 31 AUGUST 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_082016_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 AUGUST - 15 AUGUST 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_072016_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 JULY - 31 JULY 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_072016_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 JULY - 15 JULY 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_062016_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 JUN - 30 JUN 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_062016_I&noPage=104"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 JUN - 15 JUN 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_052016_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 MAY - 31 MAY 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_052016_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 MAY - 15 MAY 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_042016_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 APRIL - 30 APRIL 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_042016_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 MARCH - 31 MARCH 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_032016_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 MARCH - 15 MARCH 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_032016_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 FEBRUARY - 29 FEBRUARY 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_022016_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 FEBRUARY - 15 FEBRUARY 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_022016_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 JANUARY - 31 JANUARY 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_012016_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 JANUARY - 15 JANUARY 2016
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_012016_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 DECEMBER - 31 DECEMBER 2015
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_122015_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 DECEMBER - 15 DECEMBER 2015
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_122015_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 NOVEMBER - 30 NOVEMBER 2015
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_112015_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 NOVEMBER - 16 NOVEMBER 2015
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_112015_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 SEPTEMBER - 30 SEPTEMBER 2015
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_092015_I&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :01 SEPTEMBER - 16 SEPTEMBER 2015
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/epubflip.jsp?val=PURASHREE_082015_II&noPage=48"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :16 AUGUST - 31 AUGUST 2015
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default E_Purashree