import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios"; 

const Archive = () => {
  const { t, i18n, ready } = useTranslation();
      const increaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
          var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
          var fontSize = parseFloat(style); 
          el[i].style.fontSize = (fontSize + 1) + 'px';
        }
      };
      const fileDownload = (fileStoreId) => {
        let url = `https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`;
        axios
          .post(url, { fileId: fileStoreId, userId: "392" })
          .then((res) => {
            console.log("response is --- ", res);

            if (res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = "data:application/pdf;base64," + base64;

              let html =
                "<html>" +
                "<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>" +
                "<body>" +
                '<iframe src="' +
                pdfData +
                '"></iframe>' +
                "</body></html>";
              console.log(html);
              let a = window.open();
              a.document.write(html);
            }
          })
          .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
          });
      };
      const decreaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
        var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
        var fontSize = parseFloat(style); 
        el[i].style.fontSize = (fontSize - 1) + 'px'; 
        }
      };
  return (
    <>
      <section id="hero" className="hero hero2">
        <div
          className="social-media wow slideInDown"
          style={{ visibility: "visible", animationName: "slideInDown" }}
        >
          <div className="facebook" rel="ebInfo" title="facebook">
            <a
              href="https://www.facebook.com/KolkataMunicipalCorporation.official"
              target="_blank"
            >
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a
              href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp"
              target="_blank"
            >
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>

        <div className="carousel-item active">
          <img
            src="assets/img/inner-bg2.png"
            className="img-fluid"
            alt="image"
          />
          <div className="container">
            <div className="section-main-heading2">
              <h4 className="fontSizeClass"> {t("E Publication")} </h4>
            </div>
          </div>
        </div>
      </section>
      {/* End Slider */}
      <section className="section inner-pad1">
        <div className="container ">
          <div className="row">
            <div className="col-lg-3">
              <div className="site-menu  ">
                <nav className="sidebar card py-2">
                  <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/E_News_Letter"
                      >
                        {" "}
                        {t("E-News Letter")}{" "}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/E_Purashree"
                      >
                        {" "}
                        {t("E-Purashree")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link active1 fontSizeClass"
                        to="/Archive"
                      >
                        {" "}
                        {t("Archive")}{" "}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Monsoon_Book"
                      >
                        {" "}
                        {t("Monsoon Book")}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-9 ">
              <div className="dig-login1 dig-form ">
                <div className="min-height-400">
                  <div className="inner-title2">
                    <h3 className="fontSizeClass"> {t("E Publication")} </h3>
                  </div>
                  <div className="col-lg-12 about-head1">
                    <div className="inner-title3">
                      <h3 className="fontSizeClass">
                        {" "}
                        {t("KMC Publications")}{" "}
                      </h3>
                    </div>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1976")}
                      >
                        PURASHREE [BENGALI] :16 AUGUST - 31 AUGUST 2015
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1977")}
                      >
                        PURASHREE [BENGALI] :1 AUGUST - 15 AUGUST 2015
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1978")}
                      >
                        PURASHREE [BENGALI] :16 JULY - 31 JULY 2015
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/purashree_july2015.pdf"
                      >
                        PURASHREE [BENGALI] :1 JULY - 15 JULY 2015
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_Election_Book_2015.pdf"
                        target="_blank"
                      >
                        Puroshree Election Issue Book 2015
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/purashree_feb2015.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :FEBRUARY 2015
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/purashree_jan_2015.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :JANUARY 2015
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/purashree_Oct_De_2014.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :OCTOBER - DECEMBER 2014
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/purashree_June_Augst_2014.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :JUNE - AUGST 2014
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/purashree_may_2014.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :MAY 2014
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/purashree_mar_apr_2014.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :MARCH - APRIL 2014
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/purashree_jan_feb_2014.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :JANUARY - FEBRUARY 2014
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/purashree_dec_2013.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :DECEMBER 2013
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/purashree_oct_nov_2013.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] :OCTOBER - NOVEMMBER 2013
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1986")}
                      >
                        REPORT ON KANYASHREE PRAKALPA AND KANYASHREE MELA
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1987")}
                      >
                        CSR Report from Parks and Square department for
                        maintenance by Corporate Authority
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1988")}
                      >
                        e Office
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/purashree_july_aug_2013.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : JULY-AUGUST 2013
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Purashree_june_2013.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : JUNE 2013
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1989")}
                      >
                        PURASHREE [BENGALI] : MARCH-APRIL 2013
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1990")}
                      >
                        PURASHREE [BENGALI] : FEBRUARY 2013
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1991")}
                      >
                        PURASHREE [BENGALI] : NOVEMMBER - DECEMBER 2012
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1992")}
                      >
                        PURASHREE [BENGALI] : SEPTEMBER 2012
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1993")}
                      >
                        PURASHREE [BENGALI] : JULY 2012
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1994")}
                      >
                        SHARODIA PURASHREE 2012
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1995")}
                      >
                        PURASHREE [BENGALI] : MAY 2012
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1996")}
                      >
                        Puroshree_May_2012
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1997")}
                      >
                        PURASHREE [BENGALI] : MARCH 2012
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1998")}
                      >
                        PURASHREE [BENGALI] : FEBRUARY 2012
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_January_2012.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : JANUARY 2012
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_December_2011.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : DECEMBER 2011
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_November_2011.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : NOVEMBER 2011
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_October_2011.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : OCTOBER 2011
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Sharodia_Purashree_2011.pdf"
                        target="_blank"
                      >
                        SHARODIA PURASHREE 2011
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_August_2011.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : AUGUST 2011
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_July_2011.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : JULY 2011
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_May_2011.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : MAY 2011
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_April_2011.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : APRIL 2011
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_March_2011.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : MARCH 2011
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_February_2011.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : FEBRUARY 2011
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_January_2011.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : JANUARY 2011
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_December_2010.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : DECEMBER 2010
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_November_2010.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : NOVEMBER 2010
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Sharodia_Purashree.pdf"
                        target="_blank"
                      >
                        SHARODIA PURASHREE 2010
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_September-October_2010.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : SEPTEMBER-OCTOBER 2010
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1999")}
                      >
                        PURASHREE SPECIAL ISSUE : AUGUST 2010
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_August_2010.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : AUGUST 2010
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_June-July_2010.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : JUNE-JULY 2010
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_May_2010.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : MAY 2010
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("2000")}
                      >
                        PURASHREE [BENGALI] : APRIL 2010
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Purashree_March_2010.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : MARCH 2010
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("2001")}
                      >
                        PURASHREE [HINDI] : MARCH 2010
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("2002")}
                      >
                        PURASHREE [URDU] : MARCH 2010
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Kallolini%20Kolkata.pdf"
                        target="_blank"
                      >
                        KALLOLINI KOLKATA
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/Puroshree_Feb2010.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : FEBRUARY 2010
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("2003")}
                      >
                        PURASHREE [BENGALI] : JANUARY 2010
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/PURASHREE_20DEC.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : DECEMBER 2009
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/PURASHREE_NOV.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : NOVEMBER 2009
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/PURASHREE_OCT.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : OCTOBER 2009
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/PURASHREE_SEP.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : SEPTEMBER 2009
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/PURASHREE_AUG.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : AUGUST 2009
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/PURASHREE_JUL.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : JULY 2009
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/PURASHREE_JUN.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : JUNE 2009
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/PURASHREE_MAY.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : MAY 2009
                      </a>
                    </h6>
                    <h6>
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/downloads/PURASHREE_APR.pdf"
                        target="_blank"
                      >
                        PURASHREE [BENGALI] : APRIL 2009
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Archive