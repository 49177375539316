import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Advertisement_Dept = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  const [onlineApplication, setonlineApplication] = React.useState(false);
  const onlineApplicationToggle = () => {
    setonlineApplication(!onlineApplication);
  };
  const [onlineDeclaration, setonlineDeclaration] = React.useState(false);
  const onlineDeclarationToggle = () => {
    setonlineDeclaration(!onlineDeclaration);
  };
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu   min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/KMC_Advertisement'> {t("Advertisement Home")} </Link>
                </li>
                <li className="nav-item">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Online Application")} </button>
                    <div id="demo" className="collapse">
                        <a href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/AdvertisementOnlineApplication.jsp' target="_blank"> {t("Online Advertisement Application")} </a>
                        <a href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/AdvertisementOnlineRenewApplication.jsp' target="_blank"> {t("Online Advertisement Renew Application")} </a>
                        <a href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineTerminateApplication.jsp' target="_blank"> {t("Online Advertisement Terminate Application")} </a>
                        <a href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemandPrint_online.jsp' target="_blank">  {t("Online Advertisement Application Demand Print")} </a>
                        <a href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalAdvertisementPayment_online.jsp' target="_blank"> {t("Online Advertisement Application Demand Pay")} </a>
                        <a href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineApplicationNOC.jsp' target="_blank"> {t("Online Advertisement Application NOC Print")}</a>
                    </div>
                </li>
                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("Online Declaration")} <i className="bi bi-chevron-down" /></button>
                    <div id="demo1" className="collapse">
                        <a href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineDeclaration.jsp'> 1. {t("Online Advertisement Declaration")} </a>
                        <a href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineDeclarationStatus.jsp'> 2. {t("Online Declaration Status")} </a>
                    </div>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to='/Advertisement_rates'> {t("Rates")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link" href='https://www.kmcgov.in/KMCPortal/jsp/AgentDetails.jsp'> {t("Know Your Agent Code")}</a>                </li>
                <li className="nav-item">
                  <Link className="nav-link" to='/Advertisement_download_forms'> {t("Download Forms")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link" href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemand.jsp'>{t("Check Demands Payable")} </a>                </li>
                <li className="nav-item">
                <a className="nav-link" href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemandPrint.jsp'> {t("Print Unpaid Bill")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemand.jsp'> {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemand.jsp'> {t("Reprint e-Receipt")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href='https://www.kmcgov.in/KMCPortal/jsp/KMCAdvertisementTaxCalculator.jsp'>{t("Advertisement Tax Calculator")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1" to='/Advertisement_Dept'> {t("Contact Details")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link" href='https://www.kmcgov.in/KMCPortal/jsp/ListHoardings1.jsp'> {t("List of Hoardings")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('240')}> {t("Empanelement Of Advertising Agencies")} </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas"><h3 className="fontSizeClass"> {t("Important Officials of Advertisement Department")}</h3></div>		   
              <div className="mayor-table">
                <table className="table table-bordered officials">
                  <tbody><tr bgcolor="#302ba0" tabIndex={0}>
                      <td width="10%" rowSpan={2} style={{"color":"#fff"}} align="center" valign="middle">{t("Sl. No")}.</td>
                      <td width="23%" rowSpan={2} style={{"color":"#fff"}}>{t("Name")}</td>
                      <td width="22%" rowSpan={2} style={{"color":"#fff"}}>{t("Designation")}</td>
                      <td colSpan={2} rowSpan={2} style={{"color":"#fff"}}> {t("Office Phone No")}.</td>
                    </tr>
                    <tr>
                      {/* <td width="20%" style="padding:3">Office</td>
					<td width="13%" style="padding:3">Resi.</td> */}
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff" tabIndex={0}>
                      <td>1.</td>
                      <td align="left">Bhaskar Bhattacharya</td>
                      <td align="left">Chief Manager</td>
                      <td align="left">2286-1260 <br />2286-1000, Extn. 2600</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff" tabIndex={0}> 
                      <td>2.</td>
                      <td align="left">Supriya Ghosh</td>
                      <td align="left">Manager</td>
                      <td align="left">2286-1000 Extn:2801</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>3.</td>
                      <td align="left">Sahanowaj Mollick</td>
                      <td align="left">Dy.Manager</td>
                      <td align="left">2286-1000, Extn. 2801</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>4.</td>
                      <td align="left">Abhimanyu Sundas</td>
                      <td align="left">Dy. Manager</td>
                      <td align="left">2286-1000, Extn. 2801</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>5.</td>
                      <td align="left">Amitava Chakraborty</td>
                      <td align="left">Assistant Manager</td>
                      <td align="left">2286-1000, Extn. 2625</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>6.</td>
                      <td align="left">Dipak Kr. Ghosh</td>
                      <td align="left">Assistant Manager</td>
                      <td align="left">2286-1000, Extn. 2625</td>
                    </tr> 
                  </tbody></table>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Advertisement_Dept