import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Municipal_secretary_circular = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
<img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
        <nav className="sidebar card py-2">
                      <ul className="nav flex-column" id="nav_accordion">
                        <li>
                        <button type="button" class="btn nav-link pr-0" data-toggle="collapse" data-target="#demo">{t("Municipal Commissioner CIRCULAR")}<i className="bi bi-chevron-down " /></button>
                          <div id="demo" class="collapse">
                          <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2023_24'> {t("MC's Circular 2023-24")}</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass " to='/MC_Circular_2022_23'> {t("MC's Circular 2022-23")}</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass " to='/MC_Circular_2021_22'> {t("MC's Circular")} 2021-22</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2020_21'>{t("MC's Circular")} 2020-21</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2019_20'>{t("MC's Circular")} 2019-20</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2018_19'> {t("MC's Circular")} 2018-19</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2017_18'>{t("MC's Circular 2017-18")}</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2016_17'>{t("MC's Circular")} 2016-17</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2015_16'>{t("MC's Circular")} 2015-16</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2004_05'>{t("MC's Circular")} 2004-05</Link></li>
                          </div>
                        </li><li className="nav-item">
                          <Link className="nav-link fontSizeClass active1" to='/Municipal_secretary_circular'>{t("MUNICIPAL SECRETARY'S CIRCULAR")}</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link fontSizeClass" to='/CMF_Communique_2022_23'>{t("C M F & A's Circular and Communique - 2022-23")}</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link fontSizeClass" to='/Chief_Manager_Circular'>{t("Chief Manager (P)'s Circular")}</Link>
                        </li>
                        <li className="nav-item ">
                        <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("COMPENDIUM OF IMPORTANT DEPARTMENTAL CIRCULARS / ORDERS")}<i className="bi bi-chevron-down " /></button>
                            <div id="demo1" className="collapse">                        
                              <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>1. {t("Financial Year 2010-11")} </a>
                              <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>2. {t("Financial Year 2011-12")} </a>
                              <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('870')}>3. {t("Financial Year 2012-13")} </a>
                              <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('925')}>4. {t("Financial Year 2013-14")} </a>    
                            </div>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link fontSizeClass" to='/Govt_memo'>{t("Govt Memo")}</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link fontSizeClass" to='/Borough_Committee'> {t("West Bengal Right to Public Service Act,2013")} </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link fontSizeClass" to='/Borough_Committee'>{t("Guidelines of Section 199(2) of KMC Act,1980 (as amended in 2019)")}  </Link>
                        </li>
                        
                      </ul>
                    </nav>
            </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Circular")} </h3>
              </div>
              <div className="health-dept-table" style={{width:"100%"}}>
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials" >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle">
                      <td width="35%" className="text-white tble-brder-left">
                      {t("Circular No")}		
                      </td>
                      <td width="20%" className="text-white">
                      {t("Date")}		
                      </td>
                      <td width="45%" className="text-white tble-brder-right">
                      {t("Subject")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1952")}>{t("MUNICIPAL SECRETARY'S CIRCULAR NUMBER 32 OF 2022-23")}</a>
                      </td>
                      <td align="center">{t("06-february-2023")}	
                      </td>
                      <td align="center">{t("This is to inform all concerned that upon adoption of e-office and online services the demand for printing and paper requirements shuld have been substantially reduced.")}

                      </td>
                    </tr>                    
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1953")}>{t("MUNICIPAL SECRETARY'S CIRCULAR NUMBER 60 OF 2018-19")}</a>
                      </td>
                      <td align="center">{t("30-March-2019")}		
                      </td>
                      <td align="center">{t("MUNICIPAL SECRETARY'S CIRCULAR NUMBER 60 OF 2018-19")}
                      </td>
                    </tr>
                    
                    </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>
  )
}

export default Municipal_secretary_circular