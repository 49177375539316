import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Heritage_Proceedings_of_HCC  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          {/* <h4 className="fontSizeClass"> Proceedings of HCC </h4> */}
        </div>
      </div>
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
              <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/HeritageBuildingHome'> {t("Heritage Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Exemption_of_Tax'> {t("Exemption of Tax")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Heritage_Buildings'> {t("Heritage Buildings")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Conservation_Architect'> {t("Conservation Architect")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('473')}>{t("Composition of the Heritage Conservation Committee")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Heritage_Proceedings_of_HCC'>
                    
                    {t("Proceedings of HCC")}
                  </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('474')}>{t("Action Plan")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('475')}>{t("Parking Policy")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('476')}>{t("Measures for prevention of landfill fire")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWisePond.jsp'>  {t("Ward wise Pond")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWiseHeritageBuilding.jsp'> {t("Ward Wise Heritage Building")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Environment_Heritage_Dept'> {t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Activities_NCAP.pdf' target={'_blank'}> {t("Activities Permitted under NCAP")} </a>
                </li>

                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Swachh Vayu Survekshan")} <i className="bi bi-chevron-down" /></button>
                    <div id="demo2" className="collapse">
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessed_CPCB_scores_23_09_2023.xlsx'>1. {t("Self assessed scores & CPCB scores")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessment_reports_Kolkatacity_23_09_2023.pdf'>2. {t("Self assessment reports of Kolkata city2023")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Swachh_Vayu_Survekshan_23_09_2023.pdf'>3. {t("Swachh Vayu Survekshan 2023 Rank")} </a>
                    </div>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/airquality/citizenreport.do' target={'_blank'}> {t("Air quality status")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/emis/category.do' target={'_blank'}> {t(" Industry categorisation")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/District_Environment_ KMC2021_23_09_2023.pdf' target={'_blank'}> {t("FINAL District Environment plan KMC 2021")} </a>
                </li>


              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="">
            <div className="row">
              <div className="inner-title2 bas" style={{marginLeft:"10px"}}>
                <h3 className="fontSizeClass"> {t("Proceedings of HCC")} </h3>
              </div>
              <div className="mayor-table pump-list-tble Heritage_Conservation_Architect">
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"
                    >
                      <td width="100%" className="text-white tble-brder-left">
                        
                        {t("Name")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('480')}>
                        {t("1 & 1A, Cathedral Road, Ward No.-63")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('481')}>
                        {t("1, Clive Buildings, Ward No.-45 (1)")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('482')}>{t("1,Hogg Street, CVF Block")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('483')}>{t("1,R N Mukherjee Road, Ward No.-46")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('484')}>{t("1,R N Mukherjee Road, Ward No.-46")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('485')}>{t("2, Camac Street, Ward No.-63")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('486')}>{t("3, Lovelock Place, Ward No.-69")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('487')}>{t("4, Bepin Pal Road, Ward No.-84")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('488')}>
                        {t("5, Jawaharlal Nehru Road, Ward No.-63")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('489')}>{t("6, Lyons Range, Ward No.-45")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('490')}>
                        {t("6, Lyons Range, Ward No.-45_29.09.20")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('491')}>
                        {t("7,William Jones Sarani, Ward No.-63_29.09.20")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('492')}>
                        {t("8E, James Hickey Sarani, Ward No.-46")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('493')}>{t("12, Binode Saha Lane, Ward No.-27")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('494')}>
                        {t("12A, Netaji Subhas Road, Ward No.-45")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('495')}>
                        {t("13, Bankim Chatterjee Street, Ward No.-40")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('496')}>{t("13, Wood Street, Ward No.-63")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('497')}>{t("13, Wood Street, Ward No.-63")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('498')}>{t("13, Wood Street, Ward No.-63")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('499')}>
                        {t("13/1, Shibnarain Das Lane, Ward No.-38")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('500')}>
                        {t("13A, Shikdarbagan Street, Ward No.-11")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('501')}>{t("14, N S Road, Wrad No.-45")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('502')}>
                        {t("14, Netaji Subhas Road, Ward No.-45")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('503')}>
                        {t("14, Sarojini Naidu Sarani, Ward No.-63c")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('504')}>
                        {t("17, bal Mukund Mackar Road, Ward No.-415")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('505')}>
                        {t("17A, Ratan Sarkar Garden Street, Ward No.-22")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('506')}>{t("18, Judges Court Road, Ward No.-74")}</td>
                      </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('507')}>{t("18A, Park Street, Ward No.-63")}</td>
                      </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('508')}>
                        {t("18E, Srigopal Mallick Lane, Ward No.-40")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('509')}>{t("19, Nellie Sen Gupta Sarani")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('510')}>
                        {t("22/1/1A, Sudhir Chatterjee Street, Ward No.-26")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('511')}>
                        {t("23, Nirmal Chunder Street, Ward No.-47")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('512')}>
                        {t("24, Ballygunge Circular Road, Ward No.-69")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('513')}>{t("29C, Bentinck Street, Ward No.-46")}</td>
                     </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('514')}>
                        {t("31/1A & 31/1B, Nayan Chand Dutt Street, Ward No.-17")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('515')}>
                        {t("34I, Shyampukur Street, Ward No.-10")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('516')}>{t("37, Abhedananda Road, Ward No.-16")}</td>
                     </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('517')}>
                        {t("18 Uday Shankar Sarani000147, Pathuriaghat Street, Ward No.-24")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('518')}>
                        {t("49, Netaji Subhas Road, Ward No.-45")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('519')}>
                        {t("51, Netaji Subhas Road, Ward No.-45")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('520')}>
                        {t("52A, Indian Mirror Street, Ward No.-51")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('521')}>{t("59A, Park Street, Ward No.-63")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('522')}>
                        {t("71, 71B & 71C, Paddapukur Road, Ward No.-70")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('523')}>{t("76/1B, Bidhan Sarani, Ward No.-16")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('524')}>
                        {t("81C, Moulana Abul Kalam Azad Sarani, Ward No.-33")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('525')}>
                        {t("93/1, Acharya Prafulla Chandra Road, Ward No.-38")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('526')}>
                        {t("98, Dr. Lal Mohan Bhattacharyya Road, Ward No.-55")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('527')}>
                        {t("98, Shankar Ghosh Lane, Ward No.-38")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('528')}>
                        {t("113, Rash Behari Avenue, Ward No.-85")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('529')}>
                        {t("170, Acharyya Jagadish Bose Road, Ward No.-60")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('530')}>
                        {t("294/2/1, Acharya Prafulla chandra Road, Ward No.-28")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('531')}>
                        {t("General decision taken in HCC meeting dated 29.08.2019")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('532')}>
                        {t("Sri Sri Gopi Nath Jew Thakur Temple at 5, Ratan Babu Road")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('533')}>{t("SS Hogg Market B & NB Block")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('534')}>{t("SS Hogg Market Improvement of Roof")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('535')}>
                        {t("155A 1 AJC Bose Road0001SS Hogg Market Misc 152")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('536')}>
                        {t("SS Hogg Market MS Rolling Shutter Stall No. 201 E(N)-192")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('537')}>{t("")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('538')}>
                        {t("SS Hogg Market Structural Improvement")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('539')}>{t("SS Hogg Market WR(O) Block")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('540')}>
                        {t("Statesman House 4, Chowringhee Square, Ward No.-46")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('541')}>
                        {t("Temple at Santosh Roy Road near Barisha Clab")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('542')}>
                        {t("2/1, Ho Chi Minh Sarani, Ward No.-63")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('543')}>
                        {t("10, Saheed Nityananda Saha Sarani, Ward No.-45")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('544')}>{t("12, Park Street, Ward No.-63")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('545')}>{t("15/1, Strand Road,Ward No.-45")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('546')}>
                        {t("23, Nirmal Chunder Street, Ward No.-47")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('547')}>
                        {t("29, Netaji Subhas Road, Ward No.-45")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('548')}>{t("30, Park Street, Ward No.-63")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('549')}>{t("32A, Elliot Road, Ward No.-61")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('550')}>
                        {t("40A, Kashi Mitter Ghat Street, Ward No.-008")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('551')}>
                        {t("Declaration of 62/4A & 62/4B, Beadon Street, Ward No.-16")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('552')}>{t("138/2, Bidhan Sarani, Ward No.-10")}</td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('553')}>
                        {t("234/4, Acharya Jagadish Bose Road, Ward No.-70")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('554')}>
                        {t("243/1, Acharyya Prafulla Chandra Road, Ward No.-015")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('555')}>
                        {t("SS Hogg Market CVF Block")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('556')}>
                        {t("SS Hogg Market CVF Block Roof Drainage")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('557')}>
                        {t("SS Hogg Market E(N)-164 Stall No")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('558')}>
                        {t("SS Hogg market Food Cell")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('559')}>
                        {t("SS Hogg Market G Block")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('560')}>
                        {t("SS Hogg Market Hudco Building")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('561')}>
                        {t("SS Hogg Market NB Block Staff Quarter")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('562')}>
                        {t("SS Hogg Market New Complex")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('563')}>
                        {t("SS Hogg Market Old Complex")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('564')}>
                        {t("SS Hogg Market Renovation of wooden shelves")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('565')}>
                        {t("SS Hogg Market Repairing of Main Gate")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('566')}>
                        {t("SS Hogg Market repairing")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('567')}>
                        {t("SS Hogg Market Rolling Shutter")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('568')}>
                        {t("1A,1B./Belvedere road")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('569')}>
                        {t("2,Ballygunge Park")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('570')}>
                        {t("3,Lovelock Place,Ward-069")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('571')}>
                        {t("4,Sunny Park")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('572')}>
                        {t("4A,Chouringhee Place")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('573')}>
                        {t("6J,Bishop Lefroy Road,Ward-70")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('574')}>
                        {t("7,sir William Jones Sarani,Ward-063")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('575')}>
                        {t("7A&7B,Pratap Chatterjee Lane")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('576')}>
                        {t("8,Baishnab Sett Street")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('577')}>
                        {t("9,Circus Avenue")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('578')}>
                        {t("19,Nellie Sengupta Sarani")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('579')}>
                        {t("21,Dr.Suresh Sarkar Road")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('580')}>
                        {t("26,Strand Road,Ward-045")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('581')}>
                        {t("26A,Khudiram Bose Sarani")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('582')}>
                        {t("30A,Ratan Babu Road")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('583')}>
                        {t("45A,Hari Ghosh Street,Ward-017")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('584')}>
                        {t("67,Lenin sarani,Ward-51")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('585')}>
                        {t("80A,Bechu Chatterjee Street")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('586')}>
                        {t("93,Sri Aurobinda Sarani")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('587')}>
                        {t("118,Raja Ram Mohan Roy Sarani")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('588')}>
                        {t("224,A.J.C. Bose Road")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('590')}>
                        {t("external painting metropolitan bldg. and esplanade mansion")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('589')}>
                        {t("General discussion on Heritage Buildings at mpl.chambers on 28.07.2021")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('591')}>
                        {t("P-34,Garden Reach Road,Ward-135")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('592')}>
                        {t("RENOVATION STALL NO.152(MISC_II_A(p)) SS HOGGMARKET-0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('593')}>
                        {t("341 SHYAMPUKUR STREET WD-01")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('594')}>
                        {t("22 1 1A SUDHIR CHATERJEE STREET WD-026")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('595')}>
                        {t("18A PARK STREET WD-063")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('596')}>
                        {t("17A RATAN SARKAR GARDEN STREET WD-022")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('597')}>
                        {t("14 NETAJI SUBHAS ROAD WD-045")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('598')}>
                        {t("3 LOVELOCK PLACE WD-069")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('599')}>
                        {t("1 RAJENDRA NATH MUKHERJEE ROAD WD-046")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('600')}>
                        {t("1 CLIVE BUILDINGS WD-045")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('601')}>
                        {t("ROLLING SHUTTER STAFF QUARTER SS HOGG MAR-01")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('602')}>
                        {t("IMPROVEMENT WR(O) BLOCK SS HOGG MARKET-001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('')}>
                        {t("IMPROVEMENT OF ROOF BLOCK B, NB SS HOGG MARKET-01")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('603')}>
                        {t("13A SIKDARBAGAN STREET_WD-011")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('604')}>
                        {t("18 JUDGES COURT ROAD-74")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('605')}>
                        {t("23 NIRMAL CHUNDER STREET WARD-047")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('606')}>
                        {t("1 Hogg Street,CVF Block0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('607')}>
                        {t("1 Rajendra Nath Mukherjee Rd0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('608')}>
                        {t("1 1D Prem Chand Boral St.0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('609')}>
                        {t("4 Chowringhee Square0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('610')}>
                        {t("6 Ashutosh De Lane0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('612')}>
                        {t("6 1 Nellie Sengupta Sarani0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('613')}>
                        {t("7 Harish Ch Paul Lane0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('614')}>
                        {t("8 Baishnab Sett Street0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('615')}>
                        {t("8 Raja Gurudas St.0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('616')}>
                        {t("8 Red Cross Place0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('617')}>
                        {t("9 2G Khelat Ghose Lane0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('618')}>
                        {t("9B Raja Kali Krishna Lane0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('619')}>
                        {t("10 Ramesh Dutt Street0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('620')}>
                        {t("12 Kailash Bose Street0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('621')}>
                        {t("13 2B, Balaram Ghosh St.0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('622')}>
                        {t("14, N. S. Road0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('623')}>
                        {t("16A Kali Prosad Chakraborty St.0001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('624')}>
                        {t("17 Bal Mukund Mackar Rd 11.07.190001")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('625')}>
                        {t("17 Bal Mukund Mackar Rd 29.08.19")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('626')}>
                        {t("17 Bal Mukund Mackar Road 10.01.190001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('627')}>
                        {t("17 Ratan Sarkar Garden St.0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('628')}>
                        {t("18 Uday Shankar Sarani0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('629')}>
                        {t("18 39 Jamini Roy Sarani0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('630')}>
                        {t("18A Srigopal Mallick Lane0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('631')}>
                        {t("19 Nellie Sen Gupta Sarani0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('632')}>
                        {t("20A Karl Marx Sarani0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('633')}>
                        {t("24 1 Maharshi Debendra Rd0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('634')}>
                        {t("25B Vivekananda Road0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('635')}>
                        {t("34 Surya Sen Street0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('636')}>
                        {t("34F Shyampukur St.0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('637')}>
                        {t("45A Hari Ghosh St.0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('638')}>
                        {t("47 N. S. RD0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('639')}>
                        {t("53B Diamond Road0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('640')}>
                        {t("55 N. S. RD0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('641')}>
                        {t("60 14 Gouribari Lane0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('642')}>
                        {t("81C Moulana Abul Kalam Azad Sarani0002")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('643')}>
                        {t("120 Deshpran Shasmal Rd 11.07.190001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('644')}>
                        {t("120 Deshpran Shasmal Rd 20.09.19")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('645')}>
                        {t("120 2 APC Road0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('646')}>
                        {t("155A 1 AJC Bose Road0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('647')}>
                        {t("170 2D AJC Bose Road0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('648')}>
                        {t("211 Bidhan Sarani0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('649')}>
                        {t("Damaged Terrace above F Block-Hogg Market0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('650')}>
                        {t("Desicions taken as a follow up of HCC resolutions0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('651')}>
                        {t("Heritage Ghat at Adi Ganga00010001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('652')}>
                        {t("Nimtola Burning Ghat Wooden Pyres0001")}
                      </td>
                    </tr><tr tabIndex={0} align="center" valign="top">
                      <td align="left" onClick={(e)=>fileDownload('653')}>
                        {t("P-431 Hemanta Mukhopadhyay sarani0001")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Heritage_Proceedings_of_HCC