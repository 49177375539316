import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Building_Sanction_Circular_2021_22  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2 mb-4">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2022_23'> {t("BLDG's Circular No.of 2022-23")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Building_Sanction_Circular_2021_22'> {t("BLDG's Circular No.of 2021-22")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2020_21'> {t("BLDG's Circular No.of 2020-21")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2019_20'> {t("BLDG's Circular No.of 2019-20")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2017_18'>{t("DG(BLDG)'s Circular No.of 2017-18")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2016_17'> {t("DG(BLDG)'s Circular No.of 2016-17")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2015_16'> {t("DG(BLDG)'s Circular No.of 2015-16")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2014_15'>{t("DG(BLDG)'s Circular No.of 2014-15")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2013_14'>{t("DG(BLDG)'s Circular No.of 2013-14")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2011_12'> {t("DG(BLDG)'s Circular No.of 2011-12")}  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2010_11'> {t("DG(BLDG)'s Circular No.of 2010-11")} </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Circular")} :: 2021-22</h3>
              </div>
              <div className="mayor-table building-dept-table">
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials">
<tbody>
                    <tr bgcolor="#302ba0" align="center" valign="middle">
                      <td width="35%" className="text-white tble-brder-left">
                        {t("Circular No")}
                      </td>
                      <td width="16%" className="text-white">
                        {t("Date")}
                      </td>
                      <td width="49%" className="text-white tble-brder-right">
                        {t("Subject")}
                      </td>
                    </tr>
                    <tr>
                      <td valign="middle">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('346')}> {t("DG(P&S) Office Order")} <br />
                          {t("Ref. No.")} DG/P&amp;S/300./2021-2022 </a>
                      </td>
                      <td align="center" valign="middle">
                        31/12/2022
                      </td>
                      <td valign="middle">
                        {t("Guideline for issuance of 'no-ob.jection certificate' related to cutting / felling of trees under parks &amp; squares department")}
                      </td>
                    </tr>
                    <tr>
                      <td valign="middle">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('347')}> {t("DG(BLDG)'s Circular No. 08 of 2021-2022")} </a>
                     </td>
                      <td valign="middle" align="center">
                        25/11/2021
                      </td>
                      <td valign="middle">
                        {t("Statutory warning (reminder) for prevention of air pollution by construction industries specially during winter months Circular No. 08 of 2021-22")}
                      </td>
                    </tr>
                    <tr>
                      <td valign="middle">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('348')}> {t("DG(BLDG)'s Circular No. 07 of 2021-2022")} </a>
                      </td>
                      <td valign="middle" align="center">
                        25/11/2021
                      </td>
                      <td valign="middle">
                        {t("Management of Dilapidated building us 412A Circular No. 07 of 2021-22")}
                      </td>
                    </tr>
                    <tr>
                      <td valign="middle">  
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('349')}> {t("DG(BLDG)'s Circular No. 06 of 2021-2022")} </a>
                      </td>
                      <td valign="middle" align="center">
                        09/11/2021
                      </td>
                      <td valign="middle">
                        {t("Size of lift Circular No. 06 of 2021-22")}
                      </td>
                    </tr>
                    <tr>
                      <td valign="middle">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('350')}> {t("DG(BLDG)'s Circular No. 05 of 2021-2022")} </a>
                      </td>
                      <td valign="middle" align="center">
                        08/11/2021
                      </td>
                      <td valign="middle">
                        {t("NOC from Water supply and Drainage Department Circular No. 05 of 2021-22")}
                      </td>
                    </tr>
                    <tr>
                      <td valign="middle">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('351')}> {t("DG(BLDG)'s Circular No. 04 of 2021-2022")} </a>
                      </td>
                      <td valign="middle" align="center">
                        25/08/2021
                      </td>
                      <td valign="middle">
                        {t("Mobile Tower online permission Circular No. 04 of 2021-22")}
                      </td>
                    </tr>
                    <tr>
                      <td valign="middle">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('352')}> {t("DG(BLDG)'s Circular No. 03 of 2021-2022")} </a>
                      </td>
                      <td valign="middle" align="center">
                        13/08/2021
                      </td>
                      <td valign="middle">
                        {t("Time extension Circular No. 03 of 2021-22")}
                      </td>
                    </tr>
                    <tr>
                      <td valign="middle">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('353')}> {t("DG(BLDG)'s Circular No. 02 of 2021-2022")} </a>
                      </td>
                      <td valign="middle" align="center">
                        27/07/2021
                      </td>
                      <td valign="middle">
                        {t("FAR touching EM Bye pass Circular No. 02 of 2021-22")}
                      </td>
                    </tr>
                    <tr>
                      <td valign="middle">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('354')}> {t("DG(BLDG)'s Circular No. 01 of 2021-2022")} </a>
                      </td>
                      <td valign="middle" align="center">
                        27/07/2021
                      </td>
                      <td valign="middle">
                        {t("Renual of building plan Circular No. 01 of 2021-22")} 
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Building_Sanction_Circular_2021_22