const refererProperties = {
    LOCAL : {
        REFERER: 'https://kmcdev.ddns.net/licmgm/',
        SHA_KEY: 'F^&$^%*&G^E$#!#$*&(*&)(_I*HNMKBJK',
        APP_ID: 'ekmchr',
        TENANT_ID: 'km',
        LOCALE: 'en_IN',
        MODULE: 'rainmaker-common',
    },
    DEV: {
        REFERER: 'https://kmcdev.ddns.net/licmgm/',
        SHA_KEY: 'F^&$^%*&G^E$#!#$*&(*&)(_I*HNMKBJK',
        APP_ID: 'ekmchr',
        TENANT_ID: 'km',
        LOCALE: 'en_IN',
        MODULE: 'rainmaker-common',
    }
};

export default refererProperties;