import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const KMC_Advertisement_reprint_e_receipts = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  const [onlineApplication, setonlineApplication] = React.useState(false);
  const onlineApplicationToggle = () => {
    setonlineApplication(!onlineApplication);
  };
  const [onlineDeclaration, setonlineDeclaration] = React.useState(false);
  const onlineDeclarationToggle = () => {
    setonlineDeclaration(!onlineDeclaration);
  };
  return (
    <>
        <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
          
          <div className="carousel-item active">
            <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
            <div className="container"> <div className="section-main-heading2"> <h4 className="fontSizeClass"> KMC Advertisement </h4></div></div>             
          </div>                  
        </section>      
        {/* End Slider */}

        <section className="section inner-pad1">
          <div className="container " >
            <div className="row">
              <div className="col-lg-3">
              <div className="site-menu   min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/KMC_Advertisement'> Advertisement Home </Link>
                </li>
                <li className="nav-item">
                     <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">Online Application<i className="bi bi-chevron-down" /></button>
                      <div id="demo2" className="collapse">                        
                        <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/AdvertisementOnlineApplication.jsp'>1. Online Advertisement Application </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/AdvertisementOnlineRenewApplication.jsp'>2. Online Advertisement Renew Application </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineTerminateApplication.jsp'>3. Online Advertisement Terminate Application </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemandPrint_online.jsp'>4.  Online Advertisement Application Demand Print </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalAdvertisementPayment_online.jsp'>5. Online Advertisement Application Demand Pay </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineApplicationNOC.jsp'>6. Online Advertisement Application NOC Print</a>
                      </div>
                </li>
                <li className="nav-item"><button type="button" className="btn" data-toggle="collapse" data-target="#demo">Online Declaration<i className="bi bi-chevron-down" /></button>
                      <div id="demo" className="collapse">                        
                          <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineDeclaration.jsp'>1. Online Advertisement Declaration </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineDeclarationStatus.jsp'>2. Online Declaration Status </a>
                       </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Advertisement_rates'> Rates </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AgentDetails.jsp'> Know Your Agent Code</a>                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Advertisement_download_forms'> Download Forms</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Check_Demands_Payable'>Check Demands Payable </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemandPrint.jsp'> {t("Print Unpaid Bill")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemand.jsp'> {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemand.jsp'> {t("Reprint e-Receipt")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCAdvertisementTaxCalculator.jsp'>{t("Advertisement Tax Calculator")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Advertisement_Dept'> Contact Details</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/ListHoardings1.jsp'> {t("List of Hoardings")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('240')}> Empanelement Of Advertising Agencies </a>
                </li>
              </ul>
            </nav>
          </div>
              </div>
              <div className="col-lg-9">
                <div className="inner-title2"><h3 className="fontSizeClass"> Advertisement e-Receipt </h3></div>   
                <div className="about-head1">
                  <div className="dig-login1 dig-login2">
                    <form>
                      <div className="log-form inner-log-form"> 
                        <h3 className="fontSizeClass">List of Advertisement e-Receipts</h3>
                        <div className="row">
                          <div className="col-lg-6 col-md-12 form-group pt-2">
                            <label htmlFor="exampleFormControlInput1" className="form-label"> Receipt Date From*: </label>
                            <input type="date" className="form-control" placeholder="dd/mm/yyyy" aria-label />                     
                          </div>
                          <div className="col-lg-6 col-md-12 form-group pt-2">
                            <label htmlFor="exampleFormControlInput1" className="form-label"> Commencement Date To*: </label>
                            <input type="date" className="form-control" placeholder="dd/mm/yyyy" aria-label />                     
                          </div>
                          <div className="col-lg-6 col-md-12 form-group pt-2">
                            <label htmlFor="exampleFormControlInput1" className="form-label"> Agent Code*: </label>
                            <input type="text" className="form-control" placeholder aria-label />                   
                          </div>
                          <div className="col-lg-6 col-md-12 form-group pt-2">                    
                            <div className="form-group continu align-items-center">
                              <label htmlFor="exampleFormControlInput1" className="form-label"> &nbsp; </label>
                              <button className="btn btn-primary" type="button"> Search Receipt</button>            
                            </div>
                          </div>   
                        </div>
                      </div>
                    </form>
                  </div>            
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  )
}

export default KMC_Advertisement_reprint_e_receipts
