import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Disaster_Mgmt = () => {
     const increaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
          var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
          var fontSize = parseFloat(style); 
          el[i].style.fontSize = (fontSize + 1) + 'px';
        }
      };
      const decreaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
        var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
        var fontSize = parseFloat(style); 
        el[i].style.fontSize = (fontSize - 1) + 'px'; 
        }
      };
      const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">           
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Disaster_Mgmt'> {t("Disaster Mgmt")} </Link> </li>
                  <li className="nav-item"><Link class="nav-link" to='/Concept_Note' target="_blank"> {t("Concept Note")}</Link> </li>  
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Guidelines_for_Safety'> {t("Guidelines for Safety")} </Link> </li>  
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Form_For_Inventory'> {t("Form For Inventory")} </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/School_Safety_Booklet'> {t("School Safety Booklet")} </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Colouring_Activity_Book'> {t("Colouring Activity Book")}</Link> </li>  
    
                </ul>
              </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="">
              <div className="inner-title"><h3 className="fontSizeClass"> {t("Disaster Mgmt. Activity in Schools")} </h3>
              <div className="about-head1">
                <h4 className="fontSizeClass">
                    {t("REPORT ON DISASTER MANAGEMENT ACTIVITIES IN PRIMARY SCHOOLS OF KOLKATA CITY UNDER UEVR PROJECT")}
                </h4>
              </div>
              </div>
                  <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="heading1">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                    Background
                    </button>
                    </div>
                    <div id="collapse1" className="collapse show" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p>The Indian sub-continent is highly prone to natural disasters. Floods, Wind, Cyclones, Droughts and Earthquakes are recurrent phenomena. As per the latest damage risk zoning map of Bureau of Indian Standards (BIS) over 65% area of India is prone to earthquakes of considerable intensities. Some of the most intense earthquakes have occurred in India and its adjacent areas in the past. India has highly populated mega cities including the capital in New Delhi situated in zones of high seismic risk. Geologically Kolkata falls within Moderate Earthquake Damage Risk Zone (Zone-III) within very close vicinity of High Earthquake Damage Risk Zone (Zone-IV) of North and South 24 Parganas districts. The same area falls under Very High Wind and Cyclone Damage Risk Zone also. Over and above this, some human induced factors as unsafe buildings, unplanned urbanization, dense population and habitats have increased its vulnerability to disasters to considerable extent. Apart from natural disasters, human induced disasters like fire, gas leak, flood due to blockage of drainage system, terrorist attack, accidents etc. are common in urban areas.</p>
                        <p>Disasters lead to erosion of development gains and restricted options for the affected people. Physical safety - especially that of the vulnerable groups, which include children, old and physically handicapped people, is threatened. Alike similar experiences in other parts of the world, Orissa cyclone of 1999, Gujarat earthquake of 2001 and the Tsunami of 2004 have very clearly illustrated that we need pre-disaster mitigation, preparedness and response systems at all levels (including schools) so that the threat to human lives and property is minimized.</p>
                        <p>Schools are the most important institutions influencing development of a country and so damage/loss of lives or property of schools badly affects the development of a whole nation. Students are the future citizens of the country and the teachers are makers of a nation. Both are vulnerable to great physical and psychological sufferings caused by a disaster - natural or human induced. Most of the schools operating in urban areas are built in congested places and many of those have structural inadequacies. These factors combined with ignorance and lack of preparedness measures can have disastrous consequences in the event of earthquake in this region.</p>
                        <p>Owing to both physical as well as mental immaturity at their tender age, the students of the Primary Schools are incapable of pre-conceiving the consequences of any disaster and understanding the importance of pre-disaster mitigation, preparedness etc. These factors make them unable to save themselves and hence very highly vulnerable to all disasters (natural or human induced). That is why the entire responsibility of immediate disaster response, preparedness and safety of primary schools lies mainly with the concerned teachers who must develop their attitude, knowledge as well as skill through rigorous trainings to respond immediately and effectively to any hazard (e.g. fire, gas leak, hostage, accident, building collapse etc.). The administration in charge of primary education must take necessary actions to equip the primary schools with disaster management tools and arrange for adequate trainings/refresher courses for the primary school teachers as also for mock drills at regular intervals.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading2">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                      Intervention
                    </button>
                    </div>
                    <div id="collapse2" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                          <p>Interventions to reduce such vulnerabilities have been made by DRMP under its School Safety Programme, the goal of which is to promote a culture of disaster preparedness in schools. The Disaster Risk Management Programme (DRMP) is a national initiative jointly launched by Govt. of India and United Nations Development Programme (UNDP) to reduce vulnerabilities of communities in some of the most hazard prone areas of India (17 states and 169 districts). The programme aims to contribute to the social and economic development goals of the national and state governments, enable them to minimize losses to development gains and to reduce their vulnerability to disasters. For sustainability, it relies upon a community based approach and seeks to build capacities of all sections of the society and all the stake holders in disaster management.</p>
                          <br />
                          <p>The Urban Earthquake Vulnerability Reduction Project (UEVRP),a sub-component programme of DRMP for urban areas, is being implemented by the city municipal authorities under state government monitoring in 38 populous vulnerable cities in India including Kolkata and Asansol in West Bengal with the objective of sustainable reduction in disaster risks (with focus on earthquake) across the country.<br />
                            UEVRP envisages the following broad activities:-</p>
                          <p>1. Awareness Generation.<br />
                            2. Development of Preparedness and Response Plans at the Community as well as Administrative levels.<br />
                            3. Training and Capacity Building at all levels.<br />
                            4. Development of Techno-Legal Regime (e.g. Amendment of Building Rules, Guidelines etc.).<br />
                            5. Networking of Knowledge and Best Practices both Nationally and Internationally.</p>
                          <p>The step-wise activities for School Safety Programme for Primary Schools are:-
                            <br></br>
                            I. Centralized activity :- Awareness generation and hands on training of school teachers(one teacher - preferably the head teacher/physical education teacher representing each school) on the basics of School Disaster Management Plan, Emergency Evacuation, Search & Rescue, Fire Prevention & Control and First Aid.<br />

                            II.Decentralized Activities (for each school) :- Following activities are initiated, organized and coordinated in a school by the teacher of that school who was trained under the above centralized activity. These are made without making hindrance to the normal functioning of the school.<br />
                            <br />
                            01. Formation of School Disaster Management Committee (SDMC) in each school (for KMC Primary Schools the School Development Committees may function as SDMC).<br />
                            02. Sensitization of SDMC.<br />
                            03. Hazard Identification and Safety Assessment of school building and utilities.<br />
                            04. Making Inventory of available and required resources and mapping of services/ resources available in close vicinity (e.g. Health Service facilities, Fire Service Station, Civil Defence unit, Police Station, Local NGOs/CBOs etc.).<br />
                            05. Preparation of School Disaster Management Plan showing evacuation plan.<br />
                            06. Dissemination of School DM Plan to everybody in the School (also to the guardians).<br />
                            07. Mock drill at regular intervals to keep practice.<br />
                            08. Adoption of Structural and Non-Structural Mitigation measures.</p>
                      </div>                      
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading3">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                      Expected Outcome
                    </button>
                    </div>
                    <div id="collapse3" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p>After proper implementation of the School Safety (Disaster management) Programme, it is expected that the schools will be much equipped to mitigate disaster with sound structural and non-structural arrangements and efficiently cope up with any disaster situation collectively in a well planned, disciplined, well organized manner.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                      Target primary schools and teachers
                    </button>
                    </div>
                    <div id="collapse4" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p>As per first phase target, one teacher from each of the 242 Primary Schools as well as 100 Shishu Siksha Kendras (SSK) run by the Kolkata Municipal Corporation will be sensitized. This will be followed by hands on training (3-days duration) on School Disaster Management for 100 teachers of 100 primary schools.</p>
                    </div>
                  </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading5">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                      Awareness Generation Programmes for Primary School Teachers
                    </button>
                    </div>
                    <div id="collapse5" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p>A total of 424 teachers of KMC Primary Schools and SSKs have been sensitized in four 2½ to 3 hour programmes on vulnerability of West Bengal and Kolkata city to various natural as well as human induced disasters and required measures for preparedness-mitigation-management with focus on primary schools.</p>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <img src="assets/img/kmc-1.jpg" className="img-fluid" alt="alt-image" />
                            </div>
                            <div className='col-lg-6'>
                                <img src="assets/img/kmc-2.jpg" className="img-fluid" alt="alt-image" />
                            </div>
                        </div>
                        <p className='mt-3'>Photographs of Sensitization programmes for KMC Pry.Schl. Teachers at Chaplin Hall on 14.6.2008 and 18.6.2008</p>
                        </div>
                      </div>
                    </div>
                  <div className="card">
                <div className="card-header" id="heading6">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                    Training Programmes for Primary School Teachers   
                </button>
                </div>
                <div id="collapse6" className="collapse" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <p>I) Objective of the Training :- To train the teachers of Primary Schools run by Kolkata Municipal Corporation on the basics & practical application of the following items for building their capacity to cope with any disaster situation (natural or human induced) as the first responders before arrival of the major emergency services from Fire Brigade, Police, Civil Defence, KMC etc.</p><br />
                    <p>II) Module of the Training :- Keeping in mind the enormous responsibility on the primary school teachers for effective immediate response and initial management of any disaster situation in a primary school, a special module of 3-days training programme for primary school teachers (one from each school) on School Disaster Management Plan, School Security, basics of Search & Rescue, Prevention & Control of Fire and First Aid was adopted. The trainers were from departments / organizations having expertise in the related fields. The detailed Schedule of the 3-day teachers’ training programme has been given below :-</p><br />
                    <div className=" health-dept-table">
                    <table width="100%" border={0}
                    align="center" cellPadding={2} cellSpacing={2}
                    className="table table-bordered officials" >
                    <tbody>
                        <tr bgcolor="#302ba0" align="center" valign="middle">
                        <td width="25%" className="text-white tble-brder-left">
                            Time	
                        </td>
                        <td width="25%" className="text-white">
                            Topic	
                        </td>
                        <td width="50%" className="text-white tble-brder-right">
                            Resource Person
                        </td>
                        </tr>
                        <tr>
                        <td valign="middle">Day – 1</td>
                        </tr>
                        <tr>
                        <td valign="middle"><p>10/30 AM – 11/00 AM	</p> </td>
                        <td valign="middle">Registration</td>
                        <td valign="middle">
                        </td>
                        </tr>
                        <tr>
                        <td valign="middle">11/00 AM – 11/40 AM	</td>
                        <td valign="middle">Welcome Address<br />Inauguration & Guests’ Lectures</td>
                        <td valign="middle">
                        Jt. Mpl. Commissioner (Dev.),KMC<br />Secretary / Jt. Secretary Dept. of Disaster Management, Govt. of West Bengal
                        </td>
                        </tr>
                        <tr>
                        <td valign="middle">11/40 AM – 12/00 AM	</td>
                        <td valign="middle">Objectives of the Teachers’ Training Programme	</td>
                        <td valign="middle">Chief Manager (Edu), KMC Project Officer (UEVRP) </td>
                        </tr>
                        <tr>
                        <td valign="middle">12/00 AM – 12/15 PM (0h-15m)	 </td>
                        <td valign="middle">Tea Break</td>
                        <td valign="middle">Tea Break </td>
                        </tr>
                    </tbody>
                    </table>
                    <br />
                    </div>
                    <br />
                    <h6>Training on SECURITY OF SCHOOL DURING AND AFTER DISASTER</h6>
                    <div className=" health-dept-table">
                    <table width="100%" border={0}
                    align="center" cellPadding={2} cellSpacing={2}
                    className="table table-bordered officials" >
                    <tbody>
                        
                        <tr>
                        <td valign="middle"><p>12/15 PM – 01/15 PM (1h-00m)</p> </td>
                        <td valign="middle">Dos and Don’ts for School site Security. Help available from police and coordination with them before, during and after disaster.</td>
                        <td valign="middle">Kolkata Police Official </td>
                        </tr>
                        <tr>
                        <td valign="middle">01/15 PM – 02/00 PM (0h-45m)</td>
                        <td valign="middle">Lunch Break	</td>
                        <td valign="middle">  </td>
                        </tr>
                    </tbody>
                    </table>
                    <br />
                    </div>
                    <br />
                    <h6>Training on PREPARATION OF SCHOOL DISASTER MANAGEMENT PLAN</h6>
                    <div className=" health-dept-table">
                    <table width="100%" border={0}
                    align="center" cellPadding={2} cellSpacing={2}
                    className="table table-bordered officials" >
                    <tbody>
                        
                        <tr>
                        <td valign="middle"><p>02/00 PM – 03/30 PM(1h-30m)</p> </td>
                        <td valign="middle">Disaster scenario of West Bengal and Kolkata City Preparation of School Disaster Management Plans (Lecture, Demonstration & Practical).	</td>
                        <td valign="middle">Project Officer (UEVRP) UNDP</td>
                        </tr>
                        <tr>
                        <td valign="middle">03/30 PM – 03/45 PM (0h-15m)</td>
                        <td valign="middle">Tea Break	</td>
                        <td valign="middle">
                        </td>
                        </tr>
                        <tr>
                        <td valign="middle">03/45 PM – 04/30 PM (0h-45m)	</td>
                        <td valign="middle">Group work and presentation on Preparation of School Disaster Management Plans, Discussion on Need of Mock Drills at regular intervals.	</td>
                        <td valign="middle">Project Officer (UEVRP) UNDP</td>
                        </tr>
                    </tbody>
                    </table>
                    <br />
                    </div>
                    <br />
                    <h4>Day – 2</h4>
                    <h6>Training on WARNING, EMERGENCY EVACUATION, SEARCH & RESCUE, FIRE PREVENTION & CONTROL</h6>
                    <div className=" health-dept-table">
                    <table width="100%" border={0}
                    align="center" cellPadding={2} cellSpacing={2}
                    className="table table-bordered officials" >
                    <tbody>                            
                        <tr>
                        <td valign="middle"><p>10/30 AM – 11/30 AM(1h-00m)</p> </td>
                        <td valign="middle">Services rendered by Emergency Services provider departments (Fire & Emergency Services, Police, Civil Defence, Kolkata Municipal Corporation etc.) during and after disaster. Causes and Prevention of Fire.(Lecture).		</td>
                        <td valign="middle">Fire & Emergency Services</td>
                        </tr>
                        <tr>
                        <td valign="middle">11/30 AM – 11/45 AM (0h-15m)</td>
                        <td valign="middle">Tea Break	</td>
                        <td valign="middle">
                        </td>
                        </tr>
                        <tr>
                        <td valign="middle">11/45 PM – 01/15 PM (1h-30m)</td>
                        <td valign="middle">Emergency Information & Warning, Measures for Fire Prevention, Protecting houses/schools from fire hazard, De-activating live Electrical Lines, Simple fire prevention tips, Uses of fire protection equipments & tools (e.g. Fire Alarm).  (Lecture & Demonstration).	</td>
                        <td valign="middle">Fire & Emergency Services</td>
                        </tr>
                        <tr>
                        <td valign="middle">01/15 PM – 02/15 PM (1h-00m)</td>
                        <td valign="middle">Lunch Break</td>
                        <td valign="middle"></td>
                        </tr>
                        <tr>
                        <td valign="middle">02/15 AM – 03/15 PM (1h-00m)</td>
                        <td valign="middle">Emergency Evacuation of School Building –-Search & Rescue operation from Ground and upper (1st /2nd) Floors / Roof. Uses of Simple Rescue Tools and Equipments – Ropes, Liver, Jack, Pulley, Stretcher, Ladder etc. Control of Small Fire, use of different kinds of Fire Extinguishers. (Lecture, Demonstration & Practical).	</td>
                        <td valign="middle">Fire & Emergency Services</td>
                        </tr>
                        <tr>
                        <td valign="middle">03/15 PM – 03/30 PM(0h-15m)</td>
                        <td valign="middle">Tea Break</td>
                        <td valign="middle"></td>
                        </tr>
                        <tr>
                        <td valign="middle">03/30 PM – 04/30 PM (1h-00m)</td>
                        <td valign="middle">Above session continued.</td>
                        <td valign="middle">Fire & Emergency Services</td>
                        </tr>
                    </tbody>
                    </table>
                    <br />
                    </div>
                    <h4>Day – 3</h4>
                    <h6>Training On FIRST AID</h6>
                    <div className=" health-dept-table">
                    <table width="100%" border={0}
                    align="center" cellPadding={2} cellSpacing={2}
                    className="table table-bordered officials" >
                    <tbody>                            
                        <tr>
                        <td valign="middle"><p>10/30 AM – 11/00 AM (0hr-30m)</p> </td>
                        <td valign="middle">Structure and Function of Human Body (Lecture).	</td>
                        <td valign="middle">St. John Ambulance Asso.</td>
                        </tr>
                        <tr>
                        <td valign="middle">11/00 AM – 11/30 AM (0hr-30m)</td>
                        <td valign="middle">Circulation of Blood, Wounds and Bleeding (Lecture).</td>
                        <td valign="middle">St. John Ambulance Asso.</td>
                        </tr>
                        <tr>
                        <td valign="middle">11/30 AM – 11/45 AM	</td>
                        <td valign="middle">Tea Break</td>
                        <td valign="middle"></td>
                        </tr>
                        <tr>
                        <td valign="middle">11/45 AM – 01/15 PM (1h-30m)</td>
                        <td valign="middle">Injuries to Bones (different types of Fracture) and related First Aid measures, Artificial respiration, Burns & Scalds, Unconsciousness and Shock (including electric shock)	</td>
                        <td valign="middle">St. John Ambulance Asso.</td>
                        </tr>
                        <tr>
                        <td valign="middle">02/15 AM – 03/15 PM (1h-00m)</td>
                        <td valign="middle">Emergency Evacuation of School Building –-Search & Rescue operation from Ground and upper (1st /2nd) Floors / Roof. Uses of Simple Rescue Tools and Equipments – Ropes, Liver, Jack, Pulley, Stretcher, Ladder etc. Control of Small Fire, use of different kinds of Fire Extinguishers. (Lecture, Demonstration & Practical).	</td>
                        <td valign="middle">Fire & Emergency Services</td>
                        </tr>
                        <tr>
                        <td valign="middle">01/15 PM – 02/00 PM (0h-45m)</td>
                        <td valign="middle">Tea Break</td>
                        <td valign="middle"></td>
                        </tr>
                        <tr>
                        <td valign="middle">02/00 PM – 04/30 PM (Tea without break)</td>
                        <td valign="middle">Different types of Bandages and Dressings (Demonstration & Practical).	</td>
                        <td valign="middle">St. John Ambulance Asso.</td>
                        </tr>
                        <tr>
                            <td>04/30 PM – 05/00 PM	</td>
                            <td>Valedictions</td>
                            <td></td>
                        </tr>
                    </tbody>
                    </table>
                    <br />
                    </div>
                    <br />
                    <h6>A total of 79 teachers of 79 primary schools of Kolkata have been trained so far following the above schedule/ module in two training programmes of 3-days duration for each which were organized in Kolkata during November 28 – 30, 2007 and February 14 – 16, 2008.</h6>
                    </div>
                    </div>
                  </div>
                  <div className="card">
                <div className="card-header" id="heading7">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                    III) Training Photographs:-</button>
                </div>
                <div id="collapse7" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                            <h3 style={{textAlign:"center"}}>a) Training of 1st batch during November 28-30, 2007</h3>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <img src="assets/img/kmc-3.jpg" className="img-fluid" alt="alt-image" />
                                </div>
                                <div className='col-lg-6'>
                                    <img src="assets/img/kmc-4.jpg" className="img-fluid" alt="alt-image" />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <img src="assets/img/kmc-5.jpg" className="img-fluid" alt="alt-image" />
                                </div>
                                <div className='col-lg-6'>
                                    <img src="assets/img/kmc-6.jpg" className="img-fluid" alt="alt-image" />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <img src="assets/img/kmc-9.jpg" className="img-fluid" alt="alt-image" />
                                </div>
                                <div className='col-lg-6'>
                                    <img src="assets/img/kmc-8.jpg" className="img-fluid" alt="alt-image" />
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
    {/*   */}
  </section>
  
    </>
  )
}

export default Disaster_Mgmt