import React, { useState, useEffect } from 'react'
import SimpleImageSlider from "react-simple-image-slider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import ScrollNavbar from './ScrollNavbar';

const images = [
  { url: "assets/img/Banner/portal-banner1.png" },
  { url: "assets/img/Banner/portal-banner2.png" },
  { url: "assets/img/Banner/portal-banner3.png" },
  { url: "assets/img/Banner/portal-banner4.png" },
];

function Banner() {
  const { t, i18n, ready } = useTranslation();
  return (
    <>
      <div className="Banner">
 
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          </ol>
          <div class="carousel-inner banner-container">
            <div class="carousel-item carousel-item-img">
            <Link to='/Quick_Links'>
              <img className="  banner-img" src="assets/img/portal-banner2.png" alt="slider2" style={{ width: "100%"}}/>
            </Link>
            </div>
            <div class="carousel-item active carousel-item-img">
              <a href='https://www.kmcgov.in/KMCPortal/jsp/ComplaintSearch.jsp'>
              <img className="  banner-img" src="assets/img/portal-banner1.png" alt="slider1" style={{ width: "100%"}}/>
              </a>
            </div>            
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-target="#carouselExampleIndicators"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">{t("Previous")}</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-target="#carouselExampleIndicators"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">{t("Next")}</span>
          </button>
        </div>
      </div>
    </>
  );
}

export default Banner;
