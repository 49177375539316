import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CitizenLogin = () => {
  const { t, i18n, ready } = useTranslation();
  return (
    <>
        <section className="section inner-pad1">
  <div className="container " >
    <div className="row">
      <div className="col-lg-12">
        <div className="dig-login1">
          <form>
            <div className="log-form">
              <div className="container">
                <div className="row">
                  <h3 className="fontSizeClass">{t("Citizen Login")}</h3>
                  <p className="fontSizeClass">{t("Welcome back! Please login to your account")}.</p>
                  <div className="col-xl-12 col-md-12 mb-12 form-group pt-2">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="User ID"
                      aria-label=""
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <h6 className="orbtn">{t("OR")}</h6>
                  <div className="col-xl-12 col-md-12 mb-12 form-group pt-2">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Mobile Number"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      aria-label=""
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div className="col-xl-12 col-md-12 mb-12 form-group pt-4">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Password"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      aria-label=""
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div className="login__field">
                    <div className="form-group">
                      <span className="fontSizeClass">
                        
                        <Link to=""> {t("Forgot Password")} </Link> <p />
                      </span>
                    </div>
                  </div>
                  <div className="login__field">
                    <div className="form-group continu">
                      <Link to="index.html"> {t("Log In")} </Link>
                      <Link to=""> {t("Register")} </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default CitizenLogin