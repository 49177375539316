import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MMIC = () => {
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
     </div>
  </section>
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu ">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Contact_Us_Home'> {t("Contact Us Home")} </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Important_Contacts' target="_blank"> {t("Important Contacts")} </Link>
                 </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass active1" to='/MMIC'> {t("MMIC")}</Link></li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Controlling_Officers'> {t("Controlling Officers")}</Link></li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Councillors'> {t("Councillors")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Borough_Committee'> {t("Borough Committee")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Borough_Committee'> {t("Borough Offices")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCWard.jsp'> {t("Know Your Ward")}</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass"> {t("MMIC")} </h3>
            </div>
            <div className="col-lg-12 about-head1">
              <div className="mayor-table2 depart">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-warning table-bor-2">
                      <th className="bd-wid" scope="col">
                        
                        {t("Sl. No")}.
                      </th>
                      <th scope="col"> {t("Names")} </th>
                      <th scope="col"> {t("Portfolios")} </th>
                      <th scope="col"> {t("Office phone No")} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row"> 1 </th>
                      <td> {t("Firhad Hakim Hon'ble Mayor")} </td>
                      <td>
                        
                        {t("Water Supply,Finance & Accounts, Grievances, Cultural Affairs, KEIIP, Licence , Building,Central Stores,Assessment -Collection,TPDD and other departments not allotted to other members")}
                      </td>
                      <td>
                        
                        {t("Office")} : 2286-1211 Fax : 2286-1311/1011 EPABX :
                        2286-1000, Extn. 2471 Int. Com.201
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h4 className="fontSizeClass">{t("The Deputy Mayor and The Members, Mayor-In-Council")}</h4>
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-warning table-bor-2">
                      <th className="bd-wid" scope="col">
                        
                        {t("Sl. No")}.
                      </th>
                      <th scope="col"> {t("Name")} </th>
                      <th scope="col"> {t("Portfolios")} </th>
                      <th scope="col"> {t("Office Phone No")}. </th>
                      <th scope="col"> {t("E-mail")} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row"> 2 </th>
                      <td> {t("Sri Atin Ghosh Deputy Mayor")} </td>
                      <td>
                        
                        {t("Health,Vector Control, Disinfectant Scheme, Archives,P&FA(F&SS), Central Medical Stores,MTM TB Hospital")}.
                      </td>
                      <td> 2286-1120 (Direct) 22861000 Extn. 2465 </td>
                      <td>
                        <Link to="mailto:  mmic_hlth@kmcgov.in " target="_blank">
                          
                          mmic_hlth@kmcgov.in
                        </Link>
                        <Link
                          to="mailto:  atinghosh503@gmail.com "
                          target="_blank"
                        >
                          
                          atinghosh503@gmail.com
                        </Link>
                        <Link
                          to="mailto:  ghosh.atin2009@yahoo.com "
                          target="_blank"
                        >
                          
                          ghosh.atin2009@yahoo.com
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 3 </th>
                      <td> {t("Sri Debabrata Majumder MMIC")} </td>
                      <td> SWM </td>
                      <td> 2286-1116 (Direct) 22861000 Extn. 2466 </td>
                      <td>
                        <Link to="mailto:  mmic_swm@kmcgov.in " target="_blank">
                          
                          mmic_swm@kmcgov.in
                        </Link>
                        <br />
                        <Link
                          to="mailto:  dmswm_KMC@rediff.com "
                          target="_blank"
                        >
                          
                          dmswm_KMC@rediff.com
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 4 </th>
                      <td> {t("Sri Debasish Kumar MMIC")} </td>
                      <td>
                        
                        {t("Parks & Gardens, Sports,Advertisement, Parking & Hawker's Rehabilitation Schemes")}
                      </td>
                      <td> 2286-1114 (Direct) 22861000 Extn. 2499 </td>
                      <td>
                        <Link to="mailto:  mmic_pgs@kmcgov.in " target="_blank">
                          
                          mmic_pgs@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 4 </th>
                      <td> {t("Sri Debasish Kumar MMIC")} </td>
                      <td>
                        
                        {t("Parks & Gardens, Sports,Advertisement, Parking & Hawker's Rehabilitation Schemes")}
                      </td>
                      <td> 2286-1114 (Direct) 22861000 Extn. 2499 </td>
                      <td>
                        <Link to="mailto:  mmic_pgs@kmcgov.in " target="_blank">
                          
                          mmic_pgs@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 5 </th>
                      <td> {t("Sri Sandip Ranjan Bakshi MMIC")} </td>
                      <td> {t("Lighting & Electricity")} </td>
                      <td> 2286-1119 (Direct) 22861000 Extn. 2463 </td>
                      <td>
                        <Link to="mailto:  mmic_ltg@kmcgov.in " target="_blank">
                          
                          mmic_ltg@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 6 </th>
                      <td> {t("Sri Jiban Saha MMIC")} </td>
                      <td> I & PR,EWS,Printing,IUM,Big-dia Tubewell </td>
                      <td> 2286-1123(Direct) 22861000 Extn. 2568 </td>
                      <td>
                        <Link to="mailto:  mmic_ipr@kmcgov.in " target="_blank">
                          
                          mmic_ipr@kmcgov.in
                        </Link>
                        <br />
                        <Link
                          to="mailto: jibansahaofficial57@gmail.com "
                          target="_blank"
                        >
                          
                          jibansahaofficial57@gmail.com
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 7 </th>
                      <td> {t("Sri Tarak Singh MMIC")} </td>
                      <td>
                        
                        {t("Sewerage & Drainage Including( Drainage Pumping Station),SC(Mechanical)")}
                      </td>
                      <td> 2286-1115 22861000 Extn. 2467 </td>
                      <td>
                        <Link to="mailto:  mmic_sd@kmcgov.in " target="_blank">
                          
                          mmic_sd@kmcgov.in
                        </Link>
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 8 </th>
                      <td> {t("Smt Mitali Banerjee MMIC")} </td>
                      <td>
                        
                        {t("Swasthyasathi, BPL, NSAP, NFBS, NGR, Rupashri, Khadyasathi, NULM, Kanyashree")}
                      </td>
                      <td> 2286-1122(Direct) 22861000 Extn. 2468 </td>
                      <td>
                        <Link
                          to="mailto: mmic_bpl_nsap@kmcgov.in "
                          target="_blank"
                        >
                          
                          mmic_bpl_nsap@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 9 </th>
                      <td> {t("Sri Swapan Samaddar MMIC")} </td>
                      <td> {t("Bustee Development, Environment & Heritage")} </td>
                      <td> 2252-0280(Direct) 22861000 Extn. 2912 </td>
                      <td>
                        <Link
                          to="mailto: mmic_bustee@kmcgov.in "
                          target="_blank"
                        >
                          
                          mmic_bustee@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 10 </th>
                      <td> {t("Amiruddin(Bobby) MMIC")} </td>
                      <td> {t("Market")} </td>
                      <td> 2286-1118 (Direct) 22861000 Extn. 2569 </td>
                      <td>
                        <Link to="mailto: mmic_mkt@kmcgov.in " target="_blank">
                          
                          mmic_mkt@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 11 </th>
                      <td> {t("Sri Sandipan Saha MMIC")} </td>
                      <td> {t("Education and IT")}</td>
                      <td> 2286-1113(Direct) 2286-1000 Extn. 2464 </td>
                      <td>
                        <Link to="mailto: mmic_edn@kmcgov.in " target="_blank">
                          
                          mmic_edn@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 12 </th>
                      <td> {t("Sri Ram Payre Ram MMIC")} </td>
                      <td> W.B.U.E.S </td>
                      <td> 2252-0161 2286-1000 Extn.2934 </td>
                      <td>
                        <Link
                          to="mailto: rampayre_ram@kmcgov.in "
                          target="_blank"
                        >
                          
                          rampayre_ram@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 13 </th>
                      <td> {t("Sri Avijit Mukherjee MMIC")} </td>
                      <td> {t("Roads and Engineering")} </td>
                      <td> 2252-0281 22861000 Extn. 2904 </td>
                      <td>
                        <Link to="mailto: mmic_re@kmcgov.in " target="_blank">
                          
                          mmic_re@kmcgov.in
                        </Link>
                        <br />
                        <Link
                          to="mailto: avijitmukherjeekmc@gmail.com "
                          target="_blank"
                        >
                          
                          avijitmukherjeekmc@gmail.com
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 13 </th>
                      <td> {t("Sri Baiswanor Chatterjee MMIC")} </td>
                      <td> {t("Housing,Law,PPP and Personnel")} </td>
                      <td> 2252-0470 2286-1000 Extn.2710 </td>
                      <td>
                        <Link
                          to="mailto: b_chatterjee@kmcgov.in "
                          target="_blank"
                        >
                          
                          b_chatterjee@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default MMIC