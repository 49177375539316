import React from 'react'

const Gallery = () => {
  return (
    <>
 <div className="row gal-hight">
  <div className="col-lg-2 col-md-4 col-xs-6 thumb">
    <a
      href="assets/img/gallery/65idc_1.jpg"
      className="fancybox"
      rel="ligthbox"
    >
      <img
        src="assets/img/gallery/65idc_1.jpg"
        className="zoom img-fluid"
        alt=""
      />
    </a>
  </div>
  <div className="col-lg-2 col-md-4 col-xs-6 thumb">
    <a
      href="assets/img/gallery/65idc_2.jpg"
      className="fancybox"
      rel="ligthbox"
    >
      <img
        src="assets/img/gallery/65idc_2.jpg"
        className="zoom img-fluid"
        alt=""
      />
    </a>
  </div>
  <div className="col-lg-2 col-md-4 col-xs-6 thumb">
    <a
      href="assets/img/gallery/65idc_3.jpg"
      className="fancybox"
      rel="ligthbox"
    >
      <img
        src="assets/img/gallery/65idc_3.jpg"
        className="zoom img-fluid "
        alt=""
      />
    </a>
  </div>
  <div className="col-lg-2 col-md-4 col-xs-6 thumb">
    <a
      href="assets/img/gallery/65idc_4.jpg"
      className="fancybox"
      rel="ligthbox"
    >
      <img
        src="assets/img/gallery/65idc_4.jpg"
        className="zoom img-fluid "
        alt=""
      />
    </a>
  </div>
  <div className="col-lg-2 col-md-4 col-xs-6 thumb">
    <a
      href="assets/img/gallery/65idc_5.jpg"
      className="fancybox"
      rel="ligthbox"
    >
      <img
        src="assets/img/gallery/65idc_5.jpg"
        className="zoom img-fluid "
        alt=""
      />
    </a>
  </div>
  <div className="col-lg-2 col-md-4 col-xs-6 thumb">
    <a
      href="assets/img/gallery/65idc_6.jpg"
      className="fancybox"
      rel="ligthbox"
    >
      <img
        src="assets/img/gallery/65idc_6.jpg"
        className="zoom img-fluid "
        alt=""
      />
    </a>
  </div>
  <div className="col-lg-2 col-md-4 col-xs-6 thumb">
    <a
      href="assets/img/gallery/ala_1.jpg"
      className="fancybox"
      rel="ligthbox"
    >
      <img
        src="assets/img/gallery/ala_1.jpg"
        className="zoom img-fluid "
        alt=""
      />
    </a>
  </div>
  <div className="col-lg-2 col-md-4 col-xs-6 thumb">
    <a
      href="assets/img/gallery/deikmc.jpg"
      className="fancybox"
      rel="ligthbox"
    >
      <img
        src="assets/img/gallery/deikmc.jpg"
        className="zoom img-fluid "
        alt=""
      />
    </a>
  </div>
  <div className="col-lg-2 col-md-4 col-xs-6 thumb">
    <a
      href="assets/img/gallery/ala_2.jpg"
      className="fancybox"
      rel="ligthbox"
    >
      <img
        src="assets/img/gallery/ala_2.jpg"
        className="zoom img-fluid "
        alt=""
      />
    </a>
  </div>
  <div className="col-lg-2 col-md-4 col-xs-6 thumb">
    <a
      href="assets/img/gallery/ala_3.jpg"
      className="fancybox"
      rel="ligthbox"
    >
      <img
        src="assets/img/gallery/ala_3.jpg"
        className="zoom img-fluid "
        alt=""
      />
    </a>
  </div>
  <div className="col-lg-2 col-md-4 col-xs-6 thumb">
    <a
      href="assets/img/gallery/bhrf_1.jpg"
      className="fancybox"
      rel="ligthbox"
    >
      <img
        src="assets/img/gallery/bhrf_1.jpg"
        className="zoom img-fluid "
        alt=""
      />
    </a>
  </div>
  <div className="col-lg-2 col-md-4 col-xs-6 thumb">
    <a
      href="assets/img/gallery/bhrf_3.jpg"
      className="fancybox"
      rel="ligthbox"
    >
      <img
        src="assets/img/gallery/bhrf_3.jpg"
        className="zoom img-fluid "
        alt=""
      />
    </a>
  </div>
  
</div>

    </>
    )
}

export default Gallery