import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const User_login_Online_Application_For_Change  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <section classname="section inner-pad1">
    
  <div classname="container " >
    <div classname="row">
      <div classname="col-lg-12">
        <div classname="dig-login1">
          <form>
            <div classname="log-form">
              <div classname="container">
                <div classname="row">
                  <h3 className="fontSizeClass"> {t("User Login")}</h3>
                  <p className="fontSizeClass"> {t("Welcome back! Please login to your account")}.</p>
                  <div classname="col-xl-12 col-md-12 mb-12 form-group pt-2">
                    <input
                      type="tel"
                      classname="form-control"
                      placeholder="User Id"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      aria-label=''
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div classname="col-xl-12 col-md-12 mb-12 form-group pt-4">
                    <input
                      type="tel"
                      classname="form-control"
                      placeholder="Password"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      aria-label=''
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div classname="login__field">
                    <div classname="form-group">
                      <p className="fontSizeClass"> {t("OR")} </p>
                    </div>
                  </div>
                  <div classname="col-xl-12 col-md-12 mb-12 form-group pt-2">
                    <input
                      type="tel"
                      classname="form-control"
                      placeholder="Mobile No"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      aria-label=''
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div classname="login__field">
                    <div classname="form-group continu">
                      <Link to=''> {t("Login")}</Link>
                      <Link to=''> {t("New Account")} </Link>
                      <Link to=''> {t("Reset Password")} </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default User_login_Online_Application_For_Change