import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Heritage_Heritage_Buildings  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
<>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2" >
          
          {/* <h4 className="fontSizeClass"> {t("Heritage Buildings")} </h4> */}
        </div>
      </div>
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
              <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/HeritageBuildingHome'> {t("Heritage Home")} </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Exemption_of_Tax'> {t("Exemption of Tax")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Heritage_Heritage_Buildings'> {t("Heritage Buildings")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Conservation_Architect'> {t("Conservation Architect")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('473')}>{t("Composition of the Heritage Conservation Committee")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Proceedings_of_HCC'>                   
                    {t("Proceedings of HCC")}
                  </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('474')}>{t("Action Plan")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('475')}>{t("Parking Policy")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('476')}>{t("Measures for prevention of landfill fire")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWisePond.jsp'>  {t("Ward wise Pond")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWiseHeritageBuilding.jsp'> {t("Ward Wise Heritage Building")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Environment_Heritage_Dept'> {t("Contact Details")} </Link>
                </li>

                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Activities_NCAP.pdf' target={'_blank'}> {t("Activities Permitted under NCAP")} </a>
                </li>

                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Swachh Vayu Survekshan")} <i className="bi bi-chevron-down" /></button>
                    <div id="demo2" className="collapse">
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessed_CPCB_scores_23_09_2023.xlsx'>1. {t("Self assessed scores & CPCB scores")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessment_reports_Kolkatacity_23_09_2023.pdf'>2. {t("Self assessment reports of Kolkata city2023")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Swachh_Vayu_Survekshan_23_09_2023.pdf'>3. {t("Swachh Vayu Survekshan 2023 Rank")} </a>
                    </div>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/airquality/citizenreport.do' target={'_blank'}> {t("Air quality status")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/emis/category.do' target={'_blank'}> {t(" Industry categorisation")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/District_Environment_ KMC2021_23_09_2023.pdf' target={'_blank'}> {t("FINAL District Environment plan KMC 2021")} </a>
                </li>

              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="">
            <div className="row">
              <div className="inner-title2" style={{marginLeft:"10px"}}>
                <h3 className="fontSizeClass">{t("Heritage Buildings")} </h3>
              </div>
              <div className="about-head1">
                <div className="dig-login1 dig-login2">
                  <form>
                    <div className="log-form inner-log-form">
                      <h3 className="fontSizeClass"> {t("List of Heritage Buildings")} </h3>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 form-group pt-2">
                          <div className="list-of-forms">
                            <h5 className="fontSizeClass"> 
                            <a className="fontSizeClass" onClick={(e)=>fileDownload('479')}>{t("Graded List of Heritage Buildings Grade-I, Grade-IIA & Grade-IIB premises as on 25/02/2009")} </a>
                            </h5>
                          </div>
                        </div>
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          
                          &nbsp;
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Heritage_Heritage_Buildings