import React,{useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { generateHash } from './CommonFunction';
import { mapToOrigin } from './httpConstant';

const Health_Dept  = () => {
  const [tableData, setTableData]= useState([])
  const getPortalContact = async () => {
    try {
        let shaKey = await mapToOrigin("SHA_KEY");
        let app_id = await mapToOrigin("APP_ID");
    
        const generatedHash = generateHash(shaKey);
        const url = "https://kmcdev.ddns.net/tender-be-service/getPortalContact";
        let reqData = {
            hashInfo: {
                hashTk: generatedHash,
                appId: "ekmchr",
            },
            itemInfo: {
                department: "640002",
            }
        };

        axios
            .post(url, { reqData })
            .then((res) => {
                if (res.data) {
                    if (res.status === 200) {
                        console.log("test", res)
                        if (res.data.data && res.data.data.length) {
                          setTableData (res.data.data);
                            let roles = res.data.data;
                            let rolesArray = [];
                            for (let i = 0; i < roles.length; i++) {
                                let roleObj = {
                                    value: roles[i].id,
                                    label: roles[i].name,
                                    designation: roles[i].designation,
                                    office_no: roles[i].office_no,
                                    photo: roles[i].photo,
                                };
                                rolesArray.push(roleObj);
                            }
                           // setContactList(rolesArray);
                            console.log("contactlist", rolesArray)
                        }
                    }
                }
            })
            .catch((e) => { });
    } catch (err) { }
};
useEffect(()=>{
  getPortalContact();    
},[])
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
<div className="fontSizeClass">
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"> <h4 className="fontSizeClass"></h4></div></div>             
    </div>       	 		 
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">           
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
              <li className="nav-item">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Certificate of Enlistment")} <i class="bi bi-chevron-down"></i> </button>
                    <div id="demo" className="collapse">
                        <a href='https://www.kmcgov.in/KMCPortal/downloads/725_MA_Revised_Enlistment_Trade_Calling.pdf' target="_blank"> {t("Guidline")} </a>
                        <a href='https://www.kmcgov.in/KMCPortal/downloads/TradeLicenceEoDBProcedure.pdf' target="_blank"> {t("Procedure")} </a>               
                    </div>
                </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Advertisement_Dept'> {t("Advertisement Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Amusement_Dept'> {t("Amusement Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Assessment_Dept'> {t("Assessment Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/'> {t("Building Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Bustee_Dept'> {t("Bustee Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Car_Parking_Dept'> {t("Car Parking Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Chief_Eng_Dept'> {t("Chief Eng Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Ch_VS_Dept'> {t("Ch. VS Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Sewerage_Drainage_Dept'> {t("Sewerage Drainage Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Education_Dept'> {t("Education Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Electricity_Dept'> {t("Electricity Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Finance_Dept'> {t("Finance Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Health_Dept'>  {t("Health Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Internal_Audit_Dept'> {t("Internal Audit Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/IT_Dept'> {t("I.T. Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Law_Dept'> {t("Law Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Licence_Dept'> {t("Licence Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Lighting_Dept'> {t("Lighting Dept")}</Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Market_Dept'> {t("Market Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Mpl_Secretary_Dept'> {t("Mpl.Secretary's Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Personnel_Dept'>  {t("Personnel Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Parks_Square_Dept'>  {t("Parks & Square Dept")}. </Link> </li>	

                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Environment_Heritage_Dept'>  {t("Environment & Heritage Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Roads_Dept'>  {t("Roads Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Social_Welfare_Urban_Dept'> {t("Social Welfare & Urban Proverty Alleviation Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Solid_Waste_Dept'>  {t("Solid Waste Mgmt. Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Town_Planning_Development_Dept'>  {t("Town Planning & Development Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Treasury_Dept'>  {t("Treasury Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Water_Supply_Dept'>  {t("Water Supply Dept")} </Link> </li>									
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2"><h3 className="fontSizeClass"> {t("Officers Of Health Department (head Quarter)")} </h3></div>		   
            <div className="col-lg-12 about-head1">
              <div className="mayor-table2 mayor-table">		 	   
                <table className="table table-bordered"> 			
                  <thead>
                    <tr className="table-warning table-bor-2">				 
                        <th className="bd-wid" scope="col"> {t("Sl. No")}. </th>
                        <th scope="col"> {t("Name")} </th>
                        <th scope="col"> {t("Designation")} </th>
                        <th scope="col"> {t("Office Phone No")}. </th>
                      <th scope="col"> {t("Email ID")} </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableData && tableData.map((item,index)=>{
                        return(
                          <tr>
                          <th scope="row"> {index+1} </th>
                          <td> {item.name} </td>
                          <td> {item.designation} </td>
                          <td> {item.office_no} </td>
                        </tr>
                        );
                      })
                    
                    }
                  </tbody>
                </table>
                <h4 className="fontSizeClass"> {t("Contact Numbers Of Borough Executive Health Officers & Wards Of Health Department")} </h4>
                <table className="table table-bordered"> 			
                  <thead>
                    <tr className="table-warning table-bor-2">				 
                        <th className="bd-wid" scope="col"> {t("Sl. No")}. </th>
                        <th scope="col"> {t("Name")} </th>
                        <th scope="col"> {t("Designation")} </th>
                        <th scope="col"> {t("Wards")} </th>
                        <th scope="col"> {t("Office Phone No.")} </th>
                        <th>{t("Email ID")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row"> 10 </th>
                      <td> {t("Dr. Manirupa Sarkar")} </td>
                      <td> {t("MOIC(Executive Health Officer, Br-I)")} </td>
                      <td> 1, 2, 3, 4, 5, 6, 7, 8, 9 </td>
                      <td> 2546-0846 </td>
                      <td> eho_bri@kmcgov.in </td>
                    </tr>
                    <tr>
                      <th scope="row"> 11 </th>
                      <td> {t("Dr. Basudeb Mukherjee")} </td>
                      <td> {t("Dy CMHO VI(Incharge Br - II) & N.O. (Ambulance)")} </td>
                      <td> 10, 11, 12, 15, 16, 17, 18, 19, 20 </td>
                      <td>2555-9143</td>
                      <td>eho_brii@kmcgov.in</td>
                    </tr>
                    <tr>
                      <th scope="row"> 12 </th>
                      <td> {t("Dr. Sonia Majumder")} </td>
                      <td> MOIC, Br - III </td>
                      <td> 13, 14, 29, 30, 31, 32, 33, 34, 35 </td>
                      <td> 2364-8404 </td>
                      <td> eho_briii@kmcgov.in </td>
                    </tr>
                    <tr>
                      <th scope="row"> 13 </th>
                      <td> {t("Dr. Sukti Mukherjee")} </td>
                      <td> {t("Dy CMHO, In-charge Br - IV")} </td>
                      <td> 21, 22, 23, 24, 25, 26, 27, 28, 38, 39 </td>
                      <td> 2272-4560 </td>
                      <td> eho_briv@kmcgov.in </td>
                    </tr>
                    <tr>
                      <th scope="row"> 14 </th>
                      <td> {t("Dr. Debasish Ray")} </td>
                      <td> {t("Executive Health Officer, Br - V")} </td>
                      <td> 36, 37, 40, 41, 42, 43, 44, 45, 48, 49, 50 </td>
                      <td> 2241-4802 </td>
                      <td> eho_brv@kmcgov.in </td>
                    </tr>
                    <tr>
                      <th scope="row"> 15 </th>
                      <td> {t("Dr. Utpal Kanji")} </td>
                      <td> {t("Executive Health Officer, Br - VI")} </td>
                      <td> 46, 47, 51, 52, 53, 54, 55, 60, 61, 62 </td>
                      <td> 2286-1043 </td>
                      <td> eho_brvi@kmcgov.in </td>
                    </tr>
                    <tr>
                      <th scope="row"> 16 </th>
                      <td> {t("Dr. Jhuma Chakraborty")} </td>
                      <td> {t("Executive Health Officer, Br - VII")} </td>
                      <td> 56, 57, 58, 59, 63, 64, 65, 66, 67 </td>
                      <td> 2265-1020 </td>
                      <td> eho_brvii@kmcgov.in </td>
                    </tr>
                    <tr>
                      <th scope="row"> 17 </th>
                      <td> {t("Dr. Dwaipayan Mojumder")} </td>
                      <td> {t("Executive Health Officer, Br - VIII")} </td>
                      <td> 68, 69, 70, 72, 83, 84, 85, 86, 87, 88, 90 </td>
                      <td> 2464-9518 </td>
                      <td> eho_brviii@kmcgov.in </td>
                    </tr>
                    <tr>
                      <th scope="row"> 18 </th>
                      <td> {t("Dr. Subhajit Chakraborty")} </td>
                      <td> {t("MOIC, Br - IX")} </td>
                      <td> 71, 73, 74, 75, 76, 77, 78, 79, 80, 82 </td>
                      <td> 2479-1434 </td>
                      <td> eho_brix@kmcgov.in </td>
                    </tr>
                    <tr>
                      <th scope="row"> 19 </th>
                      <td> {t("Dr. Sarmistha Mitra")} </td>
                      <td> {t("MOIC (Executive Health Officer, Br X)")} </td>
                      <td> 81, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100 </td>
                      <td> 2422-6622 </td>
                      <td> eho_brx@kmcgov.in </td>
                    </tr>
                    <tr>
                      <th scope="row"> 20 </th>
                      <td> {t("Dr. Subrata Moulik")} </td>
                      <td> {t("Executive Health Officer, Br - XI")} </td>
                      <td> 103, 104, 110, 111, 112, 113, 114 </td>
                      <td> 2435-5968 </td>
                      <td> eho_brxi@kmcgov.in </td>
                    </tr>
                    <tr>
                      <th scope="row"> 21 </th>
                      <td> {t("Dr. Debasish Baroi")} </td>
                      <td> {t("MOIC, Br - XII")} </td>
                      <td> 101, 102, 105, 106, 107, 108, 109 </td>
                      <td> 2418-1970 </td>
                      <td> eho_brxii@kmcgov.in </td>
                    </tr>
                    <tr>
                      <th scope="row"> 22 </th>
                      <td> {t("Dr. Nagendra Nath Soren")} </td>
                      <td> {t("Executive Health Officer, Br - XIII")} </td>
                      <td> 115, 116, 117, 118, 119, 120 122 </td>
                      <td> 2447-1649 </td>
                      <td> eho_brxiii@kmcgov.in </td>
                    </tr>
                    <tr>
                      <th scope="row"> 23 </th>
                      <td> {t("Dr. Nagendra Nath Soren")} </td>
                      <td> {t("Executive Health Officer, Br - XIV")} </td>
                      <td> 121, 127, 128, 129, 130, 131, 132 </td>
                      <td> 2397-1041 </td>
                      <td> eho_brxiv@kmcgov.in </td>
                    </tr>
                    <tr>
                      <th scope="row"> 24 </th>
                      <td> {t("Dr. Rituparna Guha")} </td>
                      <td> {t("MOIC (Executive Health Officer, Br - XV)")} </td>
                      <td> 133, 134, 135, 136, 137, 138, 139, 140, 141 </td>
                      <td> 2489-3997 </td>
                      <td> eho_brxv@kmcgov.in </td>
                    </tr>
                    <tr>
                      <th scope="row"> 25 </th>
                      <td> {t("Dr.Suhena Sengupta")} </td>
                      <td> {t("MOIC (Executive Health Officer, Br - XVI)")} </td>
                      <td> 123, 124, 125, 126, 142 143, 144 </td>
                      <td> 2497-5080 </td>
                      <td> eho_brxvi@kmcgov.in </td>
                    </tr>
                  </tbody>
                </table>
                <h4 className="fontSizeClass"> {t("Other Important Numbers Of Health Department")} </h4>
                <table className="table table-bordered"> 			
                  <thead>
                    <tr className="table-warning table-bor-2">
                        <th className="bd-wid" scope="col"> {t("Sl. No")}. </th>
                        <th scope="col"> {t("Name")} </th>
                        <th scope="col"> {t("Designation")} </th>
                        <th scope="col"> {t("Office Phone No.")} </th>				 
                        <th scope="col"> {t("Email ID")} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row"> 26 </th>
                      <td> <Link to="https://www.kmcgov.in/KMCPortal/downloads/PublicSafetyStrayDogs.pdf" target="_blank"> {t("Public Safety (Stray Dogs)")} </Link> </td>
                      <td> {t("Dr. Manas Som, Nodal Officer Public Safety")} </td>
                      <td> 2252-0290 </td>
                      <td> suptd.dr.manassom07@gmail.com </td>         
                    </tr>
                    <tr>
                      <th scope="row"> 27 </th>
                      <td> {t("Ambulance")} </td>
                      <td> - </td>
                      <td> 2219-7202 / 7201 </td>
                      <td> eho_brii@kmcgov.in </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>		  
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>
</div>
  )
}

export default Health_Dept