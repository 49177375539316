import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Building_Faqs  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
 
  <section className="section inner-pad1">
        <div className="container " >
            <div className="row">
                <div className="col-lg-3">
                <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2 mb-4">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Building_Home'> {t("Building Home")}</Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Dashboard2' target="_blank"> {t("EODB Dashboard For Building Permit")} </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2022_23'> {t("Circular")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('319')}> {t("Building Demand Notice")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Floor_Area_Ratio'> {t("Floor Area Ratio")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Ground_Covergae'> {t("Ground Coverage")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Borough_Offices'> {t("Borough Offices")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('320')}> {t("Fees and Charges")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalBuildingLBSDetails.jsp' target="_blank"> {t("Find LBS")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalESESearch.jsp' target="_blank"> {t("Find ESE")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalBuildingGTDetails.jsp' target="_blank"> {t("Find GTE")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalBuildingESRDetails.jsp' target="_blank"> {t("Find ESR")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/HearingNotice.jsp' target="_blank"> {t("Check Hearing Notice")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Download_Forms'> {t("Download Forms")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/PlanProposalStatusSearch.jsp' target="_blank"> {t("Building Plan Proposal Status")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCViewBuildingDemand.jsp' target="_blank"> {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingDemandPrint.jsp' target="_blank"> {t("Print Unpaid Bill")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingPayment.jsp' target="_blank"> {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingReceiptReprint.jsp' target="_blank"> {t("Reprint e-Receipt")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('320')}> {t("Instruction Set for Online Submission of Building Plan")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/Building/gotologin.do' target="_blank"> {t("Online Submission of Building Plan")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('322')}> {t("Online CC Circular")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Important_EODB_circulars'> {t("Important EODB Circulars")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('323')}> {t("Instruction set for Online Notice of Commencement and Notice of Completion Certificate")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp' target="_blank">{t(" Online Notice of Commencement/CC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('324')}> {t("MC Circular No.44 of 2021-22 Regarding Assessment Data at the time of CC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href="assets/img/Final_Format_Flat_Assessment.xls" download="Final_Format_Flat_Assessment.xls"><i className="bi bi-download"></i> {t("Download Standard(.xls) format for MC Circular No.44 of 2021-22")} </a>                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://docs.google.com/forms/d/e/1FAIpQLSfmcCELn86TXflHLGQo4ZbhOiGhbN93TTmulOf15G7O5rVIpw/closedform' target="_blank"> {t("Architect Enlistment and Renewal")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp' target="_blank"> {t("License Renewal for Technical Personnel")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('325')}> {t("Instruction set for Online Licence Renewal For Technical Personnel")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('326')}> {t("Instruction Set for preparation of AutoCAD Drawing for Online Submission")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingPlanCaseSearch.jsp' target="_blank"> {t("Sanctioned Plan Search")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingCompletionCertificateSearch.jsp' target="_blank"> {t("Completion Certificate Search")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingUnauthorisedCaseSearch.jsp' target="_blank"> {t("Unauthorised Case Search")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Building_Faqs'> {t("FAQs")}</Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2021_22' > {t("Office Order")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Communique'> {t("Communique")}  </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('325')}> {t("DGBuilding Circular No 9(2013-2014)")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('327')}> {t("Empanelment of Geo-Technical")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('329')}> {t("West Bengal Act XIX of 2014 & The Kolkata Municipal Corporation(Amendment)Act,2014")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('328')}> {t("Check list for Sanction of Building Plan")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('330')}> {t("Building Rules 2009 including amendment")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://nocas2.aai.aero/nocas/' target="_blank"> {t("Airport Building Height Clearance")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('331')}> {t("Applications are invited from the Civil Engineers of Empanelment of Geo-Technical Engineers")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('66')}> {t("3rd Amendment of KMC Building Rules 2009 Geo Tech and Road width")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('67')}> {t("Communique(The Amendment of Building Rules,2009 video notification No 80/MA/O/C-4/3R-7/2017)")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('68')}> {t("Distribution of guideline among Building-owners/Constituted Attorney in different wards of KMC for prevention of mosquito breeding at construction sites on an urgent basis")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('69')}> {t("Notification No 445/MA/O/C-4/3R-3/2014")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Building_notice_28_05_2019.pdf' target="_blank"> {t("Building Notice")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/LicenseNoWiseSanctionSearch.jsp' target="_blank"> {t("LBS/CA/ESE/GTE/ESR WISE SANCTION SEARCH")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Notice_LBS_Architect_03_09_2019.rar' target="_blank"> {t("Notice for LBS/Architect")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('70')}> {t("EODB Proforma For Appointment of Technical Person")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('71')}> {t("EODB Standard Reasons Of Rejection")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('72')}> {t("EODB IGR Non-insisting of KMC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('73')}> {t("EODB Workflow")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('74')}> {t("FAQ for EODB")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('75')}> {t("User Manual for EODB")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('76')}> {t("Public Notice -- Pollutions at Construction Site")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('c16f7b2c-4d2b-4b8c-96fd-0b81ba0516ef')}> {t("Circulation of Administrative order for regulating sanction of building plan/construction")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href="assets/img/Enemy_Properties.xls" download="Enemy_Properties.xls"><i className="bi bi-download"></i> {t("List of Enemy Properties")} </a>
                </li>
              </ul>
            </nav>
          </div>
                </div>
                <div className="col-lg-9">
                <div className="container">
                    <div className="row">
                    <div className="inner-title"><h3 className="fontSizeClass">{t("Dept.of Building :: FAQs")}</h3>
                    </div>
                        <div id="accordion">
                        <div className="card">
                            <div className="card-header" id="heading1">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                            1. {t("What are the key activities of the Department")}? 
                            </button>
                            </div>
                            <div id="collapse1" className="collapse show" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                                <p className="fontSizeClass">{t("The key activities of the department are")}:<br />
                                    a. {t("To extend sanction for erection of new building")}.<br />
                                    b. {t("To extend sanction to for extension/ remodeling/renovation of existing building")}.<br />
                                    c. {t("To stop unauthorised construction")}.<br />
                                    d. {t("To demolish unauthorised construction")}.<br />
                                    e. {t("To consider the constructions done without proper permission")}.<br />
                                    f. {t("To keep vigilance against any of heritage building")}.<br />
                                    g. {t("Delivery of certified copy of plans")}.<br />
                                    h. {t("To take reasonable action against insecure buildings")}.<br />
                                    i. {t("To application submitted under RTI Act under guide line")}.</p>
                            </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="heading2">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                            2. {t("What activities do not require any permission from KMC’s building department")}?
                            </button>
                            </div>
                            <div id="collapse2" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1505')} target="_blank">{t("Please consult Rule 3(2) of KMC Building Rule 2009")}.</a>
                            </div>                      
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="heading3">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                            3. {t("Do I need to take approval from KMC to demolish my building")}?
                            </button>
                            </div>
                            <div id="collapse3" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                                <p className="fontSizeClass">{t("No permission is required in case of non-heritage buildings. However, a notice is to be given to the municipal commissioner in this regard. You should also ensure that the demolition work is carried out under the supervision of an empanelled Structural Engineer. But, in case of a heritage building permission from heritage conservation committee is required")}.</p>
                            </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="heading4">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                            4. {t("What activities require permission from KMC's building department")}?
                            </button>
                            </div>
                            <div id="collapse4" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                                <p className="fontSizeClass">	
                                    {t("Work that requires permission from KMC's building department includes the following")},<br />
                                    a. {t("Erection of a new building")}<br />
                                    b. {t("Addition or alteration of an existing building")}<br />
                                    c. {t("Re-erection of any building where the structure has been pulled down, burnt or destroyed")}.<br />
                                    d. {t("Change of use of an existing building")}<br />
                                    e. {t("Reconstruction of an existing roof")}<br />
                                    f. {t("Any structural change")}<br />
                                    g. {t("Removal or reconstruction of principal staircase or alteration of its position")}<br />
                                    h. {t("Installation of a new lift")}<br />
                                    i. {t("Permanent closure of any door or window in any external window")}</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="heading5">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                            5. {t("What consequences would I face if I undertake any of the above construction activities without obtaining sanction")}?
                            </button>
                            </div>
                            <div id="collapse5" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                                <p className="fontSizeClass">{t("KMC is very serious about ensuring that each building in its jurisdiction is constructed with appropriate authorization. In case of unauthorized construction, KMC may lodge a complaint with the police who would in turn issue an arrest warrant. KMC may also decide to demolish the building without granting any opportunity for hearing")}.</p>
                                </div>
                            </div>
                            </div>

                            <div className="card">
                            <div className="card-header" id="heading6">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                            6. {t("What application form do I need to fill up, what documents do I need to submit for getting permission from the Building Department")}?
                            </button>
                            </div>
                            <div id="collapse6" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            <Link className="nav-link fontSizeClass" to='/Building_Sanction_Download_Forms' target="_blank">{t("Click Here")}.</Link>
                            </div>
                            </div>

                            <div className="card">
                            <div className="card-header" id="heading7">

                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                            7. {t("Can I construct any building with asbestos sheet or similar material without sanction")}?
                            </button>
                            </div>
                            <div id="collapse7" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            {t("No, such construction is not allowed without approval from KMC")}.</div>
                            </div>

                            <div className="card">
                            <div className="card-header" id="heading8">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
                            8. {t("Is there any stipulation regarding building materials that can be used")}?
                            </button>
                            </div>
                            <div id="collapse8" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            <p className="fontSizeClass">{t("There are no specific stipulations but the materials should be as per the NBC")}.</p>        
                            </div>
                            </div>
                            </div>

                            <div className="card">
                            <div className="card-header" id="heading9">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
                            9. {t("What are the basic parameters used for calculation of sanction fees in case of building plan")}?
                            </button>
                            </div>
                            <div id="collapse9" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                                <Link className="nav-link fontSizeClass" to='/https://www.kmcgov.in/KMCPortal/jsp/BuildingDeptFees.jsp' target="_blank">{t("Click Here")}.</Link>
                            </div>
                            </div>
                            </div>

                            <div className="card">
                            <div className="card-header" id="heading10">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse10" aria-expanded="true" aria-controls="collapse10">
                            10. {t("What departments/Institutions do I need to get an NOC issued from? Why is such an NOC required")}?
                            </button>
                            </div>
                            <div id="collapse10" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                                <p className="fontSizeClass">{t("Please consult")}<a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1506')} target="_blank">{t("Rule 4 of KMC Building Rule 2009")}.</a> {t("Other information may be obtained from License Building surveyor")}.</p>
                            </div>
                            </div>

                            <div className="card">
                            <div className="card-header" id="heading11">
                            
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                            11. {t("Under what cases the observation of the Municipal Building Committee (MBC) is required prior to sanction")}?
                            </button>
                            </div>
                            <div id="collapse11" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            <p className="fontSizeClass">{t("Please consult")}<a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1507')} target="_blank">{t("Rule 38 of KMC Building Rule 2009")}.</a> </p>
                            </div>
                            </div>
                            </div>

                            <div className="card">
                            <div className="card-header" id="heading12">
                            
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse12" aria-expanded="true" aria-controls="collapse12">
                            12. {t("To undertake construction work or repair work, I need to stack up the building material on the footpath. Do I need to take permission? If yes, then from whom")}?
                            </button>
                            </div>
                            <div id="collapse12" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            {t("You need to take permission from the conservancy department of KMC. You can contact either the ward office or the borough office")}.</div>
                            </div>
                            </div>

                            <div className="card">
                            <div className="card-header" id="heading13">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse13" aria-expanded="true" aria-controls="collapse13">
                            13. {t("I need to erect scaffolding on footpath or road. Do I need to take permission")}?
                            </button>
                            </div>
                            <div id="collapseThree" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            <p className="fontSizeClass">{t("You need to take permission from the conservancy department of KMC. You can contact either the ward office or the borough office")}.</p>      
                            </div>
                            </div>
                            </div>

                            <div className="card">
                            <div className="card-header" id="heading14">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse14" aria-expanded="true" aria-controls="collapse14">
                            14. {t("To undertake construction work or repair work, I need water for plastering, flooring and other repair/civil work. Do I need to take permission from KMC for use of water")}?
                            </button>
                            </div>
                            <div id="collapse14" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            {t("Yes, you need to take permission from the water supply department of KMC. You would be required to pay prescribed charges for the same")}.</div>
                            </div>
                            </div>

                            <div className="card">
                            <div className="card-header" id="heading15">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse15" aria-expanded="true" aria-controls="collapse15">
                            15. {t("After issuance of building sanction plan for how long can commencement / completion of construction work wait")}?
                            </button>
                            </div>
                            <div id="collapse15" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            {t("You need to start the construction of your building within 2 years of sanctioning of building plan. The construction of building should be completed within 5 years from the date of sanction of building plan")}.
                            </div>
                            </div>
                            </div>

                            <div className="card">
                            <div className="card-header" id="heading16">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse16" aria-expanded="true" aria-controls="collapse16">
                            16. {t("If I have exceeded the time frame for completion how can I extend the validity period")}?
                            </button>
                            </div>
                            <div id="collapse16" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                                <p className="fontSizeClass">{t("In order to apply for such extension, you need to submit the following documents")} :<br />
                                    a. {t("Application letter giving reasons for seeking such extension")}<br />
                                    b. {t("Consolidated rate bill (Current)")}<br />
                                    c. {t("Letter from concerned LBS/Architect and ESE, certifying that the construction already done is in conformity with the Building Permit. The objective is to ensure structural stability")}<br />
                                    d. {t("Letter of Commencement")}<br />
                                    e. {t("Copy of the Sanctioned building plan")}</p>
                            </div>
                            </div>
                            </div>
                            <div className="card">
                            <div className="card-header" id="heading17">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse17" aria-expanded="true" aria-controls="collapse17">
                            17. {t("Do I need to submit any documents to KMC after the completion of construction")}?
                            </button>
                            </div>
                            <div id="collapse17" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                                <p className="fontSizeClass">{t("Please consult Rule 27 of KMC Building Rule 2009")}.</p>
                            </div>
                            </div>
                            </div>
                            <div className="card">
                            <div className="card-header" id="heading17">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse17" aria-expanded="true" aria-controls="collapse17">
                            18. {t("If there is a building around in dilapidated condition what action can I take as a citizen")}?
                            </button>
                            </div>
                            <div id="collapse17" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                                <p className="fontSizeClass">{t("KMC encourages responsible citizens to provide information regarding dilapidated buildings. Kindly inform DG (Building) or Deputy Chief Engineer or Borough Executive Engineer (Building)")}.</p>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div> 
                </div>
                </div>
            </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </section>
  
    </>
  )
}

export default Building_Faqs