import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Health_Antirabies_VACCINATION_Center  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="img" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass">  </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
      <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_services_Health_Home'> {t("Health Home")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('458')}> {t("Dengue - Status-Appeal to Citizens")} </a>
                 </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('459')}> {t("Immunisation centers")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('460')}> {t("Health Units")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Malaria_CLinics'> {t("Malaria Clinics")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Maternity_Homes'> {t("Maternity Homes")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_KMC_Dispensaries'> {t("KMC Dispensaries")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Chest_Clinincs'> {t("KMC Chest Clinics")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WaterTestDuplicateReceipt.jsp'> {t("Water Test Reprint e-Receipt")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Desease_control'> {t("Desease Control")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Fees_Charges'> {t("Fees & Charges")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_MTMTB_Hospital'> {t("MTMTB Hospital")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Slaughter_House'> {t("Slaughter House")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Ambulances'> {t("Ambulance")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Health_Antirabies_VACCINATION_Center'> {t("Antirabies Vaccination Centre")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Designation_Activities'>{t("Designation Activity")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_KMDA_Health_Projects'> {t("KMDA Health Projects")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BurningGhatStatus.jsp'> {t("Burning Ghat Status")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Land_Records_Burial_Grounds'>{t("Land Records Of Burial Grounds")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Dept'> {t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('461')}> {t("Information on FSS Act-License / Registration in KMC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('462')}> {t("Locations of Slaughtering of Animals on the Occasion of ID-UD-ZOHA(BAKRI-ID) ON 10.07.2022, 11.07.2022 & 12.07.2022")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('469')}> {t("Swasthya Bandhab Sharad Samman")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('470')}> {t("Details of UPHC")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('466')}> {t("List of Enlisted Agencies for Carrying Covid Bodies")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('471')}> {t("Immersion training by ECHO India for KMC Health staff")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('472')}> {t("KMC Participation and Experiences at ECHO India's IPC Dissemination Event : Mumbai")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'>
                    {t("Application/Payment for Potable Water Analysis")}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Antirabies Vaccination Centre")} </h3>
              </div>
              <div className="mayor-table health-dept-table">
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"
                    >
                      <td width="7%" className="text-white tble-brder-left">
                        {t("Borough")}
                      </td>
                      <td width="38%" className="text-white">
                        {t("Location")}
                      </td>
                      <td width="37%" className="text-white tble-brder-right">
                        {t("Landmark")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        I
                      </td>
                      <td width="38%">
                        {t("Chitpur dispensary")} <br />3 {t("Gopal mukherjee st kolkata")} -
                        2<br />
                      </td>
                      <td width="37%">{t("Paikpara more")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        II
                      </td>
                      <td width="38%">
                        {t("Hatibagan dispensary")} <br />
                        {t("160 Aurobinda sarani kolkata")} - 6<br />
                      </td>
                      <td width="37%">
                        {t("Crossing of apc road & grey street., near khanna cinema")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        III
                      </td>
                      <td width="38%">
                        {t("Narkeldanga dispensary 109 Narkeldanga main rd.")} <br />
                        {t("kolkata")} - 54
                        <br />
                      </td>
                      <td width="37%">{t("Phoolbagan more")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        IV
                      </td>
                      <td width="38%">
                        {t("Ward health unit, ward 25 9a, baranashi ghosh lane, kolkata – 6")}
                      </td>
                      <td width="37%">{t("Simla byam samity")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        V
                      </td>
                      <td width="38%">
                        {t("Ward health unit, ward 49")}
                        <br />
                        {t("34 Surya sen street kolkata")} - 9<br />
                      </td>
                      <td width="37%">Shraddhananda park</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        VI
                      </td>
                      <td width="38%">
                        {t("Taltala dispensary")}
                        <br />{t("3 Girish chandra bose rd. kolkata")} - 14
                        <br />
                      </td>
                      <td width="37%">{t("Kmc garage, behind old gem cinema")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        VII
                      </td>
                      <td width="38%">
                        {t("Dhapa dispensary, ward 58")}
                        <br />{t("2 Dhapa bazar 2 dhapa kol")}
                      </td>
                      <td width="37%">Dhapa bazar</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        VIII
                      </td>
                      <td width="38%">
                        {t("Borough viii office")}
                        <br />
                        {t("Vivekananda park jatin bagchi rd. kolkata")} - 29
                        <br />
                      </td>
                      <td width="37%">{t("Vivekananda park")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        IX
                      </td>
                      <td width="38%">
                        {t("Chetla dispensary")}
                        <br />
                        {t("29/5 chetla central rd. kolkata")} - 27
                        <br />
                      </td>
                      <td width="37%">{t("Chetla boy’s school")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        X
                      </td>
                      <td width="38%">
                        {t("Ward health unit, ward 81")}
                        <br />
                        {t("16, mondal temple rd. kolkata")} - 53 <br />
                      </td>
                      <td width="37%">{t("New alipur sbi")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        X
                      </td>
                      <td width="38%">
                        {t("Ward health unit, ward 96 (lyalka chest clinic)")}
                        <br />
                        {t("lyalka rd. kolakta - 92")} <br />
                      </td>
                      <td width="37%">{t("Lyalka")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        XI
                      </td>
                      <td width="38%">
                        {t("Kmc, dispensary, ward 114")}
                        <br />
                        {t("purba putiyari kolkata - 93")}
                        <br />
                      </td>
                      <td width="37%">{t("Sourjashree club")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        XII
                      </td>
                      <td width="38%">
                        {t("Esopd")}
                        <br />
                        {t("47 garfa main rd. kolkata - 93")} <br />
                      </td>
                      <td width="37%">{t("Palbazar, state bank of india")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        XIII
                      </td>
                      <td width="38%">
                        {t("Ward health unit, ward 123")}
                        <br />{t("2 raja rammohan roy rd. kolkata - 8")}<br />
                      </td>
                      <td width="18%">{t("Barisha high school, behala chowrasta")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        XIV
                      </td>
                      <td width="38%">
                        {t("Ward health unit, ward 132")}
                        <br /> {t("upen banerji rd. parnasri kol - 60")}
                        <br />
                      </td>
                      <td width="18%">{t("Nababani park, parnasree bus stand")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        XV
                      </td>
                      <td width="38%">
                        {t("Garden reach maternity home dispensary")}
                        <br />
                        {t("b-3 prince dilwar jaan kol-24")}
                        <br />
                      </td>
                      <td width="37%">{t("Kamal talkies cinema")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>

  )
}

export default Health_Antirabies_VACCINATION_Center