import React from 'react';
import { Link } from "react-router-dom";

const Education_Dept_Contact_Details  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
 
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        {" "}
        <div className="section-main-heading2">
          {" "}
          <h4 className="fontSizeClass"> Education Department Officials </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container" >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">           
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item has-submenu">
                  <Link className="nav-link fontSizeClass"> Certificate of Enlistment <i className="bi bi-chevron-right" /> </Link>
                  <ul className="submenu collapse show">
                    <li><Link className="nav-link fontSizeClass" to="https://www.kmcgov.in/KMCPortal/downloads/725_MA_Revised_Enlistment_Trade_Calling.pdf"> Guidline</Link></li>
                    <li><Link className="nav-link fontSizeClass" to="https://www.kmcgov.in/KMCPortal/downloads/TradeLicenceEoDBProcedure.pdf"> Procedure </Link></li>
                  </ul> 
                </li>
                <li className="nav-item"><Link className="nav-link  fontSizeClass" to='/Advertisement_Dept'> Advertisement Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Amusement_Dept'> Amusement Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Assessment_Dept'> Assessment Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/'> Building Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Bustee_Dept'> Bustee Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Car_Parking_Dept'> Car Parking Dept. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Chief_Eng_Dept'> Chief Eng Dept. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Ch_VS_Dept'> Ch. V&amp;S Dept. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Sewerage_Drainage_Dept'> Sewerage Drainage Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Education_Dept'> Education Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Electricity_Dept'> Electricity Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Finance_Dept'> Finance Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Health_Dept'>  Health Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Internal_Audit_Dept'> Internal Audit Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/IT_Dept'> I.T. Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Law_Dept'> Law Dept. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Licence_Dept'> Licence Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Lighting_Dept'> Lighting Dept</Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Market_Dept'> Market Dept. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Mpl_Secretary_Dept'> Mpl.Secretary's Dept. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Personnel_Dept'>  Personnel Dept. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Parks_Square_Dept'>  Parks & Square Dept. </Link> </li>	

                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Environment_Heritage_Dept'>  Environment &amp; Heritage Dept. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Roads_Dept'>  Roads Dept. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Social_Welfare_Urban_Dept'> Social Welfare &amp; Urban Proverty Alleviation Dept. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Solid_Waste_Dept'>  Solid Waste Mgmt. Dept. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Town_Planning_Development_Dept'>  Town Planning &amp; Development Dept. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Treasury_Dept'>  Treasury Dept. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Water_Supply_Dept'>  Water Supply Dept </Link> </li>									
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass"> Important Officials of Education Department</h3>
              </div>
              <div className="mayor-table swrg-tble">
                <table className="table table-bordered officials rm-mg">
                  <tbody>
                    <tr bgcolor="#302ba0" tabIndex={0}>
                      <td
                        width="10%"
                        rowSpan={2}
                        className="text-white tble-brder-left"
                        align="center"
                        valign="middle"
                      >
                        Sl. No.
                      </td>
                      <td
                        width="27%"
                        rowSpan={2}
                        align="center"
                        className="text-white"
                      >
                        Name
                      </td>
                      <td
                        width="23%"
                        rowSpan={2}
                        align="center"
                        className="text-white"
                      >
                        Designation
                      </td>
                      <td
                        colSpan={2}
                        rowSpan={2}
                        align="center"
                        className="text-white tble-brder-right"
                      >
                        {" "}
                        Office Phone No.
                      </td>
                    </tr>
                    <tr>
                      {/* <td width="20%" style="padding:3">Office</td>
                  <td width="13%" style="padding:3">Resi.</td> */}
                    </tr>
                    <tr align="center" valign="middle" tabIndex={0}>
                      <td>1.</td>
                      <td align="left">Siddhartha Shankar Dhara</td>
                      <td align="left">Chief Manager(Education)</td>
                      <td align="left">033 2286 0053 Extn:2534</td>
                    </tr>
                    <tr align="center" valign="middle" tabIndex={0}>
                      <td>2.</td>
                      <td align="left">Raktim Gangopadhyay </td>
                      <td align="left">Senior Education Officer </td>
                      <td align="left">
                        033 2252 0372 Extn:2890 <br />
                      </td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>3.</td>
                      <td align="left">- </td>
                      <td align="left">Education Officer</td>
                      <td align="left">-</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>4.</td>
                      <td align="left">Debnath Naskar</td>
                      <td align="left">Administrative Officer</td>
                      <td align="left">9874119482 </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>5.</td>
                      <td align="left">Swapan Mondal</td>
                      <td align="left">Administrative Officer </td>
                      <td align="left">8777673819</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>6.</td>
                      <td align="left">Tarun Kumar Das</td>
                      <td align="left">Administrative Officer</td>
                      <td align="left">9903118892</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>7.</td>
                      <td align="left">Ranajit Adhikary</td>
                      <td align="left">Administrative Officer </td>
                      <td align="left">8697188795</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>8.</td>
                      <td align="left">Somnath Chowdhury</td>
                      <td align="left">Deputy Education Officer</td>
                      <td align="left">6294025238</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>9.</td>
                      <td align="left">Debanjan Maulik</td>
                      <td align="left">Deputy Education Officer</td>
                      <td align="left">8420286702</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>10.</td>
                      <td align="left">Ganga Ram Paswan</td>
                      <td align="left">Deputy Education Officer</td>
                      <td align="left">9748552265</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>11.</td>
                      <td align="left">Ashoke Kumar Biswas </td>
                      <td align="left">Deputy Education Officer</td>
                      <td align="left">9836015170</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Education_Dept_Contact_Details