import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Ch_VS_Dept_Rates_Charges = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const [ReprintServices, setReprintServices] = React.useState(false);

  const ReprintServicesToggle = () => {
    setReprintServices(!ReprintServices);
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
    <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu ">
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/CH_VS_Dept_Download_Forms'> {t("Download Forms")} </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link active1 fontSizeClass" to='/Ch_VS_Dept_Rates_Charges'> {t("Fees & Charges")} </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardwiseSmartMap.jsp' target='_blank'> {t("Online Smart Map")} </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/SorDemandPrint.jsp' target='_blank'>{t("Print Unpaid Bill")} </a>
                  </li>
                  
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/SorDemandPayment.jsp' target='_blank'>{t("Make Online Payment")} </a>
                  </li>
                  <li className="nav-item ">
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Reprint e-Receipt")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo" className="collapse">                        
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/SorDuplicateReceipt.jsp' target='_blank'>1. SOR </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/SorDevDuplicateReceipt.jsp' target='_blank'>2. {t("Development Fee")} </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/SorGisMapDuplicateReceipt.jsp' target='_blank'>3. {t("GIS Map")}  </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/SorLeaseRentDuplicateReceipt.jsp' target='_blank'>4. {t("Less/Rent/License")}  </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/SorMiscDuplicateReceipt.jsp' target='_blank'>5. {t("Miscellanous")}  </a>
                      </div> 
                </li>
                  <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Ch_VS_Dept'> {t("Contact Details")} </Link>
                  </li>
                  <li className="nav-item">
                  <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('446')}> {t("Public Notice Modification Cancellation of Alignment")} </a>
                  </li>
                </ul>
              </nav>
            </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass">{t("Schedule of Rates & Charges for the year 2023 - 2024")} </h3>
              </div>
              <div className="mayor-table pump-list-tble">
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"
                      height={20}
                    >
                      <td width="10%" className="text-white text-light tble-brder-left">
                        <b>{t("Sl. No")}.</b>
                      </td>
                      <td width="50%" className="text-white text-light">
                        <b>{t("PARTICULAR")}</b>
                      </td>
                      <td width="40%" className="text-white text-light tble-brder-right">
                        <b>{t("RATE")}</b>
                      </td>
                    </tr>
                    <tr tabIndex={0} valign="middle" align="center">
                      <td colSpan={3}>
                        <b>I. {t("Sanction fee for development plan")}</b>
                      </td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">1.</td>
                      <td>{t("For 1st 100 sqm. or part thereof")}</td>
                      <td>Rs. 2000/-</td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">2.</td>
                      <td>{t("For above 100 sqm. area")}</td>
                      <td>{t("Rs. 20/- per sqm.")}.</td>
                    </tr>
                    <tr tabIndex={0} valign="middle" align="center">
                      <td colSpan={3}>
                        <b>II. {t("Fees for supply of Smart map")}</b>
                      </td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">1.</td>
                      <td>{t("Upto 100 sq.cm")}</td>
                      <td>{t("Rs. 15/- per sq.cm")}</td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">2.</td>
                      <td>
                        {t("Above 100 sq.cm upto 300 sq.cm(excluding 1st 100 sq.cm which shall be charged at Rs.8/- per sq.sm.)")}
                        
                      </td>
                      <td>{t("Rs. 25/- per sq.cm.")}.</td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">3.</td>
                      <td>{t("Above 300 sq.cm (to be charged for entire area)")}</td>
                      <td>{t("Rs. 50/- per sq.cm.")}.</td>
                    </tr>
                    <tr tabIndex={0} valign="middle" align="center">
                      <td colSpan={3}>
                        <b>
                          III. {t("Fee for supply of digitized Smart map (Computer print, Size : 24''X36'')")}
                        </b>
                      </td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">1.</td>
                      <td>{t("For Academic purpose")}</td>
                      <td>{t("Rs. 300/- per sheet")}</td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">2.</td>
                      <td>{t("For Non-academic purpose")}</td>
                      <td>{t("Rs. 1500/- per sheet")}</td>
                    </tr>
                    <tr tabIndex={0} valign="middle" align="center">
                      <td colSpan={3}>
                        <b>IV. {t("Licence fee")}</b>
                      </td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">1.</td>
                      <td>{t("For Road side garden")}</td>
                      <td>{t("Rs 2000/- per sqm. per annum")}</td>
                    </tr>
                    <tr tabIndex={0} valign="middle" align="center">
                      <td colSpan={3}>
                        <b>V. {t("Building observation fee")}</b>
                      </td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">1.</td>
                      <td>{t("For Each Building in one premise")}</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="right">a)</td>
                      <td>{t("Upto 7.0 m height")}</td>
                      <td>Rs. 5,000/-</td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="right">b)</td>
                      <td>{t("Above 7.0 m upto 10.0 m height")}</td>
                      <td>Rs. 7,000/-</td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="right">c)</td>
                      <td>{t("Above 10.0 m upto 12.5 m height")}</td>
                      <td>Rs. 18,000/-</td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="right">d)</td>
                      <td>{t("Above 12.5 m upto 15.5 m height")}</td>
                      <td>Rs. 37,000/-</td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="right">e)</td>
                      <td>{t("Above 15.5 m upto 20.0 m height")}</td>
                      <td>Rs. 50,000/-</td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="right">f)</td>
                      <td>{t("Above 20.0m upto 40.0 m height")}</td>
                      <td>Rs. 70,000/-</td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="right">g)</td>
                      <td>{t("Above 40.0m upto 60.0 m height")}</td>
                      <td>Rs. 85,000/-</td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="right">h)</td>
                      <td>{t("More than 60.0 m height")}</td>
                      <td>Rs. 1,65,000/-</td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="right">2</td>
                      <td>
                        {t("For one Premises no. having two or more Building (upto 7.00 m height)")}
                      </td>
                      <td>{t("Rs. 5000/- per Buildings and so on.")}.</td>
                    </tr>
                    <tr tabIndex={0} valign="middle" align="center">
                      <td colSpan={3}>
                        <i>
                          {t("(This formula is to be applied in case of b, c, d, e, f, g & h above also)")}
                        </i>
                      </td>
                    </tr>
                    <tr tabIndex={0} valign="middle" align="center">
                      <td colSpan={3}>
                        <b>VI. {t("Demarcation fees")}</b>
                      </td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">1.</td>
                      <td>{t("upto 10 m")}</td>
                      <td> Rs. 2,000/- </td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">2.</td>
                      <td>{t("Beyond 10 m")}</td>
                      <td>{t("Rs. 250/- per meter beyond ten m.")}.</td>
                    </tr>
                    <tr tabIndex={0} valign="middle" align="center">
                      <td colSpan={3}>
                        <b>VII. {t("Fees for inspection of survey map")}</b>
                      </td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">1.</td>
                      <td>{t("Land Area Upto 200 sqmt")}.</td>
                      <td>{t("Rs. 575/- per premises")}</td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">2.</td>
                      <td>{t("Land Area Above 200 sqmt upto 500 sqmt")}.</td>
                      <td>{t("Rs. 1250/- per premises")}</td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">3.</td>
                      <td>{t("Land Area Above 500 sqmt")}.</td>
                      <td>{t("Rs. 3000/- per premises")}</td>
                    </tr>
                    <tr tabIndex={0} valign="middle" align="center">
                      <td colSpan={3}>
                        <b>VIII. {t("Fees for inspection")}</b>
                      </td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">1.</td>
                      <td>{t("Fees for inspection of Regular line")}</td>
                      <td>{t("Rs. 1500/- per premises")}</td>
                    </tr>
                    <tr tabIndex={0} valign="middle" align="center">
                      <td colSpan={3}>
                        <b>IX. {t("Fees for Issuance of copy")}</b>
                      </td>
                    </tr>
                    <tr tabIndex={0} valign="middle">
                      <td align="center">1.</td>
                      <td>{t("Issuance of copy of regular line")}</td>
                      <td>{t("Rs. 3000/- per premises")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Ch_VS_Dept_Rates_Charges