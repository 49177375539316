import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";

const Central_Records_Fees  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      
    </div>
  </section>
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container" >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu ">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Engineering_Home'>Engineering (Civil) Home </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Central_Records_Fees'>Rates & Charges </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCRoadDevelopmentDetails.jsp'> KMC Road Development Scheme </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Engineering_Manhole_Desilting_Programme'>Manhole Desilting Programme of Borough-1 to XVI under Civil Dept </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('284')}> Tender Form Format </a> 
                </li>
                <li className="nav-item">
                    <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/ChiefEngContact.jsp'> Contact Details </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title">
                <h3 className="fontSizeClass"> Schedule of Fees &amp; Charges for the year 2022- 2023</h3>
              </div>
              <div className="mayor-table">
                <table
                  width="95%"
                  border={0}
                  cellSpacing={2}
                  cellPadding={2}
                  align="center"
                  className="rm-mg"
                  tabIndex={0}
                >
                  <tbody>
                    <tr align="left" valign="top" className="head_line_text">
                      <td
                        className="head_line_text"
                        style={{ border: "none !important" }}
                      >
                        <table
                          width="100%"
                          border={0}
                          cellSpacing={2}
                          cellPadding={2}
                          className="rm-mg inner-tbl"
                        >
                          <tbody>
                            <tr
                              bgcolor="302ba0"
                              tabIndex={0}
                              align="left"
                              style={{ height: 38 }}
                              valign="middle"
                            >
                              <td
                                width="10%"
                                align="center"
                                style={{ color: "#fff" }}
                              >
                                1.
                              </td>
                              <td colSpan={2} style={{ color: "#fff" }}>
                                Searching Fees of Assessment Register From
                                Record Deptt
                              </td>
                              <td
                                colSpan={2}
                                align="center"
                                style={{ color: "#fff" }}
                              >
                                Figures in Rs.
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">A)</td>
                              <td colSpan={2}>For Non-Bustee</td>
                              <td colSpan={2}>&nbsp;</td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="center">&nbsp;</td>
                              <td width="5%" align="center">
                                (i)
                              </td>
                              <td width="50%" align="left">
                                having annual valuation upto Rs. 18,000 /-
                              </td>
                              <td colSpan={2} align="center">
                                1,250.00 Per Assessee
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="center">&nbsp;</td>
                              <td align="center">(ii)</td>
                              <td align="left">
                                having Annual valuation above Rs 18,000/-
                              </td>
                              <td colSpan={2} align="center">
                                2,500.00 Per Assessee
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">B)</td>
                              <td colSpan={2}>
                                For Bustee/Hut/Colony Irrespective of A.V.
                              </td>
                              <td align="center" colSpan={2}>
                                150.00 Per Assessee
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5} bgcolor="FFFFFF">
                                &nbsp;
                              </td>
                            </tr>
                            <tr
                              bgcolor="302ba0"
                              tabIndex={0}
                              style={{ height: 38 }}
                              valign="middle"
                            >
                              <td align="center" style={{ color: "#fff" }}>
                                2.
                              </td>
                              <td colSpan={2} style={{ color: "#fff" }}>
                                Updated Certified copy of Assessment Book
                              </td>
                              <td
                                colSpan={2}
                                align="center"
                                style={{ color: "#fff" }}
                              >
                                Figures in Rs.
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">A)</td>
                              <td colSpan={2}>For Non-Bustee</td>
                              <td colSpan={2}>&nbsp;</td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td>&nbsp;</td>
                              <td align="center">(i)</td>
                              <td>having annual valuation upto Rs. 12,000/-</td>
                              <td colSpan={2} align="center">
                                650.00 Per Assessee
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td>&nbsp;</td>
                              <td align="center">(ii)</td>
                              <td>
                                having annual valuation above Rs. 12,000/- but
                                up to Rs. 18,000/-
                              </td>
                              <td colSpan={2} align="center">
                                1,250.00 Per Assessee
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td>&nbsp;</td>
                              <td align="center">(iii)</td>
                              <td>
                                having annual valuation above Rs. 18,000/-
                              </td>
                              <td colSpan={2} align="center">
                                2,500.00 Per Assessee
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">B)</td>
                              <td colSpan={2}>
                                For Bustee/Hut/Colony Irrespective of A.V.
                              </td>
                              <td align="center" colSpan={2}>
                                150.00 Per Assessee
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5}>&nbsp;</td>
                            </tr>
                            <tr
                              bgcolor="302ba0"
                              tabIndex={0}
                              style={{ height: 38 }}
                              valign="middle"
                            >
                              <td align="center" style={{ color: "#fff" }}>
                                3.
                              </td>
                              <td colSpan={2} style={{ color: "#fff" }}>
                                General Search
                              </td>
                              <td
                                colSpan={2}
                                style={{ color: "#fff" }}
                                align="center"
                              >
                                Figures in Rs.
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">A)</td>
                              <td colSpan={2}>For each file</td>
                              <td colSpan={2} align="center">
                                250.00
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">B)</td>
                              <td colSpan={2}>
                                For each Meeting proceedings in respect of one
                                item
                              </td>
                              <td colSpan={2} align="center">
                                250.00
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">C)</td>
                              <td colSpan={2}>
                                For certified copies having 100 words or part
                                thereof
                              </td>
                              <td colSpan={2} align="center">
                                250.00
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">D)</td>
                              <td colSpan={2}>
                                For Expeditious fees for above cases
                              </td>
                              <td colSpan={2} align="center">
                                250.00
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">E)</td>
                              <td colSpan={2}>
                                For Expeditious fees for cases prior to 1965
                              </td>
                              <td colSpan={2} align="center">
                                400.00
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5}>&nbsp;</td>
                            </tr>
                            <tr
                              bgcolor="302ba0"
                              tabIndex={0}
                              style={{ height: 38 }}
                              valign="middle"
                            >
                              <td align="center" style={{ color: "#fff" }}>
                                4.
                              </td>
                              <td colSpan={2} style={{ color: "#fff" }}>
                                Fees for searching Trade &amp; Profession Demand
                                Register
                              </td>
                              <td
                                colSpan={2}
                                align="center"
                                style={{ color: "#fff" }}
                              >
                                Figures in Rs.
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">A)</td>
                              <td colSpan={2}>
                                Fees for searching Trade &amp; Profession Demand
                                Register in respect of one entry
                              </td>
                              <td colSpan={2} align="center">
                                150.00 Per Assessee
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">B)</td>
                              <td colSpan={2}>
                                Fees for certified extract in respect of one
                                entry (with searching fees, ordinary delivery)
                              </td>
                              <td colSpan={2} align="center">
                                200.00 Per Assessee
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">C)</td>
                              <td colSpan={2}>
                                Fees for certified extract in respect of one
                                entry (with searching fees, urgent delivery)
                              </td>
                              <td colSpan={2} align="center">
                                400.00 Per Assessee
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5}>&nbsp;</td>
                            </tr>
                            <tr
                              bgcolor="302ba0"
                              tabIndex={0}
                              style={{ height: 38 }}
                              valign="middle"
                            >
                              <td align="center" style={{ color: "#fff" }}>
                                5.
                              </td>
                              <td colSpan={2} style={{ color: "#fff" }}>
                                Fees for Inspection of Building Sanctioned Plan
                              </td>
                              <td
                                colSpan={2}
                                style={{ color: "#fff" }}
                                align="center"
                              >
                                Figures in Rs.
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">A)</td>
                              <td colSpan={2}>
                                Inspection by owner in respect of sanctioned
                                plan
                              </td>
                              <td colSpan={2} align="center">
                                650.00 Per Assessee
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">B)</td>
                              <td colSpan={2}>
                                Inspection by other than owner for one sanction
                                plan (permission to be obtained from the office
                                of D.G.Bldg.)
                              </td>
                              <td colSpan={2} align="center">
                                1,250.00 Per Assessee
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">C)</td>
                              <td colSpan={2}>
                                Inspection by owner in respect of sanctioned
                                plan with paper
                              </td>
                              <td colSpan={2} align="center">
                                750.00 Per Assessee
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">D)</td>
                              <td colSpan={2}>
                                Inspection by other than owner for one
                                sanctioned plan with paper
                              </td>
                              <td colSpan={2} align="center">
                                1,500.00 Per Assessee
                              </td>
                            </tr>
                            <tr align="center" valign="top" tabIndex={0}>
                              <td colSpan={5}>
                                <i>Note : </i>Rs. 100/- to be paid extra as
                                searching fee
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5}>&nbsp;</td>
                            </tr>
                            <tr
                              bgcolor="302ba0"
                              tabIndex={0}
                              style={{ height: 38 }}
                              valign="middle"
                            >
                              <td align="center" style={{ color: "#fff" }}>
                                6.
                              </td>
                              <td colSpan={2} style={{ color: "#fff" }}>
                                Form No. (Name)
                              </td>
                              <td
                                width="20%"
                                style={{ color: "#fff" }}
                                align="center"
                              >
                                Rate Per Form
                              </td>
                              <td
                                width="15%"
                                style={{ color: "#fff" }}
                                align="center"
                              >
                                Remarks
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">A)</td>
                              <td colSpan={2}>A-42 (Mutation Form)</td>
                              <td align="center">Free</td>
                              <td rowSpan={5} valign="middle">
                                Can be downloaded from Website
                              </td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">B)</td>
                              <td colSpan={2}>A-75</td>
                              <td align="center">Free</td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">C)</td>
                              <td colSpan={2}>Contractors Bill Forms</td>
                              <td align="center">Free</td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">D)</td>
                              <td colSpan={2}>
                                P-I (Petty Improvement, CIVIL)
                              </td>
                              <td align="center">Free</td>
                            </tr>
                            <tr tabIndex={0} align="left" valign="top">
                              <td align="right">E)</td>
                              <td colSpan={2}>
                                P-I (Petty Improvement, LIGHTING)
                              </td>
                              <td align="center">Free</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Central_Records_Fees