import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Guidelines_for_Safety = () => {
    const increaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
          var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
          var fontSize = parseFloat(style); 
          el[i].style.fontSize = (fontSize + 1) + 'px';
        }
      };
      const decreaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
        var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
        var fontSize = parseFloat(style); 
        el[i].style.fontSize = (fontSize - 1) + 'px'; 
        }
      };
      const { t, i18n, ready } = useTranslation();

  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">           
            <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Disaster_Mgmt'> {t("Disaster Mgmt")} </Link> </li>
                  <li className="nav-item"><Link class="nav-link" to='/Concept_Note' target="_blank"> {t("Concept Note")}</Link> </li>  
                  <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Guidelines_for_Safety'> {t("Guidelines for Safety")} </Link> </li>  
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Form_For_Inventory'> {t("Form For Inventory")} </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/School_Safety_Booklet'> {t("School Safety Booklet")} </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Colouring_Activity_Book'> {t("Colouring Activity Book")}</Link> </li>  
    
                </ul>
              </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="">
              <div className="inner-title"><h3 className="fontSizeClass"> {t("Guidelines For School Safety In Kolkata City")} </h3>
                <div className="about-head1">
                    <h4 className="fontSizeClass">{t("DISASTER PREPAREDNESS AND MITIGATION ACTIVITIES IN A SCHOOL")}</h4>
                </div>
              </div>
                  <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="heading1">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                    {t("STEP-WISE ACTIVITIES FOR A SCHOOL ARE")} :-
                    </button>
                    </div>
                    <div id="collapse1" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p>{t("(These activities are simple and can be done without hampering the normal functioning of the school)")}<br />
                            {t("Sensitisation meeting for Teachers, Management, Staff and Students of the School on probable natural and human induced disaster threats in the area and measures required for minimizing losses")}.<br />
                            {t("Formation of Disaster Management Committee in the school")}.<br />
                            {t("It may be resolved to enable the School Development Committee to act as School Disaster Management Committee")}.<br />
                            {t("Formation of School Disaster Management Teams comprising of senior students, teachers and non-teaching staff")}.</p>
                          </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading2">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                      {t("At least 5 teams will be formed for different purposes as below and each team should have around 15 members")} :-
                    </button>
                    </div>
                    <div id="collapse2" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p>a) {t("Awareness Generation, Warning, Information Collection and Information Dissemination Team")}<br />
                            b) {t("Emergency Evacuation and School Security Team")}<br />
                            c) {t("Search & Rescue Team")}<br />
                            d) {t("Fire Prevention and Control Team")}<br />
                            a) {t("First aid Team")}</p>
                      </div>                      
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading3">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                      {t("Hazard Identification & Safety Assessment")}
                    </button>
                    </div>
                    <div id="collapse3" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p>{t("Preparation of a CHECK LIST on the basis of which the present safety of the school can be examined and actions can be planned in the future to make the school a safer place")}.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                      {t("MINIMUM POINTS OF THE CHECK LIST")} :-
                    </button>
                    </div>
                    <div id="collapse4" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p>1. {t("The natural/human induced disasters (earthquake, cyclone, flood etc.) to which the school is vulnerable ? (Include Probable Sources of Hazards e.g. weak building/structure, adjacent tall building, electric transformer, factory /godown / fuel filling station / shop keeping inflammable articles etc.etc.)")}<br />
                            2. {t("Does every floor of the school have minimum two exits ? Are the electrical mains fitted away from the exits")} ?<br />
                            3. {t("Are all exits from the school clear")} ?<br />
                            4. {t("Are the corridors and staircases clear of any obstruction")} ?<br />
                            5. {t("Do the doors of all rooms open outwards")} ?<br />
                            6. {t("Are the Rooftop Water Reservoir and the Flower Vases properly anchored to the building with rigid frames")} ?<br />
                            7. {t("Does the school have provision for emergency power back-up")} ?<br />
                            8. {t("Is emergency stock of fresh drinking water always kept stored in a separate place")} ?<br />
                            9. {t("Are the fans and lights properly fixed to the ceiling/walls")} ?<br />
                            10. {t("Are the wall-hung items (portraits// photographs etc.) properly fixed")} ?<br />
                            11. {t("Are the Almirahs/racks/furniture properly fixed with the building")} ?<br />
                            12. {t("Is there adequate arrangement for fire safety")} ?<br />
                            13. {t("Is the school building(s) structurally safe to withstand the shock of probable earthquake / super-cyclone in this region")} ?<br />
                            14. {t("Is the ground floor sufficiently raised to prevent inundation by usual flood water")} ?<br />
                            15. {t("Is the School Disaster Management Teams have acquired enough skill by practice (Mock drill)")} ?<br />
                            16. {t("Is the School Disaster Management Plan prepared and kept updated")} ?</p>
                    </div>
                  </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading5">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                      {t("Making Inventory of resources which may help in crisis management (immediate response,emergency relief, emergency shelter etc.)")}.
                    </button>
                    </div>
                    <div id="collapse5" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p>{t("A format is given for the purpose. The names, addresses, land/mobile phone numbers must be given for all")}.</p>
                      </div>
                      </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading6">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse" aria-expanded="true" aria-controls="collapse6">
                      {t("The plan must comprise of al least")}
                    </button>
                    </div>
                    <div id="collapse6" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <strong>A) {t("A Location Plan showing")}</strong>
                        <p>{t("Nearby buildings, fields, roads/lanes, railway stations, vehicle garage, water bodies etc")}.<br />
                            {t("Probable sources of hazards e.g. weak building/structure, electric transformer, factory /godown / fuel filling station / shop keeping inflammable articles etc.etc.")}<br />
                            {t("Probable resources which may help in crisis management (immediate response, emergency relief, emergency shelter etc.)")}.</p>
                            <strong>B) {t("An Emergency Evacuation Plan for each floor of each building showing escape / rescue routes")}.</strong>
                            <strong>C){t("The Hazard Identification & Safety Assessment mentioned in sl. no. 04 above")}.</strong>
                            <strong>D){t("The Inventory of Resources mentioned in sl. no. 05 above")}.</strong>
                            <strong>E){t("General Inventory of the school as given in the format (to include the Details of school timings, no. of students/teachers/boarders, type of structure & foundation, no. of floors, no. & uses of rooms in each floor, no. of Laboratories etc. etc.)")}</strong>
                        <p>{t("Display Floor wise evacuation route maps on every floor in the school. Put up signs for exits on walls and roof etc")}<br />
                            {t("Dissemination of School DM Plan to everybody in the School (also to the guardians). Call a meeting and discuss the plan with all staff members")}.<br />
                            {t("Mock drill at regular intervals to acquire skill and keep practice")}.<br />
                            {t("Adoption of Structural and Non-structural Mitigation measures (takes comparatively long time) for the school building(s)")}<br />
                            {t("Lessons from the past disasters")}<br />
                            {t("Structural Hazards (School Building / Water tank / Windows / Electrical / Gas Installations / Basements etc.)")}<br />
                            {t("Room Hazards (Heavy loose objects / hanging objects / Chemicals in laboratories / Aquarium etc.)")}<br />
                            {t("Maintenance Hazards (un-skilled maintenance of equipment / articles etc.)")}<br />
                            {t("Neighborhood Hazards (Gas stations)/ high-voltage power lines/ major traffic routes (e.g., freeways and railway lines) / major oil and natural gas pipeline or storage / electrical transformers and underground utility vaults / multi-storey buildings vulnerable to collapse / water towers )")}<br />
                            {t("Retrofit (strengthen) the weak buildings")}<br />
                            {t("Construct new buildings adopting disaster resistant technologies with strict adherence to the quality standards and advices of a knowledgeable experienced structural engineer")}</p>
                      </div>
                      </div>
                  </div>  
                  <div className="card">
                    <div className="card-header" id="heading7">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                      {t("Expected Outcomes")}</button>
                    </div>
                    <div id="collapse7" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p>{t("Sustainable Reduction of Disaster Risks by an aware, trained and prepared capable to cope with any disaster situation efficiently")}.</p>
                        <div className="about-head1">
                            <h6 className="fontSizeClass">{t("PHOTOGRAPHS OF A FEW PAST EVENTS")} :</h6>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <img src="assets/img/1.jpg" className="img-fluid" alt="alt-image" />
                                </div>
                                <div className='col-lg-6'>
                                    <img src="assets/img/2.jpg" className="img-fluid" alt="alt-image" />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <img src="assets/img/3.jpg" className="img-fluid" alt="alt-image" />
                                </div>
                                <div className='col-lg-6'>
                                    <img src="assets/img/4.jpg" className="img-fluid" alt="alt-image" />
                                </div>
                            </div>
                        </div>
                      </div>
                      </div>
                  </div>                
                  </div>
                  <div className='text-justify'>
                  <strong >{t("Training and capacity building of School Disaster Management Teams in respective subjects e.g.Warning & Information, Emergency Evacuation, Search and Rescue, Fire Fighting, basic First Aid etc")}.</strong><br />
                  <strong >{t("Preparation of Disaster Management Plan for the School showing coordination with all emergency services (e.g. Municipal Corporation, Fire, Civil Defence, Police, Health, Local NGOs/CBOs etc.) and local people")}.</strong>
                  </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
    {/*   */}
  </section>
  
    </>
  )
}

export default Guidelines_for_Safety