import React from 'react'
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

const AboutUs = () => {useEffect(() => {window.scrollTo(0, 0)}, [])
const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
  var el = document.getElementsByClassName('fontSizeClass');
  for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize + 1) + 'px';
  }
};
const decreaseFontSizeBy1px =() => {
  var el = document.getElementsByClassName('fontSizeClass');
  for (let i = 0; i < el.length; i++) {
  var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
  var fontSize = parseFloat(style); 
  el[i].style.fontSize = (fontSize - 1) + 'px'; 
  }
};
const [FinancialServices, setFinancialServices] = React.useState(false);
const [FinancialOneServices, setFinancialOneServices] = React.useState(false);
const [FinancialTwoServices, setFinancialTwoServices] = React.useState(false);
const [FinancialThreeServices, setFinancialThreeServices] = React.useState(false);
const [FinancialFourServices, setFinancialFourServices] = React.useState(false);
const [FinancialFiveServices, setFinancialFiveServices] = React.useState(false);
const [FinancialSixServices, setFinancialSixServices] = React.useState(false);
const [FinancialSevenServices, setFinancialSevenServices] = React.useState(false);
const { t, i18n, ready } = useTranslation();

const FinancialServicesToggle = () => {
  setFinancialServices(!FinancialServices);
};
const FinancialOneServicesToggle = () => {
  setFinancialOneServices(!FinancialOneServices);
};
const FinancialTwoServicesToggle = () => {
  setFinancialTwoServices(!FinancialTwoServices);
};
const FinancialThreeServicesToggle = () => {
  setFinancialThreeServices(!FinancialThreeServices);
};
const FinancialFourServicesToggle = () => {
  setFinancialFourServices(!FinancialFourServices);
};
const FinancialFiveServicesToggle = () => {
  setFinancialFiveServices(!FinancialFiveServices);
};
const FinancialSixServicesToggle = () => {
  setFinancialSixServices(!FinancialSixServices);
};
const FinancialSevenServicesToggle = () => {
  setFinancialSevenServices(!FinancialSevenServices);
};
  return (
    <>
      {/* Slider */}
      <section id="hero" className="hero hero2">
        <div
          className="social-media wow slideInDown"
          style={{ visibility: "visible", animationName: "slideInDown" }}
        >
          <div className="facebook" rel="ebInfo" title="facebook">
            <a
              href="https://www.facebook.com/KolkataMunicipalCorporation.official"
              target="_blank"
            >
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a
              href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp"
              target="_blank"
            >
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>

        <div className="carousel-item active">
          <img
            src="assets/img/inner-bg2.png"
            className="img-fluid"
            alt="image"
          />
          {/* <div className="container">
        <div className="section-main-heading2">
          <h4 className="fontSizeClass"> About Us - Vision </h4></div>
      </div> */}
        </div>
      </section>

      <section className="section inner-pad1">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="site-menu  min-height-400">
                <nav className="sidebar card py-2">
                  <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item">
                      <Link
                        className="nav-link active1 fontSizeClass"
                        to="/AboutUs"
                      >
                        {t("Vision")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Overview">
                        {t("Overview")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1849")}
                      >
                        {t("KMC Structure")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/KMCFunctions"
                      >
                        {t("KMC Functions")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/KMC_Department"
                      >
                        {t("KMC Department")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/EKolkataCenter"
                      >
                        {t("e-Kolkata Centers")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Basic_statestics"
                      >
                        {t("Basic Statistics")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Emblem">
                        {t("Emblem")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/KMCBudget">
                        {t("KMC Budget")}
                      </Link>
                    </li>
                    <li className="nav-item-list nav_dropdown">
                      <button
                        type="button"
                        className="btn"
                        data-toggle="collapse"
                        data-target="#demo"
                      >
                        KMC Financial Statements{" "}
                        <i className="bi bi-chevron-down" />
                      </button>
                      <div id="demo" className="collapse">
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo1"
                          >
                            2012- 2013
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo1" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1850")}>
                                {t("Receipt and Payment Statement 2012-13")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1851")}>
                                {t("Income & Expenditure 2012-13")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1852")}>
                                {t("Balance Sheet 2012-13")}
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo2"
                          >
                            2013- 2014
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo2" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1853")}>
                                {t("Annual Financial Statement 2013-14")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1854")}>
                                {t("Audit Certificate for ELA 2013-14")}
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo3"
                          >
                            2014- 2015
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo3" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1855")}>
                                {t("Receipt and Payment Statement 2014-15")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1858")}>
                                {t("Income & Expenditure 2014-15")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1857")}>
                                {t("Balance Sheet 2014-15")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1856")}>
                                {t("Audit Report 2014-15")}
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo4"
                          >
                            2015- 2016
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo4" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1859")}>
                                {t("Receipt and Payment Statement 2015-16")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1860")}>
                                {t("Income & Expenditure 2015-16")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1861")}>
                                {t("Balance Sheet 2015-16")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1862")}>
                                {t("Audit Report 2015-16")}
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo5"
                          >
                            2016- 2017
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo5" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1863")}>
                                {t("Receipt and Payment Statement")}{" "}
                                {t("2016-17")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1864")}>
                                {t("Income & Expenditure")} {t("2016-17")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1865")}>
                                {t("Balance Sheet 2016-17")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1866")}>
                                {t("Audit Report 2016-12")}
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo6"
                          >
                            2017-2018
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo6" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1867")}>
                                {t("Receipt and Payment Statement")} 2017-18
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1868")}>
                                {t("Income & Expenditure")} 2017-18
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a> Balance Sheet 2017-18 </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1869")}>
                                {t(
                                  "Letter of MC to Examiner of Local Accounts, WB"
                                )}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1870")}>
                                {t("Audit Report")} 2017-18
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo7"
                          >
                            2018-2019
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo7" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1871")}>
                                {t("Receipt and Payment Statement 2018-19")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1872")}>
                                {t("Income & Expenditure 2018-19")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1873")}>
                                {t("Balance Sheet 2018-19")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1874")}>
                                {t("Audit Report 2018-19")}
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo7"
                          >
                            2019-2020
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo8" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1875")}>
                                {t("Receipt and Payment Statement 2019-20")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1876")}>
                                {t("Income & Expenditure 2019-20")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1877")}>
                                {t("Balance Sheet 2019-20")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1878")}>
                                {t("Audit Report 2019-20")}
                              </a>
                            </li>
                          </div>
                        </li>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="container">
                <div className="row">
                  <div className="inner-title">
                    <h3 className="fontSizeClass">{t("Vision")} </h3>
                  </div>
                  <div className="about-head1">
                    <h4 className="fontSizeClass">
                      "
                      {t(
                        "KMC will be efficient, effective, equitable, citizen responsive, financially sustainable and transparent, delivering quality service to its citizens"
                      )}
                      ."
                    </h4>
                  </div>
                  <div className="col-lg-12 about-img2">
                    <img
                      src="assets/img/vision.jpg"
                      className="img-fluid"
                      alt="alt-image"
                    />
                    <p className="fontSizeClass">
                      {t(
                        "We have a vision. We believe that Kolkata has the potential to be one of the best cities of the world. We see Kolkata as a clean and attractive city of international repute in which all people have access to quality services"
                      )}
                      .
                    </p>
                  </div>
                  <div className="col-lg-12 about-head1">
                    <h5 className="fontSizeClass">
                      {" "}
                      {t("In our vision, KMC will be")} :-{" "}
                    </h5>
                    <p className="fontSizeClass">
                      <strong> {t("Efficient and effective")} - </strong>{" "}
                      {t(
                        "providing service to its customers in a professional, economical, timely, useful and helpful manner"
                      )}
                      .
                    </p>
                    <p className="fontSizeClass">
                      <strong> {t("Equitable")} -</strong>{" "}
                      {t(
                        "assuring service to all its citizens including the vulnerable and deprived groups in a fair, just and reasonable manner"
                      )}
                      .
                    </p>
                    <p className="fontSizeClass">
                      <strong> {t("Citizen responsive")} -</strong>{" "}
                      {t(
                        "developing a sense of involvement and participation in all its stakeholders by pro-actively addressing their concerns and providing complete customer satisfaction"
                      )}
                      .
                    </p>
                    <p className="fontSizeClass">
                      <strong> {t("Financially sustainable")} - </strong>{" "}
                      {t(
                        "reducing KMC’s dependence on state funds by optimising and efficiently managing its revenues, and"
                      )}
                    </p>
                    <p className="fontSizeClass">
                      <strong> {t("Transparent")} - </strong>{" "}
                      {t(
                        "providing an accountable and transparent civic administration for the benefit of external users as well as internal employees"
                      )}
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs