import React from 'react'
import { Link } from "react-router-dom";

const Other_Noteable_place = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const [ProjectService, setProjectService] = React.useState(false);

  const ProjectServiceToggle = () => {
    setProjectService(!ProjectService);
  };
  return (
    <>
    {/* Slider */}
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"> <h4 className="fontSizeClass">  Art Galleries / Museums / Libraries </h4></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
  
  <section className="section inner-pad1">
    <div className="container" >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  ">           
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/About_Kolkata'> About Kolkata </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/map'> Map </Link></li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/History_Of_Kolkata'> History</Link></li>
                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo6">Places of Interest<i className="bi bi-chevron-down" /></button>
                    <div id="demo6" className="collapse hide"> 
                      <Link className="nav-link fontSizeClass" to='/Art_Galleries'> Art Galleries</Link>
                      <Link className="nav-link fontSizeClass" to='/Amusement_Parks'> Amusement Park </Link>
                      <Link className="nav-link fontSizeClass" to='/Architecture'> Architecture </Link>
                      <Link className="nav-link fontSizeClass active1" to='/Other_Noteable_place'> Other Notable Places </Link>
                    </div>
                </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Basic_statestics'> Basic Statistics </Link> </li>  
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/EncumbranceCertificate.jsp'> UEVRP </a></li>   
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
          <div className="row">		  
            <div className="inner-title"><h3 className="fontSizeClass">  Art Galleries / Museums / Libraries </h3></div>		   		   
            <div className="col-lg-12 about-head1">
              <p className="fontSizeClass">
                Life never stops in Kolkata. At any time of the year there is something going on - a festival, 
                a wedding, a match, a demonstration, a concert, a vernissage or a party. For some, 
                Calcutta - Kolkata is the city of joy, while for others it is dirty, crowded, and noisy.
              </p>
            </div>
            <div className="col-lg-6 col-md-6">
              <section id="faq" className="faq">
                <div className="" >
                  <div className="row gy-4">
                    <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                      <div className="accordion accordion-flush" id="faqlist">
                        <div className='mt-2'>
                          <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq1">Bangiya Sahitya Parishad  <i className="bi bi-chevron-down float-right" /></button>
                          <div id="faq1" class="collapse text-justify">
                          <p className="fontSizeClass">Bangiya Sahitya Parishad, situated near Maniktala, is an unique library that preserves ancient manuscripts of Bengali writings. It is open from 1 pm to 7 pm and is closed on thursday.</p>
                          <p className="fontSizeClass"> Address: 243/1 AP.C.Road. Kolkata -6. <br />Ph.: 2350 3743</p>
                          </div>
                        </div>
                        <div className='mt-2'>
                          <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq2">Birla Planetorium <i className="bi bi-chevron-down float-right" /></button>
                          <div id="faq2" class="collapse text-justify mt-2">
                              <p className="fontSizeClass"> A spectacular asset for modern Kolkata. Regular astonomical shows are presented here with commentries in differt languages. This is an architectural marvel. The spherical one-storeyed Planetarium was tremendously influenced by the Buddhist architectural remnants at Sanchi. Its circumference is 82 ft. This wonderful house exhibits the universe and the solar system with all their enigmas. The planetarium has a capacity of 500 spectators.</p>
                              <p className="fontSizeClass"> Address: 96, Jawaharlal Nehru Road. Kolkata -71. <br /> Ph.: 2223 1516 / 6610</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="col-lg-6 col-md-6">
              <section id="faq" className="faq">
                <div className="" >
                  <div className="row gy-4">
                    <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                      <div className="accordion accordion-flush" id="faqlist">	 
                        <div className='mt-2'>
                          <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq3">Kolkata University <i className="bi bi-chevron-down float-right" /></button>
                          <div id="faq3" class="collapse text-justify">
                              <p className="fontSizeClass"> The University of Kolkata was founded on January 24, 1857. The University adopted in the first instance, the pattern of the University of London and gradually introduced modifications in its constitution . The University is now being governed by the Kolkata University Act, 1979. The Act provides for the reconstitution of the University to enable it to function more efficiently in the fields of teaching, training and research in various branches of learning and courses of study and extending higher education to meet the growing needs of the Society. Under the Kolkata University Act 1979, the Vice-Chancellor is the principal executive and academic officer of the University. </p>
                              <p className="fontSizeClass"> Location: College Street. <br />Ph: 2241 0071 -78 / 4984 - 90 </p>
                          </div>
                        </div>	{/* # Faq item*/}
                        <div className='mt-2'>
                          <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq4">Fort William <i className="bi bi-chevron-down float-right" /></button>
                          <div id="faq4" class="collapse text-justify">
                              <p className="fontSizeClass"> Named after King William III of England, it is the headquarter of Eastern Army Command. The Fort William stands solemnly on the Western bank of The Ganga. It stretches from the Western flank of the Red Road. Inside the Fort is the St. Peter's Church which was constructed in 1928. Swimming pool, Boxing stadium, Firing Range and other necessary arrangements are present within the Fort. There is also a provision for ten thousand interns of the Fort. The Fort has huge archways, one of which is known as 'Plassey Gate'. It is said that this is the only Fort in the world from where not a single bullet or cannon-ball has ever been shot.</p>
                              <p className="fontSizeClass"> Location: Red Road. </p>
                          </div>
                        </div>	{/* # Faq item*/}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
       </div>  
      </div>

     <div className='col-lg-12'>
      <div className="container" >
       <div className="row"> 
        <div className="col-lg-4 col-md-6">
            <section id="faq" className="faq">
              <div className="" >
                <div className="row gy-4">
                  <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                    <div className="accordion accordion-flush" id="faqlist">
                          <div className='mt-2'>
                            <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq5">Jadavpur University <i className="bi bi-chevron-down float-right" /></button>
                            <div id="faq5" class="collapse text-justify">
                            <p className="fontSizeClass"> The university has an international repute, having several modern subjects with science and arts stream. It was established at Jadavpur in post-Independence time for strengthing the modern education system. </p> <p className="fontSizeClass">Ph.: 2473 0399/ 4044 / 4495</p>
                            </div>
                          </div>	{/* # Faq item*/}
                          <div className='mt-2'>
                            <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq6">Loreto House <i className="bi bi-chevron-down float-right" /></button>
                            <div id="faq6" class="collapse text-justify">
                            <p className="fontSizeClass">The ambience of the house where Vansitart, Governor of Bengal, used to live, but was changed overnight when a group of young Irish Loret nuns landed at Chandpal Ghat in 1841. The house was turned for their residence and was named as Loreto House. </p> <p className="fontSizeClass">Location: Park Street</p>
                            </div>
                          </div>	{/* # Faq item*/}
                          <div className='mt-2'>
                            <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq7">Raj Bhawan <i className="bi bi-chevron-down float-right" /></button>
                            <div id="faq7" class="collapse text-justify">
                            <p className="fontSizeClass"> It is the Government house and was built in pre-Independence times, 1803. It is the residence of the Governor of West Bengal.</p>
                            <p className="fontSizeClass">Location : B.B.D.Bag <br /> Ph. : 2220 1641</p>                          </div>
                          </div>	{/* # Faq item*/}
                          <div className='mt-2'>
                            <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq8">Bangla Academy <i className="bi bi-chevron-down float-right" /></button>
                            <div id="faq8" class="collapse text-justify">
                            <p className="fontSizeClass"> An academy of Bengali Literature with a very dependable library and also a seminar hall. </p>
                            <p className="fontSizeClass">Address: 1/1 A.J.C.Bose Road. Kolkata-20. <br /> Ph.: 2223 9978 / 0956 / 1985</p>                          </div>
                          </div>	{/* # Faq item*/}
                          <div className='mt-2'>
                            <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq9">Bose Institute <i className="bi bi-chevron-down float-right" /></button>
                            <div id="faq9" class="collapse text-justify">
                            <p className="fontSizeClass"> A leading organisation of the nation, with an intention for the advancement of science and diffusion of knowledge. The Institute is in the service of the nation for the last 75 years through its pursuit of advancement of knowledge in science and technology and by producing efficient and skilled scientific manpower that the country needs for its development. .This institute was establishes by Late Sir Jagadish Chandra Bose, in the year 1917. </p>
                            <p className="fontSizeClass">Address : 93/1 A. P. C. ROAD, Kolkata. <br /> Phone : 2334 2403 / 02</p>                          </div>
                          </div>	{/* # Faq item*/}
                          
                    </div>
                  </div>
                </div>
              </div>
            </section>
        </div>
        <div className="col-lg-4 col-md-6">
            <section id="faq" className="faq">
              <div className="" >
                <div className="row gy-4">
                  <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                    <div className="accordion accordion-flush" id="faqlist">
                          <div className='mt-2'>
                            <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq10">Hastings House <i className="bi bi-chevron-down float-right" /></button>
                            <div id="faq10" class="collapse text-justify">
                            <p className="fontSizeClass"> Currently it is the Women's Teachers Training College. </p> <p className="fontSizeClass">Address:</p>
                          </div>
                          </div>	{/* # Faq item*/}
                          <div className='mt-2'>
                            <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq11">Kumartuli<i className="bi bi-chevron-down float-right" /></button>
                            <div id="faq11" class="collapse text-justify">
                            <p className="fontSizeClass"> Kumartuli, a warren of lanes is home to a community of artisans &amp; potters who specialize in making the clay, straw and pith images of deities used for the major festivals. In the days leading up to the great pujas, especially that of Durga, Kumartuli is a fascinating hive of activity. </p>
                            <p className="fontSizeClass"> Location: Shovabazar Street. </p>
                          </div>
                          </div>	{/* # Faq item*/}
                          <div className='mt-2'>
                            <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq21">Metro Railway<i className="bi bi-chevron-down float-right" /></button>
                            <div id="faq21" class="collapse text-justify">
                            <p className="fontSizeClass"> IThe Metro Railway is a jewel in the crown of Kolkata. India's first underground tube railway, the Kolkata Metro, connects the south to the north viz., Tollygunge to Dumdum. It covers a distance of 25 kms (approx) and has 23 stations. The whole journey takes 50 minutes. With its clean surroundings, prompt &amp; consistent service, it offers a comfortable journey to the harassed office goers sick and tired of traffic jams.</p>						
                            <p className="fontSizeClass"> Ph.: 2556 5069 / 2249 3959 </p>
                          </div>
                          </div>	{/* # Faq item*/}
                          <div className='mt-2'>
                            <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq12">Vidyasagar Setu<i className="bi bi-chevron-down float-right" /></button>
                            <div id="faq12" class="collapse text-justify">
                            <p className="fontSizeClass"> It is a modern day engineering marvel and is the largest cable stayed bridge in Asia. It is named after one of India's great sons - Ishwar Chandra Vidyasagar. This new bridge over river Hooghly connects Ravindra Sadan with Howarh. It is also known as the second Hooghly Bridge. </p>						
                          </div>
                          </div>	{/* # Faq item*/}
                          <div className='mt-2'>
                            <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq13">B.B.D. Bag, Dalhousie<i className="bi bi-chevron-down float-right" /></button>
                            <div id="faq13" class="collapse text-justify">
                            <p className="fontSizeClass"> Dalhousie Square, the centre of activity of the British Administration in India, is renamed after three brave martyrs of Bengal viz., Binay, Badal &amp; Dinesh. It is a square area built around the old Lal Dighi tank which exists till date. It was later called the Tank Square and then the Dalhousie Square. It is still the 'heart' of Kolkata. Many famous buildings housing important offices, business centres and banks are located here. </p>
                            <p className="fontSizeClass"> On the southern side of the square is the Raj Bhavan or the Governor House, built in Georgian style of architecture. Close by, is the Assembly House, a unique specimen of Gothic architecture and St. John's Church which happens to be the oldest Church in Calcutta. Herein lies the mausoleum of Job Charnock, the founder of the city. In the north lies the Writer's Building, a grandiose Gothic architecture which houses the secretariat. </p>                         </div>
                          </div>	{/* # Faq item*/}
                    </div>
                  </div>
                </div>
              </div>
            </section>
        </div>
        <div className="col-lg-4 col-md-6">
            <section id="faq" className="faq">
              <div className="" >
                <div className="row gy-4">
                  <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                    <div className="accordion accordion-flush" id="faqlist">
                          <div className='mt-2'>
                            <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq18">Kolkata Information Centre<i className="bi bi-chevron-down float-right" /></button>
                            <div id="faq18" class="collapse text-justify">
                            <p className="fontSizeClass"> A centre for several types of information regarding Kolkata &amp; West Bengal can be obtained from here. It is also a cultural centre with an auditorium - Sisir Mancha , and an exhibition hall -Gaganendra Pradarshanshala.</p>
                            <p className="fontSizeClass"> Address: 1/1 A.J.C. Bose Road. Kolkata - 20. <br /> Ph: 2223 2451 / 4214 </p>
                            </div>
                          </div>	{/* # Faq item*/}
                          <div className='mt-2'>
                            <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq19">Kolkata University<i className="bi bi-chevron-down float-right" /></button>
                            <div id="faq19" class="collapse text-justify">
                            <p className="fontSizeClass"> The University of Kolkata was founded on January 24, 1857. The University adopted in the first instance, the pattern of the University of London and gradually introduced modifications in its constitution . The University is now being governed by the Kolkata University Act, 1979. The Act provides for the reconstitution of the University to enable it to function more efficiently in the fields of teaching, training and research in various branches of learning and courses of study and extending higher education to meet the growing needs of the Society. Under the Kolkata University Act 1979, the Vice-Chancellor is the principal executive and academic officer of the University.</p>
                            <p className="fontSizeClass">Location: College Street. <br /> Ph: 2241 0071 -78 / 4984 - 90</p>
                            </div>
                          </div>	{/* # Faq item*/}
                          <div className='mt-2'>
                            <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq14">Writers' Building<i className="bi bi-chevron-down float-right" /></button>
                            <div id="faq14" class="collapse text-justify">
                            <p className="fontSizeClass"> Presently the seat of State Secretariate. It was built during the days of the East India Company to provide shelter to the junior writers or clerks of the company. The original building was constructed in 1770 on the same site as the present one, but it was plain stuccoed. The present Gothic structure was built during the tenure of Lt. Governor Ashley Eden (1877 - 1882), is much more imposing than the original ever was. Situated at the northern end of Dalhousie Square, it now houses the Secretariat of West Bengal Government. </p>
                            <p className="fontSizeClass"> Location : B.B.D.Bag <br /> Ph.: 2235 5601 / 3371 </p>
                            </div>
                          </div>	{/* # Faq item*/}
                          <div className='mt-2'>
                            <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq15">Rabindra Setu<i className="bi bi-chevron-down float-right" /></button>
                            <div id="faq15" class="collapse text-justify">
                            <p className="fontSizeClass"> This is popularly known by all and sundry as the 'Howrah Bridge'. The Howrah Bridge is a sort of 'Cantilever bridge'. In it cantilevers project from piers and are connected by girders. The Howrah Bridge or Rabindra Setu can also be regarded as the 'Gateway of Kolkata'. </p>
                            </div>
                          </div>	{/* # Faq item*/}
                          <div className='mt-2'>
                            <button type="button" class="btn btn-light" data-toggle="collapse" data-target="#faq16">Legislative Assembly Building<i className="bi bi-chevron-down float-right" /></button>
                            <div id="faq16" class="collapse text-justify">
                            <p className="fontSizeClass"> Near the High Court is the Legislative Assembly Building where the elected representatives of the State meet.</p>
                            <p className="fontSizeClass"> Ph.: 2248 6211 </p>                          </div>
                          </div>	{/* # Faq item*/}
                    </div>
                  </div>
                </div>
              </div>
            </section>
        </div>
       </div>
       </div>   
      </div>

      </div>
    </div>
  </section>
    </>
  )
}

export default Other_Noteable_place