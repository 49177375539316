import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Fucntions = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
<>
  {/* Slider */}
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        {" "}
        <div className="section-main-heading2">
          {" "}
          <h4 className="fontSizeClass"> {t("Functions")} </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Overview'>                    
                    {t("Overview")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Power'>                    
                    {t("Power")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Functions'>                    
                    {t("Functions")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Revenue'>                    
                    {t("Revenue")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Adminstration'>                    
                    {t("Administration")}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">           
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass"> {t("Functions")}</h3>
              </div>
              <div className="col-lg-12 about-head1">
                <p className="fontSizeClass">
                  {t("Obligatory functions are as detailed in Sec. 29 of the KMC Act, 1980. And discretionary functions are as detailed in Sec.30 of the KMC Act, 1980. Besides these, exercising of general functions are subject to KMC Act, Rules and Regulations")}.
                </p>
                <div className="funct1">
                  <h5 className="fontSizeClass">  {t("Water Works & Water Supply")} </h5>
                  <h5 className="fontSizeClass">  {t("Drainage & Sewerage")} </h5>
                  <h5 className="fontSizeClass">  {t("Street Lighting & Electricals")} </h5>
                  <h5 className="fontSizeClass">  {t("Roads & Pathways")} </h5>
                  <h5 className="fontSizeClass">  {t("Parks & Gardens")} </h5>
                  <h5 className="fontSizeClass">  {t("Land Use Control & Building")} </h5>
                  <h5 className="fontSizeClass">  {t("Solid Waste Management")} </h5>
                  <h5 className="fontSizeClass">  {t("Preventive & Curative Health Care")} </h5>
                  <h5 className="fontSizeClass">  {t("Town Planning & Architecture")} </h5>
                  <h5 className="fontSizeClass">  {t("Survey & Record")} </h5>
                  <h5 className="fontSizeClass">  {t("Municipal Markets")} </h5>
                  <h5 className="fontSizeClass">  {t("Improvement of Slums (Bustees)")} </h5>
                  <h5 className="fontSizeClass">  {t("Primary Education")} </h5>
                  <h5 className="fontSizeClass">  {t("Information & Public Relations")}</h5>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </section>
</>

    </>
  )
}

export default Fucntions