import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Amusement_download_forms = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const [onlinePayment, setonlinePayment] = React.useState(false);
  const onlinePaymentToggle = () => {
    setonlinePayment(!onlinePayment);
  };
  const { t, i18n, ready } = useTranslation();
  return (
   <>
     {/* Slider */}
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"> </div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
  
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">           
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Amusement'> {t("Amusement Home")} </Link> </li>
                <li className="nav-item"><a class="nav-link" onClick={(e)=>fileDownload('50')}> {t("Amusement Fees")} </a> </li>                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Amusement_download_forms'> {t("Download Forms")} </Link> </li>  
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AmusementDemandsView.jsp' > {t("Check Demands Payable")} </a> </li>
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AmusementDemandsView.jsp'> {t("Print Unpaid Bill")}</a> </li>  
                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Make Online Payment")} <i className="bi bi-chevron-down" /></button>
                    <div id="demo" className="collapse">                        
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/KMCPortalAmusementPaymentAction.do'>{t("Amusement Fees (Renew/Casual/New)")} </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'>{t("Casual Demand Generation and payment")}</a>   
                    </div>
                </li>
                			
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AmusementDuplicateReceipt.jsp'> {t("Reprint e-Receipt")}</a> </li>
                {/* <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Citizenlogin'> Reprint Puja Application e-Receipt </Link> </li>	 */}
                <li className="nav-item"><a class="nav-link" onClick={(e)=>fileDownload('154')}> {t("Notice")} </a> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Amusement_Dept'> {t("Contact Details")} </Link> </li>					
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="dig-login1 dig-form min-hg">
            <form>
              <div className="log-form">
                <div className="container">
                  <div className="row">
                    <h3 className="fontSizeClass">{t("Download Forms")}</h3>
                    <p className="subtitle">{t("Forms List")}</p>
                    <div className="col-xl-12 col-md-12 mb-12 form-group pt-2 frms"> 
                      <div className="list-of-forms">
                        <h6 className="fontSizeClass"><a className="fontSizeClass" onClick={(e)=>fileDownload('288')}> 1. {t("Form for Permission under Section 422 of the C.M.C. Act,1980s")} </a></h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

   </>
  )
}

export default Amusement_download_forms