import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const HeritageBuildingHome3  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container" >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
              <li className="nav-item">
                <Link className="nav-link active1 fontSizeClass" to='/HeritageBuildingHome'> {t("Heritage Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Exemption_of_Tax'> {t("Exemption of Tax")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Heritage_Buildings'> {t("Heritage Buildings")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Conservation_Architect'> {t("Conservation Architect")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('473')}>{t("Composition of the Heritage Conservation Committee")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Proceedings_of_HCC'>
                    
                    {t("Proceedings of HCC")}
                  </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('474')}>{t("Action Plan")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('475')}>{t("Parking Policy")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('476')}>{t("Measures for prevention of landfill fire")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWisePond.jsp'>  {t("Ward wise Pond")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWiseHeritageBuilding.jsp'> {t("Ward Wise Heritage Building")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Environment_Heritage_Dept'> {t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Activities_NCAP.pdf' target={'_blank'}> {t("Activities Permitted under NCAP")} </a>
                </li>

                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Swachh Vayu Survekshan")} <i className="bi bi-chevron-down" /></button>
                    <div id="demo2" className="collapse">
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessed_CPCB_scores_23_09_2023.xlsx'>1. {t("Self assessed scores & CPCB scores")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessment_reports_Kolkatacity_23_09_2023.pdf'>2. {t("Self assessment reports of Kolkata city2023")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Swachh_Vayu_Survekshan_23_09_2023.pdf'>3. {t("Swachh Vayu Survekshan 2023 Rank")} </a>
                    </div>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/airquality/citizenreport.do' target={'_blank'}> {t("Air quality status")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/emis/category.do' target={'_blank'}> {t(" Industry categorisation")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/District_Environment_ KMC2021_23_09_2023.pdf' target={'_blank'}> {t("FINAL District Environment plan KMC 2021")} </a>
                </li>
                
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2" >
                <h3 className="fontSizeClass">{t("Heritage Buildings of Kolkata")} </h3>
              </div>
              <div className="about-head1">                 
                    <div className="log-form inner-log-form">
                      <h4 className="fontSizeClass"> Guidelines For The Owners Of Heritage Buildings </h4>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 form-group pt-2">
                          <div className="list-of-forms">
                            <h5 className="fontSizeClass"> <strong> 425E. Powers and functions of Heritage Conservation Committee : </strong> </h5>
                            <span>...
                             <Link className="fontSizeClass" to='/HeritageBuildingHome'>  {t("1")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome2'> {t("2")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome3'> {t("3")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome4'> {t("4")} </Link>
                            </span>
                           <p> The Heritage Conservation Committee shall have the power to function independent of the
                             Municipal Building Committee for purpose of preservation, conservation and maintenance 
                             of heritage buildings in so far as such power does not offend any other provisions 
                             of this Act or the rules made thereunder relating to construction or use of building :
                           </p>
                           <p>
                           Provided that for erection or re-erection in a heritage building or part thereof, 
                           or for restoration of any heritage building to its old shape, design or beauty in 
                           the case of unlawful demolition, or for making any change of internal or external 
                           wall, structural pattern, floor, roof, interior or exterior architectural floor, 
                           facade or skyline, or for any other change, or a heritage building, the provisions
                            of chapters XXII and XXIII of this Act and the rules made thereunder shall apply
                             mutatis mutandis.
                           </p>
                           <h5>425F. Power of Corporation to require, purchase or take on lease heritage building :</h5> 
                           <p> Subject to the other provisions of this Act, the Corporation may acquire, purchase or take on 
                            lease any heritage building for the purpose of preservation and conservation thereof : 
                            Provided that in the case of a heritage building declared as such for the purpose of preservation 
                            and conservation as required under sub-clause (ii) of clause (a) of sub-section 
                            (4) of section 31 of the West Bengal Town and Country (Planning and Development) Act, 1979 
                            (West Ben. Act XIII of 1979), the approval of the concerned department of the State Government 
                            shall be taken.
                           </p>
                           <h5>425G. Transfer of right of development for the purpose of acquisition by agreement :</h5>
                           <p>When the owner of any heritage building is not willing to preserve or conserve any heritage building,
                             the Municipal Commissioner may, for the purpose of acquisition of such heritage building by agreement
                              and on the recommendation of the Heritage Conservation Committee and with the approval of the 
                              Mayor-in-Council, allow the transfer of right of development of such heritage building, which 
                              shall be heritable and transferable, to the owner of such heritage building in such manner, and 
                              subject to such conditions, as may be prescribed.
                            </p>
                            <h5>425H. Right of access to heritage building acquired by Corporation :</h5>
                            <p>Subject to such rules or regulations as may be made under this Act, every person shall have the 
                                right of access to any heritage building acquired by the Corporation.</p>
                            <h5>425I. Sub-lease of heritage building :</h5>
                            <p>The Corporation shall have the right to allow the transfer of right of development to the lessee 
                                of a heritage building where the unexpired period of the term of lease is for 90 years, 
                                and to take the heritage building on sub-lease by agreement, if there is provision for 
                                such sub-lease in the deed executed between the owner and the lessee, provided that the 
                                question of payment of premium or rent in such case to the owner shall not, notwithstanding
                                 any agreement in this behalf, arise, and if the owner as confirming party to the agreement 
                                 waives the right to receive any further payment of such premium or rent.
                            </p>
                            <h5>425J.Permission of concerned department of State Government before acquisition of heritage building :</h5>
                            <p> If the Corporation considers that it is necessary to acquire any building declared as a heritage 
                                building for the purpose of preservation and conservation as required under sub-clause 
                                (ii) of clause (a) of sub-section (4) of section 31 of the West Bengal Town and Country 
                                (Planning and Development) Act. 1979, by agreement or under the Land Acquisition Act, 1894 
                                (1 of 1894), permission of the concerned Department of the State Government shall be taken 
                                before such acquisition.</p>
                            <h5>425K. Power to exempt rates and taxes, etc. on heritage building :</h5>
                            <p>If the owner of a heritage building enters into an agreement with the Corporation to maintain, 
                                preserve and conserve such heritage building properly at his own expenses, the Corporation may, 
                                in such case, exempt wholly or partly the owner of such heritage building from payment of rates 
                                of taxes or fees for supply of water or any other charge in respect of such heritage building.
                            </p>
                            <h5>425L.	Agreement with owner of heritage building pending acquisition :</h5>
                            <p>(1)	The Municipal Commissioner may, pending acquisition of a heritage building by the 
                                Corporation under this Act and with the approval of the Mayor-in-Council, propose to the 
                                owner of such heritage building to enter into an agreement with the Corporation for a 
                                specified period for the maintenance of such heritage building.
                            </p>
                            <p> (2)	The agreement as aforesaid may provide for all or any of the following matters :- 
                            (a)	maintenance of the heritage building by the owner or by any other person willing to maintain the said heritage building;
                            (b)	custody of the heritage building and the duties of the person who may be employed to watch it;
                            (c)	the restrictions of the owner's right -
                            (i)	to use the heritage building for any other purpose detrimental to its conservation,
                            (ii)	to charge any fee for entry into, or inspection of, the heritage building, and
                            (iii)	to build on or near the site of the heritage building.
                            </p>
                            <h5>425M.	Voluntary contribution and agreement with any voluntary organisation, person or company :</h5>
                            <p>(1)	The Municipal Commissioner may receive voluntary contributions towards 
                                the cost of maintaining any heritage building and may give order as to the
                                 management and application of such contributions for the purpose of preservation and conservation
                                  of such heritage building.</p>
                            <p>	(2)	Subject to the approval of the Mayor-in-Council, the Municipal Commissioner may enter into any 
                                agreement with any person or voluntary organisation or company, whether incorporated or not, 
                                willing to preserve and conserve any heritage building on such terms and conditions as the 
                                Municipal Commissioner may determine.</p>

                          </div>
                          <span>...
                             <Link className="fontSizeClass" to='/HeritageBuildingHome'>  {t("1")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome2'> {t("2")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome3'> {t("3")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome4'> {t("4")} </Link>
                            </span>                       
                        </div>
                      </div>                
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  </section>
</>

  )
}

export default HeritageBuildingHome3