import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Controlling_Officers  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt='/' />
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu ">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Contact_Us_Home'> {t("Contact Us Home")} </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Important_Contacts' target="_blank"> {t("Important Contacts")} </Link>
                 </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/MMIC'> {t("MMIC")}</Link></li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass active1" to='/Controlling_Officers'> {t("Controlling Officers")}</Link></li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Councillors'> {t("Councillors")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Borough_Committee'> {t("Borough Committee")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Borough_Committee'> {t("Borough Offices")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCWard.jsp' target="_blank"> {t("Know Your Ward")}</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass"> {t("Controlling Officers")} </h3>
            </div>
            <div className="col-lg-12 about-head1">
              <div className="mayor-table">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-warning table-bor-2">
                      <th className="bd-wid" scope="col">
                        
                        {t("Sl. No")}.
                      </th>
                      <th scope="col"> {t("Designation")} </th>
                      <th scope="col"> {t("Names")} </th>
                      <th scope="col"> {t("Office phone No")} </th>
                      <th scope="col"> {t("E-mail")} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row"> 1 </th>
                      <td> C.M.F &amp; A (ACMA, WBA &amp; AS) </td>
                      <td> Shri Arkadeb Bhadury </td>
                      <td> 2286-1272 Extn-2473 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: cmfa@kmcgov.in" target="_blank">
                          
                          cmfa@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 2 </th>
                      <td> Ch. Mpl. Auditor (WBA &amp; AS) </td>
                      <td> Shri Sumanta Kumar Bhaumik </td>
                      <td> 2286-1273 Extn-2581 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: cma@kmcgov.in " target="_blank">
                          
                          cma@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 3 </th>
                      <td> Ch. Mpl. Law Officer </td>
                      <td> Shri Dr. Salim Ansari (W.B.L.S) </td>
                      <td> 2286-1269 Extn-2407 </td>
                      <td> - </td>
                    </tr>
                    <tr>
                      <th scope="row"> 4 </th>
                      <td> C.M.E (SWM) </td>
                      <td> Shri Subhasis Chattopadhyay </td>
                      <td> 2252-0560/2252-2753 Extn-2649 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: cma@kmcgov.in " target="_blank">
                          
                          cma@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 5 </th>
                      <td> Ch. Municipal Health Officer </td>
                      <td> Dr. Subrata Roy Chowdhury</td>
                      <td> 2286-1238 Extn- 2503 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: cmho@kmcgov.in " target="_blank">
                          
                          cmho@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 6 </th>
                      <td> Director General(Parks &amp; Squares) </td>
                      <td> Shri Kamal Sarkar </td>
                      <td> 2461-5505 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: dg_ps@kmcgov.in " target="_blank">
                          
                          dg_ps@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 7 </th>
                      <td> O.S.D. &amp; E.O.Director General (Civil) </td>
                      <td> Shri P.K. Dhua </td>
                      <td> 2286-1249 Extn. 2444 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: dg_civil@kmcgov.in " target="_blank">
                          
                          dg_civil@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 8 </th>
                      <td> Director General(Sewerage &amp; Drainage) </td>
                      <td> Shri Santanu Kumar Ghosh </td>
                      <td> 2286-1253 Extn. 2661 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: sotid@kmcgov.in " target="_blank">
                          
                          sotid@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 9 </th>
                      <td> Director General (Project), KEIIP </td>
                      <td> Shri Soumya Ganguli </td>
                      <td> 2283-0350/2283-0169 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: dg_project@kmcgov.in " target="_blank">
                          
                          dg_project@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 10 </th>
                      <td> Director General (Building) </td>
                      <td> Shri Ujjwal Kumar Sarkar </td>
                      <td> 2286-1277, Extn-2451 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto:dg_bldg@kmcgov.in " target="_blank">
                          
                          dg_bldg@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 11 </th>
                      <td> Director General (Roads/Mechanical) </td>
                      <td> Shri Biswa Ranjan Rakshit </td>
                      <td> 2252-2601 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: dg_roads@kmcgov.in " target="_blank">
                          
                          dg_roads@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 12 </th>
                      <td> Director General (Roads/Civil) </td>
                      <td> Shri Sukanta Das </td>
                      <td> 2286-1000, Extn-2598 </td>
                      <td> - </td>
                    </tr>
                    <tr>
                      <th scope="row"> 13 </th>
                      <td>
                        
                        Director General (T.P.D.D &amp; Tolly Nalah Project)
                      </td>
                      <td> Shri Soumitra Bhattacharya </td>
                      <td> 2252-2590, Extn-2501 </td>
                      <td> - </td>
                    </tr>
                    <tr>
                      <th scope="row"> 14 </th>
                      <td> Director General (IUM) </td>
                      <td> Shri Debasish Chakraborty </td>
                      <td> 2286-1089 Extn-2583</td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: dg_ium@kmcgov.in " target="_blank">
                          
                          dg_ium@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 15 </th>
                      <td> Director General (Water Supply) </td>
                      <td> Shri Mainak Mukherjee </td>
                      <td> 2286- 1239 Extn- 2247/2539</td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: dg_ws@kmcgov.in " target="_blank">
                          
                          dg_ws@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 16 </th>
                      <td>
                        
                        Director General (Elect. &amp;Ltg.) (Actg.) &amp; OSD
                      </td>
                      <td> Shri Sanjoy Bhowmik </td>
                      <td> 2252-0429 Extn- 2597</td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: dg_elec@%20kmcgov.in " target="_blank">
                          
                          dg_elec@%20kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 17 </th>
                      <td> Director General (Bustee) </td>
                      <td> Shri Amitava Pal </td>
                      <td> 2252-1280, Extn- 2598</td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: dg_bustee@kmcgov.in " target="_blank">
                          
                          dg_bustee@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 18 </th>
                      <td> Director General (E &amp; H) </td>
                      <td> Shri Subhajit Dasgupta </td>
                      <td> - </td>
                      <td> - </td>
                    </tr>
                    <tr>
                      <th scope="row"> 19 </th>
                      <td> Ch . Manager (Personnel) </td>
                      <td> Shri Saikat Dasgupta </td>
                      <td> 2286-1000, Extn. 25742286-1255 </td>
                      <td>
                        
                        e-mail :
                        <Link
                          to="mailto: cm_personnel@kmcgov.in "
                          target="_blank"
                        >
                          
                          cm_personnel@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 20 </th>
                      <td> Ch.Manager (Market) </td>
                      <td> Shri Bhaskar Ghosh </td>
                      <td> 22861000 Extn-2306 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: cm_mkt@kmcgov.in " target="_blank">
                          
                          cm_mkt@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 21 </th>
                      <td> Chief Manager (Licence &amp; Car Parking) </td>
                      <td> Shri Bijay Biswas </td>
                      <td> 2286-1036/2286-1125 Extn-2859/2285 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: cm_lic@kmcgov.in " target="_blank">
                          
                          cm_lic@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 22 </th>
                      <td> Chif Manager (Amusement) </td>
                      <td> Shri Mukul Ranjan Barai </td>
                      <td> 2286-1000, Extn-2306 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: mrbarai68@gmail.com " target="_blank">
                          
                          mrbarai68@gmail.com
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 23 </th>
                      <td>
                        
                        Ch. Mngr. [Revenue/South] &amp; Ch. Mngr. (Advt, SW
                        &amp; UPA), Zone-IV
                      </td>
                      <td> Shri Bhaskar Bhattacharya </td>
                      <td> 2286-1263, Extn-2502 </td>
                      <td>
                        
                        e-mail :
                        <Link
                          to="mailto: 	b_bhattacharya@kmcgov.in "
                          target="_blank"
                        >
                          
                          b_bhattacharya@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 24 </th>
                      <td>
                        
                        Ch. Mngr. [Revenue/North] &amp; Ch. (SW &amp; UPA),
                        Zone-I
                      </td>
                      <td> Shri Sauvick Sikdar </td>
                      <td> 2252-0615 Extn- 2633 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: s_sikdar@kmcgov.in " target="_blank">
                          
                          s_sikdar@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 25 </th>
                      <td>
                        
                        Ch. Mngr. (J.U) &amp; Ch. Mngr. SW &amp; UPA), Zone-II
                      </td>
                      <td> Smt. Sahelie Mukherjee </td>
                      <td> 2252-0387 Extn 2635 </td>
                      <td> - </td>
                    </tr>
                    <tr>
                      <th scope="row"> 26 </th>
                      <td>
                        
                        Ch. Mngr. [SR &amp; AC, Pension, Staff Qtr., P.F. ,
                        G.I., H.B.L, Revenue/TTD]
                      </td>
                      <td> Smt. Rakhi Ganguly </td>
                      <td> 2286-1032 Extn-2864 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: cm_srac@kmcgov.in " target="_blank">
                          
                          cm_srac@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 27 </th>
                      <td> Ch manager, SW &amp; UPA, (HQ), Br-XV (Zone -V) </td>
                      <td> Shri Partha Pratim Saha </td>
                      <td> Extn-2579 </td>
                      <td>
                        
                        e-mail :
                        <Link
                          to="mailto: cm_sw_upa_hq@kmcgov.in "
                          target="_blank"
                        >
                          
                          cm_sw_upa_hq@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 28 </th>
                      <td> Ch. Mngr. (REV. ADDED AREA) &amp; EDUCATION </td>
                      <td> Shri Siddhartha Sankar Dhara </td>
                      <td> 2286-1263 Extn-2520 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: cm_edu@kmcgov.in " target="_blank">
                          
                          cm_edu@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 29 </th>
                      <td>
                        
                        Ch. Manager (Admin), Br-XIII, XIV &amp; XVI (Zone -V)
                      </td>
                      <td> Smt. Dolan Champa Sikdar </td>
                      <td> - </td>
                      <td> - </td>
                    </tr>
                    <tr>
                      <th scope="row"> 30 </th>
                      <td>
                        
                        Spl. CMFA- II &amp; Controlling Officer (Treasury)
                      </td>
                      <td> Shri Mrityunjoy Biswas </td>
                      <td> 2252-0324 Extn-2579 </td>
                      <td> - </td>
                    </tr>
                    <tr>
                      <th scope="row"> 31 </th>
                      <td>
                        
                        Ch. Manager (Admin), Br-VII &amp; VIII (Zone -III)
                      </td>
                      <td> Smt.Soma Sengupta </td>
                      <td> - </td>
                      <td> - </td>
                    </tr>
                    <tr>
                      <th scope="row"> 32 </th>
                      <td> Ch.Manager(Systems) </td>
                      <td> Shri Subhasis Roy Chaudhury </td>
                      <td> 2252-0341 Extn-2911 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: sroyc@kmcgov.in " target="_blank">
                          
                          sroyc@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 33 </th>
                      <td>
                        
                        Manager &amp;Controlling Officer (MPLADS &amp; BEUP)
                      </td>
                      <td> Shri Kalicharan Banerjee </td>
                      <td> 2286-1351 Extn-2443 </td>
                      <td>
                        
                        e-mail :
                        <Link
                          to="mailto: kc_banerjee@kmcgov.in "
                          target="_blank"
                        >
                          
                          kc_banerjee@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 34 </th>
                      <td> Ch. Valuer &amp; Surveyor </td>
                      <td> Shri Nitish Chandra Basak </td>
                      <td> 2286-1264, Extn-2575, 2210, 2512 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: chvs@kmcgov.in " target="_blank">
                          
                          chvs@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h4 className="fontSizeClass">{t("Senior Officers")}</h4>
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-warning table-bor-2">
                      <th className="bd-wid" scope="col">
                        
                        {t("Sl. No")}.
                      </th>
                      <th scope="col"> {t("Designation")} </th>
                      <th scope="col"> {t("Names")} </th>
                      <th scope="col"> {t("Office phone No")} </th>
                      <th scope="col"> {t("E-mail")} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row"> 1 </th>
                      <td> Spl. CMFA - I </td>
                      <td> Shri Kaushik Choudhury </td>
                      <td> 2286-1073 Ext-2476 </td>
                      <td>
                        
                        e-mail :
                        <Link to="mailto: spcmfa@kmcgov.in " target="_blank">
                          
                          spcmfa@kmcgov.in
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"> 2 </th>
                      <td>
                        
                        District Officer, Minority Affairs, (DOMA) Kolkata
                      </td>
                      <td> Arshad Jamal Hshami, WBCS (Exe) </td>
                      <td> 2287-0461 2287-1107 </td>
                      <td> - </td>
                    </tr>
                    <tr>
                      <th scope="row"> 3 </th>
                      <td> Dy. Ch. Engineer &amp; CSP </td>
                      <td> Shri Kartik Ch.Ghosh </td>
                      <td> - </td>
                      <td> - </td>
                    </tr>
                    <tr>
                      <th scope="row"> 4 </th>
                      <td> PCS (Executive), OSD (Education) </td>
                      <td> Saadia Alam </td>
                      <td> 2252-0478 </td>
                      <td> - </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Controlling_Officers