import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Assessment_Collection_Assessment_Home  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt='' />
    </div>
  </section>

  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu   min-height-400">
            <nav className="sidebar card py-2 mb-4">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Assessment_Collection_Assessment_Home'>  {t("Assessment Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_Process_Muation'> {t("Process of Mutation")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_Collection_Unit_Area_Assessment'> {t("Unit Area Assessment")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('289')}> {t("Tax Rates / Fees")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_download_forms'> {t("Download Forms")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_Message_Board'> {t("Message Board")}  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_Notice'> {t("Notice")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/HearingNoticeSearch.jsp'>  {t("Check Hearing Notices")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/MutationStatusSearch.jsp'> {t("Check Mutation Status")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/NocDetails.jsp'> {t("Download NOC(A.C)")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCAssessmentCheckPaymentStatus.jsp'> {t("Check Payment Status")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCAssessmentCurrentPD.jsp'> {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WaiverApplicationFP.jsp'> {t("Application for Upto 50% Intrest Waive")} </a>
                </li>
                <li className="nav-item">
                        <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Print Unpaid Bill")}<i className="bi bi-chevron-down" /></button>
                        <div id="demo" className="collapse">                        
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAsmtAllUnpaidDemandPrint.jsp">{t("All Bill")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/PDDemandPrint.jsp">{t("Current PD")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/FSDemandPrint.jsp">{t("F/S Bill")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDemandPrint.jsp">{t("Outstanding LOI")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDemandWaiverFiftyPrint.jsp">{t("Upto 50% Interest Waive LOI")}</a>
                        </div>
                </li>
                <li className="nav-item">
                        <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("Make Online Payment")}<i className="bi bi-chevron-down" /></button>
                        <div id="demo1" className="collapse">                        
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssesmentAllUnpaidDemand.jsp">{t("All Bill")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssesmentLoiWaiverFiftyPayment.jsp">{t("Upto 50% Interest Waive LOI")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/MiscellaneousSuspensePay.jsp">{t("Suspense")}</a>
                        </div>
                </li>
                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Reprint e-Receipt")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo2" className="collapse">                        
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssessmentCombinedReceiptReprint.jsp">{t("All Bill")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/PDDuplicateReceipt.jsp">{t("Current PD, F/S")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDuplicateReceipt.jsp">{t("Outstanding LOI")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDuplicateReceipt.jsp">{t("Waiver 2020 Receipt")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/MiscSuspDuplicateReceipt.jsp">{t("Suspense Receipt")}</a>
                      </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AssesseeInformationSearch.jsp'> {t("Assessment Information Search")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AssessmentReportPrint.jsp'>{t("Assessment Register")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Apply for Mailing Address Change")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Mutation Application")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('317')}> {t("Help for Online Mutation Application")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('318')}> {t("Litigated Property")} </a>
               </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_Collection_FAQ'> {t("FAQs")} </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Assessment_Dept'> {t("Contact Details")} </Link>                </li>
              </ul>
           </nav>
          </div>
        </div>
        <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass"> {t("Assessment of Premises")}</h3>
            </div>
            <div className="col-lg-12 about-head1">
              <div className="unit-area-assessment">
                <p className="fontSizeClass">
                  <b>{t("Assessment of Property Tax in Kolkata")} :</b>
                </p>
                <p className="fontSizeClass">
                  {t("Unit Area Assessment (UAA) System for determination of property tax is commenced, at present, for the Ward Number 001 to 141, with the final publication of Scheme in Official Gazette on 1st April, 2017. From 1st quarter of 2017, all properties within such wards have come under the new system and are required to be reassessed as per provisions of UAA System. However, if there is any assessment pending for the previous effective quarters with respect to any property, such assessment may be completed under the Annual Rateable Value (ARV) method of assessment that was prevalent prior to final publication of Scheme. A brief idea of two different systems for determination of property tax is as follows")}:-
                </p>
                <br />
                &nbsp;
                <br />
                <p className="fontSizeClass">
                  <b>
                    {t("(A) Annual Rateable Value (ARV) method of assessment of Property Tax")} :
                  </b>
                </p>
                <div id="accordion">
                    <div className="card">
                      <div className="card-header" id="heading1">
                      <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                      1. {t("Determination Of Annual Valuation(AV)")}
                      </button>
                      </div>
                      <div id="collapse1" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 m-4">
                        <p className="fontSizeClass">
                          ● {t("If the property is used by the owner himself, 'Reasonable Rental Method' is applied, i.e. from our database and comparing with similar premises, expected reasonable rent per month, which the property is capable of fetching, is determined. Then it is multiplied by 12 to arrive at Annual Rental Value and a 10% statutory allowance for maintenance is subtracted to arrive at the Annual Valuation (rounded off to nearest ten rupees)")}
                          <br />
                          ● {t("If the property is tenanted, the actual monthly rent (including service charges and / or maintenance charges, if any) is multiplied by 12 less 10% statutory allowance to arrive at the Annual Valuation")}.
                          <br />
                          ● {t("In case of Theatre/Cinema halls 7.5% of the Gross Annual receipts (excluding taxes) is fixed as Annual Value for the Hall")}.
                          <br />
                          ● {t("In case of vacant unconstructed land, the Annual Value w.e.f 3rd August 2008 - 2009 will be 2.5% and 7% of estimated market value for land measuring upto and above 5 cottah respectively. For effective assessment period before March 2008-09, the rate is 7% for all cases irrespective of the land area")}. <br />
                        </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading2">
                      <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                      2. {t("Rates of Taxes")} :
                      </button>
                      </div>
                      <div id="collapse2" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 m-4">
                        <p className="fontSizeClass"> 
                            ● {t("If the Annual Valuation as fixed above, does not exceed Rs.600/-, the rate of tax is 11% of the Annual Value i.e. if A.V. is Rs.500/- the property tax per year will be Rs.55/- plus Howrah bridge tax . To get quarterly gross amount of the property tax, annual property tax, is divided by 4 and rounded off to the nearest rupee. Rebate @ 5% on the quarterly tax is allowed if deposited within specified date. Net amount of property tax after rebate will also be rounded off to the nearest rupee")}.<br />
                            ● {t("If the Annual Valuation as fixed above, exceeds Rs.600/- but does not exceed Rs.18000/, annual tax rate is the percentage of the A.V., worked out by dividing the A.V. of the premises by 600 and adding 10 to the quotient, the sum thus worked out being rounded off to the nearest first place of decimal. Say, if A.V. of premises is Rs.1300/-; such quotient will be 1300/600=2.16% and property tax rate will be 2.16+10=12.2% (rounded off). So gross tax of such property per year will be Rs.158.60/- plus Howrah Bridge tax @ 0.5% or 0.25%, as the case may be, of AV. To get quarterly gross amount of tax this should be divided by 4 and rounded off to the nearest rupee. A rebate of 5% or the quarterly tax is allowed if deposited within specified date. Net amount of property tax after rebate will also be rounded off to the nearest rupee")}.<br />
                            ● {t("If the Annual Valuation as fixed above exceeds Rs.18000/-, the rate of tax is 40% of the Annual Value. That is if A.V. is Rs.20000/- the property tax per year is Rs.8000/- plus Howrah Bridge tax @ 0.5% or 0.25%, as the case may be, of A.V. and a further rebate of 5% of the quarterly tax is allowed, if deposited within specified date")}.<br />
                            ● {t("Howrah Bridge Tax percentage is 0.5% (for Ward No.1-65, 68-88, 90), 0.25% (for Ward No.66,67,89,91-100, 115-141), 0% (for Ward No.101-114)")}.<br />
                            ● {t("In case of general flats / apportioned units, tax rate percentage is calculated on a cumulative Annual Value of all the flats within that premises and should be applied on individual Annual Value for respective flats to get the amount of gross property tax")}.<br />
                            ● {t("For Bustee and some statutory organizations, the rate of property tax is different. For Bustee the maximum tax rate is 18% on Annual Value")}.<br />
                            ● {t("For commercial/non-residential property, normally a surcharge @ not exceeding 50% of property tax is applicable to such property or portion of such property under non residential or commercial use and is levied additionally")}.<br />
                            ● {t("No property tax is payable if AV does not exceed Rs.300 (three hundred)")}.
                        </p>
                        </div>
                      </div>
                    </div>                    
                    <div className="card">
                      <div className="card-header" id="heading3">
                      <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                      3. {t("Hearing on Proposed Annual Value")} :
                      </button>
                      </div>
                      <div id="collapse3" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 m-4">
                        <p className="fontSizeClass"> 
								          {t("There is no system of Self-Assessment in this method and generally Kolkata Municipal Corporation, on proposing any Annual Value gives hearing to the tax-payers and finally determine the Annual Value and property tax after hearing process is over")}.
							          </p>
                        </div>
                      </div>
                    </div>
               </div>
               <p className="fontSizeClass"><b>{t("(B) Unit Area Assessment (UAA) System of determination of Property Tax")} :</b></p>
					<p className="fontSizeClass">{t("As a first step to implement Unit Area Assessment System Ward No.001 to 141 have been subdivided into 293 blocks and categorized under seven categories from ‘A’ to ‘G’. Final Notification on Blocks and Categories defining boundaries of such blocks has already been published in Official Gazette")}.</p><br />
					<p className="fontSizeClass"> {t("Corporation has also published the ‘Scheme’ for UAA System in Official Gazette on 1st April, 2017 that comprises the following")} :</p>
					<p className="fontSizeClass">
						● {t("Base Unit Area Value i.e. Annual Value per sq.ft. of covered space of building or land comprising building or any vacant land of  respective categories")}.<br />
						● {t("Multiplicative Factors, which are property specific")}.<br />
						● {t("Rate of property Tax")}.<br />
						● {t("Taxable vacant land when there is a construction")}.<br />
						● {t("Manner relating to submission of Self Assessment Form")}.<br />
					</p>
					<p className="fontSizeClass"> {t("Also, on 1st April, 2017, Final Notifications on following Rules & Regulations have been published in Official Gazette")} :</p><br />
					<p className="fontSizeClass">
						● {t("The Kolkata Municipal Corporation (Self-Assessment System, Manner and Forms) Rules, 2017")}.<br />
						● {t("The Kolkata Municipal Corporation (Covered Space) Regulations, 2017")}.<br />
						● {t("The Kolkata Municipal Corporation (Unit Area Assessment – Tax Capping) Regulations,2017")}.<br />
					</p>
					<p className="fontSizeClass">{t("For having a basic idea on the new system, please refer to page Brief Account of Unit Area Assessment System")}</p>
					<br />&nbsp
					<p className="fontSizeClass">
						{t("For further detail please refer to")}<br />
						{t("Final Notification on Blocks & Categories")},<br />
						{t("Scheme for Unit Area Assessment System")} <br />
						{t("Relevant Rules & Regulations with regard UAA system as referred above")}
					</p><br />
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="col-lg-6">
              <div id="accordion">
                    <div className="card">
                      <div className="card-header" id="heading4">
                      <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                      4. {t("Self-Assessment of Property Tax")}
                      </button>
                      </div>
                      <div id="collapse4" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 m-4">
                        <p className="fontSizeClass">
                        {t("As per provision of the Scheme and relevant Rules and Regulations as well as provisions of the Kolkata Municipal Corporation Act, 1980 (as amended time to time), the owner or lessee or sublessee or person liable to pay property tax of a property is required to submit Return in duly filled in Self-Assessment Form (SAF) within sixty days from date of publication of Scheme. Forms are available from website or helpdesks of different offices of Assessment-Collection departments common collection centres &amp; e-Kolkata citizens service Centres. Forms can be submitted offline at such offices and demand under UAA will be generated after entry of data given in the form. There is also provision of online submission of SAF and payment in online mode")}.
                      </p>
                      <br />
                      <p className="fontSizeClass">
                        {t("The forms so submitted will be checked later randomly and property tax will be calculated by the Kolkata Municipal Corporation by its own mechanism. In case forms are not submitted in time, KMC will assess the property suo-motu. In both the cases, before final determination of Annual Value and Property Tax by the Municipal Commissioner or his representative, a one month notice will be served to the tax-payer allowing him to give objection, if any. In case there is any objection, submitted within stipulated period, notice for Hearing will be issued and the tax will be determined after hearing process is over")}.
                      </p>
                      <br />
                      <p className="fontSizeClass">
                        {t("It will be pertinent to note here that Annual Valuation (AV) of different portions of a property having single assessee no. with different types of age or occupancy or structure or usages are to be assessed separately for respective portions in the relevant format mentioning them in different rows. Thereafter, on the total Annual valuation the payable tax is to be calculated as per relevant tax rate. Annual Valuation of Car parking space (if any) is also to be assessed separately as per relevant MF and added to the total Annual Value for calculation of payable tax")}.
                      </p>
                      <br />
                      <p className="fontSizeClass">
                        {t("In case of a property/flat/unit/apartment in a multiple flat building with different flat owners, proportionate common area for individual flat is to be calculated and assessed separately as per relevant MF and added to the Annual Value of the flat(s)/units(s)/apartment(s) for deriving the total annual Value for calculating payable tax as per relevant tax rate")}.
                      </p>
                      <br />
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading5">
                      <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                      5. {t("Criterion for location MF")}
                      </button>
                      </div>
                      <div id="collapse5" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 m-4">
                        <p className="fontSizeClass">
                          {t("Please note that for location MF road width is the criterion. Road width includes footpath and carriageway")}
                        </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading3">
                      <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                      6. {t("Continuance of making payment of existing property tax under the old (ARV) System")}
                      </button>
                      </div>
                      <div id="collapse3" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 m-4">
                        <p className="fontSizeClass">
                        {t("So long the property tax under UAA system is not finally determined and paid, the tax-payers are required to make payment of property tax so determined under the old (ARV) system. Property Tax Bill under ARV System for the year 2017-18 is already raised and tax-payers are requested to make payment of such tax. If demand against all four quarters are paid at a time within rebate period of first quarter, additional 5% rebate will be allowed for 2nd, 3rd & 4th quarters, in addition to normal rebate of 5%")}.
                      </p>
                      <br />
                      <p className="fontSizeClass">
                        {t("If there is any difference in property tax under new system compared to the existing demand under ARV system, so paid for the year 2017-18 or so; the same will be duly adjusted or taken care of at the time of raising demand under new system")}.
                      </p>
                        </div>
                      </div>
                    </div>
               </div>
        </div>
        <div className="col-lg-6">
              <div id="accordion">
                    <div className="card">
                      <div className="card-header" id="heading7">
                      <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                      7. {t("How to learn outstanding dues in respect of Property Tax")}:
                      </button>
                      </div>
                      <div id="collapse7" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 m-4">
                        <p className="fontSizeClass">
                        {t("Please procure an LOI from Computer Section of respective units of Assessment Collection Department and get it verified from the concerned division/ unit and if the LOI is found correct that is your O/S. [see sample]")}
                      </p>
                      <br />
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading8">
                      <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
                      8. {t("Functions of Municipal Assessment Tribunal")} :
                      </button>
                      </div>
                      <div id="collapse8" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 m-4">
                        <p className="fontSizeClass">
                        {t("Each assessee is given liberty to appear before a Hearing Officer if there is any objection against the valuation proposed by KMC (Section 184(3), 184(4), 185, 186 and 187 of KMC Act, 1980 may be referred). Hearing Officer determines such objections and fixes the Annual Valuation in respect of the premises of the assessee as per provisions under section 188 of KMC Act, 1980. The assessee is also given a further chance to appeal against such valuation as determined under section 188 if he prefers to. He can appeal before Municipal Assessment Tribunal (under section 189) consisting of a Chairman and a number of other members. However no appeal will be entertained by the said tribunal if the disputed tax is not deposited. Provisions of Limitation Act, 1963 is applicable relating to such appeal before tribunal. Valuation as determined by the Tribunal is final and no proceeding shall lie in any Civil Court against the valuation as determined by the Tribunal")}.
                      </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading9">
                      <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
                      9. {t("Information sheet for recovery of Property Tax through other penal measures")}:
                      </button>
                      </div>
                      <div id="collapse9" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 m-4">
                        <p className="fontSizeClass">
                        {t("Sections 217-221B, 223, 225 and Section 275 -(1)(aa) of Calcutta Municipal Act, 1980 deals the situation as mentioned above")}.
                        <br />
                        {t("a) Provision for interest and penalty for non-payment of property tax (Section 217)")}
                        <br />
                        {t("b) Issuance of Warrant of Distress and sale of movable property (Sections 220-221)")}
                        <br />
                        {t("c) Attachment and sale of immovable property (Section 221A and sale of property distrained or attached (Section 221 B)")}
                        <br />
                        d) {t("Recovery under Bengal Act III, 1913 (Section 223)")}
                        <br />
                        e) {t("Attachment of rent of the tenant(s) [Section 225]")}
                        <br />
                        f) {t("Disconnection of water supply line (Section 275 –(1) (aa))")}
                        <br />
                      </p>
                        </div>
                      </div>
                    </div>
              </div>
        </div>
      </div>
    </div>
  </section>


</>

  )
}

export default Assessment_Collection_Assessment_Home