import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


import { textAlign } from '@mui/system';

const Procedure = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const [ProjectService, setProjectService] = React.useState(false);
  const [MoreOneService, setMoreOneService] = React.useState(false);
  const [MoreTwoService, setMoreTwoService] = React.useState(false);
  const [MoreThreeService, setMoreThreeService] = React.useState(false);

  const ProjectServiceToggle = () => {
    setProjectService(!ProjectService);
  };
  const MoreOneServiceToggle = () => {
    setMoreOneService(!MoreOneService);
  };
  const MoreTwoServiceToggle = () => {
    setMoreTwoService(!MoreTwoService);
  };
  const MoreThreeServiceToggle = () => {
    setMoreThreeService(!MoreThreeService);
  };
  const { t, i18n, ready } = useTranslation();

  return (
    <>  
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"> <h4 className="fontSizeClass">{t("About_Kolkata")} </h4></div></div>             
    </div>       	 		 
  </section>	
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  ">           
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link active1" to='/Procedure'> {t("Procedure")} </Link> </li>
                <li className="nav-item"> <a href='https://www.kmcgov.in/KMCPortal/jsp/ComplaintSearch.jsp'> Search Grievances </a> </li>
                <li className="nav-item"><a href='https://www.kmcgov.in/KMCPortal/ComplaintFormAction.do'> Download Complaint Form </a>  </li>
                <li className="nav-item"><Link className="nav-link" to='/TERM_Cell'> {t("TERM Cell")} </Link> </li>  
                <li className="nav-item"><Link className="nav-link" to='/Pollution_Control_Board'> {("Pollution Control Board")} </Link></li>   
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass">{t("Procedure")}</h3>
              </div>
              <div className="col-lg-12 about-head1">
                <h5 className="fontSizeClass"> <strong>{t("New System For Submitting Complaints At KMC")}</strong> </h5>		   
                <p className="fontSizeClass"> <strong> {t("Now just A Click / Form Away !")} </strong> </p>		
              </div>
              <div className="about-img3 mt-4">
               <p>KMC receives various complaints from citizens on issues such as Assessment of Properties , 
                issue of Certificate of Enlistment, alteration of Building Plans , insufficient Water Supply, 
                Roads requiring repair etc .</p> <br></br>
                <p>KMC has launched a new system for managing these complaints wherein the citizen may submit complaints 
                    online or through a standard complaints form . All complaints received online or through these forms 
                    will be recorded in a central complaints logging e-database which will help KMC in systematically 
                    monitoring the action taken to resolve these complaints . These complaints form have been made 
                    available at designated public grievance counters at the KMC Head Quarter and all e-Kolkata citizen 
                    service centers and Borough Offices . This form can also be dowmloaded from this portal.</p>
              </div>		   
            
              <div className="about-img3 mt-4">
               <p> <strong> How Will New System Work ? </strong></p> <br></br>
               <p>
               <strong> Step One : </strong> Submit online Grievance or Fill in Grievance Redressal Form with the relevant detail. <br></br>
               <strong> Step Two : </strong>Tear off the and retain the lower portion of the form which will contain your Complaint No . For online submittion , please note the system generated Complaint No.<br></br>
               <strong> Step Three: </strong> Submit the form at any of the designated counters ( or drop it in the complaint drop-box), in case the grievance is not submitted online.<br></br>
               <strong> Step Four :</strong> Check status of your complaints at any of the designated counters by simply quoting your Complaint No or check the status online in this portal.
               </p>
              				 
              </div>

            </div> 
          </div>
        </div>
        </div>
    </div>
  </section>
    </>
  )
}

export default Procedure