import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Drainage_Faqs  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"></h4>
        </div>
      </div>
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Home'>{t("Drainage Home")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageNetworkMap.jsp' target="_blank">
                    {t("Ward wise Drainage Network Map")}   </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Fees_Charges'>
                    {t("Fees & Charges")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Drainage_Pumping_station'>
                    {t("Drainage Pumping Station")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/List_of_Sewage_Treatment_Plants'>
                    {t("Sewage Treatment Plants")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageFee.jsp' target="_blank">
                    {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageDemandPrint.jsp' target="_blank">
                    {t("Print Unpaid Bill")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalDrainagePayment.jsp' target="_blank">
                    {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DCDuplicateReceipt.jsp' target="_blank">
                    {t("Reprint e-Receipt")}  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DCDuplicateReceipt.jsp' target="_blank">
                    {t("List of KMC Drainage Activity")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Drainage_Faqs'> {t("FAQs")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Sewerage_Drainage_Dept'>{t("Contact Details")}</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass"> {t("FAQs :: Dept. of Drainage")} </h3>
            </div>
            <div className="col-lg-12 about-head1">
            <div id="accordion">
                        <div className="card">
                        <div className="card-header" id="heading1">
                        <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                        1. {t("What are the key activities of Sewerage and Drainage Department")}?
                        </button>
                        </div>
                        <div id="collapse1" className="collapse show" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        {t("The key activities of the department are")}: <br />
                        ● {t("Draining of waste water from the city of Kolkata")}.
                        <br />
                        ● {t("Operation & maintenance of different drainage pumping stations")}.
                        <br />
                        ● {t("Cleaning & maintenance of underground sewers, open and covered drains")}.
                        <br />
                        ● {t("To remove water logging & cleaning choked sewers with the help of pumps and mechanical sewer cleansing machines")}. <br />
                        ● {t("To accord sanction of premises house drainage plan and its connection to city sewerage through Engineering Department of different Borough offices in case")}: <br />
                        ○ {t("The building’s height is greater than 13.5 M")} <br />
                        ○ {t("The proposed house drainage connection is to be effected in a Sewer (dia≥600 mm.) or brick sewer")} <br />
                        ○ {t("It is for a housing estate ( cluster of buildings irrespective of height)")} <br />○ {t("Plot area more than 500")}
                        m²
                      </p>       
                            </div>
                        </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading2">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                            2. {t("How should I apply to get approval for construction of internal sewerage system")}?
                            </button>
                        </div>
                        <div id="collapse2" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        {t("Following are the steps to be taken by citizens in order to get approval for construction of internal sewerage system")}: <br />
                        ■ {t("Contact the Engineering dept. of your concerned borough office")} <br />
                        ■ {t("Collect the list of plumbers from the local Borough office")} <br />
                        ■ {t("Select a plumber from the list.The plumber would assist you in getting sanction to the house drainage plan of your premises and he will execute internal drainage system and effect the house drainage connection finally to Municipal drain/sewer under supervision of Engineering Department")}. <br />
                      </p>
                            </div>                      
                        </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading3">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                            3. {t("Which documents do I need to submit in order to get the approval for construction of internal sewerage system")}?
                        </button>
                        </div>
                        <div id="collapse3" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        {t("The plumber would assist you in this regard. You should attach the following documents along with the application for construction of internal sewerage system")}: ● {t("Plumber 'Niyog Patra' or Letter of Engagement")}
                        <br />
                        ● {t("Copy of Building Sanctioned Plan along with the Original copy for verification purpose")} <br />
                        ● {t("Layout of the proposed internal house drainage plan showing the proposed connection with KMC sewers")}. <br />
                        ● {t("Xerox copy of the enlistment Certificate of Plumber")}
                        <br />
                        ● {t("Copy of certified documents showing the deposition of drainage development charges")}. <br />
                      </p>
                            </div>
                        </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading4">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                            4. {t("Where should I submit the applic action")}?  
                        </button>
                        </div>
                        <div id="collapse4" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        {t("You are requested to submit the application along with the above mentioned documents to the concerned Borough Executive Engineer of your area")}.
                      </p>
                            </div>
                        </div>
                        
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading5">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                            5. {t("What do you mean by internal drainage system and external drainage system")}?
                        </button>
                        </div>
                        <div id="collapse5" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        {t("Internal drainage system means the underground sewers or drainage lines within the premises upto the master trap showing its connection with the sewer carrying soil lines, rainwater and kitchen waste water connections.External drainage system means the sewers or drainage lines connecting the house master tap to the nearest KMC underground sewers or drainage system")}.
                      </p>
                            </div>
                            </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading6">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                            6. {t("Do I need to get approval for the development of the external drainage system? If yes, which documents do I need to submit")}?
                        </button>
                        </div>
                        <div id="collapse6" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        {t("Yes, you need to get approval from KMC, by giving a written letter stating that the internal drainage system has been constructed as per the drawings submitted. You also need to attach a copy of the Occupancy Certificate received from the Building Department of KMC")}.
                      </p>
                            </div>
                            </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading7">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                            7. {t("When can the construction of the external drainage system begin")}?
                        </button>
                        </div>
                        <div id="collapse7" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        {t("After receiving your application for the development of external drainage connection, KMC would undertake a field visit of your premise and then ask you to deposit supervision charges and restoration expenses. After depositing the money as specified by KMC, you as the premises owner can start construction of external drainage connection through your authorized plumber with prior intimation to KMC")}.
                      </p>
                            </div>
                            </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading8">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
                            8. {t("How are the supervision charges and restoration expenses calculated")} ?
                        </button>
                        </div>
                        <div id="collapse8" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        
                        {t("KMC would prepare a cost estimate on the basis of KMC schedule of charges to calculate the expenses to be included for construction of such external drainage system. You would be required to pay 25% of the total (Supervision charge) cost estimate as stipulated and also bear the total cost of restoration of road (to be disturbed) which is calculated separately at the same time")}.
                      </p>
                            </div>
                            </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading9">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
                            9. {t("In case the external drainage system is damaged who is responsible")} ?
                        </button>
                        </div>
                        <div id="collapse9" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        {t("In case of damage of the external system the owner of the premises would be responsible and would be required to appoint enlisted plumbers and get the work done in conformity with KMC’s rules. He also needs to apply to Executive Engineer of concerned Borough and pay the supervision charges and road restoration expenses")}.
                      </p>
                            </div>
                            </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading10">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse10" aria-expanded="true" aria-controls="collapse10">
                            10. {t("Then, why did KMC charge development fees for sewer lines while sanctioning of building plan")}?                        </button>
                        </div>
                        <div id="collapse10" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        
                        {t("Drainage development fee is a one time payment, being charged at the time of sanction of proposed building or addition or alteration of the existing building. This fee is being charged to take care of the house drainage load of the proposed building at the premise to be disposed through the KMC sewerage and drainage system")}.
                      </p>
                            </div>
                            </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading11">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                            11. {t("Who is required to pay charges for sewerage and drainage service")} ?                       </button>
                        </div>
                        <div id="collapse11" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        
                        {t("Business establishments (Institutional, Commercial and Industrial Consumers) who enjoy the filtered water supply or tubewell water supply for their business use are required to pay the sewerage and drainage service charges")}.
                      </p>
                            </div>
                            </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading12">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse12" aria-expanded="true" aria-controls="collapse12">
                            12. {t("If I own a shop/business establishment, how would I be charged")} ?                       </button>
                        </div>
                        <div id="collapse12" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        
                        {t("In case you enjoy separate filtered water supply or tube-well water supply source in I.C.I. category you need to pay such drainage service charges as per specified rates of KMC. In case you enjoy a separate I.C.I. connection account then, such charges will be collected by the sewerage and drainage department")}.
                        <br />
                        {t("In case you do not enjoy any separate water supply source, but enjoy water supply facility from a domestic water supply source at the premises, the Sewerage and Drainage service charges will be charged by the License Department as per the specified rates of KMC")}.
                      </p>
                            </div>
                            </div>
                        </div>

                        <div className="card">
                        <div className="card-header" id="heading13">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse13" aria-expanded="true" aria-controls="collapse13">
                            13. {t("How often will I be billed")}?                      </button>
                        </div>
                        <div id="collapse13" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        {t("The sewerage and drainage department issues bills on the account holders annually in the month of April to May for the concerned financial year")}.
                      </p>
                            </div>
                            </div>
                        </div>

                        <div className="card">
                        <div className="card-header" id="heading14">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse14" aria-expanded="true" aria-controls="collapse14">
                            14. {t("Where can I deposit my Drainage Bill")}?                      </button>
                        </div>
                        <div id="collapse14" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        
                        {t("You can deposit your sewerage and drainage bill at the cash counter of central treasury of KMC at ground floor at 5, S.N. Banerjee Road, Kolkata-13. You can also pay your bill at any of the 15 e- Kolkata centers all over the city")}.
                      </p>
                            </div>
                            </div>
                        </div>

                        <div className="card">
                        <div className="card-header" id="heading15">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse15" aria-expanded="true" aria-controls="collapse15">
                            15. {t("What happens if I am late in my payment")}?                      </button>
                        </div>
                        <div id="collapse15" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        {t("Late payment will incite interest and penalty charges and may lead to disconnection of water supply facility. We advise you to pay your bills in time")}.
                      </p>
                            </div>
                            </div>
                        </div>

                        <div className="card">
                        <div className="card-header" id="heading16">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse16" aria-expanded="true" aria-controls="collapse16">
                            16. {t("What action should I take in case I have not received the Drainage bill on time")}?                      </button>
                        </div>
                        <div id="collapse16" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                            {t("Though much unlikely, in such cases, you should contact the Deputy Chief Engineer of the Drainage Department at 5, S.N. Banerjee Road, Kolkata-13 for issue of a duplicate bill. Non receipt of Drainage Bill may not be a valid reason for non payment of Drainage charges")}. </p>
                            </div>
                            </div>
                        </div>

                        <div className="card">
                        <div className="card-header" id="heading17">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse17" aria-expanded="true" aria-controls="collapse17">
                            17. {t("What should I do, if I notice missing/damaged manhole  or gully pit covers")}?                      </button>
                        </div>
                        <div id="collapse17" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        ● {t("Please inform the nearest ward office or Borough office of your concerned area. You can inform KMC headquarters on its toll free help line (1600 33 3375) or KMC Control Room: Tel. Ph. Nos. 2286-1212/2286-1313/2286/1414")}.
                      </p>
                      <p className="fontSizeClass">
                        ● {t("As a preventive measure you may also put a danger sign or some prominent object on the same or a stick protruding out of the manhole")}.
                      </p>
                      <p className="fontSizeClass">
                        ● {t("You can also register your complaints in the grievance cell of KMC")}.
                      </p>
                            </div>
                            </div>
                        </div>

                        <div className="card">
                        <div className="card-header" id="heading18">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse18" aria-expanded="true" aria-controls="collapse18">
                            18. {t("What should I do if I notice choked sewers/ gully pits/ house drainage")} ?                    </button>
                        </div>
                        <div id="collapse18" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                            {t("Please contact your local ward office or the concerned Borough office of your area. You may also contact the KMC Control Room or call at the toll free helpline")}. </p>
                            </div>
                            </div>
                        </div>

                        <div className="card">
                        <div className="card-header" id="heading19">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse19" aria-expanded="true" aria-controls="collapse19">
                            19. {t("I have seen that some people always dump the garbage/rubbish in the Nikashi /gully pits. Is that allowed?  ")}                   </button>
                        </div>
                        <div id="collapse19" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                            {t("No, this is strictly forbidden. Please help us to keep the city free from water logging. If you notice such an occurrence, please inform the nearest ward office or borough office or call at the toll free helpline immediately")}.  </p>
                            </div>
                            </div>
                        </div>

                        <div className="card">
                        <div className="card-header" id="heading20">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse20" aria-expanded="true" aria-controls="collapse20">
                            20. {t("What should I do if I notice someone laying the house drainage system by destroying KMC Road without any permission or with permission")}?                      </button>
                        </div>
                        <div id="collapse20" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            {t("The roads of KMC belong to its citizens. The damaged road will affect everyone using it. KMC would really appreciate if you can inform the nearest KMC ward office & KMC Control Room. You can also inform KMC on its toll free help line. You can also register your complaints in the grievance cell of KMC")}.
                            </div>
                            </div>
                        </div>

                        <div className="card">
                        <div className="card-header" id="heading21">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse21" aria-expanded="true" aria-controls="collapse21">
                            21. {t("What should I do if I notice water logging in my area")}  ?   </button>
                        </div>
                        <div id="collapse21" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        {t("Please inform the KMC Control Room or Local ward office or Drainage Department at KMC Head Office")}.
                      </p>
                            </div>
                            </div>
                        </div>

                        <div className="card">
                        <div className="card-header" id="heading22">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse22" aria-expanded="true" aria-controls="collapse22">
                            22. {t("What are the approximate durations for completion of various activities of the department")}? </button>
                        </div>
                        <div id="collapse22" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <div className="mayor-table2 depart">
                        <table className="table table-bordered">
                          <thead>
                            <tr className="table-warning table-bor-2">
                              <th scope="col"> {t("Sl.No")}. </th>
                              <th scope="col"> {t("Activities")} </th>
                              <th scope="col"> {t("Where")} </th>
                              <th scope="col"> {t("Timelines")} </th>
                              <th scope="col"> {t("Output")} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row"> 1. </td>
                              <td>
                                
                                {t("Process application form for Internal drainage system")}
                              </td>
                              <td> {t("Borough Office")} </td>
                              <td> {t("7 days")}* </td>
                              <td>
                                
                                {t("Sanction Letter incl. approved plan to plumber for construction of internal system")} .
                              </td>
                            </tr>
                            <tr>
                              <td scope="row"> 2. </td>
                              <td>
                                
                                {t("Estimation of charges for external drainage system")}
                              </td>
                              <td> {t("Borough Office")} </td>
                              <td> {t("15 days")}** </td>
                              <td> {t("Estimated charges to be paid")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 3. </td>
                              <td> {t("Process the charges and give approval")} </td>
                              <td> {t("Borough Office")} </td>
                              <td> {t("3 days")} </td>
                              <td>
                                
                                {t("Approval letter to plumber for construction")}.
                              </td>
                            </tr>
                            <tr>
                              <td scope="row"> 4. </td>
                              <td> {t("Handling of grievances")} </td>
                              <td> {t("Borough Exec. Eng")}. </td>
                              <td> {t("Depends")} </td>
                              <td> {t("Redressal")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 5. </td>
                              <td> {t("Complaints lodged on chokages")} </td>
                              <td> {t("Ward Office")} </td>
                              <td> {t("Every working day between 7 AM – 2 PM")}. </td>
                              <td> {t("Redressal")} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p className="fontSizeClass">
                        * {t("In case all documents are submitted as specified by KMC. ** In cases when clearance from the borough committee is not required")}.
                      </p>
                            </div>
                            </div>
                        </div>

                        <div className="card">
                        <div className="card-header" id="heading23">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse23" aria-expanded="true" aria-controls="collapse23">
                            23. {t("What should I do if my grievance is not redressed within the duration mentioned above")}?           </button>
                        </div>
                        <div id="collapse23" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                        
                        {t("In the unlikely event of such an occurrence, please contact the respective Borough Executive Engineer or Deputy Chief Engineer, Sewerage and Drainage dept. at the KMC Head Office")}.
                      </p>
                            </div>
                            </div>
                        </div>

                        <div className="card">
                        <div className="card-header" id="heading24">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse24" aria-expanded="true" aria-controls="collapse24">
                            24. {t("What are the contact details of Sub-depts. of the Sewerage and drainage Department")}?   </button>
                        </div>
                        <div id="collapse24" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <div className="mayor-table2 depart">
                        <table className="table table-bordered">
                          <thead>
                            <tr className="table-warning table-bor-2">
                              <th scope="col"> {t("Sl.No")}. </th>
                              <th scope="col"> {t("Sub Department")} </th>
                              <th scope="col"> {t("Address")} </th>
                              <th scope="col"> {t("Contact Person")} </th>
                              <th scope="col"> {t("Telephone No")}. </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row"> 1. </td>
                              <td> {t("Customer complaint Cell")} </td>
                              <td>
                                
                                {t("Local Ward Office or Br. Executive Engineer’s office")}
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td scope="row"> 2. </td>
                              <td>
                                
                                {t("Drainage Tax Billing Cell & Receiving office")}.
                              </td>
                              <td>
                                
                                {t("Drainage Department, 5, S.N. Banerjee Road, Kolkata-13")}.
                              </td>
                              <td> {t("Sri T.K Mondal O.S.D (S&D)")} </td>
                              <td> {t("2286-1000 Extn:2488")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 3. </td>
                              <td> {t("Mech. Sewer Cleansing Department")} </td>
                              <td> {t("-Do-")} </td>
                              <td> {t("Sri. Biplab Roy (Dy.Ch. Engg)")} </td>
                              <td> {t("2286-1000 Extn: 2559")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 4. </td>
                              <td> {t("Palmer Bridge Drainage Pumping Station")}. </td>
                              <td> {t("6/1, Palmer Bazar Road, Kol-15")}. </td>
                              <td> {t("Sri Biswanath Das (Ex. Engineer)")} </td>
                              <td> 2251-2402 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 5. </td>
                              <td> {t("Ballygunge Drainage Pumping Station")} </td>
                              <td> {t("25, Tiljala Road, Kolkata")}. </td>
                              <td> {t("Sri S. Bhattacharjee (Ex. Engineer)")} </td>
                              <td> 2344-9042 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 6. </td>
                              <td> {t("Dhapa Lock Drainage Pumping Station")} </td>
                              <td>
                                
                                {t("Jn. of E M Bye Pass & Canal South Road")}.
                              </td>
                              <td> {t("Sri Amarendranath Shaw (Ex. Engineer)")} </td>
                              <td> 2323-5536 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 7. </td>
                              <td> {t("Maniktala Drainage Pumping Station")} </td>
                              <td> {t("64, Raja Dinendra Street, Kolkata – 6")}. </td>
                              <td> {t("Sri S. Das (Ex. Engineer)")} </td>
                              <td> 2350-1166 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 8. </td>
                              <td> {t("Mominpur Drainage Pumping Station")} </td>
                              <td> {t("9, Mominpur Road, Kolkata-23")}. </td>
                              <td> {t("Sri S. Bhattacharjee (Ex. Engineer)")} </td>
                              <td> 2449-6630 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 9. </td>
                              <td> {t("Jodhpur Park Drainage Pumping Station")}</td>
                              <td> {t("1C, Gariahat Road (South) Kolkata-68")}. </td>
                              <td> {t("Sri S. Bhattacharjee (Ex. Engineer)")} </td>
                              <td> 2483-8457 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 10. </td>
                              <td> {t("Chetla Lock Drainage Pumping Station")}. </td>
                              <td> {t("P-125, Chetla Road, Kolkata-53")}. </td>
                              <td> {t("Sri S. Bhattacharjee (Ex. Engineer)")} </td>
                              <td> 2400-6243 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 11. </td>
                              <td> {t("Kalighat Drainage Pumping Station")} </td>
                              <td> {t("17, Judges Court Road, Kolkata-27")}. </td>
                              <td> {t("Sri S. Bhattacharjee (Ex. Engineer)")} </td>
                              <td> 2439-8857 </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                            </div>
                            </div>
                        </div>
            </div>
            </div>
          </div>
        </div>
        </div>
        
       
      </div>
    </div>
  </section>
</>

  )
}

export default Drainage_Faqs