import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Licence_Faq  = () => {
  const { t, i18n, ready } = useTranslation();
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
        <div className="container"> <div className="section-main-heading2"></div></div>             
      </div>       	 		 
    </section>	  
   
    <section className="section inner-pad1">
            <div className="container " >
              <div className="row">
              <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
              <li className="nav-item">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Certificate of Enlistment")} <i class="bi bi-chevron-down"></i> </button>
                    <div id="demo" className="collapse">
                        <a href='https://www.kmcgov.in/KMCPortal/downloads/725_MA_Revised_Enlistment_Trade_Calling.pdf' target="_blank"> {t("Guidline")} </a>
                        <a href='https://www.kmcgov.in/KMCPortal/downloads/TradeLicenceEoDBProcedure.pdf' target="_blank"> {t("Procedure")} </a>               
                    </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Requirements_For_Obtaining_CE'> {t("Requirements for CE")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/List_of_unit_offices'> {t("List of Unit Offices (Licence)")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/TradeLicenseInformation.jsp'> {t("View CE Information")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Application Registration(For New CE)")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Application For Change(For Existing CE)")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Application For Closure(For Existing CE)")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Renewal Application Registration(For Existing Defaulter CE)")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Application Search Status(For New CE)")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/LicenseDemand.jsp'> {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/LicenseUnpaidDemandSearchPrint.jsp'> {t("Print Unpaid Demand")}</a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Current_Circular_Notes_Guidelines'>  {t("Current Circular Notes & Guidelines")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Duplicate_Demand_Issuing_Offices'>  {t("Duplicate Demand Issuing Offices")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('655')}> {t("Fees & Charges")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Licence_download_form'> {t("Download Forms")}</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" target="_blank" href='https://www.kmcgov.in/KMCPortal/KMCPortalLicensePaymentAction.do'> {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" target="_blank" href='https://www.kmcgov.in/KMCPortal/jsp/LicenseDuplicateReceipt.jsp'> {t("Reprint e-Receipt cum CE")} </a>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Licence_Dept'>  {t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('656')}> {t("Online Survey for Industry Sector Key Performance Indicators under Vision, Mission Exercise")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('657')}> {t("Policy for use of pet Coke and Furnace Oil as a Fuel in the State of West Bengal")} </a>
                </li>
              </ul>
            </nav>
        </div>
        </div>
        <div className="col-lg-9">
                      <div className="container">
                      <div className="inner-title"><h3 className="fontSizeClass">{t("Licence Dept. - FAQs")}</h3>
                      </div>
                      <div id="accordion">
                          <div className="card">
                              <div className="card-header" id="heading1">
                              <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                              1. {t("What are the key activities of the department")}?
                              </button>
                              </div>
                              <div id="collapse1" className="collapse show" data-parent="#accordion">
                              <div className="card-body m-3 fontSizeClass">
                                  <p className="fontSizeClass">{t("The key activities of the department are")}:<br />
                                        a. {t("Issuance of new CE")}<br />
                                        b. {t("Renewal of CE")}<br />
                                        c. {t("Cancellation of demands and closing of CE")}<br />
                                        d. {t("Legal actions against the defaulting traders")}<br />
                                        e. {t("Issuance of CEs to hand cart & carraiges")}<br />
                                        f.  {t("Collection of renewal fees")}<br />
                                        g. {t("Other issues related to departmental activities")}.</p>
                              </div>
                              </div>
                          </div>
                          <div className="card">
                              <div className="card-header" id="heading2">
                              <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                              2. {t("Who needs to apply for a CE?")}
                              </button>
                              </div>
                              <div id="collapse2" className="collapse" data-parent="#accordion">
                              <div className="card-body m-3 fontSizeClass">
                              {t("Every person engaged in any trade or profession or calling within the KMC area is required to obtain a Certificate of Enlistment. Person intending to be engaged in any trade or profession or calling is also needed to obtain the CE from KMC")}.</div>                      
                              </div>
                          </div>
                          <div className="card">
                              <div className="card-header" id="heading3">
                              <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                              3. {t("How can one apply for a new CE?")}
                              </button>
                              </div>
                              <div id="collapse3" className="collapse" data-parent="#accordion">
                              <div className="card-body m-3 fontSizeClass">
                                  <p className="fontSizeClass">{t("Application forms can be obtained from any of the counters of the licence department or can be downloaded from website. Please refer to here for office address details")}.</p>
                              </div>
                              </div>
                          </div>
                          <div className="card">
                              <div className="card-header" id="heading4">
                              <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                              4. {t("What documents do I need to submit for issuance of a new CE?")}
                              </button>
                              </div>
                              <div id="collapse4" className="collapse" data-parent="#accordion">
                              <div className="card-body m-3 fontSizeClass">
                                  <p className="fontSizeClass">
                                  {t("Following documents are generally required")} :<br />
                                    a. {t("Application form duly filled up")}<br />
                                    b. {t("Proof of occupancy of place of business")}<br />
                                    c. {t("If a CE is sought on behalf of a company, kindly submit a copy of the Memorandum / Articles of Association, Declaration in Form No. 32. If CE is sought for Partnership firms, a copy of the Partnership deed needs to be submitted")}.<br />
                                    d. {t("Other specific requirements for specific trades: For further details please contact Licence department or go through the website")}.<br />
                                    e. {t("Relevant undertaking with seal & signature of proprietor / partners / director(s)")}<br />
                                  </p>
                              </div>
                          </div>
                          <div className="card">
                              <div className="card-header" id="heading5">
                              <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                              5. {t("What is the expected time frame for issuance of a new CE?")}
                              </button>
                              </div>
                              <div id="collapse5" className="collapse" data-parent="#accordion">
                              <div className="card-body m-3 fontSizeClass">
                                  <p className="fontSizeClass">{t("Seven days.It may vary depending on the merit of the cases")}.</p>
                                  </div>
                              </div>
                              </div>
  
                              <div className="card">
                              <div className="card-header" id="heading6">
                              <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                              6. {t("What should I do if there is an excessive delay in the issue of CE?")}
                              </button>
                              </div>
                              <div id="collapse6" className="collapse" data-parent="#accordion">
                              <div className="card-body m-3 fontSizeClass">
                              {t("In the unlikely event of such occurrence, you are advised to contact the licence officer or the deputy licence officer in charge of the concerned unit office for proper redressal. In case of inordinate delay in granting the CE or for any other inconvenience you may also approach the Chief Manager (Licence) at the KMC HO")}.</div>
                              </div>
  
                              <div className="card">
                              <div className="card-header" id="heading7">
  
                              <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                              7. {t("How can I renew my CE?")}
                              </button>
                              </div>
                              <div id="collapse7" className="collapse" data-parent="#accordion">
                              <div className="card-body m-3 fontSizeClass">
                              {t("A demand notice would be sent to you every year for renewal of CE mentioning the fees. Kindly deposit the fees at any of the offices as specified on the reverse of demand bill. The payment can be made either in cash or by cheque/demand draft/pay order in favor of Kolkata Municipal Corporation payable at Kolkata. On payment, the demand notice alogn with the payment receipt would be deemed as a CE")}.</div>
  
                              <div className="card">
                              <div className="card-header" id="heading8">
                              <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
                              8. {t("What should I do in case I do not receive the demand notice?")}
                              </button>
                              </div>
                              <div id="collapse8" className="collapse" data-parent="#accordion">
                              <div className="card-body m-3 fontSizeClass">
                              <p className="fontSizeClass">{t("In the unlikely event of such occurrence, kindly deposit the following document at the respective unit offices")}:<br />

                                    {t("Original of the last paid CE")}<br />
                                    {t("On submission of the above document a duplicate copy of demand notice would be issued")}.</p>        
                              </div>
                              </div>
                              </div>
  
                              <div className="card">
                              <div className="card-header" id="heading9">
                              <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
                              9. {t("What would be my liability in case I have defaulted in making payment for CE fees?")}
                              </button>
                              </div>
                              <div id="collapse9" className="collapse" data-parent="#accordion">
                              <div className="card-body m-3 fontSizeClass">
                                {t("The trader shall be liable to pay")} :<br />
                                a. {t("Two times of the fees as calculated from the current year schedule in case he has defaulted for one year")}.<br />
                                b. {t("Three times of the fees as calculated from the current year schedule in case he has defaulted for two year")}.<br />
                                c. {t("Four times of the fees as calculated from the current year schedule in case he has defaulted for three years or more")}.<br />
                                {t("In case of renewal payment, trader is liable to pay the arrear dues along with penalty for the defaulting year(s)")}.                              </div>
                              </div>
                              </div>
  
                              <div className="card">
                              <div className="card-header" id="heading10">
                              <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse10" aria-expanded="true" aria-controls="collapse10">
                              10. {t("What shall I do in case I need further information about CE?")}
                              </button>
                              </div>
                              <div id="collapse10" className="collapse" data-parent="#accordion">
                              <div className="card-body m-3 fontSizeClass">
                                  <p className="fontSizeClass">{t("In case you need further information please feel free to contact with our Help Desk Counter, Licence department, at the KMC head office. You may also call our helpdesk at 2286 1000 –Extension. Number. 2839 or at any unit offices of the Licence department. Please refer to here for office address details.")}</p>
                              </div>
                              </div>
  
                              <div className="card">
                              <div className="card-header" id="heading11">
                              
                              <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                              11. {t("How can I get information on my CE edited in cases such as change in location of business or errors in my CE?")}
                              </button>
                              </div>
                              <div id="collapse11" className="collapse" data-parent="#accordion">
                              <div className="card-body m-3 fontSizeClass">
                              <p className="fontSizeClass">{t("Kindly submit application in plain paper for effecting desired changes. KMC would hold an enquiry and on realization of necessary fees wherever applicable shall issue the CE")}.</p>
                              </div>
                              </div>
                              </div>
  
                              <div className="card">
                              <div className="card-header" id="heading12">
                              
                              <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse12" aria-expanded="true" aria-controls="collapse12">
                              12. {t("What shall I do if I want to close the CE?")}
                              </button>
                              </div>
                              <div id="collapse12" className="collapse" data-parent="#accordion">
                              <div className="card-body m-3 fontSizeClass">
                              {t("If you want to close the CE, please make up-to-date payment of fees for that financial year and apply for closure within the financial year enclosing current paid up CE. If it is a partnership firm, you also need to submit deed of dissolution. In case of a private limited company, necessary documents issued by R.O.C need to be produced, otherwise no effect will be given to the application for closure. If all documents are found in order, then after physical inspection by department, CE will be closed from that financial year and the applicant will get back the original CE with a remark 'closed' on it")}.</div>
                              </div>
                            </div>
                          </div>
                      </div> 
                  </div>
                  </div>
              </div>
                      </div>
                  </div>
        </div>
        </div>
        </div>
      </section>
    
      </>
  )
}

export default Licence_Faq