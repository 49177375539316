import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Basic_statestics = () => {
  const [MoreTwoService, setMoreTwoService] = React.useState(false);
  const [ProjectService, setProjectService] = React.useState(false);

  const MoreTwoServiceToggle = () => {
    setMoreTwoService(!MoreTwoService);
  };
  const ProjectServiceToggle = () => {
    setProjectService(!ProjectService);
  };
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
         {/* Slider */}
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
  
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu">           
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/About_Kolkata'> {t("About Kolkata")} </Link> </li>
                <li className="nav-item"><Link className="nav-link act" to='/map'> {t("Map")} </Link></li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/History_Of_Kolkata'> {t("History")}</Link></li>
                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Places of Interest")}<i className="bi bi-chevron-down" /></button>
                    <div id="demo" className="collapse">                        
                        <Link className="nav-link fontSizeClass" to='/Art_Galleries'>1. {t("Art Galleries")}</Link>
                        <Link className="nav-link fontSizeClass active1" to='/Amusement_Parks'>2. {t("Amusement Park")} </Link>
                        <Link className="nav-link fontSizeClass" to='/Architecture'> 3. {t("Architecture")} </Link>
                        <Link className="nav-link fontSizeClass" to='/Other_Noteable_place'> 4. {t("Other Notable Places")} </Link>
                    </div>
                </li>
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Basic_statestics'> {t("Basic Statistics")} </Link> </li>  
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/UEVRP_Home'> {t("UEVRP")} </Link></li>   
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2"><h3 className="fontSizeClass"> {t("Basic Statistics of Kolkata")} </h3></div>
              <div className="col-lg-12 about-head1 ">
                <p className="fontSizeClass"> {t("Calcutta/Kolkata stands on the Eastern Bank of River Ganga. The tail end of river Ganga flows by the side of Kolkata before it reaches Bay of Bengal about 180 Km. down stream from Kolkata. The Vital Statistics of a capital city, called by the British Raj as The Jewel of the East, and remained its capital till 1911, Kolkata is now the capital of West Bengal a state of India")}. </p>
                
                
                <div className="more-but basic_statetics">			
                <div className="card-header responsiveheader nav-link" id="heading5" style={{backgroundColor:"#fff"}}>
                    <button className="btn btn-primary mt-2" onClick={MoreTwoServiceToggle} data-toggle="collapse" data-target="#MoreTwoService" aria-expanded="false" aria-controls="MoreTwoService"  >
                    {t("More")} <i className="bi bi-chevron-down" /></button>
                  </div><br />
                  <div className="collapse collap-more " id="MoreTwoService">
                    <div className="card">
                      <p className="fontSizeClass"> <strong> {t("Location")} : </strong> {t("Kolkata is situated at the longitude of")} 88º 30'E - 22º 33' N.
                        <strong> {t("Altitude")} : </strong> 9m (30’). {t("From sea level it is 6.4 meters")} (20 ft). {t("(Mount Everest 8848m.)")}</p>					
                      <p className="fontSizeClass"><strong> {t("Climate")} : </strong> {t("Maximum temperature rises during the summer months of May-June up to 24 - 42º C and the minimum temperature falls during winter months of December - January up to 8 - 26ºC on an average. Climate is humid varying from 85 - 65 during the summer & exceeding by pleasant in winter. From June to September average rainfall in Kolkata is 158 cm")}.</p>
                      <p className="fontSizeClass"><strong> {t("AREA Old Kolkata")} : </strong> {t("Sutanuti - Chitpur, Baghbazar, Sobhabazar & Hatkhola")}. <br /> 
                        {t("Kolkata - Dharmatala, Bowbazar, Simla, Janbazar.")} <br /> {t("Gobindapur - Hastings, Maidan & Bhowanipur")}				
                      </p>
                      <p className="fontSizeClass">
                        <strong> {t("New Kolkata")} </strong> {t("North - Sinthi, Cossipore & Gughudanga")} <br /> {t("South - Tollygunge, Khidderpore & Behala")} <br /> 
                        {t("East - Salt Lake , Beliaghata & Topsia.")} <br />  {t("West - Hooghly river")}.			
                      </p>
                      <p className="fontSizeClass"><strong> {t("Greater Kolkata")} :</strong> {t("Baruipur to Bansberia & Kalyani to Budge Budge")}.</p>
                      <p className="fontSizeClass">				
                        <strong>{t("Area in kilometers")} : </strong> {t("1480 sq. km. (London 1580 sq. km.) 205 sq. km. is within Corporation Area")}.<br /> 
                        <strong>{t("Population")} : </strong> {t("A growing population of 45,80,544 according to 2001 Census. (Mumbai 14.8 million.)")}<br /> 
                        <strong>{t("Density of Population")} : </strong> 24.760/ sq. km.<br /> 
                        <strong>{t("Ratio of Population")} : </strong> {t("Male - 1000; Female - 956")};<br /> 
                        <strong>{t("Literacy Rate")} : </strong> 81.31% <br /> 
                        <strong>{t("Mother Tongues")} : </strong> {t("Bengali 55%, Hindi 20%, English 10%, Others 15%")} <br /> 
                        <strong>{t("Position")} : </strong> {t("7th biggest city of India in area and population")}.	 <br /> 			
                      </p>
                    </div>
                  </div>
                </div>			
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Basic_statestics