import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UEVRP_Home = () => {
    const increaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
          var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
          var fontSize = parseFloat(style); 
          el[i].style.fontSize = (fontSize + 1) + 'px';
        }
    };
    const decreaseFontSizeBy1px =() => {
      var el = document.getElementsByClassName('fontSizeClass');
      for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize - 1) + 'px'; 
      }
    };
    const { t, i18n, ready } = useTranslation();
  return (
    <>
  {/* Slider */}
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
      
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
      <div className="col-lg-3 ">
          <div className="site-menu">
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link active1" to="/UEVRP_Home"> {t("UEVRP Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/UEVRP_Status"> {t("UEVRP Status")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Earthquake_Leaflet"> {t("Earthquake Leaflet")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/EQ_Pocket_Guide"> {t("EQ Pocket Guide")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Disaster_Mgmt">{t("School Safety")}</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9 pb-0 pt-0">
          <div className="row">
           <div class="container">
            <div className="inner-title">
              <h3> {t("Disaster Risk Management Programme")} </h3>
            </div>
            <div className="col-lg-12 about-head1">
              <p>
                <strong>
                  
                  {t("URBAN EARTHQUAKE VULNERABILITY REDUCTION PROJECT (A sub-component of DRMP) Ministry of Home Affairs Government of India United Nations Development Programme, India 2002-2008")}
                </strong>
              </p>
              <p>
                {t("Sustainable disaster risk reduction in some of the most multi-hazard prone districts in selected states")}
              </p>
            </div>
          </div>
        </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse1"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  {t("Multi Hazard Prone District Areas")}
                  <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse1"
                className="collapse"
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/drmp1.gif"
                    className="img-fluid"
                    alt="alt-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse2"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  {t("Implementation of the DRM Programme")}
                  <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse2"
                className="collapse"
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/drmp2.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                  <p>
                    
                    <strong> Phase I:2002-2004 [28] </strong>
                    – Orissa[12] <br />
                    – Gujarat[11] <br />
                    – Bihar[5] <br />
                  </p>
                  <p>
                    Phase II: 2003-2007 [97] <br />
                    – Uttaranchal[8] <br />
                    – Bihar[9] <br />
                    – West Bengal[10] <br />
                    – Assam[12] <br />
                    – Meghalaya[7] <br />
                    – Sikkim[4] <br />
                    – Uttar Pradesh[13] <br />
                    – New Delhi[9] <br />
                    – Maharashtra[14] <br />
                    – Tamilnadu[6] <br />
                    – Orissa[2] <br />– Gujarat[3]
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse3"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  {t("Urban Earthquake Vulnerability reduction")}.
                  <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse3"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/drmp3.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                  <p> Sub-Programme- Disaster Risk Management Programme </p>
                  <p>Address: Bratacharigram, Joka South 24 Parganas.</p>
                  <ul>
                    <li>
                      
                      <strong>The Great Indian Earthquake </strong>
                    </li>
                    <li>1897 Assam Earthquake: M8.7</li>
                    <li>1905 Kangra Earthquake: M8.6</li>
                    <li>1934 Bihar-Nepal Earthquake: M8.4</li>
                    <li>1950 Assam Earthquake: M8.7</li>
                  </ul>
                  <ul>
                    <li>
                      
                      <strong>Recent Earthquakes </strong>
                    </li>
                    <li>•Koyna (1967): M6.5</li>
                    <li>Bihar-Nepal (1988): M6.6</li>
                    <li>Imphal (1988): M7.2</li>
                    <li>Uttarkashi (1991): M6.6</li>
                    <li>Killari, Latur (1993): M6.4</li>
                    <li>Jabalpur (1997): M6.0</li>
                    <li>Chamoli (1999): M6.5</li>
                    <li>Bhuj (2001): M 6.9</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse4"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  Seismic Zone Map <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse4"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/6-copy.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                  <p> Sub-Programme- Disaster Risk Management Programme </p>
                  <p>Address: Bratacharigram, Joka South 24 Parganas.</p>
                  <ul>
                    <li>
                      
                      <strong>The Great Indian Earthquake </strong>
                    </li>
                    <li>1897 Assam Earthquake: M8.7</li>
                    <li>1905 Kangra Earthquake: M8.6</li>
                    <li>1934 Bihar-Nepal Earthquake: M8.4</li>
                    <li>1950 Assam Earthquake: M8.7</li>
                  </ul>
                  <ul>
                    <li>
                      
                      <strong>Recent Earthquakes </strong>
                    </li>
                    <li>•Koyna (1967): M6.5</li>
                    <li>Bihar-Nepal (1988): M6.6</li>
                    <li>Imphal (1988): M7.2</li>
                    <li>Uttarkashi (1991): M6.6</li>
                    <li>Killari, Latur (1993): M6.4</li>
                    <li>Jabalpur (1997): M6.0</li>
                    <li>Chamoli (1999): M6.5</li>
                    <li>Bhuj (2001): M 6.9</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse5"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  Seismic Zone Map <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse5"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/6-copy.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                  <p>
                    
                    • Four zones in India: II to V – V is most severe with
                    probability of intensity more than XI – Total No. of Zones
                    reduced from five (I to V) to four II to V) neglecting
                    effects of very low risk of Zone-I
                  </p>
                  <p>
                    
                    Based on best available information at the time <br />•
                    Revised from time to time especially after earthquakes in
                    zones considered aseismic. (Lattur, Jabalpur)
                  </p>
                  <p>
                    
                    <strong>
                      
                      Strategies for Urban Earthquake Vulnerability Reduction
                    </strong>
                    <br />
                    1. Awareness generation <br />
                    • Communicating the risks/codal provisions/technologies
                    among various stakeholders <br />
                    2. Development of Earthquake preparedness plans <br />
                    3. Development of a techno-legal framework <br />
                    In new constructions-stop increasing the risk <br />
                    • Review and amendment of codes , bye-laws <br />
                    • Review of enforcement mechanisms. <br />
                    In existing constructions-decrease unacceptable risk
                    Mandatory retrofitting <br />
                    4. Training and Capacity building <br />
                    5. Networking knowledge of best practices
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse6"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  Awareness Generation <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse6"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/6-copy.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                  <p>
                    
                    • Development of city-specific Awareness generation plans
                    <br />
                    • Identifying resource institutions <br />
                    • Developing Information Education Communication (IEC)
                    materials including manuals, guidelines for safe housing
                    options in the local language.
                    <br />
                    • Sensitization of ULBs, NGOs, Resident welfare
                    associations, Students etc.
                    <br />
                    • Among practicing* Architects, Builders, Contractors,
                    Designers, Engineers etc.
                    <br />
                    • Use of mass media <br />
                    • Awareness required for the community at large on <br />
                    – Seismic hazard in their region <br />
                    – The codal provisions-in simplified vernacular language
                    <br />
                    – Cost effective disaster resistant technologies <br />
                    – Earthquake resistant features <br />
                    – Simple retrofitting measures <br />
                    – Costs and benefits of seismic strengthening <br />–
                    Possible damage scenario in their environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse7"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  Awareness generation …for policy makers
                  <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse7"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/drmp5.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                  <p>
                    
                    Capacity building of local authorities to acquire knowledge
                    and resources <br />
                    • Decentralizing authority for disaster preparedness and
                    management <br />
                    • Shifting focus from Response to Pro-active mitigation.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse8"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  Development of Earthquake preparedness plans
                  <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse8"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <p>
                    
                    • Identification of nodal agencies <br />
                    • Sensitisation of Nagarik Samitis/ RW associations <br />
                    • Meeting with line departments <br />
                    • Nagarik Committee Meeting <br />
                    • General Meeting with public of each ward/Mohalla <br />
                    • Selection of two volunteers from the ward <br />
                    • Orientation and training of volunteers <br />
                    • Mapping by community with assistance of volunteers <br />
                    – Social Mapping,Resource Mapping,Hazard Mapping,Need
                    Assessment <br />
                    • Development of preparedness plan –ward, city level <br />
                    • Formation of Task forces <br />• Development of an
                    Incident Command System
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse9"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  Techno-legal regime -For new constructions
                  <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse9"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <p>
                    
                    • Retrofit to decrease unacceptable risk <br />
                    • Millions of unsafe buildings in high risk zones <br />
                    • Many collapse in monsoons. <br />
                    • Retrofitting for individual houses-
                    <br />
                    – By creating awareness among community <br />
                    – Demonstrating retrofitting in various construction systems
                    <br />
                    – Creating capacities
                    <br />
                    • Financial institutions to fund retrofitting
                    <br />• Legislation for mandatory retrofitting
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse10"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  Capacity Building <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse10"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <p>
                    
                    • Of Government engineers,practicing
                    Architects,engineers,resource institutions <br />
                    • On codal provisions,safe construction practices,
                    retrofitting measures etc. <br />
                    • Of Development authorities, ULBs, Resource institutions,
                    Resident’s associations <br />
                    • On awareness generation techniques,development of
                    Earthquake Preparedness and response plans
                    <br />• Of policy makers –on regional vulnerability,
                    development of the techno-legal regime etc .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse11"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  Networking <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse11"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <p>
                    
                    •Developing a web-based portal for knowledge-sharing,
                    inter-city cooperation on risk reduction measures <br />
                    •Forum for city representatives,national experts/advisors to
                    share experiences and learning <br />
                    •Development of cities disaster resources database- and
                    linkages to the IDRN <br />
                    •Documentation and dissemination of best practices for wider
                    circulation <br />
                    •Learning from other’s experiences..
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse12"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  The learning from past experiences….what we do
                  <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse12"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <p>
                    
                    <strong>
                      
                      The learning from past experiences….what we do Normal Long
                      Term Human Response to Earthquakes (Key, 1988)
                    </strong>
                  </p>
                  <div className="mayor-table2 mayor-table2a ">
                    <table className="table table-bordered">
                      <thead>
                        <tr className="table-warning table-bor-2">
                          <th scope="col"> Stage </th>
                          <th scope="col"> Time </th>
                          <th scope="col"> Event </th>
                          <th colSpan={2} scope="col">
                            
                            Reaction
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td scope="row"> 1 </td>
                          <td> 0-1min </td>
                          <td> Major EQ</td>
                          <td> Positive </td>
                          <td> Negative </td>
                        </tr>
                        <tr>
                          <td scope="row"> 2 </td>
                          <td> 1min to 1week </td>
                          <td> Aftershocks </td>
                          <td> Rescue and Survival </td>
                          <td> Fear </td>
                        </tr>
                        <tr>
                          <td scope="row"> 3 </td>
                          <td> 1week to 1month </td>
                          <td> Diminishing Aftershocks </td>
                          <td> Short Term repairs </td>
                          <td>
                            
                            Allocation of blame to
                            builders,designers,officials,ect
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 4 </td>
                          <td> 1month to 1year </td>
                          <td> - </td>
                          <td>
                            
                            Long Term repairs,Action for higher standards
                          </td>
                          <td> - </td>
                        </tr>
                        <tr>
                          <td scope="row"> 5 </td>
                          <td> 1year to 10 years </td>
                          <td> - </td>
                          <td> - </td>
                          <td> Diminishing interest </td>
                        </tr>
                        <tr>
                          <td scope="row"> 6 </td>
                          <td> 10yrs to next EQ </td>
                          <td> - </td>
                          <td> - </td>
                          <td>
                            
                            Reluctance to meet costs of seismic
                            provisions,ect.,lncreasing non-compliance with
                            regulations
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 7 </td>
                          <td> The next EQ </td>
                          <td> Major EQ </td>
                          <td> Repeat stage 1-7 </td>
                          <td> - </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse13"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  The Himalayas...Among the most seismic regions on earth..
                  <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse13"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/drmp6.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse14"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  The Himalayas...Among the most seismic regions on earth..
                  <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse14"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/drmp6.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse15"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  A scene from Ahmedabad (Zone III)-same as your city
                  <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse15"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/drmp7.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse16"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  Five moderate earthquakes of around 6.5 magnitude...
                  <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse16"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/drmp8.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                  <p>
                    “Five moderate earthquakes of around 6.5 magnitude have
                    occurred in India since 1988; this leaves the country with
                    no choice but to develop strong initiatives in earthquake
                    disaster preparedness, mitigation and management.”
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse17"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  Five moderate earthquakes of around 6.5 magnitude...
                  <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse17"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/drmp8.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                  <p>
                    “Five moderate earthquakes of around 6.5 magnitude have
                    occurred in India since 1988; this leaves the country with
                    no choice but to develop strong initiatives in earthquake
                    disaster preparedness, mitigation and management.”
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default UEVRP_Home