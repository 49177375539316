import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Councillors  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
<>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
<img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Contact_Us_Home'> {t("Contact Us Home")} </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Important_Contacts' target="_blank"> {t("Important Contacts")} </Link>
                 </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/MMIC'> {t("MMIC")}</Link></li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Controlling_Officers'> {t("Controlling Officers")}</Link></li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Councillors'> {t("Councillors")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Borough_Committee'> {t("Borough Committee")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Borough_Committee'> {t("Borough Offices")}</Link>
                </li>
                <li className="nav-item">                
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCWard.jsp'> {t("Know Your Ward")}</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Councillors")} </h3>
              </div>
              <div className="health-dept-table mayor-table m-ta-2">
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"
                    >
                      <td width="10%" className="text-white tble-brder-left">
                      {t("Ward No")}.	
                      </td>
                      <td width="30%" className="text-white">
                      {t("Name")}	
                      </td>
                      <td width="50%" className="text-white tble-brder-right">
                      {t("Office Address")}	
                      </td>
                      <td width="10%" className="text-white tble-brder-right">
                      {t("Office Phone No")}.
                      </td>                      
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      1
                      </td>
                      <td width="10%" align="center">
                      SHRI KARTICK CHANDRA MANNA	
                      </td>
                      <td width="37%" align="center">
                      58/2 Kashinath Dutta Road, Cossipore, Kolkata- 700036		
                      </td>
                      <td width="10%" align="center">
                      -		
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      2	
                      </td>
                      <td width="10%" align="center">
                      DR. KAKALI SEN		
                      </td>
                      <td width="37%" align="center">
                      5/27, Dumdum road , Kolkata -700030		
                      </td>
                      <td width="37%" align="center">
                     -	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      3	
                      </td>
                      <td width="10%" align="center">
                      SMT.DEBIKA CHAKRABORTY		
                      </td>
                      <td width="37%" align="center">
                      21/ H /54, Paikpara R.M. Road , Kolkata -700037	                      </td>
                      <td width="10%" align="center">
                      -	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      4	
                      </td>
                      <td width="10%" align="center">
                      -	
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                      <td width="10%" align="center">
                      -	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      5	
                      </td>
                      <td width="10%" align="center">
                      SHRI TARUN SAHA		                      </td>
                      <td width="37%" align="center">
                      67B Khelat Babu Lane, Kolkata-700037		                      </td>
                      <td width="10%" align="center">
                      -	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      6	
                      </td>
                      <td width="10%" align="center">
                      SMT.SUMAN SINGH		                      </td>
                      <td width="37%" align="center">
                      3/1B Turner Road, Cossipore, Kolkata-700002		
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      7	
                      </td>
                      <td width="10%" align="center">
                      SHRI BAPI GHOSH		                      </td>
                      <td width="37%" align="center">
                      47A, Bagbazar Street, Kolkata-700003		
                      </td>
                      <td width="10%" align="center">
                      -	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      8		
                      </td>
                      <td width="10%" align="center">
                      SMT.POOJA PANJA			                      </td>
                      <td width="37%" align="center">
                      250, Chittaranjan Avenue, Kolkata-700003	                      </td>
                      <td width="37%" align="center">
                        -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      9	
                      </td>
                      <td width="10%" align="center">
                      SMT.MITALI SAHA			                      </td>
                      <td width="37%" align="center">
                      13/A, MadanMohantala Street, Hatkhola, Kolkata-700005			
                      </td>
                      <td width="10%" align="center">
                      -		
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      10
                      </td>
                      <td width="10%" align="center">
                      SHRI SUBRATA BANERJEE	</td>
                      <td width="37%" align="center">
                      74/1B/F2, Shyampukur Street, Kolkata-700005			
                      </td>
                      <td width="10%" align="center">
                      -		
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      11	
                      </td>
                      <td width="10%" align="center">
                      SHRI ATIN GHOSH			                      </td>
                      <td width="37%" align="center">
                      17B, Nalin Sarkar Street, Kolkata-700004	ty		
                      </td>
                      <td width="37%" align="center">
                        -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      12	
                      </td>
                      <td width="10%" align="center">
                      DR. MINAKSHI GANGOPADHYAY			                      </td>
                      <td width="37%" align="center">
                      FI- 11- 12 , 4th Floor 'Arvind Tower ' , 242/1B , A.P.C.Road,Kolkata-700004			
                      </td>
                      <td width="10%" align="center">
                      -		
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      13	
                      </td>
                      <td width="10%" align="center">
                      SHRI ANINDYA KISHOR ROUTH			                      </td>
                      <td width="37%" align="center">
                      6,Lalit Mitra Lane, Kolkata-700004			
                      </td>
                      <td width="10%" align="center">
                      -		
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      15	
                      </td>
                      <td width="10%" align="center">
                      SHRI AMAL CHAKRABORTY (BHOLA)		</td>
                      <td width="37%" align="center">
                      26, Harish Neogi Road, P.O .: Ultadanga, Kolkata-700067			
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      15	
                      </td>
                      <td width="10%" align="center">
                      SMT.SHUKLA BHORE	</td>
                      <td width="37%" align="center">
                      48B, Karbala Tank Lane, P.O. : Beadon Street, P.S. : Burtolla,Kolkata-700006			
                      </td>
                      <td width="10%" align="center">
                      -	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      16	
                      </td>
                      <td width="10%" align="center">
                      SHRI SWAPAN KUMAR DAS		
                      </td>
                      <td width="37%" align="center">
                      12, Karbala Tank Lane , Kolkata -700006			
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      17	
                      </td>
                      <td width="10%" align="center">
                      SHRI MOHAN KUMAR GUPTA (MONA)			
                      </td>
                      <td width="37%" align="center">
                      1,Brindabon Bose Lane , Kolkata -700006				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      18	
                      </td>
                      <td width="10%" align="center">
                      SMT. SUNANDA SARKAR			
                      </td>
                      <td width="37%" align="center">
                      6, Masjid Bari Street , Kolkata -700004				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      19	
                      </td>
                      <td width="10%" align="center">
                      SMT. SIKHA SAHA		
                      </td>
                      <td width="37%" align="center">
                      B/17/H/4, Beniatola Street, Kolkata -700005				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      20	
                      </td>
                      <td width="10%" align="center">
                      SHRI VIJAY UPADHYAY		
                      </td>
                      <td width="37%" align="center">
                      48a, Muktaram Babu Street, Kolkata -700007				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      21	
                      </td>
                      <td width="10%" align="center">
                      SMT.MIRA HAZRA			
                      </td>
                      <td width="37%" align="center">
                      45a, Adyasraddha Ghat Road , Jorabagan , Kolkata-700006				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      22	
                      </td>
                      <td width="10%" align="center">
                      SMT. MEENA DEVI PUROHIT			
                      </td>
                      <td width="37%" align="center">
                      42, Kali Krishna Tagore Street , Kolkata -700007				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      23	
                      </td>
                      <td width="10%" align="center">
                      SHRI VIJAY OJHA			
                      </td>
                      <td width="37%" align="center">
                      1, Tansook Lane, Posta, Kolkata -700007				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      24	
                      </td>
                      <td width="10%" align="center">
                      SMT.ELLORA SAHA			
                      </td>
                      <td width="37%" align="center">
                      1,Pathuria Ghata Lane, Kolkata -700006				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      25	
                      </td>
                      <td width="10%" align="center">
                      SHRI RAJESH KUMAR SINHA			
                      </td>
                      <td width="37%" align="center">
                      13, Panchu Dhobani Gullee, kolkata -700007				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      26	
                      </td>
                      <td width="10%" align="center">
                      SHRI TARAK NATH CHATTOPADHYAY			
                      </td>
                      <td width="37%" align="center">
                      7/5, Ramesh Dutta Street, Kolkata - 700006				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      27	
                      </td>
                      <td width="10%" align="center">
                      SMT.MINAKSHI GUPTA		
                      </td>
                      <td width="37%" align="center">
                      77/S, Kailash Bose Street, Kolkata -700006				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      28	
                      </td>
                      <td width="10%" align="center">
                      SHRI AYAN CHAKRABORTY			
                      </td>
                      <td width="37%" align="center">
                      13, Ram Mohan Roy Road, Kolkata -700009				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                        29                      </td>
                      <td width="10%" align="center">
                      IQBAL AHMED			
                      </td>
                      <td width="37%" align="center">
                      37/H/4, Canal East Road, Narkeldanga ,Kolkata - 700011				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      30	
                      </td>
                      <td width="10%" align="center">
                      SMT.PAPIYA GHOSH (BISWAS)			
                      </td>
                      <td width="37%" align="center">
                      4/A, Sir Gurudas Road, Kolkata - 700054				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      31	
                      </td>
                      <td width="10%" align="center">
                      SHRI PARESH PAUL			
                      </td>
                      <td width="37%" align="center">
                      Kankurgachia 2nd Lane , C.I.T Sch- III, BL- 7, Flat - 23 , Kolkata - 700054				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      32	
                      </td>
                      <td width="10%" align="center">
                      SHRI SANTI RANJAN KUNDU		
                      </td>
                      <td width="37%" align="center">
                      103, Ultadanga Main Road, B.R.S.-IV, Block 2B, Flat- 36, kolkata- 700067				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      33	
                      </td>
                      <td width="10%" align="center">
                      SHRI CHINU BISWAS			
                      </td>
                      <td width="37%" align="center">
                      76/H/5, Dr . S. Ch . Banerjee Road, Kolkata - 700010	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      34	
                      </td>
                      <td width="10%" align="center">
                      SMT.ALOKANANDA DAS			
                      </td>
                      <td width="37%" align="center">
                      26/A,Chaul Patty Road, P.S. : Beliaghata Kolkata-700010	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      35	
                      </td>
                      <td width="10%" align="center">
                      SHRI ASHUTOSH DAS	                      </td>
                      <td width="37%" align="center">
                      32/H , Haramohan Ghosh Lane , P.S. : Baliaghata Kolkata - 700085	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      36	
                      </td>
                      <td width="10%" align="center">
                      SHRI SACHIN KUMAR SINGH			
                      </td>
                      <td width="37%" align="center">
                      7P, Baliaghata Road , Kolkata - 700015	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      37	
                      </td>
                      <td width="10%" align="center">
                      SMT.SOMA CHAUDHURI			
                      </td>
                      <td width="37%" align="center">
                      34A, Raja Rammohan Roy Sarani, Kolkata- 700009	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      38	
                      </td>
                      <td width="10%" align="center">
                      SMT.SADHANA BOSE			
                      </td>
                      <td width="37%" align="center">
                      26A Kalidas Singha Lane,( 4th Floor), Kolkata-700009	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      39	
                      </td>
                      <td width="10%" align="center">
                      MD. JASIMUDDIN			
                      </td>
                      <td width="37%" align="center">
                      95, M. G. Road, (3rd Floor), Kolkata-700007	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      40	
                      </td>
                      <td width="10%" align="center">
                      SMT.SUPARNA DUTTA	                      </td>
                      <td width="37%" align="center">
                      9/1, Sitaram Ghose Street , P.O. : Rahamohan Sarani , Kolkata - 700009	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      41	
                      </td>
                      <td width="10%" align="center">
                      SMT.REITA CHOWDHURY	                      </td>
                      <td width="37%" align="center">
                      33B, Madan Mohan Bburman Street, Kolkata - 700007	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      42	
                      </td>
                      <td width="10%" align="center">
                      SHRI MAHESH KUMAR SHARMA	                      </td>
                      <td width="37%" align="center">
                      115, Cotton Street , Kolkata -700007	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      43	
                      </td>
                      <td width="10%" align="center">
                      AYESHA KANIZ	                      </td>
                      <td width="37%" align="center">
                      30, Ram Kamal San , Kolkata -700073	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      44	
                      </td>
                      <td width="10%" align="center">
                      REHANA KHATOON	                      </td>
                      <td width="37%" align="center">
                      8/1, Nilmadhab Sen Lane , Kolkata -700073	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      45	
                      </td>
                      <td width="10%" align="center">
                      SHRI SANTOSH KUMAR PATHAK	                      </td>
                      <td width="37%" align="center">
                      52, Garstin Place , Near Banksall Court Kolkata -700001	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      46	
                      </td>
                      <td width="10%" align="center">
                      SMT.PRIYANKA SAHA			
                      </td>
                      <td width="37%" align="center">
                      75A, Sambhu Babu Lane, Kolkata-700014	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      47	
                      </td>
                      <td width="10%" align="center">
                      SHRI BIMAL SINGH	                      </td>
                      <td width="37%" align="center">
                      Janakalyan Jalpara Tiljala, 24 PGS (S)	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      48	
                      </td>
                      <td width="10%" align="center">
                      SHRI BISWARUP DEY	                      </td>
                      <td width="37%" align="center">
                      152/B, Raja Rammohan Roy Sarani, Kolkata -700009	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      49	
                      </td>
                      <td width="10%" align="center">
                      SMT.MONALISA BANERJEE	                      </td>
                      <td width="37%" align="center">
                      13, Acharya Prafulla Chandra Road , Kolkata -700009	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      50	
                      </td>
                      <td width="10%" align="center">
                      SHRI SAJAL GHOSH	                      </td>
                      <td width="37%" align="center">
                      11, Huzurimal Lane , Kolkata -700014	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      51	
                      </td>
                      <td width="10%" align="center">
                      SHRI INDRANIL KUMAR	                      </td>
                      <td width="37%" align="center">
                      2/1, C Neogi Pukur Bye Lane, Kolkata -700014	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      52	
                      </td>
                      <td width="10%" align="center">
                      SMT.SOHINI MUKHERJEE	                      </td>
                      <td width="37%" align="center">
                      57/A, Taltala Library Road , Kolkata-700014	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      53	
                      </td>
                      <td width="10%" align="center">
                      SMT.INDRANI SAHA BANERJEE	                      </td>
                      <td width="37%" align="center">
                      46J Surendra Nath Banerjee Road , Kolkata- 700014	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      54	
                      </td>
                      <td width="10%" align="center">
                      AMIRUDDIN                      </td>
                      <td width="37%" align="center">
                      21, Tanti Bagan Road, Kolkata- 700014	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      55	
                      </td>
                      <td width="10%" align="center">
                      SMT.SABITA RANI DAS			
                      </td>
                      <td width="37%" align="center">
                      P- 109, Nani Gopal Roy Chowdhury Avenue, (2nd Floor), CIT Road, P.O. & P.S. Entally , Kolkata- 700014	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      56	
                      </td>
                      <td width="10%" align="center">
                      SHRI SWAPAN SAMADDAR	                      </td>
                      <td width="37%" align="center">
                      13A, Sir Gurudas Road, Kolkata--700054	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      57	
                      </td>
                      <td width="10%" align="center">
                      SHRI JIBAN SAHA	                      </td>
                      <td width="37%" align="center">
                      101/108, Metropolitan Co-Op Housing Society, Sector-B, Kolkata-700105	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      58	
                      </td>
                      <td width="10%" align="center">
                      SHRI SANDIPAN SAHA	                      </td>
                      <td width="37%" align="center">
                      6A Sooker Sarker Lane, Kolkata-700016	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      59	
                      </td>
                      <td width="10%" align="center">
                      SMT.JALY BOSE	                      </td>
                      <td width="37%" align="center">
                      14/A, Mahendra Roy Lane, Kolkata-700046	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      60	
                      </td>
                      <td width="10%" align="center">
                      KAISER JAMIL	                      </td>
                      <td width="37%" align="center">
                      40, Linton Street, Kolkata-700014	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      32	
                      </td>
                      <td width="10%" align="center">
                      MANZAR IQBAL	                      </td>
                      <td width="37%" align="center">
                      11, Bedford Lane , Kolkata- 700016	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      62	
                      </td>
                      <td width="10%" align="center">
                      SANA AHMED	                      </td>
                      <td width="37%" align="center">
                      94, Muzaffar Ahamed Street, Kolkata -700014	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      63	
                      </td>
                      <td width="10%" align="center">
                      SMT.SUSMITA BHATTACHARYA (CHATTERJEE)	                      </td>
                      <td width="37%" align="center">
                      22, Creek Row, Kolkata - 700014	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      64	
                      </td>
                      <td width="10%" align="center">
                      SAMMI JAHAN BEGUM	                      </td>
                      <td width="37%" align="center">
                      A/12, Dr. Sundari Mohan Avenuue , Kolkata -700014	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      65	
                      </td>
                      <td width="10%" align="center">
                      SMT.NIBEDITA SHARMA	                      </td>
                      <td width="37%" align="center">
                      Flat LA-17, Kustia Road, Govt. Housing Estate, P.O. & P.S. : Tiljala, Kolkata -700039	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      66	
                      </td>
                      <td width="10%" align="center">
                      AHMED FAIZ KHAN	                      </td>
                      <td width="37%" align="center">
                      2,Golam Jilani Khan Road , Kolkata- 700039	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      67	
                      </td>
                      <td width="10%" align="center">
                      SHRI BIJAN LAL MUKHERJEE	                      </td>
                      <td width="37%" align="center">
                      8/1/1A, Dharmatala Road , P.S. : Kasba, Kolkata- 700042	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      68	
                      </td>
                      <td width="10%" align="center">
                      SMT.SUDARSHANA MUKHERJEE	                      </td>
                      <td width="37%" align="center">
                      49/3F, Ballygunge Place, P.S. : Gariahat , P.O. : Ballygunge Kolkata- 700019	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      69	
                      </td>
                      <td width="10%" align="center">
                      SHRI DILIP BOSE	                      </td>
                      <td width="37%" align="center">
                      9/A, Chakraberia Lane , P.S. : Ballygunge , Kolkata-700020	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      70	
                      </td>
                      <td width="10%" align="center">
                      SHRI ASHIM KUMAR BOSE (BABAI)	                      </td>
                      <td width="37%" align="center">
                      35A, Dr. Rajendra Road, Kolkata -700020	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      71	
                      </td>
                      <td width="10%" align="center">
                      SMT.PAPIA SINGH	                      </td>
                      <td width="37%" align="center">
                      58/B, Turf Road, PS. Kalighat, Kolkata -700025	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      72	
                      </td>
                      <td width="10%" align="center">
                      SHRI SANDIP RANJAN BAKSHI	                      </td>
                      <td width="37%" align="center">
                      5A, Bakul Bagan Road , Kolkata - 700025	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      73	
                      </td>
                      <td width="10%" align="center">
                      SMT.KAJARI BANERJEE	                      </td>
                      <td width="37%" align="center">
                      30B, Harish Chatterjee Street , Kolkata- 700026	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      74	
                      </td>
                      <td width="10%" align="center">
                      SMT.DEBALINA BISWAS			
                      </td>
                      <td width="37%" align="center">
                      40A, Branu Field Row , P.S. : Ekbalpore Kolkata- 700027				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      75	
                      </td>
                      <td width="10%" align="center">
                      NEZAMUDDIN SHAMS	                      </td>
                      <td width="37%" align="center">
                      32/1A, Mominpur Road , Kolkata- 700023					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      76	
                      </td>
                      <td width="10%" align="center">
                      SHRI SASTI DAS	                      </td>
                      <td width="37%" align="center">
                      10/1, Gopal Doctor Road , Kolkata- 700023	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      77	
                      </td>
                      <td width="10%" align="center">
                      SHAMIMA REHAN KHAN				
                      </td>
                      <td width="37%" align="center">
                      7, Dr Sudhir Bose Road , Kolkata - 700023	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      78	
                      </td>
                      <td width="10%" align="center">
                      SMT.SOMA DAS				
                      </td>
                      <td width="37%" align="center">
                      4/3H/1,BK Road , Kolkata - 700023				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      79	
                      </td>
                      <td width="10%" align="center">
                      SHRI RAM PYARE RAM				
                      </td>
                      <td width="37%" align="center">
                      P61A/1,Karl Marx Sarani, Kolkata - 700043					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      80	
                      </td>
                      <td width="10%" align="center">
                      MD. ANWAR KHAN				
                      </td>
                      <td width="37%" align="center">
                      M97, Singarhati, Taratala Road , Kolkata -700024					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      81	
                      </td>
                      <td width="10%" align="center">
                      SMT.JUI BISWAS			
                      </td>
                      <td width="37%" align="center">
                      O/5, Durgapur Colony , Block- L, New Alipore, Bankim Mukherjee Sarani , Kolkata- 700053					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      82	
                      </td>
                      <td width="10%" align="center">
                      FIRHAD HAKIM				
                      </td>
                      <td width="37%" align="center">
                      4, Peary Mohan Road . P.S . : Chetla, P.O. :Alipore, Kolkata -700027					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      83	
                      </td>
                      <td width="10%" align="center">
                      SHRI PROBIR KUMAR MUKHOPADHYAY				
                      </td>
                      <td width="37%" align="center">
                      23A, Sadananda Road , Kolkata - 700026					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      84	
                      </td>
                      <td width="10%" align="center">
                      SMT.PAROMITA CHATTERJEE				
                      </td>
                      <td width="37%" align="center">
                      3, Nandalal Jew Road ,(4th Floor) Flat - 4A, Kolkata - 700026					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      85	
                      </td>
                      <td width="10%" align="center">
                      SHRI DEBASISH KUMAR (DEBA)				
                      </td>
                      <td width="37%" align="center">
                      35 B, Monohar Pukur Road , Kolkata - 700029					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      86	
                      </td>
                      <td width="10%" align="center">
                      SHRI SAURAV BASU (BAPPA)				
                      </td>
                      <td width="37%" align="center">
                      57E, Garcha Road, Kolkata - 700019					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      87	
                      </td>
                      <td width="10%" align="center">
                      SMT.MANISHA BOSE				
                      </td>
                      <td width="37%" align="center">
                      1A, Rajani Sen Road, Kolkata - 700026				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      88	
                      </td>
                      <td width="10%" align="center">
                      SMT.MALA ROY				
                      </td>
                      <td width="37%" align="center">
                      2B, Bowali Mondal Road , Kolkata -700026	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      89	
                      </td>
                      <td width="10%" align="center">
                      SMT. MAMATA MAJUMDAR				
                      </td>
                      <td width="37%" align="center">
                      D-24 , Charu Chandra Place, P.S. : Charu Market, Kolkata- 700033				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      90	
                      </td>
                      <td width="10%" align="center">
                      SMT.CHAITALI CHATTOPADHYAY				
                      </td>
                      <td width="37%" align="center">
                      16A, Swinhoe Lane , Kolkata - 700042					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      91	
                      </td>
                      <td width="10%" align="center">
                      SHRI BAISWANOR CHATTERJEE				
                      </td>
                      <td width="37%" align="center">
                      16A, Swinhoe Lane , Kolkata - 700042					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      92	
                      </td>
                      <td width="10%" align="center">
                      SMT.MADHUCHHANDA DEB				
                      </td>
                      <td width="37%" align="center">
                      21C, Gariahat Road (W), P.S. : Lane, Kolkata - 700068					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      93	
                      </td>
                      <td width="10%" align="center">
                      SMT.MOUSUMI DAS				
                      </td>
                      <td width="37%" align="center">
                      Sonajhuri (3rd Floor), 188/ 36A, Prince Anawar Shah Road , Kolkata - 700045					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      94	
                      </td>
                      <td width="10%" align="center">
                      SHRI SANDIP NANDI MAJUMDAR				
                      </td>
                      <td width="37%" align="center">
                      W2B, 15/6, Phase- III, Golf Green , Kolkata- 700095					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      95	
                      </td>
                      <td width="10%" align="center">
                      SHRI TAPAN DASGUPTA	                      </td>
                      <td width="37%" align="center">
                      Golf Green , Phase - 2 , Block - 8 , Flat - 6 , Kolkata -700095	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      96	
                      </td>
                      <td width="10%" align="center">
                      SHRI VASUNDHARA GOSWAMI	                      </td>
                      <td width="37%" align="center">
                      2/106 , Sree Colony, P.O : Regent Estate, Kolkata - 700092	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      97	
                      </td>
                      <td width="10%" align="center">
                      SHRI DEBABRATA MAJUMDER	                      </td>
                      <td width="37%" align="center">
                      3/11, Bijoygar , P.S. : Jadavpur , Kolkata - 700032	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      98	
                      </td>
                      <td width="10%" align="center">
                      SHRI ARUP CHAKRABORTY	                      </td>
                      <td width="37%" align="center">
                      I/24, Baghajatin Colony , Kolkata - 700092	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      99	
                      </td>
                      <td width="10%" align="center">
                      SMT.MITALI BANERJEE	                      </td>
                      <td width="37%" align="center">
                      1, Shaktigarh Colony, P.S. : Jadavpur, Kolkata - 700032	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      100	
                      </td>
                      <td width="10%" align="center">
                      SHRI PROSENJIT DAS			
                      </td>
                      <td width="37%" align="center">
                      D/77A, Ramgarh Colony, Kolkata -700047					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      101	
                      </td>
                      <td width="10%" align="center">
                      SHRI BAPPADITYA DASGUPTA				
                      </td>
                      <td width="37%" align="center">
                      J- 316, Baishnabghata Patuli upanagari , P.O . : Panchasayar, P.S . : Patuli, Kolkata - 700094	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      102	
                      </td>
                      <td width="10%" align="center">
                      SMT.SIMA GHOSH (JAYA)				
                      </td>
                      <td width="37%" align="center">
                      D/95A, Baghajatin , Kolkata- 700032	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      103	
                      </td>
                      <td width="10%" align="center">
                      SMT.NANDITA ROY				
                      </td>
                      <td width="37%" align="center">
                      3, Avenue South, Santoshpur, Kolkata -700075				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      104	
                      </td>
                      <td width="10%" align="center">
                      SHRI TARAKESWAR CHAKRABORTY				
                      </td>
                      <td width="37%" align="center">
                      23, Kalikumar Majumdar Road. , ' Manihar Appt.' Flat-n 4B, Santoshpur, Kolkata -700075	                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      105	
                      </td>
                      <td width="10%" align="center">
                      SMT.SUSHILA MONDAL				
                      </td>
                      <td width="37%" align="center">
                      12/D , Mondal Para , Garfa, Kolkata -700078					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      106	
                      </td>
                      <td width="10%" align="center">
                      SHRI ARIJIT DAS THAKUR (RANA)				
                      </td>
                      <td width="37%" align="center">
                      10/2 , Rajan Kanta Das Road , P.O. : Haltu, Kolkata -700078				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      107	
                      </td>
                      <td width="10%" align="center">
                      SMT.LIPIKA MANNA				
                      </td>
                      <td width="37%" align="center">
                      A- 7/3 , E. M. Bypass, Kolkata -700107					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      108	
                      </td>
                      <td width="10%" align="center">
                      SHRI SUSHANTA KUMAR GHOSH	                      </td>
                      <td width="37%" align="center">
                      159, Rajdanga Chakraborty Para, P.S. : Kasba, Kolkata -700107					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      109	
                      </td>
                      <td width="10%" align="center">
                      SMT.ANANYA BANERJEE				
                      </td>
                      <td width="37%" align="center">
                      1008, Chak Garia West, Kolkata - 700094					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      110	
                      </td>
                      <td width="10%" align="center">
                      SHRI SWARAJ KUMAR MONDAL				
                      </td>
                      <td width="37%" align="center">
                      115, Srirampur Road (N), Garia, Kolkata -700084				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      111	
                      </td>
                      <td width="10%" align="center">
                      SHRI SANDIP DAS				
                      </td>
                      <td width="37%" align="center">
                      K- 92 , Kamdohari Bose Para, P.O.: Garia , Kolkata - 700084					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      112	
                      </td>
                      <td width="10%" align="center">
                      SHRI GOPAL ROY				
                      </td>
                      <td width="37%" align="center">
                      37, Bashdroni Govt. Colony, K.M. C . Regent Park, Kolkata - 700070					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      113	
                      </td>
                      <td width="10%" align="center">
                      SMT.ANITA KAR MAJUMDAR SIL				
                      </td>
                      <td width="37%" align="center">
                      19,Bansdroni Rifle (W), K. M. C. Regent Park, Kolkata - 700070					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      114	
                      </td>
                      <td width="10%" align="center">
                      SHRI BISWAJIT MANDAL				
                      </td>
                      <td width="37%" align="center">
                      119, Bishnupally, P.O. : Purbaputiary, Kolkata -700093					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      115	
                      </td>
                      <td width="10%" align="center">
                      SMT.RATNA SUR				
                      </td>
                      <td width="37%" align="center">
                      5/236, Paschim Putiary, Kolkata - 700071				
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      116	
                      </td>
                      <td width="10%" align="center">
                      SMT.KRISHNA SINGH				
                      </td>
                      <td width="37%" align="center">
                      40, Jyotish Roy Road, Kolkata - 700053					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      117	
                      </td>
                      <td width="10%" align="center">
                      SHRI AMIT SINGH				
                      </td>
                      <td width="37%" align="center">
                      40, Jyotish Roy Road, Kolkata - 700053					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      118	
                      </td>
                      <td width="10%" align="center">
                      SHRI TARAK SINGH				
                      </td>
                      <td width="37%" align="center">
                      40, Jyotish Roy Road, Kolkata - 700053					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      119	
                      </td>
                      <td width="10%" align="center">
                      SMT.KAKALI BAG				
                      </td>
                      <td width="37%" align="center">
                      86, S. N. Roy Road Kolkata - 700038					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      120	
                      </td>
                      <td width="10%" align="center">
                      SHRI SUSANTA GHOSH (BUA)				
                      </td>
                      <td width="37%" align="center">
                      71/2 , Satyen Roy Road, Behala , Kolkata -700034					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      121	
                      </td>
                      <td width="10%" align="center">
                      SHRI RUPAK GANGULY					
                      </td>
                      <td width="37%" align="center">
                      15/52, Banamali Ghoshal Lane, Behala, Kolkata -700034						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      122	
                      </td>
                      <td width="10%" align="center">
                      SMT.SOMA CHAKRABORTY					
                      </td>
                      <td width="37%" align="center">
                      39, Mahatma Gandhi Road, P.S. : Haridevpur, Kolkata - 700082					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      123	
                      </td>
                      <td width="10%" align="center">
                      SHRI SUDIP POLLEY					
                      </td>
                      <td width="37%" align="center">
                      31, Santosh Roy Road , Barish , P. S. : Thakurpukur, Kolkata - 700008					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      124	
                      </td>
                      <td width="10%" align="center">
                      SHRI RAJIB KUMAR DAS					
                      </td>
                      <td width="37%" align="center">
                      165/3A, D.H. Road, Thakurpukur, Kolkata - 700063						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      125	
                      </td>
                      <td width="10%" align="center">
                      SMT.CHHANDA SARKAR					
                      </td>
                      <td width="37%" align="center">
                      32, Sreedurga Pally , Dakshin Behala Road, Kolkata - 700061						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      126	
                      </td>
                      <td width="10%" align="center">
                      SHRI GHANASREE BAGH					
                      </td>
                      <td width="37%" align="center">
                      210/3/2,Diamond Harbour Road,P.O. & P.S : Thakurpukur, Kolkata - 700063						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      127	
                      </td>
                      <td width="10%" align="center">
                      SMT.MALOBIKA BAIDYA					
                      </td>
                      <td width="37%" align="center">
                      21, Shibrampur Bye Lane, Nivedita Park, Sarsuna, Kolkata - 700061						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      128	
                      </td>
                      <td width="10%" align="center">
                      SHRI PARTHA SARKAR					
                      </td>
                      <td width="37%" align="center">
                      57, Parnashree Pally, Kolkata - 700060					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      129	
                      </td>
                      <td width="10%" align="center">
                      SMT.SANHITA DAS					
                      </td>
                      <td width="37%" align="center">
                      2/129A, Mahendra Banerjee Road, Kolkata - 700060						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      130	
                      </td>
                      <td width="10%" align="center">
                      SHRI AVIJIT MUKHERJEE					
                      </td>
                      <td width="37%" align="center">
                      80, Brahma Samaj Road, Behala , Kolkata - 700034						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      131	
                      </td>
                      <td width="10%" align="center">
                      SMT.RATNA CHATTERJEE					
                      </td>
                      <td width="37%" align="center">
                      36, Maharani Indira Devi Road, Kolkata - 700060					
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      132	
                      </td>
                      <td width="10%" align="center">
                      SMT.SANCHITA MITRA					
                      </td>
                      <td width="37%" align="center">
                      P-263/ 4, upen Banerjee Road , Kolkata -700060						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      133	
                      </td>
                      <td width="10%" align="center">
                      SHRI RANAJIT SHIL					
                      </td>
                      <td width="37%" align="center">
                      4th Floor , Laxmi Narayan Villa, N-294 , Fathepur, 2nd Lane, Kolkata- 700024						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      134	
                      </td>
                      <td width="10%" align="center">
                      SHAMS IQBAL					
                      </td>
                      <td width="37%" align="center">
                      B-43, Irongata Road , Garden Reach, Kolkata - 700024						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      135	
                      </td>
                      <td width="10%" align="center">
                      RUBINA NAAZ					
                      </td>
                      <td width="37%" align="center">
                      Q- 292, Mudiali Road , p.S . : Metiaburuz, P.O. : Garden Reach , Kolkata - 700024						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      136	
                      </td>
                      <td width="10%" align="center">
                      SHAMSUZZAMAN ANSARI				
                      </td>
                      <td width="37%" align="center">
                      Q- 292, Mudiali Road , p.S . : Metiaburuz, P.O. : Garden Reach , Kolkata - 700024						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      137	
                      </td>
                      <td width="10%" align="center">
                      WASIM ANSARI					
                      </td>
                      <td width="37%" align="center">
                      R- 101, S.A Faqooque Road , P.O. : Garden Reach, P.O. : Metiabruz, Kolkata - 700024						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      138	
                      </td>
                      <td width="10%" align="center">
                      FARIDA PARVIN					
                      </td>
                      <td width="37%" align="center">
                      X- 11 , S.A Faruqee Road, P.O. :Bartala, P.S : Nadial, Kolkata - 700018						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      139	
                      </td>
                      <td width="10%" align="center">
                      SK. MUSHTAQUE AHAMED (BACHCHU)					
                      </td>
                      <td width="37%" align="center">
                      V-126/1, S.A. Farooquie Road , P.S. :Rajbagan , Kolkata -700018						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      140	
                      </td>
                      <td width="10%" align="center">
                      ABU MD. TARIK					
                      </td>
                      <td width="37%" align="center">
                      Y-187, Kanthal Beria Bey Lane, Kolkata - 700018						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      141	
                      </td>
                      <td width="10%" align="center">
                      SMT.PURBASA NASKAR					
                      </td>
                      <td width="37%" align="center">
                      Z- 3/554, Mestry Ghat Road , P.S. : Nadial, Kolkata - 700044						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      142	
                      </td>
                      <td width="10%" align="center">
                      SHRI RAGHUNATH PATRA					
                      </td>
                      <td width="37%" align="center">
                      2/13, Darirchak , P.O. : R. C . Thakurani, P. S : Haridevpur, Kolkata - 700104						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      143	
                      </td>
                      <td width="10%" align="center">
                      SMT.CHRISTINA BISWAS					
                      </td>
                      <td width="37%" align="center">
                      A-17, Dimond Park , P.O. : Joka, P. S. : Haridevpur, Kolkata - 700104						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      144	
                      </td>
                      <td width="10%" align="center">
                      SMT.SHEFALI PRAMANIK					
                      </td>
                      <td width="37%" align="center">
                      2, Pramanik para , B.H. Road, P.O . : Joka, P.S. : Thakurpukur, Kolkata - 700104						
                      </td>
                      <td width="10%" align="center">
                      -
                      </td>
                    </tr>
                    

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>
  )
}

export default Councillors