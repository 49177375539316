import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UEVRP_Status = () => {
    const increaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
          var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
          var fontSize = parseFloat(style); 
          el[i].style.fontSize = (fontSize + 1) + 'px';
        }
    };
    const decreaseFontSizeBy1px =() => {
      var el = document.getElementsByClassName('fontSizeClass');
      for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize - 1) + 'px'; 
      }
    };
    const { t, i18n, ready } = useTranslation();
  return (
<>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4> </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
      <div className="col-lg-3 ">
          <div className="site-menu">
           <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link" to="/UEVRP_Home"> {t("UEVRP Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1" to="/UEVRP_Status"> {t("UEVRP Status")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Earthquake_Leaflet"> {t("Earthquake Leaflet")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/EQ_Pocket_Guide"> {t("EQ Pocket Guide")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Disaster_Mgmt">{t("School Safety")}</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9 pb-0 pt-0">
          <div className="row">
            <div class="container">
            <div className="inner-title">
              <h3> {t("Status on UEVRP on Kolkata")} </h3>
            </div>
            <div className="col-lg-12 about-head1">
              <p>
                
                <strong>
                  
                  {t("Urban Earthquake Vulnerability Reduction Project (UEVRP) in Kolkata May, 2008")}
                </strong>
              </p>
            </div>
          </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse2"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  {t("The City of Kolkata")} ... <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse2"
                className="collapse show"
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/drmp10.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse3"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  {t("Introduction")} <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse3"
                className="collapse show"
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/drmp11.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                  <p>
                    
                    •{t("Geologically, Kolkata falls within Moderate Earthquake Damage Risk Zone within very clo se vicinity of High Damage Risk Zone")}
                  </p>
                  <p>
                    • {t("The same area falls under Very High Wind and Cyclone Damage Risk Zone")}
                  </p>
                  <p>
                    • {t("Disaster leads to erosion of development gains and restricted options for affected people")}
                  </p>
                  <p>
                    • {t("UEVRP, as a sub-component of Govt. of India – UNDP Disaster Risk Management Programme, was . initiated in Kolkata in March, 2005 and is being implemented by KMC under the monitoring of Department of Disaster Management, Government of West Bengal")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse4"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  {t("Key Activities for Urban Earthquake Vulnerability Reduction in Kolkata city")} <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse4"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/drmp12.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                  <p>
                    
                    <strong>
                      
                      {t("Activities Undertaken till December 2007 – Awareness Generation")}
                    </strong>
                    <br />
                    <strong>
                      • {t("225 Administrators/senior officers/policy makers of different government departments have been sensitized on probable disasters in this region, vulnerability of West Bengal as well as Kolkata City and preparedness needed to cope with them Sensitization of Engineers, Architects and Masons/Carpenters/Bar benders/Fitters")}
                    </strong>
                  </p>
                  <p>
                    • {t("114 staff engineers of KMC and 56 Artisans (Masons etc.) were sensitized Sensitization of Professionals excepting engineers, architects and artisans")}
                  </p>
                  <p>
                    • {t("144 professionals of various state/central govt. departments were sensitized against a target of 150")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse5"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  {t("Sensitization of Fire & Emergency Service Officers under UEVRP at the IFS, Behala")} <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse5"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/drmp13.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                  <img
                    src="assets/img/drmp14.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                  <p>
                    
                    <strong>
                      
                      {t("Activities Undertaken till December 2007 – Awareness Generation")}
                    </strong>
                  </p>
                  <p>
                    {t("Sensitization of School Community • School Awareness & Safety Programme was attended by teachers of 73 KMC run primary schools and 100 KMC run Shishu Shiksha Kendra (children’s learning centres)")}
                  </p>
                  <p>
                    
                    • {t("Two high schools guided by ICSE/CBSE were also covered under this sensitization programme")}
                  </p>
                  <p>
                    
                    • {t("2070 high school students and 381 teachers of total 180 schools (including 7 high schools) of Kolkata City have been sensitized so far Sensitization of Colleges")}
                  </p>
                  <p>
                    • {t("177 students of 3 general colleges were sensitized so far Sensitization of corporate sector")}
                  </p>
                  <p>
                    • {t("A few officers of two corporate companies (Tata steel and Balmer Laurie) having offices in Kolkata were sensitized")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse6"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  {t("Sensitization of teachers & students of city schools")}
                  <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse6"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/drmp15.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                  <img
                    src="assets/img/drmp16.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse7"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  {t("Key Activities Undertaken till December 2007 – Awareness Generation")} <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse7"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <p>
                    
                    <strong>{t("Mass Awareness Programmes")}</strong> <br />• {t("One such programme organized at the Chaplin hall was attended by 50 members representing 50 Neighbourhood Committees of KMC Printing of Leaflets under UEVRP in Kolkata")}
                  </p>
                  <p>
                    • {t("25,200 multi-colour leaflets were printed in Bengali, Hindi, Urdu and English languages most of which have already been distributed in sensitization programmes Display of Hoardings and Banners")}
                  </p>
                  <p>
                    • {t("15 Hoardings and 2 banners have been put up showing Dos and Don’ts during Earthquake. Five big hoardings have been displayed at five prominent locations of the city. Campaign through cable TV network")}
                  </p>
                  <p>
                    
                    • {t("A TV spot was made under UEVRP showing Dos and Don’ts of earthquake disaster. This was telecast through cable TV network over wide spread areas of the city in 3 stretches")}.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse8"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  {t("Key Activities Undertaken till December 2007 – Training and capacity Building")} <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse8"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <p>
                    <strong>{t("Training of Engineers / Architects")}</strong> <br />
                    •{t("99 Civil / Structural staff engineers of KMC have been trained under UEVRP on Disaster Resistant Buildings out of which 36 Engineers were trained in Rapid Visual Screening (RVS) of Buildings")} <br />
                    <br />
                    •{t("Professors of Bengal Engineering & Science University and experienced senior engineers of state Public Works Department were the resource persons")}
                  </p>
                  <p>
                    
                    <strong>
                      
                      {t("Training of Masons/ Carpenters/ Bar benders/ Fitters")}
                    </strong>
                    <br />
                    •{t("Training on construction of disaster resistant buildings was imparted to 56 construction workers (Masons/ Carpenters/ Bar benders/ Fitters) in two batches")}
                  </p>
                  <p>
                    •{t("This training was organized in partnership with Tata Steel Ltd. Training of Administrators/ Policy makers/ Nodal officers/ Senior officers")}
                  </p>
                  <p>
                    •{t("78 officers of KMC and other organizations were trained so far Training of School Teachers")}
                  </p>
                  <p>
                    
                    •{t("40 teachers of 40 KMC run primary schools were trained in a 3-day training programme organized in November, 2007")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse9"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  {t("Training of Masons/ Carpenters/ Bar benders/ Fitters")}
                  <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse9"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <img
                    src="assets/img/drmp17.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                  <img
                    src="assets/img/drmp18.jpg"
                    className="img-fluid"
                    alt="alt-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse10"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  {t("Key Activities Undertaken till December 2007 – Earthquake Preparedness, Response Plan and Techno-Legal Regime")}
                  <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse10"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <p> {t("City Emergency Operation Centre/ Control Room")} </p>
                  <p>
                    • {t("The equipped Control Room of KMC functions round the clock throughout the year Ward Disaster Management Committees")}
                  </p>
                  <p>
                    • {t("72 Ward Committees formed so far functioning now in 72 wards of KMC are acting as Ward Disaster Management Committees after issue of a circular by the Commissioner of KMC to that effect Response Plan")}
                  </p>
                  <p>
                    • {t("After the training of the teachers of 40 KMC Primary Schools 34 of those schools have submitted data on school disaster management plans Techno Legal Regime")}
                  </p>
                  <p>
                    • {t("An expert committee for preparation of Draft Amendment of the Building Byelaws of KMC was formed in KMC. The committee prepared a draft for amendment which is in the process of enactment by the Dept. of Municipal Affairs, Govt. of West Bengal")}.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div id="accordion" className="faq">
            <div className="card">
              <div className="card-header" id="heading1">
                <button
                  className="btn btn-link m-2"
                  data-toggle="collapse"
                  data-target="#collapse11"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  {t("Way Forward")} <i className="bi bi-chevron-down" />
                </button>
              </div>
              <div
                id="collapse11"
                className="collapse "
                data-parent="#accordion"
              >
                <div className="card-body m-3 about-img4">
                  <p>
                    <strong> {t("Planned Future Activities")} </strong>
                  </p>
                  <p>
                    •{t("Sensitization programmes in Schools and Colleges (including polytechnic colleges)")}. <br />
                    •{t("Sensitization of Ward Councilors")}. <br />
                    •{t("Awareness meetings for community people")}. <br />
                    •{t("Training of Engineers, Architects and Masons on Disaster Resistant Building Technologies")}. <br />
                    •{t("Training of School Teachers and Students by experts from related govt. and other organizations")}.
                    <br />
                    •{t("Formation and Training of Disaster Management Teams of Volunteers in Schools and Wards")}.
                    <br />
                    •{t("Preparation of Inventories, Disaster Management Plans and Standard Operating Procedures (SOPs) at Ward and City Levels")}.
                    <br />
                    •{t("Mock Drills to be organized in schools and at City level")}.
                    <br />
                    •{t("Amendment of Building Byelaws")}. <br />
                    •{t("Creation of Website for dissemination/networking of knowledge about Disaster Protection")}. <br />
                    •{t("Enhancing ties/coordination with other government/non-government stakeholders")} <br />
                    •{t("Enhancing public private partnership")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default UEVRP_Status