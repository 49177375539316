import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const HeritageBuildingHome2  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container" >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
              <li className="nav-item">
                <Link className="nav-link active1 fontSizeClass" to='/HeritageBuildingHome'> {t("Heritage Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Exemption_of_Tax'> {t("Exemption of Tax")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Heritage_Buildings'> {t("Heritage Buildings")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Conservation_Architect'> {t("Conservation Architect")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('473')}>{t("Composition of the Heritage Conservation Committee")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Proceedings_of_HCC'>
                    
                    {t("Proceedings of HCC")}
                  </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('474')}>{t("Action Plan")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('475')}>{t("Parking Policy")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('476')}>{t("Measures for prevention of landfill fire")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWisePond.jsp'>  {t("Ward wise Pond")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWiseHeritageBuilding.jsp'> {t("Ward Wise Heritage Building")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Environment_Heritage_Dept'> {t("Contact Details")} </Link>
                </li>

                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Activities_NCAP.pdf' target={'_blank'}> {t("Activities Permitted under NCAP")} </a>
                </li>

                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Swachh Vayu Survekshan")} <i className="bi bi-chevron-down" /></button>
                    <div id="demo2" className="collapse">
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessed_CPCB_scores_23_09_2023.xlsx'>1. {t("Self assessed scores & CPCB scores")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessment_reports_Kolkatacity_23_09_2023.pdf'>2. {t("Self assessment reports of Kolkata city2023")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Swachh_Vayu_Survekshan_23_09_2023.pdf'>3. {t("Swachh Vayu Survekshan 2023 Rank")} </a>
                    </div>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/airquality/citizenreport.do' target={'_blank'}> {t("Air quality status")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/emis/category.do' target={'_blank'}> {t(" Industry categorisation")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/District_Environment_ KMC2021_23_09_2023.pdf' target={'_blank'}> {t("FINAL District Environment plan KMC 2021")} </a>
                </li>
                
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2" >
                <h3 className="fontSizeClass">{t("Heritage Buildings of Kolkata")} </h3>
              </div>
              <div className="about-head1">                 
                    <div className="log-form inner-log-form">
                      <h4 className="fontSizeClass"> Guidelines For The Owners Of Heritage Buildings </h4>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 form-group">
                          <div className="list-of-forms">
                            <h5 className="fontSizeClass"> <strong> (e) Change of use of the Heritage Building : </strong> </h5>
                            <span>...
                             <Link className="fontSizeClass" to='/HeritageBuildingHome'>  {t("1")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome2'> {t("2")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome3'> {t("3")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome4'> {t("4")} </Link>
                            </span>
                            <p>Heritage Conservation Committee may consider change of use. The proposed change of use shall be in 
                              terms of the Act and Rules and Regulations of the Kolkata Municipal Corporation.</p>
                            <h5>(f) Restriction on the display of signage, hoardings etc.</h5>
                            <p>Normally no display of signage, hoardings etc. upon the heritage building is 
                              allowed. However, on approval from the Heritage Conservation Committee 
                              hoardings, signage etc. may be allowed if they do not obstruct the view of 
                              the heritage building or is in harmony with the heritage building. </p>
                            <h5>(g) Restriction for use of material for repairing / addition / alteration. </h5>
                            <p>Repairing /addition/alteration works shall be done through traditional building materials and 
                              construction technique. Any kind of use of new material shall be done with the approval of 
                              the Heritage Conservation Committee on recommendation of the enlisted Conservation architect.
                            </p>
                            <h5>(h) Benefits of being a Heritage Building.</h5>
                            <p>It will enhance the valuation of the property if properly maintained. One can avail oneself of the national or 
                              international funds for the maintenance of such heritage buildings if properly approached through 
                              Conservation Architect. Compatible re-use of the building may be permitted for commercial benefits. 
                              In some special cases property tax may be reduced / exempted under Section 425 K of KMC Act, 1980.</p>
                            <h5>(i)Contact persons for further information</h5>

                           <p>
                            Director General <br />
                            Project Management Unit -<br />
                            48, Market Street <br />
                            Kolkata - 700087 <br />
                            Phone No. 2252-1721 
                            </p>
                          <h5> Preservation and Conservation of Heritage Buildings </h5>
                          <p><strong> 425A.	Owner to maintain, preserve and conserve heritage building : </strong> 
                            Every owner or occupier of any heritage building declared as such by the Corporation 
                            shall maintain, preserve and conserve it and shall not change its use in contravention 
                            of the provisions of this Act or the rules or the regulations made thereunder for its 
                            maintenance, preservation or conservation.
                          </p>
                          <h5>425B.	Power of Corporation to declare a building as a heritage building :</h5>
                          <p>Where the Corporation, on the recommendation of the Heritage Conservation Committee and also 
                            of the Mayor-in-Council, is of the opinion that any building in Calcutta should be preserved and 
                            conserved for historical, architectural, environmental or ecological purpose, it may declare 
                            such building as a heritage building : Provided that during the period when 
                            any proposal for declaring building as a heritage building is under 
                            consideration of the Heritage Conservation Committee or the Mayor-in-Council,
                             no owner of such building, or no lessee or sub-lessee to whom such building
                              has been leased out, shall transfer such building by way of sale, lease ,
                              or mortgage without the prior approval of the Municipal Commissioner.</p>
                          <h5>425C.	Gradation of heritage building.</h5>
                          <p>The gradation of a heritage building according to its historical, architectural, 
                            environmental or ecological purpose shall be such as may be prescribed.</p>
                          <h5>425D.	Heritage Conservation Committee .</h5>.
                          <p>(1)	The Mayor-in-Council shall constitute a Committee to be called the 
                            Heritage Conservation Committee with the Municipal Commissioner as its 
                            Chairman and an officer of the Corporation as its Convenor.</p>
                          <p>(2)	The Committee shall have, in addition to  the Chairman and the Convenor,
                             seven other members of whom -
                             (a)	one shall be a nominee of the Calcutta Metropolitan Development Authority. <br />
                             (b)	one shall be the Director of the Department of Archaeology, 
                             Government of West Bengal, or his nominee.<br />
                             (c) one shall be an eminent architect, <br />
                             (d)	one shall be an artist,  <br />
                             (e)	one shall be an environmentalist, <br />
                             (f)	one shall be a historian, and  <br />
                             (g)	one shall be the Chief Valuer and Surveyor of the Corporation. <br />  
                            </p>
                          <p>
                          (3)	The Committee may co-opt one person to be nominated by the concerned 
                          department of the State Government while dealing with any land or building 
                          under the management of the said department.
                          </p>
                          <p>
                          (4)	The Committee shall, in accordance with the provisions of this Act and 
                          the rules and the regulations made thereunder, scrutinize every application 
                          or proposal for declaration of a building as a heritage building, and recommend 
                          to, and also advice, the Mayor-in-Council in respect of the preservation and 
                          conservation of such building as a heritage building.
                          </p>
                          <p>	(5)	The Committee shall meet at such periodical interval as may be 
                            determined by the Mayor-in-Council.</p>
                            
                          <p>
                          (6)	The Municipal Commissioner shall, in the case of emergency, take such 
                          measures as may be necessary for the preservation and conservation of a 
                          heritage building, provided that such measures shall be required to be 
                          approved by the Heritage Conservation Committee at its meeting.
                          </p>


                          </div> 
                          <span>...
                             <Link className="fontSizeClass" to='/HeritageBuildingHome'>  {t("1")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome2'> {t("2")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome3'> {t("3")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome4'> {t("4")} </Link>
                            </span>                      
                        </div>
                      </div>                
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  </section>
</>

  )
}

export default HeritageBuildingHome2