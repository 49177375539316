import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Citizen_charter_Home  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt='/' />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"></h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Citizen_charter_Home'>
                    
                    {t("Citizen Charter's Home")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/President_with_Charters'>                    
                    {t("President with Charters")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Citizen_Charter_Ads'>
                    
                    {t("Citizen's Charter ads")}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass"> {t("Citizen's Charter")} </h3>
            </div>
            <div className="about-head1">
              <div className="log-form inner-log-form">
              <div className="container">
                <div className="row">
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1510')}>{t("Citizen's Charter Amusement Department")}</a>
          </div>
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1511')}> {t("Citizen's Charter Assessment Department")} </a>
          </div>
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1513')}>{t("Citizen's Charter Building Department")} </a>
          </div>
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1514')}>{t("Citizen's Charter C.M.E(Civil) Department")}</a>
          </div>
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1515')}> {t("Citizen's Charter Education Department")} </a>
          </div>
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1516')}> {t("Citizen's Charter Estate Management Department (Chief Valuer & Surveyor Deptt.)")} </a>
          </div>
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1517')}>{t("Citizen's Charter Health Department")}</a>
          </div>                  
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1518')}>{t("Citizen's Charter KEIIP (Kolkata Environmental Improvement Investment Programme)")}</a>
          </div>
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1519')}> {t("Citizen's Charter License Department")}</a>
          </div>
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1520')}>{t("Citizen's Charter Lighting Department")} </a>
          </div>
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1521')}>{t("Citizen's Charter Market Department")} </a>
          </div>
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1522')}> {t("Citizen's Charter Parking Department")} </a>
          </div>
          
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1523')}> {t("Citizen's Charter Roads Department")} </a>
          </div>
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1524')}> {t("Citizen's Charter Service Records and Accounts Cell (Pension, P. F., H. B. L. & Staff Quarter) Department")} </a>
          </div>
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1525')}> {t("Citizen's Charter Sewerage & Drainage Department")} </a>
          </div>
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1526')}> {t("Citizen's Charter Solid Waste Management Department")} </a>
          </div>
          <div className="width-30 pdf-link1">
          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1527')}> {t("Citizen's Charter Water Supply Department")} </a>
          </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Citizen_charter_Home