import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Ward_Wise_BPL_List  = () => {
  const fileDownload=(fileStoreId)=> 
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
<>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
   
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Social_welfare_Download_Forms'>{t("Download Forms")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to="/Govt_Sponsored_Schemes"> {t("Govt Sponsored Schemes")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Ward_Wise_BPL_List'>{t("BPL List")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Housing_Urban_Poor_Form_and_Site_Plan'>{t("Housing Urban Poor Form and Site Plan")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/GR_Recipients'>{t("GR Recipients")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('697')}> {t("National Urban Livelihoods Mission")} </a>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Social_Welfare_Urban_Dept'>{t("Contact Details")}</Link>  
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass"> {t("Ward Wise BPL List :")} </h3>
              </div>
              <div className="mayor-table2 depart min-height-400">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td scope="row">
                      <a className="fontSizeClass" onClick={(e)=>fileDownload('698')}> {t("Ward - 1")} </a>
                      </td>
                      <td>
                      <a className="fontSizeClass" onClick={(e)=>fileDownload('699')}> {t("Ward - 2")} </a>
                      </td>
                      <td>
                      <a className="fontSizeClass" onClick={(e)=>fileDownload('700')}> {t("Ward - 3 ")}</a>
                      </td>
                      <td>
                      <a className="fontSizeClass" onClick={(e)=>fileDownload('701')}> {t("Ward - 4")} </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                      <a className="fontSizeClass" onClick={(e)=>fileDownload('702')}> {t("Ward")} - 5 </a>
                      </td>
                      <td>
                      <a className="fontSizeClass" onClick={(e)=>fileDownload('703')}> {t("Ward")} - 6 </a>
                      </td>
                      <td>
                      <a className="fontSizeClass" onClick={(e)=>fileDownload('704')}> {t("Ward")} - 7 </a>
                      </td>
                      <td>
                      <a className="fontSizeClass" onClick={(e)=>fileDownload('705')}> {t("Ward")} - 8 </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('706')}> {t("Ward")} - 9 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('707')}> {t("Ward")} - 10 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('708')}> {t("Ward")} - 11 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('709')}> {t("Ward")} - 12 </a></td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('710')}> {t("Ward")} - 13 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('711')}> {t("Ward")} - 14 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('712')}> {t("Ward")} - 15 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('713')}> {t("Ward")} - 16 </a></td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('714')}> {t("Ward")} - 17 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('715')}> {t("Ward")} - 18 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('716')}> {t("Ward")} - 19 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('717')}> {t("Ward")} - 20 </a></td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('718')}> {t("Ward")} - 21 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('719')}> {t("Ward")} - 22 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('720')}> {t("Ward")} - 23 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('721')}> {t("Ward")} - 24 </a></td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('722')}> {t("Ward")} - 25 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('723')}> {t("Ward")} - 26 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('724')}> {t("Ward")} - 27 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('725')}> {t("Ward")} - 28 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('726')}> {t("Ward")} - 29 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('727')}> {t("Ward")} - 30 </a></td>
                      
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('728')}> {t("Ward")} - 31 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('729')}> {t("Ward")} - 32 </a> </td>

                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('730')}> {t("Ward")} - 33 </a> </td>
                      <td ><a className="fontSizeClass" onClick={(e)=>fileDownload('731')}> {t("Ward")} - 34 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('732')}> {t("Ward")} - 35 </a>  </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('733')}> {t("Ward")} - 36 </a> </td>
                     
                    </tr>
                    <tr>
                      <td  scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('734')}> {t("Ward")} - 37 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('735')}> {t("Ward")} - 38 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('736')}> {t("Ward")} - 39 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('737')}> {t("Ward")} - 40 </a> </td>
                      
                    </tr>
                    <tr>
                      <td  scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('738')}> {t("Ward")} - 41 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('739')}> {t("Ward")} - 42 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('740')}> {t("Ward")} - 43 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('741')}> {t("Ward")} - 44 </a></td>
                      
                    </tr>
                    <tr>
                      <td  scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('742')}> {t("Ward")} - 45 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('743')}> {t("Ward")} - 46 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('744')}> {t("Ward")} - 47 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('745')}> {t("Ward")} - 48 </a></td>
                      
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('746')}> {t("Ward")} - 49 </a> </td>
                      <td ><a className="fontSizeClass" onClick={(e)=>fileDownload('747')}> {t("Ward")} - 50 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('748')}> {t("Ward")} - 51 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('749')}> {t("Ward")} - 52 </a></td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('750')}> {t("Ward")} - 53 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('751')}> {t("Ward")} - 54 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('752')}> {t("Ward")} - 55 </a></td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('753')}> {t("Ward")} - 56 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('754')}> {t("Ward")} - 57 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('755')}> {t("Ward")} - 58 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('756')}> {t("Ward")} - 59 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('757')}> {t("Ward")} - 60 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('758')}> {t("Ward")} - 61 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('759')}> {t("Ward")} - 62 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('760')}> {t("Ward")} - 63 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('761')}> {t("Ward")} - 64 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('762')}> {t("Ward")} - 65 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('763')}> {t("Ward")} - 66 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('764')}> {t("Ward")} - 67 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('765')}> {t("Ward")} - 68 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('767')}> {t("Ward")} - 69 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('768')}> {t("Ward")} - 70 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('769')}> {t("Ward")} - 71 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('770')}> {t("Ward")} - 72 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('768')}> {t("Ward")} - 73 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('769')}> {t("Ward")} - 74 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('770')}> {t("Ward")} - 75 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('771')}> {t("Ward")} - 76 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('772')}> {t("Ward")} - 77 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('773')}> {t("Ward")} - 78 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('774')}> {t("Ward")} - 79 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('775')}> {t("Ward")} - 80 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('776')}> {t("Ward")} - 81 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('777')}> {t("Ward")} - 82 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('778')}> {t("Ward")} - 83 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('779')}> {t("Ward")} - 84 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('780')}> {t("Ward")} - 85 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('781')}> {t("Ward")} - 86 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('782')}> {t("Ward")} - 87 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('783')}> {t("Ward")} - 88 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('784')}> {t("Ward")} - 89 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('785')}> {t("Ward")} - 90 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('786')}> {t("Ward")} - 91 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('787')}> {t("Ward")} - 92 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('788')}> {t("Ward")} - 93 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('789')}> {t("Ward")} - 94 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('790')}> {t("Ward")} - 95 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('791')}> {t("Ward")} - 96 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('792')}> {t("Ward")} - 97 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('793')}> {t("Ward")} - 98 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('794')}> {t("Ward")} - 99 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('795')}> {t("Ward")} - 100 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('796')}> {t("Ward")} - 101 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('797')}> {t("Ward")} - 102 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('798')}> {t("Ward")} - 103 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('799')}> {t("Ward")} - 104 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('800')}> {t("Ward")} - 105 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('801')}> {t("Ward")} - 106 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('802')}> {t("Ward")} - 107 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('803')}> {t("Ward")} - 108 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('804')}> {t("Ward")} - 109 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('805')}> {t("Ward")} - 110 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('806')}> {t("Ward")} - 111 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('807')}> {t("Ward")} - 112 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('808')}> {t("Ward")} - 113 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('809')}> {t("Ward")} - 114 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('810')}> {t("Ward")} - 115 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('811')}> {t("Ward")} - 116 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('812')}> {t("Ward")} - 117 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('813')}> {t("Ward")} - 118 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('814')}> {t("Ward")} - 119 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('815')}> {t("Ward")} - 120 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('816')}> {t("Ward")} - 121 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('817')}> {t("Ward")} - 122 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('818')}> {t("Ward")} - 123 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('819')}> {t("Ward")} - 124 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('820')}> {t("Ward")} - 125 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('821')}> {t("Ward")} - 126 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('822')}> {t("Ward")} - 127 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('823')}> {t("Ward")} - 128 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('824')}> {t("Ward")} - 129 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('825')}> {t("Ward")} - 130 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('826')}> {t("Ward")} - 131 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('827')}> {t("Ward")} - 132 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('828')}> {t("Ward")} - 133 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('829')}> {t("Ward")} - 134 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('830')}> {t("Ward")} - 135 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('831')}> {t("Ward")} - 136 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('831')}> {t("Ward")} - 137 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('832')}> {t("Ward")} - 138 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('833')}> {t("Ward")} - 139 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('834')}> {t("Ward")} - 140 </a> </td>
                    </tr>
                    <tr>
                      <td scope="row"><a className="fontSizeClass" onClick={(e)=>fileDownload('835')}> {t("Ward")} - 141 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('836')}> {t("Ward")} - 142 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('837')}> {t("Ward")} - 143 </a> </td>
                      <td><a className="fontSizeClass" onClick={(e)=>fileDownload('838')}> {t("Ward")} - 144 </a> </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

    )
}

export default Ward_Wise_BPL_List