import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios"; 
 
const Water_Supply = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const fileDownload = (fileStoreId) => {
    let url = `https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`;
    axios
      .post(url, { fileId: fileStoreId, userId: "392" })
      .then((res) => {
        console.log("response is --- ", res);

        if (res && res.data && res.status === 200) {
          const base64 = res.data[0] && res.data[0].base64_data;
          const pdfData = "data:application/pdf;base64," + base64;

          let html =
            "<html>" +
            "<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>" +
            "<body>" +
            '<iframe src="' +
            pdfData +
            '"></iframe>' +
            "</body></html>";
          console.log(html);
          let a = window.open();
          a.document.write(html);
        }
      })
      .catch((e) => {
        // setLoader(false);
        console.log("The error is ", e);
      });
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
      <section id="hero" className="hero hero2">
        <div
          className="social-media wow slideInDown"
          style={{ visibility: "visible", animationName: "slideInDown" }}
        >
          <div className="facebook" rel="ebInfo" title="facebook">
            <a
              href="https://www.facebook.com/KolkataMunicipalCorporation.official"
              target="_blank"
            >
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a
              href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp"
              target="_blank"
            >
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>
        <div
          className="social-media wow slideInDown"
          style={{ visibility: "visible", animationName: "slideInDown" }}
        >
          <div className="facebook" rel="ebInfo" title="facebook">
            <a
              href="https://www.facebook.com/KolkataMunicipalCorporation.official"
              target="_blank"
            >
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a
              href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp"
              target="_blank"
            >
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>

        <div className="carousel-item active">
          <img
            src="assets/img/Banner/portal-banner6.jpg"
            className="img-fluid"
            alt="image"
          />
          <div className="container">
            <div className="section-main-heading2">
              <h4 className="fontSizeClass"> {t("Water Supply")} </h4>
            </div>
          </div>
        </div>
      </section>
      {/* End Slider */}
      <section className="section inner-pad1">
        <div className="container ">
          <div className="row">
            <div className="col-lg-3">
              <div className="site-menu  min-height-400">
                <nav className="sidebar card py-2">
                  <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item">
                      <Link
                        className="nav-link active1 fontSizeClass"
                        to="/Water_Supply"
                      >
                        {t("Water Supply Home")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/PlumberDetails.jsp"
                      >
                        {t("Find Plumbers")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Download_Forms"
                      >
                        {t("Download Forms")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Water_Supply_Fee_Charges"
                      >
                        {t("Fees and Charges")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/WaterFee.jsp"
                      >
                        {t("Check Demands Payable")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/WaterSupplyDemandPrint.jsp"
                      >
                        {t("Print Unpaid Bill")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/KMCPortalWaterSupplyPaymentAction.do"
                      >
                        {t("Make Online Payment")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/WSDuplicateReceipt.jsp"
                      >
                        {t("Reprint e-Receipt")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link fontSizeClass"
                        href="https://www.kmcgov.in/KMCPortal/jsp/WSPaymentStatus.jsp"
                      >
                        {t("Check Demand Status")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Water_Supply_Faqs"
                      >
                        {t("FAQs")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Water_Supply_Dept"
                      >
                        {t("Contact")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Water_For_Fire_Fighter"
                      >
                        {t("Water For Fire Fighters")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        className="fontSizeClass "
                        onClick={(e) => fileDownload("1587")}
                      >
                        {t(
                          "Action Plan to reduce water losses to less than 20%"
                        )}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="container">
                <div className="">
                  <div className="inner-title2">
                    <h3 className="fontSizeClass"> {t("Water Supply")} </h3>
                  </div>
                  <p className="fontSizeClass">
                    {t(
                      "Information sheet regarding generation of water supply showing the list of plants, reservoirs and Boosting stations."
                    )}
                  </p>

                  <div id="accordion">
                    <div className="card">
                      <div className="card-header" id="heading1">
                        <button
                          className="btn btn-link fontSizeClass  m-2"
                          data-toggle="collapse"
                          data-target="#collapse1"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          {t("Existing Water Treatment Plant")}
                          <i className="bi bi-arrow-right" />
                        </button>
                      </div>
                      <div
                        id="collapse1"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">
                            1.{t("Indira Gandhi Water Treatment Plant - Palta")}
                            <br />
                            2. {t("Garden Reach Treatment Plant")} <br />
                            3. {t("Jai Hind Jal Prakalpa - Dhapa")} <br />
                            4. {t("Jorabagan Water Treatment Plant")} <br />
                            5. {t("Watgunge Water Treatment Plant")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading2">
                        <button
                          className="btn btn-link fontSizeClass  m-2"
                          data-toggle="collapse"
                          data-target="#collapse2"
                          aria-expanded="true"
                          aria-controls="collapse2"
                        >
                          {t("Water Treatment Plant (Under Construction)")} :
                          <i className="bi bi-arrow-right" />
                        </button>
                      </div>
                      <div
                        id="collapse2"
                        className="collapse"
                        data-parent="#accordion"
                      >
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">
                            1. {t("Garden Reach Water Works - 25 MGD Capacity")}
                            . <br />
                            2.
                            {t(
                              "Indira Gandhi Water Treatment Plant - Palta - 20 MGD"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading3">
                        <button
                          className="btn btn-link fontSizeClass  m-2"
                          data-toggle="collapse"
                          data-target="#collapse3"
                          aria-expanded="true"
                          aria-controls="collapse3"
                        >
                          {t(
                            "Existing Booster Pumping Station with Reservoirs"
                          )}
                          : <i className="bi bi-arrow-right" />
                        </button>
                      </div>
                      <div
                        id="collapse3"
                        className="collapse"
                        data-parent="#accordion"
                      >
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">
                            {t(
                              "Tallah pumping station,Raja Subodh Mullick Square, Auckland Square, Behala, Md. Ali Park, Garfa, Park Circus, Ranikuthi, Kalighat, Bansdroni, Kasba, Bagmari, New Park, Daspara, Siriti, Anandapur, Gandhi Maidan, Telipara, Baishnabghata Patuli, Chetla, G J Khan Road, Khejurtala, Shanti Pally, Hatisur, Phool Bagan, Narkel Bagan, Lalgata, Mukundapur,Harish Park,Convent Park,Prafulla Park,Chaulpatty Road,Sen Pally,Parnashree Vivekananda Kanan,Layalka,Behala Chourastha,Bansdroni UGR Extension,Cocola Bagan,Agrashi Math,Hindustan Park,Nagendra Uddan,Airport Road,Poddar Nagar,Jodhpur Park Head Work,Golf Green Head Work,Ram Thakur Asram,Oposite Navina Badamtala,Near Burial Ground,Chandni Ghosh Road,Naktala,Netaji Nagar,Layelka Head Work,86 Pally Math,Rajani Mukherjee Rd,Parnasree Quarter"
                            )}
                            .
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading4">
                        <button
                          className="btn btn-link fontSizeClass  m-2"
                          data-toggle="collapse"
                          data-target="#collapse4"
                          aria-expanded="true"
                          aria-controls="collapse4"
                        >
                          {t("Booster Pumping Station (Under Construction)")}:
                          <i className="bi bi-arrow-right" />
                        </button>
                      </div>
                      <div
                        id="collapse4"
                        className="collapse"
                        data-parent="#accordion"
                      >
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">
                            {t(
                              "Anandapur Extension,Dhapa Water Work,C.N.Roy Road,Hatgachia Panchannagram,Panchasayar,Kayasthyapara, Achena Park,C.R.Colony,Subhash Pally,Garden Reach Water Work,Shakuntala Park,Bangur Park,Babu Bagan,Sachin Sengupta Park,Taramani Ghat,Deshpryia Park,Townshed(Golmath),4 No Bandh ,Park Near AMRI,Swamiji Sarak,Green Park,Jaysree Park,G.S.Bose Road"
                            )}
                            .
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading5">
                        <button
                          className="btn btn-link fontSizeClass  m-2"
                          data-toggle="collapse"
                          data-target="#collapse5"
                          aria-expanded="true"
                          aria-controls="collapse5"
                        >
                          {t(
                            "Information sheet for how to get water supply connection in buildings"
                          )}
                          : <i className="bi bi-arrow-right" />
                        </button>
                      </div>
                      <div
                        id="collapse5"
                        className="collapse"
                        data-parent="#accordion"
                      >
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">
                            {t(
                              "Different steps for getting water supply connection"
                            )}
                            <br />●
                            {t(
                              "Contact to Assistant Engineer of the Local Office of Water Supply Department"
                            )}
                            . <br />●
                            {t("List of Plumbers will be available from them")}.
                            <br />●
                            {t(
                              "Select a plumber and the plumbers prepare the estimate. The user apply in the prescribed format and placed before Assistant Engineer for sanction"
                            )}
                            . <br />●
                            {t(
                              "After sanction deposit the amount and get connection."
                            )}
                            <br />
                            {t(
                              "Fees includes Deposit money, Water charges, Connection fees, Road opening fees, Road restoration fees etc"
                            )}
                            .
                            <br />
                            {t(
                              "One premises entitled to get one Water Supply Connection"
                            )}
                            . <br />
                            {t(
                              "If any premises wants to take more water they may contact Executive Engineer(Water Supply) of the respective Zone for water supply through Bulk Meter"
                            )}
                            .
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading6">
                        <button
                          className="btn btn-link fontSizeClass  m-2"
                          data-toggle="collapse"
                          data-target="#collapse6"
                          aria-expanded="true"
                          aria-controls="collapse6"
                        >
                          {t(
                            "Charges on Bulk Supply of water through meters u/s 262, and 265"
                          )}
                          : <i className="bi bi-arrow-right" />
                        </button>
                      </div>
                      <div
                        id="collapse6"
                        className="collapse"
                        data-parent="#accordion"
                      >
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">
                            {t(
                              "Different steps for getting water supply connection"
                            )}
                            <br />●
                            {t(
                              "Contact to Assistant Engineer of the Local Office of Water Supply Department"
                            )}
                            . <br />●
                            {t("List of Plumbers will be available from them")}.
                            <br />●
                            {t(
                              "Select a plumber and the plumbers prepare the estimate. The user apply in the prescribed format and placed before Assistant Engineer for sanction"
                            )}
                            . <br />●
                            {t(
                              "After sanction deposit the amount and get connection"
                            )}
                            .
                            <br />
                            {t(
                              "Fees includes Deposit money, Water charges, Connection fees, Road opening fees, Road restoration fees etc"
                            )}
                            .
                            <br />
                            {t(
                              "One premises entitled to get one Water Supply Connection"
                            )}
                            . <br />
                            {t(
                              "If any premises wants to take more water they may contact Executive Engineer(Water Supply) of the respective Zone for water supply through"
                            )}
                          </p>
                          <div className="mayor-table2 depart">
                            <table className="table table-bordered">
                              <thead>
                                <tr className="table-warning table-bor-2">
                                  <th scope="col"> {t("Type")} </th>
                                  <th className="water-tab1" scope="col">
                                    {t("Water Charge (per kl)")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td scope="row">
                                    {t(
                                      "Bulk Domestic : (Including Housing Complexes, both rental & ownership) or owned by State & Central Govt. & other public bodies as well as private housing estates with less than 25% of non-residential use"
                                    )}
                                  </td>
                                  <td> {t("Rs. 6/-")} </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    {t("Government Hospitals")}
                                  </td>
                                  <td> {t("Rs. 7/-")} </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    {t(
                                      "Bulk ICI (Private Hospital, Defense Establishment other than housing complexes, Railway and other commercial establishment)"
                                    )}
                                  </td>
                                  <td> Rs. 15/- </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    {t(
                                      "Multistoried Buildings With 25% or more Commercial area"
                                    )}
                                  </td>
                                  <td> Rs. 12/- </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    {t("Other than KMC area")}
                                  </td>
                                  <td> - </td>
                                </tr>
                                <tr>
                                  <td scope="row"> a){t("Domestic")} </td>
                                  <td> Rs. 12/-</td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    {t("Government Hospitals")}
                                  </td>
                                  <td> Rs. 7/-</td>
                                </tr>
                                <tr>
                                  <td scope="row"> b) {t("ICI")} </td>
                                  <td> Rs. 18/-</td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    c) {t("Domestic + Partly Commercial")}
                                  </td>
                                  <td> Rs. 15/-</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p className="fontSizeClass">
                            {t(
                              "Fees Structure for commercial water supply If any Commercial consumer take Connection through ferrule connection, the rates according to the sizes are given below"
                            )}
                            :
                          </p>
                          <div className="mayor-table2 depart">
                            <table className="table table-bordered">
                              <thead>
                                <tr className="table-warning table-bor-2">
                                  <th className="water-tab1" scope="col">
                                    {t("Ferrule Size")}
                                  </th>
                                  <th scope="col">
                                    {t(
                                      "Building-Business/Non-domestic (Permonth) in Rs."
                                    )}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td scope="row">10.00 mm </td>
                                  <td> 525 </td>
                                </tr>
                                <tr>
                                  <td scope="row"> 15.00 mm </td>
                                  <td> 1200 </td>
                                </tr>
                                <tr>
                                  <td scope="row"> 20.00 mm </td>
                                  <td> 2000 </td>
                                </tr>
                                <tr>
                                  <td scope="row"> 25.00 mm </td>
                                  <td> 3000 </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    {t("Other than KMC area")}
                                  </td>
                                  <td> - </td>
                                </tr>
                                <tr>
                                  <td scope="row"> a){t("Domestic")} </td>
                                  <td> Rs. 12/-</td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    {t("Government Hospitals")}
                                  </td>
                                  <td> Rs. 7/-</td>
                                </tr>
                                <tr>
                                  <td scope="row"> b) ICI </td>
                                  <td> Rs. 18/-</td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    c) {t("Domestic + Partly Commercial")}
                                  </td>
                                  <td> Rs. 15/-</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading7">
                        <button
                          className="btn btn-link fontSizeClass  m-2"
                          data-toggle="collapse"
                          data-target="#collapse7"
                          aria-expanded="true"
                          aria-controls="collapse7"
                        >
                          {t("For not getting water")}....
                          <i className="bi bi-arrow-right" />
                        </button>
                      </div>
                      <div
                        id="collapse7"
                        className="collapse"
                        data-parent="#accordion"
                      >
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">
                            <strong>
                              1.
                              {t(
                                "For not getting water - Complaint is required to contact the corresponding office of his/her locality as mentioned below"
                              )}
                              :-
                            </strong>
                          </p>
                          <div className="mayor-table2 depart">
                            <table className="table table-bordered">
                              <thead>
                                <tr className="table-warning table-bor-2">
                                  <th scope="col">
                                    {t("Name of local water supply")}
                                  </th>
                                  <th scope="col"> {t("Ward No")}.</th>
                                  <th scope="col">{t("Officers concerned")}</th>
                                  <th scope="col"> {t("Time")} </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td scope="row">
                                    Cossipur Section, 10, B.T.
                                    Road,Kolkata-700002 Ph: 2558 8553
                                  </td>
                                  <td> 1,2,3,4,5,6 </td>
                                  <td> Asstt. Engineers </td>
                                  <td> From 7-30 to 11-00 A.M. </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Hatibagan Section, 79, Bidhan Sarani,
                                    Kolkata-700006 Ph: 2543 1602
                                  </td>
                                  <td> 7,8,9,10,11,12,15 </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Brindaban Bose Section, 28, Brindaban Bose
                                    Lane, Kolkata-700006, KMC Building
                                  </td>
                                  <td>16,17,18, 19,20</td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    B. K. Pal Section, 105, B. K. Pal Avenue
                                    (Near the B.K. Pal Park), Kolkata-700005
                                  </td>
                                  <td> 21,22,23,24,25 </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Hedua Section, 6, Azad Hind Bagh, Hedua
                                    Square, Kolkata-700006
                                  </td>
                                  <td> 26,27,28,38,39 </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Section (Maniktala) 109, Narkeldanga Main
                                    Road, Kolkata-700011 Ph: 2364 9252
                                  </td>
                                  <td> 13,14,29,30,31,32,33,34,35 </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Section 'E' 1, Neel Madhab Sen Lane(Near Md.
                                    Ali Park), Kolkata-700011
                                  </td>
                                  <td> 41,42,43, 44,45 </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Section 'F' 22, Surya Sen Street,
                                    Kolkata-700012 (Near Borough V office)
                                    Ph:2219 2399
                                  </td>
                                  <td> 36,37,40, 48,49,50 </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Section 'G', 23, Princep
                                    Street,Kolkata-700072
                                  </td>
                                  <td> 46,47,51 </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Section 'H' Hazi Md. Mohsin Square
                                    (Goltalab), Kolkata-700016
                                  </td>
                                  <td> 52,53,61,62,63 </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Section 'I', 13, Dilkhusha Street (Near Park
                                    Circus Tram Depot), Kolkata-700017
                                  </td>
                                  <td> 64,65 </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Section 'J', P-65, Dr. Sundari Mohan Avenue,
                                    Kolkata-700014 KMC Office
                                  </td>
                                  <td> 54,55,56,57, 58,59,60 </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Picnic Garden, 105/2, Prasanna Naskar Lane
                                    (Near Colony Bazar), Kolkata-700039 Ph: 2334
                                    3900
                                  </td>
                                  <td> 66,67</td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Section 'K', 212, Rashbehari Avenue
                                    (Gariahat Market, 3rd Floor), Kolkata-700025
                                  </td>
                                  <td> 68,69,72,84,85,86,87,90 </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Section 'L', 125, Hazra Road (At Hazra Road
                                    and Harish Mukherjee Road Crossing),
                                    Kolkata-700026 Ph: 2455 7978
                                  </td>
                                  <td> 70,71,73,74,82,83,88</td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Section 'M', 39, Circular Garden Reach Road
                                    (At Sudhi Bose Road and C.G.R.D. Road
                                    Junction), Kolkata-700024
                                  </td>
                                  <td> 75,76,77,78, 79,80 </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Section 'Tollygunge', Opposite Golf Green
                                    Daily Market, Uday Sankar Sarani,
                                    Kolkata-700045 Ph: 2483 6604
                                  </td>
                                  <td>81,89,91,92,93,94,95,96,97,98,99,100</td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    97 & 98, Garfa Main Road, Borough - XII
                                    Office, Kolkata - 700 075
                                  </td>
                                  <td> 103, 104, 105, 106, 107, 108, 109 </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    Baghajatin Market Complex, Unit-III,
                                    Baghajatin Station Road, Kolkata - 700 086
                                  </td>
                                  <td> 101, 102, 110, 111, 112, 113, 114 </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    S.S. Unit, Behala, 516, D.H. Road,
                                    Borough-XIII and XIV Office, Kolkata - 700
                                    034
                                  </td>
                                  <td>
                                    115, 116, 117, 118, 119, 120, 121, 122, 127,
                                    128, 129, 130, 131, 132
                                  </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row">
                                    G.R. Unit, E/3, Circular Garden Reach Road,
                                    Borough - XV Office, Kolkata - 700 024
                                  </td>
                                  <td>
                                    133, 134, 135, 136, 137, 138, 139, 140, 141
                                  </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row"> Br. XVI office, JOKA </td>
                                  <td> 123, 124, 125, 126, 142, 143, 144 </td>
                                  <td> Do </td>
                                  <td> Do </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading8">
                        <button
                          className="btn btn-link fontSizeClass  m-2"
                          data-toggle="collapse"
                          data-target="#collapse8"
                          aria-expanded="true"
                          aria-controls="collapse8"
                        >
                          {t("Special supply by water tanker")}
                          <i className="bi bi-arrow-right" />
                        </button>
                      </div>
                      <div
                        id="collapse8"
                        className="collapse"
                        data-parent="#accordion"
                      >
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">
                            2.
                            {t(
                              "Special supply by water tanker. party is required to contact following Telephone Numbers between 7 A.M. to 6 P.M. For each tanker of 3600 litre capacity the party has to deposit Rs. 450/- within Ward 1 - 141."
                            )}
                          </p>
                          <p className="fontSizeClass">
                            <strong>
                              {t(
                                "H.P.L. 6, Convent Rd. , Ph. - 2265-5785 (Central)"
                              )}
                              <br />
                              {t(
                                "Assistant Engineer (Mains) - Ph. - 2265-5845 <br /> 79, Bidhan Sarani (North) - 25431602 <br /> Kalighat (West)"
                              )}
                              - <br />
                              {t(
                                "Poddar nagar (South & Jadavpur Unit) - 24132232"
                              )}
                              <br />
                              516, D H Road (Behala & Joka) - 23970135 <br />
                              E-3, C G R Road (Garden Reach) - 24693283 <br />
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading9">
                        <button
                          className="btn btn-link fontSizeClass  m-2"
                          data-toggle="collapse"
                          data-target="#collapse9"
                          aria-expanded="true"
                          aria-controls="collapse9"
                        >
                          {t(
                            "Any other complaint on water supply including contamination"
                          )}
                          .<i className="bi bi-arrow-right" />
                        </button>
                      </div>
                      <div
                        id="collapse9"
                        className="collapse"
                        data-parent="#accordion"
                      >
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">
                            {t(
                              "Report may be lodged at the local water supply office mentioned above or K.M.C. Control Room during odd hrs. :- Tel. Numbers and address of the K.M.C. water supply zonal offices."
                            )}
                          </p>
                          <div className="mayor-table2 depart">
                            <table className="table table-bordered">
                              <thead>
                                <tr className="table-warning table-bor-2">
                                  <th scope="col">
                                    {t("Name of Zonal water supply office")}
                                  </th>
                                  <th scope="col"> {t("Address")} </th>
                                  <th scope="col"> {t("Ph. No")}. </th>
                                  <th scope="col"> {t("Ward No")}. </th>
                                  <th scope="col">{t("Officer concerned")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td scope="row"> {t("North")} </td>
                                  <td> 79, Bidhan Sarani, Kolkata-700006 </td>
                                  <td> 2543-1602 </td>
                                  <td> 1-12,15-28,38-39 </td>
                                  <td> Executive Engineer </td>
                                </tr>
                                <tr>
                                  <td scope="row"> Central </td>
                                  <td> 5, S.N. Banerjee Rd. Kolkata-700013 </td>
                                  <td> 2286-1000 to 08, Ext. 2524 </td>
                                  <td> 13-14,29-37,40-65, </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row"> South </td>
                                  <td>
                                    Poddar Nagar, 135,Prince Anwar Shah Rd.,
                                    Kolkata-700068
                                  </td>
                                  <td> 2413-2232 </td>
                                  <td> 81-89,91-100 </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row"> West </td>
                                  <td>
                                    212 Rash Bihari Avenue, Kolkata-7000025
                                  </td>
                                  <td> 2440-2793 </td>
                                  <td> 66-80, 82-88,90 </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row"> Jadavpur Unit </td>
                                  <td>97, 98 Garfa Main Rd, Kolkata-700075</td>
                                  <td> 2418-0645 </td>
                                  <td> 101-114 </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row"> S.S.Unit </td>
                                  <td>
                                    516 D.H. Rd. Near S.B.I, Kolkata-700034
                                  </td>
                                  <td> 2397-0135 Extn Water Supply </td>
                                  <td> 115-132 </td>
                                  <td> Do </td>
                                </tr>
                                <tr>
                                  <td scope="row"> Garden Reach Unit </td>
                                  <td>
                                    E-3, C.G. R. rd. (Near Hindusthan Liver),
                                    Kolkata-700024
                                  </td>
                                  <td> 2469-3283 </td>
                                  <td> 133-141 </td>
                                  <td> Do </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p className="fontSizeClass">
                            4.
                            {t(
                              "For Tubewell contact Executive Engineer Tubewell at 5, S.N. Banerjee Rd. Kolkata-13, Phn - 22861000, Ext. 2567"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Water_Supply