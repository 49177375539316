import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EWaste  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <div className="fontSizeClass">
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"> <h4 className="fontSizeClass"></h4></div></div>             
    </div>       	 		 
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">           
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Contact_Us_Home'> {t("Contact Us Home")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/EWaste'> {t("E-Waste")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/'>  {t("Important Contacts")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MMIC'> {t("MMIC")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Controlling_Officers'> {t("Controlling Officers")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Councillors'> {t("Councillors")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Borough_Committee'> {t("Borough Committee")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Borough_Committee'> {t("Borough Offices")} </Link> </li>
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCWard.jsp'> {t("Know Your Ward")}</a> </li>              								
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2"><h3 className="fontSizeClass"> {t("Contact Us")}: </h3></div>		   
            <div className="col-lg-12 about-head1">
              <div className="mayor-table2">
                <h5> <strong>Electronic waste or e-Waste [e-waste management rules, 2016]</strong> </h5>
                <p>e-waste means electrical and electronic equipment. whole or in part discarded as waste by the 
                    consumer or bulk consumer as well as rejects from manufacturing, refurbishment and repair processes.
                    ther details regarding e-waste are available in e-waste management rule 2016.</p>		 	   
                <table className="table table-bordered"> 			
                  <tbody>
                    <tr>              
                      <td> <strong> {t("E-Waste Collection Centre")} -</strong> <br /> 
                        <p className="fontSizeClass"> {t("Dhapa Garage")}  <br />
                        {t("Ph")} : The Kolkata Municipal Corporation <br />
                        Tangra, Kolkata 
                         </p> </td>
                      <td> <strong> {t("For disposal of E-Waste,Contact Call Centre")}  - </strong> <br /> 
                        <p className="fontSizeClass"> {t("Ph")}: 18003453375 (Toll Free) <br />
                        Mon-Fri : 10:00 AM to 6:00 PM <br />
                        Sat :        10:00 AM to 5:00 PM <br />
                       
                          </p> </td>
                    </tr>
                    <tr>
                      <td colSpan={2}> 
                        <p className="fontSizeClass"> {t("Mr.Amit Kumar Bera,Deputy Manager(Systems)-9804103317 ")} <br />
                          {t("Mr.Avijit Kumar Dutta,Asst. Manager(Systems)-9883014880")}
                          </p></td>             
                    </tr>
                             
                  </tbody>
                </table>
                
              </div>			
            </div>		  
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>
</div>
  )
}

export default EWaste