import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CH_VS_Dept_Print_Unpaid_Bill = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const [ReprintServices, setReprintServices] = React.useState(false);

  const ReprintServicesToggle = () => {
    setReprintServices(!ReprintServices);
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
<img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      <div className="container">
        
        <div className="section-main-heading2">
          
        </div>
      </div>
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item">
                    <Link className="nav-link active1 fontSizeClass" to='/CH_VS_Dept_Download_Forms'>
                      {t("Download Forms")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Ch_VS_Dept_Rates_Charges'>
                      {t("Fees & Charges")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/CH_VS_Dept_Print_Unpaid_Bill'>
                      {t("Print Unpaid Bill")}
                    </Link>
                  </li>
                  
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/CH_VS_Dept_Make_online_payment'>
                      {t("Make Online Payment")}
                    </Link>
                  </li>
                  <li className="nav-item ">
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Reprint e-Receipt")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo" className="collapse">                        
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/SorDuplicateReceipt.jsp'><i className="bi bi-chevron-right" /> SOR </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/SorDevDuplicateReceipt.jsp'><i className="bi bi-chevron-right" /> {t("Development Fee")} </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/SorGisMapDuplicateReceipt.jsp'><i className="bi bi-chevron-right" />3. {t("GIS Map")}  </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/SorLeaseRentDuplicateReceipt.jsp'><i className="bi bi-chevron-right" />4. {t("Less/Rent/License")}  </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/SorMiscDuplicateReceipt.jsp'><i className="bi bi-chevron-right" />5. {t("Miscellanous")}  </a>
                      </div>
                </li>
                  <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Ch_VS_Dept'> {t("Contact Details")} </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/portal'>
                      {t("Public Notice Modification Cancellation of Alignment")}
                    </Link>
                  </li>
                </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass"> {t("Print Unpaid Bill")} </h3>
              </div>
              <div className="about-head1">
                <div className="dig-login1 dig-login2">
                  <form>
                    <div className="log-form inner-log-form">
                      <h3 className="fontSizeClass"> {t("Unpaid Demand Details")} </h3>
                      <div className="row">
                        <div className="col-lg-6 col-md-12 form-group pt-2">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            
                            {t("Demand No")}*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={1234567890}
                            aria-label="Username"
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 form-group pt-2" style={{marginTop:"40px"}}>
                          <div className="form-group continu">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              
                              &nbsp;
                            </label>
                            <button className="btn btn-primary" type="button">
                              
                              {t("Search")}
                            </button>
                            <button className="btn btn-primary" type="button">
                              
                              {t("Print Demand")}
                            </button>
                          </div>
                        </div>
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          
                          &nbsp;
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default CH_VS_Dept_Print_Unpaid_Bill