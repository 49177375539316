import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Central_records_home  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container " >
        <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">           
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Central_records_home'> {t("Central Records Home")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Central_records_fee'> {t("Fees")} </Link> </li>
             </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title"><h3 className="fontSizeClass"> {t("Central Records Dept :: FAQs")} </h3>
              </div>
                  <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="heading1">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                        {t("What are the key activities of the department")}? 
                    </button>
                    </div>
                    <div id="collapse1" className="collapse show" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      {t("The key activities of the Central Records Department include")}:
                        a. {t("Preserving registers and documents pertaining to birth, death, building plans, assessment, trade licenses")}.
                        b. {t("Issuing certified copies related to the assessment trade license on payment of prescribed fees")}
                        c. {t("Selling of Tender forms, Power tender Barta, street list, etc")}.
                        d. {t("Free distribution of different forms")}
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading2">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                      2.{t("What forms/records does the department distribute free of cost")}?
                    </button>
                    </div>
                    <div id="collapse2" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      {t("The following forms are distributed by the department free of cost")}:
                        a. {t("A 42 (Used for Assessment)")}
                        b. {t("A 75-1 (Used for Assessment)")}
                        c. {t("A75-2 (Used for Assessment)")}
                        d. {t("Building Application")}
                        e {t("Erection of building by a t")}
                        f. {t("Birth/ Form 24 H")}
                        g. {t("Death/ Form 23 H")}
                        h. {t("License")}
                        i. {t("Amusement")}
                        j. {t("Advertisement")}
                        k. {t("Water Supply")}
                        l. {t("Form of National family benefit scheme (NFBS)")}
                        m. {t("Forms of national old age pension scheme (NOAPS)")}
                      </div>                      
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading3">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                      3. {t("Where can I complaint if there is a delay in obtaining my records")}?
                    </button>
                    </div>
                    <div id="collapse3" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                            {t("We take every endeavor to ensure that we hand out record requests on time. In the unlikely case of delays in receipt of records you may visit us at Central Records Department, Ground Floor, 5 SN Banerjee Road, Kolkata 700013, Phone (033) 2286 1000 Extension – 2481 (Superintendent Records) / Dy. Manager (Central Records), Extension - 2901 You may speak with the Municipal secretary at extension 2402")}. 
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                      4. {t("What is the methodology of application, documents to be submitted, estimated timelines with respect to the permissions required from the central records department (CRD)")}?
                    </button>
                    </div>
                    <div id="collapse4" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <div className="mayor-table">
                        <table className="table table-bordered officials">
                        <tbody>
                        <tr bgcolor="#302ba0" tabIndex={0}>
                            <td rowSpan={2} style={{"color":"#fff"}} align="center" valign="middle">{t("Sl. No")}.</td>
                            <td rowSpan={2} style={{"color":"#fff"}}>{t("Permission required")}</td>
                            <td rowSpan={2} style={{"color":"#fff"}}>{t("Application procedure/Pick up Completed form from")}?</td>
                            <td rowSpan={2} style={{"color":"#fff"}}> {t("Documents to be submitted")}</td>
                            <td rowSpan={2} style={{"color":"#fff"}}> {t("Forms to be submitted to")}</td>
                            <td rowSpan={2} style={{"color":"#fff"}}> {t("Lead Time")}</td>
                            <td rowSpan={2} style={{"color":"#fff"}}> {t("Cost and payment mechanism")} </td>
                        </tr>
                    <tr>
                      {/* <td width="20%" style="padding:3">Office</td>
					<td width="13%" style="padding:3">Resi.</td> */}
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff" tabIndex={0}>
                      <td>1.</td>
                      <td align="left">{t("Birth/Death Certificate")}</td>
                      <td align="left">1. {t("Obtain & fill form OS 105 from the Central Records Department at the ground floor of KMC headquarters")}
                        2. {t("KMC staff searches building plans register")}
                        3. {t("Pay search Fee at CRD per schedule")}
                        4. {t("Once details are located, fill form 23H")}
                        5. {t("Contact certificate section of health department with cash receipt & form 23H")}
                        6. {t("Pay prescribed Fee at health department")}</td>
                      <td align="left">{t("Copy of birth/death certificate if available")}</td>
                      <td align="left">OS 105, 23H	</td>
                      <td align="left">20 – 30 {t("minutes")}</td>
                      <td align="left">	{t("Free of cost")}
                        {t("Health Department Fee: 100 – Death, 100 - Birth")}</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff" tabIndex={0}> 
                      <td>2.</td>
                      <td align="left">{t("Building Plans")}</td>
                      <td align="left">1. {t("Obtain & fill form OS 105 from the Central Records Department at the ground floor of KMC headquarters")}
                        2. {t("KMC staff searches building plans register")}
                        3. {t("Pay search Fee & collect receipt")}
                        4. {t("Contact Building department with receipt (must mention building sanction number)Apply at building department for building plan")}</td>
                      <td align="left">{t("Tax Bill, Copy of Deed of ownership")}</td>
                      <td align="left">OS 105</td>
                      <td align="left">{t("1 day (does not include time taken by building department)")}</td>
                      <td align="left">{t("Search Fee Rs 400 for property owner & Rs 600 for non-owners. Rs.60 to be paid extra as searching fee")}.
                        {t("Application Fee (Bldg Dept) Rs 60")}</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>3.</td>
                      <td align="left">{t("Assessment Documents")}
                        {t("(For Years Prior to 1950only)")}
                        {t("Note: For years after 1950 contact assessment department")}</td>
                      <td align="left">1. {t("Obtain & fill form OS 105 from the Central Records Department at the ground floor of KMC headquarters")}
                            2. {t("KMC staff searches assessment register")}
                            3. {t("Pay search Fee & collect receipt")}
                            4. {t("Department issues certified copy of assessment register")}</td>
                      <td align="left">{t("Record of ownership such as Tax Receipt, Sale Deed")}</td>
                      <td align="left">OS 105</td>
                      <td align="left">	7 – 10 days</td>
                      <td align="left">{t("Search For Pukka House – Rs150/500/1000 depending on annual valuation Bustee – Rs 50 to Rs 100")}
                        {t("Certified copy for Pukka House 100/150/200/1000")}
                        {t("Certified copy for bustee - Rs 50 to Rs 100")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>4.</td>
                      <td align="left">{t("License Documents")}</td>
                      <td align="left">1. {t("Obtain & fill form OS 105 from the Central Records Department at the ground floor of KMC headquarters")}
                        2. {t("KMC staff searches license register")}
                        3. {t("Pay search Fee & collect receipt")}
                        4. {t("Department issues certified copy of assessment register")}</td>
                      <td align="left">{t("Copy of old license")}</td>
                      <td align="left">OS 105</td>
                      <td align="left">7 – 10 {t("days")}</td>
                      <td align="left">	{t("Only Search – Rs 75, Search + Certificate – 150")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>5.</td>
                      <td align="left">	{t("Electoral rolled")}</td>
                      <td align="left">1. {t("Obtain & fill form OS 105 from the Central Records Department at the ground floor of KMC headquarters")}
                        2. {t("KMC staff searches license register")}
                        3. {t("Pay search Fee & collect receipt")}
                        4. {t("Department issues certified copy of assessment register")}</td>
                      <td align="left">{t("Copy of old license")}	</td>
                      <td align="left">OS 105</td>
                      <td align="left">7 – 10 {t("days")}</td>
                      <td align="left">i) {t("Searching fees for one entry")} – Rs.50
                        ii) {t("Certified extract with searching fee for each entry (ordinary)")} – Rs.90
                        iii) {t("Certified extract with searching fee for each entry for urgent delivery ")}– Rs.100</td>
                    </tr> 
                        </tbody></table>
                      </div>
                      <div className="mayor-table my-4" style={{overflow:"hidden"}}>
                        <table className="table table-bordered officials">
                        <tbody>
                        <tr bgcolor="#302ba0" tabIndex={0}>
                            <td rowSpan={2} style={{"color":"#fff"}} align="center" valign="middle">{t("Sl. No")}.</td>
                            <td rowSpan={2} style={{"color":"#fff"}}>{t("Department")}</td>
                            <td rowSpan={2} style={{"color":"#fff"}}>{t("Address")}</td>
                            <td rowSpan={2} style={{"color":"#fff"}}> {t("Contact person")}</td>
                            <td rowSpan={2} style={{"color":"#fff"}}> {t("Tel, Mobile No")}.</td>
                        </tr>
                        <tr>
                            {/* <td width="20%" style="padding:3">Office</td>
                            <td width="13%" style="padding:3">Resi.</td> */}
                        </tr>
                    <tr align="center" valign="middle" bgcolor="#fff" tabIndex={0}>
                      <td>1.</td>
                      <td align="left">{t("Central Records Department")}</td>
                      <td align="left">{t("Ground Floor, 5 SN Banerjee Road, Kolkata 700013")}</td>
                      <td align="left">{t("Superintendent of Records/ Dy.Manager of Record Department")}</td>
                      <td align="left">(033) 2286 1000 Extension – 2481, 2901</td>
                    </tr>
                    
                        </tbody></table>
                      </div>
                    </div>
                  </div>
                </div>
            </div> 
          </div>
        </div>
      </div>
        </div>
    </div>
  </section>
  
    </>
  )
}

export default Central_records_home