import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Dashboard = () => {
  const [LicenseService, setLicenseService] = React.useState(false);
  const [LicenseService1, setLicenseService1] = React.useState(false);
  const [LicenseService2, setLicenseService2] = React.useState(false);
  const [LicenseService3, setLicenseService3] = React.useState(false);

  const LicenseServiceToggle = () => {
    setLicenseService(!LicenseService);
  };

  const LicenseServiceToggle1 = () => {
    setLicenseService1(!LicenseService1);
  };

  const LicenseServiceToggle2 = () => {
    setLicenseService2(!LicenseService2);
  };

  const LicenseServiceToggle3 = () => {
    setLicenseService3(!LicenseService3);
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 pb-0 pt-0">
          <div className="bd-example bd-example-tabs">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 eodb-site-menu">
                <div
                  className="nav nav-tabs flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <a
                    className="nav-link active show"
                    id="eodb-1a"
                    data-toggle="pill"
                    href="#eodb-1"
                    role="tab"
                    aria-controls="eodb-1"
                    aria-selected="false">
                    {t("Building Construction Permit")}
                  </a>
                  <a
                    className="nav-link"
                    id="eodb-2a"
                    data-toggle="pill"
                    href="#eodb-2"
                    role="tab"
                    aria-controls="eodb-2"
                    aria-selected="false"
                  >
                    
                    {t("Civil- Road Cutting Permission")}
                  </a>
                  <a
                    className="nav-link"
                    id="eodb-3a"
                    data-toggle="pill"
                    href="#eodb-3"
                    role="tab"
                    aria-controls="eodb-3"
                    aria-selected="false">
                    {t("CE & Shops")}
                  </a>
                  <a
                    className="nav-link"
                    id="eodb-4a"
                    data-toggle="pill"
                    href="#eodb-4"
                    role="tab"
                    aria-controls="eodb-4"
                    aria-selected="true"
                  >
                    
                    {t("Water Connection")}
                  </a>
                  <a
                    className="nav-link"
                    id="eodb-5a"
                    data-toggle="pill"
                    href="#eodb-5"
                    role="tab"
                    aria-controls="eodb-5"
                    aria-selected="true"
                  >
                    
                    {t("Mutation")}
                  </a>
                  <a
                    className="nav-link"
                    id="eodb-6a"
                    data-toggle="pill"
                    href="#eodb-6"
                    role="tab"
                    aria-controls="eodb-6"
                    aria-selected="true"
                  >
                    
                    {t("Signage License for Advertisement")}
                  </a>
                  <a
                    className="nav-link"
                    id="eodb-7a"
                    data-toggle="pill"
                    href="#eodb-7"
                    role="tab"
                    aria-controls="eodb-7"
                    aria-selected="true"
                  >
                    
                    {t("Movie Shooting")}
                  </a>
                  <a
                    className="nav-link"
                    id="eodb-8a"
                    data-toggle="pill"
                    href="#eodb-8"
                    role="tab"
                    aria-controls="eodb-8"
                    aria-selected="true"
                  >
                    
                    {t("Mobile Tower Approval")}
                  </a>
                  <a
                    className="nav-link"
                    href="https://www.kmcgov.in/KMCPortal/jsp/UserRegistration.jsp"
                    target="_blank"
                  >
                    
                    {t("Register")}
                  </a>
                </div>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-6">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade active show"
                    id="eodb-1"
                    role="tabpanel"
                    aria-labelledby="eodb-1a"
                  >
                    <h3 className="eodb-titl">
                      
                      {t("Building Construction Permit")}
                    </h3>
                    <div className="eodb-but1 eodb-pdf eodb-1">
                      <ul className="eodb-pd-li m-0">
                          <li>
                            <a type="button" className="btn p-0" data-toggle="modal" data-target="#exampleModalCenter">
                              {t("About The Service")} <i className="bi bi-chevron-right" /></a>
                          </li>
                      </ul>
                      <div
                        className="modal fade"
                        id="exampleModalCenter"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLongTitle"
                              >
                                
                                {t("About the service- Building Construction Permit")}
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="mod-hed-1">
                                <img src="assets/img/logo.png" alt="alt-image" />
                                <p>
                                  {t("The online Building Construction Permit system has the following features without the requirement of physical visit href the department.")}
                                </p>
                              </div>
                              <ul className="model-list">
                                <li> 1. {t("Submision of Application")}.</li>
                                <li> 2. {t("Payment of Application Fees")}.</li>
                                <li> 3. {t("Track Status of Application")}.</li>
                                <li>
                                  
                                  4. {t("Download the Final Signed Certificate")}.
                                </li>
                                <li> 5. {t("Third Party Verification")}. </li>
                              </ul>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                {t("Thank You")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="eodb-pdf eodb-1">
                      <li className="eodb-pd-li m-0">
                        <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo">{t("Application")}<i className="bi bi-chevron-right" /></button>
                        <div id="demo" className="collapse"> 
                            <li><Link to="/Dashboard2"> {t("CAF")} </Link> </li>
                            <li><a href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/BuildingPlinthCmplDeclaration.jsp" target="_blank"> {t("Plinth Level")} </a></li>
                            <li><a href=""> {t("Completion/ Occupancy Certificate")}</a></li>
                            <li><a href=""> {t("NOC")} </a></li>
                            <li><a href="https://www.kmcgov.in/KMCPortal/jsp/PlanProposalStatus.jsp" target="_blank"> {t("Sanction Status")} </a></li>
                        </div>
                      </li>
                    </div>
                    <div className="eodb-pdf ">
                      <ul className="eodb-pd-li m-0">
                        <li><a href="https://www.kmcgov.in/KMCPortal/jsp/BuildingDeptFees.jsp" target="_blank"> {t("Fees & Charges")}</a></li>
                        <li><a href="https://www.kmcgov.in/KMCPortal/downloads/EODB_CAF_PROCEDURE_FOR_SANCTION.pdf" target="_blank"> {t("Procedures")}</a></li>
                        <li><a href="https://www.kmcgov.in/KMCPortal/downloads/DOCUMENTS_TO_UPLOAD.pdf" target="_blank"> {t("List Of Documents")}</a></li>
                        <li><a href="https://www.kmcgov.in/KMCPortal/jsp/EodbCircular2020.jsp" target="_blank"> {t("Circulars")} </a></li>
                        <li><a href="https://www.kmcgov.in/KMCPortal/downloads/628_MA_and_627_MA_KMC_Building_permit.pdf" target="_blank"> {t("Notification")}</a> </li>
                        <li><a href=""> {t("Guidelines")} </a></li>
                      </ul>
                    </div>
                    <div className="eodb-pdf eodb-1">
                        <li className="eodb-pd-li m-0">
                          <button type="button" className="btn p-0" data-toggle="collapse" data-target="#demo1">{t("Application Status")}<i className="bi bi-chevron-right" /></button>
                          <div id="demo1" className="collapse"> 
                            <img src="assets/img/eodb-1.png" />
                          </div>
                        </li>
                        <li className="eodb-pd-li m-0">
                          <button type="button" className="btn p-0" data-toggle="collapse" data-target="#demo2">{t("Approval Time Statistics")}<i className="bi bi-chevron-right" /></button>
                          <div id="demo2" className="collapse"> 
                            <img src="assets/img/eodb-1.png" />
                          </div>
                        </li>
                      <div>
                      <li className="eodb-pd-li m-0">
                        <button type="button" className="btn p-0" data-toggle="collapse" data-target="#demo3">{t("WBRTPS Act")}<i className="bi bi-chevron-right" /></button>
                        <div id="demo3" className="collapse"> 
                         <ul className="eodb-li">
                            <li> <a href=""> {t("Engineering")} </a></li>
                            <li> <a href=""> {t("Building")} </a></li>
                            <li> <a href=""> {t("KMDA")} </a> </li>
                            <li> <a href=""> {t("Fire")} </a> </li>
                          </ul>
                        </div>
                      </li>
                      </div>
                      <p />
                    </div>
                    <div className="eodb-pdf">
                      <ul className="eodb-pd-li">
                        <li>
                          
                          <a
                            href="https://www.kmcgov.in/KMCPortal/jsp/PlanProposalStatus.jsp"
                            target="_blank"
                          >
                            
                            {t("Third Party Certification")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="eodb-2"
                    role="tabpanel"
                    aria-labelledby="eodb-2a"
                  >
                    <h3 className="eodb-titl">
                      
                      {t("Civil- Road Cutting Permission")}
                    </h3>
                    <div className="eodb-but1 eodb-pdf eodb-1">
                      <ul className="eodb-pd-li m-0">
                          <li>
                            <a type="button" className="btn p-0" data-toggle="modal" data-target="#exampleModalCenter">
                              {t("About The Service")} <i className="bi bi-chevron-right" /></a>
                          </li>
                      </ul>
                      <div
                        className="modal fade"
                        id="exampleModalCenter"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLongTitle"
                              >
                                
                                {t("About the service- Building Construction Permit")}
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="mod-hed-1">
                                <img src="assets/img/logo.png" alt="alt-image" />
                                <p>
                                  {t("The online Building Construction Permit system has the following features without the requirement of physical visit href the department")}.
                                </p>
                              </div>
                              <ul className="model-list">
                                <li> 1. {t("Submision of Application")}.</li>
                                <li> 2. {t("Payment of Application Fees")}.</li>
                                <li> 3. {t("Track Status of Application")}.</li>
                                <li>
                                  
                                  4. {t("Download the Final Signed Certificate")}.
                                </li>
                                <li> 5. {t("Third Party Verification")}. </li>
                              </ul>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                {t("Thank You")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="eodb-pdf">
                      <ul className="eodb-pd-li">
                        <li className="eodb-pd-li m-0">
                          <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo5">{t("Application")}<i className="bi bi-chevron-right" /></button>
                          <div id="demo5" className="collapse"> 
                          <ul className="eodb-li mt-4">
                              <li>                              
                                <a href="https://www.kmcgov.in/KMCPortal/jsp/IndustrialLoginPage.jsp?callbackURL=/KMCPortal/jsp/CESCRoadRestorationInd.jsp" target="_blank"> {t("Road Restoration Application")} </a>
                              </li>
                              <li>
                                <a href="https://www.kmcgov.in/KMCPortal/jsp/IndustrialLoginPage.jsp?callbackURL=/KMCPortal/jsp/ROW_Dashboard.jsp" target="_blank" > {t("Road Restoration Dashboard")} </a>
                              </li>
                              <li>
                                <a href="https://www.kmcgov.in/KMCPortal/jsp/IndustrialLoginPage.jsp?callbackURL=/KMCPortal/jsp/CESCRoadRestorationStatusInd.jsp" target="_blank"> {t("Application Status")} </a>
                              </li>
                              <li>                              
                                <a href="https://www.kmcgov.in/KMCPortal/jsp/IndustrialLoginPage.jsp?callbackURL=/KMCPortal/jsp/KMDAApplicationStatusInd.jsp" target="_blank"> {t("Application Approval")} </a>
                              </li>
                              <li>
                                <a href="https://www.kmcgov.in/KMCPortal/jsp/IndustrialLoginPage.jsp?callbackURL=/KMCPortal/jsp/RoadRestorationPayment.jsp" target="_blank"> {t("Demand Download")}</a>
                              </li>
                              <li>                              
                                <a href="https://www.kmcgov.in/KMCPortal/jsp/IndustrialLoginPage.jsp?callbackURL=/KMCPortal/jsp/RoadRestorationPaymentInfo.jsp" target="_blank"> {t("Payment Submission")} </a>
                              </li>
                              <li>                              
                                <a href="https://www.kmcgov.in/KMCPortal/jsp/IndustrialLoginPage.jsp?callbackURL=/KMCPortal/jsp/KPApproval.jsp" target="_blank"> {t("Kolkata Police Approval")}</a>
                              </li>
                              <li>                              
                                <a href="https://www.kmcgov.in/KMCPortal/jsp/IndustrialLoginPage.jsp?callbackURL=/KMCPortal/jsp/RoadRestorationPaymentInfoConfirm.jsp" target="_blank"> {t("Payment Confirmation")} </a>
                              </li>
                            </ul>  
                          </div>
                        </li>
                        <li> <a href="https://www.kmcgov.in/KMCPortal/jsp/ChiefEngFees1.jsp" target="_blank"> {t("Fees & Charges")} </a></li>
                        <li> <a href="EODB_ROW_Procedures.pptx" target="_blank"> {t("Procedures")} </a> </li>
                        <li> <a href="List_Documents.docx" target="_blank"> {t("List Of Documents")} </a></li>
                        <li> <a href="https://www.kmcgov.in/KMCPortal/downloads/Circular_MC_Circular177_12_03_2020.pdf" target="_blank"> {t("Circulars")}</a></li>
                        <li> <a href="https://www.kmcgov.in/KMCPortal/downloads/ROW_Notifications.pdf" target="_blank"> {t("Notification")} </a></li>
                        <li> <a href="https://www.kmcgov.in/KMCPortal/downloads/Guidelines_ROW_FlowChart.jpg" target="_blank"> {t("Guidelines")}</a>
                        </li>
                        <li className="eodb-pd-li m-0">
                        <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo6">{t("Application Status")}<i className="bi bi-chevron-right" /></button>
                        <div id="demo6" className="collapse"> 
                          <div className="card card-body">
                            <img src="assets/img/eodb-1.png" />
                          </div>
                        </div>
                        </li>
                        <li className="eodb-pd-li m-0">
                            <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo7">{t("Approval Time Statistics")}<i className="bi bi-chevron-right" /></button>
                              <div id="demo7" className="collapse"> 
                                <div className="card card-body">
                                  <img src="assets/img/eodb-1.png" />
                                </div>
                              </div>
                        </li>
                      </ul>
                    </div>
                    
                    <div className="eodb-pdf ">
                      <ul className="eodb-pd-li">
                        <li> <a href="" target="_blank"> {t("WBRTPS APPLICATION")} </a> </li>
                        <li> <a href="https://www.kmcgov.in/KMCPortal/jsp/PlanProposalStatus.jsp" target="_blank"> {t("Third Party Certification")} </a> </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="eodb-3"
                    role="tabpanel"
                    aria-labelledby="eodb-3a"
                  >
                    <h3 className="eodb-titl"> {t("CE & Shops")} </h3>
                    <div className="eodb-but1">
                      <button
                        type="button"
                        className="btn"
                        data-toggle="modal"
                        data-target="#exampleModalCenter3"
                      >
                        {t("About The Service")} <i className="bi bi-chevron-right" />
                      </button>
                      <div
                        className="modal fade"
                        id="exampleModalCenter3"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLongTitle"
                              >
                                
                                {t("About the service- Building Construction Permit")}
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="mod-hed-1">
                                <img src="assets/img/logo.png" alt="alt-image" />
                                <p>
                                  {t("The online Building Construction Permit system has the following features without the requirement of physical visit href the department.")}
                                </p>
                              </div>
                              <ul className="model-list">
                                <li> 1. {t("Submision of Application")}.</li>
                                <li> 2. {t("Payment of Application Fees")}.</li>
                                <li> 3. {t("Track Status of Application")}.</li>
                                <li>
                                  
                                  4. {t("Download the Final Signed Certificate")}.
                                </li>
                                <li> 5. {t("Third Party Verification")}. </li>
                              </ul>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                {t("Thank You")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eodb-pdf">
                      <ul className="eodb-pd-li">
                      <li className="eodb-pd-li m-0">
                          <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo5">{t("Application")}<i className="bi bi-chevron-right" /></button>
                          <div id="demo5" className="collapse"> 
                          <ul className="eodb-li">
                            <li><a href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/TradeLicence.jsp" target="_blank"> {t("New Certificate of Enlistment (CE)")}</a></li>
                            <li> <a href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/TradeLicenceShop.jsp" target="_blank"> {t("New CE & Shop Registration")} </a></li>
                            <li> <a href="https://www.kmcgov.in/KMCPortal/jsp/CafShopPaymentHome.jsp" target="_blank"> {t("Online Payment (CE & Shops)")} </a></li>
                            <li> <a href="https://www.kmcgov.in/KMCPortal/jsp/CafShopReceiptReprint.jsp" target="_blank"> {t("e-Receipt (CE & Shops)")} </a></li>
                            <li> <a href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/LicenceChangeCorrection.jsp" target="_blank"> {t("Change Of CE")} </a></li>
                            <li> <a href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/LicenceClosedForm.jsp" target="_blank"> {t("Closure of CE")}</a> </li>
                            <li> <a href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/LicenceRenewalApplicationForm.jsp" target="_blank"> {t("Renewal of CE")} </a> </li>
                            <li> <a href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/TradeLicenceStatusSearch.jsp" target="_blank"> {t("Search Application Status")}</a></li>
                          </ul>  
                          </div>
                        </li>
                        <li>
                          <a href="https://www.kmcgov.in/KMCPortal/jsp/EODBLicenseFeesCharges.jsp"target="_blank"> {t("Fees & Charges")} </a>
                        </li>
                        <li>
                          <a href="https://www.kmcgov.in/KMCPortal/jsp/EODBLicenseHelpLineNumber.jsp"target="_blank">{t("HelpLine Number")}</a>
                        </li>
                        <li>                          
                          <a href="https://www.kmcgov.in/KMCPortal/jsp/EODBLicenseWBRTPS.jsp" target="_blank"> {t("WBRTPS")} </a>
                        </li>
                        <li>
                          <a href="https://www.kmcgov.in/KMCPortal/downloads/TradeLicenceEoDBProcedure.pdf" target="_blank"> {t("Procedures")}</a>
                        </li>
                        <li> <a href="https://www.kmcgov.in/KMCPortal/downloads/Documents_For_Certificate.pdf" target="_blank"> {t("List Of Documents")}</a>
                        </li>
                        <li> <a href="https://www.kmcgov.in/KMCPortal/downloads/725_MA_Revised_Enlistment_Trade_Calling.pdf" target="_blank"> {t("Circulars")} </a> </li>
                        <li> <a href="https://www.kmcgov.in/KMCPortal/jsp/EODBDashboard.jsp?tab=ext-comp-1006" target="_blank"> {t("Notification")} </a></li>
                        <li> <a href="https://www.kmcgov.in/KMCPortal/downloads/725_MA_Revised_Enlistment_Trade_Calling.pdf" target="_blank"> {t("Guidelines")}</a></li>
                        <li className="eodb-pd-li m-0">
                      <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo8">{t("Application Status")}<i className="bi bi-chevron-right" /></button>
                      <div id="demo8" className="collapse"> 
                        <div className="card card-body">
                          <img src="assets/img/eodb-1.png" />
                        </div> 
                      </div>
                    </li>
                    <li className="eodb-pd-li m-0">
                      <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo9">{t("Approval Time Statistics")}<i className="bi bi-chevron-right" /></button>
                      <div id="demo9" className="collapse"> 
                        <div className="card card-body">
                          <img src="assets/img/eodb-1.png" />
                        </div> 
                      </div>
                    </li>
                    <li><a href="" target="_blank"> {t("WBRTPS Act")}</a></li>
                    <li><a href="https://www.kmcgov.in/KMCPortal/jsp/TradeLicenseInformation.jsp" target="_blank"> {t("Third Party Certification")}</a></li>
                    
                      </ul>
                    </div>
                    
                  </div>
                  <div
                    className="tab-pane fade"
                    id="eodb-4"
                    role="tabpanel"
                    aria-labelledby="eodb-4a"
                  >
                    <h3 className="eodb-titl"> {t("Water Connection")} </h3>
                    <div className="eodb-but1">
                      <button
                        type="button"
                        className="btn"
                        data-toggle="modal"
                        data-target="#exampleModalCenter4"
                      >
                        {t("About The Service")} <i className="bi bi-chevron-right" />
                      </button>
                      <div
                        className="modal fade"
                        id="exampleModalCenter4"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLongTitle"
                              >
                                
                                {t("About the service- Building Construction Permit")}
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="mod-hed-1">
                                <img src="assets/img/logo.png" alt="alt-image" />
                                <p>
                                  {t("The online Building Construction Permit system has the following features without the requirement of physical visit href the department")}.
                                </p>
                              </div>
                              <ul className="model-list">
                                <li> 1. {t("Submision of Application")}.</li>
                                <li> 2. {t("Payment of Application Fees")}.</li>
                                <li> 3. {t("Track Status of Application")}.</li>
                                <li>
                                  
                                  4. {t("Download the Final Signed Certificate")}.
                                </li>
                                <li> 5. {t("Third Party Verification")}. </li>
                              </ul>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                {t("Thank You")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eodb-pdf">
                      <ul className="eodb-pd-li">
                      <li className="eodb-pd-li m-0">
                          <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo10">{t("Application")}<i className="bi bi-chevron-right" /></button>
                          <div id="demo10" className="collapse"> 
                          <ul className="eodb-li mt-4">
                              <li> <a href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/WaterSupplyNewConnection1.jsp" target="_blank">{t("Apply for New Online Domestic Water Connection")}</a></li>
                              <li><a href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/WaterSupplySearchApplication1.jsp" target="_blank"> {t("Search Online Domestic Water Connection Application")}</a></li>
                            </ul>  
                          </div>
                      </li>
                        <li><a href="https://www.kmcgov.in/KMCPortal/jsp/WaterSupply.jsp" target="_blank"> {t("Information on tariffs on Water (For Domestic,Comme & Industrial User)")}</a></li>
                        <li><a href="" target="_blank">{t("Fees & Charges")}</a></li>
                        <li><a href="https://www.kmcgov.in/KMCPortal/downloads/OnlineDomesticWaterConnectionUserManual.pdf" target="_blank"> {t("Procedures")}</a></li>
                        <li><a href="" target="_blank"> {t("List Of Documents")}</a></li>
                        <li className="eodb-pd-li m-0">
                          <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo10">{t("Application")}<i className="bi bi-chevron-right" /></button>
                          <div id="demo10" className="collapse"> 
                          <ul className="eodb-li mt-4">
                              <li> <a href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/WaterSupplyNewConnection1.jsp" target="_blank"> {t("Apply for New Online Domestic Water Connection")}</a></li>
                              <li><a href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/WaterSupplySearchApplication1.jsp" target="_blank"> {t("Search Online Domestic Water Connection Application")}</a></li>
                            </ul>  
                          </div>
                        </li>
                        <li><a href="" target="_blank"> {t("Circulars")} </a></li>
                        <li><a href="" target="_blank">{t("Notification")}</a> </li>
                        <li><a href="" target="_blank"> {t("Guidelines")}</a></li>
                        <li className="eodb-pd-li m-0">
                          <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo11">{t("Application Status")}<i className="bi bi-chevron-right" /></button>
                          <div id="demo11" className="collapse"> 
                            <ul className="eodb-li mt-4">
                            <div className="card card-body">
                              <img src="assets/img/eodb-1.png" />
                            </div>  
                            </ul>  
                          </div>
                        </li>
                        <li className="eodb-pd-li m-0">
                          <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo12">{t("Approval Time Statistics")}<i className="bi bi-chevron-right" /></button>
                          <div id="demo12" className="collapse"> 
                            <ul className="eodb-li mt-4">
                              <div className="card card-body">
                                <img src="assets/img/eodb-1.png" />
                              </div>  
                            </ul>  
                          </div>
                        </li>
                        <li><a href="" target="_blank">{t("WBRTPS Act")}</a></li>
                        <li><a href="" target="_blank"> {t("Third Party Certification")}</a> </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="eodb-5"
                    role="tabpanel"
                    aria-labelledby="eodb-5a"
                  >
                    <h3 className="eodb-titl"> {t("Mutation")} </h3>
                    <div className="eodb-but1">
                      <button
                        type="button"
                        className="btn"
                        data-toggle="modal"
                        data-target="#exampleModalCenter5"
                      >
                        {t("About The Service")} <i className="bi bi-chevron-right" />
                      </button>
                      <div
                        className="modal fade"
                        id="exampleModalCenter5"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLongTitle"
                              >
                                
                                {t("About the service- Building Construction Permit")}
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="mod-hed-1">
                                <img src="assets/img/logo.png" alt="alt-image" />
                                <p>
                                  {t("The online Building Construction Permit system has the following features without the requirement of physical visit href the department")}.
                                </p>
                              </div>
                              <ul className="model-list">
                                <li> 1. {t("Submision of Application")}.</li>
                                <li> 2. {t("Payment of Application Fees")}.</li>
                                <li> 3. {t("Track Status of Application")}.</li>
                                <li>
                                  
                                  4. {t("Download the Final Signed Certificate")}.
                                </li>
                                <li> 5. {t("Third Party Verification")}. </li>
                              </ul>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                {t("Thank You")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eodb-1">
                    <li className="eodb-pd-li m-0">
                        <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo13">{t("Application")}<i className="bi bi-chevron-right" /></button>
                        <div id="demo13" className="collapse"> 
                        <ul className="eodb-li mt-4">
                        <li>
                              
                              <a
                                href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/AssesseeMutationApplication.jsp"
                                target="_blank"
                              >
                                
                                {t("NEW APPLICATION")}
                              </a>
                            </li>
                            <li>
                              
                              <a
                                href="https://www.kmcgov.in/KMCPortal/jsp/MutaStatusSearch.jsp"
                                target="_blank"
                              >
                                
                                {t("STATUS SEARCH")}
                              </a>
                            </li>
                            <li>
                              
                              <a
                                href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/MutationPrint.jsp"
                                target="_blank"
                              >
                                
                                {t("DEMAND PRINT")}
                              </a>
                            </li>
                            <li>
                              
                              <a
                                href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/MutationProcessingFeesPayment.jsp"
                                target="_blank"
                              >
                                
                                {t("E-MUTATION FEES PAYMENT")}
                              </a>
                            </li>
                            <li>
                              
                              <a
                                href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/MutationPaymentReceipt.jsp"
                                target="_blank"
                              >
                                
                                {t("RECEIPT REPRINT")}
                              </a>
                            </li>
                        </ul>  
                        </div>
                    </li>
                    </div>
                    <div className="eodb-pdf">
                      <ul className="eodb-pd-li">
                        <li><a href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssessmentTaxRates.jsp" target="_blank"> {t("Fees & Charges")}</a></li>
                        <li>
                          
                          <a
                            href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssessmentMutation.jsp"
                            target="_blank">
                            
                            {t("Procedures")}
                          </a>
                        </li>
                        <li>
                          
                          <a href="" target="_blank">
                            
                            {t("List Of Documents")}
                          </a>
                        </li>
                        <li>
                          
                          <a href="" target="_blank">
                            
                            {t("Circulars")}
                          </a>
                        </li>
                        <li>
                          
                          <a
                            href="https://www.kmcgov.in/KMCPortal/downloads/Mutation_noti.pdf"
                            target="_blank"
                          >
                            
                            {t("Notification")}
                          </a>
                        </li>
                        <li>
                          
                          <a
                            href="https://www.kmcgov.in/KMCPortal/downloads/Help_Online_Mutation.pdf"
                            target="_blank"
                          >
                            
                            {t("Guidelines")}
                          </a>
                        </li>
                        <li className="eodb-pd-li m-0">
                        <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo14">{t("Application Status")}<i className="bi bi-chevron-right" /></button>
                        <div id="demo14" className="collapse"> 
                        <ul className="eodb-li mt-4">
                            <div className="card card-body">
                              <img src="assets/img/eodb-1.png" />
                            </div>
                        </ul>  
                        </div>
                    </li>
                    <li className="eodb-pd-li m-0">
                        <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo15">{t("Approval Time Statistics")}<i className="bi bi-chevron-right" /></button>
                        <div id="demo15" className="collapse"> 
                          <div className="card card-body">
                            <img src="assets/img/eodb-1.png" />
                          </div>
                        </div> 
                    </li>
                    <li><a href="" target="_blank"> {t("WBRTPS Act")}</a></li>                  
                      </ul>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="eodb-6"
                    role="tabpanel"
                    aria-labelledby="eodb-6a"
                  >
                    <h3 className="eodb-titl">
                      
                      {t("Signage License for Advertisement")}
                    </h3>
                    <div className="eodb-but1">
                      <button
                        type="button"
                        className="btn"
                        data-toggle="modal"
                        data-target="#exampleModalCenter6"
                      >
                        {t("About The Service")} <i className="bi bi-chevron-right" />
                      </button>
                      <div
                        className="modal fade"
                        id="exampleModalCenter6"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLongTitle"
                              >
                                
                                {t("About the service- Building Construction Permit")}
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="mod-hed-1">
                                <img src="assets/img/logo.png" alt="alt-image" />
                                <p>
                                  {t("The online Building Construction Permit system has the following features without the requirement of physical visit href the department")}.
                                </p>
                              </div>
                              <ul className="model-list">
                                <li> 1. {t("Submision of Application")}.</li>
                                <li> 2. {t("Payment of Application Fees")}.</li>
                                <li> 3. {t("Track Status of Application")}.</li>
                                <li>
                                  
                                  4. {t("Download the Final Signed Certificate")}.
                                </li>
                                <li> 5. {t("Third Party Verification")}. </li>
                              </ul>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                {t("Thank You")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eodb-pdf">
                      <ul className="eodb-pd-li">
                      <li className="eodb-pd-li m-0">
                        <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo5">{t("Application")}<i className="bi bi-chevron-right" /></button>
                        <div id="demo5" className="collapse"> 
                        <ul className="eodb-li">
                            <li>
                              
                              <a
                                href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/AdvertisementOnlineApplication.jsp"
                                target="_blank"
                              >
                                
                                {t("Apply for New Online Application")}
                              </a>
                            </li>
                            <li>
                              
                              <a
                                href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/AdvertisementOnlineRenewApplication.jsp"
                                target="_blank"
                              >
                                
                                {t("Apply for Renew Application")}
                              </a>
                            </li>
                            <li>
                              
                              <a
                                href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/AdvertisementOnlineApplicationStatus.jsp"
                                target="_blank"
                              >
                                
                                {t("Search Online Application Status")}
                              </a>
                            </li>
                            <li>
                              
                              <a
                                href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/AdvertisementOnlineApplicationNOC.jsp"
                                target="_blank"
                              >
                                
                                {t("Online Application NOC")}
                              </a>
                            </li>
                          </ul>  
                        </div>
                    </li>
                        <li>
                          
                          <a
                            href="https://www.kmcgov.in/KMCPortal/jsp/AdvertisementRates.jsp"
                            target="_blank"
                          >
                            
                            {t("Fees & Charges")}
                          </a>
                        </li>
                        <li>
                          
                          <a href="" target="_blank">
                            
                            {t("Procedures")}
                          </a>
                        </li>
                        <li>
                          
                          <a
                            href="https://www.kmcgov.in/KMCPortal/downloads/ADVT_DOCUMENTS_TO_UPLOAD.pdf"
                            target="_blank"
                          >
                            
                            {t("List Of Documents")}
                          </a>
                        </li>
                        <li>
                          
                          <a href="" target="_blank">
                            
                            {t("Circulars")}
                          </a>
                        </li>
                        <li>
                          
                          <a
                            href="https://www.kmcgov.in/KMCPortal/downloads/Mutation_noti.pdf"
                            target="_blank"
                          >
                            
                            {t("Notification")}
                          </a>
                        </li>
                        <li>
                          
                          <a
                            href="https://www.kmcgov.in/KMCPortal/downloads/Help_Online_Mutation.pdf"
                            target="_blank"
                          >
                            
                            {t("Guidelines")}
                          </a>
                        </li>
                        <li className="eodb-pd-li m-0">
                            <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo16">{t("Application Status")}<i className="bi bi-chevron-right" /></button>
                            <div id="demo16" className="collapse"> 
                                <div className="card card-body">
                                  <img src="assets/img/eodb-1.png" />
                                </div>  
                            </div>
                        </li>
                        <li className="eodb-pd-li m-0">
                            <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo17">{t("Approval Time Statistics")}<i className="bi bi-chevron-right" /></button>
                            <div id="demo17" className="collapse"> 
                            <div className="card card-body">
                              <img src="assets/img/eodb-1.png" />
                            </div>
                            </div>
                        </li>
                        <li>
                          
                          <a href="" target="_blank">
                            
                            {t("WBRTPS Act")}
                          </a>
                        </li>
                        <li>
                          
                          <a href="" target="_blank">
                            
                            {t("Third Party Certification")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="eodb-7"
                    role="tabpanel"
                    aria-labelledby="eodb-7a"
                  >
                    <h3 className="eodb-titl"> {t("Movie Shooting")} </h3>
                    <div className="eodb-but1">
                      <button
                        type="button"
                        className="btn"
                        data-toggle="modal"
                        data-target="#exampleModalCenter7"
                      >
                        {t("About The Service")} <i className="bi bi-chevron-right" />
                      </button>
                      <div
                        className="modal fade"
                        id="exampleModalCenter7"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLongTitle"
                              >
                                
                                {t("About the service- Building Construction Permit")}
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="mod-hed-1">
                                <img src="assets/img/logo.png" alt="alt-image" />
                                <p>
                                  {t("The online Building Construction Permit system has the following features without the requirement of physical visit href the department")}.
                                </p>
                              </div>
                              <ul className="model-list">
                                <li> 1. {t("Submision of Application")}.</li>
                                <li> 2. {t("Payment of Application Fees")}.</li>
                                <li> 3. {t("Track Status of Application")}.</li>
                                <li>
                                  
                                  4. {t("Download the Final Signed Certificate")}.
                                </li>
                                <li> 5. {t("Third Party Verification")}. </li>
                              </ul>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                {t("Thank You")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eodb-pdf">
                      <ul className="eodb-pd-li">
                        <li className="eodb-pd-li m-0">
                            <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo18">{t("Application")}<i className="bi bi-chevron-right" /></button>
                            <div id="demo18" className="collapse"> 
                            <ul className="eodb-li mt-4">
                                <li><a href="" target="_blank"> {t("New")} </a></li>
                                <li><a href="" target="_blank"> {t("Renewal")}</a></li>
                            </ul>  
                            </div>
                        </li>
                        <li>
                          
                          <a href="" target="_blank">
                            
                            {t("Fees & Charges")}
                          </a>
                        </li>
                        <li>
                          
                          <a href="" target="_blank">
                            
                            {t("Procedures")}
                          </a>
                        </li>
                        <li>
                          
                          <a href="" target="_blank">
                            
                            {t("List Of Documents")}
                          </a>
                        </li>
                        <li>
                          
                          <a href="" target="_blank">
                            
                            {t("Circulars")}
                          </a>
                        </li>
                        <li>
                          
                          <a href="" target="_blank">
                            
                            {t("Notification")}
                          </a>
                        </li>
                        <li><a href="" target="_blank">{t("Guidelines")} </a></li>
                        <li className="eodb-pd-li m-0">
                            <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo19">{t("Application Status")}<i className="bi bi-chevron-right" /></button>
                            <div id="demo19" className="collapse"> 
                                <div className="card card-body">
                                  <img src="assets/img/eodb-1.png" />
                                </div>  
                            </div>
                        </li>
                        <li className="eodb-pd-li m-0">
                            <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo20">{t("Approval Time Statistics")}<i className="bi bi-chevron-right" /></button>
                            <div id="demo20" className="collapse"> 
                              <div className="card card-body">
                                <img src="assets/img/eodb-1.png" />
                              </div>  
                            </div>
                        </li>
                        <li><a href="" target="_blank"> {t("WBRTPS Act")}</a></li>
                        <li><a href="" target="_blank"> {t("Third Party Certification")}</a></li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="eodb-8"
                    role="tabpanel"
                    aria-labelledby="eodb-8a"
                  >
                    <h3 className="eodb-titl"> {t("Movie Shooting")} </h3>
                    <div className="eodb-but1">
                      <button
                        type="button"
                        className="btn"
                        data-toggle="modal"
                        data-target="#exampleModalCenter8"
                      >
                        {t("About The Service")} <i className="bi bi-chevron-right" />
                      </button>
                      <div
                        className="modal fade"
                        id="exampleModalCenter8"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLongTitle"
                              >
                                
                                {t("About the service- Building Construction Permit")}
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="mod-hed-1">
                                <img src="assets/img/logo.png" alt="alt-image" />
                                <p>
                                  {t("The online Building Construction Permit system has the following features without the requirement of physical visit href the department")}.
                                </p>
                              </div>
                              <ul className="model-list">
                                <li> 1. {t("Submision of Application")}.</li>
                                <li> 2. {t("Payment of Application Fees")}.</li>
                                <li> 3. {t("Track Status of Application")}.</li>
                                <li>
                                  
                                  4. {t("Download the Final Signed Certificate")}.
                                </li>
                                <li> 5. {t("Third Party Verification")}. </li>
                              </ul>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                {t("Thank You")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eodb-pdf">
                      <ul className="eodb-pd-li">
                        <li className="eodb-pd-li m-0">
                          <button type="button" className="btn pl-0" data-toggle="collapse" data-target="#demo19">{t("Application")}<i className="bi bi-chevron-right" /></button>
                          <div id="demo19" className="collapse"> 
                          <ul className="eodb-li mt-4">
                              <li><a href="" target="_blank"> {t("New")} </a></li>
                              <li><a href="" target="_blank"> {t("Renewal")}</a></li>
                          </ul>  
                          </div>
                        </li>
                        <li> <a href="" target="_blank"> {t("Fees & Charges")} </a></li>
                        <li><a href="" target="_blank"> {t("Procedures")}</a></li>
                        <li>
                          
                          <a href="" target="_blank">
                            
                            {t("List Of Documents")}
                          </a>
                        </li>
                        <li>
                          
                          <a href="" target="_blank">
                            
                            {t("Circulars")}
                          </a>
                        </li>
                        <li>
                          
                          <a href="" target="_blank">
                            
                            {t("Notification")}
                          </a>
                        </li>
                        <li>
                          
                          <a href="" target="_blank">
                            
                            {t("Guidelines")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Dashboard