import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Building_Sanction_Borough_Offices  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
           <nav className="sidebar card py-2 mb-4">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Building_Home'> {t("Building Home")}</Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Dashboard2' target="_blank"> {t("EODB Dashboard For Building Permit")} </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2022_23'> {t("Circular")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('319')}> {t("Building Demand Notice")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Floor_Area_Ratio'> {t("Floor Area Ratio")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Ground_Covergae'> {t("Ground Coverage")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Building_Sanction_Borough_Offices'> {t("Borough Offices")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('320')}> {t("Fees and Charges")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalBuildingLBSDetails.jsp' target="_blank"> {t("Find LBS")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalESESearch.jsp' target="_blank"> {t("Find ESE")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalBuildingGTDetails.jsp' target="_blank"> {t("Find GTE")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalBuildingESRDetails.jsp' target="_blank"> {t("Find ESR")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/HearingNotice.jsp' target="_blank"> {t("Check Hearing Notice")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Download_Forms'> {t("Download Forms")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/PlanProposalStatusSearch.jsp' target="_blank"> {t("Building Plan Proposal Status")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCViewBuildingDemand.jsp' target="_blank"> {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingDemandPrint.jsp' target="_blank"> {t("Print Unpaid Bill")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingPayment.jsp' target="_blank"> {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingReceiptReprint.jsp' target="_blank"> {t("Reprint e-Receipt")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('321')}> {t("Instruction Set for Online Submission of Building Plan")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/Building/gotologin.do' target="_blank"> {t("Online Submission of Building Plan")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('322')}> {t("Online CC Circular")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Important_EODB_circulars'> {t("Important EODB Circulars")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('323')}> {t("Instruction set for Online Notice of Commencement and Notice of Completion Certificate")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp' target="_blank">{t(" Online Notice of Commencement/CC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('324')}> {t("MC Circular No.44 of 2021-22 Regarding Assessment Data at the time of CC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href="assets/img/Final_Format_Flat_Assessment.xls" download="Final_Format_Flat_Assessment.xls"><i className="bi bi-download"></i> {t("Download Standard(.xls) format for MC Circular No.44 of 2021-22")} </a>                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://docs.google.com/forms/d/e/1FAIpQLSfmcCELn86TXflHLGQo4ZbhOiGhbN93TTmulOf15G7O5rVIpw/closedform' target="_blank"> {t("Architect Enlistment and Renewal")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp' target="_blank"> {t("License Renewal for Technical Personnel")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('325')}> {t("Instruction set for Online Licence Renewal For Technical Personnel")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('326')}> {t("Instruction Set for preparation of AutoCAD Drawing for Online Submission")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingPlanCaseSearch.jsp' target="_blank"> {t("Sanctioned Plan Search")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingCompletionCertificateSearch.jsp' target="_blank"> {t("Completion Certificate Search")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingUnauthorisedCaseSearch.jsp' target="_blank"> {t("Unauthorised Case Search")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Faqs'> {t("FAQs")}</Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2021_22' > {t("Office Order")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Communique'> {t("Communique")}  </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('325')}> {t("DGBuilding Circular No 9(2013-2014)")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('327')}> {t("Empanelment of Geo-Technical")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('329')}> {t("West Bengal Act XIX of 2014 & The Kolkata Municipal Corporation(Amendment)Act,2014")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('328')}> {t("Check list for Sanction of Building Plan")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('330')}> {t("Building Rules 2009 including amendment")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://nocas2.aai.aero/nocas/' target="_blank"> {t("Airport Building Height Clearance")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('331')}> {t("Applications are invited from the Civil Engineers of Empanelment of Geo-Technical Engineers")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('66')}> {t("3rd Amendment of KMC Building Rules 2009 Geo Tech and Road width")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('67')}> {t("Communique(The Amendment of Building Rules,2009 video notification No 80/MA/O/C-4/3R-7/2017)")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('68')}> {t("Distribution of guideline among Building-owners/Constituted Attorney in different wards of KMC for prevention of mosquito breeding at construction sites on an urgent basis")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('69')}> {t("Notification No 445/MA/O/C-4/3R-3/2014")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Building_notice_28_05_2019.pdf' target="_blank"> {t("Building Notice")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/LicenseNoWiseSanctionSearch.jsp' target="_blank"> {t("LBS/CA/ESE/GTE/ESR WISE SANCTION SEARCH")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Notice_LBS_Architect_03_09_2019.rar' target="_blank"> {t("Notice for LBS/Architect")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('70')}> {t("EODB Proforma For Appointment of Technical Person")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('71')}> {t("EODB Standard Reasons Of Rejection")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('72')}> {t("EODB IGR Non-insisting of KMC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('73')}> {t("EODB Workflow")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('74')}> {t("FAQ for EODB")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('75')}> {t("User Manual for EODB")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('76')}> {t("Public Notice -- Pollutions at Construction Site")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('c16f7b2c-4d2b-4b8c-96fd-0b81ba0516ef')}> {t("Circulation of Administrative order for regulating sanction of building plan/construction")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href="assets/img/Enemy_Properties.xls" download="Enemy_Properties.xls"><i className="bi bi-download"></i> {t("List of Enemy Properties")} </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Address of Borough Offices")}</h3>
              </div>
              <div className="mayor-table pump-list-tble">
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="left"
                      valign="middle"
                    >
                      <td width="10%" className="text-white tble-brder-left">
                        <div align="center">{t("Sl.No")}</div>
                      </td>
                      <td width="20%">
                        <div align="center" className="text-white">
                          {t("Borough")}
                        </div>
                      </td>
                      <td width="58%">
                        <div
                          align="center"
                          className="text-white tble-brder-right"
                        >
                          {t("Address")}
                        </div>
                      </td>
                    </tr>
                    <tr align="left" valign="middle">
                      <td width="10%">
                        <div align="center">1.</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass">I</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass">10, B.T. Road, Cossipore.</div>
                      </td>
                    </tr>
                    <tr align="left" valign="middle">
                      <td width="10%">
                        <div align="center">2.</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass">II</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass">79, Bidhan Sarani,Hatibagan</div>
                      </td>
                    </tr>
                    <tr align="left" valign="middle">
                      <td>
                        <div align="center">3.</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass"> III</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass"> 109, Narkeldanga Road, Fulbagan </div>
                      </td>
                    </tr>
                    <tr align="left" valign="middle">
                      <td>
                        <div align="center">4.</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass"> IV</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass">22, Surya Sen Street, College Sqare.</div>
                      </td>
                    </tr>
                    <tr align="left" valign="middle">
                      <td>
                        <div align="center">5.</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass"> V </div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass">22, Surya Sen Street, College Sqare.</div>
                      </td>
                    </tr>
                    <tr align="left" valign="middle">
                      <td>
                        <div align="center">6.</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass"> VI</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass">5, S.N. Banerjee Road, CMO Building</div>
                      </td>
                    </tr>
                    <tr align="left" valign="middle">
                      <td>
                        <div align="center">7.</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass"> VII</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass">5, S.N. Banerjee Road, CMO Building</div>
                      </td>
                    </tr>
                    <tr align="left" valign="middle">
                      <td>
                        <div align="center">8.</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass"> VIII</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass">
                          Crossing of Rashbehari Avenue &amp; Ekdalia near Bijan
                          Setu.
                        </div>
                      </td>
                    </tr>
                    <tr align="left" valign="middle">
                      <td>
                        <div align="center">9.</div>
                      </td>
                      <td align="center">
                        
                        <div className="fontSizeClass"> IX </div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass"> 11, Belvedere Road, Alipore.</div>
                      </td>
                    </tr>
                    <tr align="left" valign="middle">
                      <td>
                        <div align="center">10.</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass"> X</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass">C.I.T. Market Complex, Jadavpur</div>
                      </td>
                    </tr>
                    <tr align="left" valign="middle">
                      <td>
                        <div align="center">11.</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass"> XI</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass">B-XI Office at Garia.</div>
                      </td>
                    </tr>
                    <tr align="left" valign="middle">
                      <td>
                        <div align="center">12.</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass"> XII</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass">
                          Santoshpur Market Complex, Santoshpur, Jadavpur.
                        </div>
                      </td>
                    </tr>
                    <tr align="left" valign="middle">
                      <td>
                        <div align="center">13.</div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass"> XIII </div>
                      </td>
                      <td align="center">
                        
                        <div className="fontSizeClass">D.H. Road, Br. Office, Behala </div>
                      </td>
                    </tr>
                    <tr align="left" valign="middle">
                      <td>
                        <div align="center">14.</div>
                      </td>
                      <td align="center">
                        
                        <div className="fontSizeClass"> XIV </div>
                      </td>
                      <td align="center">
                        
                        <div className="fontSizeClass">D.H. Road, Br. Office, Behala </div>
                      </td>
                    </tr>
                    <tr align="left" valign="middle">
                      <td>
                        <div align="center">15.</div>
                      </td>
                      <td align="center">
                        
                        <div className="fontSizeClass"> XV </div>
                      </td>
                      <td align="center">
                        <div className="fontSizeClass"> Garden Reach Br. Office</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Building_Sanction_Borough_Offices