import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Assessment_collection_Process_Muation  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu   min-height-400">
            <nav className="sidebar card py-2 mb-4">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_Collection_Assessment_Home'>  {t("Assessment Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Assessment_collection_Process_Muation'> {t("Process of Mutation")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_Collection_Unit_Area_Assessment'> {t("Unit Area Assessment")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('289')}> {t("Tax Rates / Fees")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_download_forms'> {t("Download Forms")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_Message_Board'> {t("Message Board")}  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_Notice'> {t("Notice")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/HearingNoticeSearch.jsp'>  {t("Check Hearing Notices")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/MutationStatusSearch.jsp'> {t("Check Mutation Status")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/NocDetails.jsp'> {t("Download NOC(A.C)")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCAssessmentCheckPaymentStatus.jsp'> {t("Check Payment Status")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCAssessmentCurrentPD.jsp'> {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WaiverApplicationFP.jsp'> {t("Application for Upto 50% Intrest Waive")} </a>
                </li>
                <li className="nav-item">
                        <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Print Unpaid Bill")}<i className="bi bi-chevron-down" /></button>
                        <div id="demo" className="collapse">                        
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAsmtAllUnpaidDemandPrint.jsp">1. {t("All Bill")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/PDDemandPrint.jsp">2. {t("Current PD")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/FSDemandPrint.jsp">3. {t("F/S Bill")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDemandPrint.jsp">4. {t("Outstanding LOI")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDemandWaiverFiftyPrint.jsp">5. {t("Upto 50% Interest Waive LOI")}</a>
                        </div>
                </li>
                <li className="nav-item">
                        <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("Make Online Payment")}<i className="bi bi-chevron-down" /></button>
                        <div id="demo1" className="collapse">                        
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssesmentAllUnpaidDemand.jsp">1. {t("All Bill")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssesmentLoiWaiverFiftyPayment.jsp">2. {t("Upto 50% Interest Waive LOI")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/MiscellaneousSuspensePay.jsp">3. {t("Suspense")}</a>
                        </div>
                </li>
                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Reprint e-Receipt")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo2" className="collapse">                        
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssessmentCombinedReceiptReprint.jsp">. {t("All Bill")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/PDDuplicateReceipt.jsp">2. {t("Current PD, F/S")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDuplicateReceipt.jsp">3. {t("Outstanding LOI")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDuplicateReceipt.jsp">4. {t("Waiver 2020 Receipt")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/MiscSuspDuplicateReceipt.jsp">5. {t("Suspense Receipt")}</a>
                      </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AssesseeInformationSearch.jsp'> {t("Assessment Information Search")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AssessmentReportPrint.jsp'>{t("Assessment Register")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Apply for Mailing Address Change")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Mutation Application")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('317')}> {t("Help for Online Mutation Application")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('318')}> {t("Litigated Property")} </a>
               </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_Collection_FAQ'> {t("FAQs")} </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Assessment_Dept'> {t("Contact Details")} </Link>                </li>
              </ul>
           </nav>
          </div> 
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass">{t("Process of Mutation")} </h3>
              </div>
              <div className="about-head1">
                <div className="dig-login1 dig-login2">
                  <form>
                    <div className="log-form inner-log-form">
                      <div className="para_text">
                        <p className="fontSizeClass">
                          {t("At first, please procure the scheduled application form for Mutation called Form (A-42) [see sample]. This can be obtained from Central Record Section in CMO building. It is also available in Gariahat (Tolly Tax) office at Behala (SSU) Office. The form can also be downloaded from the KMC Website")}.
                        </p>
                        <p className="fontSizeClass">
                          {t("'No Outstanding Certificate' from Assessment Collection Department is required to be attached with prescribed mutation form (A-42). For obtaining NOC from Assessment Collection Department single dedicated computerised window has been instituted at respective Assessment Collection Units (At CMO Buildings, Tolly Tax, SSU, JU & GRU office) from which either NOC or letter of Intimation (LoI) of demand will be handed over within a specified time limit")}.
                        </p>
                        <p className="fontSizeClass">
                          {t("Do not forget to attach other papers/documents as mentioned in (A-42) form")}.
                        </p>
                        <p className="fontSizeClass">
                          {t("In case of Mutation on succession ground death certificate of the R/O and an affidavit regarding legal heirs (incase of intestate succession) or probated will (incase of testamentary succession)")}
                        </p>
                        <p className="fontSizeClass">
                          {t("In case of mutation and apportionment of tax, please submit a site-plan duly signed by an LBS and the applicant(s) showing the entire area of the premises as well the area(s) under the occupation of the individual applicant(s) along with the prescribed form of mutation (A-42)")}.
                        </p>
                        <p className="fontSizeClass">
                          {t("In case of Mutation Amalgamation or Mutation-separation please enclose detail site map duly signed by a LBS")}.
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Assessment_collection_Process_Muation