import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Car_Parking_Home  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Car_Parking_Home'> {t("Car Parking Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Car_Parking_Rate_Chart'> {t("Rate Chart")}</Link>
                </li>
                <li className="nav-item ">
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Notice")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo" className="collapse">                        
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('228')}>1. {t("Notice 1")} </a>
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('229')}>2. {t("Notice 2")} </a>  
                      </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/CheckListAuthorisedParking.jsp'> {t("List of Parking Lots")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/CarParkingFee.jsp'> {t("Check Demands Payable")} </a>
                  </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/ParkingDemandPrint.jsp'> {t("Print Unpaid Bill")} </a>
                 </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCCarParkingPayment.jsp'> {t("Make Online Payment")} </a>
                  </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/CPDuplicateReceipt.jsp'> {t("Reprint e-Receipt")} </a>
                  </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Car_Parking_FAQ'> {t("FAQs")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('230')}> {t("Taxi Parking in KMC's Free Parking Stretches (Yellow boarded area)")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('231')}> {t("Car Parking Policy")} </a>
                  </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('232')}> {t("Parking Tender Policy")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('233')}> {t("Proposed enhancement of fee car parking space in KMC area")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('234')}> {t("Phalpatty area & rates")} </a>
                  </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('235')}> {t("Corri Notice for correction of Short Car Parking Tender 2018")} </a>
                 </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('236')}> {t("Extension of payment date of demands of May-18")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('237')}> {t("Usage of Mobile apps (Bandhu)")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('238')}> {t("Proposed enhancement of night fee car parking spaces in KMC area")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('239')}> {t("List of Defaulters")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" target={'_blank'} href='https://www.kmcgov.in/KMCPortal/downloads/List_Defaulters_07_11_2023.pdf'> {t("List of Defaulters up to 31-10-2023")} </a>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Car_Parking_Dept'> {t("Contact Details")} </Link>                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
         <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass"> {t("Car Parking")} :</h3>
            </div>
            <div className="col-lg-12 about-head1">
              <div className="unit-area-assessment">
                <br />
                <p className="fontSizeClass">
                  {t("The Collector member shall have Authentic Identity Card and shall be endorsed by the Concerned Officer of K.M.C., otherwise the Identity Card shall be considered as invalid. Certified copy of the current Possession Certificate to be made available with the Collectors working at site")}.
                </p>
                <p className="fontSizeClass">
                  {t("Parking fees can be realised on the schedule rate of K.M.C. as mentioned herein and are Permissible upto the validity period of license")}.
                </p>
                <br />
                &nbsp;
                <p className="fontSizeClass">
                  {t("Anybody realising the Parking Fees beyond the validity period of license and validity period of the possession certificate will be treated as illegal and may be proceeded against the law. Realisation of parking fees without valid identity card is illegal")}.
                </p>
                <br />
                &nbsp;
                <p className="fontSizeClass">
                  {t("Parking fees can be realised from those vehicles parked within the licensed area and which have been parked leaving 50’ from Crossing junction/ intersection of the road and keeping free from obstruction of any carriage way, garage, entance to any premises. If any parking is made and parking fees are realised from those cars parked beyond the above area will be considered illegal")}.
                </p>
                <p className="fontSizeClass">
                  
                  {t("The licensee shall not collect parking fees at a rate higher or lower than that approved by the Municipal Commissioner, K.M.C")}
                </p>
                <br />
                &nbsp;
                <p className="fontSizeClass">
                  {t("In the event of a compliant made against the licensee for collection of parking fees at a higher rate or for any violation of the terms and condition, K.M.C. shall have the right to enquire into the complaint and on being satisfied about the nature of the complaints, K.M.C shall also have the right to impose fine/penalty on the licensee as may be determined from time to time")}.
                </p>
                <br />
                &nbsp;
                <p className="fontSizeClass">
                  
                  {t("Other terms and conditions as already agreed and singed to be made available with all the Collector-Member at site")}.
                </p>
                <br />
                &nbsp;
                <p className="alert-notice">
                  
                  {t("If a Collector of Parking Society demands more money then the parker should lodge a complaint before Dy. Municipal Commissioner (Parking) / Ch. Manager (Parking)")}
                </p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Car_Parking_Home