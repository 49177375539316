import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Borough_Committee  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
<img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Contact_Us_Home'> {t("Contact Us Home")} </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Important_Contacts' target="_blank"> {t("Important Contacts")} </Link>
                 </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/MMIC'> {t("MMIC")}</Link></li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Controlling_Officers'> {t("Controlling Officers")}</Link></li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Councillors'> {t("Councillors")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Borough_Committee'> {t("Borough Committee")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Borough_Committee'> {t("Borough Offices")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCWard.jsp'> {t("Know Your Ward")}</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Borough Committee & Others")} </h3>
              </div>
              <div className="health-dept-table mayor-table m-ta-2">
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"
                    >
                      <td width="7%" className="text-white tble-brder-left">
                      {t("Borough Committee")}	
                      </td>
                      <td width="33%" className="text-white">
                      {t("Ward No")}.	
                      </td>
                      <td width="12%" className="text-white tble-brder-right">
                      {t("Chairperson")}	
                      </td>
                      <td width="12%" className="text-white tble-brder-right">
                      {t("Office Address")}	
                      </td>
                      <td width="12%" className="text-white tble-brder-right">
                      {t("Office Phone")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      I
                      </td>
                      <td width="33%" align="center">
                      1,2,3,4,5,6,7,8 &9
                      </td>
                      <td width="12%" align="center">
                      {t("Sri Tarun Saha")}	
                      </td>
                      <td width="12%" align="center">
                      {t("10, B. T. Road, Kolkata -700 002")}		
                      </td>
                      <td width="12%" align="center">
                      2557-4232
	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      II	
                      </td>
                      <td width="33%" align="center">
                      10,11,12,15,16,17,18,19 & 20	
                      </td>
                      <td width="12%" align="center">
                      {t("Smt.Shukla Bhore")}	
                      </td>
                      <td width="12%" align="center">
                      {t("79, Bidhan Sarani, Kolkata - 700 006")}	
                      </td>
                      <td width="12%" align="center">
                      2555-9081
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      III	
                      </td>
                      <td width="33%" align="center">
                      13,14,29,30,31,32,33,34 & 35	
                      </td>
                      <td width="12%" align="center">
                      {t("Sri Anindya Kishore Routh")}	
                      </td>
                      <td width="12%" align="center">
                      {t("109,Narkeldanga Main Road, Kolkata - 700 011")}	
                      </td>
                      <td width="12%" align="center">
                      {t("109,Narkeldanga Main Road, Kolkata - 700 011")}	
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      IV	
                      </td>
                      <td width="33%" align="center">
                      21,22,23,24,25,26,27,28,38 & 39	
                      </td>
                      <td width="12%" align="center">
                      {t("Smt. Sadhana Bose")}	
                      </td>
                      <td width="12%" align="center">
                      {t("213/B, C. R. Avenue, Kolkata - 06")}	
                      </td>
                      <td width="12%" align="center">
                      2272-2933
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      V	
                      </td>
                      <td width="33%" align="center">
                      36,37,40,41,42,43,44,45,48,49 & 50	                      </td>
                      <td width="12%" align="center">
                      {t("Rehana Khatoon")}	                      </td>
                      <td width="12%" align="center">
                      {t("22, Surya Sen Street, Kolkata - 700 012")}	
                      </td>
                      <td width="12%" align="center">
                      2241-7288
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      VI	
                      </td>
                      <td width="33%" align="center">
                      46,47,51,52,53,54,55,60,61 & 62	                      </td>
                      <td width="12%" align="center">
                      {t("Sana Ahmed")}	
                      </td>
                      <td width="12%" align="center">
                      {t("1, Hogg. Street, Kolkata -700 087")}	
                      </td>
                      <td width="12%" align="center">
                      2252-7526
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      VII	
                      </td>
                      <td width="33%" align="center">
                      56,57,58,59,63,64,65,66 & 67	                      </td>
                      <td width="12%" align="center">
                      {t("Smt. Susmita Bhattacharya (Chatterjee)")}	
                      </td>
                      <td width="12%" align="center">
                      {t("9/1A, A. J. C. Bose Road, Kolkata - 17")}	
                      </td>
                      <td width="12%" align="center">
                      2290-6635
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      VIII		
                      </td>
                      <td width="33%" align="center">
                      68,69,70,72,83,84,85,86,87,88 & 90		                      </td>
                      <td width="12%" align="center">
                      {t("Smt. Chaitali Chattopadhyay")}		
                      </td>
                      <td width="12%" align="center">
                      {t("172/5, Rash Behari Avenue, Kolkata -700 029, (Triangular Park)")}		
                      </td>
                      <td width="12%" align="center">
                      2465-2860

                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      IX	
                      </td>
                      <td width="33%" align="center">
                      71,73,74,75,76,77,78,79,80 &82		                      </td>
                      <td width="12%" align="center">
                      {t("Smt. Debalina Biswas")}		
                      </td>
                      <td width="12%" align="center">
                      {t("11, Belvedere Road, Kolkata -700 027")}		
                      </td>
                      <td width="12%" align="center">
                      2479-1833
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      X	
                      </td>
                      <td width="33%" align="center">
                      81,89,91,92,93,94,95,96,97,98,99 & 100		                      </td>
                      <td width="12%" align="center">
                      {t("Smt. Jui Biswas")}		
                      </td>
                      <td width="12%" align="center">
                      {t("28, Prince Anwar Shah Road, Kolkata -700 033")}		
                      </td>
                      <td width="12%" align="center">
                      2422-9043

                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      XI	
                      </td>
                      <td width="33%" align="center">
                      103,104,110,111,112,113 & 114		                      </td>
                      <td width="12%" align="center">
                      {t("Sri Tarakeswar Chakraborty")}		
                      </td>
                      <td width="12%" align="center">
                      {t("Baghajatin Mkt. Complex, Unit - III, Baghajatin Stn. Road, Kolkata 86")}	
                      </td>
                      <td width="12%" align="center">
                      2425-8138

                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      XII	
                      </td>
                      <td width="33%" align="center">
                      101,102,105,106,107,108 & 109		                      </td>
                      <td width="12%" align="center">
                      {t("Sri Sushanta Kumar Ghosh")}		
                      </td>
                      <td width="12%" align="center">
                      {t("97 & 98, Garfa Main Road, Kolkata - 75")}		
                      </td>
                      <td width="12%" align="center">
                      2418-1857

                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      XIII	
                      </td>
                      <td width="33%" align="center">
                      115,116,117,118,119,120 &122		                      </td>
                      <td width="12%" align="center">
                      {t("Smt. Ratna Sur")}		
                      </td>
                      <td width="12%" align="center">
                      {t("516, Diamond Harbour Road, Kolkata -700 034")}		
                      </td>
                      <td width="12%" align="center">
                      2397-1102/1053
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      XIV	
                      </td>
                      <td width="33%" align="center">
                      121,127,128,129,130,131 &132	</td>
                      <td width="12%" align="center">
                      {t("Smt. Sanhita Das")}		
                      </td>
                      <td width="12%" align="center">
                      {t("516, Diamond Harbour Road, Kolkata -700 034")}	
                      </td>
                      <td width="12%" align="center">
                      2397-1102/1053

                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      XV	
                      </td>
                      <td width="33%" align="center">
                      133,134,135,136,137,138,139,140 & 141		                      </td>
                      <td width="12%" align="center">
                      {t("Sri Ranajit Shil")}		
                      </td>
                      <td width="12%" align="center">
                      {t("E/3, Circular Garden Reach Road, Kolkata -700 024")}		
                      </td>
                      <td width="12%" align="center">
                      2469-6049

                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td width="7%" align="center">
                      XVI	
                      </td>
                      <td width="33%" align="center">
                      123,124,125,126,142,143 &144	
                      </td>
                      <td width="12%" align="center">
                      {t("Sri Sudip Polley")}		
                      </td>
                      <td width="12%" align="center">
                      {t("Diamond Park,P.O.Joka,Kolkata - 700104")}
                      </td>
                      <td width="12%" align="center">
                      2467-0057
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>
  )
}

export default Borough_Committee