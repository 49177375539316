import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Assessment_Collection_Unit_Area_Assessment  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
    const { t, i18n, ready } = useTranslation();
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />        
      </div>
    </section>
    {/* End Slider */}
    <section className="section inner-pad1 min-height-800">
      <div className="container " >
        <div className="row">
            <div className="col-lg-3">
            <div className="site-menu   min-height-400">
            <nav className="sidebar card py-2 mb-4">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Assessment_Collection_Unit_Area_Assessment'>  {t("A Brief Summary")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" onClick={(e)=>fileDownload('292')}> {t("Mayor's Appeal")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass " onClick={(e)=>fileDownload('')}> {t("Scheme for Unit Area Assessment")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('293')}> {t("Brief Synopsis of Unit Area Assessment System")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" onClick={(e)=>fileDownload('294')}> {t("Final Notification (Block & Categorization)")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" onClick={(e)=>fileDownload('295')}> {t("Kolkata Municipal Corporation(Self Assessment System,Manner and Forms)Rules 2017")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" onClick={(e)=>fileDownload('296')}> {t("Kolkata Municipal Corporation(Covered Space)Regulations 2017")}</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('297')}>{t("Kolkata Municipal Corporation(Unit Area Assessment - Tax Capping)Regulation 2017")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'>{t("UAA Registration")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Assessee Dashboard")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UAABoroughWiseMap.jsp'>{t("Borough wise Map")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("Downloadable SAF Forms")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('298')}> {t("List of Self Assessment Return Preparer under Unit Area Assessment(UAA)")}</a>
                </li>
              </ul>
           </nav>
          </div>             
            </div>
            <div class="col-lg-9">
             <div class="container">
              <div class="row">		  
            <div class="inner-title2"><h3 className="fontSizeClass"> {t("Unit Area Assessment System: A Synopsis")}  </h3></div>		   
                <div class="col-lg-12 about-head1">		   
                    <div class="unit-area-assessment para_text">
                        <p className="fontSizeClass"><b>{t("‘Annual Rateable Value’ (ARV) system of property taxation prevalent prior to Publication of scheme for Unit Area Assessment System on 1st April, 2017")}:-:</b></p>
                        <p className="fontSizeClass">{t("The ARV system of property taxation is based on the principle of Annual Rateable Value method. The Annual Valuation (AV) of the property is determined after deducting 10% on account of maintenance and repair from the actual annual rent realized from the property or the annual reasonable rent which the property might have fetched from year to year had it been let out")}.</p>
                        <p className="fontSizeClass">{t("The tax rate, under the said system, increases proportionately with the gradual increase of individual AV of the property and is calculated using a somewhat complex formula. It varies from 11% to 40%. However for ordinary flats the rate of tax of individual flat depends on cumulative AV of all flats in the premises. There is also provision of 50% commercial surcharge if the property is being used for commercial activities")}.</p>
                        
                    </div>
                        
                </div>
              </div>
             </div>			
            </div>		  
		</div>
        <div className='container my-5' >
            <div className="row para_text" >
                <div className='col-md-6 about-head1'>
                    <p className="fontSizeClass"><b>{t("Issues with the ARV system")}:</b></p>
                    <p className="fontSizeClass">
                    ● {t("There is scope for subjectivity in ARV system which may lead to arbitrariness causing real or perceived unfairness in the eyes of the property taxpayer")}.</p>
                    <p className="fontSizeClass">● {t("It becomes difficult to establish reasonable rent for various types of properties since it is subjective in nature and often varies from person to person")}.</p>
                    <p className="fontSizeClass">● {t("As the methodology for determining Annual Valuation depends on received-rent, there is a tendency to misrepresent the actual rent")}.</p>
                    <p className="fontSizeClass">● {t("Citizens' support to the ARV system is scarce and that leads to poor compliance")}.</p>
                    <p className="fontSizeClass">● {t("To seek relief, the assessee often resorts to litigation causing wastage of time and resource")}.</p>
                    <p className="fontSizeClass">● {t("There is no scope for self assessment by the assessee")}.</p>
                </div>
                <div className='col-md-6 about-head1'>
                        <p className="fontSizeClass"><b>{t("Issues with the ARV system")}:</b></p>
                                <p className="fontSizeClass">
                                ● {t("There is scope for subjectivity in ARV system which may lead to arbitrariness causing real or perceived unfairness in the eyes of the property taxpayer")}</p>
                                <p className="fontSizeClass">● {t("It becomes difficult to establish reasonable rent for various types of properties since it is subjective in nature and often varies from person to person")}.</p>
                                <p className="fontSizeClass">● {t("As the methodology for determining Annual Valuation depends on received-rent, there is a tendency to misrepresent the actual rent")}.</p>
                                <p className="fontSizeClass">● {t("Citizens' support to the ARV system is scarce and that leads to poor compliance")}.</p>
                                <p className="fontSizeClass">● {t("To seek relief, the assessee often resorts to litigation causing wastage of time and resource")}.</p>
                                <p className="fontSizeClass">● {t("There is no scope for self assessment by the assessee")}.</p>
                </div>
            </div>
            <div className="row my-5">
                <div class="col-lg-6">		   
                    <div class="col-lg-12 about-head1 para_text">	
                    <p className="fontSizeClass">{t("The entire city is divided into 293 blocks and these blocks are classified into seven categories (A to G) on the basis of market value, infrastructure, facilities etc. Corresponding to each category, (viz. A,B,C,D,E,F,G) an Annual value / sq.ft. i.e. Base Unit Area Value (BUAV) is assigned, category 'A' having the highest BUAV while 'G' having the lowest BUAV. It ensures equity at the macro level of the properties lying within the same block or similar category block. However, in order to remove hardship, bustee / slum / thika-tenanted area are categorized as 'G', for the purpose of assessment, irrespective of their geographical location under any block or category. Similarly, all recognized RR colonies including settlements under Govt. notified EWS and BSUP Schemes have been categorized as 'E' irrespective of their geographical situation under any block or category unless these belong to a block whose category is lower than that of 'E'")}.</p>
        
                    <p className="fontSizeClass">{t("To account for the wide heterogeneity among properties within a block, Multiplicative Factors (MFs) are introduced. MFs would account for variations in terms of purpose of use, age of the property, location of the property within the block, nature of occupancy and type of structure, which are clearly notified and used to increase or decrease Base Unit Area Values of different properties as applicable or to keep it same")}.</p>
                        
                    <p className="fontSizeClass">{t("As opposed to the ARV system, a flat tax rate percentage within min-max limit of 6% to 20% is applied and notified in the UAA system. Already tax rate for developed bustee is fixed at 8%. Also, in this system, rate of tax of individual flat will not depend on cumulative AV of all flats in the premises concerned")}.</p>
                        
                    <p className="fontSizeClass">{t("To determine such divisions of blocks with different categories, concerned BUAV, MF values, Tax rate etc. a Municipal Valuation Committee was formed which was constituted by experts from different fields of the society. Similarly an Anomaly Review Committee was formed to consider citizen's feedback towards such categorization. Such categories and values will be revisited once in five year")}.</p>
                        
                    <p className="fontSizeClass">{t("Before finally fixing the block division and categories, a draft notification was published in official Gazette inviting suggestions and objections from the citizens. This facility was similarly extended to the citizens while fixing category wise Base Unit Area Value(s) of properties")}.</p>
                        
                    </div>	
                </div>
                <div class="col-lg-6">
                <div class="col-lg-12 about-head1 para_text">		   
                <p className="fontSizeClass">{t("Assessment of property tax can be done by the assessee himself as opposed to the ARV system where only assessment inspectors were responsible for assessment. All parameters for calculating property tax in UAA system have, therefore, been notified clearly for the information of the rate payers in Scheme which is available at different offices of the Assessment-Collection Department and website of Kolkata Municipal Corporation. Return in Self-Assessment form is required to be submitted within sixty days from date of publication of Scheme collecting the same from offices of Assessment-Collection Department or website and the same may be submitted offline or online")}.</p>
                        <p className="fontSizeClass">{t("It is envisioned that the UAA system would be non-discretionary, transparent, equitable, easy to administer and the citizens will thereby be empowered by providing opportunities for self assessment")}.</p>
                        <p className="fontSizeClass"><b>{t("Annual Property Tax under the UAA system is calculated on the basis of the following formula")}:</b></p>
                        <p className="fontSizeClass"><b>{t("Annual Tax = [BUAV] x [Covered Space/Land Area] x [Age MF Value] x [Structure MF Value] x [Usage MF Value] x [Location MF Value] x [Occupancy MF Value] x [Rate of Tax (including HB Tax)]")}.</b></p>
                        <p className="fontSizeClass"><b>{t("For Base Unit Area Values and MF Values please go to the section ‘Scheme’. Tax Capping is a provision of the Act and this is not a temporary measure")}.</b></p>
                        <p className="fontSizeClass"><b>{t("For further detail please go to the Section “Brief Account of the Unit Area Assessment System” and refer to relevant pages of ‘Scheme’, ‘Regulation for Covered Space’ , ‘Tax Capping’ and Rule for ‘Self-Assessment System Manner & Forms.'")}</b></p>
                </div>		
                </div>
            </div>
        </div>
	</div>
    </section>
  </>
  )
}

export default Assessment_Collection_Unit_Area_Assessment