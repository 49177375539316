import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

function Footer() {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const [language, setLanguage] = React.useState(false);
  const languageToggle = () => {
    setLanguage(!language);
  };
  const [more, setmore] = React.useState(false);
  const moreToggle = () => {
    setmore(!more);
  };
  const { t, i18n, ready } = useTranslation();
  return (
    
     <>
     <footer id="footer" className="footer">
  <div className="footer-content px-4">
    <div className="container-fluid">
      <div className="row">
        
        <div
          className="col-lg-3 col-md-6 col-sm-6 footer-links aos-init aos-animate"
          data-aos="fade-down"
          data-aos-delay={400} >
          <h4 className="fontSizeClass">{t("Useful Links")}</h4>
          <ul>
            <li>
              <i className="bi bi-chevron-right" />
              <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/DocumentDetails.jsp" target="_blank">{t("M.I.C Agenda & Resolution")}</a>
            </li>
            <li>
              <i className="bi bi-chevron-right" />
              <Link className="fontSizeClass" to='/KMC_Recruitments'>{t("Recruitment / Result/Training")}</Link>
            </li>
            <li>
              <i className="bi bi-chevron-right" />
              <a className="fontSizeClass" onClick={(e)=>fileDownload('868')}>{t("ist_of_Pay_&_Use_Toilets")}</a>
            </li>
            <li className="drop-footer" style={{display:"block"}}>
              <button type="button" className="btn btn-secondary btn-sm footer-more" data-toggle="collapse" data-target="#footer2">{t("More")}</button>
              <div id="footer2" className="collapse mt-4">
                  <li>
                    <i className="bi bi-chevron-right" />
                    <a className="fontSizeClass" href='https://www.kmcgov.inkmcportal/jsp/ComplaintSearch.jspk' target="_blank"> {t("Grievance_Redressal")} </a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />
                    <Link className="fontSizeClass" to='/Download_Form'> {t("Downloadable_Forms")}</Link>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right" />
                    <a className="fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/EncumbranceCertificate.jsp' target="_blank"> {t("Encumbrance Certificate")} </a>
                  </li>
              </div>
            </li>
            
            
          </ul>
        </div>


        <div
          className="col-lg-2 col-md-6 col-sm-6 footer-links aos-init aos-animate"
          data-aos="fade-down"
          data-aos-delay={400}
        >
          <h4 className="fontSizeClass">{t("Other Links")}s</h4>
          <ul>
            <li>
              <i className="bi bi-chevron-right" /> <Link className="fontSizeClass" to='/MC_Circular_2022_23'>{t("Circulars")}</Link>
            </li>
            <li>
              <i className="bi bi-chevron-right" /> <a className="fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/SiteMap.jsp' target="_blank">{t("Site map")}</a>
            </li>
            <li>
              <i className="bi bi-chevron-right" /> <Link className="fontSizeClass" to='/Disclaimer'>{t("Disclaimer")}</Link>
            </li>
            <li className="drop-footer" style={{display:"block"}}>
              <button type="button" className="btn btn-secondary btn-sm footer-more" data-toggle="collapse" data-target="#footer1">{t("More")}</button>
              <div id="footer1" className="collapse mt-4">
                      <li>
                        <i className="bi bi-chevron-right" />
                        <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/PortalFeedback.jsp" target="_blank"> {t("Feedback")} </a>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right" />
                        <Link className="fontSizeClass" to='/Faqs'> {t("FAQ")} </Link>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right" />
                        <Link className="fontSizeClass" to='/Contact_Us_Home'> {t("Contact Us")}  </Link>
                      </li>
                      <li>
                        <i className="bi bi-chevron-right" />
                        <Link className="fontSizeClass" to='/Dashboard'> {t("EODB Dashboard-Online")} <br /> {t("Services")}  </Link>
                      </li>
              </div>
            </li>
          </ul>
        </div>
        <div
          className="col-lg-4 col-md-6 col-sm-6 footer-links aos-init aos-animate"
          data-aos="fade-down"
          data-aos-delay={400}
        >
          <h4 className="fontSizeClass">{t("Usefull Information")}</h4>
          <p className="fontSizeClass">
            
            <i className="bi bi-map-fill" />
            <span className="fontSizeClass"> {t("5, S.N.Banerjee Road, Kolkata 700 013, India")} </span>
          </p>
          <p className="fontSizeClass">
            
            <i className="bi bi-phone-fill" />
            <span className="fontSizeClass">
              
              {t("KMC Working Days")} <br />
              {t("Mon-Fri : 10:00 AM to 6:00 PM Sat : 10:00 AM to 5:00 PM")} <br />
              {t("Ph")} : 155360 , 2226-9909 , <br />
              18003453375 {t("(Toll Free)")}
            </span>
          </p>
        </div>
        <div
          className="col-lg-3 col-md-6 col-sm-6 footer-newsletter aos-init aos-animate"
          data-aos="fade-down"
          data-aos-delay={400}
        >
          <form action="" method="post">
            <input type="email" name="email" />
            <input type="submit" defaultValue="Subscribe" />
          </form>
          <p className="fontSizeClass">
            <Link className="fontSizeClass" to=""> {t("Visitor No")} : 1000 </Link>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="footer-legal text-center">
    <div className="container d-flex flex-lg-row justify-content-center justify-content-lg-between align-items-center">
      
        <div className="align-items-center align-items-lg-start">
          <div className="credits">
            {t("Designed by")} : <Link className="fontSizeClass" to="">{t("Kolkata Municipal Corporation")}@2022</Link>
          </div>
        </div>
        {/* <div className="social-links order-first order-lg-last mb-3 mb-lg-0">
          <Link to='/portal' className="twitter fontSizeClass">
            <i className="bi bi-twitter" />
          </Link>
          <Link to='/portal' className="facebook fontSizeClass">
            <i className="bi bi-facebook" />
          </Link>
        </div> */}
     
    </div>
  </div>
</footer>

 <Link  to='/portal'  className="scroll-top d-flex align-items-center justify-content-center fontSizeClass"  >
    <i className="bi bi-arrow-up-short fontSizeClass" />
  </Link>

     </>
    
  );
}

export default Footer;
