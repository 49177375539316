import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import NavBar from "./NavBar";

const CarouselNav = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
    },
  };

  return (
    <div style={{ position: "relative" }}>
      <Carousel responsive={responsive} autoPlay={true}>
        {/* <NavBar /> */}
      </Carousel>
    </div>
  );
};

export default CarouselNav;
