import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Chief_Manager_Circular = () => {
  const fileDownload=(fileStoreId)=>
    {

      let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
        axios
          .post(url,{"fileId": fileStoreId, "userId": "392" })
          .then((res) => {
              console.log("response is --- ", res);

              if(res && res.data && res.status === 200) {
                const base64 = res.data[0] && res.data[0].base64_data;
                const pdfData = 'data:application/pdf;base64,'+base64;

                let html = '<html>' +
                    '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                    '<body>' +
                    '<iframe src="' + pdfData + '"></iframe>' +
                    '</body></html>';
                    console.log(html);
                let a = window.open();
                a.document.write(html);
            }
          })
          .catch((e) => {
              // setLoader(false);
              console.log("The error is ", e);
          })
    }
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const [DocumentService, setDocumentService] = React.useState(false);
  const [LicenseService, setLicenseService] = React.useState(false);

  const LicenseServiceToggle = () => {
    setLicenseService(!LicenseService);
  };
  const DocumentServiceToggle = () => {
    setDocumentService(!DocumentService);
    };
    const { t, i18n, ready } = useTranslation();
  return (
<>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
<img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <button type="button" class="btn nav-link pr-0" data-toggle="collapse" data-target="#demo">{t("Municipal Commissioner CIRCULAR")}<i className="bi bi-chevron-down " /></button>
                    <div id="demo" class="collapse">
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2024_25'> {t("MC's Circular")} 2024-25 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2023_24'> {t("MC's Circular")} 2023-24 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2022_23'> {t("MC's Circular")} 2022-23 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2021_22'> {t("MC's Circular")} 2021-22 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2020_21'> {t("MC's Circular")} 2020-21 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2019_20'> {t("MC's Circular")} 2019-20 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2018_19'> {t("MC's Circular")} 2018-19 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2017_18'> {t("MC's Circular")} 2017-18 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2016_17'> {t("MC's Circular")} 2016-17 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2015_16'> {t("MC's Circular")} 2015-16 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2004_05'> {t("MC's Circular")} 2004-05 </Link></li>
                    </div>
                  </li><li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Municipal_secretary_circular'>{t("MUNICIPAL SECRETARY'S CIRCULAR")}</Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/CMF_Communique_2023_24'>{t("C M F & A's Circular and Communique - 2023-24")}</Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/CMF_Communique_2022_23'>{t("C M F & A's Circular and Communique - 2022-23")}</Link>
                  </li>
                  
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass active1" to='/Chief_Manager_Circular'>{t("Chief Manager (P)'s Circular")}</Link>
                  </li>
                  <li className="nav-item-list ">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("COMPENDIUM OF IMPORTANT DEPARTMENTAL CIRCULARS / ORDERS")}<i className="bi bi-chevron-down " /></button>
                      <div id="demo" className="collapse">                        
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>1. {t("Financial Year 2010-11")} </a>
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>2. {t("Financial Year 2011-12")} </a>
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('870')}>3. {t("Financial Year 2012-13")} </a>
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('925')}>4. {t("Financial Year 2013-14")} </a> 
                      </div>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Govt_memo'>{t("Govt Memo")}</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1558')}> {t("West Bengal Right to Public Service Act,2013")} </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1559')}>{t("Guidelines of Section 199(2) of KMC Act,1980 (as amended in 2019)")}  </a>
                  </li>
                  <li className="nav-item-list ">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("COMPENDIUM OF IMPORTANT DEPARTMENTAL CIRCULARS / ORDERS")}<i className="bi bi-chevron-down " /></button>
                      <div id="demo1" className="collapse">                        
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>1. Financial Year 2010-11 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>2. Financial Year 2011-12 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('870')}>3. Financial Year 2012-13 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('925')}>4. Financial Year 2013-14 </a>   
                      </div>
                  </li>
                  
                </ul>
              </nav>
            </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Circular")} </h3>
              </div>
              <div className="health-dept-table mayor-table" style={{width:"100%"}}>
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials" >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle">
                      <td width="35%" className="text-white tble-brder-left">
                      {t("Circular No")}		
                      </td>
                      <td width="20%" className="text-white">
                      {t("Date")}		
                      </td>
                      <td width="45%" className="text-white tble-brder-right">
                      {t("Subject")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1531')}>{t("CMP's Circular No.01 / Election of 2023-2024	")}	</a>
                      </td>
                      <td align="center">{t("06-April-2023	")}
                      </td>
                      <td align="center">{t("Updation of data of KMC personnel in Election Manpower Management Systen(EMMS) portal")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1532')}>{t("CMP's Office Order No. CH.MANAGER(P)/185/RTI of 2022-2023")}	</a>
                      </td>
                      <td align="center">{t("CMP_OfficeOrder185_04_02_2023")}
                      </td>
                      <td align="center">{t("State Assistant Public Information Officer, KMC")}
                      </td>
                    </tr>                    
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1533')}>{t("CMP's Circular No.34 / R.Cell of 2022-2023")}	</a>
                      </td>
                      <td align="center">{t("16-november-2022")}		
                      </td>
                      <td align="center">{t("Chif Manger(P)'s Circular No.34 / R.Cell of 2022-2023")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1534')}>{t("CMP's Circular No.05/I of 2022-23")}</a>
                      </td>
                      <td align="center">{t("19-April-2022")}	
                      </td>
                      <td align="center">{t("Guidelines Regarding Section Of Special Leave With Full Pay To K.m.c Employees Suffering From Center, 100% Renal Failure & Aids")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1535')}>{t("CMP's Circular No.45/VIII of 2021-22")}</a>
                      </td>
                      <td align="center">{t("26-Nov-2021")}		
                      </td>
                      <td align="center">{t("REMOVAL OF ANOMALIES IN THE PAY SCAL OF OFFICERS UNDER KMC LEAGAL SERVICE")}.
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1536')}>{t("CMP's Circular No. 18/VIII of 2021-22")}</a>
                      </td>
                      <td align="center">{t("24-June-2021")}		
                      </td>
                      <td align="center">{t("Addendum to Chief Manager (P)'s Circular No.24/VIII/2020-21")}.
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1537')}>{t("CM(P)'s Circular No. 11 of 2021-22	")}	</a>
                      </td>
                      <td align="center">{t("04-Jun-2021	")}		
                      </td>
                      <td align="center">
                      {t("Re-circulation of The Calcutta Municipal Corporation Officers and Employees (Conduct) Regulations,1991")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1538')}>{t("Chief Manager(P)'s Circular No.-: 42/VIII/2019-2020")}</a>
                      </td>
                      <td align="center">{t("02-Dec-2019")}		
                      </td>
                      <td align="center">{t("Grant of Paternity-cum-Child Care Leave for 30(thirty) days to the male employees of Kolkata Municipal Corporation")}.

                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1539')}>{t("Chief Manager (P)'s Circular No. -:19 /VIII of 2019-20")}	</a>
                      </td>
                      <td align="center">{t("27-August-2019")}		
                      </td>
                      <td align="center">
                      {t("Implementation of modified Recruitment Regulations for the post of Teacher in KMC Primary Schools under Education Department, KMC as approved by Corporation, dated 21.05.2019")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1540')}>{t("Chief Manager (P)'s Circular No. -:14 /VIII of 2019-20")}	</a>
                      </td>
                      <td align="center">{t("09-August-2019")}		
                      </td>
                      <td align="center">{t("Modification of Recruitment Regulations for the post of 'Overseer (SWM)' under SWM-I Department, KMC")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1541')}> {t("Chief Manager (P)'s Circular No.62/I of 2018-19")} </a>
                      </td>
                      <td align="center">{t("12-February-2019	")}	
                      </td>
                      <td align="center">{t("Modifications in the existing procedure for Issuance of NOC to KMC employees for obtaining Passport")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1542')}>{t("Chief Manager (P)'s Circular No.48/VIII of 2018-19")}</a>
                      </td>
                      <td align="center">{t("26-November-2018")}		
                      </td>
                      <td align="center">{t("Chief Manager (P)'s Circular No.48/VIII of 2018-19")}
                      </td>
                    </tr>
                   
                    </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>
  )
}

export default Chief_Manager_Circular