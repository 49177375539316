import React from 'react'

const Wathsapp = () => {
  return (
    <>
        <section className="section inner-pad1">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="whats-app">
          <img src="assets/img/logo.png" alt="alt-image" />
          <h1> Kolkata Municipal Corporation </h1>
        </div>
        <div className="whats-app2">
          <img className="whats1" src="assets/img/whatsapp.png" alt="alt-image" />
          <img src="assets/img/kmc-whatsapp-QR-code.png" alt="alt-image" />
          <h3>            
            <span> Want to chat with </span> Kolkata Municipal Corporation ?
          </h3>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default Wathsapp