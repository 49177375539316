import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios"; 

const E_News_Letter = () => {
  const { t, i18n, ready } = useTranslation();
    const increaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
          var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
          var fontSize = parseFloat(style); 
          el[i].style.fontSize = (fontSize + 1) + 'px';
        }
      };
      const fileDownload = (fileStoreId) => {
        let url = `https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`;
        axios
          .post(url, { fileId: fileStoreId, userId: "392" })
          .then((res) => {
            console.log("response is --- ", res);

            if (res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = "data:application/pdf;base64," + base64;

              let html =
                "<html>" +
                "<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>" +
                "<body>" +
                '<iframe src="' +
                pdfData +
                '"></iframe>' +
                "</body></html>";
              console.log(html);
              let a = window.open();
              a.document.write(html);
            }
          })
          .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
          });
      };
      const decreaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
        var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
        var fontSize = parseFloat(style); 
        el[i].style.fontSize = (fontSize - 1) + 'px'; 
        }
      };
  return (
    <>
    
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
        <div className="container">        
          <div className="section-main-heading2">           
            <h4 className="fontSizeClass"> {t("E-News Letter")} </h4>
          </div>
        </div>
      </div>
    </section>
    {/* End Slider */}
   <section className="section inner-pad1">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="site-menu">
              <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/E_News_Letter'> {t("E-News Letter")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/E_Purashree'> {t("E-Purashree")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Archive'> {t("Archive")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Monsoon_Book'> {t("Monsoon Book")}</Link>
                </li>
              </ul>
              </nav>
            </div>
          </div>
          <div className="col-lg-9 ">
            <div className="dig-login1 dig-form ">
              <div className='min-height-400'>
                    <div className="inner-title2">
                        <h3 className="fontSizeClass"> {t("Publication")} </h3>
                    </div>
                    <div className="col-lg-12 about-head1">
                        <div className="inner-title3">                  
                        <h3 className="fontSizeClass"> {t("KMC E-News Letter Publications")} </h3>
                        </div>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1954")}>{t("E-News Letter")} : {t("January")} 2019</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1955")}>{t("E-News Letter")} : {t("June")} 2018</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1956")}>{t("E-News Letter")} : {t("January")} 2018</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1957")}>{t("E-News Letter")} : {t("December")} 2017</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1958")}>{t("E-News Letter")} : {t("November")} 2017</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1959")}>{t("E-News Letter")} : {t("September")} 2017</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1960")}>{t("E-News Letter")} : {t("August")} 2017</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1961")}>{t("E-News Letter")} : {t("July")} 2017</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1962")}>{t("E-News Letter")} : {t("Jun")} 2017</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1963")}>{t("E-News Letter")} : {t("May")} 2017</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1964")}>{t("E-News Letter")} : {t("April")} 2017</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1965")}>{t("E-News Letter")} : {t("March")} 2017</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1966")}>{t("E-News Letter")} : {t("February")} 2017</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1967")}>{t("E-News Letter")} : {t("January")} 2017</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1968")}>{t("E-News Letter")} : {t("December")} 2016</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1969")}>{t("E-News Letter")} : {t("November")} 2016</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1970")}>{t("E-News Letter")} : {t("October")} 2016</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1971")}>{t("E-News Letter")} : {t("September")} 2016</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1972")}>{t("E-News Letter")} : {t("August")} 2016</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1973")}>{t("E-News Letter")} : {t("July")} 2016</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1974")}>{t("E-News Letter")} : {t("March")} 2016</a></h6>
                        <h6><a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1975")}>{t("E-News Letter")} : {t("February")} 2016</a></h6>


                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default E_News_Letter