import React from 'react';
import { Link } from "react-router-dom";


const KMC_Central_records_Home = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> KMC Central Records </h4>
        </div>
      </div>
    </div>
  </section>

  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/KMC_Central_records_Home'>
                    Central Records Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/KMC_Central_records_Fees'>
                    Fees
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title">
                <h3 className="fontSizeClass"> Central Records Department : FAQs</h3>
              </div>
              <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="heading1">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                    What are the key activities of the department? 
                    </button>
                    </div>
                    <div id="collapse1" className="collapse show" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      The key activities of the Central Records Department include:
                              <ul className="kmc-function-list">
                                <li>
                                  Preserving registers and documents pertaining
                                  to birth, death, building plans, assessment,
                                  trade licenses.
                                </li>
                                <li>
                                  {" "}
                                  Issuing certified copies related to the
                                  assessment trade license on payment of
                                  prescribed fees
                                </li>
                                <li>
                                  {" "}
                                  Implementation and co-ordination of programme
                                  / schemes of GOI &amp; GoWB
                                </li>
                                <li>
                                  {" "}
                                  Selling of Tender forms, Power tender Barta,
                                  street list, etc.{" "}
                                </li>
                                <li>
                                  {" "}
                                  Beneficiary’s identification etc under BSUP of
                                  JNNURM
                                </li>
                                <li>Free distribution of different forms</li>
                                <ul></ul>
                              </ul>                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading2">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                      What forms/records does the department distribute free of cost? <i className="bi bi-chevron-down" />
                    </button>
                    </div>
                    <div id="collapse2" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      The following forms are distributed by the department free of cost:
                              <ul className="kmc-function-list">
                                <li> A 42 (Used for Assessment)</li>
                                <li> A 75-1 (Used for Assessment)</li>
                                <li>A75-2 (Used for Assessment)</li>
                                <li> Building Application</li>
                                <li> Erection of building by a t</li>
                                <li> Birth/ Form 24 H</li>
                                <li> Death/ Form 23 H</li>
                                <li> License</li>
                                <li> Amusement</li>
                                <li> Advertisement</li>
                                <li> Water Supply</li>
                                <li>
                                  {" "}
                                  Form of National family benefit scheme (NFBS)
                                </li>
                                <li>
                                  {" "}
                                  Forms of national old age pension scheme
                                  (NOAPS)
                                </li>
                              </ul>
                      </div>                      
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading3">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                      Where can I complaint if there is a delay in obtaining my records?<i className="bi bi-chevron-down" />
                    </button>
                    </div>
                    <div id="collapse3" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                              We take every endeavor to ensure that we hand out
                              record requests on time. In the unlikely case of
                              delays in receipt of records you may visit us at
                              Central Records Department, Ground Floor, 5 SN
                              Banerjee Road, Kolkata 700013, Phone (033) 2286
                              1000 Extension – 2481 (Superintendent Records) /
                              Dy. Manager (Central Records), Extension - 2901
                              <br />
                              You may speak with the Municipal secretary at
                              extension 2402.                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                      What is the methodology of application, documents to be submitted, estimated timelines with respect to the permissions required from the central records department (CRD)?<i className="bi bi-chevron-down" />
                    </button>
                    </div>
                    <div id="collapse4" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                              <table className="table table-bordered officials rm-mg">
                                <tbody>
                                  <tr bgcolor="#302ba0" tabIndex={0}>
                                    <td
                                      width="8%"
                                      rowSpan={2}
                                      style={{
                                        color: "#fff",
                                        borderLeft: "1px solid #302ba0"
                                      }}
                                      align="center"
                                      valign="middle"
                                    >
                                      Sl.No.
                                    </td>
                                    <td
                                      width={12}
                                      rowSpan={2}
                                      align="center"
                                      valign="middle"
                                      style={{ color: "#fff" }}
                                    >
                                      Permission required{" "}
                                    </td>
                                    <td
                                      width="27%"
                                      rowSpan={2}
                                      align="center"
                                      valign="middle"
                                      style={{ color: "#fff" }}
                                    >
                                      Application procedure/Pick up Completed
                                      form from?
                                    </td>
                                    <td
                                      width="15%"
                                      rowSpan={2}
                                      style={{
                                        color: "#fff",
                                        borderLeft: "1px solid #302ba0"
                                      }}
                                      align="center"
                                      valign="middle"
                                    >
                                      Documents to be submitted
                                    </td>
                                    <td
                                      width="11%"
                                      rowSpan={2}
                                      align="center"
                                      valign="middle"
                                      style={{ color: "#fff" }}
                                    >
                                      Forms to be submitted to
                                    </td>
                                    <td
                                      width="10%"
                                      rowSpan={2}
                                      align="center"
                                      valign="middle"
                                      style={{ color: "#fff" }}
                                    >
                                      Lead Time
                                    </td>
                                    <td
                                      width="17%"
                                      rowSpan={2}
                                      align="center"
                                      valign="middle"
                                      style={{ color: "#fff" }}
                                    >
                                      Cost and payment mechanism
                                    </td>
                                  </tr>
                                  <tr>
                                    {/* <td width="20%" style="padding:3">Office</td>
                              <td width="13%" style="padding:3">Resi.</td> */}
                                  </tr>
                                  <tr
                                    align="center"
                                    valign="middle"
                                    bgcolor="#fff"
                                    tabIndex={0}
                                  >
                                    <td>1.</td>
                                    <td align="left">
                                      Birth/Death Certificate
                                    </td>
                                    <td align="left">
                                      1. Obtain &amp; fill form OS 105 from the
                                      Central Records Department at the ground
                                      floor of KMC headquarters
                                      <br />
                                      2. KMC staff searches building plans
                                      register
                                      <br />
                                      3. Pay search Fee at CRD per schedule
                                      <br />
                                      4. Once details are located, fill form 23H
                                      <br />
                                      5. Contact certificate section of health
                                      department with cash receipt &amp; form
                                      23H
                                      <br />
                                      6. Pay prescribed Fee at health department
                                      <br />
                                    </td>
                                    <td>
                                      Copy of birth/death certificate if
                                      available{" "}
                                    </td>
                                    <td>OS 105, 23H </td>
                                    <td>20 – 30 minutes </td>
                                    <td>
                                      Free of cost <br /> Health Department Fee:
                                      100 – Death, 100 - Birth{" "}
                                    </td>
                                  </tr>
                                  <tr
                                    align="center"
                                    valign="middle"
                                    bgcolor="#fff"
                                    tabIndex={0}
                                  >
                                    <td>2.</td>
                                    <td align="left">Building Plans </td>
                                    <td align="left">
                                      1. Obtain &amp; fill form OS 105 from the
                                      Central Records Department at the ground
                                      floor of KMC headquarters
                                      <br />
                                      2. KMC staff searches building plans
                                      register
                                      <br />
                                      3. Pay search Fee &amp; collect receipt
                                      <br />
                                      4. Contact Building department with
                                      receipt (must mention building sanction
                                      number)Apply at building department for
                                      building plan
                                    </td>
                                    <td>Tax Bill, Copy of Deed of ownership</td>
                                    <td>OS 105</td>
                                    <td>
                                      1 day (does not include time taken by
                                      building department)
                                    </td>
                                    <td>
                                      Search Fee Rs 400 for property owner &amp;
                                      Rs 600 for non-owners. Rs.60 to be paid
                                      extra as searching fee. Application Fee
                                      (Bldg Dept) Rs 60
                                    </td>
                                  </tr>
                                  <tr
                                    align="center"
                                    valign="middle"
                                    bgcolor="#fff"
                                  >
                                    <td>3.</td>
                                    <td align="left">
                                      Assessment Documents (For Years Prior to
                                      1950only) Note: For years after 1950
                                      contact assessment department
                                    </td>
                                    <td align="left">
                                      1. Obtain &amp; fill form OS 105 from the
                                      Central Records Department at the ground
                                      floor of KMC headquarters
                                      <br />
                                      2. KMC staff searches assessment register
                                      <br />
                                      3. Pay search Fee &amp; collect receipt
                                      <br />
                                      4. Department issues certified copy of
                                      assessment register
                                    </td>
                                    <td>
                                      Record of ownership such as Tax Receipt,
                                      Sale Deed
                                    </td>
                                    <td>OS 105</td>
                                    <td>7 – 10 days</td>
                                    <td>
                                      Search For Pukka House – Rs150/500/1000
                                      depending on annual valuation Bustee – Rs
                                      50 to Rs 100
                                      <br />
                                      Certified copy for Pukka House
                                      100/150/200/1000
                                      <br />
                                      Certified copy for bustee - Rs 50 to Rs
                                      100
                                      <br />
                                    </td>
                                  </tr>
                                  <tr
                                    align="center"
                                    valign="middle"
                                    bgcolor="#fff"
                                  >
                                    <td>4.</td>
                                    <td align="left">License Documents</td>
                                    <td align="left">
                                      1. Obtain &amp; fill form OS 105 from the
                                      Central Records Department at the ground
                                      floor of KMC headquarters
                                      <br />
                                      2. KMC staff searches license register
                                      <br />
                                      3. Pay search Fee &amp; collect receipt
                                      <br />
                                      4. Department issues certified copy of
                                      assessment register
                                    </td>
                                    <td>Copy of old license</td>
                                    <td>OS 105</td>
                                    <td>7 – 10 days</td>
                                    <td>
                                      Only Search – Rs 75, Search + Certificate
                                      – 150
                                    </td>
                                  </tr>
                                  <tr
                                    align="center"
                                    valign="middle"
                                    bgcolor="#fff"
                                  >
                                    <td>5.</td>
                                    <td align="left">Electoral rolled. </td>
                                    <td align="left">
                                      1. Obtain &amp; fill form OS 105 from the
                                      Central Records Department at the ground
                                      floor of KMC headquarters
                                      <br />
                                      2. KMC staff searches license register
                                      <br />
                                      3. Pay search Fee &amp; collect receipt
                                      <br />
                                      4. Department issues certified copy of
                                      assessment register
                                    </td>
                                    <td>Copy of old license</td>
                                    <td>OS 105</td>
                                    <td>7 – 10 days</td>
                                    <td>
                                      i) Searching fees for one entry – Rs.50
                                      <br />
                                      ii) Certified extract with searching fee
                                      for each entry (ordinary) – Rs.90
                                      <br />
                                      iii) Certified extract with searching fee
                                      for each entry for urgent delivery –
                                      Rs.100
                                      <br />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <br />
                              <table className="table table-bordered officials rm-mg">
                                <tbody>
                                  <tr bgcolor="#302ba0" tabIndex={0}>
                                    <td
                                      width="10%"
                                      rowSpan={2}
                                      style={{
                                        color: "#fff",
                                        borderLeft: "1px solid #302ba0"
                                      }}
                                      align="center"
                                      valign="middle"
                                    >
                                      Sl.No.
                                    </td>
                                    <td
                                      width="17%"
                                      rowSpan={2}
                                      align="center"
                                      valign="middle"
                                      style={{ color: "#fff" }}
                                    >
                                      Department{" "}
                                    </td>
                                    <td
                                      width="28%"
                                      rowSpan={2}
                                      align="center"
                                      valign="middle"
                                      style={{ color: "#fff" }}
                                    >
                                      Address
                                    </td>
                                    <td
                                      width="25%"
                                      rowSpan={2}
                                      style={{
                                        color: "#fff",
                                        borderLeft: "1px solid #302ba0"
                                      }}
                                      align="center"
                                      valign="middle"
                                    >
                                      Contact person
                                    </td>
                                    <td
                                      width="20%"
                                      rowSpan={2}
                                      align="center"
                                      valign="middle"
                                      style={{ color: "#fff" }}
                                    >
                                      Tel, Mobile No.{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    {/* <td width="20%" style="padding:3">Office</td>
                              <td width="13%" style="padding:3">Resi.</td> */}
                                  </tr>
                                  <tr
                                    align="center"
                                    valign="middle"
                                    bgcolor="#fff"
                                    tabIndex={0}
                                  >
                                    <td>1.</td>
                                    <td align="left">
                                      Central Records Department
                                    </td>
                                    <td align="left">
                                      Ground Floor, 5 SN Banerjee Road, Kolkata
                                      700013
                                    </td>
                                    <td>
                                      {" "}
                                      Superintendent of Records/ Dy.Manager of
                                      Record Department
                                    </td>
                                    <td>
                                      (033) 2286 1000 Extension – 2481, 2901
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                           </div>
                          </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading5">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                      If I need more information whom should I meet?<i className="bi bi-chevron-down" />
                    </button>
                    </div>
                    <div id="collapse5" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                          Kindly meet D.G Bustee, located at the Head Office
                          of KMC, Hogg Buildings (3rd Floor), 1, Hogg
                          Street, Kolkata -87.
                          <br />
                          Phone No. : 22861280 (Extn: 2598).  
                      </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default KMC_Central_records_Home