import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Birth_Ragistration_center = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
        {/* Slider */}
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">           
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Birth_Death_Ragistration'> {t("Birth/Death Home")} </Link> </li>
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Birth_Ragistration_center'> {t("Birth Registration Centres")} </Link> </li>  
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Death_Ragistration_center'> {t("Death Registration Centres")} </Link> </li>  
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBirthRecordSearch.jsp'> {t("Find Birth Records")} </a> </li>
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCDeathRecordSearch.jsp'> {t("Find Death Records")}</a> </li>  					
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title"><h3 className="fontSizeClass"> {t("Birth Registration Centres(Borough Head Office only)")} </h3>
              </div>
              <div className="mayor-table">
                <table className="table table-bordered officials rm-mg">
                  <tbody><tr bgcolor="#302ba0" tabIndex={0}>
                      <td width="10%" rowSpan={2} style={{"color":"#fff","border-left":"1px solid #302ba0"}} align="center" valign="middle">{t("Sl. No")}.</td>
                      <td width="20%" rowSpan={2} align="center" valign="middle" style={{"color":"#fff"}}>{t("Borough")}	</td>
                      <td width="80%" rowSpan={2} align="center" valign="middle" style={{"color":"#fff"}}>{t("Address")}</td>
                    </tr>
                    <tr>
                      {/* <td width="20%" style="padding:3">Office</td>
					<td width="13%" style="padding:3">Resi.</td> */}
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff" tabIndex={0}>
                      <td>1.</td>
                      <td>I</td>
                      <td align="left">{t("10, B.T. Road, Cossipore, Kolkata - 700 002")}</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff" tabIndex={0}> 
                      <td>2.</td>
                      <td>II	</td>
                      <td align="left">{t("79, Bidhan Sarani, Kolkata - 700 006")}</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>3.</td>
                      <td>III</td>
                      <td align="left">{t("109, Narkeldanga main Road, Kolkata - 700 014")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>4.</td>
                      <td>IV	</td>
                      <td align="left">{t("213/B, Chittaranjan Avenue, Kolkata - 700 006")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>5.</td>
                      <td>V		</td>
                      <td align="left">{t("22, Surya Sen Street, Kolkata - 700 012")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>6.</td>
                      <td>VI		</td>
                      <td align="left">{t("1 HOGG STREET KOL - 87")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>7.</td>
                      <td>VII		</td>
                      <td align="left">{t("149, AJC Bose Rd. Kol - 14")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>8.</td>
                      <td>VIII		</td>
                      <td align="left">{t("172/5 Rash Behari Avenue kolkata - 29 (Tri -ANGULAR PARK)")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>9.</td>
                      <td>IX		</td>
                      <td align="left">{t("1, Belvedere Road, Kolkata - 700 027")}	</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>`10.</td>
                      <td>X		</td>
                      <td align="left">{t("217, Prince Anwar Shah Road, Kolkata - 700 033")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>11.</td>
                      <td>XI	</td>
                      <td align="left">{t("Atabagan Health Centre, Garia, Kolkata - 700 084")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>12.</td>
                      <td>XII	</td>
                      <td align="left">{t("97, Garia Main Road, Kolkata - 700 075")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>13.</td>
                      <td>XIII		</td>
                      <td align="left">{t("2, Raja Rammohan Roy Road, Kolkata -700 008")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>14.</td>
                      <td>XIV		</td>
                      <td align="left">	{t("516, Diamond Harbour Road, Kolkata - 700 024")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>15.</td>
                      <td>XV		</td>
                      <td align="left">{t("E 3, Circular Garden Reach, Kolkata - 700 024")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td />
                      <td>{t("Chief Mpl. Health Office's office")}		</td>
                      <td align="left">	{t("The Kolkata Municipal Corporation")},<br />
                        {t("5, S.N. Bannerjee Road, Kolkata - 700 013.(1st floor)")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>16.</td>
                      <td>XVI	</td>
                      <td align="left">{t("Diamond Park P.O. - JOKA Kol - 104")}</td>
                    </tr> 
                  </tbody></table>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
    {/*   */}
  </section>
    </>
  )
}

export default Birth_Ragistration_center