import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Drainage_Home  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"></h4>
        </div>
      </div>
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Drainage_Home'>{t("Drainage Home")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageNetworkMap.jsp' target="_blank">
                    {t("Ward wise Drainage Network Map")}   </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Fees_Charges'>
                    {t("Fees & Charges")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Drainage_Pumping_station'>
                    {t("Drainage Pumping Station")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/List_of_Sewage_Treatment_Plants'>
                    {t("Sewage Treatment Plants")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageFee.jsp' target="_blank">
                    {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageDemandPrint.jsp' target="_blank">
                    {t("Print Unpaid Bill")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalDrainagePayment.jsp' target="_blank">
                    {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DCDuplicateReceipt.jsp' target="_blank">
                    {t("Reprint e-Receipt")}  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DCDuplicateReceipt.jsp' target="_blank">
                    {t("List of KMC Drainage Activity")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Faqs'> {t("FAQs")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Sewerage_Drainage_Dept'>{t("Contact Details")}</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass"> {t("Dept. of Sewerage & Drainage")} </h3>
              </div>
              <div className="col-lg-12 about-head1">
                    <div id="accordion">
                        <div className="card">
                        <div className="card-header" id="heading1">
                        <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                        (1){t("Under mentioned procedures are to be followed for issuance of Occupancy Certificate and thereafter effecting house drainage connection of new buildings to KMC mains following usual norms and practice in vogue")}. 
                        </button>
                        </div>
                        <div id="collapse1" className="collapse show" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                                            
                                            1. {t("After obtaining sanction to the building plan the owner will apply to Engineering Department through plumber with a copy of the sanctioned plan for sanctioning home drainage plan")}.
                                            </p>
                                            <p className="fontSizeClass">
                                            
                                            2. {t("The Engineering Department will sanction the house drainage plan on the basis of sanctioned plan and allow them to proceed with the internal works according to the progress of the building works")}.
                                            </p>
                                            <p className="fontSizeClass">
                                            3. {t("Engineering Department after completion of the internal drainage works will certify in respect of completion of the said works after receiving the fee for connection to the corporation main")}.
                                            </p>
                                            <p className="fontSizeClass">
                                            4. {t("With the above house drainage completion certificate the owner will apply to the Bldg Department for occupancy certificate as per Building Rule 2a")}.
                                            </p>
                                            <p className="fontSizeClass">
                                            5.{t("The Bldg. Department after receiving the said certificate from the Engineering Dept. will Issue Occupancy Certificate in favour of the owner on compliance of departmental requisitions and on receipt of payment of the charges against issuance of Occupancy Certificate as per Building Rule 27")}.
                                            </p>
                                            <p className="fontSizeClass">
                                            6. {t("Engineering Department on receipt of Occupancy Certificate from the Bldg. Dept. will effect the drainage connection to the Corporation main")}.
                                            </p>        </div>
                        </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading2">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                            (2) {t("Procedure for connection with Municipal drains vide office order no. 2 dated 30/04/03 DG Bldg")}
                        </button>
                        </div>
                        <div id="collapse2" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                                            i){t("After obtaining sanction of the Building plan the owner will apply to the Engineering Dept. through KMC Licensed Plumber with copy of sanction Bldg. Plan for sanctioning House Drainage Plan")}.
                                            </p>
                                            <p className="fontSizeClass">
                                            ii) {t("The Engg. Deptt. will sanction the H.D. Plan on the basis of sanctioned plan and allow them to produce with the internal works according to the progress of the Building works")}.
                                            </p>
                                            <p className="fontSizeClass">
                                            iii) {t("Engg. Deptt. after completion of the internal drainage works will certify in respect of completion of said works after receiving the fees for connection the Corporation main")}.
                                            </p>
                                            <p className="fontSizeClass">
                                            iv) {t("With the above house drainage completion certificate, the owner will apply to the Bldg. Deptt. for occupancy certificate as per Building Rule 26")}.
                                            </p>
                                            <p className="fontSizeClass">
                                            v) {t("The Bldg. Deptt. after receiving the said certificate from the Engineering Deptt. will issue occupancy certficate in favour of the owner on compliance of the departmental requisition and on receipt of payment of the charges against issuance of occupancy certificate")}.
                                            </p>
                                            <p className="fontSizeClass">
                                            vi) {t("Engg. Deptt. on receipt of the occupancy certificate from the Building Deptt. will effect the drainage connection to the Corporation main")}.
                                            </p>
                            </div>                      
                        </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading3">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                            (3) {t("Construction House Drain")} :
                        </button>
                        </div>
                        <div id="collapse3" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                                <p className="fontSizeClass">
                                    <strong>
                                    {t("sanction of the House Drainage Plan, the plumber will complete the internal House Drainage works as per sanction plan")}.
                                    </strong>
                                </p>
                            </div>
                        </div>
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading4">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                            (4) {t("Provisions for drainage of Trade effluents")} :  
                        </button>
                        </div>
                        <div id="collapse4" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                                            <strong> {t("C.M. Act 80")} </strong> <br />
                                            <strong>U/s 301 : </strong> {t("Subject to the provisions of this Act and the regulations made there under and of any other law for the time being in force, the occupier of any trade premises may, with the consent of the Municipal Commissioner or, so far as may be permitted by this Act of the regulations made there under or any other law for the time being in force, without such consent, discharge into the municipal drains any trade effluent proceeding from those premises")}.
                                            </p>
                                            <p className="fontSizeClass">[ {t("Special provisions relating to trade effluent")} ]</p>
                                            <p className="fontSizeClass">
                                            {t("U/s 302 : Notwithstanding anything contained in this Act or the regulations made there under or any usage, custom or agreement, where in the opinion of the Municipal Commissioner any trade premises are without sufficient means of effectual drainage of trade effluent or the drains thereof, though otherwise unobjectionable, are not adopted to the general drainage system of Calcutta, the Municipal Commissioner may by written notice require the owner or occupier of such premises")} - <br />
                                            {t("[Special provisions regarding drainage of trade effluents]")}
                                            </p>
                                            <p className="fontSizeClass">
                                            (a) {t("to discharge the trade effluent in such manner, at such times, through such drains and to such conditions as may be specified in the notice and to cease to discharge the trade effluents otherwise than in accordance with the notice")};
                                            </p>
                                            <p className="fontSizeClass">
                                            (b) {t("to purify the trade effluent before its discharge into a municipal drain and to set up for purifying the trade effluents such appliances, apparatus, fittings and plant as may be specified in the notice")};
                                            </p>
                                            <p className="fontSizeClass">
                                            (c) {t("to construct a drain of such material, size and description and laid at such level and according to such alignment and with such fall and outlet as may be specified in the notice")};
                                            </p>
                                            <p className="fontSizeClass">
                                            (d) {t("to alter, amend, repair or renovate any purification plant, existing drains, apparatus, plat-fitting or article used in connection with any municipal or house-drain")}.
                                            </p>      </div>
                        </div>
                        
                        </div>
                        <div className="card">
                        <div className="card-header" id="heading5">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                            (5) {t("Levy of fees for drainage & sewerage service")} :
                        </button>
                        </div>
                        <div id="collapse5" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                                <p className="fontSizeClass">
                                    {t("The Kolkata Municipal Corporation have introduced the Levy of Annual Charges for drainage & Sewerage Service on and from 1st November, 1998 as provided under section 602 read with Section 307 of KMC Act 1980")}.
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Drainage_Home