import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Death_Ragistration_center = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
        <>
            {/* Slider */}
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">           
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Birth_Death_Ragistration'> {t("Birth/Death Home")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Birth_Ragistration_center'> {t("Birth Registration Centres")} </Link> </li>  
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Death_Ragistration_center'> {t("Death Registration Centres")} </Link> </li>  
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBirthRecordSearch.jsp'> {t("Find Birth Records")} </a> </li>
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCDeathRecordSearch.jsp'> {t("Find Death Records")}</a> </li>  					
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title"><h3 className="fontSizeClass"> {t("Death Registration Centres")} </h3>
              </div>
              <div className="mayor-table">
                <table className="table table-bordered officials rm-mg">
                  <tbody><tr bgcolor="#302ba0" tabIndex={0}>
                      <td width="10%" rowSpan={2} style={{"color":"#fff","border-left":"1px solid #302ba0"}} align="center" valign="middle">{t("Sl. No")}.</td>
                      <td width="80%" rowSpan={2} align="center" valign="middle" style={{"color":"#fff"}}>{t("Address")}</td>
                    </tr>
                    <tr>
                      {/* <td width="20%" style="padding:3">Office</td>
					<td width="13%" style="padding:3">Resi.</td> */}
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff" tabIndex={0}>
                      <td>1.</td>
                      <td align="left">{t("Cossipore Ramkrishna Burning Ghat, Cossipore")}</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff" tabIndex={0}> 
                      <td>2.</td>
                      <td align="left">{t("Nimtola Burning Ghat, Strand Bank Road, Nimtola")}</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>3.</td>
                      <td align="left">{t("Kashimitra Burning Ghat, Bagbazar")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>4.</td>
                      <td align="left">{t("Shanagar Buring Ghat, Tollygunge Road")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>5.</td>
                      <td align="left">{t("Sirity Burning Ghat, B.L.Saha Road, Tollygunge")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>6.</td>
                      <td align="left">{t("Garia Adi Mahasansan, Boral Road, Garia")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>7.</td>
                      <td align="left">{t("Birjunala Burning Ghat, Garden Reach")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>8.</td>
                      <td align="left">{t("Bagmari Muslim Burial Ground, CIT Road, Manicktala")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>9.</td>
                      <td align="left">{t("Garia Muslim Burial Ground, Park Circus")}	</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>`10.</td>
                      <td align="left">	{t("Soloana Muslim Burial Ground, Khidderpore")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>11.</td>
                      <td align="left">{t("Garden Reach Muslim Burial Ground, Garden Reach")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>12.</td>
                      <td align="left">	{t("Muraripukur Hindu Burial Ground, Ultadanga")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>13.</td>
                      <td align="left">	{t("Topsia Hindu Burial Ground, Topsia")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>14.</td>
                      <td align="left">	{t("Bhatchala Hindu Burial Ground, Budge Budge Road")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>15.</td>
                      <td align="left">{t("Christian Burial Ground, Mullick Bazar, A.J.C Bose Road")}</td>
                    </tr> 
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>16.</td>
                      <td align="left">	{t("Office of the Chief Mpl. Health Officer")},<br />
                        {t("The Kolkata Municipal Corporation")}<br />
                        {t("Health Department, 1st. Floor")}<br />
                        {t("Mail Office")},<br />
                        {t("5, S.N. Banerjee Road")}<br />
                        {t("Kolkata - 700 013")}
                      </td>
                    </tr> 
                  </tbody></table>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  </section>
        </>
  )
}

export default Death_Ragistration_center