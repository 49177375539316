import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Citizen_services  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();

  return (
    <>
      <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
        <div className="carousel-item active">
          <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
          <div className="container">
            
            <div className="section-main-heading2">
              
              <h4 className="fontSizeClass">{t("Menu_item3")}</h4>
            </div>
          </div>
        </div>
      </section>
      
      <section className="section inner-pad1">
        <div className="container " >
          <div className="row">
            <div className="col-lg-3">
              <div className="site-menu  min-height-400">
                <nav
                  className="sidebar card py-2" >
                  <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/KMC_Advertisement"> Advertisement </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link act" to="/Amusement"> Amusement</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Assessment_Collection_Assessment_Home"> Assessment & Collection </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Birth_Death_Ragistration"> Birth/Death Registration </Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Building_Sanction_Building_Home'> Building Home</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/KMC_Bustee_services"> Bustee Services </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Car_Parking_Home"> Car Parking </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Central_records_home"> Central Records </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/CH_VS_Dept_Download_Forms" > Ch. VS Department</Link>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCPortalDrainagePayment.jsp" > Drainage </a>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Kmc_school_home"> Education </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Engineering_Home"> Engineering (Civil) </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Health_services_Health_Home"> Health Sevices </Link>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/HeritageBuildingHome.jsp"> Environment & Heritage </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link act" href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept_EWaste.jsp"> Information Technology </a>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link act" to="/Kmc_school_home"> KMC School </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Certificate_of_Enlistment"> License </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Lighting_Home"> Lighting</Link>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCMarketHome.jsp' target="_blank"> Markets </a>                    </li>
                    <li className="nav-item">
                    <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCParksGardens1.jsp' target="_blank"> Parks & Squares </a>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Drainage_Home"> Sewerage & Drainage </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Social_welfare_Download_Forms"> Social Welfare & Urban Poverty Alleviation Department  </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link act" to="/Solid_wastes_home"> Solid Waste Management </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Water_Supply"> Water Supply </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="container">
                <div className="row">
                  <div className="inner-title2">
                    <h3 className="fontSizeClass"> {t("Citizen_services_heading")}</h3>
                  </div>
                  <div className="col-lg-12 about-head1">
                    <p className="fontSizeClass">
                     {t("Citizen_services_text")}
                    </p>
                    <p className="fontSizeClass">
                    {t("Citizen_services_text1")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Citizen_services;
