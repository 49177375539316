import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Parks_and_gardens  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
    const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">

      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                <Link className="nav-link active1" to='/Parks_and_gardens' target="_blank"> {t("Parks & Squares Home")} </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Office_Order'>{t("Office Order")}</Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link  fontSizeClass" to='/Parks_Square_Dept'> {t("Contact Details")} </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
         <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass"> {t("Parks and Gardens")} </h3>
            </div>
            <div className="col-lg-12 about-head1">
              <h4 className="fontSizeClass">
                {t("List of some important Parks maintained by the Kolkata Municipal Corporation")}
              </h4>
              <div id="accordion">

                
                    <div className="card">
                    <div className="card-header" id="heading1">
                    <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                    I </button>
                    </div>
                    <div id="collapse1" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3">
                        <div className="mayor-table2">
                                        <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("C.I.T. Park at Rani Harashamukhi Rd.")} </td>
                                            <td> </td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 4 </td>
                                            <td> {t("Area :")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park &amp; Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <br />
                                        <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Bengal Gymkhana Ground")} </td>
                                            <td> </td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 5 </td>
                                            <td> {t("Area : NA")} </td>
                                            <td> {t("No. of Mali : ")}</td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Nursery &amp; Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <br />
                                        <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>T{("Name :")}</strong>
                                            </td>
                                            <td>
                                                
                                                {t("Children &amp; Ladies Park at Tarasankar Sarani")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 5 </td>
                                            <td> {t("Area : 27,758.52")} </td>
                                            <td> {t("No. of Mali :")} </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Nursery &amp; Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <br />
                                        <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Jatindra Mohan Park (Tala Jheel Park)")} </td>
                                            <td> </td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 5 </td>
                                            <td> {t("Area : 27,758.52 ")}</td>
                                            <td> {t("No. of Mali :")} </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Nursery")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <br />
                                        <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Sister Nivedita Uddyan")} </td>
                                            <td> </td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 7 </td>
                                            <td> {t("Area : NA")} </td>
                                            <td> {t("No. of Mali :")} </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Playground &amp; Children's Park")}</td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <br />
                                        <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Kumartully Park at 18 Abhoy Mitra St.")} </td>
                                            <td> </td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 9 </td>
                                            <td> {t("Area : NA")} </td>
                                            <td> {t("No. of Mali :")} </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Playground &amp; Children's Park")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <br />
                                        <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                                
                                                {t("Children's Park at Biswanath Colony South Sinthi Road")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Ward No : ")}</strong>
                                            </td>
                                            <td> 2 </td>
                                            <td> {t("Area : 1226.56")} </td>
                                            <td> {t("No. of Mali :")} </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>NA </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <br />
                                        <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Children's Park at Panchanantala ")}</td>
                                            <td> </td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Ward No : ")}</strong>
                                            </td>
                                            <td> 2 </td>
                                            <td> {t("Area : NA")} </td>
                                            <td> {t("No. of Mali :")} </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> Children's Park </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>NA </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <br />
                                        <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Park at Sabji Bangan")} </td>
                                            <td> </td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 2 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")} </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <br />
                                        <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Children's Park at Northern Avenue")} </td>
                                            <td> </td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 4 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")} </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {("Children's park")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <br />
                                        <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                                
                                                {t("Biplobi Ganesh Ghosh Uddyan Northern Avenue")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 4 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")} </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park &amp; Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <br />
                                        <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                                
                                                {t("Talapark Circus Maidan, Tara Sankar Sarani")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 5 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")} </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Play Ground")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <br />
                                        <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                                
                                                {t("Maharaja Narendra K. Deb. Jatindra Mohan Avenue Park")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 9 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")} </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                            <tr>
                                            <td scope="row">
                                                
                                                <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>        </div>
                    </div>
                    </div>
                    <div className="card">
                    <div className="card-header" id="heading2">
                        <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                        II </button>
                    </div>
                    <div id="collapse2" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3">
                        <div className="mayor-table2">
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Gouribari C.I.T. Park")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 12 </td>
                                            <td> {t("Area :")} 1542.60 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Deshbandhu Park at Raja Dinendra St. ")}</td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 12 </td>
                                            <td> {t("Area :")} 72310.94 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Nursery, Ladies corner palyground")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Goabagan C.I.T. Park")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 16 </td>
                                            <td> {t("Area :")} 3110.29</td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("B.K.Pal Park")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 19 </td>
                                            <td> {t("Area :")} 4433.19 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                        </div>                      
                    </div>
                    </div>
                    <div className="card">
                    <div className="card-header" id="heading3">
                        <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                        III</button>
                    </div>
                    <div id="collapse3" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3">
                        <div className="mayor-table2">
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Bagmari Park (At Maniktola Main Rd.), beside housing estate")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 29 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("New Park at 106/C, Narkeldanga Rd. (Vivek Uddyan)")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 30 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("A.P.C. Park at Ramkrishna Samadhi Rd.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 31 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td>
                                            
                                            {t("Children's park, Nursery, Green house, Playground")}
                                            </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Prafulla Khudiram Uddyan at CIT Sect. VIII M")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 32 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td>
                                            
                                            {t("Children's corner, Landscape, Garden fountain, Lighting.")}
                                            </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Ramkrishna Mahila Sishu Uddyan")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 31 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    </div> 
                            </div>
                    </div>
                    </div>
                    <div className="card">
                    <div className="card-header" id="heading4">
                        <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                        IV </button>
                    </div>
                    <div id="collapse4" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3">
                        <div className="mayor-table2">
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Jorabagan Park, 2, Baisnab Sett St.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 21 </td>
                                            <td> {t("Area :")} 7549.98 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td>
                                            
                                            {t("Playground, Children's Park, Religious Function Club")}
                                            </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("Water Reservoir")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Satyanarayan Park at the Junction of Kalakar St. &amp; Cotton St.")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 23 </td>
                                            <td> {t("Area :")} 2846.45 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("Under Ground Market")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Girish Park at the Junction of C.R.Avenue &amp; Vivekanda Rd.")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 25 </td>
                                            <td> {t("Area :")} 3649.58 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td>
                                            
                                            {t("Children's Park, Landscape, Garden Fountain, Lilypool with over bridge.")}
                                            </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Kalisinghi Park")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 25 </td>
                                            <td> {t("Area :")} 2782.36 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Rabindra Kanan at 9, Beadon St.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 26 </td>
                                            <td> {t("Area :")} 14,084.10 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td>
                                            
                                            {t("Playground, Children's Park, Religious Centre, CMC building, Club Temple")}
                                            </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Azad Hind Bag at 5, Bidhan Sarani")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 27 </td>
                                            <td> {t("Area :")} 15,785.57 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td>
                                            {t("Tank at the Centre for Swimming Practice, Children's park, Shelter Shed &amp; Flower Garden")}
                                            </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Sadhana Sankar Uddyan at 204/2, APC Rd.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 28 </td>
                                            <td> {t("Area :")} 3545.06 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Mohila Yoga Kendra")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Vidyasagar Park at 26/1, Badur Bagan Lane")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 38 </td>
                                            <td> {t("Area :")} 3356.94 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Hrishikesh Park")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 38 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> NA </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Tarasundar Park, 56, Ratan Sankar Garden Lane")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 23 </td>
                                            <td> {t("Area :")} 1615.90 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("VAT CESC's Room")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Saroda Banerjee Park")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 25 </td>
                                            <td> {t("Area :")} 2782.36 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Jhama Pukur Square at 36, Guru Prosad Chowdhury Lane")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 38 </td>
                                            <td> {t("Area :")} 1895.71 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    </div>      </div>
                    </div>
                    </div>
                    <div className="card">
                    <div className="card-header" id="heading5">
                        <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                        V </button>
                    </div>
                    <div id="collapse5" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3">
                        <div className="mayor-table2">
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                           {t(" Vidyasagar Uddyan (College Square) 53/1, College St.")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 40 </td>
                                            <td> {t("Area :")} 17,792.21 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Corner &amp; Swimming Tank")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("Water Body")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Millenium Park from Babughat to Kadamtala")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 45 </td>
                                            <td> {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("Garden")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Girish Park at the Junction of C.R.Avenue &amp; Vivekanda Rd.")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 25 </td>
                                            <td> {t("Area :")} 3649.58 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td>
                                            
                                            {t("Children's Park, Landscape, Garden Fountain, Lilypool with over bridge.")}
                                            </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Town Hall &amp; Millenium Park")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 45 </td>
                                            <td> {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Landscape &amp; Flower Bed ")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Shradhananda Park at 34, Suyra Sen St.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 49 </td>
                                            <td> {t("Area :")} 6613.55 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td>
                                            
                                            {t("Children's Playground, Nursery, Flower Bed, KMC Office")}
                                            </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> NA </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Santosh Mitra Square (Lebutola Park), Ram Narayan Motilal Lane")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 50 </td>
                                            <td> {t("Area :")} 9832.53 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Playground, Nursery")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    </div>
                        </div>
                        </div>
                    </div>
                

                    <div className="card">
                    <div className="card-header" id="heading6">
                        <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                        VI </button>
                    </div>
                    <div id="collapse6" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3">
                        <div className="mayor-table2">
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Chaplin Square &amp; CMO Bldg. 5/2, Chowringhee Place, Kol-13")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 46 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Corner ")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Girin Banerjee Park on Ganesh Ch. Avenue")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 47 </td>
                                            <td> {t("Area :")} 2742.41 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Corner &amp; Nursery")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Convent Park at 13, Convent Rd.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 55 </td>
                                            <td> {t("Area :")} 4297.55 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Corner, Nursery")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Ramlila Park")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 55 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Playground &amp; Children's Park ")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("C.I.T. Padyapukur Park")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 55 </td>
                                            <td> {t("Area :")} {t("NA")}</td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {("Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td>{t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Hazi Mohammed Mahasin Square")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 62 </td>
                                            <td> {t("Area :")} 16,588.22 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Pond")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Mireea Elliod Square (Ripon Square)")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 62 </td>
                                            <td> {t("Area :")} 2325.02 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Triangular Park Jn. of Rafi Ahmed Kidwai St. &amp; Ripon St.")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 62 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Park opposite Pearless Inn")}</td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 46 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    </div>        </div>
                    </div>
                    </div>
                    <div className="card">
                    <div className="card-header" id="heading7">

                        <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                        VII </button>
                    </div>
                    <div id="collapse7" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3">
                        <div className="mayor-table">
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Rana Pratap Uddyan (Mc. Pherson Square) at 14/1, Loudon St.")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 63 </td>
                                            <td> {t("Area :")} 4180.50</td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Park &amp; Nursery ")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Loudon Square (Nature Park) at 4, Loudon St.")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 63 </td>
                                            <td>{t("Area :")} 7625.23</td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Nature Study Park")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Gopinath Saha Uddyan (Allen Park) at 28, Park St.")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 63 </td>
                                            <td>{t("Area :")} 5493.18</td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Victoria Sq./Albent TRd.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 63 </td>
                                            <td> {t("Area :")} 13,310.71 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Park &amp; Water Body")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Mintoo Park, 5/1, A.J.C. Bose Rd.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 63 </td>
                                            <td> {t("Area :")} 14,782.25 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Park &amp; Water Body")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("NAtional Congress Park (Park Circus Maidan)")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 64 </td>
                                            <td> {t("Area :")} 36,954.40 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Children Park on Ekdalia Rd.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 68 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td>{t("Children's Corner ")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Deshapriya Park ")}</td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 85 </td>
                                            <td> {t("Area :")} 27,628.65 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Playground, Tennis Court, Children's Park")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Children's Park at 108, D.C.Dey Rd.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 56 </td>
                                            <td> {t("Area :")} 3854.42 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Childern's Park")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Park at 7, Carnfield Rd.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 68 </td>
                                            <td> {t("Area :")} 3854.42 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Corner ")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    </div>        </div>
                    </div>
                    </div>
                    <div className="card">
                    <div className="card-header" id="heading8">
                        <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
                        VIII</button>
                    </div>
                    <div id="collapse8" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3">
                        <div className="mayor-table">
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Maddox Square at 9, Pankaj Mallik Sarani")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 69 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Dwarkanath Mitra Square at 13, Ashutosh Mukherjee Rd., Kolkata-20")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 70 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Playground ")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Northern Park at 48, Allenby Rd.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 70 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Woodburn Park at 28 Woodburn Rd.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 70 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Corner &amp; Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Landsdown Square at 49, Padmapukur")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 72 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Water Tank")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Harish Park at 16, Harish Mukherjee Rd. Kolkata-25")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 73 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Corner &amp; Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Jatin Das Park at 9/1, S.P.Mukherjee Rd. Kolkata-25")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 73 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Corner &amp; Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Kalighat Park at 125, S.P.Mukherjee Rd.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 84 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Corner &amp; Playground ")}</td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Sarat Chatterjee PArk (Triangular Park) at 172/2, R.B.Avenue. Kolkata-29")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 86 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    </div>      
                        </div>
                        </div>
                    </div>
                    <div className="card">
                    <div className="card-header" id="heading9">
                    <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
                    IX</button>
                    </div>
                    <div id="collapse9" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3">
                        <div className="mayor-table">
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Padmapukur Square (Khidirpore)")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 76 </td>
                                            <td> {t("Area :")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Tank &amp; Garden")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> VA </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Nabab Ali Park (Ekbalpur)")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 78 </td>
                                            <td> {t("Area :")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Chetla Park at 30, Chetla Central Rd.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 82 </td>
                                            <td> {t("Area :")} 8568.17 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Corner &amp; Playground")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Mysore Garden (Tollygunge Rd.)")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 88 </td>
                                            <td> {t("Area :")} 3015.35 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Flower Garden")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Triangular Park on Pratapaditya Rd.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 88 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Garden")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Alipore Office Garden")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 74 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Nursery")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Mani Sannyal Uddyan")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 82 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Garden")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                           {t(" Deshpran Sashmal Park at 10, Nepal Bhattacharjee St.")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 83 </td>
                                            <td> {t("Area :")} 4265.97 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td>{t("Playground &amp; Children's Park")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Desh Bandhu C.R.Das Memorial. 111, Tollygunge Road")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 88 </td>
                                            <td> {t("Area :")} {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td>
                                            
                                           {t(" Flower Garden with memorial tomb of Deshbandhu C.R.Das")}
                                            </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    </div>
                </div>
                        </div>
                    </div>
                    <div className="card">
                    <div className="card-header" id="heading10">
                    <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse10" aria-expanded="true" aria-controls="collapse10">
                    X</button>
                    </div>
                    <div id="collapse10" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3">
                        <div className="mayor-table">
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Datu Fadkar Memorial (New Alipur) ")}</td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 81 </td>
                                            <td> {t("Area :")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park &amp; Children's park")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Tanupukur Park at Sarat Ghosh Garden Rd.")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 92 </td>
                                            <td> {t("Area :")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Jodhpur Children's Park")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 93 </td>
                                            <td> {t("Area :")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Tilak Nagar Park")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 95 </td>
                                            <td> {t("Area :")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park")} </td>
                                            <td> {t("NA")} </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Sishu Uddyan at Regent Estate Park")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 96 </td>
                                            <td> {t("Area :")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Playground")} </td>
                                            <td> {t("NA")} </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("Regent Park in Govt. Estate at N.S.C.Bose RD")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 97 </td>
                                            <td> {t("Area :")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park")} </td>
                                            <td> {t("NA")} </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Niranjan Sengupta Park")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 99 </td>
                                            <td> {t("Area :")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td>{t(" Children's Park &amp; Playground")} </td>
                                            <td> {t("NA")} </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Park at New Alipore 'O' Block")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 81 </td>
                                            <td> {t("Area :")} 2960.17 </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park")} </td>
                                            <td> {t("NA")} </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Tarakeswar Sen Uddyan at Babu Bagan")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 92 </td>
                                            <td> {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Playground")} </td>
                                            <td> {t("NA")} </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Bimal Ghosg Uddyan")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 92 </td>
                                            <td> {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park ")}</td>
                                            <td> {t("NA")} </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Layalka Park (N.S.C. Bose Rd.)")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 96 </td>
                                            <td> {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park &amp; Playground")} </td>
                                            <td> {t("NA")} </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Bijoygarh Park (Bijoygarh Rd.)")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 96 </td>
                                            <td> {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park &amp; Playground")} </td>
                                            <td> {t("NA")} </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td>
                                            
                                            {t("RABINDRA VIVEKANANDA SARDHA SATABARSHO SMARAK UDDAN")}
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 97 </td>
                                            <td> {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Playground &amp; Nursery")} </td>
                                            <td> {t("NA")} </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Jibananda Park")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 98 </td>
                                            <td> {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park")} </td>
                                            <td> {t("NA")} </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Park at Sree Colony Block, Block-1")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 99 </td>
                                            <td> {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park &amp; Playground")} </td>
                                            <td> {t("NA")} </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Name :")}</strong>
                                            </td>
                                            <td> {t("Lakshi Narayan ParK")} </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong>{t("Ward No :")} </strong>
                                            </td>
                                            <td> 100 </td>
                                            <td> {t("NA")} </td>
                                            <td> {t("No. of Mali :")}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Amenities :")} </strong>
                                            </td>
                                            <td> {t("Children's Park")} </td>
                                            <td> {t("NA")} </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td scope="row">
                                            
                                            <strong> {t("Remarks :")} </strong>
                                            </td>
                                            <td> {t("NA")} </td>
                                            <td></td>
                                            <td> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    </div>
                        </div>
                    </div>

                    
                    </div>
                
             
    </div>
        </div>
          </div>
         </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Parks_and_gardens