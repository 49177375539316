import React from 'react';
import { Link } from "react-router-dom";

const Art_Galleries = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
    const [ProjectService, setProjectService] = React.useState(false);

  const ProjectServiceToggle = () => {
    setProjectService(!ProjectService);
  };
  return (
    <>  
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
 
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
        <div className="container"> <div className="section-main-heading2"> <h4 className="fontSizeClass">About_Kolkata </h4></div></div>             
      </div>       	 		 
    </section>	
    <section className="section inner-pad1">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="site-menu">           
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/About_Kolkata'> About Kolkata </Link> </li>
                  <li className="nav-item"><Link className="nav-link act" to='/map'> Map </Link></li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/History_Of_Kolkata'> History</Link></li>
                  <li className="nav-item">
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">Places of Interest<i className="bi bi-chevron-down" /></button>
                      <div id="demo2" className="collapse show">                        
                            <Link className="nav-link fontSizeClass active1" to='/Art_Galleries'>1. Art Galleries</Link>
                            <Link className="nav-link fontSizeClass" to='/Amusement_Parks'>2. Amusement Park </Link>
                            <Link className="nav-link fontSizeClass" to='/Architecture'>3. Architecture </Link>
                            <Link className="nav-link fontSizeClass" to='/Other_Noteable_place'>4. Other Notable Places </Link>  
                      </div>
                  </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Basic_statestics'> Basic Statistics </Link> </li>  
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/UEVRP_Home'> UEVRP </Link></li>   
                </ul>
              </nav>	   
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2">
                  <h3 className="fontSizeClass">Places of Interest</h3>
                </div>
                  <div className="col-lg-12 about-head1">
                      <h5 className="fontSizeClass"> <strong>Art Galleries / Museums / Libraries</strong> </h5>		   
                      <p className="fontSizeClass">Life never stops in Kolkata. At any time of the year there is something going on - a festival, a wedding, a match, a demonstration, a concert, a vernissage or a party. For some, Calcutta - Kolkata is the city of joy, while for others it is dirty, crowded, and noisy.</p>		
                  </div>
  
                  <div className="col-lg-12 p-0" id="accordion">
                    <div className="card">
                      <div className="card-header" id="heading1">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                      Academy of Fine Art
                      </button>
                      </div>
                      <div id="collapse1" className="collapse show" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                          <img src="assets/img/art.jpg" className="img-fluid mt-2" alt /> 
                          <p className="fontSizeClass">Established in 1933, the Academy is a place where the city's culturally conscious intellectuals converge. It's art galleries feature exhibitions by contemporary artists throughout the year. The Rabindra gallery contains personal belongings, manuscripts and paintings by Tagore. Situated near Victoria Memorial, its galleries are open daily from 3 pm to 8 pm (except the Rabindra Gallery; 12.00 noon to 6 pm daily, closed on Mondays).</p> 	<br />		 
                          <p className='mt-2'>Address: 2, Cathedral Road, Kolkata 700016.<br />Ph.: 2223 4302 /4303</p>
                          </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading2">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                        Academy of Indian Coins & History</button>
                      </div>
                      <div id="collapse2" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">Established in 1997, a voluntary organisation dealing in ancient Indian coins and history. It is opened on weekdays from 11 am to 1 pm. Address: 361/B, Rabindra Sarani</p>
                         </div>                      
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading3">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                        Arts Acre
                      </button>
                      </div>
                      <div id="collapse3" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">A village for the visual arts with art gallery, artists in residence and artists at work. Address: Gopalpur , 24 Parganas (North), Pin - 743518<br />Ph.: 2331 1372 (City Office)</p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading4">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                        Asiatic Society </button>
                      </div>
                      <div id="collapse4" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                        Located at 1 Park Street, Kolkata 700016, this institution is a famous centre of Indology, Literature and Scientific Research. It was founded by Sir William Jones in 1784 with Warren Hastings as its first patron. Its library contains about 20000 volumes including about 8000 rare Sanskrit, Arabic, Persian and Hindi manuscripts.
                         </div>
                    </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading5">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                        Birla Academy of Art & Culture</button>
                      </div>
                      <div id="collapse5" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">A well maintained state gallery with exhibits depicting the artistic and cultural heritage of India and a permanent exhibition of Indian contemporary art. There are also archaeological specimens there. It is open from 4 pm to 8 pm.</p>
                        <p className="fontSizeClass">Address: 108 & 109 Dr. Meghnad Saha Sarani Kolkata - 700 029<br />Ph.: 2466 2843 / 6802</p>
                        </div>
                        </div>
                      </div>
  
                      <div className="card">
                      <div className="card-header" id="heading6">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                        Birla Industrial & Technological Museum </button>
                      </div>
                      <div id="collapse6" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">Established in 1966, a permanent exhibition of industrial and technological progress in India. A seat of learning of modern science and technology for students, research scholars etc. Has a workshop which designs and produces much of its exhibits. Closed on Mondays.</p>
                        <p className="fontSizeClass">Address: 19A Gurusaday Road. Kolkata - 700019<br />Ph.: 2247 7241-43 / 6102</p>
                        </div>
                      </div>
                      </div>
  
                      <div className="card">
                      <div className="card-header" id="heading7">
  
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                        Centres of International Modern Art (CIMA) </button>
                      </div>
                      <div id="collapse7" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">Establishes in 1993, the CIMA Art Gallery is situated at 'Sunny Tower' in Asutosh Chowdhury Avenue, near Birla Temple. It is the first Art Gallery in Calcutta matching international standards and its cover area is no less than 11,500 sq. ft.</p>
                        </div>
                      </div>
                      </div>
  
                      <div className="card">
                      <div className="card-header" id="heading8">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
                        Gandhi Smarak Sangrahalay </button>
                      </div>
                      <div id="collapse8" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">Located in Barrakpore, this smarak sangrahalaya holds a library and a museum on the life of Mahatma Gandhi.</p><br /><br />
                          <p className="fontSizeClass">Address: 14, Riverside Road Barrackpore - 743 101 West-Bengal Ph: 5600170</p>    </div>
                        </div>
                      </div>
  
                      <div className="card">
                      <div className="card-header" id="heading9">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
                      Government College of Art & Crafts </button>
                      </div>
                      <div id="collapse9" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">An almamater of many artistes and sulptors of national and international fame.</p><br /><br />
                          <p className="fontSizeClass">Address: 28 J.L.Nehru Rd. , Kolkata - 700071<br />Ph.:2249 2027</p>
                      </div>
                        </div>
                      </div>
  
                      <div className="card">
                      <div className="card-header" id="heading10">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse10" aria-expanded="true" aria-controls="collapse10">
                      Gurusaday Museum </button>
                      </div>
                      <div id="collapse10" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">About 15km from Kolkata on the Diamond Harbour Road, this museum contains a small but rich collection of the folk arts and crafts of undivided Bengal. Seminars & demonstrations on folk art & culture are held in the annexe and the grounds of the museum.</p><br /><br />
                        <p className="fontSizeClass">Address: Bratacharigram, Joka South 24 Parganas.</p>              
                        </div>
                      </div>
                      </div>
  
                      <div className="card">
                      <div className="card-header" id="heading11">
                        
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                        Indian Museum  </button>
                      </div>
                      <div id="collapse11" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">Situated in Chowringhee on Jawaharlal Nehru Road, it was founded in 1814. It is the largest museum in the country and has rare collection of antiques, armour & ornaments, fossils, skeletons, mummies, mughal paintings etc. It remains opened everyday from 10 a.m. to 5 p.m. except Mondays during March to November & between 10 am - 4:30 pm during December to February.</p> 	<br />		 
                          <p className='mt-2'>Address: J.L.Nehru Road. Kolkata 700 071<br />ph.: 2249 9902 / 8931</p>   </div>
                        </div>
                      </div>
  
                      <div className="card">
                      <div className="card-header" id="heading12">
                        
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse12" aria-expanded="true" aria-controls="collapse12">
                        Jorasanko Thakurbari  </button>
                      </div>
                      <div id="collapse12" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">Situated near Girish Park on Chittaranjan Avenue, the birth place of National Poet Rabindra Nath Tagore. The home of the Tagore family is now the premises of Rabindra Bharati University and a museum of Tagore memorabilia.</p> 	<br />		 
                          <p className='mt-2'>Address: 286 Rabindra Sarani. North Kolkata<br />Ph.: 2239 5242</p>                    </div>
                        </div>
                      </div>
  
                      <div className="card">
                      <div className="card-header" id="heading13">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse13" aria-expanded="true" aria-controls="collapse13">
                        Metcalfe Hall </button>
                      </div>
                      <div id="collapse13" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">Located in Strand Road, it is an imperial library with one hall. Its thirty column rising 3feet and resembling the ' Tower of Winds ' in Athens, homours Sir Charles Metcalfe's efforts towards a free press.</p>
                        </div>
                        </div>
                      </div>
  
                      <div className="card">
                      <div className="card-header" id="heading14">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse14" aria-expanded="true" aria-controls="collapse14">
                        National Library  </button>
                      </div>
                      <div id="collapse14" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                          <p className="fontSizeClass">The 2nd largest library in Asia . Its collection is in excess of 1million books and 5,00,000 manuscripts, with a number of over 18,000 reader-members. Several notable persons have donated their invaluable collections to the Library at different times. Sadar – Ud-ddin, the Zaminder of Buhor, Sir Ashutosh Mukherjee, Surendra Nath Sen etc. are some famous donors. These have added to the value and content of books in this prestigious institution.</p> 	<br />		 
                          <p className='mt-2'>Address: Belvedere Road. Alipore. Kolkata 700 027.<br />Ph.: 2479 1381</p>                    </div>
                        </div>
                      </div>
  
                      <div className="card">
                      <div className="card-header" id="heading15">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse15" aria-expanded="true" aria-controls="collapse15">
                        Nehru Children Museum  </button>
                      </div>
                      <div id="collapse15" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">It is a centre for children's activity, collection of puppets depicting the Ramayana & the Mahabharata, toys & dolls and a huge library for children. This is opened on all days except Mondays from 12-00 noon to 8 pm.</p> 	<br />		 
                        <p className='mt-2'>Address: 94/1 J.L. Nehru Road. Kolkata -20<br />Ph.: 2223 3517.</p> </div>
                        </div>
                      </div>
  
                      <div className="card">
                      <div className="card-header" id="heading16">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse16" aria-expanded="true" aria-controls="collapse16">
                        Netaji Research Bureau</button>
                      </div>
                      <div id="collapse16" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">Established in 1961 at the Bose family house on Elgin Road, this bureau holds the Netaji Subhas Ch. Bose archieval material including Freedom Library and his personal effects. It is open from 12 noon to 4 pm.</p>
                        <p className="fontSizeClass">Address: Elgin Road , Lala Rajpat Rai Sarani<br />Ph.: 2475 6139</p>
                      </div>
                      </div>
  
                    </div>
  
                    <div className="card">
                      <div className="card-header" id="heading17">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse17" aria-expanded="true" aria-controls="collapse17">
                        Rabindra Bharati Museum</button>
                      </div>
                      <div id="collapse17" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">The Museum contains vestiges of the Bengal Renaissance of the nineteenth century. Situated near Girish Park on Chittaranjan Avenue, it is open from 10.00 a.m. to 5.00 p.m. on weekdays (1.30 p.m. on Saturdays).</p>
                        <p className="fontSizeClass">Ph.: 2239 6601 / 6610 / 5241</p>
                      </div>
                      </div>
  
                    </div>
  
                    <div className="card">
                      <div className="card-header" id="heading18">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse18" aria-expanded="true" aria-controls="collapse18">
                        Ramakrishna Mission Institute of Culture</button>
                      </div>
                      <div id="collapse18" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">The Ramakrishna Mission Institute of Culture at Gol Park, Calcutta, is a branch of Ramakrishna Math and Ramakrishna Mission, Belur Math (Worldwide headquarters of all RKM centres and sub-centres.). The Institute is well known for its philanthropic, educational and cultural activities all over the world. The building is having a very good Auditorium with a very good Library which contains rare books on History, Religion, Philosophy etc.</p>
                        <p className="fontSizeClass">Address: Gol Park, Calcutta - 700 029<br />Ph: 2464 1303/ 04/ 05</p>
                      </div>
                      </div>
  
                    </div>

                  </div>
              </div> 
            </div>
          </div>
          </div>
      </div>
    </section>
      </>
  )
}

export default Art_Galleries