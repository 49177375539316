import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

    const MC_Circular_2024_25 = () => {
        const fileDownload=(fileStoreId)=>
        {
          
          let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
           axios
              .post(url,{"fileId": fileStoreId, "userId": "392" })
              .then((res) => {
                  console.log("response is --- ", res);
      
                  if(res && res.data && res.status === 200) {
                    const base64 = res.data[0] && res.data[0].base64_data;
                    const pdfData = 'data:application/pdf;base64,'+base64;
      
                    let html = '<html>' +
                        '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                        '<body>' +
                        '<iframe src="' + pdfData + '"></iframe>' +
                        '</body></html>';
                        console.log(html);
                    let a = window.open();
                    a.document.write(html);
                }
              })
              .catch((e) => {
                  // setLoader(false);
                  console.log("The error is ", e);
              })
        }
        const increaseFontSizeBy1px =() => {
          var el = document.getElementsByClassName('fontSizeClass');
          for (let i = 0; i < el.length; i++) {
            var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
            var fontSize = parseFloat(style); 
            el[i].style.fontSize = (fontSize + 1) + 'px';
          }
        };
        const decreaseFontSizeBy1px =() => {
          var el = document.getElementsByClassName('fontSizeClass');
          for (let i = 0; i < el.length; i++) {
          var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
          var fontSize = parseFloat(style); 
          el[i].style.fontSize = (fontSize - 1) + 'px'; 
          }
        };
      const [DocumentService, setDocumentService] = React.useState(false);
      const [LicenseService, setLicenseService] = React.useState(false);
      
      const LicenseServiceToggle = () => {
        setLicenseService(!LicenseService);
      };
      const DocumentServiceToggle = () => {
          setDocumentService(!DocumentService);
          };
          const { t, i18n, ready } = useTranslation();
        return (
          <>
        <section id="hero" className="hero hero2">
          
          <div className="carousel-item active">
            <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
          </div>
        </section>
        <section className="section inner-pad1">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="site-menu  min-height-400">
                    <nav className="sidebar card py-2">
                      <ul className="nav flex-column" id="nav_accordion">
                        <li className="nav-item-list ">
                        <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Municipal Commissioner CIRCULAR")}<i className="bi bi-chevron-down " /></button>
                          <div id="demo" class="collapse">
                          <li className="nav-item"><Link className="nav-link fontSizeClass active1" to='/MC_Circular_2024_25'> {t("MC's Circular")} 2024-25 </Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2023_24'> {t("MC's Circular")} 2023-24 </Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2022_23'> {t("MC's Circular")} 2022-23 </Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2021_22'> {t("MC's Circular")} 2021-22</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2020_21'>{t("MC's Circular")}  2020-21</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2019_20'>{t("MC's Circular")}  2019-20</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2018_19'> {t("MC's Circular")} 2018-19</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2017_18'>{t("MC's Circular")} 2017-18</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2016_17'>{t("MC's Circular")} 2016-17</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2015_16'>{t("MC's Circular")} 2015-16</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2004_05'>{t("MC's Circular")} 2004-05</Link></li>
                          </div>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link fontSizeClass" to='/Municipal_secretary_circular'>{t("MUNICIPAL SECRETARY'S CIRCULAR")}</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link fontSizeClass" to='/CMF_Communique_2022_23'>{t("C M F & A's Circular and Communique - 2022-23")}</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link fontSizeClass" to='/Chief_Manager_Circular'>{t("Chief Manager (P)'s Circular")}</Link>
                        </li>
                        <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Govt_memo'>{t("Govt Memo")}</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1558')}> {t("West Bengal Right to Public Service Act,2013")} </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1559')}>{t("Guidelines of Section 199(2) of KMC Act,1980 (as amended in 2019)")}  </a>
                  </li>
                  <li className="nav-item-list ">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("COMPENDIUM OF IMPORTANT DEPARTMENTAL CIRCULARS / ORDERS")}<i className="bi bi-chevron-down " /></button>
                      <div id="demo1" className="collapse">                        
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>1. Financial Year 2010-11 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>2. Financial Year 2011-12 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('870')}>3. Financial Year 2012-13 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('925')}>4. Financial Year 2013-14 </a>   
                      </div>
                  </li>
                        
                      </ul>
                    </nav>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="container">
                  <div className="row">
                    <div className="inner-title2 bas">
                      <h3 className="fontSizeClass"> {t("Circular")} </h3>
                    </div>
                    <div className="health-dept-table mayor-table m-ta-2">
                      <table className="table table-bordered officials" >
                        <tbody>
                          <tr
                            bgcolor="#302ba0"
                            tabIndex={0}
                            align="center"
                            valign="middle">
                            <td width="35%" className="text-white tble-brder-left">
                            {t("Circular No")}		
                            </td>
                            <td width="20%" className="text-white">
                            {t("Date")}		
                            </td>
                            <td width="45%" className="text-white tble-brder-right">
                            {t("Subject")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular11_02_05_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 11 of 2024-25 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("26-April-2024")}			
                            </td>
                            <td align="center">
                            {t(" Management of C&D Waste and Realisation of Charges for Transportation and Processing by the Kolkata Municipal Corporation. ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular10_23_04_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 10 of 2024-25 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("20-April-2024")}			
                            </td>
                            <td align="center">
                            {t(" Formation of a 'Manpower Evaluation Committee' to Examine, Review and optimization of the existing Human Resource of KMC. ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular09_23_04_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 09 of 2024-25 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("19-April-2024")}			
                            </td>
                            <td align="center">
                            {t(" Activities of Central Demolition Squad of Building Department, KMC ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular08_20_04_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 08 of 2024-25 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("19-April-2024")}			
                            </td>
                            <td align="center">
                            {t(" Procedure to be followed in respect of Court / Tribunal Matters.")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular07_20_04_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 07 of 2024-25 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("19-April-2024")}			
                            </td>
                            <td align="center">
                            {t(" Posting of Guard at unauthorised construction site. ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular05_20_04_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 05 of 2024-25 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("18-April-2024")}			
                            </td>
                            <td align="center">
                            {t("Disconnection of Water Supply & Drainage from unauthorised buildings")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular03_15_04_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 03 of 2024-25 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("13-April-2024")}			
                            </td>
                            <td align="center">
                            {t("Space Management Committee")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular02_15_04_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 02 of 2024-25 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("12-April-2024")}			
                            </td>
                            <td align="center">
                            {t("Implementation of UDIN")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular01_09_04_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 01 of 2024-25 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("09-April-2024")}			
                            </td>
                            <td align="center">
                            {t("Protection during demolition of existing building & construction of new Building by erecting scaffolding and providing net the proper covering.")}
                            </td>
                          </tr>

                          
                          
                          </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
        )
      }

export default MC_Circular_2024_25