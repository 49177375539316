import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Contact_Us_Home  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <div className="fontSizeClass">
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"> <h4 className="fontSizeClass"></h4></div></div>             
    </div>       	 		 
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">           
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Contact_Us_Home'> {t("Contact Us Home")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/EWaste'> {t("E-Waste")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/'>  {t("Important Contacts")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MMIC'> {t("MMIC")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Controlling_Officers'> {t("Controlling Officers")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Councillors'> {t("Councillors")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Borough_Committee'> {t("Borough Committee")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Borough_Committee'> {t("Borough Offices")} </Link> </li>
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCWard.jsp'> {t("Know Your Ward")}</a> </li>              								
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2"><h3 className="fontSizeClass"> {t("Contact Us")}: </h3></div>		   
            <div className="col-lg-12 about-head1">
              <div className="mayor-table2">		 	   
                <table className="table table-bordered"> 			
                  <tbody>
                    <tr>              
                      <td> <strong> {t("Kolkata Municipal Corporation")} -</strong> <br /> 
                        <p className="fontSizeClass"> {t("5, S.N.Banerjee Road,Kolkata 700 013, India")}  <br />
                        {t("Ph")} : +91 33 2286-1000 (28 Lines) </p> </td>
                      <td> <strong> {t("Control Room")}  - </strong> <br /> 
                        <p className="fontSizeClass"> {t("Ph")}: +91 33 2286-1212/1313/1414 <br />
                          {t("Fax")} +91 33 2286-1444 </p> </td>
                    </tr>
                    <tr>
                      <td colSpan={2}> <strong>  {t("Call Centre")}  -</strong>  <br />
                        <p className="fontSizeClass"> {t("KMC Working Days")}
                          {t("Mon-Fri : 10:00 AM to 6:00 PM")}
                          {t("Sat : 10:00 AM to 5:00 PM")}
                          {t("Ph")} : 2226-9909
                          18003453375 {t("(Toll Free)")} </p></td>             
                    </tr>
                    <tr>
                    <td> <strong>  {t("WhatsApp")}  -</strong> <p className="fontSizeClass">{t("Ph")}:8335988888</p> </td> 
                      <td> <strong>  {t("CHATBOT")}  -</strong> <p className="fontSizeClass">  {t("Ph")}:8335999111 </p></td>            
                    </tr>          
                  </tbody>
                </table>
                <div className="mayor-table2 mayor-table">		 	   
                <table className="table table-bordered"> 			
                    <tbody>
                      <tr>
                      </tr><tr className="table-warning table-bor-2">				                 
                        <th colSpan={3} scope="col">{t("Important Departments with Contact Details")}
                          {t("(Click on the dept. to get the nos.)")} </th>                 
                      </tr>
                      <tr>
                        <td> <Link to="/Advertisement_Dept">{t("Advertisement Department")}</Link> </td>
                        <td> <Link to="/Amusement_Dept">{t("Amusement Department")} </Link> </td>
                        <td> <Link to="/Assessment_Dept"> {t("Assessment Department")} </Link> </td>
                      </tr>
                      <tr>
                        <td> <Link to="/">{t("Building Department")}</Link> </td>
                        <td> <Link to="/Bustee_Dept"> {t("Bustee Department")} </Link> </td>
                        <td> <Link to="/Car_Parking_Dept"> {t("Car Parking Department")} </Link> </td>
                      </tr>
                      <tr>
                        <td> <Link to="/Chief_Eng_Dept"> {t("Chief Mpl. Engineer Department")} </Link> </td>
                        <td> <Link to="/Ch_VS_Dept"> {t("Ch. V&S Department")} </Link> </td>
                        <td> <Link to="/Sewerage_Drainage_Dept"> {t("Sewerage Drainage Department")} </Link> </td>
                      </tr>
                      <tr>
                        <td> <Link to="/Education_Dept"> {t("Education Department")} </Link> </td>
                        <td> <Link to="/Electricity_Dept"> {t("Electricity Department")} </Link> </td>
                        <td> <Link to="/Finance_Dept"> {t("Finance & Accounts Department")} </Link> </td>
                      </tr>
                      <tr>
                        <td> <Link to="/Health_Dept"> {t("Health Department")} </Link> </td>
                        <td> <Link to="/Internal_Audit_Dept"> {t("Internal Audit Department")} </Link> </td>
                        <td> <Link to="/IT_Dept"> {t("Information Technology Department")} </Link> </td>
                      </tr>
                      <tr>
                        <td> <Link to="/Law_Dept"> {t("Law Department")} </Link> </td>
                        <td> <Link to="/Licence_Dept"> {t("Licence Department")} </Link> </td>
                        <td> <Link to="/Lighting_Dept"> {t("Lighting Department")} </Link> </td>
                      </tr>
                      <tr>
                        <td> <Link to="/Market_Dept"> {t("Market Department")} </Link> </td>
                        <td> <Link to="/Mpl_Secretary_Dept"> {t("Mpl. Secretary's Department")} </Link> </td>
                        <td> <Link to="/Parks_Square_Dept"> {t("Parks & Square Department")} </Link> </td>
                      </tr>
                      <tr>
                        <td> <Link to="/Personnel_Dept"> {t("Personnel Department")} </Link> </td>
                        <td> <Link to="/Environment_Heritage_Dept"> {t("Environment & Heritage Department")} </Link> </td>
                        <td> <Link to="/Roads_Dept"> {t("Roads Department")} </Link> </td>
                      </tr>
                      <tr>
                        <td> <Link to="/Social_Welfare_Urban_Dept"> {t("Social   Welfare & Urban Proverty Alleviation Department")} </Link> </td>
                        <td> <Link to="/Solid_Waste_Dept"> {t("Solid Waste Mgmt. Department")} </Link> </td>
                        <td> <Link to="/Town_Planning_Development_Dept"> {t("Town Planning & Development Department")} </Link> </td>
                      </tr>
                      <tr>
                        <td colSpan={1}> <Link to="/Treasury_Dept"> {t("Treasury Department")} </Link> </td>
                        <td> <Link to="/Water_Supply_Dept"> {t("Water Supply Department")} </Link> </td>             
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>			
            </div>		  
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>
</div>
  )
}

export default Contact_Us_Home