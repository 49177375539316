import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Assessment_Collection_FAQ  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
    const { t, i18n, ready } = useTranslation();
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> </h4>
        </div>
      </div>
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu   min-height-400">
            <nav className="sidebar card py-2 mb-4">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_Collection_Assessment_Home'>  {t("Assessment Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_Process_Muation'> {t("Process of Mutation")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_Collection_Unit_Area_Assessment'> {t("Unit Area Assessment")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('289')}> {t("Tax Rates / Fees")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_download_forms'> {t("Download Forms")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_Message_Board'> {t("Message Board")}  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_Notice'> {t("Notice")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/HearingNoticeSearch.jsp'>  {t("Check Hearing Notices")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/MutationStatusSearch.jsp'> {t("Check Mutation Status")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/NocDetails.jsp'> {t("Download NOC(A.C)")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCAssessmentCheckPaymentStatus.jsp'> {t("Check Payment Status")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCAssessmentCurrentPD.jsp'> {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WaiverApplicationFP.jsp'> {t("Application for Upto 50% Intrest Waive")} </a>
                </li>
                <li className="nav-item">
                        <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Print Unpaid Bill")}<i className="bi bi-chevron-down" /></button>
                        <div id="demo" className="collapse">                        
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAsmtAllUnpaidDemandPrint.jsp">1. {t("All Bill")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/PDDemandPrint.jsp">2. {t("Current PD")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/FSDemandPrint.jsp">3. {t("F/S Bill")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDemandPrint.jsp">4. {t("Outstanding LOI")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDemandWaiverFiftyPrint.jsp">5. {t("Upto 50% Interest Waive LOI")}</a>
                        </div>
                </li>
                <li className="nav-item">
                        <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("Make Online Payment")}<i className="bi bi-chevron-down" /></button>
                        <div id="demo1" className="collapse">                        
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssesmentAllUnpaidDemand.jsp">1. {t("All Bill")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssesmentLoiWaiverFiftyPayment.jsp">2. {t("Upto 50% Interest Waive LOI")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/MiscellaneousSuspensePay.jsp">3. {t("Suspense")}</a>
                        </div>
                </li>
                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Reprint e-Receipt")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo2" className="collapse">                        
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssessmentCombinedReceiptReprint.jsp">. {t("All Bill")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/PDDuplicateReceipt.jsp">2. {t("Current PD, F/S")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDuplicateReceipt.jsp">3. {t("Outstanding LOI")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDuplicateReceipt.jsp">4. {t("Waiver 2020 Receipt")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/MiscSuspDuplicateReceipt.jsp">5. {t("Suspense Receipt")}</a>
                      </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AssesseeInformationSearch.jsp'> {t("Assessment Information Search")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AssessmentReportPrint.jsp'>{t("Assessment Register")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Apply for Mailing Address Change")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Mutation Application")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('317')}> {t("Help for Online Mutation Application")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('318')}> {t("Litigated Property")} </a>
               </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Assessment_Collection_FAQ'> {t("FAQs")} </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Assessment_Dept'> {t("Contact Details")} </Link>                </li>
              </ul>
           </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className='inner-title'>
            <h3>{t("Assessment : FAQs")}</h3>
          </div>
        <div id="accordion">
        <div className="card">
            <div className="card-header" id="heading1">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                1. {t("What are the key activities of the department")}?
                </button>
            </div>
            <div id="collapse1" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> 
					
					{t("The key activities of the Assessment Department include")} :: </p>
					<p className="fontSizeClass">● {t("Identification of premises for which property tax would be charged")}.</p>
					<p className="fontSizeClass">● {t("Recording and Maintaining detail of such property")}.</p>
					<p className="fontSizeClass">● {t("Evaluating/Categorizing premises to determine the Annual Value of a property")}.</p>
					<p className="fontSizeClass">● {t("Hearing the cases and deciding the Annual Value of a property")}. </p>
					<p className="fontSizeClass">● {t("Issuing and Collecting Fresh/Supplementary (F/S) bills, which might be generated for changes in the value of an older demand owing to change in Annual Value")}.</p>
					<p className="fontSizeClass">● {t("To accord sanction of premises house drainage plan and its connection to city sewerage through Engineering Department of different Borough offices in case")}: </p>
					<p className="fontSizeClass">● {t("Tracking all such demands which become outstanding and following up with assessees, if necessary, to obtain payment")} </p>
					<p className="fontSizeClass">● {t("Maintaining Assessee Master as well as Demand Master to send PD (Preliminary Demand) Bill every year")}. </p>
					<p className="fontSizeClass">● {t("Mutation of premises")} </p>	
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading2">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                2.  {t("When should I contact the Assessment department")}?
                </button>
            </div>
            <div id="collapse2" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
					<p className="fontSizeClass">● {t("Purchase of new property - In this case individuals are required to contact KMC within 90 days of receipt of purchase deed")}.</p>
					<p className="fontSizeClass">● {t("Mutation-Succession or transfer")}.</p>
					<p className="fontSizeClass">● {t("Separation/Amalgamation/Apportionment")}.</p>
					<p className="fontSizeClass">● {t("When nature of occupancy or usage of the property changes")}. </p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading3">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                3. {t("What is mutation of premises")}?
                </button>
            </div>
            <div id="collapse3" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
					<p className="fontSizeClass">● {t("Mutation is recording the name of the valid owner in respect of a property")}. </p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading4">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                4.  {t("How long does the mutation process take")}?
                </button>
            </div>
            <div id="collapse4" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
					<p className="fontSizeClass">● {t("For simple mutation it takes 2 to 7 days and for other cases such as amalgamation, separation or apportionment it can take up to 15 days")}. </p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading5">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                5.  {t("What documentation do I need to provide the Assessment department for purchase of property, mutation, separation etc")}.?
                </button>
            </div>
            <div id="collapse5" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
					<p className="fontSizeClass">● {t("In each case including purchase of new property, mutation, separation/amalgamation/apportionment, individuals are required to complete form (A-42) and enclose additional documentation as described in form (A- 42). Form can be purchased from the Central Records counter at the ground floor of KMC headquarters. Once form (A- 42) and additional enclosures are complete, this document set could be mailed to KMC as per address or individuals could visit KMC personally. It is recommended that individuals visit the KMC office personally")}. </p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading6">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                    6. {t("Any important additional document needed with A-42")}?
                </button>
            </div>
            <div id="collapse6" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
					<p className="fontSizeClass">● {t("Additional documents are described in (A-42) and vary for purchase, amalgamation, separation, apportionment etc. However, a ‘No Outstanding Certificate’ is required for mutation. However, it is advisable to have a NOC for separation, amalgamation and apportionment but the same is not a mandatory requirement for these cases")}. </p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading7">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                7.  {t("What is the NOC? How can I obtain an NOC")}?
                </button>
            </div>
            <div id="collapse7" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> 	● {t("Mutation form (A-42) is available free of cost")}<br></br>
						● {t("Rs.100/- for Mutation/Separation/Apportionment processing fee")}<br></br>
						● {t("Amalgamation Fee")}
				   </p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading8">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
                8.  {t("How much do the documents cost")}?
                </button>
            </div>
            <div id="collapse8" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                    <p className="fontSizeClass"> {t("KMC would prepare a cost estimate on the basis of KMC schedule of charges to calculate the expenses to be included for construction of such external drainage system. You would be required to pay 25% of the total (Supervision charge) cost estimate as stipulated and also bear the total cost of restoration of road (to be disturbed) which is calculated separately at the same time")}.
				   </p>
                   <div class="mayor-table2 depart faqss-tbl">
					  <table class="table table-bordered">                        
						<tbody>			    
						 
						 <tr>
						   <td scope="row">  (A) </td>
						   <td> {t("For below 500 Sq. Mtr")}. </td>
						   <td> 300.00 </td>		
						 </tr>
						 <tr>
						  <td scope="row">  (B) </td>
						  <td> {t("For 500 Sq.Mtr. & above but less than 1000 Sq. Mtr")}. </td>
						  <td> 2000.00 </td>				
						</tr>
						<tr>
						  <td scope="row"> (C) </td>
						  <td> {t("For 1000 Sq. Mtr.& above but less than 1500 Sq. Mtr")}. </td>
						  <td> 10000.00 </td>
						</tr>
						<tr>
						  <td scope="row"> (D) </td>
						  <td> {t("For 1500 Sq. Mtr. & above")}</td>
						  <td> 20000.00 </td>			
						</tr>												  
						</tbody>
					  </table>
                    </div>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading9">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
                9.  {t("Once documents are submitted, what happens next")}?
                </button>
            </div>
            <div id="collapse9" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> {t("Once documents are handed over to KMC, the individual is given a receipt subject to presentation of a complete file. Thereafter the following steps are taken")},<br></br>
                    ● {t("Within 10 days of receipt of a complete file by KMC, a designated KMC inspector visits the premises in order to ascertain authenticity of property, its usage (residential, commercial etc), property size etc")}.<br></br>
                    ● {t("Based on this information a property is valued (see details of valuation procedure below)")}.<br></br>
                    ● {t("Details of valuation are entered into a inspection book maintained at the assessment department of each ward")}.<br></br>
                    ● {t("Depending on the valuation, clearance is sought from Deputy Assessor Collector, Assessor Collector, and Deputy Municipal Collector. The clearance is obtained within 15 days")}.<br></br>
                    ● {t("Once clearance is obtained from concerned authority, the individual’s name is mutated in the Assessment Register and an assessee number is generated. Then a letter is sent to the individual from the concerned Assessor Collector stating the annual valuation of property (Form A-105) alongwith the Assesse Number at the address provided by them")}
				</p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading10">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse10" aria-expanded="true" aria-controls="collapse10">
                10. {t("What if I do not agree with my property valuation")}?
                </button>
            </div>
            <div id="collapse10" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> ● {t("In response to form A-105, an individual may agree to sign attached declaration if she agrees with the property valuation")}.<br></br>
                    ● {t("In case an individual does not agree with property valuation, they are required to inform concerned assessor collector in writing and request for a Hearing Notice")}.<br></br>
                    ● {t("If the assess does not respond to form A-105 within 45 days, a hearing notice is sent to the assessee. Hearing Notice contains the annual valuation of property & states the name and address of hearing officer. Additionally, it states a time & location for hearing")}.<br></br>
                    ● {t("In case of vacant unconstructed land, the Annual Value w.e.f 3rd August 2008 - 2009 will be 2.5% and 7% of estimated market value for land measuring upto and above 5 cottah respectively. For effective assessment period before March 2008-09, the rate is 7% for all cases irrespective of the land area")}.<br></br>
                    {t("However, KMC has undertaken the project to introduce area based property tax system called 'Unit Area Assessment' method which would be introduced soon")}.
                </p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading11">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                11. {t("What happens at the hearing office")}?
                </button>
            </div>
            <div id="collapse11" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> ● {t("Hearing notice is issued to the assessee specifying hearing date and time and hearing officer number. Assessee can check his hearing status through the KMC website as well")}.<br></br>
                    ● {t("Upon discussions with the hearing officer, the individual may agree with property valuation and then the property tax bill is handed over to the individual on the spot")}.<br></br>
                    ● {t("If it is agreed by the hearing officer that the valuation may be inappropriate, then the hearing officer fixes the new valuation based on supporting documents presented by the property owner and assessment department. In such a case also the property tax bill (F/S Bill) is handed over on the same date")}.<br></br>
                    ● {t("If the assessee does not agree with the Annual Valuation as decided by the hearing officer, he can further appeal against the valuation under section 188 of KMC Act, 1980 by appearing before Municipal Assessment Tribunal. However, the assessee has to pay bills as per decision of the hearing officer prior to appearing before tribunal")}.
                </p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading12">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse12" aria-expanded="true" aria-controls="collapse12">
                12. {t("What is the property valuation method")}?
                </button>
            </div>
            <div id="collapse12" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> ● {t("KMC follows Annual Ratable Value Method for arriving at Annual Value. If the owner uses the property, then 'Reasonable Rent Method' is applied using the assessment department database. The property is compared with similar properties in the same location and the monthly rent is determined. This monthly rental figure is multiplied by 12 in order to arrive at the annual rental figure (say A). 10% is deducted from A as a statutory maintenance allowance to arrive at Annual Value")}.<br></br>
                    {t("If the property is tenanted, then the exact monthly rent (including service charges if any) is multiplied by 12 less 10% statutory allowance to arrive at the Annual Valuation.c. In case of Theatre/Cinema halls, 7.5% of the Gross Annual receipts (excluding taxes) is fixed as Annual Valuation for the Hall")}.
                </p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading13">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse13" aria-expanded="true" aria-controls="collapse13">
                13. {t("What is the Annual tax on property")}?
                </button>
            </div>
            <div id="collapse13" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> {t("The Annual tax on property varies with the Annual valuation of the same. Following table shows the calculation of Annual")} 
						 {t("Tax calculation according to the Annual Valuation of property")}.</p><br></br>
							<div class="mayor-table2 depart faqss-tbl">
							<table class="table table-bordered"> 			
							 <thead>
							 <tr class="table-warning table-bor-2">				  
							   <th scope="col"> {t("Sl.No")}. </th> 
							   <th scope="col"> {t("Annual Valuation (Rs) (AV)")} </th> 
							   <th scope="col"> {t("Annual Tax")} </th>
							   <th scope="col"> {t("Rebate if Tax deposited on Time")} </th>
							 </tr>
							 </thead>                        
							<tbody>			    
							 
							 <tr>
							   <td scope="row">  1. </td>
							   <td> {t("AV less than 600")} </td>
							   <td> 0.11*AV </td>
							   <td> 0.05*{t("Quarterly Tax")} </td>
							 </tr>
							 <tr>
							  <td scope="row">  2. </td>
							  <td> {t("AV greater than 600 but less than 18000")} </td>
							  <td> (AV/600 + 10)*1/100 *AV </td>
							  <td> 0.05*{t("Quarterly Tax")} </td>
							</tr>
							<tr>
							  <td scope="row"> 3. </td>
							  <td> {t("AV greater than 18,000")} </td>
							  <td> 0.4*AV </td>
							  <td> 0.05*{t("Quarterly Tax")} </td>
							</tr>													  
							</tbody>
						  </table>
						 </div>
						  <p className="fontSizeClass">1 {t("QuarterlyTax = Annual Tax/4")}.<br></br>
						  {t("Note: Commercial Surcharge not exceeding 50% of tax is levied for non-residential property")}.<br></br>
							● {t("If the Annual Valuation as fixed above, does not exceed Rs.600/-, then the rate of tax is 11% of the Annual Value. That is if A.V. is Rs,500/- then the property tax per year is Rs.55/- plus Howrah bridge tax @ 0.5% of the quarterly tax is allowed if deposited in time. To get quarterly gross amount of tax this should be divided by 4 and rounded off to the nearest rupee. Net tax after rebate will also be rounded off to the nearest rupee")}.<br></br>
							●  {t("If the Annual Valuation as fixed above, exceeds Rs.600/- but does not exceed Rs.18000/-, then tax rate is the percentage of the A.V. worked out by dividing the A.V. of the premises by 600 and adding 10 to the quotient, the sum thus worked out being rounded off to the nearest first place of decimal. That is if A.V. of premises is Rs.1300/-; then rate will be 1300/600=2.16%. Hence property tax percentage is 2.16+10=12.2% (rounded off). So gross tax of such property per year will be Rs.158.60/- plus Howrah bridge tax @ 0.5%. of AV. To get quarterly gross amount of tax this should be divided by 4 and rounded off to the nearest rupee. A further rebate of 5% of the quarterly tax is allowed if deposited in time. Net tax after rebate will also be rounded off to the nearest rupee")}.<br></br>
							● {t("If the Annual Valuation as fixed above exceeds Rs.18000/-, then the rate of tax is 40% of the Annual Value. That is if A.V. is Rs20000/- then the property tax per year is Rs.8000/- plus Howrah Bridge tax @ 0.5% of A.V. and a further rebate of 5% of the quarterly tax is allowed, if deposited in time")}.<br></br>
							● {t("In case of general flats/ appropriate units tax rate percentage should be calculated on a cumulative Annual Value of all the flats within that premises and should be applied on individual Annual Value for respective flats to get the amount of gross property tax")}.<br></br>
							● {t("For Bustee, and some statutory organizations the rate of tax is different. For Bustee the maximum tax rate is 18% and minimum tax rate is 15% on Annual Value")}.<br></br>
							● {t("For commercial/non-residential property, normally a surcharge @ not exceeding 50% of tax is applicable to such property or portion of such property under non residential use and is levied additionally")}.<br></br>
							● {t("No tax is payable if AV does not exceed Rs.300 (three hundred)")}.</p>
						
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading14">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse14" aria-expanded="true" aria-controls="collapse14">
                14. {t("What are tax rates for bustees")}?
                </button>
            </div>
            <div id="collapse14" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> {t("For Bustee, specified educational institutions, some statutory organizations the rate of tax is different. For instance, schools that meet each of the following criteria are exempt from property tax")},<br></br>
                    ● {t("The school must possesses own buildings")}<br></br>
                    ● {t("The schools Audited by qualified accountants are exempt from taxes")}<br></br>
                    ● {t("Teachers are remunerated by GoWB")}<br></br>
                    ● {t("For land, hut or building within a bustee improved under West Bengal Slum Areas (Improvement and Clearance) Act, 1972, tax rate is 18% while that for other bustees is 15% of Annual Value")}.
                </p>	
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading15">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse15" aria-expanded="true" aria-controls="collapse15">
                15. {t("How much time does it take to obtain a property valuation report")}?
                </button>
            </div>
            <div id="collapse15" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> {t("In general, the time taken for generating a property valuation report after assessee's name is mutated in Assessment Register is 7 working days")}.</p>	
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading16">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse16" aria-expanded="true" aria-controls="collapse16">
                16. {t("What can I do if there is excessive delay in my getting the valuation report inspite of providing all the documents as specified")}?
		        </button>
            </div>
            <div id="collapse16" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> {t("In the unlikely case of such an event, you are most welcome to approach concerned Assessor-Collector at telephone number given in the table below. You may also drop in an email to cm_mkt@kmcgov.in and we would do our best to assist you in the speedy redressal of your grievance")}.</p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading17">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse17" aria-expanded="true" aria-controls="collapse17">
                17. {t("What if I have objections to the Annual Valuation Report and/or corresponding property tax? How can I bring up my case for hearing? How much time is elapsed before a case comes up for hearing")}?
		        </button>
            </div>
            <div id="collapse17" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> {t("Each assessee is given liberty to appear before a Hearing Officer if he has any objection against the valuation proposed by KMC (Section 186 and 187 of KMC Act, 1980). Hearing Officer considers such objections (verbal/written) and fixes the Annual Valuation against the premises of the assessee as per provisions under section 188 of KMC Act, 1980. The assessee has a further chance to appeal against such valuation as determined under section 188. He can appeal under Section 189, before Municipal Assessment Tribunal that consists of a Chairman and other members not exceeding five in number. However, the said tribunal entertains no appeal if the tax on the disputed Annual valuation is not deposited. Provisions of Limitation Act, 1963 is applicable relating to such appeal before tribunal. Valuation as determined by the Tribunal is final and no proceeding shall lie in any Civil Court against the valuation as determined by The Tribunal")}.</p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading18">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse18" aria-expanded="true" aria-controls="collapse18">
                18. {t("What should I do incase I have a complaint against property valuers")}?
		        </button>
            </div>
            <div id="collapse18" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> {t("Please contact with concerned Assessor-Collector as per details in the table below")}.</p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading19">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse19" aria-expanded="true" aria-controls="collapse19">
                19. {t("What are the locations at which hearing is conducted")}?
		        </button>
            </div>
            <div id="collapse19" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> {t("Hearing is conducted at the addresses mentioned in the table below. Please contact ward office according to your location")}.</p>
            </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading20">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse20" aria-expanded="true" aria-controls="collapse20">
                20. {t("Will I need a lawyer to support my case for hearing")}?
		        </button>
            </div>
            <div id="collapse20" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> {t("Every effort is made to ensure that you receive full justice, in a fair and unbiased manner. However you are most welcome to bring in a lawyer with you if you feel more comfortable in doing so")}.</p>
            </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading21">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse21" aria-expanded="true" aria-controls="collapse21">
                21. {t("What is the General Revaluation process? How often it is carried out")}?
		        </button>
            </div>
            <div id="collapse21" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> {t("KMC inspectors visit your premises and propose a valuation. Generally, for a residential property the Annual Valuation increases by a minimum of ten percent if no addition and alteration in use and nature of the premises occur and for Non-residential premises in the increment is of minimum 20%. Normally, the general revaluation happens after a gap of 6 years")}.
                </p>
            </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading22">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse22" aria-expanded="true" aria-controls="collapse22">
                22. {t("Can I perform a self-valuation (self-assessment)? What is the procedure for the same? Can I carry out a self-revaluation?  What is the procedure")}?
		        </button>
            </div>
            <div id="collapse22" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass">{t("Self-Assessment is tentatively planned to be implemented from April 2011. However you should inform KMC in writing regarding any sale/purchase of property in the jurisdiction of KMC and as well as regarding any change in nature and use of the building")}.</p>
            </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading23">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse23" aria-expanded="true" aria-controls="collapse23">
                23. {t("What should I do if there is a change in use or occupier of my property such that there is a change in my income from the same")}?
		        </button>
            </div>
            <div id="collapse23" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> 	
                    {t("If there is a change in the use of property the following steps need to be taken")},<br></br>
                    ● {t("Assessee needs to fill in form (A-75) and submit to Assessor Collector. (A-75) can be purchased from the Central Records counter at the ground floor of KMC")}.<br></br>
                    ● {t("Within 2-3 working days of submission of form, an inspector is sent to concerned premises")}<br></br>
                    ● {t("Within 2-3 working days of submission of form, an inspector is sent to concerned premises")}<br></br>
                    ● {t("Inspector obtains clearance from Deputy Assessor Collector, Assessor Collector, DMC (Revenue) as applicable and arranges for hearing notice to be sent to assessee")}<br></br>
                    ● {t("Hearing officer will decide the appropriate annual valuation of the property based on comparable rates in the same location and paper work submitted by the assessment department and the assessee")}<br></br>
                    ● {t("If the assessee does not agree with the Annual Valuation after discussions with the hearing officer, he can further appeal against the valuation under section 188 of KMC Act, 1980 by appearing before Municipal Assessment Tribunal. (See question 6). However, the assessee has to pay bills per hearing officer prior to appearing before tribunal")}
                </p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading24">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse24" aria-expanded="true" aria-controls="collapse24">
                24. {t("What are Fresh/Supplementary (F/S) bills")}?
		        </button>
            </div>
            <div id="collapse24" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass">{t("Bills that are generated just after hearing are called as Fresh/Supplementary (F/S) bills. If it is first time assessment then the bill is fresh one and for revaluation hearing the bill is a supplementary bill (incase currently fixed Annual Value vis-à-vis tax is higher than earlier one)")}
			    </p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading25">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse25" aria-expanded="true" aria-controls="collapse25">
                25. {t("What is the user charge charged by KMC")}?
		        </button>
            </div>
            <div id="collapse25" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass">{t("The user charges are as given in Tax Rates/Fees")}.</p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading26">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse26" aria-expanded="true" aria-controls="collapse26">
                26. {t("Is there a reminder service for payment of outstanding tax demand? How often are these reminders sent")}?
		        </button>
            </div>
            <div id="collapse26" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass">{t("Yes, After every quarter-end such reminder is sent. All details of O/s is sent with yearly bills")}.</p>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading27">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse27" aria-expanded="true" aria-controls="collapse27">
                26. {t("Is there a reminder service for payment of outstanding tax demand? How often are these reminders sent")}?
		        </button>
            </div>
            <div id="collapse27" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass">{t("Up to Rs. 25,000/-, the payers will be entitled to make payment in cash, but above and beyond Rs. 25,000/- the payers will have to make the requisite payment by bank drafts or pay orders drawn in favour of 'KOLKATA MUNICIPAL CORPORATION'")}.</p>
				</div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading28">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse28" aria-expanded="true" aria-controls="collapse28">
                28. {t("What is the property tax payment schedule? Can I pay in monthly installments")}?
		        </button>
            </div>
            <div id="collapse28" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> {t("The property tax schedule is printed in the bills. Installments are generally quarterly")}.</p>
                 </div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading29">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse29" aria-expanded="true" aria-controls="collapse29">
                29. {t("Where can I deposit my outstanding property taxes")}?
		        </button>
            </div>
            <div id="collapse29" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> {t("You can deposit yearly property tax bills at any treasury counter, e-Kolkata citizen Service Centres of KMC as well as in stipulated banks as printed on the reverse of the bill. Fresh/Supplementary (F/S) bills are to be deposited in the Treasury Counters and e-Kolkata Citizen Service Centres")}.
				 </p></div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading30">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse30" aria-expanded="true" aria-controls="collapse30">
                30. {t("Can I pay my Preliminary Demand bills for future years")}?
		        </button>
            </div>
            <div id="collapse30" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> {t("Yes. Permission is to be taken from concerned Assessor-Collector")}.
				 </p></div>
            </div>
        </div>
        <div className="card">
            <div className="card-header" id="heading31">
                <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse31" aria-expanded="true" aria-controls="collapse31">
                31. {t("What happens if I am late in my payment")}?
		        </button>
            </div>
            <div id="collapse31" className="collapse show m-4" data-parent="#accordion">
                <div className="card-body m-3">
                <p className="fontSizeClass"> {t("We advise you to pay your bills on time. Late payment may lead to serious discomfort to you including and not limited to disconnection of water supply to your premises. The KMC Act, 1980 Act provides with following penal measures")} :<br></br>
				 {t("Sections 219-221B, 223, 225 and Section 275(aa) of Calcutta Municipal Act, 1980 deals the situation as mentioned above. Late payment can lead to")},<br></br>
					● {t("Loss of Rebate if property tax is not paid within 21 days of presentation of bill")}.<br></br>
					● {t("One time penalty of upto 15% of the amount of tax will be charged if tax is not paid within presentation quarter")}. <br></br>
					● {t("Monthly simple interest at 1% (where Annual Value is below 1 lakh) and 1.5% (where Annual value is 1 Lakh or more) will be charged commencing on the first day of the quarter following that in which the bill is presented and ending with the month preceding the month in which payment is made")}. <br></br>
					● {t("Issuance of Warrant of Distress and sale of movable property (Sections 220-221)")}<br></br>
					● {t("Attachment and sale of immovable property (Section 221A) and sale of property distrained or attached (section 221 B) may be initiated by KMC at defaulting premises")}.<br></br>
					● {t("Recovery under Bengal Act III, 1913 (Section 223)")} <br></br>
					● {t("Attachment of rent of the tenant(s) [Section 225]")} <br></br>
					● {t("Disconnection of water supply line (Section 275 [aa])")}<br></br>
				 </p></div>
            </div>
        </div>
        </div>
        </div>
      </div>
     </div>
   </section>
   </>
  )
}

export default Assessment_Collection_FAQ