import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const KMC_Training  = () => {
  const { t, i18n, ready } = useTranslation();
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const fileDownload = (fileStoreId) => {
    let url = `https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`;
    axios
      .post(url, { fileId: fileStoreId, userId: "392" })
      .then((res) => {
        console.log("response is --- ", res);

        if (res && res.data && res.status === 200) {
          const base64 = res.data[0] && res.data[0].base64_data;
          const pdfData = "data:application/pdf;base64," + base64;

          let html =
            "<html>" +
            "<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>" +
            "<body>" +
            '<iframe src="' +
            pdfData +
            '"></iframe>' +
            "</body></html>";
          console.log(html);
          let a = window.open();
          a.document.write(html);
        }
      })
      .catch((e) => {
        // setLoader(false);
        console.log("The error is ", e);
      });
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt="img" />
       
      </div>
    </section>
    {/* End Slider */}
    <section className="section inner-pad1">
      <div className="container " >
        <div className="row">
          <div className="col-lg-3">
          <div className="site-menu  ">
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/KMC_Recruitments'> {t("KMC Recruitments")} </Link>
                  </li>                
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/KMC_Results'> {t("KMC Results")} </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link active1 fontSizeClass" to='/KMC_Training'> {t("KMC Training")} </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2 bas">
                  <h3 className="fontSizeClass"> {t("KMC Training")} </h3>
                </div>
                <div className=" health-dept-table">
                  <table
                    width="95%"
                    border={0}
                    align="center"
                    cellPadding={2}
                    cellSpacing={2}
                    className="table table-bordered officials"
                  >
                    <tbody>
                      <tr
                        bgcolor="#302ba0"
                        tabIndex={0}
                        align="center"
                        valign="middle"
                      >
                        <td width="90%" className="text-white tble-brder-left">
                        {t("Description")}
                        </td>
                        <td width="10%" className="text-white">
                          {t("Date")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td >
                        <a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1762")}> {t("Training schedule at IUM for KMC Jr.Asst, SAE and AE")} </a>
                        </td>
                        <td >12.06.2019</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td width="90%">
                        <a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1763")}> {t("Communique from DG(IUM) for the KMC Employees who participated in the training program between 24.06.2019 and 29.06.2019")}	</a>
                        </td>
                        <td width="10%">15.07.2019</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      </>
  )
}

export default KMC_Training