import React from 'react'
import { Link } from "react-router-dom";
import axios from "axios";

const List_Of_Tenders = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const fileDownload = (fileStoreId) => {
    let url = `https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`;
    axios
      .post(url, { fileId: fileStoreId, userId: "392" })
      .then((res) => {
        console.log("response is --- ", res);

        if (res && res.data && res.status === 200) {
          const base64 = res.data[0] && res.data[0].base64_data;
          const pdfData = "data:application/pdf;base64," + base64;

          let html =
            "<html>" +
            "<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>" +
            "<body>" +
            '<iframe src="' +
            pdfData +
            '"></iframe>' +
            "</body></html>";
          console.log(html);
          let a = window.open();
          a.document.write(html);
        }
      })
      .catch((e) => {
        // setLoader(false);
        console.log("The error is ", e);
      });
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
      <section id="hero" className="hero hero2">
        <div
          className="social-media wow slideInDown"
          style={{ visibility: "visible", animationName: "slideInDown" }}
        >
          <div className="facebook" rel="ebInfo" title="facebook">
            <a
              href="https://www.facebook.com/KolkataMunicipalCorporation.official"
              target="_blank"
            >
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a
              href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp"
              target="_blank"
            >
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>

        <div className="carousel-item active">
          <img
            src="assets/img/Banner/portal-banner7.jpg"
            className="img-fluid"
            alt="image"
          />
          <div className="container">
            <div className="section-main-heading2">
              <h4 className="fontSizeClass"> Find Plumber Details </h4>
            </div>
          </div>
        </div>
      </section>
      {/* End Slider */}

    <section className="section inner-pad1">
        <div className="container ">
          <div className="row">
          <div className="col-lg-12">                   
          <div className="inner-title2">
              <h3 className="fontSizeClass">{("List Of Tenders")} </h3>
          </div>
          <div className="mayor-table depart Tender_batra">
            <table className="table table-bordered">
              <thead>
                <tr className="table-warning table-bor-2">
                  <th scope="col">                   
                    {("Tender")}
                  </th>
                  <th scope="col"> {("Tender Uploaded On")} </th>
                  <th scope="col"> {("Action")} </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row"> {("MISCELLANEOUS URGENT REPAIRING OF LIGHTING SYSTEM ARRANGEMENT AND OTHER ALLIED WORKS AT VARIOUS PLACES IN WARD NO:47")} </td>
                  <td> 07/08/2024 </td>
                  <td>
                      <a className="fontSizeClass" href='' target="_blank"> {("Download")} </a>  
                  </td>
                </tr>
                <tr>
                  <td scope="row"> {("CLEANING OF WATER BODY AT 47 NARKELDANGA MAIN ROAD IN WARD NO-033 FOR SECURING COMPLIANCE OF NOTICE U/S 496A")} 1866(09/12/2022) </td>
                  <td> 06/08/2024 </td>
                  <td>
                  <a className="fontSizeClass" href='' target="_blank"> 
                  {("Download")} </a>   
                  </td>
                </tr>
                <tr>
                  <td scope="row"> {("REPAIRING OF DAMAGED 52 NOS. G.I. HAND CARTS, IN WARD NO.-21 & 22 UNDER A.D./SWM-I/BR.-IV.")} </td>
                  <td> 03/08/2024 </td>
                  <td>
                  <a className="fontSizeClass" href='' target="_blank"> 
                  {("Download")} </a>   
                  </td>
                </tr>
                <tr>
                  <td scope="row"> {("SUPPLYING OF SPARES WITH FITTING AND FIXING FOR DAMAGED 40 NOS. TRI-CYCLE PADDLE VAN IN WARDS NO.-68,69 & 90 UNDER A.D./SWM-I/BR.-VIII.")} </td>
                  <td> 03/08/2024 </td>
                  <td>
                  <a className="fontSizeClass" href='' target="_blank"> 
                  {("Download")} </a>   
                  </td>
                </tr>
                <tr>
                  <td scope="row"> {("SUPPLYING OF SPARES WITH FITTING AND FIXING FOR DAMAGED 45 NOS. TRI-CYCLE PADDLE VAN IN ALL WARDS UNDER A.D./SWM-I/BR.-VII.")} </td>
                  <td> 03/08/2024 </td>
                  <td>
                  <a className="fontSizeClass" href='' target="_blank"> 
                  {("Download")} </a>   
                  </td>
                </tr>
                <tr>
                  <td scope="row"> {("SUPPLYING OF SPARES WITH FITTING AND FIXING FOR DAMAGED 44 NOS. TRI-CYCLE PADDLE VAN IN ALL WARDS, UNDER A.D./SWM-I/BR.-VI")} </td>
                  <td> 03/08/2024 </td>
                  <td>
                  <a className="fontSizeClass" href='' target="_blank"> 
                  {("Download")} </a>   
                  </td>
                </tr>
                <tr>
                  <td scope="row"> {("SUPPLYING OF SPARES WITH FITTING AND FIXING FOR DAMAGED 42 NOS. TRI-CYCLE PADDLE VAN IN WARDS NO.-41,43,44 UNDER A.D./SWM-I/BR.-V")} </td>
                  <td> 03/08/2024 </td>
                  <td>
                  <a className="fontSizeClass" href='' target="_blank"> 
                  {("Download")} </a>   
                  </td>
                </tr>
                <tr>
                  <td scope="row"> {("REPAIRING OF DAMAGED 60 NOS. G.I. HAND CARTS, IN WARDS NO.-21,22,24,25,26,28,38 & 39 UNDER A.D./SWM-I/BR.-IV")} </td>
                  <td> 03/08/2024 </td>
                  <td>
                  <a className="fontSizeClass" href='' target="_blank"> 
                  {("Download")} </a>   
                  </td>
                </tr>
                <tr>
                  <td scope="row"> {("SUPPLYING OF SPARES WITH FITTING AND FIXING FOR DAMAGED 40 NOS. TRI-CYCLE PADDLE VAN IN WARDS NO.-21,22,24,25,26,28,38 & 39 UNDER A.D./SWM-I/BR.-IV")} </td>
                  <td> 03/08/2024 </td>
                  <td>
                  <a className="fontSizeClass" href='' target="_blank"> 
                  {("Download")} </a>   
                  </td>
                </tr>
                <tr>
                  <td scope="row"> {("SUPPLYING OF SPARES WITH FITTING AND FIXING FOR DAMAGED 40 NOS. TRI-CYCLE PADDLE VAN IN WARDS NO.-1,3,5 & 6 UNDER A.D./SWM-I/BR.-I.")} </td>
                  <td> 03/08/2024 </td>
                  <td>
                  <a className="fontSizeClass" href='' target="_blank"> 
                  {("Download")} </a>   
                  </td>
                </tr>
                <tr>
                  <td scope="row"> {("REPAIRING & REPLACEMENT OF OLD & DEFECTIVE ELECTRICAL ACCESSORIES AT ROXY BUILDING UNDER BR-VI KMC LTG/16/INST/46/VI/Z-II/24-25")} </td>
                  <td> 03/08/2024 </td>
                  <td>
                  <a className="fontSizeClass" href='' target="_blank"> 
                  {("Download")} </a>   
                  </td>
                </tr>
                <tr>
                  <td scope="row"> {("ELECTRIFICATION WORKS REQUIRED FOR 3 NOS KMCP SCHOOLS AT 33 GARDENER LANE, 49/1 DURGA CHARAN DOCTOR LANE & 35 CANTOPHER LANE UNDER BR-VI, KMC")} </td>
                  <td> 03/08/2024 </td>
                  <td>
                  <a className="fontSizeClass" href='' target="_blank"> 
                  {("Download")} </a>   
                  </td>
                </tr>
                <tr>
                  <td scope="row"> {("Urgent repairing ,re-winding including mechanicql seal changing with qllied Works of one submersible pump unit no.4 at LS-2 (Thakery Road) DPS under PBPS(Drg.)/S&D")} </td>
                  <td> 03/08/2024 </td>
                  <td>
                  <a className="fontSizeClass" href='' target="_blank"> 
                  {("Download")} </a>   
                  </td>
                </tr>

                
              </tbody>
            </table>
          </div>

          

          </div>           
        </div>
      </div>
    </section>
    </>
  );
}

export default List_Of_Tenders