import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Map = () => {
  const [MoreOneService, setMoreOneService] = React.useState(false);
  const [MoreTwoService, setMoreTwoService] = React.useState(false);
  const [MoreThreeService, setMoreThreeService] = React.useState(false);

  const MoreOneServiceToggle = () => {
    setMoreOneService(!MoreOneService);
  };
  const MoreTwoServiceToggle = () => {
    setMoreTwoService(!MoreTwoService);
  };
  const MoreThreeServiceToggle = () => {
    setMoreThreeService(!MoreThreeService);
  };
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const [ProjectService, setProjectService] = React.useState(false);

  const ProjectServiceToggle = () => {
    setProjectService(!ProjectService);
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
         {/* Slider */}
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"> <h4 className="fontSizeClass"> {t("MAPS")} </h4></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
 
  <section className="section inner-pad1">
  <div className="container " >
    <div className="row">
      <div className="col-lg-3">
      <div className="site-menu  ">           
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/About_Kolkata'> {t("About Kolkata")} </Link> </li>
                  <li className="nav-item"><Link className="nav-link active1" to='/map'> {t("Map")} </Link></li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/History_Of_Kolkata'> {t("History")}</Link></li>
                  <li className="nav-item">
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Places of Interest")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo2" className="collapse hide">                        
                            <Link className="nav-link fontSizeClass" to='/Art_Galleries'>1. {t("Art Galleries")}</Link>
                            <Link className="nav-link fontSizeClass" to='/Amusement_Parks'>2. {t("Amusement Park")} </Link>
                            <Link className="nav-link fontSizeClass" to='/Architecture'>3. {t("Architecture")} </Link>
                            <Link className="nav-link fontSizeClass" to='/Other_Noteable_place'>4. {t("Other Notable Places")} </Link>  
                      </div>
                  </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Basic_statestics'> {t("Basic Statistics")} </Link> </li>  
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/UEVRP_Home'> {t("UEVRP")} </Link></li>   
                </ul>
              </nav>	   
            </div>
      </div>
      <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3> {t("Maps")} </h3>
            </div>
            <div className="col-lg-12 about-head1">
              <div className="position-relative map2">
                <a href="assets/img/Base-Map-of-Kolkata-Metropolitan-Area.png" className="glightbox">
                  {t("Map of Kolkata Metropolitan Area")}
                </a>
              </div>
              <div className="position-relative map2">
                <a
                  href="assets/img/902px-KMC_Ward_Map_(Bengali).jpg"
                  className="glightbox"
                >
                  
                  {t("Wards of Kolkata Municipal Corporation")}
                </a>
              </div>
              <div className="position-relative map2">
                <a
                  href="assets/img/KOLKATA_RD_big_A0_JOKA_PCE_mayor.pdf"
                  className="glightbox"
                >
                  
                  {t("City Map Of Kolkata")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default Map