import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const HeritageBuildingHome  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                <Link className="nav-link active1 fontSizeClass" to='/HeritageBuildingHome'> {t("Heritage Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Exemption_of_Tax'> {t("Exemption of Tax")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Heritage_Buildings'> {t("Heritage Buildings")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Conservation_Architect'> {t("Conservation Architect")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('473')}>{t("Composition of the Heritage Conservation Committee")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Proceedings_of_HCC'>
                    
                    {t("Proceedings of HCC")}
                  </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('474')}>{t("Action Plan")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('475')}>{t("Parking Policy")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('476')}>{t("Measures for prevention of landfill fire")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWisePond.jsp'>  {t("Ward wise Pond")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWiseHeritageBuilding.jsp'> {t("Ward Wise Heritage Building")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Environment_Heritage_Dept'> {t("Contact Details")} </Link>
                </li>

                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Activities_NCAP.pdf' target={'_blank'}> {t("Activities Permitted under NCAP")} </a>
                </li>

                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Swachh Vayu Survekshan")} <i className="bi bi-chevron-down" /></button>
                    <div id="demo2" className="collapse">
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessed_CPCB_scores_23_09_2023.xlsx'>1. {t("Self assessed scores & CPCB scores")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessment_reports_Kolkatacity_23_09_2023.pdf'>2. {t("Self assessment reports of Kolkata city2023")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Swachh_Vayu_Survekshan_23_09_2023.pdf'>3. {t("Swachh Vayu Survekshan 2023 Rank")} </a>
                    </div>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/airquality/citizenreport.do' target={'_blank'}> {t("Air quality status")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/emis/category.do' target={'_blank'}> {t(" Industry categorisation")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/District_Environment_ KMC2021_23_09_2023.pdf' target={'_blank'}> {t("FINAL District Environment plan KMC 2021")} </a>
                </li>

                 
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2" >
                <h3 className="fontSizeClass">{t("Heritage Buildings of Kolkata")} </h3>
              </div>
              <div className="about-head1">
                  
                    <div className="log-form inner-log-form">
                      <h4 className="fontSizeClass"> Guidelines For The Owners Of Heritage Buildings </h4>                     
                      <div className="row">
                        <div className="col-lg-12 col-md-12 form-group">
                          <div className="list-of-forms">
                            <h5 className="fontSizeClass"> <strong> Introduction: </strong> </h5>
                            <span>...
                             <Link className="fontSizeClass" to='/HeritageBuildingHome'>  {t("1")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome2'> {t("2")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome3'> {t("3")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome4'> {t("4")} </Link>
                            </span>
                            <p>Buildings with architectural significance and historical values are considered for their preservation and conservation. These buildings playing an
                                 important role in the history of the city of Kolkata in particular and maintained properly. 
                                 For this purpose it has been realised that an inventory of such buildings upon which the heritage 
                                 status has been conferred is to be undertaken. Through various process and documentation stages in 
                                 assistance with the Expert Committee formed by the Government of West Bengal, a list of such heritage 
                                 buildings has been prepared and being up-dated regularly. The Corporation with the recommendation of 
                                 the Heritage Conservation Committee has prepared a Graded List of Heritage Buildings for Grade-I,
                                  Grade-IIA & Grade-IIB which is available in this website.
                            </p>
                            <h5> (i) What is Heritage Building ? </h5>
                            <p> Under the provisions of Section 2 (42A) of the K.M.C. Act 1980 the definition of heritage 
                                building has been given. The definition runs thus "heritage building means any building of 
                                one or more premises, or any part thereof, which requires preservation and conservation for
                                 historical, architectural, environmental or ecological purpose and includes such portion
                                  of the land adjoining such building or any part thereof as may be required for fencing 
                                  or covering or otherwise preserving such building and also includes the areas and 
                                  buildings requiring preservation and conservation for the purpose as aforesaid under 
                                  sub-clause (ii) of clause (a) of subsection (4) of the section 31 of the West Bengal 
                                  Town and Country (Planning and Development) Act, 1979 (West Beng. Act XIII of 1979)". 
                                </p>
                            <h5>(ii) What is Heritage Precinct?</h5>
                                <p> Heritage Precinct may be defined as the neighbourhood or environs of a place or a group of 
                                buildings that share wholly or partly certain common physical, social, cultural significance 
                                worth preservation and conservation. Ambience can be defined as the quantitative, qualitative 
                                aspects attached to heritage building necessary for its appreciation of the space and viewing 
                                corridors. 
                                </p>
                            <h5>(iii) How Conservation of Heritage Building is explained ? </h5>
                            <p>
                              Conservation may be defined as the process involved in the preservation of a building, 
                              precinct or artifact, in order to retain its architectural, historical, environmental or 
                              cultural significance. This term includes maintenance and according to necessity, may require 
                              preservation, restoration or reconstruction and may commonly be a combination of more than one
                               of the above.</p>
                              <h5> (iv) How Preservation of Heritage Building is explained ? </h5>
                              <p> Preservation may be defined as the maintenance necessary in order to maintain the building 
                               precinct or artifact in its present state to prevent and retard further deterioration.</p>
                             <h5>(v) How Restoration of Heritage Building is explained ? </h5> 
                             <p>Restoration may be defined as the means necessary to return the fabric to its known earlier sate 
                                (as may have existed prior to change that may have occurred by way of demolition, removal, addition etc.
                                 in contravention of the provision of the Act, the rules and regulations framed therein) by means of 
                                 the removal of accretions or reassembling existing components or by the sensitive introduction of
                                 compatible materials.</p>
                            <h5>(vi) What is the responsibility of the owner ? </h5>
                            <p> Every owner or occupier of any heritage building declared as such by the Corporation shall 
                                maintain, preserve and conserve it and shall not change its use in contravention of the 
                                provisions of this Act or the rules or the regulations made there under for its maintenance,
                                preservation or conservation. Elements of the building, which are not compatible with the
                              whole or part of the building, should be removed with the approval of Heritage Conservation 
                              Committee.
                            </p> 
                            <h5> Standard Guidelines : </h5>
                            <h5> (a) Selling or leasing out of the Heritage Property. </h5>
                            <p> No permission is required for the buildings which are already declared as heritage 
                                building (Refer to Graded list of heritage buildings). However the owner should mention the heritage status
                             of the building in registered document during such transfer of property. The owner should apply along 
                             with the intending buyer jointly to KMC prior to transfer of any property for the buildings which are 
                             under considerations for declaring as heritage building for permission (Refer to the list of heritage 
                             buildings under consideration). 
                            </p>
                        <h5>(b) Undertaking new construction and/or addition/alteration within the Heritage Property .</h5>
                        <p>On prior approval of the Heritage Conservation Committee new construction in a heritage property may be undertaken on fulfillment of the following conditions :</p>
                          <p> (i) Grade Table :   </p>  
                        <div className='mayor-table2 mayor-table my-2'>
                        <table className="table table-bordered kmc-department">
                            <thead>
                              <tr className='table-warning table-bor-2'>
                                 <th className='bd-wid'> Grade </th>
                                 <th> Sub-Grade </th>
                                 <th>Permissible intervention of the heritage premises</th>
                              </tr>
                            </thead>
                            <tbody>                            
                            <tr>
                             <td>1</td>
                             <td>  </td>
                             <td> No external change will be permissible. Use of the building should also 
                                be compatible with the category of the heritage building
                             </td>
                            </tr>

                            <tr rowspan="2">
                                <td>II</td>
                                <td>A</td>
                                <td>Same as Grade-I for the heritage building. But new construction may be allowed in the open 
                                    land within the premises in compatible manner with the heritage building. In no case new 
                                    construction should obstruct the view of the heritage building.
                                </td>                                
                            </tr>

                            <tr>
                            <td> </td>
                            <td>B</td>
                            <td>Horizontal and vertical addition and alteration of the building may 
                                be allowed in compatible with the heritage building</td>
                            </tr>
                            <tr>
                                <td>III</td>
                                <td> </td>
                                <td> A plaque depicting the history of the building should be provided. 
                                    Where the structure is having no architectural importance, demolition of the structure may 
                                    be allowed.
                                </td>
                            </tr>
                            <tr><td colSpan={3}>Note :- The HCC would be the final arbiter of the case-to-case examination of the compatibility issue.</td></tr>
                         </tbody>
                         </table>
                        </div>
                        <h5> (ii) If surplus buildable land is available. </h5>
                        <h5> (iii) The construction shall have to be compatible with the existing heritage building. </h5>
                        <h5> (iv) The drawings and designs for such construction shall be prepared and recommended by the enlisted Conservation Architect (Annex 'A'). </h5>
                        <h5>(v) Addition/alteration proposal shall be in accordance with the prevailing KMC Building Rules.</h5>
                        <h5> (c) Demolition of Heritage Building. </h5>
                        <p>Normally no heritage building shall be permitted for demolition. However, on prior approval 
                            of the Heritage Conservation Committee on the recommendation of the enlisted Conservation 
                            Architect the non-significant portion of a heritage building may be allowed to be 
                        demolished.</p>
                        <h5>(d) Permission for repairing</h5>
                        <p>To repair any heritage building a prior permission of the Heritage Conservation 
                            Committee is required for :</p>
                        <h5>(i) Restoration of façade, structure and interior.</h5>
                        <h5>(ii) Restoration of architectural elements/features.</h5>
                        <h5>(iii) Reconstruction of the portion which has been collapsed.</h5>
                        <h5>(iv) Reconstruction of the irreparable portion of the building to match with the existing characteristics.</h5>
                         
                         </div>
                         <span>...
                             <Link className="fontSizeClass" to='/HeritageBuildingHome'>  {t("1")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome2'> {t("2")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome3'> {t("3")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome4'> {t("4")} </Link>
                            </span>
                        </div>
                        
                      </div>
                    </div>
                  
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default HeritageBuildingHome