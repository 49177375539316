import { React, useState } from "react";
import List from "./List.js";
import { Link } from "react-router-dom";


function SearchBar() {
  const [inputText, setInputText] = useState("");

  const [show, toggleShow] = useState(false);

  let inputHandler = (e) => {
    var lowerCase = e.target.value;
    setInputText(lowerCase);
  };

 
  return (
    <div className="main">
      <div className="search">
        <input type="text"
          id="outlined-basic"
          onChange={inputHandler}
          onKeyUp={() => toggleShow(!show)}
          variant="outlined"
          fullWidth
          label="Search" placeholder="Search" autoComplete="off"
        />
      </div>
      {show && <div className="searchResult"><List input={inputText}/></div>}
    </div>
  );
}

export default SearchBar;