import Propery from './property';

const baseUrl = window.location.origin;
console.log("baseUrl ", baseUrl)
const isLocalhost = baseUrl.includes('localhost');
const isDEV = baseUrl.includes('kmcdev');

const apiBaseUrl = () => {
    console.log("isLocalhost----", isLocalhost)
    if(isLocalhost)
        //return 'http://localhost:5001';
        return 'https://kmcdev.ddns.net';

    else 
        return baseUrl;
        
}

const getOriginVal = () => {
    if(isLocalhost)
        return 'LOCAL';
    else if(isDEV)
        return 'DEV';
}

const mapToOrigin = async (valueToFetch) => {
    // match origin value with the propety value.
    let propertyKeys = Object.keys(Propery);
    let originVal = getOriginVal();
    let value = '';
    propertyKeys.forEach(function(key) { //loop through propertyKeys array
        if(key === originVal) 
            value = Propery[key][valueToFetch];
    });
    return value;
}

export { apiBaseUrl, getOriginVal, mapToOrigin };

