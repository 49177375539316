import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Lighting_Home  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />      
    </div>
  </section>

  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu">
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Lighting_Home'> {t("Lighting Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Lighting_Fees'> {t("Fees")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('667')}>{t("Urban Beautification Decorative Ornamental Street Light")}</a>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Lighting_Dept'> {t("Contact Details")} </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass"> {t("Dept.of Lighting")} </h3>
            </div>
            <div className="col-lg-12 about-head1">
            <div id="accordion">
                    <div className="card">
                      <div className="card-header" id="heading1">
                      <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                      {t("1. What are the key activities of the department?")}
                      </button>
                      </div>
                      <div id="collapse1" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                        <p className="fontSizeClass">
                        {t("The key activities of the Lighting Department include:")}
                        <br />
                        {t("a. Installation and maintenance of street lights under KMC")} <br />
                        {t("b. Routine and emergency maintenance of street lights")}
                        <br />
                        {t("c. Installation and maintenance of lights in gardens and parks")} <br />
                        {t("d. Installation and proper functioning of electric crematoriums")} <br />
                        {t("e. Installation and maintenance of institutional lighting such as schools, ward offices etc")}
                      </p>                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading2">
                      <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                      {t("2. Who should I contact if streetlights in my area are lit in the day or not lit at night?")}
                      </button>
                      </div>
                      <div id="collapse2" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                        <p className="fontSizeClass">
                        {t("KMC encourages responsible citizens to inform the lighting department in case streetlights are lit during daytime or are not lit in the night. Please refer to Annex A for a list of contacts in case streetlights are not lit as per schedule. You may also contact the Control Room at KMC headquarters at (033) 2286 1212 or (033) 2286 1313 or (033) 2286 1414.")}
                      </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading3">
                      <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                      {t("3. My area does not have any streetlights. Whom do I contact for help on the matter?")}
                      </button>
                      </div>
                      <div id="collapse3" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                        <p className="fontSizeClass">{t("Please refer to Table below for contact details.")}</p>
                      <div className="mayor-table">
                        <table className="table table-bordered">
                          <thead>
                            <tr className="table-warning table-bor-2">
                              <th scope="col"> {t("Zone No")} </th>
                              <th scope="col"> {t("Borough")} </th>
                              <th scope="col"> {t("Ward")} </th>
                              <th scope="col"> {t("Authorized Personnel ")}</th>
                              <th scope="col"> {t("Address")} </th>
                              <th scope="col"> {t("Phone Number")} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row"> 1 </td>
                              <td> 1,2,3 </td>
                              <td> {t("1 to 20, 29 to 35 ")}</td>
                              <td> {t("Executive Engineer (Lighting)")} </td>
                              <td> {t("79, Bidhan Sarani,Kolkata 700006")} </td>
                              <td> 25302513 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 2 </td>
                              <td> 4,5,6 </td>
                              <td> {t("21 to 28, 36 to 55, 60 to 62 ")}</td>
                              <td> {t("Executive Engineer (Lighting)")} </td>
                              <td>
                                
                                {t("15N, Neli Sengupta Sarani, Kolkata-700087")}
                              </td>
                              <td> {t("22861000 Extension: 2541")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 3 </td>
                              <td> 7,8,9 </td>
                              <td> {t("56 to 59, 63 to 80, 82 to 88, 90")} </td>
                              <td> {t("Executive Engineer (Lighting)")} </td>
                              <td>
                                
                                {t("3rd Floor,28 A.K.N Sen Road.e-Governance Building,Kolkata-700042")}
                              </td>
                              <td> &nbsp; </td>
                            </tr>
                            <tr>
                              <td scope="row"> 4 </td>
                              <td> 10,11,12 </td>
                              <td> {t("81, 89, 91 to 114")} </td>
                              <td> {t("Executive Engineer (Lighting)")} </td>
                              <td>
                                
                                {t("2Santoshpur Municipal Market(1st Floor),Kolkata 700075")}
                              </td>
                              <td> 24165409 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 5 </td>
                              <td> 13,14,15 </td>
                              <td> {t("115 to 122 and 127 to 141")} </td>
                              <td> {t("Executive Engineer (Lighting)")} </td>
                              <td>
                                
                                {t("Parnashreepally Government Quarter Block 14,Kolkata 700060")}
                              </td>
                              <td> 24077585 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 6 </td>
                              <td> 16 </td>
                              <td> 123,124, 125, 126,142,143,144 </td>
                              <td> {t("Executive Engineer (Lighting)")} </td>
                              <td> {t("4,DIAMOND PARK,JOKA,KOKATA- 700104")} </td>
                              <td> 9830391678 </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading4">
                      <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                      {t("4. What can I do if I notice pilferage of property/electricity of the lighting department?")}
                      </button>
                      </div>
                      <div id="collapse4" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                        <p className="fontSizeClass">
                        {t("The lighting equipment is used to provide civic amenities to the citizens of Kolkata. People who are involved in pilfering lighting equipment are committing a crime against the city. We strongly encourage responsible citizens to inform us in case they notice such activity. Your name and particulars will kept absolutely confidential. You may inform concerned Executive Engineer as per Annex A")}.
                      </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading4">
                      <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                      {t("5. Who do I contact if streetlight poles are damaged or have loose wires or there is a risk of electrocution?")}
                      </button>
                      </div>
                      <div id="collapse4" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                        <p className="fontSizeClass">
                        
                        {("In case of such events, please contact the lighting department immediately. Please refer to Annex A for contact details.")}
                      </p>
                      <div className="mayor-table">
                        <table className="table table-bordered">
                          <thead>
                            <tr className="table-warning table-bor-2">
                              <th scope="col"> {t("Zone No ")}</th>
                              <th scope="col"> {t("Borough")} </th>
                              <th scope="col"> {t("Ward")} </th>
                              <th scope="col"> {t("Authorized Personnel")} </th>
                              <th scope="col"> {t("Address")} </th>
                              <th scope="col"> {t("Phone Number")} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row"> 1 </td>
                              <td> 1,2,3 </td>
                              <td> {t("1 to 20, 29 to 35")} </td>
                              <td> {t("Executive Engineer (Lighting)")} </td>
                              <td> {t("79, Bidhan Sarani,Kolkata 700006")} </td>
                              <td> 25302513 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 2 </td>
                              <td> 4,5,6 </td>
                              <td> {t("21 to 28, 36 to 55, 60 to 62")} </td>
                              <td> {t("Executive Engineer (Lighting)")} </td>
                              <td>
                                
                                {t("15N, Neli Sengupta Sarani, Kolkata-700087")}
                              </td>
                              <td> {t("22861000 Extension: 2541")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 3 </td>
                              <td> 7,8,9 </td>
                              <td> {t("56 to 59, 63 to 80, 82 to 88, 90")} </td>
                              <td> {t("Executive Engineer (Lighting)")} </td>
                              <td>
                                
                                {t("3rd Floor,28 A.K.N Sen Road.e-Governance Building,Kolkata-700042")}
                              </td>
                              <td> &nbsp; </td>
                            </tr>
                            <tr>
                              <td scope="row"> 4 </td>
                              <td> 10,11,12 </td>
                              <td> {t("81, 89, 91 to 114")} </td>
                              <td> {t("Executive Engineer (Lighting)")} </td>
                              <td>
                                
                                {t("2Santoshpur Municipal Market(1st Floor),Kolkata 700075")}
                              </td>
                              <td> 24165409 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 5 </td>
                              <td> 13,14,15 </td>
                              <td> {t("115 to 122 and 127 to 141")} </td>
                              <td> {t("Executive Engineer (Lighting)")} </td>
                              <td>
                                
                                {t("Parnashreepally Government Quarter Block 14,Kolkata 700060")}
                              </td>
                              <td> 24077585 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 6 </td>
                              <td> 16 </td>
                              <td> 123,124, 125, 126,142,143,144 </td>
                              <td> {t("Executive Engineer (Lighting)")} </td>
                              <td> {t("4,DIAMOND PARK,JOKA,KOKATA- 700104")} </td>
                              <td> 9830391678 </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Lighting_Home