import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Project_Faqs  = () => {
  const { t, i18n, ready } = useTranslation();
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
<>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  ">           
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/CBP_Home'> {t("CBP Home")} </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Vision_Strategy'> {t("Vision And Strategy")} </Link></li>
                  <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Project_Faqs'> {t("FAQs")}</Link></li>
                </ul>
              </nav>	   
            </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title"><h3 className="fontSizeClass">{t("Capacity Building Programme (CBP) :: FAQs")}</h3>
              </div>
                  <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="heading1">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                    1. {t("What is the Capacity Building Programme")}? 
                    </button>
                    </div>
                    <div id="collapse1" className="collapse show" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      {t("The Kolkata Environmental Improvement Project (KEIP), funded by the Asian Development Bank, included as one of its five components, a Capacity Building Programme (CBP) for the Kolkata Municipal Corporation. The CBP is aimed at improving the overall efficiency of KMC, ultimately leading to sustainable and equitable provision of urban services in the city. The Department for International Development (DFID), Government of U.K, agreed to provide £28.3m million over a six-year period to implement the CBP")}.                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading2">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                      2. {t("Why build Capacity")}?                    </button>
                    </div>
                    <div id="collapse2" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      {t("To enhance the ability to ‘manage the delivery of urban services to the citizens of Kolkata in a sustainable & equitable manner’")}
                      </div>                      
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading3">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                      3. {t("What was the need to build capacity")}?
                    </button>
                    </div>
                    <div id="collapse3" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <strong>a.{t("High level of Complexity")}:</strong> {t("KMC, with a total area of 187 sq. kms and housing a population of 4.6 million is the largest urban local body in West Bengal and also one of the largest in India")}.
                      <strong>b.{t("Historical Background")}:</strong> {t("The city had witnessed a major degradation of its environment since independence in 1947, when a major influx of refugees from Bangladesh threw the city services completely out of gear. The city has since then found it difficult to keep pace with the increasing population and its demand for services. Today Kolkata suffers from severe environmental problems with 26 - 30% of households in the KMC area living in Bustees, and a significant proportion living below the poverty line")}.
                      <strong>c.{t("Kolkata environment improvement programme (KEIP)")}:</strong> {t("Under this project major infrastructure investments are being done in roads, sewerage & drainage, water supply and solid waste management. In order to sustain and continue the initiatives undertaken by the KEIP team, a need for capacity building within KMC was envisaged")}.
                      <strong>d.{t("Low Financial Strength")}:</strong> {t("Due to its low financial capacity, KMC found it increasingly difficult to allocate sufficient resources for improved service delivery; and pro-poor expenditure was one of the first areas to be adversely affected")}.
                      <strong>{t("Capacity building and sustainability was therefore not a choice for KMC and rather an imperative for regeneration & an improved quality of life for its citizens")}. </strong>                     </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                      4. {t("What are its contents? Who are the partners")}?
                    </button>
                    </div>
                    <div id="collapse4" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <div className="health-dept-table" style={{width:"100%"}}>
                    <table
                        width="95%"
                        border={0}
                        align="center"
                        cellPadding={2}
                        cellSpacing={2}
                        className="table table-bordered officials" >
                        <tbody>
                        <tr
                            bgcolor="#302ba0"
                            tabIndex={0}
                            align="center"
                            valign="middle">
                            <td width="15%" className="text-white tble-brder-left">
                            {t("Sl.No")}.		
                            </td>
                            <td width="40%" className="text-white">
                            {t("Modules")}
                            </td>
                            <td width="45%" className="text-white tble-brder-right">
                            {t("Partners")}
                            </td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            {t("D.M.C.(P)'S CIRCULAR NO./17/2004-2005")}</td>
                            <td align="center">{t("Organisational Development")}</td>
                            <td align="center">{t("A.F.Ferguson (AFF)")} </td>
                        </tr> 
                        <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">CBP002</td>
                            <td align="center">{t("Computerisation")}</td>
                            <td align="center">{t("Tata Consultancy Services (TCS)")}</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">CBP003                        </td>
                            <td align="center">{t("Public Relation & Communication")}	</td>
                            <td align="center">.</td>
                        </tr>                      
                        <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">CBP005 </td>
                            <td align="center">{t("Program Management")}<br />
                            {t("Implementation")}<br/>{t("Change Management and Governance")}<br/>
                            {t("Social Inclusion")}<br/>{t("Urban Planning")}</td>
                            <td align="center"></td>
                        </tr>                    
                        <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">CBP006</td>
                            <td align="center">{t("Resource Mobilisation and Improved Financial Management")}</td>
                            <td align="center">{t("ICAI-Accounting Research Foundation (ICAI-ARF)")}</td>
                        </tr>
                        </tbody>
                    </table>
                        </div>
                      </div>
                    </div>
                  <div className="card">
                    <div className="card-header" id="heading5">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                      5. {t("Who is the implementing Agency")}?
                    </button>
                    </div>
                    <div id="collapse5" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      {t("A core team has been formed within KMC, which is called the Capacity Building Cell. Nodal officers have been nominated for all the above modules. The CB Cell is headed by the Capacity Building Programme (CBP) Manager")}.
                       </div>
                      </div>
                    </div>
                  <div className="card">
                    <div className="card-header" id="heading6">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                      6. {t("What are the expected Benefits of this programme")}?
                    </button>
                    </div>
                    <div id="collapse6" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      {t("The CBP by the end of the project (6 years) is expected to result in")} -
                        <ul>
                            <li>{t("Social benefits which would include improved access to better services, greater participation in urban planning and service delivery, reduced corruption, greater access to information and a more equitable tax regime")}.</li>
                            <li>{t("Environmental benefits which would include lower health risks, improved quality of water, improved air and lower noise pollution, better sanitation and reduced solid waste on the streets")}.</li>
                            <li>{t("Institutional improvement with effective decentralization, greater inclusion of the poor, better utilization of human resources, improved efficiency and image building")}.</li>
                            <li>{t("Financially reduced dependence on state government funds, increase in the own revenues of KMC, access to external funding sources and development of strategic partnerships with the private sector for cost-effective service delivery")}.</li>
                        </ul>
                        <strong align="center">{t("Repeating history – 'What West Bengal does today, India thinks tomorrow'")}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
    {/*   */}
  </section>
  
    </>
  )
}

export default Project_Faqs