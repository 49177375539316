import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Amusement = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const [onlinePayment, setonlinePayment] = React.useState(false);
  const onlinePaymentToggle = () => {
    setonlinePayment(!onlinePayment);
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
 
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">           
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Amusement'> {t("Amusement Home")} </Link> </li>
                <li className="nav-item"><a class="nav-link" onClick={(e)=>fileDownload('285')}> {t("Amusement Fees")} </a> </li>                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Amusement_download_forms'> {t("Download Forms")} </Link> </li>  
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AmusementDemandsView.jsp'> {t("Check Demands Payable")} </a> </li>
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AmusementDemandsView.jsp'> {t("Print Unpaid Bill")}</a> </li>  
                <li className="nav-item">
                <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Make Online Payment")} <i className="bi bi-chevron-down" /></button>
                    <div id="demo" className="collapse">                        
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/KMCPortalAmusementPaymentAction.do'>{t("Amusement Fees (Renew/Casual/New)")} </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'>{t("Casual Demand Generation and payment")}</a>   
                    </div>
                </li>
                			
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AmusementDuplicateReceipt.jsp'> {t("Reprint e-Receipt")}</a> </li>
                {/* <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Citizenlogin'> Reprint Puja Application e-Receipt </Link> </li>	 */}
                <li className="nav-item"><a class="nav-link" onClick={(e)=>fileDownload('286')}> {t("Notice")} </a> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Amusement_Dept'> {t("Contact Details")} </Link> </li>					
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title"><h3 className="fontSizeClass"> {t("Amusement : FAQs")} </h3>
              </div>
                  <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="heading1">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                      {t("What are the key activities of the department")}? 
                    </button>
                    </div>
                    <div id="collapse1" className="collapse show" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      {t("Amusement Department is for issuing permission under section 422 read with Sec.543 (2) of the KMC Act 1980 to amusement related businesses. Such amusement related businesses/activities include Theatres, Cinema Houses, Circuses, Fairs, Fetes, Exhibitions, Dancing Halls, Carnivals, Video Shows/Games, Cyber Cafes, Cable TV operators, Restaurants, Restaurants with Bar, Hotels, Planetariums, Pool Parlors, Clubs & similar public resorts")}. 
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading2">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                      {t("What are the contact details of sub-departments within the assessment department")}?
                    </button>
                    </div>
                    <div id="collapse2" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                          {t("Sr. Amusement Officer")}, <br />
                          {t("5, S.N. Banerjee Road, Kolkata 700013")},<br />
                          {t("Phone: 22861000, Ext 2608")}
                      </div>                      
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading3">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                      {t("Who needs to apply for an amusement license")}?
                    </button>
                    </div>
                    <div id="collapse3" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      {t("If you are involved in any business activity included in Schedule of Fees & Charges, you will need to apply for an Amusement License and pay an Amusement fee prior to commencing business. The issued licenses are valid from the date of issue to end of financial year i.e. 31st Mar and an annual license fee is charged to issue licenses irrespective of number of days/months effectively available from date of issue. For e.g. if the license is issued on 30th Mar, a complete annual fee will be charged even though the license will expire on 31st Mar. At the financial year-end, licenses need to be renewed by paying annual license fee")}. 
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                      {t("Do I need a license for exhibitions, shows fairs etc")}?   
                    </button>
                    </div>
                    <div id="collapse4" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      {t("Yes.If you are applying for a license for casual or regular shows, performances, exhibitions, fairs, fetes, carnival, circus etc please fill in Proforma Application form. See Annex A for a sample of Performa application. Refer to Schedule of Fees & Charges, to ascertain the fees applicable to your event. Please visit the amusement department at KMC HQ between 11AM and 2:30 PM in order to submit your application form, fees and other documents such as authorization letter from the premises that you will use. You will be issued a license on the spot when you visit the amusement department")}.  </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading5">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                      {t("Do I need a license for a restaurant, cyber café, cinema hall etc")}?
                    </button>
                    </div>
                    <div id="collapse5" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        {t("Yes. Please write a letter on your business letterhead or on plain paper addressed to the Sr. Amusement Officer, KMC seeking a license for your concerned amusement activity. Please attach")},
                          <ul class="kmc-function-list">			 
                            <li>   {t("Recent rent bills")} </li>
                            <li>   {t("Electricity bills")}. </li>
                            <li>   {t("Rental agreement paper or purchase deed to prove lawful tenancy or ownership of proposed amusement site")}. </li>
                          </ul>
                          {t("Please refer to Schedule of Fees & Charges to determine applicable license fee")}.
                        </div>
                      </div>
                  </div>
                  <div className="card">
                  <div className="card-header" id="heading6">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                    {t("What happens after I submit documents")}?   
                  </button>
                  </div>
                  <div id="collapse6" className="collapse" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    {t("Upon submission of documents & fees, an inspection of proposed site will be carried out by an inspector from the amusement department within 7 days of submission of application. Once inspection is complete & satisfactory, the amusement department will issue a license the next business day")}.  </div>
                    </div>
                  </div>
                  <div className="card">
                  <div className="card-header" id="heading7">

                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                    {t("What can I do if there is excessive delay in my getting my license in spite of providing all the documents as specified")}?
                  </button>
                  </div>
                  <div id="collapse7" className="collapse" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    {t("In the unlikely case of such an event, you are most welcome to approach us either personally or by phone at the address/phone number provided above (question 2)")}   </div>
                    </div>
                  </div>
                  <div className="card">
                  <div className="card-header" id="heading8">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
                    {t("What should I do incase if I have a complaint against inspectors")}? 
                  </button>
                  </div>
                  <div id="collapse8" className="collapse" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                          {t("Please write a letter describing your complaint to")},<br />
                          {t("Sr. Amusement Officer")},<br />
                          {t("5 S.N Banerjee Road, Kolkata - 700 013")}<br />
                          {t("Or call us at 22861000, Ext 2608")}    </div>
                    </div>
                  </div>

                    <div className="card">
                    <div className="card-header" id="heading9">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
                      {t("Can my amusement license be revoked? If yes, under what circumstances")}?
                    </button>
                    </div>
                    <div id="collapse9" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                          {t("Under section 441 of the KMC act 1980, Municipal Commissioner is empowered to cancel the annual permission granted by the department. Licenses can be revoked under following circumstances")},
                          <ul class="kmc-function-list">			 
                            <li> {t("Cyber Cafés that allow clients to visit pornographic sites may lose their licenses")}. </li>
                            <li> {t("Hotel Bars that do not have prior permission from landlords or create public nuisance are liable to lose their licenses")}.</li>
                          </ul>
              </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading10">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse10" aria-expanded="true" aria-controls="collapse10">
                      {t("Is there a reminder service for payment of amusement charges? How often are these reminders sent")}?
                    </button>
                    </div>
                    <div id="collapse10" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      {t("Yes. Amusements license must be renewed in April each year since licenses are valid up to 31st March i.e. the end of financial year. The department mails a reminder letter in June reminding business owners regarding outstanding dues. The department consequently sends further communication on a monthly basis reminding business owners regarding outstanding charges")}.  </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading11">
                      
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                      {t("What payment methods are available to me")}?
                    </button>
                    </div>
                    <div id="collapse11" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      {t("You can pay by cash (Upto Rs. 10,000) or demand draft in favor of Kolkata Municipal Corporation payable at Kolkata. Please mail DDs to")},<br />
                          {t("Senior Amusement Officer")}<br />
                          {t("5, S.N. Banerjee Road, Kolkata 700013")}.<br />
                          {t("You are welcome to visit the amusement department personally to make your payment between 11 AM & 2:30 PM on all working days and between 11 AM to 12 PM on Saturdays")}.
                        </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading12">
                      
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse12" aria-expanded="true" aria-controls="collapse12">
                      {t("What is the amusement fee payment schedule? Can I pay in monthly installments")}?
                    </button>
                    </div>
                    <div id="collapse12" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        {t("Amusement fee is a license fee that must be paid prior to commencing business operations. Unfortunately, there is no option to pay amusement charges on an installment basis. In case of license renewals the fee must be paid in April or May of new financial year")}.
                    </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading13">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse13" aria-expanded="true" aria-controls="collapse13">
                      {t("Where can I deposit my outstanding amusement fees")}?
                    </button>
                    </div>
                    <div id="collapseThree" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                          {t("You can deposit the renewal amusement fees at any Common Collection Centres")}.<br />
                          {t("Deposit new and casual Amusement fees at")} :<br />
                          {t("Sr. Amusement Officer")}<br />
                          {t("5, S.N. Banerjee Road, Kolkata 700013")},<br />
                          {t("Phone: 22861000, Ext 2608")}  </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading14">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse14" aria-expanded="true" aria-controls="collapse14">
                      {t("Can I pay my amusement taxes for future years")}?
                    </button>
                    </div>
                    <div id="collapse14" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                          {t("No. Amusement fees are subject to change on a yearly basis. Therefore, these cannot be paid for future years")}.
                    </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading15">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse15" aria-expanded="true" aria-controls="collapse15">
                            {t("What if I have information regarding people who do not pay amusement fees? Who do I contact")}? <i class="bi bi-chevron-down"></i>
                      </button>
                    </div>
                    <div id="collapse15" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      {t("In such cases you are welcome to contact the Sr. Amusement officer at the number provided above")}.<br />
                            {t("The amusement fees is used to provide civic amenities to the citizens of Kolkata. People who do not pay their amusement fee are committing a crime against the city. We strongly encourage responsible citizens to inform us in case they notice people who do not pay amusement fees. Your name and particulars will kept absolutely confidential. You may inform Senior Amusement officer at 22861000, Ext 2608. You may also drop in a letter or email directly to the Municipal Commissioner of Kolkata (email - mc@kmcgov.in)")}.
                        </div>
                      </div>
                    </div>

                  </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
    {/*   */}
  </section>
  
    </>
  )
}

export default Amusement