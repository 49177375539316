import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const KMC_Bustee_services = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
  
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> KMC Bustee Services </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/KMC_Bustee_services'> Bustee Services </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/BSUP'> BSUP </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('247')}> Result of Lottery held at Nonadanga </a>
                  
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BusteeDemandView.jsp'> Check Demands Payable </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BusteeDemandView.jsp'> Print Unpaid Bill</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BusteePayment.jsp'> Make Online Payment</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BusteeDuplicateReceipt.jsp'> Reprint e-Receipt </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BSContact.jsp'> Contact Details</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="">
              <div className="inner-title">
                <h3 className="fontSizeClass"> Bustee Services : FAQs</h3>
              </div>
              <section id="faq" className="faq advertisement">
                <div className="" >
                  <div className="row gy-4">
                    <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1 no-margin">
                     
                    <div id="accordion">
                    <div className="card">
                    <div className="card-header" id="heading1">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                    What are the key activities of the department? 
                    </button>
                    </div>
                    <div id="collapse1" className="collapse show" data-parent="#accordion">
                    <div class="card-body m-3">
                         The role of the Bustee Cell is to facilitate
                    <ul className="kmc-function-list">
                      <li>
                        Supply of adequate drinking water by arranging
                        for community tube-wells and community water
                        taps.
                      </li>
                      <li>
                        Improvement of the sewerage &amp; drainage
                        system of the Bustees including conversion of
                        kuchha surface drain intounder-ground pipe
                        sewerage.
                      </li>
                      <li>
                        Improvement of existing passage as well as
                        construction of new passage including
                        conversion of kuchha passage into pucca road.
                      </li>
                      <li>
                        Construction of new community latrines and
                        repairing of existing community latrine
                      </li>
                      <li>
                        
                        Illumination of slum lanes, by-lanes and
                        passages by providing streetlight, mini pole
                        etc.
                      </li>
                      <li>
                        
                        Construction of Community Hall for the slum
                        dwellers
                      </li>
                    </ul>
                    </div>
                    </div>
                  </div>

  
                <div class="card">
                <div className="card-header" id="heading2">
                                  <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                                  What are the different types of slum infrastructure development works undertaken? 
                                  </button>
                                  </div>   
                  <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordion">
                    <div class="card-body m-3">
                  The role of the Bustee Cell is to facilitate
                        <ul className="kmc-function-list">
                          <li>
                            Supply of adequate drinking water by arranging
                            for community tube-wells and community water
                            taps.
                          </li>
                          <li>
                            Improvement of the sewerage &amp; drainage
                            system of the Bustees including conversion of
                            kuchha surface drain intounder-ground pipe
                            sewerage.
                          </li>
                          <li>
                            Improvement of existing passage as well as
                            construction of new passage including
                            conversion of kuchha passage into pucca road.
                          </li>
                          <li>
                            Construction of new community latrines and
                            repairing of existing community latrine
                          </li>
                          <li>
                            
                            Illumination of slum lanes, by-lanes and
                            passages by providing streetlight, mini pole
                            etc.
                          </li>
                          <li>
                            
                            Construction of Community Hall for the slum
                            dwellers
                          </li>
                        </ul>
                          </div>
                  </div>
                </div>
                <div class="card">
                <div className="card-header" id="heading3">
                                  <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                                  Whom should I contact for the above types of work? 
                                  </button>
                                  </div> 
                  <div id="collapse3" class="collapse" aria-labelledby="heading3" data-parent="#accordion">
                    <div class="card-body m-3">
                    You may contact the following :
                    <ul className="kmc-function-list">
                      <li> Local Ward officer </li>
                      <li>Concerned Borough Executive Engineer</li>
                      <li>Bustee Director-General</li>
                    </ul>
                      </div>
                  </div>
                </div>
                <div class="card">
                <div className="card-header" id="heading4">
                                  <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                                  How should I apply to avail the above civic-infrastructure facilities? 
                                  </button>
                                  </div> 
                  
                  <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordion">
                    <div class="card-body m-3">
                    You can avail of the facilities by :
                    <ul className="kmc-function-list">
                      <li> submitting a written complaint to local ward officer or by </li>
                      <li> entering your complaint in the local ward office complaint register</li>
                    </ul>
                      </div>
                  </div>
                </div>
                <div class="card">
                <div className="card-header" id="heading5">
                                  <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                                  When will action be taken on my complaint? 
                                  </button>
                                  </div>                   
                  <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordion">
                    <div class="card-body m-3">
                    You can avail of the facilities by :
                    <ul className="kmc-function-list">
                      <li> submitting a written complaint to local ward officer or by </li>
                      <li> entering your complaint in the local ward office complaint register</li>
                    </ul>
                      </div>
                  </div>
                </div>
                <div class="card">
                <div className="card-header" id="heading6">
                                  <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                                  When will action be taken on my complaint? 
                                  </button>
                                  </div>                   
                  <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                    <div class="card-body m-3">
                    <div className=" health-dept-table">
                                <table
                                  width="95%"
                                  border={0}
                                  align="center"
                                  cellPadding={2}
                                  cellSpacing={2}
                                  className="table table-bordered officials"
                                >
                                  <tbody>
                                    <tr
                                      bgcolor="#302ba0"
                                      tabIndex={0}
                                      align="center"
                                      valign="middle"
                                    >
                                      <td width="20%" className="text-white tble-brder-left">
                                      Sl.No.
                                      </td>
                                      <td width="40%" className="text-white">
                                      Queries/complain	
                                      </td>
                                      <td width="40%" className="text-white">
                                      Immediate contract person
                                      </td>
                                    </tr>
                                    <tr tabIndex={0} align="left" valign="top">
                                      <td >1.</td>
                                      <td >Chokage of Sewer Lines</td>
                                      <td >Concerned ward A.E./S.A.E.of civil dept.</td>
                                    </tr>
                                    <tr tabIndex={0} align="left" valign="top">
                                      <td >2.</td>
                                      <td >Bulb/Fluorescent tube / vapor lamp not functioning.	</td>
                                      <td >Local electrical maintenance staff of KMC or inform concerned ward AE/SAE (Electrical)</td>
                                    </tr>
                                    <tr tabIndex={0} align="left" valign="top">
                                      <td >3.</td>
                                      <td >Break-down of Hand tube-wells	</td>
                                      <td >Local tube well maintenance staff of KMC or inform concerned ward AE/SAE of tube well dept.</td>
                                    </tr>
                                    <tr tabIndex={0} align="left" valign="top">
                                      <td >4.</td>
                                      <td >Problems regarding water stand posts	</td>
                                      <td >Local ward AE/SAE (water supply)</td>
                                    </tr>
                                    <tr tabIndex={0} align="left" valign="top">
                                      <td >5.</td>
                                      <td >Damage of manhole cover or gully pit or lamp hole or inspection pit cover.	</td>
                                      <td >Local ward SAE.</td>
                                    </tr>
                                    <tr tabIndex={0} align="left" valign="top">
                                      <td >6.</td>
                                      <td >Septic tank over flow in community toilets.	</td>
                                      <td >Inform local C.O., or Block Sarkar.</td>
                                    </tr>
                                    <tr tabIndex={0} align="left" valign="top">
                                      <td >7.</td>
                                      <td >Any other problem not covered above	</td>
                                      <td >To be informed local Borough Office.</td>
                                    </tr>
                                    <tr tabIndex={0} align="left" valign="top">
                                      <td >8.</td>
                                      <td >Pay & Use Toilet	</td>
                                      <td >AE(C), ISP, Bustee Department</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                      </div>
                  </div>
                </div>
                <div class="card">
                <div className="card-header" id="heading7">
                                  <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                                  If I need more information whom should I meet? 
                                  </button>
                                  </div>                  
                  <div id="collapse7" class="collapse" aria-labelledby="heading7" data-parent="#accordion">
                    <div class="card-body m-3">
                    Kindly meet D.G Bustee, located at the Head Office of KMC, Hogg Buildings (3rd Floor), 1, Hogg Street, Kolkata -87.
                    Phone No. : 22861280 (Extn: 2598).
                      </div>
                  </div>
                </div>
              </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>
  )
}

export default KMC_Bustee_services