import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

    const MC_Circular_2023_24 = () => {
        const fileDownload=(fileStoreId)=>
        {
          
          let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
           axios
              .post(url,{"fileId": fileStoreId, "userId": "392" })
              .then((res) => {
                  console.log("response is --- ", res);
      
                  if(res && res.data && res.status === 200) {
                    const base64 = res.data[0] && res.data[0].base64_data;
                    const pdfData = 'data:application/pdf;base64,'+base64;
      
                    let html = '<html>' +
                        '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                        '<body>' +
                        '<iframe src="' + pdfData + '"></iframe>' +
                        '</body></html>';
                        console.log(html);
                    let a = window.open();
                    a.document.write(html);
                }
              })
              .catch((e) => {
                  // setLoader(false);
                  console.log("The error is ", e);
              })
        }
        const increaseFontSizeBy1px =() => {
          var el = document.getElementsByClassName('fontSizeClass');
          for (let i = 0; i < el.length; i++) {
            var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
            var fontSize = parseFloat(style); 
            el[i].style.fontSize = (fontSize + 1) + 'px';
          }
        };
        const decreaseFontSizeBy1px =() => {
          var el = document.getElementsByClassName('fontSizeClass');
          for (let i = 0; i < el.length; i++) {
          var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
          var fontSize = parseFloat(style); 
          el[i].style.fontSize = (fontSize - 1) + 'px'; 
          }
        };
      const [DocumentService, setDocumentService] = React.useState(false);
      const [LicenseService, setLicenseService] = React.useState(false);
      
      const LicenseServiceToggle = () => {
        setLicenseService(!LicenseService);
      };
      const DocumentServiceToggle = () => {
          setDocumentService(!DocumentService);
          };
          const { t, i18n, ready } = useTranslation();
        return (
          <>
        <section id="hero" className="hero hero2">
          
          <div className="carousel-item active">
            <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
          </div>
        </section>
        <section className="section inner-pad1">
          <div className="container " >
            <div className="row">
              <div className="col-lg-3">
              <div className="site-menu  min-height-400">
                    <nav className="sidebar card py-2">
                      <ul className="nav flex-column" id="nav_accordion">
                        <li className="nav-item-list ">
                        <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Municipal Commissioner CIRCULAR")}<i className="bi bi-chevron-down " /></button>
                          <div id="demo" class="collapse">
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2024_25'> {t("MC's Circular")} 2024-25 </Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass active1" to='/MC_Circular_2023_24'> {t("MC's Circular")} 2023-24 </Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2022_23'> {t("MC's Circular")} 2022-23 </Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2021_22'> {t("MC's Circular")} 2021-22</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2020_21'>{t("MC's Circular")}  2020-21</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2019_20'>{t("MC's Circular")}  2019-20</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2018_19'> {t("MC's Circular")} 2018-19</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2017_18'>{t("MC's Circular")} 2017-18</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2016_17'>{t("MC's Circular")} 2016-17</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2015_16'>{t("MC's Circular")} 2015-16</Link></li>
                              <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2004_05'>{t("MC's Circular")} 2004-05</Link></li>
                          </div>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link fontSizeClass" to='/Municipal_secretary_circular'>{t("MUNICIPAL SECRETARY'S CIRCULAR")}</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link fontSizeClass" to='/CMF_Communique_2022_23'>{t("C M F & A's Circular and Communique - 2022-23")}</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link fontSizeClass" to='/Chief_Manager_Circular'>{t("Chief Manager (P)'s Circular")}</Link>
                        </li>
                        <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Govt_memo'>{t("Govt Memo")}</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1558')}> {t("West Bengal Right to Public Service Act,2013")} </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1559')}>{t("Guidelines of Section 199(2) of KMC Act,1980 (as amended in 2019)")}  </a>
                  </li>
                  <li className="nav-item-list ">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("COMPENDIUM OF IMPORTANT DEPARTMENTAL CIRCULARS / ORDERS")}<i className="bi bi-chevron-down " /></button>
                      <div id="demo1" className="collapse">                        
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>1. Financial Year 2010-11 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>2. Financial Year 2011-12 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('870')}>3. Financial Year 2012-13 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('925')}>4. Financial Year 2013-14 </a>   
                      </div>
                  </li>
                        
                      </ul>
                    </nav>
                  </div>
              </div>
              <div className="col-lg-9">
                <div className="container">
                  <div className="row">
                    <div className="inner-title2 bas">
                      <h3 className="fontSizeClass"> {t("Circular")} </h3>
                    </div>
                    <div className="health-dept-table mayor-table m-ta-2">
                      <table className="table table-bordered officials" >
                        <tbody>
                          <tr
                            bgcolor="#302ba0"
                            tabIndex={0}
                            align="center"
                            valign="middle">
                            <td width="35%" className="text-white tble-brder-left">
                            {t("Circular No")}		
                            </td>
                            <td width="20%" className="text-white">
                            {t("Date")}		
                            </td>
                            <td width="45%" className="text-white tble-brder-right">
                            {t("Subject")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular60_30_03_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 60 of 2023-24 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("30-March-2024")}			
                            </td>
                            <td align="center">
                            {t("Strict Compliance of KMC Bldg. Rules,2019 & KMC Act,1980")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular59_28_03_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 59 of 2023-24 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("28-March-2024")}			
                            </td>
                            <td align="center">
                            {t("Roll out of new Finance system including CRN and Contractual Employee Management System under eKMC 2.0 Project from WBSDC")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular58_30_03_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 58 of 2023-24 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("27-March-2024")}			
                            </td>
                            <td align="center">
                            {t("Ever since KMC has adopted zero tolernce policy against any sort of unauthorized contructions, relentless efforts were made to identify such cases and to take appropriate legal measures")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular57_28_03_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 57 of 2023-24 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("27-March-2024")}			
                            </td>
                            <td align="center">
                            {t("To detect and take immediate action against illegal filing and ill maintained of water bodies day to day the following procedures are to be followed henceforth")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular56_04_04_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No.56 of 2023-24 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("27-March-2024")}			
                            </td>
                            <td align="center">
                            {t("Procedural Guideline to be followed at the time of mutation / assessment / suo-moto assessment / apportionment vis-a-vis recording of name of owner or occupier or person liable to pay tax with respect to buildings / flats / structures which are newly built or which have remained unassessed")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular53_20_03_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 53 of 2023-24 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("16-March-2024")}			
                            </td>
                            <td align="center">
                            {t("Over the last few years KMC has taken over new properties / land / premises from different stakeholders like PWD, Govt. of West Bengal, KMDA and other ULBs etc")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular52_20_03_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 52 of 2023-24 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("15-March-2024")}			
                            </td>
                            <td align="center">
                            {t("Payment of NEFT/RTGS of Central/State Government agencies through KMC Virtual Account Number")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular51_15_03_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 51 of 2023-24 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("14-March-2024")}			
                            </td>
                            <td align="center">
                            {t(" Modification of Mpl. Commissioner's Circular no. 45 of 2023-24, dated 16.02.2024 regarding the Job distribution amongst Additional Municipal Commissioner, Joint Municipal Commissioner and Municipal Secretary ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 51 of 2023-24 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("14-March-2024")}			
                            </td>
                            <td align="center">
                            {t(" Modification of Mpl. Commissioner's Circular no. 45 of 2023-24, dated 16.02.2024 regarding the Job distribution amongst Additional Municipal Commissioner, Joint Municipal Commissioner and Municipal Secretary ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular50_13_03_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t(" MC's Circular No. 50 of 2023-24 ")}	 </a>
                            </td>
                            <td align="center">
                            {t("12-March-2024")}			
                            </td>
                            <td align="center">
                            {t(" Enhancement of allocation in Councillors' Elaka Unnayan Prakalpa and Integrated Borough Scheme ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular49_11_03_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 49 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("11-March-2024")}			
                            </td>
                            <td align="center">
                            {t(" 	Re-Constitution of a Committee to review the proposals of increase in remuneration of the contractual and re-employee in KMC ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular48_05_03_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 48 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("04-March-2024")}			
                            </td>
                            <td align="center">
                            {t(" 	In partial modification of the Municipal Commissioner's Circular No. 19 of 2023-24 dated 17.06.2023, it is hereby, informed that henceforth Building Department will process for issuing Completion Certificate & Occupancy Certificate (C.C & O.C) for the Building which have been completed after obtaining sanctioned plan initially sanctioned through Ease of Doing Business (EoDB) system ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular47_01_03_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 47 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("01-March-2024")}			
                            </td>
                            <td align="center">
                            {t(" Use of PAN as unique identity for contractual and casual employees of KMC ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular46_28_02_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 46 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("23-February-2024")}			
                            </td>
                            <td align="center">
                            {t(" All the Controlling Officers and HoD of KMC are hereby requested to update their departmental data directly in the RTI Section of the KMC website through I.T. Dept ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular45_17_02_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 45 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("16-February-2024")}			
                            </td>
                            <td align="center">
                            {t(" Job distribution amongst Additional Municipal Commissioner, Joint Municipal Commissioner and Municipal Secretary ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular44_06_02_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 44 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("05-February-2024")}			
                            </td>
                            <td align="center">
                            {t(" Common Hearing Module ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular43_06_02_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 43 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("	01-February-2024")}			
                            </td>
                            <td align="center">
                            {t(" 	Modification of Municipal Commissioner's Circular no.36 of 2020-21, dated 11.12.2020 reg. full utilization of ERP ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular42_02_02_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 42 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("31-January-2024")}			
                            </td>
                            <td align="center">
                            {t(" Printing of Invitation Card ")}
                            </td>
                          </tr>


                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Communique91_29_01_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("Communique No.91 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("27-January-2024")}			
                            </td>
                            <td align="center">
                            {t(" Implementation of Biometric Attendance System at different Departments/Borough/Unit offices of KMC ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular41_10_01_2024.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 41 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("08-January-2024")}			
                            </td>
                            <td align="center">
                            {t(" Regarding Mandatory Biometric Attendance of KMC Staff ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular40_27_12_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 40 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("27-December-2023")}			
                            </td>
                            <td align="center">
                            {t(" Biometric Attendance System for CMO Building, Hogg Building, Roxy Building, HUDCO Building, Information Technology Department, Environment & Heritage, Town Planning Development Department and CME (Civil) Head Quarter ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular35_20_09_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 35 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("19-September-2023")}			
                            </td>
                            <td align="center">
                            {t(" Modalities for implementation, monitoring,maintenance and documentation of plantation activities within KMC area ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular31_08_09_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 31 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("04-September-2023")}			
                            </td>
                            <td align="center">
                            {t(" Emergent instruments/apparatus arrangement during festive days (most particularly from October 18th, 2023 to October 27th, 2023) 24 x 7 arrangements ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular30_07_09_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 30 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("04-September-2023")}			
                            </td>
                            <td align="center">
                            {t(" Emergent and superior-level personnel arrangement during festive days (most particularly from October 18th, 2023 to October 27th, 2023) ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular28_28_08_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 28 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("28-Aug-2023")}			
                            </td>
                            <td align="center">
                            {t(" 	Restriction of entry into the Councillors' Club Room ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular27_23_08_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 27 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("22-Aug-2023")}			
                            </td>
                            <td align="center">
                            {t(" Online System for Medical Bill Reimbursement in the e-KMC System ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular26_28_07_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 26 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("27-July-2023")}			
                            </td>
                            <td align="center">
                            {t(" Assignments of Shri Jyotirmoy Tanti, WBCS(Exe), Jt. Mpl. Commissioner of KMC ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/NOTIFICATION_26_07_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("Notification No. 001/DIV-VIII/PERS/KMC of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("24-July-2023")}			
                            </td>
                            <td align="center">
                            {t(" Method of Recruitment for the post of Sarkar ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular24_22_07_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 24 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("21-July-2023")}			
                            </td>
                            <td align="center">
                            {t(" A work file not to split in different files ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular23_12_07_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 23 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("10-July-2023")}			
                            </td>
                            <td align="center">
                            {t(" Handing over the gifted land to Borough ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular22_10_07_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 22 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("08-July-2023")}			
                            </td>
                            <td align="center">
                            {t(" Biometric Attendance System of SWM Dept ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular21_04_07_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 21 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("04-July-2023")}			
                            </td>
                            <td align="center">
                            {t(" Online submission of Annaul Confidential Report (ACR) by the Group 'A' employees of the Kolkata Municipal Corporation ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular20_04_07_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 20 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("04-July-2023")}			
                            </td>
                            <td align="center">
                            {t(" 	Monitoring of Court Cases - Drafting of Statement of Fact concerning sensitive issues ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular18_12_06_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No. 18 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("10-June-2023")}			
                            </td>
                            <td align="center">
                            {t("KMC Employee Work Diary in the form of Mobile App called 'KMC EMPLOYEE APP' ")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular39_07_06_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("CM(SYS)/10/2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("06-June-2023")}			
                            </td>
                            <td align="center">
                            {t("Computer Password Policy - MC's Circular No. 39 of 2010-11, Dated 27-Dec-2010")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/RTI_List_SPIOs_05_06_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("Con/Com/20/RTI/2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("02-June-2023")}			
                            </td>
                            <td align="center">
                            {t("List of State Public Information Officers and 1st Appeliate Authorities (RTI) under The Kolkata Municipal Corporation")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular16_27_05_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No.16 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("26-May-2023")}			
                            </td>
                            <td align="center">
                            {t("Digital reimbursement of Telephone Bills")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No.16 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("26-May-2023")}			
                            </td>
                            <td align="center">
                            {t("Digital reimbursement of Telephone Bills")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular15_23_05_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No.15 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("22-May-2023")}			
                            </td>
                            <td align="center">
                            {t("Ceiling of Rs. 5 Lakh on subscription in General Provident Fund in a Financial Year")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular12_17_05_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No.12 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("17-May-2023")}			
                            </td>
                            <td align="center">
                            {t("Municipal Commissionr's Circular No. 12 of 2023-24")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular11_16_05_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No.11 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("16-May-2023")}			
                            </td>
                            <td align="center">
                            {t("Speedy disposal of monthly labour wage bills and O&M bills")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular08_04_05_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No.08 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("02-May-2023")}			
                            </td>
                            <td align="center">
                            {t("Emergency arrangements during the ensuing Nor'wester / Rainyseason in the new few months of 2023")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a href='https://www.kmcgov.in/KMCPortal/downloads/MC_Circular07_04_05_2023.pdf' target="_blank" className="nav-link fontSizeClass" onClick={(e)=>fileDownload('')}> {t("MC's Circular No.07 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("29-April-2022")}			
                            </td>
                            <td align="center">
                            {t("Extension of last date for submission of Annual Confidential Report for FY - 2022-23")}
                            </td>
                          </tr> 


                          
                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('921')}> {t("MC's Circular No.05 of 2023-24")}	 </a>
                            </td>
                            <td align="center">
                            {t("18-April-2022")}			
                            </td>
                            <td align="center">
                            {t("Municipal Commissioner's Circular No. 05 of 2023-24")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('922')}> {t("MC's Circular No.03 of 2023-24")}		 </a>
                            </td>
                            <td align="center">
                            {t("05-April-2022")}		
                            </td>
                            <td align="center">
                            {t("Procurement Digital Library (PDL) of Kolkata Municipal Corporation")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('923')}> {t("Communique No.02 of 2023-24")}		 </a>
                            </td>
                            <td align="center">{t("18-April-2022")}	</td>
                            <td align="center">
                            {t("Communique No. 02 of 2023-24")}
                            </td>
                          </tr>

                          <tr tabIndex={0} align="left" valign="top">
                            <td  align="center">
                            <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('875')}> {t("MC's Circular No.01 of 2023-24")}</a>
                            </td>
                            <td align="center">{t("04-April-2022")}</td>
                            <td align="center">
                            {t("Detailed guidelines were issued vide Chief Manager Personnel's Circular No. 24/IVA of 21-22")}
                            </td>
                          </tr>
                          
                          </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
        )
      }

export default MC_Circular_2023_24