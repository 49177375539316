import React from 'react'; 
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Health_Malaria_CLinics  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
<img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass">  </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
      <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_services_Health_Home'> {t("Health Home")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('458')}> {t("Dengue - Status-Appeal to Citizens")} </a>
                 </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('459')}> {t("Immunisation centers")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('460')}> {t("Health Units")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Health_Malaria_CLinics'> {t("Malaria Clinics")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Maternity_Homes'> {t("Maternity Homes")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_KMC_Dispensaries'> {t("KMC Dispensaries")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Chest_Clinincs'> {t("KMC Chest Clinics")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WaterTestDuplicateReceipt.jsp'> {t("Water Test Reprint e-Receipt")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Desease_control'> {t("Desease Control")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Fees_Charges'> {t("Fees & Charges")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_MTMTB_Hospital'> {t("MTMTB Hospital")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Slaughter_House'> {t("Slaughter House")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Ambulances'> {t("Ambulance")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Antirabies_VACCINATION_Center'> {t("Antirabies Vaccination Centre")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Designation_Activities'>{t("Designation Activity")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_KMDA_Health_Projects'> {t("KMDA Health Projects")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BurningGhatStatus.jsp'> {t("Burning Ghat Status")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Land_Records_Burial_Grounds'>{t("Land Records Of Burial Grounds")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Dept'> {t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('461')}> {t("Information on FSS Act-License / Registration in KMC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('462')}> {t("Locations of Slaughtering of Animals on the Occasion of ID-UD-ZOHA(BAKRI-ID) ON 10.07.2022, 11.07.2022 & 12.07.2022")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('469')}> {t("Swasthya Bandhab Sharad Samman")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('470')}> {t("Details of UPHC")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('466')}> {t("List of Enlisted Agencies for Carrying Covid Bodies")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('471')}> {t("Immersion training by ECHO India for KMC Health staff")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('472')}> {t("KMC Participation and Experiences at ECHO India's IPC Dissemination Event : Mumbai")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'>
                    {t("Application/Payment for Potable Water Analysis")}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("KMC Malaria Clinics")} </h3>
              </div>
              <div className="health-dept-table" style={{width:"100%"}}>
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"
                    >
                      <td width="20%" className="text-white tble-brder-left">
                        {t("Borough")}
                      </td>
                      <td width="50%" className="text-white">
                        {t("Clinic Name")}
                      </td>
                      <td width="30%" className="text-white tble-brder-right">
                        {t("Address")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> I </td>
                      <td>{t("Municipal Dispensary, Chitpur")}</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> ||</td>
                      <td width={800}>
                        {t("Hatibagan Dispensary")}
                        <br />
                        {t("Allen Market Dispensary, Malaria Detection Centre: WHU - 19, W.H.U. - 15, W.H.U. - 16")}
                        <br />
                        {t("M.D.C. At Nilmoni Mitra St")}
                        <br />
                        {t("WHU. - 20, W.H.U. - 12")}
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">|||</td>
                      <td>
                        {t("Ultadanga Dispensary")}
                        <br />
                        {t("Rajabazar Dispensary")}
                        <br />
                        {t("Narkeldanga Dispensary")}
                        <br />
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> IV</td>
                      <td>
                        {t("Jorabagan Park")}
                        <br />
                        {t("WHU, Raja U Street")}
                        <br />
                        {t("Girish Park Clinic")}
                        <br />
                        {t("Sukia Street VAC, Centre: WHU - 26, WHU - 23")}
                        <br />
                        {t("WHU - 24, W.H.U. - 39")}
                        <br />
                        {t("WHU - 25, W.H.U. - 22")} <br />
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> V</td>
                      <td>
                        {t("Bathakkhana Dispensary, Shraddhananda Park")}
                        <br />
                        {t("Nafar Kolay Dispensar, Bagola Clinic")}
                        <br />
                        {t("HQ - Br V, Surya Sen Street, WHU - WD - 36")} <br />
                        {t("W.H.U. - 41, WHU WD 45")}
                        <br />
                        {t("WHU - 44, WHU - 50")}
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> VI</td>
                      <td>
                        {t("Taltala Dispensary")}
                        <br />
                        {t("WHU 60 + 61")}
                        <br />
                        {t("Haji Md. M.SQ Dispensary")}
                        <br />
                        {t("WHU - 46 + 47")}
                      </td>
                      <td>
                        <br />
                        {t("42, Jannagore Road")}
                        <br />
                        <br />
                        {t("16, Jadunath Dey Road")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> VII </td>
                      <td>
                        {t("Gobra Dispensary, Tiljala Dispensary")}
                        <br />
                        {t("S. Roy Road Clinic, New Tangra Dispensary")}
                        <br />
                        {t("Palm Avenue Dispensary, Sundari Mohan Avenue Dispensary, Topsia Clinic HQ - BR - VII")}
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">VIII</td>
                      <td>
                        {t("Ballygunge Phari Clinic, Bhowanipur Clinic")}
                        <br />
                        {t("Vivekananda Park ward Office, Southern Avenue WHU, Ward No 85, W.H.U. - 68")}
                        <br />
                        {t("W.H.U. - 84, W.H.U. - 72")}
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> IX </td>
                      <td>
                        {t("Kalighat Dispensary, Khidderpore Dispensary")}
                        <br />
                        {t("Chetla Dispensary, WHU - 84")}
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> X </td>
                      <td>{t("Tollygunge Clinic, Cachtala Clinic")} </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> XI </td>
                      <td>{t("Atabagan Clinic")} </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> XII </td>
                      <td>{t("Ramlal Bazar Clinic ")}</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> XIII </td>
                      <td>{t("Barisha Matrisadan, Buroshibtala M.D.C")}. </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> XIV </td>
                      <td>{t("Panchanantala (S.S.M.L. & Disp)")} </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center"> XV </td>
                      <td>{t("Garden Reach Clinic, W.H.U.-141, VCD")}</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Health_Malaria_CLinics