import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const HeritageBuildingHome4  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container" >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
              <li className="nav-item">
                <Link className="nav-link active1 fontSizeClass" to='/HeritageBuildingHome'> {t("Heritage Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Exemption_of_Tax'> {t("Exemption of Tax")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Heritage_Buildings'> {t("Heritage Buildings")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Conservation_Architect'> {t("Conservation Architect")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('473')}>{t("Composition of the Heritage Conservation Committee")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Proceedings_of_HCC'>
                    
                    {t("Proceedings of HCC")}
                  </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('474')}>{t("Action Plan")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('475')}>{t("Parking Policy")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('476')}>{t("Measures for prevention of landfill fire")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWisePond.jsp'>  {t("Ward wise Pond")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWiseHeritageBuilding.jsp'> {t("Ward Wise Heritage Building")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Environment_Heritage_Dept'> {t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Activities_NCAP.pdf' target={'_blank'}> {t("Activities Permitted under NCAP")} </a>
                </li>

                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Swachh Vayu Survekshan")} <i className="bi bi-chevron-down" /></button>
                    <div id="demo2" className="collapse">
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessed_CPCB_scores_23_09_2023.xlsx'>1. {t("Self assessed scores & CPCB scores")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessment_reports_Kolkatacity_23_09_2023.pdf'>2. {t("Self assessment reports of Kolkata city2023")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Swachh_Vayu_Survekshan_23_09_2023.pdf'>3. {t("Swachh Vayu Survekshan 2023 Rank")} </a>
                    </div>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/airquality/citizenreport.do' target={'_blank'}> {t("Air quality status")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/emis/category.do' target={'_blank'}> {t(" Industry categorisation")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/District_Environment_ KMC2021_23_09_2023.pdf' target={'_blank'}> {t("FINAL District Environment plan KMC 2021")} </a>
                </li>


              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2" >
                <h3 className="fontSizeClass">{t("Heritage Buildings of Kolkata")} </h3>
              </div>
              <div className="about-head1">                 
                    <div className="log-form inner-log-form">
                      <h4 className="fontSizeClass"> Guidelines For The Owners Of Heritage Buildings </h4>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 form-group pt-2">
                          <div className="list-of-forms">
                            <h5 className="fontSizeClass"> <strong> 425N.	Taking over management and control of heritage building : </strong> </h5>
                            <span>...
                             <Link className="fontSizeClass" to='/HeritageBuildingHome'>  {t("1")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome2'> {t("2")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome3'> {t("3")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome4'> {t("4")} </Link>
                            </span>

                           <p> 	(1)	If the Municipal Commissioner, on receipt of any information, is satisfied that the owner 
                            of a heritage building fails to preserve or conserve the heritage building, the Municipal Commissioner
                             may, when the heritage building is vacant and after hearing the owner, by order in writing, 
                             take over the management and control of such heritage building for the purpose of preservation 
                             and conservation thereof, suspending the right of the owner to transfer such heritage building 
                             for a maximum period of five years, subject to acquisition either by agreement or under the 
                             provisions of the Land Acquisition Act, 1894 (1 of 1894).
                           </p>
                           <p>	(2)	The Municipal Commissioner shall thereafter notify the heritage building for letting it out 
                            by agreement to any person as tenant for the purpose as aforesaid, and the owner shall be entitled
                             to an amount equal to the reasonable letting value of the heritage building as rent less the cost 
                             on account of preservation and conservation of the heritage building.</p>
                            <p> <strong> 425O.When heritage building ceases to be heritage building :</strong><br />
                             If the Corporation decides that any heritage building has ceased to be of public interest or 
                             has lost its importance for any reason whatsoever, it may, with the approval of the State 
                             Government, declare that such heritage building has ceased to be a heritage building for the 
                             purposes of this Act.
                            </p>
                            <h5>425P.	Penalty :</h5>
                            <p>(1)	Any person who destroys, removes, alters, defaces or misuses any heritage building or 
                                does any act, or abets in the commission thereof, in contravention of any provision of this 
                                chapter or the rules or the regulations made thereunder, shall be punishable with rigorous 
                                imprisonment for a term which may extend to three years and also with fine which may 
                                extend to fifty thousand rupees and, in default, with further rigorous imprisonment 
                            for six months.
                            </p>
                            <p>(2)	Any court convicting any person under this section shall, by order, 
                                direct such person to restore the heritage building to its former shape 
                                and beauty at his cost, and any failure to comply with such order shall be deemed to be a 
                                continuing offence and such person shall be punishable with an additional fine of rupees two 
                                hundred and fifty for every day during which such contravention or failure continues after 
                                conviction for the first such contravention.</p>
                            <p>(3)	Where an offence under this section has been committed by a company, the provisions 
                                of section 619 shall apply to such company.</p>
                            <p> <strong> List of empanelled conservation Architect </strong>
                                To view the list click <a href='https://www.kmcgov.in/KMCPortal/jsp/ConservationArchitect.jsp' target={'_blank'}>here</a> .</p>

                            <span>...
                             <Link className="fontSizeClass" to='/HeritageBuildingHome'>  {t("1")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome2'> {t("2")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome3'> {t("3")} </Link>
                             <Link className="fontSizeClass" to='/HeritageBuildingHome4'> {t("4")} </Link>
                            </span>

                          </div>                       
                        </div>
                      </div>                
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  </section>
</>

  )
}

export default HeritageBuildingHome4