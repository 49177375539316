import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const MC_Circular_2022_23 = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
const [DocumentService, setDocumentService] = React.useState(false);
const [LicenseService, setLicenseService] = React.useState(false);

const LicenseServiceToggle = () => {
  setLicenseService(!LicenseService);
};
const DocumentServiceToggle = () => {
    setDocumentService(!DocumentService);
    };
    const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <button type="button" class="btn nav-link pr-0" data-toggle="collapse" data-target="#demo">{t("Municipal Commissioner CIRCULAR")}<i className="bi bi-chevron-down " /></button>
                    <div id="demo" class="collapse">
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2024_25'> {t("MC's Circular")} 2024-25 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2023_24'> {t("MC's Circular")} 2023-24 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass active1" to='/MC_Circular_2022_23'> {t("MC's Circular")} 2022-23 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass " to='/MC_Circular_2021_22'> {t("MC's Circular")} 2021-22</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2020_21'>{t("MC's Circular")} 2020-21</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2019_20'>{t("MC's Circular")} 2019-20</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2018_19'> {t("MC's Circular")} 2018-19</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2017_18'>{t("MC's Circular")} 2017-18 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2016_17'>{t("MC's Circular")} 2016-17</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2015_16'>{t("MC's Circular")} 2015-16</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2004_05'>{t("MC's Circular")} 2004-05</Link></li>
                    </div>
                  </li><li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Municipal_secretary_circular'>{t("MUNICIPAL SECRETARY'S CIRCULAR")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/CMF_Communique_2022_23'>{t("C M F & A's Circular and Communique - 2022-23")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Chief_Manager_Circular'>{t("Chief Manager (P)'s Circular")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Govt_memo'>{t("Govt Memo")}</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1558')}> {t("West Bengal Right to Public Service Act,2013")} </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1559')}>{t("Guidelines of Section 199(2) of KMC Act,1980 (as amended in 2019)")}  </a>
                  </li>
                  <li className="nav-item-list ">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("COMPENDIUM OF IMPORTANT DEPARTMENTAL CIRCULARS / ORDERS")}<i className="bi bi-chevron-down " /></button>
                      <div id="demo1" className="collapse">                        
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>1. Financial Year 2010-11 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>2. Financial Year 2011-12 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('870')}>3. Financial Year 2012-13 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('925')}>4. Financial Year 2013-14 </a>   
                      </div>
                  </li>
                  
                </ul>
              </nav>
            </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> Circular </h3>
              </div>
              <div className="health-dept-table mayor-table m-ta-2">
                <table className="table table-bordered officials" >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle">
                      <td width="35%" className="text-white tble-brder-left">
                      Circular No		
                      </td>
                      <td width="20%" className="text-white">
                      Date		
                      </td>
                      <td width="45%" className="text-white tble-brder-right">
                      Subject
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('871')}> FY 2013-14 </a>
                      </td>
                      <td align="center">
                      22-March-2023		
                      </td>
                      <td align="center">
                      Initiation of works file of Engineering Departments through eOffice
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('873')}> MC Circular No.50 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      17-March-2023	
                      </td>
                      <td align="center">
                      Online Submission of Asset Declaration and Annual Confidential Report (ACR) by the Employees of Kolkata Municipal Corporation
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('874')}> MC Circular No.48 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      08-February-2023	                      </td>
                      <td align="center">
                      Emphasis on street wise combing operation, field work, service at doorstep and revenue augmentation by Assessment Collection Department
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('875')}> MC Circular No.47 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      06-February-2023	                      </td>
                      <td align="center">
                      Introduction of 30th June and 31st December as cut off dates for O&M Contracts/Labour Contracts/Work Orders
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('876')}> ("FY 2010-11") </a>
                      </td>
                      <td align="center">
                      11-January-2023	
                      </td>
                      <td align="center">
                      Recording of Waterbodies
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('877')}> MC Circular No.45 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      30-January-2023	                      </td>
                      <td align="center">
                      Recruitment Regulation for the posts of Director General (Civil), Director General (Mechanical) and Director General (Electrical) under the Kolkata Municipal Corporation
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('878')}> MC Circular No.44 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      11-January-2023	                      </td>
                      <td align="center">
                      Recording of Waterbodies
                      </td>
                    </tr>                    
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('879')}> MC Circular No. 42 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      04-January-2023		
                      </td>
                      <td align="center">
                      Procurement & Renewal of Digital Signature Certificate (DSC)
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('880')}> MC Circular No. 41 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      24-December-2022	
                      </td>
                      <td align="center">
                      Municipal Commissioner's Circular No. 41 of 2022-23
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('881')}> MC Circular No. 40 of 2022-23 </a>
                      </td>
                      <td align="center">
                      22-December-2022		
                      </td>
                      <td align="center">
                      Proper procedure for e-office.
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('782')}> MC Circular No. 39 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      22-December-2022		
                      </td>
                      <td align="center">
                      Municipal Commissioner's Circular No. 39 of 2022-23
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('783')}>MC's Office Order No.38 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      06-December-2022		
                      </td>
                      <td align="center">
                      Modification of Recruitment Regulations for the post Veterinary officer under Health Department, KMC.
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('784')}>MC's Office Order No.37 of 2022-23 </a>
                      </td>
                      <td align="center">
                      06-December-2022		
                      </td>
                      <td align="center">
                      Modification of Recruitment Regulations for the posts of Assistant Horticulturist and Arboricultural Overseer under Parks & Squares Department, KMC
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('785')}> MC's Office Order No.36 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      05-November-2022		
                      </td>
                      <td align="center">
                      Municicipal Commissioner's Circular No.36 of 2022-23
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('884')}> MC's Office Order No.35 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      22-October-2022		
                      </td>
                      <td align="center">
                      Safety Audit and Maintenance of Foot Over Bridge (F.O.B) Ref: E- file No. KMC -23/59/2022-ADVT SEC-KMC
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('885')}> MC's Office Order No.34 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      21-October-2022		
                      </td>
                      <td align="center">
                      Re: Deployment of officials at the different ghats on the river hooghly and ponds in added areas
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('886')}> MC's Circular No.33 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      19-October-2022		
                      </td>
                      <td align="center">
                      Re: Deployment of officials at the different ghats on the river hooghly and ponds in added areas
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('887')}> MC's Office Order No.10 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      19-October-2022		
                      </td>
                      <td align="center">
                      Municipal Commissioner's Office Order No.10 of 2022-23
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('888')}> MC's Circular No.32 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      11-October-2022		
                      </td>
                      <td align="center">
                      Floating Tender three months earlier for works that are predetermined or considered to be annual events
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('889')}> MC's Circular No.29 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      MC_Circular29_06_09_2022	
                      </td>
                      <td align="center">
                      Emergent instruments/ apparatus arrangements during festive days(most particularly from (september, 30th 2022 to October, 9th 2022))24*7 arrangements
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('890')}> MC's Circular No.28 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      05-september-2022		
                      </td>
                      <td align="center">
                      Emergent and superior-level personnel arrangement during festive days(most particularly from september 30th, 2022 to October 9th, 2022)                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('891')}> MC's Circular No.27 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      20-august-2022		
                      </td>
                      <td align="center">
                      Matters of utmost importance like public safety etc. to be brought to the notice of the authority immediately.
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('892')}> MC's Circular No.26 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      16-august-2022		
                      </td>
                      <td align="center">
                      Safety Audit and Maintenance of Foot Over Bridge (FoB)
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('893')}> MC's Circular No.25 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      13-august-2022		
                      </td>
                      <td align="center">
                      Guidelinc for lateral transfer and transfer on promotion of KMC employees
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('894')}> MC's Office Order No.07 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      08-august-2022		
                      </td>
                      <td align="center">
                      Municipal Commissioner's Office Order No.07 of 2022-23
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('895')}> MC's Circular No.24 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      01-august-2022		
                      </td>
                      <td align="center">
                      Partial modification of communittee for guiding the manager of provedent fund to invest and /or re -invest the amount of p.f corpus in time deposit sceme or government approvent securities
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('896')}> MC's Circular No.23 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      23-july-2022	
                      </td>
                      <td align="center">
                      Revenue augmentation
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('897')}> MC's Office Order No.05 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      21-july-2022		
                      </td>
                      <td align="center">
                      Municipal Commissioner's Office Order No.05 of 2022-23
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('898')}> MC's Circular No.22 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      22-july-2022		
                      </td>
                      <td align="center">
                      Municipal Commissioner's Circular no 22 of 2022-23
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('899')}> MC's Circular No.21 of 2022-23 </a>
                      </td>
                      <td align="center">
                      22-july-2022		
                      </td>
                      <td align="center">
                      Municipal Commissioner's Circular no 21 of 2022-23

                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('900')}> MC's Circular No.20 of 2022-23</a>
                      </td>
                      <td align="center">
                      20-july-2022			
                      </td>
                      <td align="center">
                      New Godown for Storage of records at Bhatchala
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('899')}>MC's Office Order No.04 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      16-july-2022			
                      </td>
                      <td align="center">
                      Display of unauthorised/illegal/litigated buildings on KMC website MC OfficeOrder No. 04 of 2022-23
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('900')}> MC's Office Order No.03 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      07-july-2022			
                      </td>
                      <td align="center">
                      Up to date assessment prior to issue of no ducs certificate/no objection certificate from the Assessment Collection Department
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('901')}> MC's Office Order No.02 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      16-june-2022	
                      </td>
                      <td align="center">
                      Prevention of electrocution caused from electrical infrastructure
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('902')}> MC's Circular No.19 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      09-july-2022			
                      </td>
                      <td align="center">
                      Office procedure
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('903')}> MC's Circular No.18 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      07-july-2022			
                      </td>
                      <td align="center">
                      Detrmination and fixation of Holiday allowance / Overtime / tiffin Allowance/ Night allowance of eligible employees and mazdoors of K.M.C.
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('904')}> MC's Circular No.17 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      07-july-2022			
                      </td>
                      <td align="center">
                      MNo replacements of WBUES cmployees without prior approval of Hon'ble MMIC (WBUES)
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('905')}> MC's Circular No.16 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      07-july-2022			
                      </td>
                      <td align="center">
                      Municipal Commissioner Circular's No. 16 of 2022-23
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('906')}> MC's Circular No.15 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      06-july-2022			
                      </td>
                      <td align="center">
                      Municipal Commissioner Circular's No. 15 of 2022-23
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1907')}> MC's Circular No.14 of 2022-23		 </a>
                      </td>
                      <td align="center">
                      30-june-2022				
                      </td>
                      <td align="center">
                      Proper planning for timely procurement/ execution of works</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1908')}> MC's Circular No.13 of 2022-23		 </a>
                      </td>
                      <td align="center">
                      29-june-2022				
                      </td>
                      <td align="center">
                      Periodic Checking of KELLP Work by respective Borough (Engineering),S&D department and Water Supply Department

                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('909')}> MC's Circular No.12 of 2022-23		 </a>
                      </td>
                      <td align="center">
                      14-june-2022				
                      </td>
                      <td align="center">
                      Punctual attendance in office
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('910')}> MC's Circular No.11 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      13-june-2022				
                      </td>
                      <td align="center">
                      Deletion of duplicate/physically non -existent Assessee No.and consequential wrong demand
                     </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('911')}> Clarification to MC's Circular No.09 of 2022-23 </a>
                      </td>
                      <td align="center">
                      11-june-2022				
                      </td>
                      <td align="center">
                      Clarification Municipal Commissioner's Circular No. 09 of 2022-23
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('911')}> MC's Office Order No.01 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      10-june-2022				
                      </td>
                      <td align="center">
                      Inspection of KMC offices
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('912')}> MC's Circular No.09 of 2022-23		 </a>
                      </td>
                      <td align="center">
                      09-june-2022				
                      </td>
                      <td align="center">
                      Usage & Consumption of fuel of hired cars
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('913')}> MC's Circular No.08 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      01-june-2022				
                      </td>
                      <td align="center">
                      Owning -up the e-Tendering Processes By the Department of the KMC
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('914')}>MC's Circular No.07 of 2022-23	</a>
                      </td>
                      <td align="center">
                      28-May-2022				
                      </td>
                      <td align="center">
                      Revenue Mobilization- Recovery of outstanding property tax
                     </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('915')}> MC's Circular No.06 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      10-May-2022				
                      </td>
                      <td align="center">
                      Municipal Commissioner's Circular No. 06 of 2022-23
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('916')}> MC's Circular No.05 of 2022-23	 </a>
                      </td>
                      <td align="center">
                      08-May-2022				
                      </td>
                      <td align="center">
                      Emergency arrangements to be made due to the forecast of cyclonic storm.
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('917')}> MC's Circular No.04 of 2022-23 </a>
                      </td>
                      <td align="center">
                      25-April-2022				
                      </td>
                      <td align="center">
                      PRIOR APPROVAL FROM HON'BLE MMIC (W.BU.E.S.) RAG. REPLACEMENT OF 100- DAYS WORKER WORKING UNDER WESTBENGAL URBAN EMPLOYMENT (W.B.U.E.S.)
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('918')}> MC's Circular No.03 of 2022-23 </a>
                      </td>
                      <td align="center">
                      23-April-2022				
                      </td>
                      <td align="center">
                      EMERGENCY ARRANGEMENT DURING THE ENSUING NOR'WESTER/RAINYSEASION IN THE NEXT FEW MONTHS OF2022
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('919')}> MC's Circular No.02 of 2022-23	</a>
                      </td>
                      <td align="center">
                      05-April-2022				
                      </td>
                      <td align="center">
                      Municipal Commissioner's Circular No. 02 of 2022-23
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td  align="center">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('920')}> MC's Circular No.01 of 2022-23	</a>
                      </td>
                      <td align="center">
                      01-April-2022				
                      </td>
                      <td align="center">
                      Municipal Commissioner's Circular No. 01 of 2022-23
                      </td>
                    </tr>
                    </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>
  )
}

export default MC_Circular_2022_23