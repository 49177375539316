import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Heritage_Contact_Details  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
   
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> {t("Ch VS Officials")} </h4>
        </div>
      </div>
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
            <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/HeritageBuildingHome'> {t("Heritage Home")} </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Exemption_of_Tax'> {t("Exemption of Tax")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Heritage_Buildings'> {t("Heritage Buildings")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Conservation_Architect'> {t("Conservation Architect")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('473')}>{t("Composition of the Heritage Conservation Committee")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Proceedings_of_HCC'> {t("Proceedings of HCC")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('474')}>{t("Action Plan")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('475')}>{t("Parking Policy")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('476')}>{t("Measures for prevention of landfill fire")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWisePond.jsp'>  {t("Ward wise Pond")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWiseHeritageBuilding.jsp'> {t("Ward Wise Heritage Building")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Environment_Heritage_Dept'> {t("Contact Details")} </Link>
                </li>

                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Activities_NCAP.pdf' target={'_blank'}> {t("Activities Permitted under NCAP")} </a>
                </li>

                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Swachh Vayu Survekshan")} <i className="bi bi-chevron-down" /></button>
                    <div id="demo2" className="collapse">
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessed_CPCB_scores_23_09_2023.xlsx'>1. {t("Self assessed scores & CPCB scores")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessment_reports_Kolkatacity_23_09_2023.pdf'>2. {t("Self assessment reports of Kolkata city2023")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Swachh_Vayu_Survekshan_23_09_2023.pdf'>3. {t("Swachh Vayu Survekshan 2023 Rank")} </a>
                    </div>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/airquality/citizenreport.do' target={'_blank'}> {t("Air quality status")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/emis/category.do' target={'_blank'}> {t(" Industry categorisation")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/District_Environment_ KMC2021_23_09_2023.pdf' target={'_blank'}> {t("FINAL District Environment plan KMC 2021")} </a>
                </li>


              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="">
            <div className="row">
              <div className="inner-title2" style={{marginLeft:"10px"}}>
                <h3 className="fontSizeClass">
                  
                  {t("Directory of Officers & Employees Under Environment & Heritage Department")}
                </h3>
              </div>
              <div className="mayor-table no-scroll-table">
                <table className="table table-bordered officials rm-mg">
                  <tbody>
                    <tr bgcolor="#302ba0" tabIndex={0}>
                      <td
                        width="10%"
                        rowSpan={2}
                        className="text-white tble-brder-left"
                        align="center"
                        valign="middle"
                      >
                        {t("Sl. No")}.
                      </td>
                      <td
                        width="27%"
                        rowSpan={2}
                        align="center"
                        className="text-white"
                      >
                        {t("Name")}
                      </td>
                      <td
                        width="27%"
                        rowSpan={2}
                        align="center"
                        className="text-white"
                      >
                        {t("Designation")}
                      </td>
                      <td
                        colSpan={2}
                        rowSpan={2}
                        align="center"
                        className="text-white tble-brder-right"
                      >
                        
                        {t("Mobile No")}.
                      </td>
                    </tr>
                    <tr>
                      {/* <td width="20%" style="padding:3">Office</td>
                  <td width="13%" style="padding:3">Resi.</td> */}
                    </tr>
                    <tr align="center" valign="middle" tabIndex={0}>
                      <td>1.</td>
                      <td align="left">{t("Anindya karforma")} </td>
                      <td align="left">{t("Director General")} </td>
                      <td align="center">9830324310</td>
                    </tr>
                    <tr align="center" valign="middle" tabIndex={0}>
                      <td>2.</td>
                      <td align="left">{t("Partha sarathi samanta")} </td>
                      <td align="left">{t("Dy.chief engineer(civil)")} </td>
                      <td align="center">9831267462</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>3.</td>
                      <td align="left">{t("Kalyan Das")} </td>
                      <td align="left">{t("Executive engineer(civil)")}</td>
                      <td align="center">9831518635</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>4.</td>
                      <td align="left">{t("Arup Sarkar")} </td>
                      <td align="left">{t("Executive engineer(civil)")} </td>
                      <td align="center">9433722182 </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>5.</td>
                      <td align="left">{t("Chandan banerjee")} </td>
                      <td align="left"> {t("Assistant engineer(civil)")} </td>
                      <td align="center">8334905588</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>6.</td>
                      <td align="left">{t("Dulal Saha")} </td>
                      <td align="left"> {t("Assistant engineer(civil)")} </td>
                      <td align="center">7595986495</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>7.</td>
                      <td align="left">{t("Debasish karmakar")} </td>
                      <td align="left">{t("Assistant engineer(civil)")} </td>
                      <td align="center">9433656178</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>8.</td>
                      <td align="left">{t("Manoj kumar kundu")} </td>
                      <td align="left">{t("Assistant engineer(civil)")} </td>
                      <td align="center">9433295737</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>9.</td>
                      <td align="left">{t("Kingshuk barman")} </td>
                      <td align="left">{t("Assistant engineer(civil)")}</td>
                      <td align="center">7076587055</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>10.</td>
                      <td align="left">{t("Lalu Lohar")} </td>
                      <td align="left">{t("Sub assistant engineer(civil)")} </td>
                      <td align="center">9804794620</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>11.</td>
                      <td align="left">{t("Soma Sarkar")} </td>
                      <td align="left">{t("Sub assistant engineer(civil)")} </td>
                      <td align="center">9733503353</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>12.</td>
                      <td align="left">{t("Sumar Mitra")} </td>
                      <td align="left">{t("Sub assistant engineer(civil)")} </td>
                      <td align="center">8348459597</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>13.</td>
                      <td align="left">{t("Priya Paul")} </td>
                      <td align="left">{t("Sub assistant engineer(civil)")} </td>
                      <td align="center">8240871243</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>14.</td>
                      <td align="left">{t("Md. shahrukh alam")} </td>
                      <td align="left">{t("Sub assistant engineer(civil)")}</td>
                      <td align="center">6294518005</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>15.</td>
                      <td align="left">{t("Subham maity")} </td>
                      <td align="left">{t("Sub assistant engineer(civil)")} </td>
                      <td align="center">7001891863</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>16.</td>
                      <td align="left">{t("Debasis kar")} </td>
                      <td align="left">{t("Assistant Manager")} </td>
                      <td align="center">7980546580</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Heritage_Contact_Details