import { textAlign } from '@mui/system';
import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Concept_Note = () => {
  const { t, i18n, ready } = useTranslation();
    const increaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
          var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
          var fontSize = parseFloat(style); 
          el[i].style.fontSize = (fontSize + 1) + 'px';
        }
      };
      const decreaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
        var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
        var fontSize = parseFloat(style); 
        el[i].style.fontSize = (fontSize - 1) + 'px'; 
        }
      };
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">           
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Disaster_Mgmt'> {t("Disaster Mgmt")} </Link> </li>
                  <li className="nav-item"><Link class="nav-link active1" to='/Concept_Note' target="_blank"> {t("Concept Note")}</Link> </li>  
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Guidelines_for_Safety'> {t("Guidelines for Safety")} </Link> </li>  
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Form_For_Inventory'> {t("Form For Inventory")} </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/School_Safety_Booklet'> {t("School Safety Booklet")} </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Colouring_Activity_Book'> {t("Colouring Activity Book")}</Link> </li>  
    
                </ul>
              </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="">
              <div className="inner-title"><h3 className="fontSizeClass"> {t("Concept Note on School Safety")} </h3>
                <div className="about-head1">
                    <h4 className="fontSizeClass"> {t("CONCEPT NOTE ON DISASTER MANAGEMENT ACTIVITIES IN SCHOOLS")} </h4>
                </div>
              </div>
              <div>

              </div>
              <div className='fontSizeClass'>
              <p className='fontSizeClass' style={{fontSize:"13px",textAlign:"justify"}}>{t("The Indian sub-continent is highly prone to natural disasters. Floods, Wind, Cyclones, Droughts and Earthquakes are recurrent phenomena. As per the latest damage risk zoning map of Bureau of Indian Standards (BIS) over 65% area of India is prone to earthquakes of considerable intensities. Some of the most intense earthquakes have occurred in India and its adjacent areas in the past. India has highly populated mega cities including the capital in New Delhi situated in zones of high seismic risk. Geologically Kolkata falls within Moderate Earthquake Damage Risk Zone (Zone-III) within very close vicinity of High Earthquake Damage Risk Zone (Zone-IV) of North and South 24 Parganas districts. The same area falls under Very High Wind and Cyclone Damage Risk Zone also. Over and above this, some human induced factors as unsafe buildings, unplanned urbanization, dense population and habitats have increased its vulnerability to disasters to considerable extent. Apart from natural disasters, human induced disasters like fire, gas leak, flood due to blockage of drainage system, terrorist attack, accidents etc. are common in urban areas")}.</p>
              <p className='fontSizeClass'style={{fontSize:"13px",textAlign:"justify"}}>{t("Disasters lead to erosion of development gains and restricted options for the affected people. Physical safety - especially that of the vulnerable groups, which include children, old and physically handicapped people, is threatened. Orissa cyclone of 1999, Gujarat earthquake of 2001 and the Tsunami of 2004 have very clearly illustrated that we need pre-disaster mitigation, preparedness and response systems at all levels (including schools) so that the threat to human lives and property is minimized")}.</p>
              <p className='fontSizeClass'style={{fontSize:"13px",textAlign:"justify"}}>{t("Schools are the most important institutions influencing development of a country and so damage/loss of lives or property of schools badly affects the development of a whole nation. Students are the future citizens of the country and the teachers are makers of a nation. Both are vulnerable to great physical and psychological sufferings caused by a disaster - natural or human induced. Most of the schools operating in urban areas are built in congested places and many of those have structural inadequacies. These factors combined with ignorance and lack of preparedness measures can have disastrous consequences in the event of earthquake in this region. Interventions to reduce such vulnerabilities have been made by DRMP under its School safety programme, the goal of which is to promote a culture of disaster preparedness in schools")}.</p>
              <p className='fontSizeClass'style={{fontSize:"13px",textAlign:"justify"}}>{t("The Disaster Risk management Programme (DRMP) is a national initiative jointly launched by Govt. of India and United Nations Development Programme (UNDP) to reduce vulnerabilities of communities in some of the most hazard prone areas of India (17 states and 169 districts). The programme aims to contribute to the social and economic development goals of the national and state governments, enable them to minimize losses to development gains and to reduce their vulnerability to disasters. For sustainability, it relies upon a community based approach and seeks to build capacities of all sections of the society and all the other stake holders in disaster management")}.</p>
              <p className='fontSizeClass'style={{fontSize:"13px",textAlign:"justify"}}>{t("The URBAN EARTHQUAKE VULNERABILITY REDUCTION PROGRAMME (UEVRP), a sub-component programme of DRMP for urban areas, is being implemented by the city municipal authorities under state government monitoring in 38 populous vulnerable cities in India including Kolkata and Asansol in West Bengal with the objective of sustainable reduction in disaster risks (with focus on earthquake) across the country")}.</p>
              </div>    <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="heading1">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                    {t("UEVRP envisages the following broad activities")}:-
                    </button>
                    </div>
                    <div id="collapse1" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p>1. {t("Awareness Generation")}.<br />
                            2. {t("Development of Preparedness and Response Plans at the Community as well as Administrative levels")}.<br />
                            3. {t("Training and Capacity Building at all levels")}.<br />
                            4. {t("Development of Techno-Legal Regime (e.g. Amendment of Building Rules, Guidelines etc.)")}.<br />
                            5. {t("Networking of Knowledge and Best Practices both Nationally and Internationally")}.</p>
                          </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading2">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                      {t("The step-wise programmed activities for School Safety are")} :-
                    </button>
                    </div>
                    <div id="collapse2" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p><strong style={{textAlign:"justify"}}>I. {t("Centralized activity")} :-</strong>{t("Awareness Training of School Teachers (one/two representing each School depending on the number of students and staff in the school) to be organized by the Kolkata Municipal Corporation")}.</p>
                        <p><strong style={{textAlign:"justify"}}>II. {t("Decentralized Activities (for each school)")} :-</strong>{t("Following activities are to be initiated, organized and coordinated in a school by the teacher/staff who represented that school in the above centralized awareness meeting. In fact, proper implementation of the school safety programme has been materialized in many schools without making hindrance to their normal functioning")}.</p>
                         
                      </div>                      
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading3">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                      {t("DISASTER PREPAREDNESS AND MITIGATION ACTIVITIES IN A SCHOOL (Stepswise)")} :-
                    </button>
                    </div>
                    <div id="collapse3" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p style={{textAlign:"justify"}}>{t("Sensitisation meeting for awareness of Teachers, Management, Staff and Students of School")}.<br />
                            {t("Formation of Disaster Management Committee in each school")}.<br />
                            {t("Hazard identification and Safety Assessment")}.<br />
                            {t("Making Inventory of resources")}.<br />
                            {t("Mapping and Preparation of Disaster Management Plans for School involving and showing coordination with all emergency services (e.g. Municipal Corporation, Fire, Civil Defence, Police, Health, Local NGOs/CBOs etc.)")}.<br />
                            {t("Formation of School Disaster Management Teams consisting of senior class students (students excluded for primary schools), teaching and non-teaching staff")}.<br />
                            {t("Training and capacity building of School Disaster Management Teams in emergency Evacuation, Search and Rescue, Fire Fighting, basic First Aid etc")}.<br />
                            {t("Dissemination of School DM Plan to everybody in the School (also to the guardians)")}.<br />
                            {t("Mock drill at regular intervals to keep practice")}.<br />
                            {t("Adoption of Structural and Non-Structural Mitigation measures")}.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                      {t("Target primary schools and teachers")}
                    </button>
                    </div>
                    <div id="collapse4" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p style={{textAlign:"justify"}}>{t("As per first phase target, one teacher from each of the 242 Primary Schools as well as 100 Shishu Siksha Kendras (SSK) run by the Kolkata Municipal Corporation will be sensitized. This will be followed by hands on training (3-days duration) on School Disaster Management for 100 teachers of 100 primary schools")}.</p>
                    </div>
                  </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading5">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                      {t("OUTCOMES")}
                    </button>
                    </div>
                    <div id="collapse5" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p>{t("After proper implementation of the School Safety (Disaster management) Programme, it is expected that the schools will be much equipped to mitigate disaster with sound structural and non-structural arrangements and efficiently cope up with any disaster situation collectively in a well planned, disciplined, well organized manner")}.</p>
                      </div>
                      </div>
                    </div>                  
                  </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
    {/*   */}
  </section>
  
    </>
  )
}

export default Concept_Note