import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Assessment_collection_Check_Payment_Status  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <section className="section inner-pad1">
  <div className="container " >
    <div className="row">
      <div className="col-lg-3">
      <div className="site-menu   min-height-400">
            <nav className="sidebar card py-2 mb-4">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_Collection_Assessment_Home'>  {t("Assessment Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_Process_Muation'> {t("Process of Mutation")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_Collection_Unit_Area_Assessment'> {t("Unit Area Assessment")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('289')}> {t("Tax Rates / Fees")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_download_forms'> {t("Download Forms")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_Message_Board'> {t("Message Board")}  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_Notice'> {t("Notice")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_Search_Hearing_Notice'>  {t("Check Hearing Notices")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/DynAssessment_collection_Check_Mutaion_Status'> {t("Check Mutation Status")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_Dwonload_NOC'> {t("Download NOC(A.C)")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Assessment_collection_Check_Payment_Status'> {t("Check Payment Status")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_Check_Demands_Payable'> {t("Check Demands Payable")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_collection_Application_uptp_intrest_waiver'> {t("Application for Upto 50% Intrest Waive")} </Link>
                </li>
                <li className="nav-item">
                        <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Print Unpaid Bill")}<i className="bi bi-chevron-down" /></button>
                        <div id="demo" className="collapse">                        
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAsmtAllUnpaidDemandPrint.jsp">1. {t("All Bill")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/PDDemandPrint.jsp">2. {t("Current PD")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/FSDemandPrint.jsp">3. {t("F/S Bill")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDemandPrint.jsp">4. {t("Outstanding LOI")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDemandWaiverFiftyPrint.jsp">5. {t("Upto 50% Interest Waive LOI")}</a>
                        </div>
                </li>
                <li className="nav-item">
                        <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("Make Online Payment")}<i className="bi bi-chevron-down" /></button>
                        <div id="demo1" className="collapse">                        
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssesmentAllUnpaidDemand.jsp">1. {t("All Bill")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssesmentLoiWaiverFiftyPayment.jsp">2. {t("Upto 50% Interest Waive LOI")}</a>
                          <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/MiscellaneousSuspensePay.jsp">3. {t("Suspense")}</a>
                        </div>
                </li>
                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Reprint e-Receipt")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo2" className="collapse">                        
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssessmentCombinedReceiptReprint.jsp">. {t("All Bill")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/PDDuplicateReceipt.jsp">2. {t("Current PD, F/S")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDuplicateReceipt.jsp">3. {t("Outstanding LOI")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIDuplicateReceipt.jsp">4. {t("Waiver 2020 Receipt")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/MiscSuspDuplicateReceipt.jsp">5. {t("Suspense Receipt")}</a>
                      </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AssesseeInformationSearch.jsp'> {t("Assessment Information Search")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AssessmentReportPrint.jsp'>{t("Assessment Register")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Apply for Mailing Address Change")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Mutation Application")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('317')}> {t("Help for Online Mutation Application")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('318')}> {t("Litigated Property")} </a>
               </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Assessment_Collection_FAQ'> {t("FAQs")} </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Assessment_Dept'> {t("Contact Details")} </Link>                </li>
              </ul>
            </nav>
          </div>
      </div>
      <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass">{t("List Of Property Tax Demands")} </h3>
            </div>
            <div className="about-head1">
              <div className="dig-login1 dig-login2">
                <form>
                  <div className="log-form inner-log-form">
                    <h3 className="fontSizeClass"> {t("Payment Status")} </h3>
                    <div className="row">
                      <div className="col-lg-6 col-md-12 form-group pt-2">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          
                          {t("Assessee No")}*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="e.g. 141320100129"
                          aria-label="Username"
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 form-group pt-2">
                        <div className="form-group continu align-items-center">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            
                            &nbsp;
                          </label>
                          <button className="btn btn-primary" type="button" style={{marginTop:"40px"}}>
                            {t("Search")} </button>
                        </div>
                      </div>
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        
                        &nbsp;
                      </label>
                      <div className="form-p-text">
                        <p className="fontSizeClass">
                          <b>{t("PD Bill")} :</b> {t("Periodic Demand bills, which are issued annually, based on the last decided valuation of the property")}
                        </p>
                        <p className="fontSizeClass">
                          <b>{t("F/S Bill")} :</b> {t("Fresh/Supplementary bills, which are issued immediately after a hearing to reflect any changes to the earlier issued bills. Fresh bills are also issued after the first assessment of a property")}
                        </p>
                        <p className="fontSizeClass">
                          <b>LOI : </b>{t("Letter of Intimation is issued against outstanding tax bills (PD or/and F/S)")}
                        </p>
                        <ul>
                          {t("LOI contains the following tax bills")}:
                          <br />
                          <li>{t("All unpaid F/S bills which attract penalty")} </li>
                          <li>
                            {t("All unpaid PD bills which were issued prior to the current financial year")}
                          </li>
                          {t("Such outstanding bills can only be paid against the LOI")}.
                        </ul>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Assessment_collection_Check_Payment_Status