import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const CH_VS_Dept_Download_Forms = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const [ReprintServices, setReprintServices] = React.useState(false);

  const ReprintServicesToggle = () => {
    setReprintServices(!ReprintServices);
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>      
      <div className="carousel-item active">
  <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      </div>
    </section>
  
    <section className="section inner-pad1">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="site-menu">
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item">
                    <Link className="nav-link active1 fontSizeClass" to='/CH_VS_Dept_Download_Forms'> {t("Download Forms")} </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Ch_VS_Dept_Rates_Charges'> {t("Fees & Charges")} </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardwiseSmartMap.jsp' target='_blank'> {t("Online Smart Map")} </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/SorDemandPrint.jsp' target='_blank'>{t("Print Unpaid Bill")} </a>
                  </li>
                  
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/SorDemandPayment.jsp' target='_blank'>{t("Make Online Payment")} </a>
                  </li>
                  <li className="nav-item ">
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Reprint e-Receipt")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo" className="collapse">                        
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/SorDuplicateReceipt.jsp' target='_blank'>1. {t("SOR")} </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/SorDevDuplicateReceipt.jsp' target='_blank'>2. {t("Development Fee")} </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/SorGisMapDuplicateReceipt.jsp' target='_blank'>3. {t("GIS Map")}  </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/SorLeaseRentDuplicateReceipt.jsp' target='_blank'>4. {t("Less/Rent/License")}  </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/SorMiscDuplicateReceipt.jsp' target='_blank'>5. {t("Miscellanous")}  </a>
                      </div>
                </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Ch_VS_Dept'> {t("Contact Details")} </Link>
                  </li>
                  <li className="nav-item">
                  <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('446')}> {t("Public Notice Modification Cancellation of Alignment")} </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2">
                  <h3 className="fontSizeClass"> {t("Download Forms")} </h3>
                </div>
                <div className="about-head1">
                  <div className="dig-login1 dig-login2">
                    <form>
                      <div className="log-form inner-log-form">
                        <h3 className="fontSizeClass"> {t("List of Forms")} </h3>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 form-group pt-2">
                            <div className="list-of-forms">
                              <h6 className="fontSizeClass"><a className="fontSizeClass" onClick={(e)=>fileDownload('447')}> 1. {t("Development Fees Application Form")} </a> </h6>
                              <h6 className="fontSizeClass"><a className="fontSizeClass" onClick={(e)=>fileDownload('448')}> 2. {t("Survey Observation Report (SOR) Application Form")} </a> </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  
  )
}

export default CH_VS_Dept_Download_Forms