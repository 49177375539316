import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const History_Of_Kolkata = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const [ProjectService, setProjectService] = React.useState(false);
  const [MoreOneService, setMoreOneService] = React.useState(false);
  
  const ProjectServiceToggle = () => {
    setProjectService(!ProjectService);
  };
  const MoreOneServiceToggle = () => {
    setMoreOneService(!MoreOneService);
  };

  const { t, i18n, ready } = useTranslation();
  return (
    <>
          {/* Slider */}
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"> <h4 className="fontSizeClass"> {t("History Of Kolkata")} </h4></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  ">           
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">

                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/About_Kolkata'> {t("About Kolkata")} </Link> </li>
                <li className="nav-item"><Link className="nav-link act" to='/map'> {t("Map")} </Link></li>
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/History_Of_Kolkata'> {t("History")}</Link></li>
                <li className="nav-item">
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Places of Interest")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo2" className="collapse">                        
                        <Link className="nav-link fontSizeClass" to='/Art_Galleries'> Art Galleries</Link>
                        <Link className="nav-link fontSizeClass" to='/Amusement_Parks'> Amusement Park </Link>
                        <Link className="nav-link fontSizeClass" to='/Architecture'> Architecture </Link>
                        <Link className="nav-link fontSizeClass" to='/Other_Noteable_place'> Other Notable Places </Link>
                     </div>
                    </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Basic_statestics'> {t("Basic Statistics")} </Link> </li>  
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/UEVRP_Home'> UEVRP </Link></li>   

              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2"><h3 className="fontSizeClass">{t("History_of_Kolkata")}</h3></div>
              <div className="col-lg-12 about-head1 ">
                <p className="fontSizeClass"> {t("History_of_Kolkata_text")}</p>
                <div className="more-but history_of_kolkata">			
                      <button type="button" className="btn btn-primary mt-2" data-toggle="collapse" data-target="#demo1">{t("More")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo1" className="collapse">                        
                        <p className="fontSizeClass">{t("The old Fort William was built to protect the English post in 1696. The city became famous in 1756, in England particularly, when Siraj-ud-Dawlah, a Bengal ruler, captured the fort and, according to British historians, stifled to death 43 British residents in a small guardroom called the Black Hole of Kolkata")}.</p>
                        <p className="fontSizeClass"> {t("The city was recaptured by the British under Robert Clive in 1757. The English initially built an intricate transport network through the Hugli - Ganges water system, but it was the railroads, introduced in the 1850s, that successfully established connections with the hinterland and the rest of India. The city eventually had the largest concentration of trading establishments in India, and a Western-style business district evolved by the end of the 19th century. The colonial city maintained a strict division between the crowded and ill-planned native quarters to the east and north of the Central Business District, and the spacious and well-planned quarters where the Europeans lived in the south and southeastern parts of the old city. After independence, the former European quarters were either turned into residences of the Indian rich or, as in the Park Street area, into commercial areas")}.</p> 
                      </div>
                </div>			
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  </section>

    </>
  )
}

export default History_Of_Kolkata