import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Building_Sanction_Circular_2017_18  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
      
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2 mb-4">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2022_23'> {t("BLDG's Circular No.of 2022-23")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2021_22'> {t("BLDG's Circular No.of 2021-22")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2020_21'> {t("BLDG's Circular No.of 2020-21")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2019_20'> {t("BLDG's Circular No.of 2019-20")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Building_Sanction_Circular_2017_18'>{t("DG(BLDG)'s Circular No.of 2017-18")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2016_17'> {t("DG(BLDG)'s Circular No.of 2016-17")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2015_16'> {t("DG(BLDG)'s Circular No.of 2015-16")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2014_15'>{t("DG(BLDG)'s Circular No.of 2014-15")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2013_14'>{t("DG(BLDG)'s Circular No.of 2013-14")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2011_12'> {t("DG(BLDG)'s Circular No.of 2011-12")}  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2010_11'> {t("DG(BLDG)'s Circular No.of 2010-11")} </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Circular")} :: 2017-18</h3>
              </div>
              <div className="mayor-table building-dept-table">
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials">
                      <tbody>
                    <tr bgcolor="#302ba0" align="center" valign="middle">
                      <td width="34%" className="text-white tble-brder-left">
                        {t("Circular No")}
                      </td>
                      <td width="17%" className="text-white">
                        {t("Date")}
                      </td>
                      <td width="49%" className="text-white tble-brder-right">
                        {t("Subject")}
                      </td>
                    </tr>
                    <tr tabIndex={0}>
                      <td valign="middle">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('369')}> {t("DG(BLDG)'s Circular No. 10 of 2017-18")} </a>
                      </td>
                      <td valign="middle" align="center">
                        15/03/2018
                      </td>
                      <td valign="middle">
                        {t("Workshop on Energy Conservation Building Code-ECBC Circular No. 10 of 2017-18")}
                      </td>
                    </tr>
                    <tr tabIndex={0}>
                      <td valign="middle">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('370')}> {t("DG(BLDG)'s Circular No. 09 of 2017-18")} </a>
                      </td>
                      <td valign="middle" align="center">
                        28/02/2018
                      </td>
                      <td valign="middle">
                        {t("Verification of permissible height as required by the AAI from latest version of CCZM Circular No. 09 of 2017-18")}
                      </td>
                    </tr>
                    <tr tabIndex={0}>
                      <td valign="middle">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('371')}> {t("DG(BLDG)'s Circular No. 08 of 2017-18")} </a>
                      </td>
                      <td valign="middle" align="center">
                        26/12/2017
                      </td>
                      <td valign="middle">
                        {t("Online Notice of Commencement and CC for Br VI and VII from 02012018 Circular No. 08 of 2017-18")}
                      </td>
                    </tr>
                    <tr tabIndex={0}>
                      <td valign="middle">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('372')}> {t("DG(BLDG)'s Circular No. 07 of 2017-18")} </a>
                      </td>
                      <td valign="middle" align="center">
                        13/12/2017
                      </td>
                      <td valign="middle">
                        {t("Original green certificate has to be submitted Circular No. 07 of 2017-18")}
                      </td>
                    </tr>
                    <tr tabIndex={0}>
                      <td valign="middle">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('373')}> {t("DG(BLDG)'s Circular No. 06 of 2017-18")} </a>
                      </td>
                      <td valign="middle" align="center">
                        13/10/2017
                      </td>
                      <td valign="middle">
                        {t("Completion plan for partial & Block building Circular No. 06 of 2017-18")}
                      </td>
                    </tr>
                    <tr tabIndex={0}>
                      <td valign="middle">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('374')}> {t("DG(BLDG)'s Circular No. 04 of 2017-18")} </a>
                      </td>
                      <td valign="middle" align="center">
                        01/08/2017
                      </td>
                      <td valign="middle">
                        {t("Building Plan in Small Plot and Small Scale Industrial Building with less open space Circular No. 4 of 2017-18")}
                      </td>
                    </tr>
                    <tr tabIndex={0}>
                      <td valign="middle">
                      <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('375')}> {t("DG(BLDG)'s Circular No. 02 of 2017-18")} </a>
                      </td>
                      <td valign="middle" align="center">
                        01/07/2017
                      </td>
                      <td valign="middle">{t("Section 412A Circular No. 02")}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Building_Sanction_Circular_2017_18