import React from 'react';
import { Link } from "react-router-dom";

const Architecture = () => { 
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };   
  const [ProjectService, setProjectService] = React.useState(false);

  const ProjectServiceToggle = () => {
    setProjectService(!ProjectService);
  };
  return (
    <>  
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
     <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"> <h4 className="fontSizeClass">About_Kolkata </h4></div></div>             
    </div>       	 		 
  </section>	
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  ">           
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/About_Kolkata'> About Kolkata </Link> </li>
                <li className="nav-item"><Link className="nav-link act" to='/map'> Map </Link></li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/History_Of_Kolkata'> History</Link></li>
                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">Places of Interest<i className="bi bi-chevron-down" /></button>
                      <div id="demo2" className="collapse hide">                        
                        <Link className="nav-link fontSizeClass" to='/Art_Galleries'> Art Galleries</Link>
                        <Link className="nav-link fontSizeClass" to='/Amusement_Parks'> Amusement Park </Link>
                        <Link className="nav-link fontSizeClass active1" to='/Architecture'> Architecture </Link>
                        <Link className="nav-link fontSizeClass" to='/Other_Noteable_place'> Other Notable Places </Link>
                      </div>
                </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Basic_statestics'> Basic Statistics </Link> </li>  
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/UEVRP_Home'> UEVRP </Link></li>   
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass">Architecture</h3>
              </div>
                <div className="col-lg-12 about-head1">
                    <h5 className="fontSizeClass"> <strong>Art Galleries / Museums / Libraries</strong> </h5>		   
                    <p className="fontSizeClass">Life never stops in Kolkata. At any time of the year there is something going on - a festival, a wedding, a match, a demonstration, a concert, a vernissage or a party. For some, Calcutta - Kolkata is the city of joy, while for others it is dirty, crowded, and noisy.</p>		
                </div>

                <div className="col-lg-12 p-0 min-height-400" id="accordion">
                  <div className="card">
                    <div className="card-header" id="heading1">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                    Belur Math 
                    </button>
                    </div>
                    <div id="collapse1" className="collapse show" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <img src="assets/img/belur.gif" className="img-fluid mt-2" alt /> 
                        <p className="fontSizeClass mt-4">Though Belur Math is not within Kolkata's periphery, but it must be visited by those who come to Kolkata. It is only 10 Kms. away from the city, situated at the bank of river Ganga. It is the headquarters of Ramakrishna Math and Mission which controls over 100 centres in India and abroad. Opens from 6:30 am to 11 am and from 4 pm to 7:30 pm.</p> 	<br />		 
                        <p className='mt-2'>Location: 10km from Kolkata, 3 km. from Dakshineswar, Howrah. Ph.: 2665 5391</p>
                        </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading2">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                      Birla Mandir </button>
                    </div>
                    <div id="collapse2" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">A temple with high quality Rajasthani engravings with an all marble structure is the latest addition to the places of visit in Kolkata. It took twenty-two years of painstaking labour to build this splendid & imposing structure in white which attracts huge crowd everyday.</p> 	<br />		 
                        <p className='mt-2'>Address: Vishwa Mangal Trust, 29 Ashutosh Choudhury Avenue, Kolkata-19.<br />Ph.: 2475 9717</p>
                       </div>                      
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading3">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                      Chitteswari temple
                    </button>
                    </div>
                    <div id="collapse3" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <p className="fontSizeClass">It is a temple, dated back to the early 17th century, from which Chitpur is often held to take its its name.<br /> Location : Chitpur Road.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                      Dakshineshwar Temple                    </button>
                    </div>
                    <div id="collapse4" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                            <img src="assets/img/dakshin1.gif" className="img-fluid mt-2" alt /> 
                            <p className="fontSizeClass mt-4">Build by Rani Rashmoni in the 19th century, its a place of pilgrimage where Sri Ramakrishna Parmahansa Deva worshipped Goddess Kali and attained salvation. The Ganga (Hooghly River) flows beside the temple. 12 Shiva temples, six on either side of the eastern entrance of the main temple stand enmasse. There is a sort of courtyard behind the Shiva temple. By the side of the Ganga, outside the temple, stands the 'Panchavati' tree, (a cluster of 5 banyan trees). This is the sacred place where Sri Ramakrishna used to meditate and performed severe penances sitting on the 'Panchamundi (5 skulls) Ashana' – a custom which is mandatory for the ' Tantrika ' form of worship.</p> 	<br />		 
                            <p className='mt-2'>Location: Opposite Belur Math, across Ganga, Dakhineswar, Kolkata-35.Ph.: 2553 2222</p>
                      </div>
                  </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading5">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                      Deshbandhu Memorial                    </button>
                    </div>
                    <div id="collapse5" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <p className="fontSizeClass">A mausoleum dedicated to the memory of Deshbandhu Chittaranjan Das.<br />Location: North of Keoratala Ghat, Kalighat.</p>
                      </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading6">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                      Gandhi Ghat                    </button>
                    </div>
                    <div id="collapse6" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <p className="fontSizeClass">Located at Barrackpore, people come here to pay homage to the Father of the Nation, Mahatma Gandhi. His ashes were also immersed here in the river.</p>
                      </div>
                    </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading7">

                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                      Jain Temple                    </button>
                    </div>
                    <div id="collapse7" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <p className="fontSizeClass">Located in Barrakpore,this smarak sangrahalaya holds a library and a museum on the life of Mahatma Gandhi.<br /><br />

                        Address: 14, Riverside Road Barrackpore - 743 101 West-Bengal.<br />
                        Ph: 5600170</p>                      </div>
                    </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading8">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
                      Job Charnock's Mausoleum </button>
                    </div>
                    <div id="collapse8" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">About 15km from Kolkata on the Diamond Harbour Road, this museum contains a small but rich collection of the folk arts and crafts of undivided Bengal.Seminars & demonstrations on folk art & culture are held in the annexe and the grounds of the museum.</p><br /><br />
                        <p className="fontSizeClass">Address: Bratacharigram, Joka South 24 Parganas.</p>    </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading9">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
                    Marble Palace                    </button>
                    </div>
                    <div id="collapse9" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">It is famous for marble walls & floors, antiques, paintings of Ruben, Curios, Marble statues, floor to ceiling mirrors and collection of rare birds. Visiting hours if from 10 am to 4 pm. Monday / Thursday closed.</p><br /><br />
                        <p className="fontSizeClass">Address: 46 Muktaram babu Street. Kolkata 700 007.<br /> ph.: 2239 3310</p>              
              
              </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading10">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse10" aria-expanded="true" aria-controls="collapse10">
                    Missionaries of Charity                    </button>
                    </div>
                    <div id="collapse10" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <p className="fontSizeClass">Started by the Noble Laureate Mother Teresa in the year 1950, the Missionary of Charity serves the poorest of the poor, the destitute, the suffering and the dying.</p><br /><br />
                      <p className="fontSizeClass">Address: 54A A.J.C.Bose Road. Kolkata - 700017.<br /> Ph.: 2244 7115</p>              
                                    </div>
                    </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading11">
                      
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                      Nakhoda Mosque                    </button>
                    </div>
                    <div id="collapse11" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">One of the most remarkable places to visit in Kolkata is the Nakhoda Masjid. This mosque had been built in imitation of the mausoleum of Mughal Emperor Akbar at Secundra by Kuchhi Memon Jamat – a member of a particular sect of the Muslim community in Calcutta. A second school of opinion suggests that Abdur Rahim Osman, a Kachha resident, was the founder of the mosque in the year 1926. About 10,000 men can perform the 'Namaaz' in the stupendous prayer hall of the mosque.</p> 	<br />		 
                        <p className='mt-2'>Location: Rabindra Sarani, Kolkata.</p>   </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading12">
                      
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse12" aria-expanded="true" aria-controls="collapse12">
                      Shovabazar Thakurbari                    </button>
                    </div>
                    <div id="collapse12" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">It is the oldest temple in this area and is still used on religious and social occasions. It was built by Raja Nabakrishna Deb in 1757 for the Clive Lord for his celebration of victory at Palassey. Raja Nabakrishna Deb is believed to have started Kolkata's tradition of public celebration of Durga Puja from here.</p> 	<br />		 
                        <p className='mt-2'>Location: Shovabazar Street.</p>                    </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading13">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse13" aria-expanded="true" aria-controls="collapse13">
                      Saheed Minar                    </button>
                    </div>
                    <div id="collapse13" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <img src="assets/img/monument.gif" className="img-fluid mt-2" alt /> 
                        <p className="fontSizeClass mt-4">A 48.16 mt. monument in the syle of Qutab Minar of Delhi, erected in 1828 to commemorate the victory of British army under David Octarloni over the Nepal army in 1814. Previously known as Octerloni Monument, the Monument was rechristened as the 'Shahid Minar' to commemorate the Indian freedom fighters, in 1969. Different public/political parties convene meetings, rallies and seminars behind the Shahid Minar at present.</p> 	<br />		 
                        <p className='mt-2'>Location: On Maidan at Esplanade.</p>
                </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading14">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse14" aria-expanded="true" aria-controls="collapse14">
                      St.Andrews Church                    </button>
                    </div>
                    <div id="collapse14" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">It is the only air-conditioned church in the city of Kolkata.</p> 	<br />		 
                        <p className='mt-2'>Address:</p>                    </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading15">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse15" aria-expanded="true" aria-controls="collapse15">
                      ST. John's Church                      </button>
                    </div>
                    <div id="collapse15" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <p className="fontSizeClass">It was constructed in 1784, Job Charnock's tomb is located in the churchyard. It is popularly known as 'The Stone Church'. The floor of the church was made with stones obtained from Ganda. Stones were also obtained from Chunar. The stone tower is 174 ft. high. There is a dome with a pitcher appended to it. There are also the tombs of the daughters of Charnock. Au reste, there are the sculptures of British Admiral Watson who subjugated Calcutta in 1757, Lord Brabourne, Lady Canning and several distinguished persons. Another remarkable attraction of this church is 'The Last Supper', a marvellous painting by Zofanni.</p> 	<br />		 
                      <p className='mt-2'>Location: K.S.Roy Road crossing & Council House in B.B.D.Bag area.</p> </div>
                      </div>
                    </div>

                    <div className="card">
                    <div className="card-header" id="heading16">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse16" aria-expanded="true" aria-controls="collapse16">
                      Tipu Sultan Mosque</button>
                    </div>
                    <div id="collapse16" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <p className="fontSizeClass">Tipu Sultan's Mosque or the Dharamtollah Mosque was constructed by Mohammed, the son of Tipu Sultan in 1842. But there are other factions who credit its construction to Ghulam Mohammed, who built a mosque at Tollygunge, which incidentally bears an uncanny resemblance to the former. The Mosque is double-aisled, with multiple domes and tall corner towers. The rounded arches and meticulous details drawn from classical architecture lends it a subtle European air.</p>
                    </div>
                    </div>

                  </div>

                  <div className="card">
                    <div className="card-header" id="heading17">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse17" aria-expanded="true" aria-controls="collapse17">
                      Victoria Memorial</button>
                    </div>
                    <div id="collapse17" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <p className="fontSizeClass">Built between 1906 and 1921, this dream structure of white marble is architecturally an exquisite blending of the Oriental and Occidental. This is still one of India's most beautiful monumental legacies of the Raj. The memorial was the brain-child of Lord Curzon, who in 1901, felt that the late Empress deserved a suitable monument to her memory. It was formally inaugurated by the Prince of Wales in 1921. It is surrounded by a beautiful garden. Inside the Victoria Memorial, there is a large bronze statue of the Empress. The memorial faces the Calcutta Maidan. It houses paintings, manuscripts, and other objects of historic value in its Museum and Art Gallery.</p>
                    </div>
                    </div>

                  </div>

                  <div className="card">
                    <div className="card-header" id="heading18">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse18" aria-expanded="true" aria-controls="collapse18">
                      Kalighat Temple</button>
                    </div>
                    <div id="collapse18" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <p className="fontSizeClass">Kalighat is situated on the bank of Adi (old) Ganga in the south-western border of Calcutta. This is a sacred pilgrimage center for the Hindus. It is believed that the swirling wheel of Lord Vishnu had sliced off the corpse of Goddess Sati, when Lord Shiva was performing his menacing dance (Pralay) with her on his shoulders, in a fit of tremendous rage. The slices of her body fell at different places which later turned into pilgrimage centers for the devout Hindus. Kalighat is one such sacred spot where a toe of the Goddess's right foot is believed to have dropped. In the months of Bhadra, Paush and Chaitra (according to Bengali calendar), Kalighat turns into a confluence of devout Bengali and non-Bengali pilgrims.</p>
                    </div>
                    </div>

                  </div>

                  <div className="card">
                    <div className="card-header" id="heading19">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse19" aria-expanded="true" aria-controls="collapse19">
                      Kalighat Temple</button>
                    </div>
                    <div id="collapse19" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <p className="fontSizeClass">Kalighat is situated on the bank of Adi (old) Ganga in the south-western border of Calcutta. This is a sacred pilgrimage center for the Hindus. It is believed that the swirling wheel of Lord Vishnu had sliced off the corpse of Goddess Sati, when Lord Shiva was performing his menacing dance (Pralay) with her on his shoulders, in a fit of tremendous rage. The slices of her body fell at different places which later turned into pilgrimage centers for the devout Hindus. Kalighat is one such sacred spot where a toe of the Goddess's right foot is believed to have dropped. In the months of Bhadra, Paush and Chaitra (according to Bengali calendar), Kalighat turns into a confluence of devout Bengali and non-Bengali pilgrims.</p>
                        <p className="fontSizeClass">Santosh Roy, the descendant of Sabarna Roychowdhury of Barisha, had founded the Kali temple at Kalighat. He constructed this temple in 1809. The Birlas had made the present 'Toran' or arch-portal of the Kali temple. The Nakuleshwar Mandir stands in front of the Kali temple. Tara Singh, a Punjabi merchant had founded this temple. It was built in 1805. Kalighat started to gain prominence simultaneously with the growth and development of Calcutta. During this time, an indigenous canvas art emerged in Kalighat. It became popular as 'Kalighat Pot' (canvas art of Kalighat).</p>
                      </div>
                    </div>

                  </div>

                  <div className="card">
                    <div className="card-header" id="heading20">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse20" aria-expanded="true" aria-controls="collapse20">
                      Thanthania Kalibari</button>
                    </div>
                    <div id="collapse20" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <p className="fontSizeClass">Opinions differ about the date of construction of Thanthania Kalibari. Some say it was built in 1703 whereas some regard it as 1803. On the stone structure in the temple wall is inscribed, '1110' (Bengali calendar). Going by this, the date of the foundation of the temple should be 1703 according to English calendar.Sankar Ghosh was the founder of this temple. The goddess worshipped here is 'Siddheswari Mata'. The temple has an idol of the Mother Goddess. It is very popular and draws innumerable devotees throughout the year.</p>
                      <p className="fontSizeClass">Location : Bidhan Sarani,opposite Vidyasagar College</p></div>
                    </div>

                  </div>

                  <div className="card">
                    <div className="card-header" id="heading21">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse"  data-target="#collapse21" aria-expanded="true" aria-controls="collapse21">
                      Pareshnath Temple</button>
                    </div>
                    <div id="collapse21" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        <img src="assets/img/pareshnath.gif" className="img-fluid mt-2" alt /> 
                        <p className="fontSizeClass mt-4">The tower of the Jain Temple can be seen as soon as one enters Raja Dinendra Street. The temple is accessible through Badridas Temple Road. Pareshnath is worshipped by the Jains. This temple was built in 1867. Ray Badridas Bahadur was its founder. He was a connoisseur of art.</p>
                        <p className="fontSizeClass">There are four temples here. The main temple is dedicated to the 10th Jain Avtaar (incarnation of God) Sri Sital Nath Ji. The gateway of the temple is a pleasant spectacle to behold.</p>
                        <p className="fontSizeClass">Here is a lamp which burns with ghee and is never extinguished. On the south of the temple of Sri SitalaNath Ji stands the temple of Sri Chanda Prabhujidev. Ganeshlal Kapoorchand Jahar constructed it in 1895. There is another temple towards the right where Dadaji Garu and Kushalji Maharaj are worshipped. The 4th temple towards the north, belongs to Mahavira the last guru of the Jains.</p>
                      </div>
                    </div>
                  </div>
                </div>
            </div> 
          </div>
        </div>
        </div>
    </div>
  </section>
    </>
  )
}

export default Architecture