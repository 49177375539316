import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Drainage_Fees_Charges  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          <h4 className="fontSizeClass">  </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Home'>{t("Drainage Home")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageNetworkMap.jsp' target="_blank">
                    {t("Ward wise Drainage Network Map")}   </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Drainage_Fees_Charges'>
                    {t("Fees & Charges")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Drainage_Pumping_station'>
                    {t("Drainage Pumping Station")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/List_of_Sewage_Treatment_Plants'>
                    {t("Sewage Treatment Plants")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageFee.jsp' target="_blank">
                    {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageDemandPrint.jsp' target="_blank">
                    {t("Print Unpaid Bill")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalDrainagePayment.jsp' target="_blank">
                    {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DCDuplicateReceipt.jsp' target="_blank">
                    {t("Reprint e-Receipt")}  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DCDuplicateReceipt.jsp' target="_blank">
                    {t("List of KMC Drainage Activity")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Faqs'> {t("FAQs")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Sewerage_Drainage_Dept'>{t("Contact Details")}</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Rates, Taxes, Fees & Charges for the year 2023 - 2024")} </h3>
              </div>
              <div className="mayor-table pump-list-tble" style={{height:"420px"}}>
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials">
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"
                      height={22}
                    >
                      <td width="10%" className="tble-brder-left text-light">
                        {t("Sl No")}.
                      </td>
                      <td width="50%" className="text-white text-light">
                        <b>{t("Items")}</b>
                      </td>
                      <td width="40%" className="text-white tble-brder-right text-light">
                        <b>{t("Figures in Rs")}.</b>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td colSpan={3}>
                        <b>I. {t("Renting of")}</b>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">1.</td>
                      <td>
                        {t("Jetting Machine / Jetting cum Suction Machine to private parties or outside agencies (per 8 hours or part thereof excluding fuel & operating staff)")}
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">a)</td>
                      <td>{t("For other than Corporation work")}</td>
                      <td>{t("Rs. 43,125/- per shift per day")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">b)</td>
                      <td>{t("For public bodies and Hospitals")}</td>
                      <td>{t("Rs. 28,635/- per shift per day")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">c)</td>
                      <td>{t("For private Hospitals")}</td>
                      <td>{t("Rs. 35,880/- per shift per day")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>&nbsp;</td>
                      <td>
                        {t("In each case in addition to above rate party has to provide 16 Ltrs. Diesel per shift and has to pay following charges also")}.
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">a)</td>
                      <td>{t("DMO")}</td>
                      <td>
                        {t("Rs. 1,047/- per day")} <br />
                        {t("[1 DMO & 3 Helpers are required to operate the machine]")}
                        <br />
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">b)</td>
                      <td>{t("Helper")}</td>
                      <td>
                        {t("Rs. 325/- per day")}
                        <br /> {t("[1 DMO & 3 Helpers are required to operate the machine]")}
                        <br />
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">2.</td>
                      <td>
                        {t("Power Bucket Machine to private parties or outside agencies (per 8 hours or part thereof excluding fuel but including operation staff)")}
                      </td>
                      <td />
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">a)</td>
                      <td>{t("For other than corporation work")}</td>
                      <td>
                        {t("Rs. 17,940/- per day (Rs. 3,680/- to be paid extra as cost of transportation to work site and back if executed by KMC)")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">b)</td>
                      <td>
                        {t("For Corporation work")}
                        <br />
                        {t("(In addition to above, party has to provide 10 Ltrs. diesel per day)")}
                      </td>
                      <td>
                        {t("Rs. 7,245/- per day (Rs. 3,680/- to be paid extra as cost of transportation to work site and back if executed by KMC)")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">3.</td>
                      <td>
                        {t("Gully-pit Emptier machine to private parties or outside agencies (per 8 hours or part thereof excluding fuel but including operation staff) In addition to above rate, party has to provide 16 Ltrs. diesel per shift and has to pay following charges also.")}
                      </td>
                      <td>{t("Rs. 21,563/- per shift per day")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td>&nbsp;</td>
                      <td>
                        {t("In addition to above rate party has to provide 16 Ltrs. diesel per shift and has to pay following charges also")}.
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">a)</td>
                      <td>{t("DMO")}</td>
                      <td>
                        {t("Rs. 1,047/- per day")} <br />
                        {t("[1 DMO & 3 Helpers are required to operate the machine]")}
                        <br />
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">b)</td>
                      <td>{t("Helper")}</td>
                      <td>
                        {t("Rs. 748/- per day")}
                        <br /> {t("[1 DMO & 3 Helpers are required to operate the machine]")}
                        <br />
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">4.</td>
                      <td>
                        {t("Blow Vac Machine to private parties or outside agencies (Per 8 hours or part thereof excluding fuel but including opening staff)")}
                      </td>
                      <td>
                        {t("Rs. 1,22,475/- per shift per day")}
                        <br />
                        {t("(In addition, party has to provide 40 Ltrs. diesel per shift)")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td colSpan={3}>
                        <b>
                          II. {t("Sewerage & Drainage service charges for discharging trade effluent into KMC sewer")}
                        </b>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">1.</td>
                      <td>{t("Consumers enjoying ICI ferrule connection")}</td>
                      <td>{t("25% of the amount of annual water charges")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">2.</td>
                      <td colSpan={2}>
                        {t("Consumers enjoying other than ICI ferrule connection")}
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">i)</td>
                      <td>{t("Up to 10000 Ltrs/day")}</td>
                      <td>{t("Rs. 10670/-")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">ii)</td>
                      <td>{t("Above 10000 Ltr/day but upto 20000 Ltr/day")}</td>
                      <td>{t("Rs. 19965/-")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">iii)</td>
                      <td>{t("Above 20000 Ltr/day but upto 50000 Ltr/day")}</td>
                      <td>{t("Rs. 33275/-")}</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">iv)</td>
                      <td>{t("Above 50000 Ltr/day")}</td>
                      <td>{t("Rs. 660/- for per addl. 100 Ltrs")}.</td>
                    </tr>
                    <tr align="center" valign="top" tabIndex={0}>
                      <td colSpan={3}>
                        <b>
                          III {t("Sewerage & Drainage service charges for Tube well")}
                        </b>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="center">1.</td>
                      <td>{t("Tube well (mm.)")}</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">a)</td>
                      <td>40/50</td>
                      <td>8,600/-</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">b)</td>
                      <td>80</td>
                      <td>16,500/-</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">c)</td>
                      <td>100</td>
                      <td>27,500/-</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">d)</td>
                      <td>150</td>
                      <td>45,100/-</td>
                    </tr>
                    <tr tabIndex={0} align="left" valign="top">
                      <td align="right">e)</td>
                      <td>200</td>
                      <td>1,50,150/-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Drainage_Fees_Charges