import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const MC_Circular_2017_18 = () => {
  const fileDownload=(fileStoreId)=>
        {
          
          let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
           axios
              .post(url,{"fileId": fileStoreId, "userId": "392" })
              .then((res) => {
                  console.log("response is --- ", res);
      
                  if(res && res.data && res.status === 200) {
                    const base64 = res.data[0] && res.data[0].base64_data;
                    const pdfData = 'data:application/pdf;base64,'+base64;
      
                    let html = '<html>' +
                        '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                        '<body>' +
                        '<iframe src="' + pdfData + '"></iframe>' +
                        '</body></html>';
                        console.log(html);
                    let a = window.open();
                    a.document.write(html);
                }
              })
              .catch((e) => {
                  // setLoader(false);
                  console.log("The error is ", e);
              })
        }
  const { t, i18n, ready } = useTranslation();
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
    const [DocumentService, setDocumentService] = React.useState(false);
    const [LicenseService, setLicenseService] = React.useState(false);

    const LicenseServiceToggle = () => {
      setLicenseService(!LicenseService);
    };

    const DocumentServiceToggle = () => {
    setDocumentService(!DocumentService);
    };
  return (
<>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
  <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      </div>
    </section>
    <section className="section inner-pad1">
      <div className="container " >
        <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <button type="button" class="btn nav-link pr-0" data-toggle="collapse" data-target="#demo">{t("Municipal Commissioner CIRCULAR")}<i className="bi bi-chevron-down " /></button>
                    <div id="demo" class="collapse">
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2024_25'> {t("MC's Circular")} 2024-25 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2023_24'> {t("MC's Circular")} 2023-24</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2022_23'> {t("MC's Circular")} 2022-23</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2021_22'> {t("MC's Circular")} 2021-22</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2020_21'>{t("MC's Circular")} 2020-21</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2019_20'>{t("MC's Circular")} 2019-20</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2018_19'> {t("MC's Circular")} 2018-19</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass active1" to='/MC_Circular_2017_18'>{t("MC's Circular")} 2017-18</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2016_17'>{t("MC's Circular")} 2016-17</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2015_16'>{t("MC's Circular")} 2015-16</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2004_05'>{t("MC's Circular")} 2004-05</Link></li>
                    </div>
                  </li><li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Municipal_secretary_circular'>{t("MUNICIPAL SECRETARY'S CIRCULAR")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/CMF_Communique_2022_23'>{t("C M F & A's Circular and Communique - 2022-23")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Chief_Manager_Circular'>{t("Chief Manager (P)'s Circular")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Govt_memo'>{t("Govt Memo")}</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1558')}> {t("West Bengal Right to Public Service Act,2013")} </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1559')}>{t("Guidelines of Section 199(2) of KMC Act,1980 (as amended in 2019)")}  </a>
                  </li>
                  <li className="nav-item-list ">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("COMPENDIUM OF IMPORTANT DEPARTMENTAL CIRCULARS / ORDERS")}<i className="bi bi-chevron-down " /></button>
                      <div id="demo1" className="collapse">                        
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>1. Financial Year 2010-11 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>2. Financial Year 2011-12 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('870')}>3. Financial Year 2012-13 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('925')}>4. Financial Year 2013-14 </a>   
                      </div>
                  </li>
                  
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2 bas">
                  <h3 className="fontSizeClass"> {t("Circular")} </h3>
                </div>
                <div className="health-dept-table mayor-table" style={{width:"100%"}}>
                  <table
                    width="95%"
                    border={0}
                    
                    cellPadding={2}
                    cellSpacing={2}
                    className="table table-bordered officials" >
                    <tbody>
                      <tr
                        bgcolor="#302ba0"
                        tabIndex={0}
                        
                        valign="middle">
                        <td width="35%" className="text-white tble-brder-left">
                        {t("Circular No")}		
                        </td>
                        <td width="20%" className="text-white">
                        {t("Date")}		
                        </td>
                        <td width="45%" className="text-white tble-brder-right">
                        {t("Subject")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1382')}>MC's Circular No. 75 of 2017-18</a>
                        </td>
                        <td >
                        24-March-2018				
                        </td>
                        <td >
                        Municipal Commissioner's Circular No. 75 of 2017-18.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1383')}>MC's Circular No. 74 of 2017-18</a>
                        </td>
                        <td >22-March-2018	 </td>
                        <td >Payment to contractors where work order was issued before 1st july 2017.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1384')}>MC's Circular No. 73 of 2017-18</a>
                        </td>
                        <td >22-March-2018</td>
                        <td >
                        Relaxation of tender rules with regards to contractual premium for excution of schemes.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1385')}>Office Order No. Chief Manager (P)/G/340/V/2017-18</a>
                        </td>
                        <td >27-February-2018						
                        </td>
                        <td >Office Order No. Chief Manager (P)/G/340/V/2017-18
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1386')}>Chief Manager (P)'s Circular No. 48/VIII of 2017-18</a>
                        </td>
                        <td >12-February-2018	  </td>
                        <td >
                        Chief Manager (P)'s Circular No. 48/VIII of 2017-18
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1387')}>Communique(The Amendment of Building Rules,2009 vide notification No 80/MA/O/C-4/3R-7/2017)</a>
                        </td>
                        <td >05-February-2018</td>
                        <td >
                        Communique(The Amendment of Building Rules,2009 vide notification No 80/MA/O/C-4/3R-7/2017)
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1388')}>CMFA's Circular No. 10 of 2017-18	</a>
                        </td>
                        <td >05-February-2018					
                        </td>
                        <td >CMFA's Circular No. 10 of 2017-18.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1389')}>MC's Circular No. 71 of 2017-18</a>
                        </td>
                        <td >03-February-2018	</td>
                        <td >
                        MC's Circular No. 71 of 2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1390')}>CMFA's Circular No. 08 of 2017-18</a>
                        </td>
                        <td >25-January-2018</td>
                        <td >Sensitising of KMC employees about misconception of filing of their individual return of income u/s 139(1) of the Income Tax Act,1961.  </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1391')}>Municipal Secretary's Circular No.60 of 2017-18	</a>
                        </td>
                        <td >25-January-2018	</td>
                        <td >
                        Delegation of financial vetting power,bill signing power and adaptation & implementation of new PWD schedule of rates published w.e.f.01.11.2017.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1392')}>MC's Circular No. 69 of 2017-18</a>
                        </td>
                        <td >27-January-2018</td>
                        <td >Revised Project review Meeting and Revenue Meeting Schedule. </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1393')}>MC's Circular No. 68 of 2017-18</a>
                        </td>
                        <td >19-January-2018</td>
                        <td >Census-2021 to be conducted by the Personal Department of KMC.
                      </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1394')}>MC's Circular No. 67 of 2017-18</a>
                        </td>
                        <td >11-January-2018</td>
                        <td >
                        Norms to be followed for diverting/transferring of fund allotted for Govt.approved schemes.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1395')}>MC's Circular No. 65 of 2017-18</a>
                        </td>
                        <td >08-January-2018</td>
                        <td >Administrative Calendar2018 .  </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1396')}>MC's Circular No. 64 of 2017-18</a>
                        </td>
                        <td >05-January-2018</td>
                        <td >MC's Circular No. 64 of 2017-18.</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1397')}>MC's Circular No. 63 of 2017-18</a>
                        </td>
                        <td >03-January-2018</td>
                        <td >Further surender of unutilized State Government Fund lying unspent in Local Fund & Bank Account.</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1398')}>MC's Circular No. 62 of 2017-18</a>
                        </td>
                        <td >22-December-2017</td>
                        <td >MC's Circular No. 62 of 2017-18.</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1399')}>MC's Circular No. 61 of 2017-18</a>
                        </td>
                        <td >21-December-2017</td>
                        <td >Corrigendum of Mpl.Commissioner's Circular No 58 of 2017-18,dated 14.12.2017,regaeding Work Procedure for passing of Bills of Work Contract/Procurement/Coposite Suppliers out of local fund,Government Fund and Revenue Fund of KMC.           </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1400')}>MC's Circular No. 60 of 2017-18</a>
                        </td>
                        <td >19-December-2017</td>
                        <td >
                        MC's Circular No. 60 of 2017-18
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1401')}>MC's Circular No. 59 of 2017-18</a>
                        </td>
                        <td >19-December-2017</td>
                        <td >MC's Circular No. 59 of 2017-18  </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1402')}>Chief Manager(P)'s Circular No.38/VIII of 2017-18</a>
                        </td>
                        <td >14-December-2017</td>
                        <td >
                        Chief Manager(P)'s Circular No.38/VIII of 2017-18
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1403')}>MC's Circular No. 58 of 2017-18	</a>
                        </td>
                        <td >14-December-2017</td>
                        <td >
                        Corrigendum of Mpl.Commissioner's Circular No 56 of 2017-18,regaeding Work Procedure for passing of Bills of Work Contract/Procurement/Coposite Suppliers out of local fund,Government Fund and Revenue Fund of KMC.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1404')}>MC's Circular No. 57 of 2017-18</a>
                        </td>
                        <td >13-December-2017</td>
                        <td >MC's Circular No. 57 of 2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1405')}>MC's Circular No. 56 of 2017-18</a>
                        </td>
                        <td >07-December-2017</td>
                        <td >Work procedure for passing of bills of works contract/procurement/composite suppliers out of Local Fund </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1406')}>MC's Circular No. 48 of 2017-18</a>
                        </td>
                        <td >01-November-2017	</td>
                        <td >
                        Tender Procedure to be followed as per the amended credential policy
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1407')}>CMFA's Circular No. 06 of 2017-18	</a>
                        </td>
                        <td >06-September-2017</td>
                        <td >
                        CMFA's Circular No. 06 of 2017-18                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1408')}>MC's Circular No. 46 of 2017-18	</a>
                        </td>
                        <td >18-September-2017</td>
                        <td >MC's Circular No. 46 of 2017-18
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1409')}>MC's Circular No. 44 of 2017-18</a>
                        </td>
                        <td >14-September-2017</td>
                        <td >
                        Preparation of the brief in regard to court matter and to review the number of pending court cases
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1410')}>MC's Circular No. 42 of 2017-18</a>
                        </td>
                        <td >06-September-2017</td>
                        <td >Procedure to be followed to dwal with Court cases</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1410')}>MC's Circular No. 41 of 2017-18</a>
                        </td>
                        <td >06-September-2017</td>
                        <td >
                        All COs and HODs to take review meetings to moniter progress of works vic-a-vic targets, audit queries, punctuality and attendance
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1411')}>MC's Circular No. 40 of 2017-18</a>
                        </td>
                        <td >06-September-2017</td>
                        <td >
                        E-tender in mandatory for works and procurement of value of Rs. 5 lakh and above
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1412')}>MC's Circular No. 39 of 2017-18</a>
                        </td>
                        <td >30-August-2017</td>
                        <td >MC's Circular No. 39 of 2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1413')}>MC's Circular No. 38 of 2017-18</a>
                        </td>
                        <td >28-August-2017</td>
                        <td >
                        MC's Circular No. 38 of 2017-18
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1414')}>MC's Circular No. 37 of 2017-18</a>
                        </td>
                        <td >28-August-2017</td>
                        <td >Clarification/Guidelines regarding issues pertaining to Contracts awarded by KMC due to the introduction of GST on and from 01/07/2017 </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1415')}>MC's Circular No. 36 of 2017-18</a>
                        </td>
                        <td >25-August-2017	</td>
                        <td >
                        MC's Circular No. 36 of 2017-18
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1416')}>MC's Circular No. 34 of 2017-18</a>
                        </td>
                        <td >22-August-2017</td>
                        <td >
                        MC's Circular No. 34 of 2017-18
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1417')}>MC's Circular No. 33 of 2017-18</a>
                        </td>
                        <td >18-August-2017</td>
                        <td >
                        MC's Circular No. 33 of 2017-18
                       </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1418')}>MC's Circular No. 31 of 2017-18</a>
                        </td>
                        <td >09-August-2017	</td>
                        <td >MC's Circular No. 31 of 2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1419')}>MC's Circular No. 29 of 2017-18</a>
                        </td>
                        <td >29-July-2017</td>
                        <td >
                        MC's Circular No. 29 of 2017-18
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1420')}>Chief Manager (P)'s Circular No. 17 2017-18</a>
                        </td>
                        <td >31-July-2017</td>
                        <td >
                        Chief Manager (P)'s Circular No. 17 2017-18
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1421')}>MC's Circular No. 27 of 2017-18</a>
                        </td>
                        <td >28-July-2017</td>
                        <td >MC's Circular No. 27 of 2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1422')}>MC's Circular No. 26 of 2017-18</a>
                        </td>
                        <td >28-July-2017</td>
                        <td >MC's Circular No. 26 of 2017-18 </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1423')}>MC's Circular No. 25 of 2017-18</a>
                        </td>
                        <td >14-July-2017</td>
                        <td >MC's Circular No. 25 of 2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1424')}>MC's Circular No. 24 of 2017-18</a>
                        </td>
                        <td >11-July-2017</td>
                        <td >
                        MC's Circular No. 24 of 2017-18
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1425')}>MC's Circular No. 22 of 2017-18</a>
                        </td>
                        <td >05-July-2017</td>
                        <td >MC's Circular No. 22 of 2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1426')}>Chief Manager (P)'s Circular No. 13/VIII/2017-18</a>
                        </td>
                        <td >03-July-2017</td>
                        <td >Chief Manager (P)'s Circular No. 13/VIII/2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1427')}>MC's Circular No. 21 of 2017-18</a>
                        </td>
                        <td >30-Jun-2017</td>
                        <td >MC's Circular No. 21 of 2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1428')}>MC's Circular No. 19 of 2017-18</a>
                        </td>
                        <td >14-Jun-2017</td>
                        <td >On line payment of GPF dues/dues of terminal benifits of KMC employees</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1429')}>MC's Circular No. 17 of 2017-18</a>
                        </td>
                        <td >03-Jun-2017</td>
                        <td >MC's Circular No. 17 of 2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1430')}>MC's Circular No. 16 of 2017-18</a>
                        </td>
                        <td >26-May-2017</td>
                        <td >Revised Circular of SOMOBYATHI Scheme</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1431')}>MC's Circular No. 15 of 2017-18</a>
                        </td>
                        <td >23-May-2017	</td>
                        <td >MC's Circular No. 15 of 2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1432')}>MC's Circular No. 14 of 2017-18</a>
                        </td>
                        <td >23-May-2017</td>
                        <td >MC's Circular No. 14 of 2017-18
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1433')}>MC's Circular No. 12 of 2017-18</a>
                        </td>
                        <td >16-May-2017</td>
                        <td >Everybody working in Kolkata Municipal Corporation are hereby made aware about the recent massive Cyber (Ransomeware) Attack. In order to prevent the attack, all are strongly recemmended to abide by the following instructions</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1434')}>MC's Circular No. 11 of 2017-18</a>
                        </td>
                        <td >06-May-2017</td>
                        <td >Emergency arrangements during the ensuing norwester/rainy season in the next few months of 2017</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1435')}>MC's Circular No. 10 of 2017-18</a>
                        </td>
                        <td >02-May-2017</td>
                        <td >Municipal Commissioner's Circular No. 10 of 2017-18
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1436')}>MC's Circular No. 09 of 2017-18</a>
                        </td>
                        <td >29-April-2017</td>
                        <td >
                        Municipal Commissioner's Circular No. 09 of 2017-18
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1437')}>MC's Circular No. 08 of 2017-18</a>
                        </td>
                        <td >25-April-2017</td>
                        <td >Municipal Commissioner's Circular No. 08 of 2017-18
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1438')}>MC's Circular No. 07 of 2017-18</a>
                        </td>
                        <td >25-April-2017	</td>
                        <td >Municipal Commissioner's Circular No. 07 of 2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1439')}>MC's Circular No. 06 of 2017-18</a>
                        </td>
                        <td >21-April-2017</td>
                        <td >Municipal Commissioner's Circular No. 06 of 2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1440')}>MC's Circular No. 05 of 2017-18		</a>
                        </td>
                        <td >06-April-2017	</td>
                        <td >Municipal Secretary's Circular No. 02 of 2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1441')}>MC's Circular No. 03 of 2017-18</a>
                        </td>
                        <td >06-April-2017</td>
                        <td >Municipal Commissioner's Circular No. 03 of 2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1442')}>MC's Circular No. 02 of 2017-18</a>
                        </td>
                        <td >01-April-2017</td>
                        <td >Municipal Commissioner's Circular No. 02 of 2017-18</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1443')}>MC's Circular No. 01 of 2017-18</a>
                        </td>
                        <td >01-April-2017	</td>
                        <td >Mayor's Budget Estimate for the year 2017-2018 and to restrict the booking of fund under all Budget heads "400" and "800"</td>
                      </tr>
                      
                      </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>  )
}

export default MC_Circular_2017_18