import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Drainage_Contact_Details  = () => {
  const { t, i18n, ready } = useTranslation();
  const increaseFontSizeBy1px =() => {
      var el = document.getElementsByClassName('fontSizeClass');
      for (let i = 0; i < el.length; i++) {
        var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
        var fontSize = parseFloat(style); 
        el[i].style.fontSize = (fontSize + 1) + 'px';
      }
    };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> Sewage Treatment Plants </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
 

  <section className="section inner-pad1 mt-14">
    <div className="container " >
      <div className="row">
      <div className="col-lg-3">
          <div className="site-menu  min-height-400">           
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
              <li className="nav-item">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Certificate of Enlistment")} <i class="bi bi-chevron-down"></i> </button>
                    <div id="demo" className="collapse">
                        <a href='https://www.kmcgov.in/KMCPortal/downloads/725_MA_Revised_Enlistment_Trade_Calling.pdf' target="_blank"> {t("Guidline")} </a>
                        <a href='https://www.kmcgov.in/KMCPortal/downloads/TradeLicenceEoDBProcedure.pdf' target="_blank"> {t("Procedure")} </a>               
                    </div>
                </li>               
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Advertisement_Dept'> {t("Advertisement Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Amusement_Dept'> {t("Amusement Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Assessment_Dept'> {t("Assessment Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/'> {t("Building Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Bustee_Dept'> {t("Bustee Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Car_Parking_Dept'> {t("Car Parking Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Chief_Eng_Dept'> {t("Chief Eng Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Ch_VS_Dept'> {t("Ch. VS Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Sewerage_Drainage_Dept'> {t("Sewerage Drainage Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Education_Dept'> {t("Education Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Electricity_Dept'> {t("Electricity Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Finance_Dept'> {t("Finance Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Health_Dept'>  {t("Health Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Internal_Audit_Dept'> {t("Internal Audit Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/IT_Dept'> {t("I.T. Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Law_Dept'> {t("Law Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Licence_Dept'> {t("Licence Dept")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Lighting_Dept'> {t("Lighting Dept")}</Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Market_Dept'> {t("Market Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Mpl_Secretary_Dept'> {t("Mpl.Secretary's Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Personnel_Dept'>  {t("Personnel Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Parks_Square_Dept'>  {t("Parks & Square Dept")}. </Link> </li>	

                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Environment_Heritage_Dept'>  {t("Environment & Heritage Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Roads_Dept'>  {t("Roads Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Social_Welfare_Urban_Dept'> {t("Social Welfare & Urban Proverty Alleviation Dept")}. </Link> </li>	
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Solid_Waste_Dept'>  {t("Solid Waste Mgmt. Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Town_Planning_Development_Dept'>  {t("Town Planning & Development Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Treasury_Dept'>  {t("Treasury Dept")}. </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Water_Supply_Dept'>  {t("Water Supply Dept")} </Link> </li>									
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> Important officials of Sewerage Drainage Department</h3>
              </div>
              <div className="mayor-table swrg-tble">
                <table className="table table-bordered officials">
                  <tbody>
                    <tr bgcolor="#302ba0" tabIndex={0}>
                      <td
                        width="10%"
                        rowSpan={2}
                        style={{
                          color: "#fff",
                          borderLeft: "1px solid #302ba0"
                        }}
                        align="center"
                        valign="middle"
                      >
                        Sl. No.
                      </td>
                      <td
                        width="27%"
                        rowSpan={2}
                        align="center"
                        style={{ color: "#fff" }}
                      >
                        Name
                      </td>
                      <td
                        width="23%"
                        rowSpan={2}
                        align="center"
                        style={{ color: "#fff" }}
                      >
                        Designation
                      </td>
                      <td
                        colSpan={2}
                        rowSpan={2}
                        align="center"
                        style={{
                          color: "#fff",
                          borderRight: "1px solid #302ba0"
                        }}
                      >
                        
                        Office Phone No.
                      </td>
                    </tr>
                    <tr>
                      {/* <td width="20%" style="padding:3">Office</td>
              <td width="13%" style="padding:3">Resi.</td> */}
                    </tr>
                    <tr
                      align="center"
                      valign="middle"
                      bgcolor="#fff"
                      tabIndex={0}
                    >
                      <td>1.</td>
                      <td align="left">Santanu Kumar Ghosh</td>
                      <td align="left">Director General (S&amp;D)</td>
                      <td align="left">
                        2286-1253(D), 2286-1000, <br /> Extn. 2661 I.C.-253
                      </td>
                    </tr>
                    <tr
                      align="center"
                      valign="middle"
                      bgcolor="#fff"
                      tabIndex={0}
                    >
                      <td>2.</td>
                      <td align="left">Sanjoy Mandol</td>
                      <td align="left">Dy. C.E (Dr)</td>
                      <td align="left">
                        2286-1000, Extn. 2806 <br />
                      </td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>3.</td>
                      <td align="left">Sandip Das </td>
                      <td align="left">Dy.C.E(E)S &amp; D</td>
                      <td align="left">-</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>4.</td>
                      <td align="left">Bishnu Das Mondal </td>
                      <td align="left">Dy.C.E(M)S.C </td>
                      <td align="left">
                        2286-1037(D)
                        <br /> 2286-1000, Extn. 2559
                      </td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>5.</td>
                      <td align="left">Md.Minhazuddin </td>
                      <td align="left">
                        Ex. Engr(M)/S.C(C)
                        <br />
                        Ex. Engr(M)/S.C(N)
                      </td>
                      <td align="left">
                        2528-7755 <br /> 2329-1877
                      </td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>6.</td>
                      <td align="left">Partha Nandi </td>
                      <td align="left">Ex. Engr(M)/S.C(S) </td>
                      <td align="left">2401-4464</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>7.</td>
                      <td align="left">Utpal Dey </td>
                      <td align="left">Ex. Engr(DR)/N </td>
                      <td align="left">2286-1000, Extn. 2551</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>8</td>
                      <td align="left">Utpal Ranjan Choudhuri </td>
                      <td align="left">Ex. Engr(DR)/C </td>
                      <td align="left">2286-1000, Extn. 2551</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>9.</td>
                      <td align="left">Jayanta Pal </td>
                      <td align="left">Ex. Engr(DR)/S1 </td>
                      <td align="left">2286-1000, Extn. 2554</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>10.</td>
                      <td align="left">Ataur Rahaman </td>
                      <td align="left">Ex. Engr(DR)/S2 (Actg.) </td>
                      <td align="left">2344-9142</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>11.</td>
                      <td align="left">Kajal Roy </td>
                      <td align="left">Ex. Engr(E)/(BDPS) </td>
                      <td align="left">2344-9142</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>12.</td>
                      <td align="left">Biswajit Bala </td>
                      <td align="left">
                        Asst.Engr(M)/(DLPS) <br />
                        [Officiating as Ex.Engr(M)]
                      </td>
                      <td align="left">2323-5536</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>13.</td>
                      <td align="left">Biswanath Das </td>
                      <td align="left">Ex. Engr(M)/(PBPS) </td>
                      <td align="left">2251-0053</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>14.</td>
                      <td align="left">Tapan Sarkar </td>
                      <td align="left">Ex. Engr(E)/(MDPS) </td>
                      <td align="left">2350-1166</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>15.</td>
                      <td align="left">Supriyo Sengupta </td>
                      <td align="left">Ex. Engr(E)/(Kudghat) </td>
                      <td align="left">-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Drainage_Contact_Details