import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const KMC_Advertisement = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  const [onlineApplication, setonlineApplication] = React.useState(false);
  const onlineApplicationToggle = () => {
    setonlineApplication(!onlineApplication);
  };
  const [onlineDeclaration, setonlineDeclaration] = React.useState(false);
  const onlineDeclarationToggle = () => {
    setonlineDeclaration(!onlineDeclaration);
  };
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      
    </div>
  </section>
  {/* End Slider */}
  
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu   min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/KMC_Advertisement'> Advertisement Home </Link>
                </li>
                <li className="nav-item">
                     <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">Online Application<i className="bi bi-chevron-down" /></button>
                      <div id="demo2" className="collapse">                        
                        <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/AdvertisementOnlineApplication.jsp'>1. Online Advertisement Application </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/AdvertisementOnlineRenewApplication.jsp'>2. Online Advertisement Renew Application </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineTerminateApplication.jsp'>3. Online Advertisement Terminate Application </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemandPrint_online.jsp'>4.  Online Advertisement Application Demand Print </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalAdvertisementPayment_online.jsp'>5. Online Advertisement Application Demand Pay </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineApplicationNOC.jsp'>6. Online Advertisement Application NOC Print</a>
                      </div>
                </li>
                <li className="nav-item"><button type="button" className="btn" data-toggle="collapse" data-target="#demo3">Online Declaration<i className="bi bi-chevron-down" /></button>
                      <div id="demo3" className="collapse">                        
                          <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineDeclaration.jsp'>1. Online Advertisement Declaration </a>
                          <a className="nav-link fontSizeClass" style={{fontSize:"10px"}} href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineDeclarationStatus.jsp'>2. Online Declaration Status </a>
                       </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Advertisement_rates'> Rates </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AgentDetails.jsp'> Know Your Agent Code</a>                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Advertisement_download_forms'> Download Forms</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Check_Demands_Payable'>Check Demands Payable </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemandPrint.jsp'> {t("Print Unpaid Bill")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemand.jsp'> {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemand.jsp'> {t("Reprint e-Receipt")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCAdvertisementTaxCalculator.jsp'>{t("Advertisement Tax Calculator")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Advertisement_Dept'> Contact Details</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/ListHoardings1.jsp'> {t("List of Hoardings")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('240')}> Empanelement Of Advertising Agencies </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title">
                <h3 className="fontSizeClass"> Advertisement : FAQs </h3>
              </div>
              <div id="accordion">
              
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <button className="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    What are the key activities of the department?
                    </button>
                  </div>
                  <div id="collapseOne" className="collapse show" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass m-3 fontSizeClass">
                          The key activities of the Department include:
                          <ul className="kmc-function-list">
                            <li>
                              
                              Issuing permission and license for outdoor
                              advertisements
                            </li>
                            <li>
                              
                              Control of nuisance created by unauthorized
                              advertisement
                            </li>
                            <li>
                              
                              Construction and maintenance of bus passenger
                              shelters
                            </li>
                          </ul>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    
                      <button className="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      What are the contact details of the Advertisement department?
                      </button>
                    
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <ul className="kmc-function-list">
                          <li>
                            
                            The entire department operates at 5, S N
                            Banerjee Road, Kolkata 700013, 1st Floor, Room
                            Numbers - 115,116,118
                          </li>
                          <li>
                            
                            Contact Number - 22861000, Extension -
                            2801/2625/2829.
                          </li>
                    </ul>
                  </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    
                      <button className="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      How can I apply for an ad-spot? Can you describe each step in the process, documentation &amp; turn-around-time for each step?
                      </button>
                    
                  </div>
                  <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <ul className="kmc-function-list">
                                              <li>
                                                
                                                For banners, posters, umbrella, leaflets,
                                                carts, balloons, advertisement on vehicles and
                                                other temporary advertisements a simple letter
                                                on plain paper or letterhead furnishing the
                                                details of size, duration, location and number
                                                (how many) serves the purpose. The charge will
                                                be calculated on the basis of existing budget
                                                schedule and normally the permission is issued
                                                within next 24 hours.
                                              </li>
                                              <li>
                                                
                                                For permanent hoardings on property owned by
                                                any individual; or organization other than KMC
                                                the following papers are to be submitted
                                              </li>
                                              <ol>
                                                <li>
                                                  
                                                  An application in the format available in
                                                  the department (Annex A – Available Online)
                                                </li>
                                                <li>
                                                  An agreement between the owner(s) of the
                                                  premises and the applicant
                                                </li>
                                                <li>
                                                  Structural stability certificate from an
                                                  empanelled structural engineer class II
                                                </li>
                                                <li>NOC from the owner</li>
                                                <li>
                                                  Copy of current tax bill of the premises
                                                </li>
                                                <li>
                                                  Engineering drawing of the proposed
                                                  structure in triplicate blue print signed by
                                                  the applicant, the owner of the premises and
                                                  approved by qualified structural engineer
                                                </li>
                                                <li>
                                                  An affidavit in the prescribed format sworn
                                                  before a first class magistrate (Annex B –
                                                  Available Online)
                                                </li>
                                              </ol>
                                              <li>
                                                For advertisement on KMC property an
                                                application on plain paper suggesting the
                                                proposed sites and blue print plan of the
                                                proposed site is sufficient. However such
                                                sites are often allotted through tender also.
                                              </li>
                                            </ul>      </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="heading4">
                    
                      <button className="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapseThree">
                      What can I do if there is excessive delay in my getting an ad-spot in spite of providing all the documents as specified?        </button>
                    
                  </div>
                  <div id="collapse4" className="collapse" aria-labelledby="heading4" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                        <ul className="kmc-function-list">
                          <li>
                            
                            In the unlikely case of such an event, you are
                            most welcome to approach the Manager,
                            Advertising at 22861000, extension - 2801 or
                            the Assistant License Officer at 22861000,
                            extension - 2625 who would do their best to
                            sort out the matter.
                          </li>
                        </ul>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="heading5">
                    
                      <button className="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
                      What are the rules regarding hoardings?  </button>    
                  </div>
                  <div id="collapse5" className="collapse" aria-labelledby="heading5" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    Hoardings are governed by chapter XIV of KMC Act
                                            1980 salient rules are :
                                            <ul className="kmc-function-list">
                                              <li>
                                                
                                                No structure can be erected for the purpose of
                                                advertisement without prior permission and
                                                license from KMC and without paying the taxes
                                                thereof.
                                              </li>
                                              <li>
                                                
                                                No advertisement can be made causing
                                                obstruction to natural light and flow of air
                                                and movement of pedestrian and vehicular
                                                traffic.
                                              </li>
                                            </ul>
                                            Please note that KMC is empowered to demolish,
                                            deface, and pull down any structure erected for
                                            the purpose of advertisement, which contravenes
                                            the rules.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="heading6">
                    
                      <button className="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapseThree">
                      What can I do if I notice obstructions to my hoardings such as tree branches?
                    </button>
                    
                  </div>
                  <div id="collapse6" className="collapse" aria-labelledby="heading6" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <ul className="kmc-function-list">
                      <li>
                        In case a tree obstructs your hoarding, please
                        do not trim it yourself. This will lead to
                        cancellation of your license. Please inform
                        the parks and square department of KMC who
                        will look into the matter. If trimming is not
                        possible, you may apply to advertisement
                        department for shifting of hoarding. If
                        obstruction is due to any other hoarding
                        please inform advertisement department by
                        lodging a complaint.
                      </li>
                    </ul>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="heading7">
                    
                      <button className="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapseThree">
                      How do I obtain lighting for my ad-spot? What  the charges for the same?
                    </button>
                    
                  </div>
                  <div id="collapse7" className="collapse" aria-labelledby="heading7" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <ul className="kmc-function-list">
                      <li>
                        
                        Lighting to be obtained from CESC after
                        payment of illumination charges to KMC. See
                        schedule of charges and taxes for applicable
                        fees (Annex C – Available Online).
                      </li>
                    </ul>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="heading8">
                    
                      <button className="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapseThree">
                      Are there regular inspections of ad-spots by KMC?
                    </button>
                    
                  </div>
                  <div id="collapse8" className="collapse" aria-labelledby="heading8" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <ul className="kmc-function-list">
                      <li>
                        
                        Yes. Each hoarding has an identification
                        number and periodic inspections are carried
                        out.
                      </li>
                    </ul>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="heading9">
                    
                      <button className="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse9" aria-expanded="false" aria-controls="collapseThree">
                      What should I do if I notice ad-spots that are a public nuisance or objectionable?
                      <i className="bi bi-chevron-down" />
                    </button>
                    
                  </div>
                  <div id="collapse9" className="collapse" aria-labelledby="heading9" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <ul className="kmc-function-list">
                      <li>
                        
                        If you notice objectionable advertisements,
                        please inform advertisement department over
                        phone or by a letter. In case of phone please
                        furnish your own particulars. In case of a
                        letter you may simply furnish the location of
                        the site without disclosing your identity.
                      </li>
                    </ul>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="heading9">
                    
                      <button className="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse9" aria-expanded="false" aria-controls="collapseThree">
                      How do I confirm that the ad-spot for  advertisement is authorized and legal?
                      <i className="bi bi-chevron-down" />
                    </button>
                    
                  </div>
                  <div id="collapse9" className="collapse" aria-labelledby="heading9" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <ul className="kmc-function-list">
                      <li>
                        Please enquire with the advertising department
                      </li>
                    </ul>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="heading10">
                    
                      <button className="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse10" aria-expanded="false" aria-controls="collapseThree">
                      How often will I be billed? What are the components of my bill (license fee, lease etc)?
                      <i className="bi bi-chevron-down" />
                    </button>
                    
                  </div>
                  <div id="collapse10" className="collapse" aria-labelledby="heading10" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <ul className="kmc-function-list">
                      <li>
                        Payment of tax and fees are to be made prior to display. Subsequently periodic demands are raised by KMC. Frequency of payment and renewal of license is given in the tax schedule (Annex C – Available online).
                      </li>
                    </ul>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="heading10">
                    
                      <button className="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse10" aria-expanded="false" aria-controls="collapseThree">
                      What payment methods are available to me?
                      <i className="bi bi-chevron-down" />
                    </button>
                    
                  </div>
                  <div id="collapse10" className="collapse" aria-labelledby="heading10" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <ul className="kmc-function-list">
                      <li>
                        
                        Payment may be made in cash, bank draft or
                        cheque (cheques are accepted only from
                        Government institutions and its undertakings)
                        in favor of the Kolkata Municipal Corporation
                        payable at Kolkata.
                      </li>
                    </ul>
                    </div>
                  </div>

                  
                </div>

                <div className="card">
                  <div className="card-header" id="heading11">
                    
                      <button className="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse11" aria-expanded="false" aria-controls="collapseThree">
                      What payment methods are available to me? <i className="bi bi-chevron-down" />
                    </button>
                    
                  </div>
                  <div id="collapse11" className="collapse" aria-labelledby="heading11" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <ul className="kmc-function-list">
                      <li>
                        Payment may be made in cash, bank draft or
                        cheque (cheques are accepted only from
                        Government institutions and its undertakings)
                        in favor of the Kolkata Municipal Corporation
                        payable at Kolkata.
                      </li>
                    </ul>
                    </div>
                  </div>

                  
                </div>

                <div className="card">
                  <div className="card-header" id="heading12">
                    
                      <button className="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse12" aria-expanded="false" aria-controls="collapseThree">
                      Who do I contact for grievances regarding my bill? <i className="bi bi-chevron-down" />
                    </button>
                    
                  </div>
                  <div id="collapse12" className="collapse" aria-labelledby="heading12" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <ul className="kmc-function-list">
                      <li>
                      You are most welcome to approach the Manager,
                      Advertising at 22861000, extension – 2801 or
                      the Assistant License Officer at 22861000,
                      extension – 2625 who would do their best to
                      sort out the matter.
                      </li>
                    </ul>
                    </div>
                  </div>

                  
                </div>

                <div className="card">
                  <div className="card-header" id="heading13">
                    
                      <button className="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse13" aria-expanded="false" aria-controls="collapseThree">
                      What happens if I am late in my payment? <i className="bi bi-chevron-down" />
                    </button>
                    
                  </div>
                  <div id="collapse13" className="collapse" aria-labelledby="heading13" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <ul className="kmc-function-list">
                      <li>
                      It is strongly recommended that you make your
                      payments on time. In the event of delays in
                      payment, your license will lapse resulting in
                      the demolition and/or defacement of the
                      display and/or a penalty to the tune of three
                      times the usual taxes will be applicable.
                      Please refer to relevant provisions in KMC
                      Act,1980.
                      </li>
                    </ul>
                    </div>
                  </div>

                  
                </div>

                <div className="card">
                  <div className="card-header" id="heading14">
                    
                      <button className="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse14" aria-expanded="false" aria-controls="collapseThree">
                      Can I pay in installments? <i className="bi bi-chevron-down" />
                    </button>
                    
                  </div>
                  <div id="collapse14" className="collapse" aria-labelledby="heading14" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <ul className="kmc-function-list">
                      <li>Unfortunately there is no provision for payment in installments.</li>
                    </ul>
                    </div>
                  </div>

                  
                </div>
              </div>        
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default KMC_Advertisement