import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios"; 

const Colouring_Activity_Book = () => {
  const { t, i18n, ready } = useTranslation();
    const increaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
          var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
          var fontSize = parseFloat(style); 
          el[i].style.fontSize = (fontSize + 1) + 'px';
        }
    };
    const fileDownload = (fileStoreId) => {
      let url = `https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`;
      axios
        .post(url, { fileId: fileStoreId, userId: "392" })
        .then((res) => {
          console.log("response is --- ", res);

          if (res && res.data && res.status === 200) {
            const base64 = res.data[0] && res.data[0].base64_data;
            const pdfData = "data:application/pdf;base64," + base64;

            let html =
              "<html>" +
              "<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>" +
              "<body>" +
              '<iframe src="' +
              pdfData +
              '"></iframe>' +
              "</body></html>";
            console.log(html);
            let a = window.open();
            a.document.write(html);
          }
        })
        .catch((e) => {
          // setLoader(false);
          console.log("The error is ", e);
        });
    };
    const decreaseFontSizeBy1px =() => {
      var el = document.getElementsByClassName('fontSizeClass');
      for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize - 1) + 'px'; 
      }
    };
  return (
    <>
      <section id="hero" className="hero hero2">
        <div
          className="social-media wow slideInDown"
          style={{ visibility: "visible", animationName: "slideInDown" }}
        >
          <div className="facebook" rel="ebInfo" title="facebook">
            <a
              href="https://www.facebook.com/KolkataMunicipalCorporation.official"
              target="_blank"
            >
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a
              href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp"
              target="_blank"
            >
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>

        <div className="carousel-item active">
          <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
          <div className="container">
            {" "}
            <div className="section-main-heading2"></div>
          </div>
        </div>
      </section>
      {/* End Slider */}

      <section className="section inner-pad1">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="site-menu ">
                <nav className="sidebar card py-2">
                  <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Disaster_Mgmt"
                      >
                        {" "}
                        {t("Disaster Mgmt")}{" "}
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      <Link class="nav-link" to="/Concept_Note" target="_blank">
                        {" "}
                        {t("Concept Note")}
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Guidelines_for_Safety"
                      >
                        {" "}
                        {t("Guidelines for Safety")}{" "}
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Form_For_Inventory"
                      >
                        {" "}
                        {t("Form For Inventory")}{" "}
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/School_Safety_Booklet"
                      >
                        {" "}
                        {t("School Safety Booklet")}{" "}
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link active1 fontSizeClass"
                        to="/Colouring_Activity_Book"
                      >
                        {" "}
                        {t("Colouring Activity Book")}
                      </Link>{" "}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="container">
                <div className="row">
                  <div className="inner-title2">
                    <h3 className="fontSizeClass">
                      {" "}
                      {t("Colouring Activity Book")}
                    </h3>
                  </div>
                  <div className="col-lg-12 map2 p-0">
                    <a
                      className="nav-link fontSizeClass"
                      onClick={(e) => fileDownload("1984")}
                    >
                      {" "}
                      {t("Colouring Activity Book")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Colouring_Activity_Book