import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Heritage_Wardwise_Heritage_Buildings  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
    <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
    </div>    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          {/* <h4 className="fontSizeClass"> Proceedings of HCC </h4> */}
        </div>
      </div>
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                <Link className="nav-link active1 fontSizeClass" to='/HeritageBuildingHome'> {t("Heritage Home")} </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Exemption_of_Tax'> {t("Exemption of Tax")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Heritage_Buildings'> {t("Heritage Buildings")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Conservation_Architect'> {t("Conservation Architect")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('473')}>{t("Composition of the Heritage Conservation Committee")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Heritage_Proceedings_of_HCC'>
                    
                    {t("Proceedings of HCC")}
                  </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('474')}>{t("Action Plan")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('475')}>{t("Parking Policy")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('476')}>{t("Measures for prevention of landfill fire")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWisePond.jsp'>  {t("Ward wise Pond")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WardWiseHeritageBuilding.jsp'> {t("Ward Wise Heritage Building")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Environment_Heritage_Dept'> {t("Contact Details")} </Link>
                </li>

                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Activities_NCAP.pdf' target={'_blank'}> {t("Activities Permitted under NCAP")} </a>
                </li>

                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Swachh Vayu Survekshan")} <i className="bi bi-chevron-down" /></button>
                    <div id="demo2" className="collapse">
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessed_CPCB_scores_23_09_2023.xlsx'>1. {t("Self assessed scores & CPCB scores")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Self_assessment_reports_Kolkatacity_23_09_2023.pdf'>2. {t("Self assessment reports of Kolkata city2023")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/downloads/Swachh_Vayu_Survekshan_23_09_2023.pdf'>3. {t("Swachh Vayu Survekshan 2023 Rank")} </a>
                    </div>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/airquality/citizenreport.do' target={'_blank'}> {t("Air quality status")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='http://emis.wbpcb.gov.in/emis/category.do' target={'_blank'}> {t(" Industry categorisation")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/District_Environment_ KMC2021_23_09_2023.pdf' target={'_blank'}> {t("FINAL District Environment plan KMC 2021")} </a>
                </li>


              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="">
            <div className="row">
              <div className="inner-title2 bas" style={{marginLeft:"10px"}}>
                <h3 className="fontSizeClass"> Proceedings of HCC </h3>
              </div>
              <div className="mayor-table pump-list-tble Heritage_Conservation_Architect" >
                <table
                  width="95%"
                  border={0}
                  align="center"
                  cellPadding={2}
                  cellSpacing={2}
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr
                      bgcolor="#302ba0"
                      tabIndex={0}
                      align="center"
                      valign="middle"
                    >
                      <td width="60%" className="text-white tble-brder-left">
                        
                        Name
                      </td>
                      <td width="40%" className="text-white tble-brder-right">
                        {t("Download")} Link
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        1 &amp; 1A, Cathedral Road, Ward No.-63
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        1, Clive Buildings, Ward No.-45 (1)
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">1,Hogg Street, CVF Block</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">1,R N Mukherjee Road, Ward No.-46</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">1,R N Mukherjee Road, Ward No.-46</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">2, Camac Street, Ward No.-63</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">3, Lovelock Place, Ward No.-69</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">4, Bepin Pal Road, Ward No.-84</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        5, Jawaharlal Nehru Road, Ward No.-63
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">6, Lyons Range, Ward No.-45</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        6, Lyons Range, Ward No.-45_29.09.20
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        7,William Jones Sarani, Ward No.-63_29.09.20
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        8E, James Hickey Sarani, Ward No.-46
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">12, Binode Saha Lane, Ward No.-27</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        12A, Netaji Subhas Road, Ward No.-45
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        13, Bankim Chatterjee Street, Ward No.-40
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">13, Wood Street, Ward No.-63</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">13, Wood Street, Ward No.-63</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">13, Wood Street, Ward No.-63</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        13/1, Shibnarain Das Lane, Ward No.-38
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        13A, Shikdarbagan Street, Ward No.-11
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">14, N S Road, Wrad No.-45</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        14, Netaji Subhas Road, Ward No.-45
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        14, Sarojini Naidu Sarani, Ward No.-63c
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        17, bal Mukund Mackar Road, Ward No.-415
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        17A, Ratan Sarkar Garden Street, Ward No.-22
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">18, Judges Court Road, Ward No.-74</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">18A, Park Street, Ward No.-63</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        18E, Srigopal Mallick Lane, Ward No.-40
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">19, Nellie Sen Gupta Sarani</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        22/1/1A, Sudhir Chatterjee Street, Ward No.-26
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        23, Nirmal Chunder Street, Ward No.-47
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        24, Ballygunge Circular Road, Ward No.-69
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">29C, Bentinck Street, Ward No.-46</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        31/1A &amp; 31/1B, Nayan Chand Dutt Street, Ward No.-17
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        34I, Shyampukur Street, Ward No.-10
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">37, Abhedananda Road, Ward No.-16</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        18 Uday Shankar Sarani000147, Pathuriaghat Street, Ward
                        No.-24
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        49, Netaji Subhas Road, Ward No.-45
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        51, Netaji Subhas Road, Ward No.-45
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        52A, Indian Mirror Street, Ward No.-51
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">59A, Park Street, Ward No.-63</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        71, 71B &amp; 71C, Paddapukur Road, Ward No.-70
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">76/1B, Bidhan Sarani, Ward No.-16</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        81C, Moulana Abul Kalam Azad Sarani, Ward No.-33
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        93/1, Acharya Prafulla Chandra Road, Ward No.-38
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        98, Dr. Lal Mohan Bhattacharyya Road, Ward No.-55
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        98, Shankar Ghosh Lane, Ward No.-38
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        113, Rash Behari Avenue, Ward No.-85
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        170, Acharyya Jagadish Bose Road, Ward No.-60
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        294/2/1, Acharya Prafulla chandra Road, Ward No.-28
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        General decision taken in HCC meeting dated 29.08.2019
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        Sri Sri Gopi Nath Jew Thakur Temple at 5, Ratan Babu
                        Road
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">SS Hogg Market B &amp; NB Block</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">SS Hogg Market Improvement of Roof</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        155A 1 AJC Bose Road0001SS Hogg Market Misc 152
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        SS Hogg Market MS Rolling Shutter Stall No. 201 E(N)-192
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">SS Hogg Market Rolling Shutter</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        SS Hogg Market Structural Improvement
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">SS Hogg Market WR(O) Block</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        Statesman House 4, Chowringhee Square, Ward No.-46
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        Temple at Santosh Roy Road near Barisha Clab
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        2/1, Ho Chi Minh Sarani, Ward No.-63
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        10, Saheed Nityananda Saha Sarani, Ward No.-45
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">12, Park Street, Ward No.-63</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">15/1, Strand Road,Ward No.-45</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        23, Nirmal Chunder Street, Ward No.-47
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        29, Netaji Subhas Road, Ward No.-45
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">30, Park Street, Ward No.-63</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">32A, Elliot Road, Ward No.-61</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        40A, Kashi Mitter Ghat Street, Ward No.-008
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        Declaration of 62/4A &amp; 62/4B, Beadon Street, Ward
                        No.-16
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">138/2, Bidhan Sarani, Ward No.-10</td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        234/4, Acharya Jagadish Bose Road, Ward No.-70
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                    <tr tabIndex={0} align="center" valign="top">
                      <td align="center">
                        243/1, Acharyya Prafulla Chandra Road, Ward No.-015
                      </td>
                      <td>
                        <Link>{t("Download")}</Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Heritage_Wardwise_Heritage_Buildings