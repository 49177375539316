import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Overview = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
    {/* Slider */}
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
        <div className="container">
          
          <div className="section-main-heading2">
            
            <h4 className="fontSizeClass"> {t("Overview")} </h4>
          </div>
        </div>
      </div>
    </section>
    {/* End Slider */}
   
    <section className="section inner-pad1">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="site-menu  ">
              <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Overview'>                    
                    {t("Overview")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Power'>                    
                    {t("Power")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Functions'>                    
                    {t("Functions")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Revenue'>                    
                    {t("Revenue")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Adminstration'>                    
                    {t("Administration")}
                  </Link>
                </li>
              </ul>
              </nav>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2">
                  <h3 className="fontSizeClass"> {t("Overview")} </h3>
                </div>
                <div className="about-head1">
                <h4 className="fontSizeClass ">
                    {t("Overview_text_1")}
                </h4>
              </div>
                <div className="inner-title2 ">
                  <h3 className="fontSizeClass "> {t("The Mayor")} </h3>
                <div className="col-lg-12 about-img2">
                <p className="fontSizeClass">
                  {t("We believe that Kolkata has the potential to be one of the best cities of the world. We see Kolkata as a clean and attractive city of international repute in which all people have access to quality services")}.
                </p>
              </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="mayor-table mayor-table2a">
              <div className="row">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-warning table-bor-2">
                      <th scope="col">{t("Sl-No")}</th>
                      <th scope="col">{t("Name")}</th>
                      <th scope="col">{t("Portfolios")} </th>
                      <th scope="col">{t("Office contact details")} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td> Firhad Hakim Hon'ble Mayor </td>
                      <td>
                        
                        {t("Water Supply,Finance & Accounts, Grievances, Cultural Affairs, KEIIP, Licence")} ,<br /> 
                        {t("Building,Central Stores,Assessment -Collection,TPDD and other")} <br />
                        {t("departments not allotted to other members")}.
                      </td>
                      <td>
                        
                        Office : 2286-1211 .<br /> Fax : 2286-1311/1011. <br />
                        EPABX : 2286-1000, Extn. 2471 <br /> Int. Com.201
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='col-lg-12'>
            <div className="inner-title3">
                  <h3 className="fontSizeClass"> {t("The Deputy Mayor and The Members, Mayor-In-Council")} </h3>
            </div>
          </div>
          <div className='col-lg-12'>
            <div className="mayor-table mayor-table2a my-2">
              <div className='row'>
                  <div className="mayor-table">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="table-warning table-bor-2">
                            <th className="bd-wid" scope="col">
                              {t("Sl-No")}
                            </th>
                            <th scope="col">{t("Name")}</th>
                            <th scope="col">{t("Portfolios")} </th>
                            <th scope="col">{t("Office Phone Nos")}. </th>
                            <th scope="col"> {t("e-mail")} </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">1</th>
                            <td> Sri Atin Ghosh Deputy Mayor </td>
                            <td>
                              
                              Health,Vector Control, Disinfectant Scheme,
                              Archives,P&FA(F&SS),Central Medical Stores,MTM TB
                              Hospital.
                            </td>
                            <td> 2286-1120 (Direct) 22861000 Extn. 2465 </td>
                            <td>
                              
                              mmic_hlth@kmcgov.in atinghosh503@gmail.com
                              ghosh.atin2009@yahoo.com
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">2</th>
                            <td> Sri Debabrata Majumder MMIC</td>
                            <td> SWM </td>
                            <td> 2286-1116 (Direct) 22861000 Extn. 2466 </td>
                            <td> mmic_swm@kmcgov.in dmswm_KMC@rediff.com </td>
                          </tr>
                          <tr>
                            <th scope="row">3</th>
                            <td> Sri Debasish Kumar MMIC </td>
                            <td>
                              
                              Parks & Gardens, Sports,Advertisement, Parking &
                              Hawker's Rehabilitation Schemes
                            </td>
                            <td> 2286-1114 (Direct) 22861000 Extn. 2499 </td>
                            <td> mmic_pgs@kmcgov.in </td>
                          </tr>
                          <tr>
                            <th scope="row">4</th>
                            <td> Sri Sandip Ranjan Bakshi MMIC </td>
                            <td> Lighting & Electricity </td>
                            <td> 2286-1119 (Direct) 22861000 Extn. 2463 </td>
                            <td> mmic_ltg@kmcgov.in </td>
                          </tr>
                          <tr>
                            <th scope="row"> 5 </th>
                            <td> Sri Jiban Saha MMIC </td>
                            <td> I & PR,EWS,Printing,IUM,Big-dia Tubewell </td>
                            <td> 2286-1123(Direct) 22861000 Extn. 2568 </td>
                            <td> mmic_ipr@kmcgov.in </td>
                          </tr>
                          <tr>
                            <th scope="row"> 6 </th>
                            <td> Sri Tarak Singh MMIC </td>
                            <td>
                              
                              Sewerage & Drainage Including( Drainage Pumping
                              Station),SC(Mechanical)
                            </td>
                            <td> 2286-111522861000 Extn. 2467 </td>
                            <td> mmic_sd@kmcgov.in </td>
                          </tr>
                          <tr>
                            <th scope="row"> 7 </th>
                            <td> Smt Mitali Banerjee MMIC </td>
                            <td>
                              
                              Swasthyasathi, BPL, NSAP, NFBS, NGR, Rupashri,
                              Khadyasathi, NULM, Kanyashree
                            </td>
                            <td> 2286-1122(Direct) 22861000 Extn. 2468</td>
                            <td> mmic_bpl_nsap@kmcgov.in </td>
                          </tr>
                          <tr>
                            <th scope="row"> 8 </th>
                            <td> Sri Swapan Samaddar MMIC </td>
                            <td> Bustee Development, Environment & Heritage </td>
                            <td> 2252-0280(Direct) 22861000 Extn. 2912 </td>
                            <td> mmic_bustee@kmcgov.in </td>
                          </tr>
                          <tr>
                            <th scope="row"> 9 </th>
                            <td> Amiruddin(Bobby) MMIC </td>
                            <td> Market </td>
                            <td> 2286-1118 (Direct) 22861000 Extn. 2569 </td>
                            <td> mmic_mkt@kmcgov.in </td>
                          </tr>
                          <tr>
                            <th scope="row"> 10 </th>
                            <td> Sri Sandipan Saha MMIC </td>
                            <td> Education and IT </td>
                            <td> 2252-0281 22861000 Extn. 2904 </td>
                            <td> mmic_edn@kmcgov.in </td>
                          </tr>
                          <tr>
                            <th scope="row"> 11 </th>
                            <td> Sri Ram Payre Ram MMIC </td>
                            <td> W.B.U.E.S </td>
                            <td> - </td>
                            <td> - </td>
                          </tr>
                          <tr>
                            <th scope="row"> 12 </th>
                            <td> Sri Avijit Mukherjee MMIC </td>
                            <td> Roads and Engineering </td>
                            <td> 2252-0281 22861000 Extn. 2904 </td>
                            <td> mmic_re@kmcgov.in avijitmukherjeekmc@gmail.com </td>
                          </tr>
                          <tr>
                            <th scope="row"> 13 </th>
                            <td> Sri Baiswanor Chatterjee MMIC </td>
                            <td> Housing,Law,PPP and Personnel </td>
                            <td> - </td>
                            <td> - </td>
                          </tr>
                        </tbody>
                      </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  
  )
}

export default Overview