import React from 'react';
import { Link } from "react-router-dom";

const KMC_Central_records_Fees = () => {
  return (
<>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> KMC Central Records </h4>
        </div>
      </div>
    </div>
  </section>

  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/KMC_Central_records_Home'>
                    Central Records Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/KMC_Central_records_Fees'>
                    Fees
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title">
                <h3 className="fontSizeClass"> Schedule of Fees & Charges for the year 2022- 2023</h3>
              </div>
                    <table className="table table-bordered officials rm-mg">
                        <tbody>
                            <tr bgcolor="#302ba0" tabIndex={0}>
                            <td width="10%"  style={{color: "#fff", borderLeft: "1px solid #302ba0"}}
                                align="center" valign="middle" >
                                1
                            </td>
                            <td width="60%"  align="center" valign="middle" style={{ color: "#fff" }}>
                            Searching Fees of Assessment Register From Record Deptt	
                            </td>
                            <td width="30%"  align="center" valign="middle" style={{ color: "#fff" }}>
                            Figures in Rs.
                            </td>
                            </tr>

                            <tr>
                                <td>A)</td>
                                <td>For Non-Bustee	</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>(i)	having annual valuation upto Rs. 18,000 /-	</td>
                                <td>1,250.00 Per Assessee</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>(ii)having Annual valuation above Rs 18,000/-	</td>
                                <td>2,500.00 Per Assessee</td>
                            </tr>
                            <tr>
                                <td>B)</td>
                                <td>For Bustee/Hut/Colony Irrespective of A.V.	</td>
                                <td>150.00 Per Assessee</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <table className="table table-bordered officials rm-mg">
                        <tbody>
                            <tr bgcolor="#302ba0" tabIndex={0}>
                            <td width="10%"  style={{color: "#fff", borderLeft: "1px solid #302ba0"}}
                                align="center" valign="middle" >
                                2
                            </td>
                            <td width="60%"  align="center" valign="middle" style={{ color: "#fff" }}>
                            Updated Certified copy of Assessment Book		
                            </td>
                            <td width="30%"  align="center" valign="middle" style={{ color: "#fff" }}>
                            Figures in Rs.
                            </td>
                            </tr>

                            <tr>
                                <td>A)</td>
                                <td>For Non-Bustee	</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>(i)	having annual valuation upto Rs. 12,000/-	</td>
                                <td>650.00 Per Assessee</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>(ii)having annual valuation above Rs. 12,000/- but up to Rs. 18,000/-	</td>
                                <td>1,250.00 Per Assessee</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>(iii)having annual valuation above Rs. 18,000/-	</td>
                                <td>2,500.00 Per Assessee</td>
                            </tr>
                            <tr>
                                <td>B)</td>
                                <td>For Bustee/Hut/Colony Irrespective of A.V.	</td>
                                <td>150.00 Per Assessee</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <table className="table table-bordered officials rm-mg">
                        <tbody>
                            <tr bgcolor="#302ba0" tabIndex={0}>
                            <td width="10%"  style={{color: "#fff", borderLeft: "1px solid #302ba0"}}
                                align="center" valign="middle" >
                                3
                            </td>
                            <td width="60%"  align="center" valign="middle" style={{ color: "#fff" }}>
                            General Search			
                            </td>
                            <td width="30%"  align="center" valign="middle" style={{ color: "#fff" }}>
                            Figures in Rs.
                            </td>
                            </tr>

                            <tr>
                                <td>A)</td>
                                <td>For Non-Bustee	</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>A)</td>
                                <td>For each file	</td>
                                <td>250.00</td>
                            </tr>
                            <tr>
                                <td>B)</td>
                                <td>For each Meeting proceedings in respect of one item	</td>
                                <td>250.00</td>
                            </tr>
                            <tr>
                                <td>C)</td>
                                <td>For certified copies having 100 words or part thereof		</td>
                                <td>250.00</td>
                            </tr>
                            <tr>
                                <td>D)</td>
                                <td>For Expeditious fees for above cases	</td>
                                <td>250.00</td>
                            </tr>
                            <tr>
                                <td>E)</td>
                                <td>For Expeditious fees for cases prior to 1965	</td>
                                <td>400.00</td>
                            </tr>
                            <tr>
                                <td>B)</td>
                                <td>For Bustee/Hut/Colony Irrespective of A.V.	</td>
                                <td>150.00 Per Assessee</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <table className="table table-bordered officials rm-mg">
                        <tbody>
                            <tr bgcolor="#302ba0" tabIndex={0}>
                            <td width="10%"  style={{color: "#fff", borderLeft: "1px solid #302ba0"}}
                                align="center" valign="middle" >
                                4
                            </td>
                            <td width="60%"  align="center" valign="middle" style={{ color: "#fff" }}>
                            Fees for searching Trade & Profession Demand Register				
                            </td>
                            <td width="30%"  align="center" valign="middle" style={{ color: "#fff" }}>
                            Figures in Rs.
                            </td>
                            </tr>

                            <tr>
                                <td>A)</td>
                                <td>Fees for searching Trade & Profession Demand Register in respect of one entry	</td>
                                <td>150.00 Per Assessee</td>
                            </tr>
                            <tr>
                                <td>B)</td>
                                <td>Fees for certified extract in respect of one entry (with searching fees, ordinary delivery)	</td>
                                <td>200.00 Per Assessee</td>
                            </tr>
                            <tr>
                                <td>c)</td>
                                <td>Fees for certified extract in respect of one entry (with searching fees, urgent delivery)	</td>
                                <td>400.00 Per Assessee</td>
                            </tr>
                           
                        </tbody>
                    </table>
                    <br />
                    <table className="table table-bordered officials rm-mg">
                        <tbody>
                            <tr bgcolor="#302ba0" tabIndex={0}>
                            <td width="10%"  style={{color: "#fff", borderLeft: "1px solid #302ba0"}}
                                align="center" valign="middle" >
                                5
                            </td>
                            <td width="60%"  align="center" valign="middle" style={{ color: "#fff" }}>
                            Fees for Inspection of Building Sanctioned Plan			
                            </td>
                            <td width="30%"  align="center" valign="middle" style={{ color: "#fff" }}>
                            Figures in Rs.
                            </td>
                            </tr>

                            <tr>
                                <td>A)</td>
                                <td>Inspection by owner in respect of sanctioned plan	</td>
                                <td>650.00 Per Assessee</td>
                            </tr>
                            <tr>
                                <td>B)</td>
                                <td>Inspection by other than owner for one sanction plan (permission to be obtained from the office of D.G.Bldg.)	</td>
                                <td>1,250.00 Per Assessee</td>
                            </tr>
                            <tr>
                                <td>c)</td>
                                <td>Inspection by owner in respect of sanctioned plan with paper	</td>
                                <td>750.00 Per Assessee</td>
                            </tr>
                            <tr>
                                <td>D)</td>
                                <td>Inspection by other than owner for one sanctioned plan with paper	</td>
                                <td>1,500.00 Per Assessee</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <p><strong style={{textAlign:"center"}}>Note : Rs. 100/- to be paid extra as searching fee</strong></p>
                    <table className="table table-bordered officials rm-mg">
                        <tbody>
                            <tr bgcolor="#302ba0" tabIndex={0}>
                            <td width="10%"  style={{color: "#fff", borderLeft: "1px solid #302ba0"}}
                                align="center" valign="middle" >
                                6
                            </td>
                            <td width="40%"  align="center" valign="middle" style={{ color: "#fff" }}>
                            Form No. (Name)	                            </td>
                            <td width="30%"  align="center" valign="middle" style={{ color: "#fff" }}>
                            Rate Per Form	
                            </td>
                            <td width="20%"  align="center" valign="middle" style={{ color: "#fff" }}>
                            Remarks
                            </td>
                            </tr>

                            <tr>
                                <td>A)</td>
                                <td>A-42 (Mutation Form)</td>
                                <td>Free</td>
                                <td>Can be downloaded from Website</td>
                            </tr>
                            <tr>
                                <td>B)</td>
                                <td>A-75</td>
                                <td>Free</td>
                                <td>Can be downloaded from Website</td>  
                            </tr>
                            <tr>
                                <td>C)</td>
                                <td>Contractors Bill Forms	</td>
                                <td>Free</td>
                                <td>Can be downloaded from Website</td>  
                            </tr>
                            <tr>
                                <td>D)</td>
                                <td>P-I (Petty Improvement, CIVIL)	</td>
                                <td>Free</td>
                                <td>Can be downloaded from Website</td>  
                            </tr>
                            <tr>
                                <td>E)</td>
                                <td>P-I (Petty Improvement, LIGHTING)	</td>
                                <td>Free</td>
                                <td>Can be downloaded from Website</td>  
                            </tr>
                        </tbody>
                    </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default KMC_Central_records_Fees