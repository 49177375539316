import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const List_of_Sewage_Treatment_Plants  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2"> </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Home'>{t("Drainage Home")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageNetworkMap.jsp' target="_blank">
                    {t("Ward wise Drainage Network Map")}   </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Fees_Charges'>
                    {t("Fees & Charges")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Drainage_Pumping_station'>
                    {t("Drainage Pumping Station")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/List_of_Sewage_Treatment_Plants'>
                    {t("Sewage Treatment Plants")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageFee.jsp' target="_blank">
                    {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageDemandPrint.jsp' target="_blank">
                    {t("Print Unpaid Bill")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalDrainagePayment.jsp' target="_blank">
                    {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DCDuplicateReceipt.jsp' target="_blank">
                    {t("Reprint e-Receipt")}  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DCDuplicateReceipt.jsp' target="_blank">
                    {t("List of KMC Drainage Activity")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Drainage_Faqs'> {t("FAQs")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Sewerage_Drainage_Dept'>{t("Contact Details")}</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass"> {t("List of Sewage Treatment Plants")} </h3>
              </div>
              <div className="mayor-table2 ">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-warning table-bor-2">
                      <th scope="col"> {t("Sl.No")}. </th>
                      <th scope="col"> {t("Name")} </th>
                      <th scope="col"> {t("Phone No")}. </th>
                      <th scope="col"> {t("Officer In-Charge")} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row"> 1. </td>
                      <td> {t("Gardenreach STP")} </td>
                      <td> - </td>
                      <td>{t("Kajal Roy Ex.Engr.(E) Mob : 9874272283")}</td>
                    </tr>
                    <tr>
                      <td scope="row"> 2. </td>
                      <td> {t("Bangur STP")} </td>
                      <td> -</td>
                      <td>{t("Tapan Sarkar Ex.Engr.(E) Mob : 8013763737")}</td>
                    </tr>
                    <tr>
                      <td scope="row"> 3. </td>
                      <td> {t("South Suburban (Keorapukur) STP")} </td>
                      <td> -</td>
                      <td> {t("Supriyo Sengupta Ex.Engr.(E) Mob : 9830311559")} </td>
                    </tr>
                    <tr>
                      <td scope="row"> 4. </td>
                      <td> {t("Baghajatin STP")} </td>
                      <td>- </td>
                      <td> {t("Pradip Nandy Ex.Engr (M) Mob : 9433112634")} </td>
                    </tr>
                    <tr>
                      <td scope="row"> 5. </td>
                      <td> {t("Hatisur STP")} </td>
                      <td> - </td>
                      <td>{t("Pradip Nandy Ex.Engr (M) Mob : 9433112634")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default List_of_Sewage_Treatment_Plants