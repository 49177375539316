import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const KMC_Recruitments = () => {
  const { t, i18n, ready } = useTranslation();
  const increaseFontSizeBy1px = () => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style);
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const fileDownload = (fileStoreId) => {
    let url = `https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`;
    axios
      .post(url, { fileId: fileStoreId, userId: "392" })
      .then((res) => {
        console.log("response is --- ", res);

        if (res && res.data && res.status === 200) {
          const base64 = res.data[0] && res.data[0].base64_data;
          const pdfData = "data:application/pdf;base64," + base64;

          let html =
            "<html>" +
            "<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>" +
            "<body>" +
            '<iframe src="' +
            pdfData +
            '"></iframe>' +
            "</body></html>";
          console.log(html);
          let a = window.open();
          a.document.write(html);
        }
      })
      .catch((e) => {
        // setLoader(false);
        console.log("The error is ", e);
      });
  };
  const decreaseFontSizeBy1px = () => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style);
      el[i].style.fontSize = (fontSize - 1) + 'px';
    }
  };
  return (
    <>
      <section id="hero" className="hero hero2">
        <div
          className="social-media wow slideInDown"
          style={{ visibility: "visible", animationName: "slideInDown" }}
        >
          <div className="facebook" rel="ebInfo" title="facebook">
            <a
              href="https://www.facebook.com/KolkataMunicipalCorporation.official"
              target="_blank"
            >
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a
              href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp"
              target="_blank"
            >
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>

        <div className="carousel-item active">
          <img src="assets/img/inner-bg2.png" className="img-fluid" alt="img" />
        </div>
      </section>
      {/* End Slider */}
      <section className="section inner-pad1">
        <div className="container ">
          <div className="row">
            <div className="col-lg-3">
              <div className="site-menu  ">
                <nav className="sidebar card py-2">
                  <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item">
                      <Link
                        className="nav-link active1 fontSizeClass"
                        to="/KMC_Recruitments"
                      >
                        {t("KMC Recruitments")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/KMC_Results"
                      >
                        {t("KMC Results")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/KMC_Training"
                      >
                        {t("KMC Training")}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="container">
                <div className="row">
                  <div className="inner-title2 bas">
                    <h3 className="fontSizeClass"> {t("Recruitments")} </h3>
                  </div>
                  <div className="mayor-table health-dept-table m-ta-2">
                    <table className="table table-bordered officials">
                      <tbody>
                        <tr
                          bgcolor="#302ba0"
                          tabIndex={0}
                          align="center"
                          valign="middle"
                        >
                          <td
                            width="90%"
                            className="text-white tble-brder-left"
                          >
                            {t("Description")}
                          </td>
                          <td width="10%" className="text-white">
                            {t("Date")}
                          </td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a className="nav-link fontSizeClass" onClick={(e) => fileDownload("2013")}
                              style={{ cursor: "pointer" }}>
                              Walk-in Interview held on 22.09.2023, 29nos. of Medical officer (part time)
                              contractual basis under the Kolkata City NUHM Society, Adv. No . 07/Kolkata City
                              NUHM Society/2023 – 24. dated 21.08.2023
                            </a>
                          </td>
                          <td>12.09.2023</td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a className="nav-link fontSizeClass" onClick={(e) => fileDownload("1012")}
                              style={{ cursor: "pointer" }}>
                              Walk-in Interview on 29.08.2023 26nos. of Medical Officer on contractual basis for
                              Urban Health & Wellness Centre under the Health Department of
                              Kolkata Municipal Corporation Advertisement No . H/03/KMC/2023-24 dated.
                              07.08.2023
                            </a>
                          </td>
                          <td>18.08.2023</td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            {/* <a className="nav-link fontSizeClass" onClick={(e) => fileDownload("2011")}
                              style={{ cursor: "pointer" }}>
                              Engagement of 17nos. of Accountant-I under
                              Kolkata City NUHM Society on contractual basis.
                              Advertisement no 06/Kolkata City NUHM Society/2023 – 24 dated: 05.08.2023
                            </a> */}
                            <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Accountant_NUHM_16_08_2023.pdf" target={"_blank"}>
                              {t("Engagement of 17nos. of Accountant-I under Kolkata City NUHM Society on contractual basis. Advertisement no 06/Kolkata City NUHM Society/2023 – 24 dated: 05.08.2023")}</a>

                          </td>
                          <td>16.08.2023</td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a className="nav-link fontSizeClass" onClick={(e) => fileDownload("2010")}
                              style={{ cursor: "pointer" }}>
                              Contractual Appointment
                            </a>
                          </td>
                          <td>04.08.2023</td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a className="nav-link fontSizeClass" onClick={(e) => fileDownload("2009")}
                              style={{ cursor: "pointer" }}>
                              Walk-in Interview on 19.08.2023, for the post of Specialist (G&O, Medicine, Paediatrics,
                              Ophthalmologist, Cardiology, ENT, Endocrinology and Orthopaedics) on contractual basis
                              under the Health Department of Kolkata Municipal Corporation against
                              Adv. No . H/02/KMC/2023–24. dated 15.07.2023
                            </a>
                          </td>
                          <td>01.08.2023</td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            {/* <a className="nav-link fontSizeClass" onClick={(e) => fileDownload("2032")}
                              style={{ cursor: "pointer" }}>
                              Engagement of 8 nos. Laboratory Technicians on contractual basis under the
                              Kolkata City NUHM Society against Adv. No . 05/Kolkata City NUHM Society/2023–24.
                              dated 15.07.2023
                            </a> */}
                             <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Laboratory_Technicians_24_07_2023.pdf" target={"_blank"}>
                              {t("Engagement of 8 nos. Laboratory Technicians on contractual basis under the Kolkata City NUHM Society against Adv. No . 05/Kolkata City NUHM Society/2023–24. dated 15.07.2023<")}</a>

                          </td>
                          <td>24.07.2023</td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a className="nav-link fontSizeClass" onClick={(e) => fileDownload("2007")}
                              style={{ cursor: "pointer" }}>
                              Walk-in Interview on 04.08.2023, 59nos. Medical officer (part time) contractual
                              basis under the Kolkata City NUHM Society against Adv. No . 04/Kolkata City
                              NUHM Society/2023 – 24. dated 15.07.2023
                            </a>
                          </td>
                          <td>19.07.2023</td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1588")}
                            >
                              Walk-in Interview on 06.07.2023, 04 nos. Medical
                              Officer on contractual basis for Kidderpore U-CHC
                              under Kolkata City NUHM Society against Adv. No .
                              03/Kolkata City NUHM Society/2023–24. dated
                              19.06.2023
                            </a>
                          </td>
                          <td>28.06.2023</td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1589")}
                            >
                              Walk-in Interview on 12.06.2023, 05 nos.
                              Specialist (Surgeon, Orthopedics, Medicine and
                              Anesthetist) on contractual basis for Kidderpore
                              U-CHC under Kolkata City NUHM Society against Adv.
                              No . 02/Kolkata City NUHM Society/2023–24. dated
                              06.06.2023
                            </a>
                          </td>
                          <td>07.06.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1590")}
                            >
                              Walk-in Interview on 12.06.2023, 90 nos.
                              Specialist (G&O, Medicine, Paediatrics,
                              Ophthalmologist, Cardiology, ENT, Endocrinology
                              and Orthopendics) on contractual basis under the
                              Health Department of Kolkata Municipal Corporation
                              against Adv. No . H/01/KMC/2023–24. dated
                              31.05.2023
                            </a>
                          </td>
                          <td>06.06.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1591")}
                            >
                              Engagement of STS, STLS, LT, TBHV and MO(NTEP)
                              under National Tuberculosis Elimination Programme
                              on contractual basis against Adv. No .
                              KDTCS/Office/37/23. dated 03.06.2023
                            </a>
                          </td>
                          <td>05.06.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1592")}
                            >
                              Corrigendum to Advertisement No. 01/Kolkata City
                              NUHM Society/2023-24. Dated 12.04.2023
                            </a>
                          </td>
                          <td>15.05.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1593")}
                            >
                              Walk-in Interview on 22.05.2023, 89nos. Medical
                              officer (part time) contractual basis under the
                              Kolkata City NUHM Society against Adv. No .
                              01/Kolkata City NUHM Society/2023 – 24. Dated
                              12.04.2023{" "}
                            </a>
                          </td>
                          <td>11.05.2023</td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1594")}
                            >
                              Engagement of 30nos. of Community Health Assistant
                              (Urban) contractual basis under XV Finance
                              Commission. Advt. No. H/07/KMC/2022-23. Dated
                              20.10.2022
                            </a>
                          </td>
                          <td>27.03.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1595")}
                            >
                              Engagement of 17nos.Staff Nurse for Polyclinic on
                              contractual basis under XV Finance Commission.
                              Advt. No. H/06/KMC/2022-23. Dated 20.10.2022
                            </a>
                          </td>
                          <td>24.03.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1596")}
                            >
                              Corrigendum to Advertisement No. H/04/KMC/2022-23.
                              dated 23.02.2023.
                            </a>
                          </td>
                          <td>13.03.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1597")}
                            >
                              Walk-in Interview on 20.03.2023 & 21.03.2023,
                              01no. of Analyst & 01 no. of Driver contractual
                              basis under the Health Department of Kolkata
                              Municipal Corporation against Adv. No .
                              H/04/KMC/2022-23 dated. 23.02.2023.
                            </a>
                          </td>
                          <td>09.03.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1598")}
                            >
                              Engagement of 30 nos.Staff Nurse on contractual
                              basis under XV Finance Commission.Advt. No.
                              H/05/KMC/2022-23. Dated 20.10.2022
                            </a>
                          </td>
                          <td>28.02.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1599")}
                            >
                              Engagement of 17nos.Counsellor (contractual) basis
                              under the Health Department of Kolkata Municipal
                              Corporation against Adv. No . H/03/KMC/2022-23
                              dated. 20.10.2022
                            </a>
                          </td>
                          <td>08.02.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1600")}
                            >
                              Walk-in Interview on 15.02.2023, 29nos. Medical
                              officer contractual basis under the Kolkata City
                              NUHM Society against Adv. No . 13/Kolkata City
                              NUHM Society/2022 – 23. dated 28.01.2023
                            </a>
                          </td>
                          <td>03.02.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1601")}
                            >
                              Engagement of 01 no.(only one) Quality Manager
                              under Kolkata City NUHM Society on contractual
                              basis through against Adv. No . 12/Kolkata City
                              NUHM Society/2022– 23. dated 15.12.2022
                            </a>
                          </td>
                          <td>21.01.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td width="90%">
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1602")}
                            >
                              Walk-in Interview on 01.02.2023, 55nos. Medical
                              Officer (part time) contractual basis under the
                              Kolkata City NUHM Society against Adv. No .
                              06/Kolkata City NUHM Society/2022– 23.dated
                              13.01.2022
                            </a>
                          </td>
                          <td width="10%">19.01.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1603")}
                            >
                              Applications are invited from the eligible
                              candidates,superannuated/to be superannuated on or
                              before 31.01.2023, for selection and appointment
                              on re- employment basis to the post of Special
                              Officer (Building) in KMC,to hear and deal with
                              the matter U/S 400, SUB-SEC (I) AND 416 of the KMC
                              ACT,1980
                            </a>
                          </td>
                          <td>17.01.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1604")}
                            >
                              Engagement of 01(only one) no. of Ophthalmic
                              Assistant for Khidderpore U-CHC under Kolkata City
                              NUHM Society on contractual basis. Advt. No.
                              11/Kolkata City NUHM Society/2022 – 23 Dated:
                              12.11.20222
                            </a>
                          </td>
                          <td>16.01.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1605")}
                            >
                              Engagement of 05 nos.of Staff Nurse for
                              Khidderpore U-CHC under Kolkata City NUHM Society
                              on contractual basis. Advt. No. 10/Kolkata City
                              NUHM Society/2022 – 23 Dated: 12.11.2022
                            </a>
                          </td>
                          <td>16.01.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1606")}
                            >
                              Engagement of 01 (only one) no. of Pharmacist for
                              Khidderpore U-CHC under Kolkata City NUHM Society
                              on contractual basis. Advt. No. 09/Kolkata City
                              NUHM Society/2022 – 23 Dated: 12.11.2022
                            </a>
                          </td>
                          <td>16.01.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1607")}
                            >
                              Engagement of 02 nos. of Laboratory Technicians
                              for Khidderpore U-CHC under Kolkata City NUHM
                              Society on contractual basis. Advt. No. 08/Kolkata
                              City NUHM Society/2022 – 23 Dated: 12.11.2022
                            </a>
                          </td>
                          <td>5.01.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1608")}
                            >
                              ADDENDUM of Notice dated 22.09.2022. Inviting
                              application for contractual engagement of
                              different posts under Air Quality Monitoring Cell
                              (AQMC), Kolkata Municipal Corporation, the last
                              date for submission of applications for all posts
                              except DEO,has been extended upto 31.01.2023.
                            </a>
                          </td>
                          <td>5.01.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1609")}
                            >
                              Walk-in Interview on 16.12.2022, 68 nos.
                              Specialist (Medicine, Paediatrics, G&O and
                              Ophthalmologist) contractual basis under the
                              Health Department of Kolkata Municipal Corporation
                              against Adv. No . H/02/KMC/2022–23. dated
                              20.10.2022
                            </a>
                          </td>
                          <td>7.12.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1610")}
                            >
                              Walk-in Interview on 14.12.2022, 30 nos.Medical
                              Officer contractual basis under the Kolkata
                              Municipal Corporation(Health Department) against
                              Adv. No . H/01/KMC/2022–23. dated 20.10.2022.
                            </a>
                          </td>
                          <td>5.12.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1611")}
                            >
                              Corrigendum to Advertisement No. 07/Kolkata City
                              NUHM Society/2022-23. Dated 12.11.2022.
                            </a>
                          </td>
                          <td>30.11.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1612")}
                            >
                              Walk-in Interview on 06.12.2022, 5 nos. Specialist
                              Medical officer & 2 nos. Medical officer
                              contractual basis under the Kolkata City NUHM
                              Society against Adv. No . 07/Kolkata City NUHM
                              Society/2022– 23. dated 12.11.2022
                            </a>
                          </td>
                          <td>23.11.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1613")}
                            >
                              Applications are invited for contractual
                              engagement of different posts under Air Quality
                              Monitoring Cell, Kolkata Municippal Corporation
                            </a>
                          </td>
                          <td>22.09.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1614")}
                            >
                              Walk-in Interview on 23.09.2022, 67 nos. Medical
                              Officer (part time) contractual basis under the
                              Kolkata City NUHM Society against Adv. No .
                              05/Kolkata City NUHM Society/2022– 23. dated
                              09.09.2022
                            </a>
                          </td>
                          <td>14.09.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("115")}
                            >
                              Engagement of 186 nos. Staff Nurse contractual for
                              its Urban Primary Health Centers in Kolkata City
                              NUHM Society areas against Advt. No. 04/Kolkata
                              City NUHM Society/2022 – 23.dated: 06.09.2022
                            </a>
                          </td>
                          <td>14.09.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1615")}
                            >
                              Application are invited from the eligible women
                              candidates for the post of Honorary Health Worker
                              (HHW) Memo No. H/L/153/2022-23.dated 25.08.2022
                            </a>
                          </td>
                          <td>25.08.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1616")}
                            >
                              Engagement of 285 nos.of Community Health
                              Assistant (Urban) contractual for its Urban
                              Primary Health Centers in Kolkata City NUHM
                              Society areas against Advt. No.03/Kolkata City
                              NUHM Society/2022 – 23 Dated: 22.07.2022
                            </a>
                          </td>
                          <td>01.08.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1617")}
                            >
                              List of Eligible Candidates for Written
                              Examination for Selection of 32 nos of Community
                              Organizers on contractual basis under DAY-NULM in
                              the KMC
                            </a>
                          </td>
                          <td>14.06.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1618")}
                            >
                              Walk-in Interview on 12.05.2022, 27nos. Medical
                              officer & 71 nos.Medical officer (part time)
                              contractual basis under the Kolkata City NUHM
                              Society against Adv. No . 02/Kolkata City NUHM
                              Society/2022– 23. Dated 30.04.2022
                            </a>
                          </td>
                          <td>30.04.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1619")}
                            >
                              Engagement of 18 nos. Pharmacist for its urban
                              primary health centers in Kolkata City NUHM
                              Society areas on contractual basis. Adv. No .
                              01/Kolkata City NUHM Society/2022 – 23. Dt.
                              05.04.2022
                            </a>
                          </td>
                          <td>06.04.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1620")}
                            >
                              Walk-in Interview on 10.03.2021, 22 nos. Medical
                              officer & 53 nos. Medical officer (part time)
                              contractual basis under the Kolkata City NUHM
                              Society against Adv. No . 10/Kolkata City NUHM
                              Society/2021 – 22.Dated 26.02.2022
                            </a>
                          </td>
                          <td>04.03.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1621")}
                            >
                              Engagement of 2 Software Support Personnel(SSP) on
                              contract basis for e-Office in KMC.
                            </a>
                          </td>
                          <td>24.01.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1622")}
                            >
                              Engagement of 14 nos.of Laboratory Technicians
                              (contractual) for its Urban Primary Health Centers
                              in Kolkata City NUHM Society areas against Advt.
                              No. 09/Kolkata City NUHM Society/2021 – 22.
                            </a>
                          </td>
                          <td>24.11.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1623")}
                            >
                              Walk-in Interview on 29.11.2021, 14 nos. Medical
                              officer & 46 nos. Medical officer (part time)
                              contractual basis under the Kolkata City NUHM
                              Society against Adv. No . 07/Kolkata City NUHM
                              Society/2021 – 22.
                            </a>
                          </td>
                          <td>20.11.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1624")}
                            >
                              Engagement of 01 Accounts Assistant (contractual)
                              under Kolkata City NUHM Society against Adv. No .
                              08/Kolkata City NUHM Society/2021 – 22.
                            </a>
                          </td>
                          <td>18.11.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1625")}
                            >
                              Notice and application form for the post of
                              District Co-ordinator( IT) & District
                              Co-ordinator(Hospital) on contractual basis for
                              one year.
                            </a>
                          </td>
                          <td>08.11.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1626")}
                            >
                              Recruitment Examination, 2021 for the post of
                              Medical Officer (General) under kolkata municipal
                              corporation.
                            </a>
                          </td>
                          <td>22.09.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1627")}
                            >
                              Walk-in Interview on 10.09.2021, 18 nos. Medical
                              officer & 55 nos. Medical officer (part time)
                              contractual basis under the Kolkata City NUHM
                              Society against Adv. No . 06/Kolkata City NUHM
                              Society/2021 – 22.
                            </a>
                          </td>
                          <td>03.09.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1628")}
                            >
                              Notice and Application Form for the post of
                              Community Organiser.
                            </a>
                          </td>
                          <td>02.08.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1629")}
                            >
                              Engagement of 20nos. Pharmacist for its urban
                              primary health centers in Kolkata City NUHM
                              Society areas on contractual basis against Advt.
                              No . 03/Kolkata City NUHM Society/2021 – 22.
                              Dated: 06.05.2021.
                            </a>
                          </td>
                          <td>26.07.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1630")}
                            >
                              Recruitment for different posts of National
                              Tuberculosis Elimination Program (NTEP) under
                              Kolkata District TB Control Society (Kolkata
                              Municipal Corporation) on purely contractual
                              basis. as per norms of State NUHM Society of West
                              Bengal.
                            </a>
                          </td>
                          <td>15.07.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1631")}
                            >
                              Walk-in Interview on 06.07.2021, 27 nos. Medical
                              officer & 64 nos. Medical officer (part time)
                              contractual basis under the Kolkata City NUHM
                              Society against Adv. No . 05/Kolkata City NUHM
                              Society/2021 – 22.
                            </a>
                          </td>
                          <td>24.06.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1632")}
                            >
                              Walk-in Interview on 30.06.2021, 06 nos.
                              Sub-Registrars (contractual) basis under the
                              Health Department of Kolkata Municipal Corporation
                              against Adv. No . H/01/KMC/2021-22 Dt.
                              12.06.2021.(tentative subject to changes).
                            </a>
                          </td>
                          <td>24.06.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1633")}
                            >
                              Engagement of 205 nos Staff Nurse for its urban
                              primary health centers in Kolkata City NUHM
                              Society areas on contractual basis, against Adv.
                              No . 02/Kolkata City NUHM Society/2021 – 22.
                            </a>
                          </td>
                          <td>08.05.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1634")}
                            >
                              Walk-in Interview on 24.05.2021, 71 nos Medical
                              officer (part time) contractual basis under the
                              Kolkata City NUHM Society against Adv. No .
                              04/Kolkata City NUHM Society/2021 – 22
                            </a>
                          </td>
                          <td>06.05.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1635")}
                            >
                              Corrigendum to Advertisement no 07/Kolkata City
                              NUHM Society/ 2020-2021
                            </a>
                          </td>
                          <td>03.02.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1637")}
                            >
                              Walk-in Interview on 08.02.2021, 46 nos Medical
                              officer (full time) contractual basis under the
                              Kolkata City NUHM Society against Adv. No .
                              07/Kolkata City NUHM Society/2020 – 21
                            </a>
                          </td>
                          <td>01.02.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1638")}
                            >
                              Walk-in Interview on 11.11.2020, 40 nos Medical
                              officer (full time) & 61 nos Medical officer (part
                              time) contractual basis under the Kolkata City
                              NUHM Society against Adv. No . 06/Kolkata City
                              NUHM Society/2020 – 21
                            </a>
                          </td>
                          <td>05.11.2020</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1639")}
                            >
                              Applications are invited through e-mail id:
                              kmc_personnel@kmcgov.in from the Superannuated/to
                              be superannuated Govt. Officers equivalent to the
                              rank of Dy. Secretary & above of the Govt. of West
                              Bengal,for Preparation of Panel regarding
                              engagement of Inquiry Officers on contractual
                              basis, from the said Panel.
                            </a>
                          </td>
                          <td>17.10.2020</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1640")}
                            >
                              Walk-in Interview on 09.10.2020,43 nos Medical
                              officer (full time) & 58 nos Medical officer (part
                              time) contractual basis under the Kolkata City
                              NUHM Society against Adv. No . 05/Kolkata City
                              NUHM Society/2020 – 21
                            </a>
                          </td>
                          <td>01.10.2020</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1641")}
                            >
                              Walk-in Interview on 10.09.2020, 42 nos Medical
                              officer (full time) & 55 nos Medical officer (part
                              time) contractual basis under the Kolkata City
                              NUHM Society against Adv. No. 04/Kolkata City NUHM
                              Society/2020 – 21
                            </a>
                          </td>
                          <td>03.09.2020</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1042")}
                            >
                              Engagement of 26 nos Laboratory Technicians for
                              its urban primary health centers in Kolkata City
                              NUHM Society areas on contractual basis against
                              Advt. No . 03/Kolkata City NUHM Society/2020 – 21
                            </a>
                          </td>
                          <td>07.08.2020</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1043")}
                            >
                              Walk-in Interview on 14.08.2020, 42 nos Medical
                              officer (full time) & 48 nos Medical officer (part
                              time) contractual basis under the Kolkata City
                              NUHM Society against Adv. No . 02/Kolkata City
                              NUHM Society/2020 – 21
                            </a>
                          </td>
                          <td>04.08.2020</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass">
                              Notification for recruitment of 02 (two) contractual SSP.
                            </a>
                          </td>
                          <td>18.07.2020</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1044")}
                            >
                              walk-in interview on 17.06.2020, 23 nos Medical
                              Officer full time & 19 nos Medical Office Part
                              time contractual basis under the Kolkata City NUHM
                              Society against Adv. No 01/ Kolkata City NUHM
                              Society/ 2020-21.
                            </a>
                          </td>
                          <td>05.06.2020</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1645")}
                            >
                              Walk-in Interview on 16.03.2020, 140 nos Staff
                              NUHM contractual basis under the Kolkata City NUHM
                              Society against Adv. No . 11/Kolkata City NUHM
                              Society/2019 – 20.
                            </a>
                          </td>
                          <td>04.03.2020</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1646")}
                            >
                              Walk-in Interview on 07.03.2020, 24nos Medical
                              officer (full time) & 11nos Medical officer (part
                              time) contractual basis under the Kolkata City
                              NUHM Society against Adv. No . 10/Kolkata City
                              NUHM Society/2019 – 20.
                            </a>
                          </td>
                          <td>28.02.2020</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1647")}
                            >
                              Walk-in Interview on 10.02.2020, 25nos Medical
                              officer (full time) & 17nos Medical officer (part
                              time) contractual basis under the Kolkata City
                              NUHM Society against Adv. No . 09/Kolkata City
                              NUHM Society/2019 – 20.
                            </a>
                          </td>
                          <td>01.02.2020</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1648")}
                            >
                              Contractual Engagement of Two Senior Software
                              Developers.
                            </a>
                          </td>
                          <td>21.01.2020</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1647")}
                            >
                              Walk in interview for different post of K.D.T.C.S
                              ( Kolkata District TB Control Society) Ad No.
                              KDTCS/RECRUITMENT/01/2020-21.
                            </a>
                          </td>
                          <td>15.01.2020</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1648")}
                            >
                              Walk-in Interview on 09.01.2020, 28nos Medical
                              officer (full time) & 26nos Medical officer (part
                              time) contractual basis under the Kolkata City
                              NUHM Society against Adv. No . 08/Kolkata City
                              NUHM Society/2019 – 20.
                            </a>
                          </td>
                          <td>30.12.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1649")}
                            >
                              Walk-in Interview on 09.12.2019, 31nos Medical
                              officer (full time) & 23nos Medical officer (part
                              time) contractual basis under the Kolkata City
                              NUHM Society against Adv. No . 07/Kolkata City
                              NUHM Society/2019 – 20.
                            </a>
                          </td>
                          <td>30.11.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1650")}
                            >
                              Engagement of 3nos Urban Health Planning &
                              Monitoring Manager at KMC (HQ) under Kolkata City
                              NUHM Society on contractual basis, against Adv. No
                              . 07/Kolkata City NUHM Society/2019 – 20.
                            </a>
                          </td>
                          <td>20.11.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1651")}
                            >
                              Engagement of 28nos Medical officer full time
                              (contractual) reserved category and 30nos Medical
                              officer part time (contractual) for its urban
                              primary health centers in Kolkata City NUHM
                              Society areas on contractual basis through Walk-in
                              Interview on 08.11.2019, against Adv. No .
                              06/Kolkata City NUHM Society/2019 – 20
                            </a>
                          </td>
                          <td>01.11.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1652")}
                            >
                              Engagement of 28nos Medical officer full time
                              (contractual) reserved category and 28nos Medical
                              officer part time (contractual) for its urban
                              primary health centers in Kolkata City NUHM
                              Society areas on contractual basis through Walk-in
                              Interview, against Adv. No . 05/Kolkata City NUHM
                              Society/2019 – 20
                            </a>
                          </td>
                          <td>01.10.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1653")}
                            >
                              LIST OF SELECTED CANDIDATES FOR THE POST OF
                              MEDICAL OFFICER (FULL TIME) & (PART TIME).
                              ADVERTISEMENT NO- 04/KOLKATA CITY NUHM
                              SOCIETY/2019-20
                            </a>
                          </td>
                          <td>14.09.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1654")}
                            >
                              ENGAGEMENT OF 27 MEDICAL OFFICER(S) FULL
                              TIME(CONTRACTUAL) RESERVED CAREGORY AND 26 MEDICAL
                              OFFICER(S) PART TIME (CONTRACTUAL) FOR ITS URBAN
                              PRIMARY HELATH CENTRES UNDER KOLKATA CITY NUHM
                              SOCIETY ON CONTRATUAL BASIS THROUGH WALK-IN
                              INTERVIEW AGAINST ADV. NO. 04/KOLKATA CITY NUHM
                              SOCIETY /2019-20
                            </a>
                          </td>
                          <td>02.09.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1655")}
                            >
                              ENGAGEMENT OF 165 STAFF NURSE FOR ITS URBAN
                              PRIMARY HELATH CENTRES IN KOLKATA CITY NUHM
                              SOCIETY AREAS ON CONTRATUAL BASIS, AGAINST ADV.
                              NO. 04 KOLKATA CITY NUHM SOCIETY /2019-20
                            </a>
                          </td>
                          <td>22.08.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1656")}
                            >
                              ENGAGEMENT OF 28 MEDICAL OFFICER FULL
                              TIME(CONTRACTUAL) RESERVED CAREGORY AND 25 MEDICAL
                              OFFICER PART TIME (CONTRACTUAL) FOR ITS URBAN
                              PRIMARY HELATH CENTRES IN KOLKATA CITY NUHM
                              SOCIETY AREAS ON CONTRATUAL BASIS THROUGH WALK IN
                              INTERVIEW, AGAINST ADV. NO. 03 KOLKATA CITY NUHM
                              SOCIETY /2019-20 AND ADVERTISEMENT NO 03 KOLKATA
                              CITY NUHM SOCIETY /2019-20.
                            </a>
                          </td>
                          <td>01.08.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1657")}
                            >
                              Date and Venue of Personality Test for Recruitment
                              of Technical Personnel (AutoCAD) (On contractual
                              service purely on temporary basis)- Only for
                              candidates absent in Personality Test held on
                              {"02!07l19, 03107"} {"19 & 04/07/19"}.
                            </a>
                          </td>
                          <td>26.07.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1658")}
                            >
                              APPLICATION ARE INVITED FOR ENGAGEMENT OF 45
                              (FORTY FIVE) PHARMACISTS ON CONTRACTUAL BASIS
                              AGAINST ADVERTISEMENT NUMBER 03/KOLKATA CITY NUHM
                              SOCIETY / 2019 - 20.
                            </a>
                          </td>
                          <td>20.07.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1659")}
                            >
                              Applicalions are invited from the Officers (State
                              Govt./KMC) Superannuated /to be superannuated
                              on/before 31.08.2019, for selection and
                              appointment on re-appointment basis to the post of
                              Special Officer (Building) in KMC, to deal with
                              matters U/S 400, SUB-SEC.(I) AND 416 Of KMC, ACT
                              1980.
                            </a>
                          </td>
                          <td>12.07.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1660")}
                            >
                              ENGAGEMENT OF 30 MEDICAL OFFICER FULL
                              TIME(CONTRACTUAL) RESERVED CAREGORY AND 23 MEDICAL
                              OFFICER PART TIME (CONTRACTUAL) FOR ITS URBAN
                              PRIMARY HELATH CENTRES IN KOLKATA CITY NUHM
                              SOCIETY AREAS ON CONTRATUAL BASIS THROUGH WALK IN
                              INTERVIEW, AGAINST ADV. NO. 02 KOLKATA CITY NUHM
                              SOCIETY /2019-20 AND ADVERTISEMENT NO 02 KOLKATA
                              CITY NUHM SOCIETY /2019-20.
                            </a>
                          </td>
                          <td>12.07.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1661")}
                            >
                              Corrigendum to Memo No:227/NUHM/KOL/19-20. has
                              been extended up to 4th July 2019.
                            </a>
                          </td>
                          <td>27.06.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1662")}
                            >
                              THE COMPUTER TEST FOR THE POST OF LABORATORY
                              TECHNICIAN UNDER CITY NUHM SOCIETY AS PER
                              ADVERTISEMENT NO.11/KOLAKATA CITY NUHM
                              SOCIETY/2018-19 WILL BE HELD FROM 18.06.2019 TO
                              21.06.2019 AT INSTITUTE OF URBAN MANAGEMNENT
                              (I.U.M), 36C , BALLYGAUNG CIRCULAR ROAD, KOLKATA
                              -700019 AS PER ASTTACHED LISTED SCHEDULE.
                            </a>
                          </td>
                          <td>17.06.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1663")}
                            >
                              ADMISSION IN TWO YEARS 179 AUXILIARY NURSING &
                              MIDWIFERY (ANM) COURSE FOR URBAN PUBLIC HEALTH
                              CENTER (UPHC) UNDER KOLKATA CITY NUHM SOCIETY
                            </a>
                          </td>
                          <td>14.06.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1664")}
                            >
                              Notice of Personality Test for Recruitment of
                              Technical Personnel (AutoCAD)
                            </a>
                          </td>
                          <td>13.06.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1665")}
                            >
                              ENGAGEMENT OF SPECIALIST MEDICAL OFFICER OF
                              GYNAECOLOGIST, ANESTHETIST, CARDIOLOGIST,
                              SONOLOGIST, RADIOLOGIST FOR ITS GARDEN REACH
                              MATERNITY HOME AND OTHER MATERNITY HOMES IN
                              KOLKATA MUNICIPAL CORPORATION ON THE BASIS OF
                              CONTRACT OF PAYMENT CASE TO CASE BASIS THROUGH
                              WALK-IN-INTERVIEW, AGAINST ADV. H/01/KMC/2018-19 ,
                              DATED:08.05.2019.
                            </a>
                          </td>
                          <td>07.06.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1665")}
                            >
                              ENGAGEMENT OF 1 (ONE) LAB-TECH (CONTRACTUAL)
                              RESERVED CAREGORY (ST - 1) AND 2 (TWO) MEDICAL
                              OFFICER RNTCP (CONTRACTUAL) (UR - 2) FOR KOLKATA
                              RNTCP ON CONTRATUAL BASIS THROUGH WALK IN
                              INTERVIEW, AGAINST MEMO. NO. CTO/ KOL/Adm/094/2019
                              dated 22.05.2019 UNDER KOLKATA DISTRICT TB CONTROL
                              SOCIETY.
                            </a>
                          </td>
                          <td>03.06.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1666")}
                            >
                              ENGAGEMENT OF 32 MEDICAL OFFICER FULL
                              TIME(CONTRACTUAL) RESERVED CAREGORY AND 28 MEDICAL
                              OFFICER PART TIME (CONTRACTUAL) FOR ITS URBAN
                              PRIMARY HELATH CENTRES IN KOLKATA CITY NUHM
                              SOCIETY AREAS ON CONTRATUAL BASIS THROUGH WALK IN
                              INTERVIEW, AGAINST ADV. NO. 01 KOLKATA CITY NUHM
                              SOCIETY /2019-20 AND ADVERTISEMENT NO 01 KOLKATA
                              CITY NUHM SOCIETY /2019-20
                            </a>
                          </td>
                          <td>28.05.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1667")}
                            >
                              ENGAGEMENT OF STAFF NURSE (CONTRACTUAL) UNDER
                              KOLKATA CITY NUHM SOCIETY
                            </a>
                          </td>
                          <td>07.03.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass">
                              ADVERTISEMENT FOR RECRUITMENT IN KOLKATA RNTCP
                              AGAINST THE POST (I) LAB TECHNICIAN – TWO (ST-2);
                              (II) COUNSELLOR AT NODAL DTRB CENTER (MTM BORAL
                              HOSPITAL) - ONE (UR-1); (III) TBHV – ONE (UR-1)
                              VIDE WALK-IN-INTERVIEW
                            </a>
                          </td>
                          <td>28.02.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1668")}
                            >
                              ENGAGEMENT OF 38 (UR-06,UR(SPORTS PERSON) - 02,
                              UR(EX-SERVICEMAN)-04, UR (PWD) - 04, SC-13,
                              SC-EX-SERVICEMEN - 02, ST-07) LABORATORY
                              TECHNICIANS ON CONTRACTUAL BASIS FOR KOLKATA CITY
                              NUHM SOCIETY AGAINST ADVERTISEMENT NO. -
                              11/KOLKATA CITY NUHM SOCIETY/2018-2019 DATED
                              23.02.2019.
                            </a>
                          </td>
                          <td>23.02.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1669")}
                            >
                              NOTICE FOR INTERVIEW OF HEARING OFFICER.
                            </a>
                          </td>
                          <td>19.02.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1670")}
                            >
                              ENGAGEMENT OF MEDICAL OFFICERS FULL-TIME
                              (CONTRACTUAL) RESERVED CATEGORY & MEDICAL OFFICERS
                              PART-TIME UNDER KOLKATA CITY NUHM SOCIETY THROUGH
                              WALK-IN-INTERVIEW AT THE PREMISES 5, S.N. BANERJEE
                              ROAD, KOLKATA - 700013
                            </a>
                          </td>
                          <td>12.02.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1671")}
                            >
                              WALK IN INTERVIEW FOR (30) THIRTY NUMBERS OF
                              FULL-TIME MEDICAL OFFICER & (44) FORTY-FOUR
                              PART-TIME MEDICAL OFFICER PURELY ON CONTRACTUAL
                              BASIS FOR ITS URBAN PRIMARY HEATLH CENTERS UNDER
                              KOLKATA CITY NUHM SOCIETY, AGAINST ADV.
                              NO.15KOLKATA CITY NUHM SOCIETY2017-18 & AGAINST
                              ADV. NO.7KOLKATA CITY NUHM SOCIETY2017-18
                            </a>
                          </td>
                          <td>17.12.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1672")}
                            >
                              Recruitment of AUTOCAD Personal (On contractual
                              service purely on temporary basis)
                            </a>
                          </td>
                          <td>18.12.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1673")}
                            >
                              Walk -in- Interview for selection of contractual
                              engagement of 10(ten) Contractual Computer Knowing
                              Steno-Typist(English)
                            </a>
                          </td>
                          <td>12.11.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1674")}
                            >
                              ENGAGEMENT OF 37 (THIRTY-SEVEN) MEDICAL OFFICERs
                              (PART-TIME) FOR ITS URBAN PRIMARY HEALTH CENTRES
                              IN KOLKATA CITY NUHM SOCIETY AREAS ON CONTRACTUAL
                              BASIS THROUGH WALK-IN-INTERVIEW, AGAINST ADV. NO.
                              6/KOLKATA CITY NUHM SOCIETY/2018-19, DATED -
                              03.10.2018
                            </a>
                          </td>
                          <td>03.10.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1675")}
                            >
                              Applications are invited from the Superannuated/to
                              be Superannuated Govt.Officers for selection and
                              appointment on re-employment basis to the post of
                              Hearing Officer in KMC.
                            </a>
                          </td>
                          <td>18.09.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1676")}
                            >
                              Advertisement no KMC/AMRUT/NULM-01/2016-17 dt.
                              16.06.2016 , had applied for the contractual
                              engagement in the posts of Manager (MIS&ME)
                            </a>
                          </td>
                          <td>17.07.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1677")}
                            >
                              Advertisement no KMC/AMRUT/NULM-01/2016-17 dt.
                              16.06.2016 , had applied for the contractual
                              engagement in the posts of Multitasking Helper
                            </a>
                          </td>
                          <td>17.07.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1678")}
                            >
                              Advertisement no KMC/AMRUT/NULM-01/2016-17 dt.
                              16.06.2016 , had applied for the contractual
                              engagement in the posts of Accountant
                            </a>
                          </td>
                          <td>17.07.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1679")}
                            >
                              Advertisement no KMC/AMRUT/NULM-01/2016-17 dt.
                              16.06.2016 , had applied for the contractual
                              engagement in the posts of Dealing Assistant cum
                              DEO.
                            </a>
                          </td>
                          <td>17.07.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1680")}
                            >
                              ENGAGEMENT OF 191 (ONE-HUNDRED-NINETY-ONE) STAFF
                              NURSE FOR ITS URBAN PRIMARY HEALTH CENTERS IN
                              KOLKATA CITY AREA PURELY ON CONTRACTUAL BASIS
                              THROUGH WALK-IN-INTERVIEW, VIDE ADVERTISEMENT NO.
                              3/KOLKATA CITY NUHM SOCIETY/2018-2019, DATED -
                              02.06.2018.
                            </a>
                          </td>
                          <td>04.06.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1681")}
                            >
                              ENGAGEMENT OF 33 (THIRTY-THREE) MEDICAL OFFICERS
                              (PART-TIME) FOR ITS URBAN PRIMARY HEALTH CENTRES
                              IN KOLKATA CITY NUHM SOCIETY AREAS ON CONTRACTUAL
                              BASIS THROUGH WALK-IN-INTERVIEW, AGAINST ADV. NO.
                              2/KOLKATA CITY NUHM SOCIETY/2018-19, DATED -
                              01-06.2018
                            </a>
                          </td>
                          <td>01.06.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1682")}
                            >
                              Walk-in-interview for contractual engagement of
                              veterinary officer(V.O)
                            </a>
                          </td>
                          <td>12.05.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1683")}
                            >
                              WALK IN INTERVIEW FOR (30) THIRTY NUMBERS OF
                              PART-TIME MEDICAL OFFICERS PURELY ON CONTRACTUAL
                              BASIS FOR ITS URBAN PRIMARY HEATLH CENTERS UNDER
                              KOLKATA CITY NUHM SOCIETY, AGAINST ADV.
                              NO.1/KOLKATA CITY NUHM SOCIETY/2018-19, DATED -
                              04.04.2018.
                            </a>
                          </td>
                          <td>04.04.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1684")}
                            >
                              ENGAGEMENT OF 12 (TWELVE) M.O.(FULL-TIME) FOR ITS
                              URBAN PRIMARY HEALTH CENTRES IN KOLKATA CITY AREA
                              PURELY ON CONTRACTUAL BASIS THROUGH
                              WALK-IN-INTERVIEW, AGAINST ADV. NO. 15/KOLKATA
                              CITY NUHM SOCIETY/2017-18, DATED - 19-03.2018.
                            </a>
                          </td>
                          <td>20.03.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1685")}
                            >
                              ENGAGEMENT OF 42 (FORTY-TWO) LABORATORY
                              TECHNICIANS FOR ITS KOLKATA CITY NUHM SOCIETY
                              AREAS ON CONTRACTUAL BASIS THROUGH INTERVIEW,
                              AGAINST ADV. NO.14/KOLKATA CITY NUHM
                              SOCIETY/2017-18, DATED - 19-03.2018.
                            </a>
                          </td>
                          <td>20.03.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1686")}
                            >
                              FRESH ADVERTISEMENT FOR THE POST OF "SECRETARY,
                              MUHAMMADAN BURIAL BOARD".
                            </a>
                          </td>
                          <td>10.03.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1687")}
                            >
                              ENGAGEMENT OF 14 (FOURTEEN) PHARMACISTS (UR-PH-01;
                              UR-EX.SERVICE MAN-05;UR-MERITORIOUS SPORTS
                              PERSON-02; S.C.-EX.SERVICEMAN-02;S.T.-04) ON
                              CONTRACTUAL BASIS, AGAINST ADV. NO. 13 / KOLKATA
                              CITY NUHM SOCIETY / 2017-18, DATED - 09.02.2018
                            </a>
                          </td>
                          <td>09.02.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1688")}
                            >
                              TEMPORARY ENGAGEMENT OF LT (RESERVED ST-2), M.O.
                              RNTCP (MEDICAL COLLEGE-UR-1), TBHV (UR-1) IN
                              KOLKATA RNTCP, AGAINST ADV. NO.
                              CTO/KOL/ADM/005/2018, DATED - 15.01.2018.
                            </a>
                          </td>
                          <td>29.01.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1689")}
                            >
                              ENGAGEMENT OF M.O.(PART-TIME) FOR ITS URBAN
                              PRIMARY HEALTH CENTRES IN KOLKATA CITY AREA PURELY
                              ON CONTRACTUAL BASIS THROUGH WALK-IN-INTERVIEW,
                              AGAINST ADV. NO. 12/KOLKATA CITY NUHM
                              SOCIETY/2017-18, DATED - 29.01.2018.
                            </a>
                          </td>
                          <td>29.01.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1670")}
                            >
                              ENGAGEMENT OF 12 (TWELVE) NOS. OF MEDICAL OFFICERS
                              (FULL-TIME) (SC-4,ST-2,OBC--A-2,OBC-B-4) FOR ITS
                              URBAN PRIMARY HEALTH CENTERS IN KOLKATA CITY AREA
                              PURELY ON CONTRACTUAL BASIS THROUGH
                              WALK-IN-INTERVIEW ON 27.12.2017 / 11:30 AM,
                              AGAINST ADV.NO. 11/KOLKATA CITY NUHM
                              SOCIETY/2017-18, DATED - 18.12.2017.
                            </a>
                          </td>
                          <td>18.12.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              href="http://kmc.applythrunet.co.in/"
                              target="_blank"
                            >
                              Download Admit Card for Contractual Teachers
                              Recruitment.
                            </a>
                          </td>
                          <td>17.12.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1691")}
                            >
                              WALK-IN-INTERVIEW FOR ENGAGEMENT OF GYNECOLOGIST,
                              ANESTHETIST, CARDIOLOGIST & SONOLOGIST /
                              RADIOLOGIST FOR ITS MATERNITY HOMES PURELY ON CASE
                              TO CASE BASIS, VIDE ADV. NO. H/00/243/2017-18,
                              DATED - 18.12.2017.
                            </a>
                          </td>
                          <td>18.12.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1692")}
                            >
                              ENGAGEMENT OF 47 (FORTY-SEVEN) NOS. OF MEDICAL
                              OFFICER (PART-TIME) FOR ITS URBAN PRIMARY HEALTH
                              CENTERS IN KOLKATA CITY AREA PURELY ON CONTRACTUAL
                              BASIS THROUGH WALK-IN-INTERVIEW ON 14.12.2017 /
                              11:30 AM AGAINST ADV.NO. 10/KOLKATA CITY NUHM
                              SOCIETY/2017-18, DATED - 04.12.2017.
                            </a>
                          </td>
                          <td>05.12.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1693")}
                            >
                              Corrigendum to Advertisement No.09/Kolkata City
                              NHUM Society/2017-18.
                            </a>
                          </td>
                          <td>27.11.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1694")}
                            >
                              WALK-IN-INTERVIEW FOR ENGAGEMENT OF THREE (3)
                              LAB.TECH (LT) (UR-1,ST-2), M.O. RNTCP FOR CNMCH-
                              UR-1, SENIOR MO (DRTB CENTRE MTM BORAL HOSP -
                              UR-1), AGAINST ADVT. MEMO NO.
                              CTO/KOL/ADM/121/2017, DATED -20.11.2017, FOR
                              KOLKATA DISTRICT TB CONTROL SOCIETY.
                            </a>
                          </td>
                          <td>25.11.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1695")}
                            >
                              Corrigendum of Engagement of Auto-CAD
                              Professionals.
                            </a>
                          </td>
                          <td>24.11.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1696")}
                            >
                              WALK-IN-INTERVIEW FOR ENGAGEMENT OF ONE HUNDRED
                              THIRTY NINE (139) 'STAFF NURSES' FOR ITS URBAN
                              PRIMARY HEALTH CENTERS UNDER KOLKATA CITY NUHM
                              SOCIETY AGAINST ADVERTISEMENT NO.09/KOLKATA CITY
                              NUHM SOCIETY/2017-18, DATED - 17.11.2017.
                            </a>
                          </td>
                          <td>17.11.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1697")}
                            >
                              Engagement of Auto-CAD Professionals.
                            </a>
                          </td>
                          <td>07.11.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1698")}
                            >
                              WALK-IN-INTERVIEW FOR ENGAGEMENT OF ELEVEN (11)
                              MEDICAL OFFICERS (FULL-TIME) FOR ITS URBAN PRIMARY
                              HEALTH CENTERS UNDER KOLKATA CITY NUHM SOCIETY
                              AGAINST ADVERTISEMENT NO.07/KOLKATA CITY NUHM
                              SOCIETY/2017-18, DATED - 01.11.2017.
                            </a>
                          </td>
                          <td>01.11.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1699")}
                            >
                              ENGAGEMENT OF MEDICAL OFFICER (PART-TIME) FOR
                              URBAN PRIMARY HEALTH CENTERS IN KOLKATA CITY AREA
                              UNDER KOLKATA CITY NUHM SOCIETY AGAINST
                              ADVERTISEMENT NO.07/KOLKATA CITY NUHM
                              SOCIETY/2017-18, DATED - 23.09.2017.
                            </a>
                          </td>
                          <td>23.09.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1700")}
                            >
                              Engagement of 2(two) Inquiry Officers on
                              contractual basis
                            </a>
                          </td>
                          <td>21.09.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1700")}
                            >
                              RECRUITMENT OF MEDICAL OFFICER (PART-TIME)OF
                              KOLKATA CITY NUHM SOCIETY
                            </a>
                          </td>
                          <td>24.08.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1701")}
                            >
                              Recruitment of Lab. Tech (ST-2) R.N.T.C.P.,
                              Kolkata, Special Drive for Reserved Catagory (ST)
                              on Contractual Basis
                            </a>
                          </td>
                          <td>16.08.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1702")}
                            >
                              Recruitment of Assistant Engineer & Executive
                              Engineer for KEIIP on Contract Basis.
                            </a>
                          </td>
                          <td>08.08.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1703")}
                            >
                              WALK-IN-INTERVIEW FOR 06 (SIX) NUMBER OF X-RAY
                              TECHNICIANS PURELY ON CONTRACTUAL BASIS FOR HEALTH
                              DEPARTMENT, KOLKATA MUNICIPAL CORPORATION.
                            </a>
                          </td>
                          <td>28.07.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1704")}
                            >
                              Recruitment Notice for I.T. Hardware Professional
                              (Contractual) at KEIIP.
                            </a>
                          </td>
                          <td>20.07.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1705")}
                            >
                              ENGAGEMENT OF 08 (EIGHT)
                              {
                                "CATEGORISED AS SC-2, ST-02, OBC-A - 02, OBC-B - 02"
                              }
                              MEDICAL OFFICERS (FULLTIME) PURELY ON CONTRACTUAL
                              BASIS UNDER KOLKATA CITY NUHM SOCIETY FOR ITS
                              URBAN PRIMARY HEALTH CENTRES IN KOLKATA CITY AREA
                              AGAINST ADVERTISEMENT NO. 05 / KOLKATA CITY NUHM
                              SOCIETY / 2017-18, DTD. 17.07.2017
                            </a>
                          </td>
                          <td>18.07.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1706")}
                            >
                              ENGAGEMENT OF 60 (SIXTY) MEDICAL OFFICERS PURELY
                              ON CONTRACTUAL BASIS UNDER KOLKATA CITY NUHM
                              SOCIETY FOR ITS URBAN PRIMARY HEALTH CENTRES IN
                              KOLKATA CITY AREA AGAINST ADVERTISEMENT NO.
                              04/KOLKATA CITY NUHM SOCIETY / 2017-18, DTD.
                              05.07.2017
                            </a>
                          </td>
                          <td>08.07.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1707")}
                            >
                              RECRUITMENT OF ONE DATA ENTRY OPERATOR (ST), NINE
                              LAB. TECHNICIANS (SC-4, ST-2, OBC-A-2, OBC-B-1) &
                              ONE TBHV (SC-1) UNDER RNTCP, KOLKATA.
                            </a>
                          </td>
                          <td>21.06.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1708")}
                            >
                              ENGAGEMENT OF 87 (EIGHTY-SEVEN) NOS. OF M.O.
                              (PART-TIME) PURELY ON CONTRACTUAL BASIS FOR URBAN
                              PRIMARY HEALTH CENTRES IN KOLKATA CITY AREA.
                            </a>
                          </td>
                          <td>09.06.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1709")}
                            >
                              FORMAT FOR MEDICAL CERTIFICATE FOR ANM TRAINEE.
                            </a>
                          </td>
                          <td>25.05.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1710")}
                            >
                              RECRUITMENT OF ONE (1) (UNRESERVED) MEDICAL
                              OFFICER AND ONE (1) (UNRESERVED) ACCOUNTANT, RNTCP
                              AGAINST VACANCIES PURELY ON CONTRACTUAL BASIS
                              INITIALLY FOR A PERIOD OF ONE YEAR (RENEWABLE ON
                              PERFORMANCE).
                            </a>
                          </td>
                          <td>22.04.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1711")}
                            >
                              COMPUTER TEST OF LABORATORY TECHNICIAN UNDER
                              KOLKATA CITY NUHM SOCIETY ON CONTRACTUAL BASIS ON
                              26.04.2017, 27.04.2017 & 28.04.2017.
                            </a>
                          </td>
                          <td>21.04.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1712")}
                            >
                              ENGAGEMENT OF MEDICAL OFFICER (FULL-TIME) RESERVE
                              CATEGORY ON CONTRACTUAL BASIS AGAINST
                              ADVERTISEMENT NO. 1/ KOLKATA CITY NUHM SOCIETY /
                              2017-2018.
                            </a>
                          </td>
                          <td>08.04.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1713")}
                            >
                              Engagement of Contractual Teachers for running
                              different KMCP Schools purely on temporary basis
                              in Education Department,KMC.
                            </a>
                          </td>
                          <td>01.04.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1714")}
                            >
                              RECRUITMENT OF ONE DATA ENTRY OPERATOR & FIVE LAB.
                              TECHNICIANS UNDER RNTCP,KOLKATA.
                            </a>
                          </td>
                          <td>23.03.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1715")}
                            >
                              Walk in interview for the post of
                              Consultant,Electrical engineer and Mechanical
                              engineer on 05/04/2017.
                            </a>
                          </td>
                          <td>22.03.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1716")}
                            >
                              ENGAGEMENT OF 100 NOS. OF LABORATORY TECHNICIANS
                              ON CONTRACTUAL BASIS AGAINST ADVERTISEMENT NO. 10
                              / KOLKATA CITY NUHM SOCIETY / 2016-2017.
                            </a>
                          </td>
                          <td>10.03.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1717")}
                            >
                              Recruitment of MEDICAL OFFICER (PART-TIME).
                            </a>
                          </td>
                          <td>03.03.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1718")}
                            >
                              Recruitment of 20 Staff Nurse(Contractual) needed
                              for MTM Boral TB Hospital for 6 Months (Renewable
                              if necessary).
                            </a>
                          </td>
                          <td>16.02.2017</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1719")}
                            >
                              ENGAGEMENT OF 80 (EIGHTY) PHARMACISTS ON
                              CONTRACTUAL BASIS UNDER KOLKATA CITY NUHM SOCIETY.
                            </a>
                          </td>
                          <td>05.12.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1720")}
                            >
                              ENGAGEMENT OF 65 (SIXTY-FIVE) FULL-TIME MEDICAL
                              OFFICER ON CONTRACTUAL BASIS UNDER KOLKATA CITY
                              NUHM SOCIETY.
                            </a>
                          </td>
                          <td>03.12.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1721")}
                            >
                              Walk-in-interview for the post of Medical officer
                              (Part-time) purely on contractual basis for
                              Kolkata City NUHM Society.
                            </a>
                          </td>
                          <td>09.11.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1722")}
                            >
                              Walk-in-interview for the post of Medical Officer
                              (Full Time) purely on contractual basis for
                              Kolkata City NUHM Society.
                            </a>
                          </td>
                          <td>28.09.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1723")}
                            >
                              Applications are invited from the Civil Engineers
                              of Empanelment of Geo-Technical Engineers.
                            </a>
                          </td>
                          <td>05.09.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1724")}
                            >
                              Notice for Walk-in Interview regarding Appointment
                              in Various Posts (On Contract Basis) at Kolkata
                              Environmental Improvement Investment Program
                              (KEIIP).
                            </a>
                          </td>
                          <td>26.08.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1725")}
                            >
                              Kolkata City NUHM Society will engage 79 Medical
                              Officer (Full-Time)
                            </a>
                          </td>
                          <td>11.08.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1726")}
                            >
                              Applications are invited for engagement of 228
                              (two hundred twenty eight) Staff Nurse on
                              contractual basis
                            </a>
                          </td>
                          <td>11.08.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1727")}
                            >
                              CORRIGENDUM FOR THE EMPLOYMENT NOTICE NO
                              KMC/AMRUT/NULM-01/2016-17 DTD 16/6/2016 FOR THE
                              POST OF URBAN PLANNER UNDER AMRUT has been
                              extended upto 05-August-2016,upto 5 p.m
                            </a>
                          </td>
                          <td>29.07.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1728")}
                            >
                              CORRIGENDUM FOR THE EMPLOYMENT NOTICE NO
                              KMC/AMRUT/NULM-01/2016-17 DTD 16/6/2016 FOR THE
                              POST OF URBAN PLANNER UNDER AMRUT has been
                              extended upto 05-August-2016,upto 5 p.m
                            </a>
                          </td>
                          <td>29.07.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1729")}
                            >
                              CORRIGENDUM FOR THE EMPLOYMENT NOTICE NO
                              KMC/AMRUT/NULM-01/2016-17 DTD 16/6/2016 FOR THE
                              POST OF URBAN PLANNER UNDER AMRUT has been
                              extended upto 05-August-2016,upto 5 p.m
                            </a>
                          </td>
                          <td>29.07.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1730")}
                            >
                              Engage of 115 (One Hundred Fifteen) Medical
                              Officers (Part-Time) on Contractual Basis for
                              Urban Primary Health Centers in Kolkata City area
                              under Kolkata City NUHM Society.
                            </a>
                          </td>
                          <td>12.07.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp"
                              target="_blank"
                            >
                              Empl Notice No. - KMC/AMRUT/NULM-01/2016-17 Dated
                              16/6/2016(Apply Through External User Login).
                            </a>
                          </td>
                          <td>16.06.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1730")}
                            >
                              Empl Notice No. - KMC/AMRUT/NULM-01/2016-17 Dated
                              16/6/2016(.pdf notice) .
                            </a>
                          </td>
                          <td>16.06.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1731")}
                            >
                              Engagement of 13 nos Medical Officers (Full Time)
                              on contractual basis for Urban Primary Health
                              Centres under Kolkata City NUHM Society, KMC.
                            </a>
                          </td>
                          <td>01.06.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1732")}
                            >
                              Recruitment of Computer Assistant and Data Entry
                              Operator under Kolkata City NUHM Society.
                            </a>
                          </td>
                          <td>01.03.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1733")}
                            >
                              Recruitment of four (4) Laboratory Technician
                              (reserved category post only) under Kolkata City
                              NUHM Society.
                            </a>
                          </td>
                          <td>30.01.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1734")}
                            >
                              Notice For Interview of Hearing Officer.
                            </a>
                          </td>
                          <td>21.01.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1735")}
                            >
                              Corrigendum to Recruitment Advt. No 6/Kolkata City
                              NUHM Society/2015-16 for Recruitment of 24(Twenty
                              Four) Part time Medical Officer under Kolkata City
                              NUHM Society, KMC.
                            </a>
                          </td>
                          <td>08.01.2016</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1736")}
                            >
                              Recruitment of 24(Twenty Four) Part time Medical
                              Officer under Kolkata City NUHM Society, KMC.
                            </a>
                          </td>
                          <td>31.12.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1737")}
                            >
                              Recruitment of One Data Manager under Kolkata City
                              NUHM Society, KMC.
                            </a>
                          </td>
                          <td>28.12.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1738")}
                            >
                              SPECIAL OFFICER(BUILDING) will be held on
                              14.12.2015 (MONDAY) at 11 a.m. at the Conference
                              Room-II of the CMO building of the Kolkata
                              Municipal Corporation at 5, S.N. Banerjee Road,
                              Kolkata-700 013.
                            </a>
                          </td>
                          <td>10.12.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1739")}
                            >
                              Walk-in-Interview Recruitment of select Apprentice
                              Trainee (Degree/Diploma) for the period of 1(one)
                              Calendar Year of commencing from 2016.
                            </a>
                          </td>
                          <td>07.12.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1740")}
                            >
                              Recruitment of One Computer Assistant and One Data
                              Entry Operator under Kolkata City NUHM Society,
                              KMC.
                            </a>
                          </td>
                          <td>26.11.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1741")}
                            >
                              Recruitment of Accounts Manager under Kolkata City
                              NUHM Society, KMC.
                            </a>
                          </td>
                          <td>18.11.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1742")}
                            >
                              Walk-in-Interview Recruitment of Technical
                              Personnel (On contract service purely on temporary
                              basis)
                            </a>
                          </td>
                          <td>16.11.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1743")}
                            >
                              ADVERTISEMENT FOR Recruitment for 15 Staff Nurses
                              for Boral TB Hospital, KMC in Walk in Interview on
                              26/11/2015.
                            </a>
                          </td>
                          <td>06.11.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1744")}
                            >
                              Corrigendum of Re-Appointment Special Officer
                              Building.
                            </a>
                          </td>
                          <td>31.10.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1745")}
                            >
                              Re-Appointment Special Officer Building.
                            </a>
                          </td>
                          <td>28.10.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1746")}
                            >
                              Addendum - The last date of submission of
                              application for selection and appointment on
                              re-employment basis to the post of hearing officer
                              in the Kolkata Municipal Corporation uls 187 of
                              the KMC act, 1980, is hereby extended up to
                              15.10.2015.
                            </a>
                          </td>
                          <td>05.10.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1747")}
                            >
                              Applications are invited from the Superannuated/to
                              be superannuated Govt. Officers for selection and
                              appointment on re-employment basis to the post of
                              Hearing Officer in KMC.
                            </a>
                          </td>
                          <td>29.09.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1748")}
                            >
                              Recruitment of Thirty part time and twelve full
                              time Medical Officers under Kolkata City NUHM
                              Society
                            </a>
                          </td>
                          <td>04.09.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1749")}
                            >
                              Walk -in -Interview for contractual engagement of
                              02(two) Nos. of Computer knowing Steno-typist
                              (ENGLISH)purely on temporary basisfor 6 (Six)
                              months at The Kolkata Municipal Corporation.
                            </a>
                          </td>
                          <td>06.08.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1750")}
                            >
                              Walk-in Interview for contractual engagement of
                              Medical Officer (Specialist (G&O), Medical Officer
                              (Specialist Radiologist cum Sonologist), Medical
                              Officer Specialist (Anaesthetist), Medical Officer
                              Specialist (Pediatrician), O.T. Nurse.
                            </a>
                          </td>
                          <td>17.07.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1752")}
                            >
                              Recruitment of 1 (one) TB / HIV Co-ordinator.
                            </a>
                          </td>
                          <td>02.07.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1753")}
                            >
                              Recruitment in KEIIP/KMC.
                            </a>
                          </td>
                          <td>09.06.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1754")}
                            >
                              Advertisement No. –1/Kolkata City NUHM Society /
                              2015-16.
                            </a>
                          </td>
                          <td>02.06.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1755")}
                            >
                              Recruitment of Environment Specialist for KEIIP.
                            </a>
                          </td>
                          <td>16.03.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1756")}
                            >
                              Walk-in-interview for contractual engagement of
                              various categories of Medical Officers &
                              Para-medical staff in KMC Health Dept.
                            </a>
                          </td>
                          <td>12.03.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1757")}
                            >
                              Recruitment of Environmental,Social and GIS
                              Personnel for KEIIP, KMC
                            </a>
                          </td>
                          <td>21.02.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1757")}
                            >
                              CORRIGENDUM, Appointment notice published on
                              16.01.2015 – for Utility Finance Improvement Unit
                              (UFIU),KEIIP
                            </a>
                          </td>
                          <td>22.01.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1758")}
                            >
                              Engagement of 30(Thirty) Medical Officers for 30
                              Urban Primary Health Centres Kolkata City area
                              under Kolkata City NUHM Society
                            </a>
                          </td>
                          <td>17.01.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1759")}
                            >
                              Appointments For KEIIP
                            </a>
                          </td>
                          <td>15.01.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1760")}
                            >
                              Recruitment of Senior Medical Officer DRTB Centre
                              1 post(Contractual)
                            </a>
                          </td>
                          <td>14.01.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1761")}
                            >
                              Recruitment of DEO - 1 post(Contractual)
                            </a>
                          </td>
                          <td>13.12.2014</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default KMC_Recruitments