import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Schedule_Of_Fees  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/Banner/portal-banner6.jpg" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> </h4>
        </div>
      </div>
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  ">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Solid_wastes_home'> {t("Solid Wastes Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Schedule_Of_Fees'> {t("Schedule of Fees")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Solid_waste_management_faqs'> {t("FAQs")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Solid_Waste_Dept'> {t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('842')}>{t("Status Report SWM Feb 2019")}</a>
              </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('843')}>{t("Gazette notification for KMC SWM Bye Laws 2020")}</a>
              </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass"> {t("Schedule of Fees and Charges for the year 2022- 2023")} </h3>
            </div>
            <div className="col-lg-12 about-head1">
            <div id="accordion" className="Schedule_Of_Fees">
            <div class="card">
                    <div class="card-header" id="heading15">
                    <h5 class="mb-0">
                        <button class="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse15" aria-expanded="false" aria-controls="collapseThree">
                        {t("I. Encroachment (temporary) on Public Roadways")}
                    </button>
                    </h5>
                    </div>
                    <div id="collapse15" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                    <div class="card-body">
                    <p className="fontSizeClass">{t("Scaffolding, Platform, Stand-Place, Stall, Blind Shade or other Projection of any kind, Building Materials, Earth or any other goods whatsoever referred under Section 371 of KMC Act.'80, to which a scale of charges according to area occupied is applicable")} </p>
            <div class="mayor-table2 ">
            <table class="table table-bordered">  
            <tbody>    
            <tr>
            <td scope="row"> 1 </td>
            <td> {t("Not exceeding 3 days per sq. ft.")} </td>
            <td> {t("Rs. 4.00 per sq. ft.")} </td>
            </tr>
            <tr>
            <td scope="row"> 2 </td>
            <td> {t("Exceeding 3 days but not exceeding 10 days")} </td>
            <td> {t("Rs. 6.00 per sq. ft.")} </td>
            </tr>
            <tr>
            <td scope="row"> 3 </td>
            <td> {t("Exceeding 10 days upto 1 month or part thereof")} </td>
            <td>  {t("Rs. 10.00 per sq. ft.")} </td>
            </tr>
            </tbody>
            </table>
            </div>
            <p className="fontSizeClass">{t("Note : Prior permission is to be obtained failing which the chargeable amount will be double the rate.")}</p>
                    </div>
                    </div>
                </div>

                
                <div class="card">
                    <div class="card-header" id="heading1">
                    <h5 class="mb-0">
                        <button class="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse1" aria-expanded="false" aria-controls="collapseThree">
                        {t("II. Temporary occupation")}
                    </button>
                    </h5>
                    </div>
                    <div id="collapse1" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                    <div class="card-body">
                    <div className="mayor-table2 ">
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <td scope="row"> 1 </td>
                              <td> &nbsp; </td>
                              <td> {t("For Marriage, Shraddha etc.")} </td>
                              <td> &nbsp; </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> {t("a)")} </td>
                              <td>
                                
                                {t("Exceeding 3 days but not exceeding 10 days")}
                              </td>
                              <td> {t("Rs. 3.00 per sq. ft.")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> b) </td>
                              <td>
                                
                                {t("For occupation exceeding 400 sq. ft. for 3 days")}
                              </td>
                              <td> {t("Rs. 4.00 per sq. ft.")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> c) </td>
                              <td>
                                
                                {t("For occupation exceeding 3 days upto 7 days")}
                              </td>
                              <td> {t("Rs. 6.50 per sq. ft.")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 2 </td>
                              <td> {t("Temporary Occupation (for Festivals)")} </td>
                              <td> &nbsp; </td>
                              <td> &nbsp; </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> a) </td>
                              <td> {t("Upto 7 days")} </td>
                              <td> {t("Rs. 6.00 per sq. ft")}. </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> c) </td>
                              <td> {t("Upto 15 days")} </td>
                              <td> {t("8Rs. 10.00 per sq. ft.")} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading2">
                    <h5 class="mb-0">
                        <button class="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapseThree">
                        {t("III. Archway, Gate, Nahabatkhana or similar contrivances on or across the road, footpath or boulevard")}
                    </button>
                    </h5>
                    </div>
                    <div id="collapse2" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                    <div class="card-body">
                    <div className="mayor-table2 ">
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <td scope="row"> 1 </td>
                              <td> a) </td>
                              <td>
                                
                                {t("Archway across roadway or footpath or boulevard")}
                              </td>
                              <td> {t("600.00 per day")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> b) </td>
                              <td> {t("-Do- with advertisement")} </td>
                              <td>{t("800.00 per day")}</td>
                            </tr>
                            <tr>
                              <td scope="row"> 2 </td>
                              <td> &nbsp; </td>
                              <td>
                                
                                {t("Gate along roadway or footpath adjoining the gate of the building")}
                              </td>
                              <td> {t("150.00")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 3 </td>
                              <td> &nbsp; </td>
                              <td>
                                
                                {t("Nahabatkhana or similar contrivances (for max..3 days or part thereof.)")}
                              </td>
                              <td> {t("10.00 per day per sq. ft.")} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p className="fontSizeClass">
                        {t("N.B. :Prior permission is to be obtained failing which the chargeable amount will be four times the rate, in case of II and III above.")}
                      </p>
                      <p className="fontSizeClass">
                        {t("Note :")} <br />
                        {t("1) Charges once deposited is not refundable or adjustable.")} <br />
                        {t("2) Occupation licence shall not be granted beyond the frontage of premises of the applicant ordinarily. Consents of the owners of the adjoining houses will be required for exceptional cases occupying beyond the frontage of own premises.")} <br />
                        {t("3) Permission is terminable on serving notice u/S 377.")}
                        {t("4) Precaution for public safety is to be taken by proper fence and guard as noted u/S 378.")}
                      </p>
                    </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading3">
                    <h5 class="mb-0">
                        <button class="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse3" aria-expanded="false" aria-controls="collapseThree">
                        {t("IV. Trade Refuse")}                    </button>
                    </h5>
                    </div>
                    <div id="collapse3" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                    <div class="card-body">
                    <div className="mayor-table2 ">
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <td scope="row"> 1 </td>
                              <td> &nbsp; </td>
                              <td>
                                
                                {t("Markets and Others (other fees collected by the Licence Dept u/s 333)")}
                              </td>
                              <td> &nbsp; </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> a) </td>
                              <td>
                                
                                {t("Where daily refuse generation is upto 3 c.ft.")}
                              </td>
                              <td> {t("350.00 p.a.")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> b) </td>
                              <td>
                                
                                {t("Where daily refuse generation varies between 3")}
                                {t("c.ft. and 6 c.ft.")}
                              </td>
                              <td> {t("500.00 p.a.")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> c) </td>
                              <td>
                                
                                {t("Where daily refuse generation varies between 6")}
                                {t("c.ft. and 9 c.ft.")}
                              </td>
                              <td> {t("650.00 p.a.")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp;</td>
                              <td> d) </td>
                              <td>
                                
                                {t("For every additional 2 c.ft. or part thereof")}
                              </td>
                              <td> {t("200.00 p.a.")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> e) </td>
                              <td>
                                
                                {t("For removal of rubbish, building materials, earth or similar materials of private parties by lorry")}
                              </td>
                              <td> {t("1600.00 p.trip")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> f) </td>
                              <td>
                                
                                {t("For removal of garden and any other refuse of private parties by lorry from party's premises or deposited by parties on footpath (including circus")})
                              </td>
                              <td> {t("650.00 p.trip")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> g) </td>
                              <td> {t("-Do- by Handcart")} </td>
                              <td> {t("60.00 p.trip")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> h) </td>
                              <td>
                                
                                {t("For removal of refuse from establishments (As per demand) on the basis of number of each vehicle trip")}
                              </td>
                              <td>
                                {t("1,100.00 p.trip/p. vehicle+ Disposal charges as at VII(4)")}
                              </td>
                            </tr>
                            <tr>
                              <td scope="row"> 2 </td>
                              <td> &nbsp;</td>
                              <td>
                                
                                {t("Supply of silt and refuse for filling up tanks etc. of private parties(excluding cost of disinfection and dressing)")}
                              </td>
                              <td> &nbsp; </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> a) </td>
                              <td>
                                
                                {t("Supply of decomposed/garbage sludge (N.S.)for filling up per 100 c. ft. by parties lorry")}
                              </td>
                              <td> 60.00 </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> b) </td>
                              <td>
                                
                                {t("Supply of silt and spoils for filling up by lorry per 100c. ft. by parties lorry For (a) and (b) above - within the Corporation area")}
                              </td>
                              <td> 80.00 </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading4">
                    <h5 class="mb-0">
                        <button class="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapseThree">
                        V. {t("Sullage")}                    </button>
                    </h5>
                    </div>
                    <div id="collapse4" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                    <div class="card-body">
                            <div className="mayor-table2 ">
                                <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                    <td scope="row"> 1 </td>
                                    <td> a) </td>
                                    <td>
                                        
                                        {t("Removal of Nightsoil by lorry per 1000 ltrs. septic tank or part thereof other than bustee")}
                                    </td>
                                    <td> 300.00 </td>
                                    </tr>
                                    <tr>
                                    <td scope="row"> &nbsp; </td>
                                    <td> b) </td>
                                    <td> {t("-Do- in case of bustees")} </td>
                                    <td> 100.00 </td>
                                    </tr>
                                    <tr>
                                    <td scope="row"> 2 </td>
                                    <td> &nbsp; </td>
                                    <td>
                                        
                                        {t("For conservancy services rendered to institution per head per day")}
                                    </td>
                                    <td> 75.00 </td>
                                    </tr>
                                    <tr>
                                    <td scope="row"> 3 </td>
                                    <td> a) </td>
                                    <td>
                                        
                                        {t("Removal of Nightsoil with cesspool emptier having volumetric capacity less than or equal to 1000 Ltrs. capacity")}
                                    </td>
                                    <td> {t("500.00 per trip")} </td>
                                    </tr>
                                    <tr>
                                    <td scope="row"> &nbsp; </td>
                                    <td> b) </td>
                                    <td>
                                        
                                        {t("--do-- more than 1000 Ltrs. and upto 4000 Ltrs. capacity")}
                                    </td>
                                    <td> {t("1,350.00 per trip")} </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                    </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading5">
                    <h5 class="mb-0">
                        <button class="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
                                {t("VI. Removal of Carcass by Lorry")}
                        </button>
                    </h5>
                    </div>
                    <div id="collapse5" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                    <div class="card-body">
                    <div className="mayor-table2 ">
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <td scope="row">a) </td>
                              <td>
                                
                                {t("For every horse,bull, bullock,cow,pony or for any other animal weighing more than 70 Kgs.")}
                              </td>
                              <td> 200.00 </td>
                            </tr>
                            <tr>
                              <td scope="row">b) </td>
                              <td>
                                
                                {t("For every horse,bull, bullock,cow,pony or for any other animal weighing less than 70 Kgs.")}
                              </td>
                              <td> 100.00 </td>
                            </tr>
                            <tr>
                              <td scope="row">c) </td>
                              <td>
                                
                                {t("For every animal weighing less than 35 Kgs.")}
                              </td>
                              <td> 30.00 </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p className="fontSizeClass">
                        {t("Note: In case of unclaimed carcasses no fee shall be claimed on the specification list on the recommendation by Ward Councillor.")}
                      </p>
                    </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading13">
                    <h5 class="mb-0">
                        <button class="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapseThree">
                        {t("VII. Rate for the Cattle Impounding")}
                        </button>
                    </h5>
                    </div>
                    <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                    <div class="card-body">
                    <div className="mayor-table2 ">
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <td scope="row"> 1. </td>
                              <td> &nbsp; </td>
                              <td> {t("Seizure charge per animal")} </td>
                              <td> 100.00 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 2. </td>
                              <td> &nbsp; </td>
                              <td> {t("Pounding charge per animal (per day)")} </td>
                              <td> 15.00 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 3. </td>
                              <td> &nbsp; </td>
                              <td> {t("Feeding charge per animal per day")} :- </td>
                              <td> &nbsp; </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> a) </td>
                              <td> {t("Buffalo")} </td>
                              <td> 50.00 </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> b) </td>
                              <td> {t("Cow")} </td>
                              <td> 40.00 </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> c) </td>
                              <td> {t("Calf(Buffalo or Cow)")} </td>
                              <td> 30.00 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 4. </td>
                              <td> &nbsp;</td>
                              <td>
                                
                               {t(" Charges/fees for disposal of refuse transported by private parties at the Corporation Dumping Grounds.")}
                              </td>
                              <td> {t("550.00 per trip")} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading7">
                    <h5 class="mb-0">
                        <button class="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapseThree">
                        {t("VIII. Fees For Special Conservancy")}
                        </button>
                    </h5>
                    </div>
                    <div id="collapse7" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                    <div class="card-body">
                    <div className="mayor-table2 ">
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <td scope="row"> 1. </td>
                              <td> a)</td>
                              <td>
                                
                                {t("For ceremonial functions held in pandal erected on Corporation Roads/Footpaths per function")}
                              </td>
                              <td> 300.00 </td>
                            </tr>
                            <tr>
                              <td scope="row"> &nbsp; </td>
                              <td> b) </td>
                              <td>
                                
                                {t("For ceremonial functions held in pandal erected on Corporation parks or any other such places per function")}
                              </td>
                              <td> 550.00 </td>
                            </tr>
                            <tr>
                              <td scope="row"> 2. </td>
                              <td> &nbsp; </td>
                              <td>
                                
                                {t("For ceremonial functions held in Bustee Premises")}
                              </td>
                              <td> {t("Free")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 3. </td>
                              <td> &nbsp; </td>
                              <td>
                                
                                {t("Buffalo For ceremonial functions held in domestic houses per function")}
                              </td>
                              <td> 100.00 </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading8">
                    <h5 class="mb-0">
                        <button class="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapseThree">
                        {t("IX. Other Hiring Charges")}
                        </button>
                    </h5>
                    </div>
                    <div id="collapse8" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                    <div class="card-body">
                    <div className="mayor-table2 ">
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td scope="row"> 1. </td>
                            <td>
                              
                              {t("Hiring of Bulldozer per hour including P.O.L minimum booking for 5 hours i.e. minimum charge")}
                            </td>
                            <td> 13000.00 </td>
                          </tr>
                          <tr>
                            <td scope="row"> 2. </td>
                            <td>
                              
                              {t("For each additional hour or part thereof for Bulldozer")}
                            </td>
                            <td> 3000.00 </td>
                          </tr>
                          <tr>
                            <td scope="row"> 3. </td>
                            <td>
                              
                              {t("Hiring of Pay Loader per hour including P.O.L minimum booking for 5 hours i.e. minimum charge")}
                            </td>
                            <td> 10000.00 </td>
                          </tr>
                          <tr>
                            <td scope="row"> 4. </td>
                            <td>
                              
                              {t("For additional hour or part thereof for Payloader")}
                            </td>
                            <td> 2500.00 </td>
                          </tr>
                          <tr>
                            <td scope="row"> 5. </td>
                            <td>
                              
                             {t(" Hiring Mechanical Sweeper including P.O.L. minimum booking for five hours i.e. minimum charge")}
                            </td>
                            <td> 16000.00 </td>
                          </tr>
                          <tr>
                            <td scope="row"> 6. </td>
                            <td>
                              
                              {t("For each additional hour or part thereof for Mechanical Sweeper")}
                            </td>
                            <td> 3500.00 </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading9">
                    <h5 class="mb-0">
                        <button class="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse9" aria-expanded="false" aria-controls="collapseThree">
                        {t("X. Other Charges")}
                        </button>
                    </h5>
                    </div>
                    <div id="collapse9" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                    <div class="card-body">
                    <div className="mayor-table2 ">
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td scope="row"> 1. </td>
                            <td> {t("Notification Charges ")}</td>
                            <td> 100.00 </td>
                          </tr>
                          <tr>
                            <td scope="row">{t("Note :")} </td>
                            <td>
                              
                              {t("In case of business where access of vehicular traffic is not possible the rate of notification charges shall be Rs. 20.00 in each case.")}
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td scope="row"> 2. </td>
                            <td>
                              
                              {t("Charges for Supply of street watering tanker")}
                            </td>
                            <td> {t("600.00 per trip")} </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading10">
                    <h5 class="mb-0">
                        <button class="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collaps106" aria-expanded="false" aria-controls="collapseThree">
                        XI. {t("Cost Of Seizure Of Vehicles Scooter, Motor Cycle From Public Streets")}
                        </button>
                    </h5>
                    </div>
                    <div id="collapse10" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                    <div class="card-body">
                    <div className="mayor-table2 ">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <td>{t("Type of Vehicles")} </td>
                            <td>
                              {t("Charges for Seizing, Towing,Storing,etc. Rs.")}
                            </td>
                            <td>
                              {t("Demurrage Charges per day after seven days. Rs.")}
                            </td>
                            <td> {t("Maximum Demurrage Rs.")} </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">
                              
                              {t("Motor Lorry/Tanker above 3MT (Loaded)")}
                            </td>
                            <td> 4,500.00 </td>
                            <td> 100.00 </td>
                            <td> 7,000.00 </td>
                          </tr>
                          <tr>
                            <td scope="row">
                              {t("Motor Lorry/Tanker above 3MT (Empty)")}
                            </td>
                            <td>4,000.00 </td>
                            <td>100.00</td>
                            <td>7,000.00</td>
                          </tr>
                          <tr>
                            <td scope="row"> {t("Bus")} </td>
                            <td> 3,500.00 </td>
                            <td> 100.00 </td>
                            <td>7,000.00</td>
                          </tr>
                          <tr>
                            <td scope="row"> {t("Luxury Bus")} </td>
                            <td> 4,000.00 </td>
                            <td> 100.00 </td>
                            <td>7,000.00</td>
                          </tr>
                          <tr>
                            <td scope="row">
                              
                              {t("Motor Lorry / Tanker upto 3 Tonnes")}
                            </td>
                            <td> 3,500.00 </td>
                            <td> 50.00 </td>
                            <td>4,000.00</td>
                          </tr>
                          <tr>
                            <td scope="row"> {t("Mini Bus")} </td>
                            <td> 3,200.00 </td>
                            <td> 50.00 </td>
                            <td>4,000.00</td>
                          </tr>
                          <tr>
                            <td scope="row"> {t("Prime Mover with Trailor")} </td>
                            <td> 6,000.00 </td>
                            <td> 100.00 </td>
                            <td>7,000.00</td>
                          </tr>
                          <tr>
                            <td scope="row">
                              {t("Motor Car, Three Wheeler and Jeep")}
                            </td>
                            <td> 1,500.00 </td>
                            <td> 40.00 </td>
                            <td>3,500.00</td>
                          </tr>
                          <tr>
                            <td scope="row">{t("Scooter/ Motor Cycles")}</td>
                            <td> 500.00 </td>
                            <td> 20.00 </td>
                            <td>1,500.00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading11">
                    <h5 class="mb-0">
                        <button class="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse11" aria-expanded="false" aria-controls="collapseThree">
                                {t("XII. Special Fees")}
                        </button>
                    </h5>
                    </div>
                    <div id="collapse11" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                    <div class="card-body">
                        <div className="mayor-table2 ">
                        <table className="table table-bordered">
                            <tbody>
                            <tr>
                                <td scope="row"> 1. </td>
                                <td>
                                
                                {t("For occupation of footpath on road ways for auction and other similar purposes")}
                                </td>
                                <td> {t("Rs. 20.00 per sq. ft. per day.")} </td>
                            </tr>
                            <tr>
                                <td scope="row">Note : </td>
                                <td>
                                
                               {t(" Permission to be granted by Head of the ment or Head of Office as the case may be.")}
                                </td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading12">
                    <h5 class="mb-0">
                        <button class="btn btn-link fontSizeClass  collapsed" data-toggle="collapse" data-target="#collapse12" aria-expanded="false" aria-controls="collapseThree">
                        {t("XIV. Nuisance cleaning charges")}
                        </button>
                    </h5>
                    </div>
                    <div id="collapse12" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                    <div class="card-body">
                    <div className="mayor-table2 ">
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td scope="row"> 1. </td>
                            <td> {t("Cleaning charge per occasion")} </td>
                            <td> Rs. 30.00 </td>
                          </tr>
                          <tr>
                            <td scope="row">2 : </td>
                            <td>
                              
                              {t("Realisation of charges from seizure Materials from public street/Footpaths/Parks etc.")}
                            </td>
                            <td> {t("40% of the cost of seizure materials.")} </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                    </div>
                </div>

            </div>
              
            </div>
            
          </div>
          </div>
        </div>
        
      </div>
    </div>
  </section>
  </>
  )
}

export default Schedule_Of_Fees