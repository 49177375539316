import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const MC_Circular_2018_19 = () => {
  const fileDownload=(fileStoreId)=>
        {
          
          let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
           axios
              .post(url,{"fileId": fileStoreId, "userId": "392" })
              .then((res) => {
                  console.log("response is --- ", res);
      
                  if(res && res.data && res.status === 200) {
                    const base64 = res.data[0] && res.data[0].base64_data;
                    const pdfData = 'data:application/pdf;base64,'+base64;
      
                    let html = '<html>' +
                        '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                        '<body>' +
                        '<iframe src="' + pdfData + '"></iframe>' +
                        '</body></html>';
                        console.log(html);
                    let a = window.open();
                    a.document.write(html);
                }
              })
              .catch((e) => {
                  // setLoader(false);
                  console.log("The error is ", e);
              })
        }
  const { t, i18n, ready } = useTranslation();
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
    const [DocumentService, setDocumentService] = React.useState(false);
    const [LicenseService, setLicenseService] = React.useState(false);

    const LicenseServiceToggle = () => {
      setLicenseService(!LicenseService);
    };
    const DocumentServiceToggle = () => {
    setDocumentService(!DocumentService);
    };
  return (
<>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
  <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      
  </div>
    </section>
    <section className="section inner-pad1">
      <div className="container">
        <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <button type="button" class="btn nav-link pr-0" data-toggle="collapse" data-target="#demo">{t("Municipal Commissioner CIRCULAR")}<i className="bi bi-chevron-down " /></button>
                    <div id="demo" class="collapse">
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2024_25'> {t("MC's Circular")} 2024-25 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2023_24'> {t("MC's Circular")} 2023-24</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2022_23'> {t("MC's Circular")} 2022-23 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2021_22'> {t("MC's Circular")} 2021-22</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2020_21'> {t("MC's Circular")} 2020-21</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2019_20'> {t("MC's Circular")} 2019-20</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass active1" to='/MC_Circular_2018_19'> {t("MC's Circular")} 2018-19</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2017_18'>{t("MC's Circular")} 2017-18</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2016_17'>{t("MC's Circular")} 2016-17</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2015_16'>{t("MC's Circular")} 2015-16</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2004_05'>{t("MC's Circular")} 2004-05</Link></li>
                    </div>
                  </li><li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Municipal_secretary_circular'>{t("MUNICIPAL SECRETARY'S CIRCULAR")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/CMF_Communique_2022_23'>{t("C M F & A's Circular and Communique - 2022-23")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Chief_Manager_Circular'>{t("Chief Manager (P)'s Circular")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Govt_memo'>{t("Govt Memo")}</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1558')}> {t("West Bengal Right to Public Service Act,2013")} </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1559')}>{t("Guidelines of Section 199(2) of KMC Act,1980 (as amended in 2019)")}  </a>
                  </li>
                  <li className="nav-item-list ">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("COMPENDIUM OF IMPORTANT DEPARTMENTAL CIRCULARS / ORDERS")}<i className="bi bi-chevron-down " /></button>
                      <div id="demo1" className="collapse">                        
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>1. Financial Year 2010-11 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>2. Financial Year 2011-12 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('870')}>3. Financial Year 2012-13 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('925')}>4. Financial Year 2013-14 </a>   
                      </div>
                  </li>
                  
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2 bas">
                  <h3 className="fontSizeClass"> {t("Circular")} </h3>
                </div>
                <div className="health-dept-table mayor-table" style={{width:"100%"}}>
                  <table
                    width="95%"
                    border={0}
                    
                    cellPadding={2}
                    cellSpacing={2}
                    className="table table-bordered officials" >
                    <tbody>
                      <tr
                        bgcolor="#302ba0"
                        tabIndex={0}
                        
                        valign="middle">
                        <td width="35%" className="text-white tble-brder-left">
                        {t("Circular No")}		
                        </td>
                        <td width="20%" className="text-white">
                        {t("Date")}		
                        </td>
                        <td width="45%" className="text-white tble-brder-right">
                        {t("Subject")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1232')}>MC's Circular No.150 of 2018-19	 </a>
                        </td>
                        <td >
                        26-March-2019			
                        </td>
                        <td >
                        MC's Circular No.150 of 2018-19..
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1233')}>MC's Circular No.149 of 2018-19	</a>
                        </td>
                        <td >
                        20-March-2019	                        </td>
                        <td >
                        Safety of Foot Over Bridges.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1234')}>MC's Circular No.148 of 2018-19	</a>
                        </td>
                        <td >09-March-2019	</td>
                        <td >
                        MC's Circular No.148 of 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1235')}>MC's Circular No.147 of 2018-19	</a>
                        </td>
                        <td >
                        09-March-2019					
                        </td>
                        <td >
                        MC's Circular No.147 of 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1236')}>MC's Circular No.146 of 2018-19	</a>
                        </td>
                        <td >
                        08-March-2019	                        </td>
                        <td >
                        Issuance of Photo Identity Cards for K.M.C. employees.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1237')}>MC's Circular No.145 of 2018-19	</a>
                        </td>
                        <td >06-March-2019	</td>
                        <td >
                        Board to be affixed on all KMC properties.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1238')}>MC's Circular No.144 of 2018-19	</a>
                        </td>
                        <td >05-March-2019				
                        </td>
                        <td >
                        MC's Circular No.144 of 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1239')}>MC's Circular No.143 of 2018-19	</a>
                        </td>
                        <td >05-March-2019	 </td>
                        <td >
                        Concession not allowed for booking of Community Hall of Kolkata Municipal Corporation
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1240')}>MC's Circular No.142 of 2018-19	</a>
                        </td>
                        <td >02-March-2019	  </td>
                        <td >
                        Corrigendum to Municipal Commissioner's Circular No. 134 of 2018-19 dated 21.02.2019. Urgent issuance of Work Order before declaration of General Election regarding.

                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1241')}>MC's Circular No.141 of 2018-19	</a>
                        </td>
                        <td >01-March-2019	</td>
                        <td >
                        MC's Circular No.141 of 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1242')}>MC's Circular No.140 of 2018-19</a>
                        </td>
                        <td >01-March-2019	</td>
                        <td >
                        MC's Circular No.140 of 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1243')}>MC's Circular No.139 of 2018-19	</a>
                        </td>
                        <td >26-February-2019	</td>
                        <td >
                        MC's Circular No.139 of 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1244')}>MC's Circular No.138 of 2018-19	</a>
                        </td>
                        <td >26-February-2019	</td>
                        <td >
                        MC's Circular No.138 of 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1245')}>MC's Circular No.137 of 2018-19</a>
                        </td>
                        <td >25-February-2019	</td>
                        <td >
                        MC's Circular No.137 of 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1246')}>MC's Circular No.136 of 2018-19	</a>
                        </td>
                        <td >23-February-2019	</td>
                        <td >Display of KMC's achievement on LED monopole screens.</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1247')}>MC's Circular No.135 of 2018-19	</a>
                        </td>
                        <td >22-February-2019	</td>
                        <td >MC's Circular No.135 of 2018-19.</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1248')}>MC's Circular No.134 of 2018-19	</a>
                        </td>
                        <td >21-February-2019	 </td>
                        <td >Urgent issuance of Work Order before declaration of General Election .</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1249')}>MC's Circular No.133 of 2018-19	</a>
                        </td>
                        <td >20-February-2019	</td>
                        <td >
                        MC's Circular No.133 of 2018-19 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1250')}>MC's Circular No.132 of 2018-19</a>
                        </td>
                        <td >20-February-2019</td>
                        <td >
                        Protection of tenant's right for the sanction of Building plan u/r 142 of the KMC Building Rules,2009 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1251')}>MC's Circular No.131 of 2018-19</a>
                        </td>
                        <td >20-February-2019	</td>
                        <td >
                        MC's Circular No.131 of 2018-19 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1252')}>MC's Circular No.130 of 2018-19	</a>
                        </td>
                        <td >16-February-2019	</td>
                        <td >
                        Embargo/restriction upon engagement/re-engagement of retired employees of KMC on contractual basis beyond 65 years of age .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1253')}>MC's Circular No.129 of 2018-19	</a>
                        </td>
                        <td >16-February-2019</td>
                        <td >
                        List of Litigated Property to be published in KMC Web Portal along with the list of Property Tax Defaulters .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1254')}>MC's Circular No.128 of 2018-19	</a>
                        </td>
                        <td >16-February-2019</td>
                        <td >Use of PRANDHARA Package drinking water in all offices of Kolkata Municipal Corporation. .</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1255')}>MC's Circular No.127 of 2018-19</a>
                        </td>
                        <td >16-February-2019	</td>
                        <td >
                        Committee to review the remuneration of Contractual Employees of KMC .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1256')}>MC's Circular No.126 of 2018-19</a>
                        </td>
                        <td >16-February-2019	</td>
                        <td >
                        Restriction on movement of files to save delay in execution.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1257')}>MC's Circular No.123 of 2018-19	</a>
                        </td>
                        <td >16-February-2019	 </td>
                        <td >
                        Periodical checking of KEIIP works from Borough.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1258')}>MC's Circular No.122 of 2018-19	</a>
                        </td>
                        <td >12-February-2019		</td>
                        <td >
                        MC's Circular No.122 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1259')}>MC's Circular No.121 of 2018-19	</a>
                        </td>
                        <td >04-February-2019	</td>
                        <td >
                        MC's Circular No.121 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1260')}>MC's Circular No.120 of 2018-19	</a>
                        </td>
                        <td >31-January-2019	</td>
                        <td >MC's Circular No.120 of 2018-19 </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1263')}>MC's Circular No.119 of 2018-19</a>
                        </td>
                        <td >31-January-2019	</td>
                        <td >
                        Files related to disposal of cases under Section 400(1) of KMC Act,1980
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1264')}>MC's Circular No.118 of 2018-19	</a>
                        </td>
                        <td >29-January-2019	</td>
                        <td >
                        Surrender of fund under State Development Schemes which are likely to remain unutilized during F.Y.2018-19-Corrigendum to M.C's circular no.114 of 2018-19 dated 25-01-2019
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1265')}>MC's Circular No.117 of 2018-19	</a>
                        </td>
                        <td >29-January-2019	</td>
                        <td >
                        Slum development works
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1266')}>MC's Circular No.116 of 2018-19</a>
                        </td>
                        <td >25-January-2019	</td>
                        <td >
                        MC's Circular No.116 of 2018-19

                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1267')}>MC's Circular No.115 of 2018-19</a>
                        </td>
                        <td >25-January-2019	</td>
                        <td >
                        Urgent issuance of work order before 28th February,2019.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1268')}>MC's Circular No.114 of 2018-19</a>
                        </td>
                        <td >25-January-2019	</td>
                        <td >
                        Surrender of funds under State Development Schemes which are likely to remain unutilized during F.Y. 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1269')}>MC's Circular No.113 of 2018-19	</a>
                        </td>
                        <td >18-January-2019	</td>
                        <td >
                        MC's Circular No.113 of 2018-19
                       </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1270')}>MC's Circular No.112 of 2018-19		</a>
                        </td>
                        <td >18-January-2019	</td>
                        <td >MC's Circular No.112 of 2018-19</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1271')}>MC's Circular No.111 of 2018-19</a>
                        </td>
                        <td >17-January-2019	</td>
                        <td >
                        Preparation of Annual Development Plan for 2019-20 as advised by UD&MA Department , Government of West Bengal
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1272')}>MC's Circular No.110 of 2018-19	</a>
                        </td>
                        <td >10-January-2019	</td>
                        <td >
                        MC's Circular No.110 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1273')}>MC's Circular No.109 of 2018-19	</a>
                        </td>
                        <td >08-January-2019	</td>
                        <td >MC's Circular No.109 of 2018-19</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1274')}>MC's Circular No.108 of 2018-19</a>
                        </td>
                        <td >07-January-2019	</td>
                        <td >
                        Incorporation of Maintenance Cost of Different Roads in the DPR
                       </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1275')}>MC's Circular No.107 of 2018-19</a>
                        </td>
                        <td >05-January-2019</td>
                        <td >Committee to analyse the variance of rates of items with the current PWD schedule.</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1276')}>MC's Circular No.106 of 2018-19</a>
                        </td>
                        <td >05-January-2019</td>
                        <td >
                        Display of BOQ at work site during execution of work
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1277')}>MC's Circular No.105 of 2018-19	</a>
                        </td>
                        <td >05-January-2019	</td>
                        <td >MC's Circular No.105 of 2018-19</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1278')}>MC's Circular No.104 of 2018-19</a>
                        </td>
                        <td >03-January-2019</td>
                        <td >MC's Circular No.104 of 2018-19</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1279')}>MC's Circular No.103 of 2018-19	</a>
                        </td>
                        <td >03-January-2019</td>
                        <td >Attendance through Biometric System</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1280')}>MC's Circular No.102 of 2018-19	</a>
                        </td>
                        <td >02-January-2019</td>
                        <td >MC's Circular No.102 of 2018-19</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1281')}>MC's Circular No.101 of 2018-19</a>
                        </td>
                        <td >31-December-2018	</td>
                        <td >MC's Circular No.101 of 2018-19</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1282')}>MC's Circular No.100 of 2018-19</a>
                        </td>
                        <td >29-December-2018</td>
                        <td >MC's Circular No.100 of 2018-19</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1283')}>MC's Circular No.99 of 2018-19</a>
                        </td>
                        <td >24-December-2018</td>
                        <td >MC's Circular No.99 of 2018-19</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1284')}>MC's Circular No.98 of 2018-19</a>
                        </td>
                        <td >19-December-2018</td>
                        <td >
                        MC's Circular No.98 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1285')}>MC's Circular No.97 of 2018-19	</a>
                        </td>
                        <td >17-December-2018</td>
                        <td >MC's Circular No.97 of 2018-19	</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1286')}>MC's Circular No.96 of 2018-19		</a>
                        </td>
                        <td >17-December-2018	</td>
                        <td >MC's Circular No.96 of 2018-19	</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1287')}>MC's Circular No.95 of 2018-19	</a>
                        </td>
                        <td >17-December-2018	</td>
                        <td >
                        MC's Circular No.95 of 2018-19	
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1288')}>MC's Circular No.94 of 2018-19</a>
                        </td>
                        <td >08-December-2018	</td>
                        <td >
                        MC's Circular No.94 of 2018-19	
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1289')}>MC's Circular No.93 of 2018-19	</a>
                        </td>
                        <td >07-December-2018	 </td>
                        <td >
                        MC's Circular No.93 of 2018-19	
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1290')}>MC's Circular No.92 of 2018-19	</a>
                        </td>
                        <td >07-December-2018	</td>
                        <td >MC's Circular No.92 of 2018-19	</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1291')}>MC's Circular No.91 of 2018-19</a>
                        </td>
                        <td >01-December-2018	</td>
                        <td >MC's Circular No.91 of 2018-19 </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1292')}>MC's Circular No.90 of 2018-19	</a>
                        </td>
                        <td >28-November-2018	</td>
                        <td >MC's Circular No.90 of 2018-19	 </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1293')}>MC's Circular No.89 of 2018-19	</a>
                        </td>
                        <td >28-November-2018	</td>
                        <td >Prosecution of offender for depositing or throwing of rubbish,filthy,waste,offensive matter $ polluted matter at any public street,Municipal drain,public place,land belonging to the Corporation or any unoccupied land or on the bank of water course</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1294')}>MC's Circular No.88 of 2018-19</a>
                        </td>
                        <td >28-November-2018	</td>
                        <td >MC's Circular No.88 of 2018-19	 </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1295')}>MC's Circular No.87 of 2018-19</a>
                        </td>
                        <td >24-November-2018</td>
                        <td >Revised norms for acceptance of L1/H1 bid/Single bid when the no.of qualified bidders during 2nd call is than 3</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1296')}>MC's Circular No.86 of 2018-19</a>
                        </td>
                        <td >22-November-2018</td>
                        <td >
                        To provide Completion Plan & Completion Certificate to Assessment Department
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1297')}>MC's Circular No.85 of 2018-19	</a>
                        </td>
                        <td >20-November-2018</td>
                        <td >
                        Municipal Commissioner's Circular No. 85 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1298')}>MC's Circular No.84 of 2018-19</a>
                        </td>
                        <td >16-November-2018	</td>
                        <td >
                        All Director Generals to take fotuightly review meeting to take stock of the developmental projects and schemes
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1299')}>MC's Circular No.83 of 2018-19	</a>
                        </td>
                        <td >05-November-2018	</td>
                        <td >Deployment of officials at the different ghats on the river Hooghly and ponds in added areas</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1300')}>MC's Circular No.82 of 2018-19</a>
                        </td>
                        <td >01-November-2018</td>
                        <td >Statement of requirement of fund from UD & MA department,GoWB as per the format given below</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1301')}>MC's Circular No.81 of 2018-19</a>
                        </td>
                        <td >01-November-2018</td>
                        <td >Revised norms for extension of dates for getting more bids when the nos.of bids received is less then three or insufficient</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1302')}>MC's Circular No.80 of 2018-19</a>
                        </td>
                        <td >29-October-2018</td>
                        <td >Municipal Commissioner's Circular No. 80 of 2018-19 </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1303')}>MC's Circular No.79 of 2018-19		</a>
                        </td>
                        <td >13-October-2018	</td>
                        <td >MC's Circular No.79 of 2018-19	</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1304')}>MC's Circular No.78 of 2018-19		</a>
                        </td>
                        <td >12-October-2018</td>
                        <td >MC's Circular No.78 of 2018-19	</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1305')}>3098(150)-UD/O/M/B/MISC-05/2016(Urban Development & Municipal Affairs Department)	</a>
                        </td>
                        <td >13-dec-2019</td>
                        <td >
                        Municipal Commissioner's Circular No. 124 of 2019-20
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1306')}>MC's Circular No. 114 of 2019-20	</a>
                        </td>
                        <td >11-October-2018</td>
                        <td >Revised Budget 2018-19 & Budget Estimate for 2019-20 </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1307')}>MC's Circular No.77 of 2018-19</a>
                        </td>
                        <td >11-October-2018</td>
                        <td >Municipal Commissioner's Circular No. 77 of 2018-19 </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1308')}>MC's Circular No.76 of 2018-19	</a>
                        </td>
                        <td >11-October-2018</td>
                        <td >Emergent and superior-level personal arrangements during festive days (most particliarly from October, 15th, 2018 to October, 25th, 2018 )</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1309')}>MC's Circular No.75 of 2018-19</a>
                        </td>
                        <td >03-October-2018</td>
                        <td >Despatch of Salary,Ex-Gratia and WBUES Bills by the concerned department before 06/10/2018 </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1310')}>MC's Circular No.74 of 2018-19</a>
                        </td>
                        <td >03-October-2018</td>
                        <td >
                        Submission of Detailed Project reports conforming with the Municipal Commissioner's Circular No 66 of 2018-2019 dates 20.09.2018
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1310')}>MC's Circular No.73 of 2018-19	</a>
                        </td>
                        <td >29-September-2018</td>
                        <td >
                        To submit the list of matters pending with the UD & MA Department,GOWB
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1311')}>MC's Circular No.72 of 2018-19</a>
                        </td>
                        <td >28-September-2018</td>
                        <td >
                        MC's Circular No.72 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1312')}>MC's Circular No.71 of 2018-19</a>
                        </td>
                        <td >26-September-2018</td>
                        <td >
                        Emergent instruments/apparatus arrangements during festive days (most particliarly from (October, 15th, 2018 to October, 25th, 2018))24 * 7 arrangements
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1312')}>MC's Circular No.70 of 2018-19</a>
                        </td>
                        <td >26-September-2018</td>
                        <td >
                        Emergent and superior-level personal arrangements during festive days (most particliarly from October, 15th, 2018 to October, 25th, 2018 )
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1313')}>MC's Circular No.69 of 2018-19</a>
                        </td>
                        <td >25-September-2018</td>
                        <td >
                        MC's Circular No.69 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1314')}>MC's Circular No.68 of 2018-19</a>
                        </td>
                        <td >25-September-2018</td>
                        <td >
                        MC's Circular No.68 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1315')}>MC's Circular No.67 of 2018-19</a>
                        </td>
                        <td >20-September-2018</td>
                        <td >
                        Defect Liability Period of different project
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1316')}>MC's Circular No.66 of 2018-19</a>
                        </td>
                        <td >20-September-2018</td>
                        <td >
                        Format for Detailed Project reports
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1317')}>MC's Circular No.65 of 2018-19</a>
                        </td>
                        <td >18-September-2018</td>
                        <td >MC's Circular No.65 of 2018-19 </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1318')}>MC's Circular No.64 of 2018-19</a>
                        </td>
                        <td >17-September-2018</td>
                        <td >MC's Circular No.64 of 2018-19 </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1319')}>MC's Circular No.63 of 2018-19</a>
                        </td>
                        <td >14-September-2018</td>
                        <td >MC's Circular No.63 of 2018-19 </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1320')}>MC's Circular No.62 of 2018-19</a>
                        </td>
                        <td >14-September-2018</td>
                        <td >
                        Affirmation of Affidavits to be filed before the Court of Law
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1321')}>MC's Circular No.61 of 2018-19</a>
                        </td>
                        <td >08-September-2018</td>
                        <td >
                        Field visit of officers for effective control of Vector Borne Diseases
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1322')}>MC's Circular No.60 of 2018-19</a>
                        </td>
                        <td >08-September-2018	</td>
                        <td >
                        Formation of a dedicated team for Majherhat Bridge works
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1323')}>MC's Circular No.59 of 2018-19</a>
                        </td>
                        <td >07-September-2018</td>
                        <td >
                        MC's Circular No.59 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1324')}>MC's Circular No.58 of 2018-19</a>
                        </td>
                        <td >31-August-2018	</td>
                        <td >
                        MC's Circular No.58 of 2018-19	                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1325')}>MC's Circular No.57 of 2018-19</a>
                        </td>
                        <td >28-August-2018</td>
                        <td >Removal of various types of wastes from throughfare.</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1326')}>MC's Circular No.56 of 2018-19</a>
                        </td>
                        <td >24-August-2018</td>
                        <td >
                        Auto generation of inspection notice/hearing notice.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1327')}>MC's Circular No.55 of 2018-19</a>
                        </td>
                        <td >23-August-2018</td>
                        <td >
                        Requirement of approval of Government -constituted Tender Committee.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1328')}>MC's Circular No.54 of 2018-19</a>
                        </td>
                        <td >09-August-2018</td>
                        <td >
                        Government Order regarding fixation of board at the sites and prioritazation of sector wise schemes during submission to the Urban Development & Municipal Affairs Department,Govt. of West Bengal.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1329')}>MC's Circular No.53 of 2018-19</a>
                        </td>
                        <td >08-August-2018</td>
                        <td >
                        Payment of salary to the KMC employees through E-pradan(e-payment) under IFMS module of the Government of West Bengal.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1330')}>MC's Circular No.52 of 2018-19</a>
                        </td>
                        <td >06-August-2018</td>
                        <td >
                        Guidelines for creation of Organisational Hiararchy and Selections of Primary/Secondary Users in Gem.Ref: procurement of Goods and Services through Government e-Marketplace(GeM).
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1331')}>MC's Circular No.51 of 2018-19</a>
                        </td>
                        <td >31-July-2018</td>
                        <td >
                        Requisition of surrendered fund from state Government.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1332')}>MC's Circular No.50 of 2018-19</a>
                        </td>
                        <td >28-July-2018</td>
                        <td >
                        Lodging of F.I.R on detection of forged and fake signature(s) of official(s) of KMC on documents and papers
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1333')}>MC's Circular No.49 of 2018-19</a>
                        </td>
                        <td >25-July-2018</td>
                        <td >
                        Revised list of Agencies to execute Government works under Rule 47D of WBFR
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1334')}>MC's Circular No.48 of 2018-19</a>
                        </td>
                        <td >24-July-2018</td>
                        <td >
                        Additional Performance Security and splitting up of works and/or increase in scope of work of projects during excution phase
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1335')}>MC's Circular No.47 of 2018-19</a>
                        </td>
                        <td >18-July-2018</td>
                        <td >
                        Formation of a committee to determine the rates of non -schedule items.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1336')}>MC's Circular No.46 of 2018-19</a>
                        </td>
                        <td >16-July-2018	</td>
                        <td >
                        Procurement of Goods and Services through Government e-Marketplace(GeM)
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1337')}>MC's Circular No.45 of 2018-19</a>
                        </td>
                        <td >12-July-2018</td>
                        <td >
                        Circular on Economy Measures and constitution of State Level Committee on Rationalisation of Human Resources
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1338')}>MC's Circular No.44 of 2018-19</a>
                        </td>
                        <td >06-July-2018</td>
                        <td >
                        MC's Circular No.44 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1339')}>MC's Circular No.43 of 2018-19</a>
                        </td>
                        <td >06-July-2018</td>
                        <td >
                        MC's Circular No.43 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1340')}>MC's Circular No.42 of 2018-19</a>
                        </td>
                        <td >04-July-2018</td>
                        <td >
                        Civil Engineering Department to execute the works of Bustee Development in Borough IX
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1341')}>MC's Circular No.41 of 2018-19</a>
                        </td>
                        <td >26-June-2018</td>
                        <td >
                        MC's Circular No.41 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1342')}>MC's Circular No.40 of 2018-19</a>
                        </td>
                        <td >25-June-2018</td>
                        <td >
                        MC's Circular No.40 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1343')}>MC's Circular No.39 of 2018-19</a>
                        </td>
                        <td >22-June-2018	</td>
                        <td >
                        Refund of unutilised funds to state exchequer
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1344')}>MC's Circular No.38 of 2018-19</a>
                        </td>
                        <td >15-June-2018</td>
                        <td >
                        Schedule of joint inspection for disaster management precaution
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1345')}>MC's Circular No.37 of 2018-19</a>
                        </td>
                        <td >13-June-2018</td>
                        <td >
                        MC's Circular No.37 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1346')}>MC's Circular No.36 of 2018-19</a>
                        </td>
                        <td >08-June-2018	</td>
                        <td >Formation of Fair Rate Committee in KMC </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1347')}>MC's Circular No.35 of 2018-19</a>
                        </td>
                        <td >07-June-2018</td>
                        <td >
                        MC's Circular No.35 of 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1348')}>MC's Circular No.34 of 2018-19</a>
                        </td>
                        <td >07-June-2018</td>
                        <td >
                        MC's Circular No.34 of 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1349')}>MC's Circular No.33 of 2018-19</a>
                        </td>
                        <td >07-June-2018	</td>
                        <td >
                        Census 2021 to be conducted under SW&UPA Department,Headquarters,KMC
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1350')}>MC's Circular No.32 of 2018-19</a>
                        </td>
                        <td >25-May-2018</td>
                        <td >
                        MC's Circular No.32 of 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1351')}>MC's Circular No.31 of 2018-19</a>
                        </td>
                        <td >21-May-2018</td>
                        <td >
                        IT Department to develop (i) e-service book for employees and (ii)sms-alert for GPS subscribers of KMC.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1352')}>MC's Circular No.30 of 2018-19</a>
                        </td>
                        <td >17-May-2018	</td>
                        <td >
                        Adjustment of unadjusted suspense amountwith the PD Bills allowing extra 5% rebate in 2nd,3rd and 4thquarter where all four quarters can be adjusted S/A deposit.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1353')}>MC's Circular No.29 of 2018-19	</a>
                        </td>
                        <td >16-May-2018</td>
                        <td >
                        Expenditure Planning of Fund received from Govt. the of West Bengal.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1354')}>MC's Circular No.28 of 2018-19</a>
                        </td>
                        <td >15-May-2018	</td>
                        <td >
                        Emergency arrangements during the ensuring Nor'wester/Rainy season in the next few months of 2018 .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1355')}>MC's Circular No.27 of 2018-19</a>
                        </td>
                        <td >11-May-2018</td>
                        <td >
                        Municipal Commissioner's Circular No. 27 of 2018-19, Dated 11th May, 2018
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1356')}>MC's Circular No.26 of 2018-19	</a>
                        </td>
                        <td >10-May-2018</td>
                        <td >
                        Corrigendum to Municipal Commissioner Circular No. 18 of 2018-19,dated 26th April,2018,regarding the restriction of deployment of workers working under WBUES and UWEP during the summer months.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1357')}>MC's Circular No.25 of 2018-19</a>
                        </td>
                        <td >08-May-2018</td>
                        <td >
                        Municipal Commissioner's Circular No. 25of 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1358')}>MC's Circular No.24 of 2018-19</a>
                        </td>
                        <td >07-May-2018</td>
                        <td >
                        Additional Performance Security.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1359')}>MC's Circular No.23 of 2018-19</a>
                        </td>
                        <td >07-May-2018</td>
                        <td >
                        Municipal Commissioner's Circular No. 23 of 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1360')}>MC's Circular No.22 of 2018-19</a>
                        </td>
                        <td >05-May-2018</td>
                        <td >
                        Status of unutilized fund allotted by the UD & MA Department in the FY 2016 -17 and 2017-18.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1361')}>MC's Circular No.21 of 2018-19</a>
                        </td>
                        <td >04-May-2018</td>
                        <td >
                        Revised Project Review meeting and Revenue Meeting Schedule.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1362')}>MC's Circular No.20 of 2018-19</a>
                        </td>
                        <td >03-May-2018</td>
                        <td >
                        Municipal Commissioner's Circular No. 20 of 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1363')}>MC's Circular No.19 of 2018-19</a>
                        </td>
                        <td >28-April-2018</td>
                        <td >
                        Tender papers to be downloaded by the intended tenderers from portal free of cost.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1364')}>MC's Circular No.18 of 2018-19		</a>
                        </td>
                        <td >26-April-2018	</td>
                        <td >
                        MC's Circular No.18 of 2018-19	
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1365')}>MC's Circular No.17 of 2018-19</a>
                        </td>
                        <td >24-April-2018</td>
                        <td >
                        Withdrawal Vs Advance Payment.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1366')}>MC's Circular No.16 of 2018-19		</a>
                        </td>
                        <td >24-April-2018</td>
                        <td >
                        Strengthening of inter-departmental coordination and cooperation in relation to prevention of mosquito breeding,in144 wards of KMC.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1367')}>MC's Circular No.15 of 2018-19		</a>
                        </td>
                        <td >23-April-2018</td>
                        <td >
                        Municipal Commissioner's Circular No. 15 of 2018-19.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1368')}>MC's Circular No.14 of 2018-19</a>
                        </td>
                        <td >23-April-2018</td>
                        <td >
                        MIC Agenda Item to be vetted by Finance & Accounts Department.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1369')}>MC's Circular No. 13 of 2018-19</a>
                        </td>
                        <td >23-April-2018</td>
                        <td >
                        Tender papers to be downloaded by the intended tenderers from KMC web portal.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1370')}>MC's Circular No. 12 of 2018-19</a>
                        </td>
                        <td >232-April-2018</td>
                        <td >
                        Participation of the enlisted contractors of PW Department,Irrigation & Waterways Department and Public Health & Engineering Department,Government of West Bengal,in Tenders invited by KMC.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1371')}>MC's Circular No. 11 of 2018-19</a>
                        </td>
                        <td >18-April-2018</td>
                        <td >
                        Proposal for holding sanctioned fund up to 30th June,2018 in terms of Memorandum No.1900-F(Y) dated 24.03.2018 of Finance Department(Audit Branch),Government of West Bengal.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1372')}>MC's Circular No. 10 of 2018-19</a>
                        </td>
                        <td >17-April-2018</td>
                        <td >
                        Financial vetting is essential for seeking Government Fund and for placing the estimate before the Mayor-in-Council Meeting.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1373')}>MC's Circular No. 09 of 2018-19	</a>
                        </td>
                        <td >17-April-2018</td>
                        <td >
                        Formation of Technical Committee to review the KMC Schedule of Rates (SOR).
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1374')}>MC's Circular No. 08 of 2018-19</a>
                        </td>
                        <td >17-April-2018	</td>
                        <td >
                        Dhapa Water Treatment Plant to be maintained and operated by Water Supply Department,KMC.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1375')}>MC's Circular No. 07 of 2018-19</a>
                        </td>
                        <td >17-April-2018	</td>
                        <td >
                        Construction of Community Hall.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1376')}>MC's Circular No. 06 of 2018-19	</a>
                        </td>
                        <td >17-April-2018</td>
                        <td >
                        Municipal Commissioner's Circular No. 06 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1377')}>MC's Circular No. 05 of 2018-19</a>
                        </td>
                        <td >12-April-2018</td>
                        <td >
                        To expedite procurement of emergency items.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1378')}>MC's Circular No. 04 of 2018-19</a>
                        </td>
                        <td >10-April-2018</td>
                        <td >
                        Municipal Commissioner's Circular No. 04 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1379')}>MC's Circular No. 03 of 2018-19</a>
                        </td>
                        <td >03-April-2018</td>
                        <td >
                        Municipal Commissioner's Circular No. 03 of 2018-19
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1380')}>MC's Circular No. 02 of 2018-19	</a>
                        </td>
                        <td >03-April-2018</td>
                        <td >
                        Proposal for holding sanctioned fund up to 30th June,2018 in terms of Memorandum No.1900-F(Y) dated 24.03.2018 of Finance Department(Audit Branch),Government of West Bengal.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1381')}>MC's Circular No. 01 of 2018-19	</a>
                        </td>
                        <td >02-April-2018</td>
                        <td >
                        Developmental works of KMC that is to be carried on the land of R.R.& R Dept.,Govt of West Bengal.
                        </td>
                      </tr>
                      </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>  )
}

export default MC_Circular_2018_19