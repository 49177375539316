import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Building_Sanction_Building_Home  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2 mb-4">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Building_Sanction_Building_Home'> {t("Building Home")}</Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Dashboard2' target="_blank"> {t("EODB Dashboard For Building Permit")} </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2022_23'> {t("Circular")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('319')}> {t("Building Demand Notice")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Floor_Area_Ratio'> {t("Floor Area Ratio")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Ground_Covergae'> {t("Ground Coverage")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Borough_Offices'> {t("Borough Offices")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('320')}> {t("Fees and Charges")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalBuildingLBSDetails.jsp' target="_blank"> {t("Find LBS")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalESESearch.jsp' target="_blank"> {t("Find ESE")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalBuildingGTDetails.jsp' target="_blank"> {t("Find GTE")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalBuildingESRDetails.jsp' target="_blank"> {t("Find ESR")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/HearingNotice.jsp' target="_blank"> {t("Check Hearing Notice")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Download_Forms'> {t("Download Forms")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/PlanProposalStatusSearch.jsp' target="_blank"> {t("Building Plan Proposal Status")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCViewBuildingDemand.jsp' target="_blank"> {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingDemandPrint.jsp' target="_blank"> {t("Print Unpaid Bill")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingPayment.jsp' target="_blank"> {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingReceiptReprint.jsp' target="_blank"> {t("Reprint e-Receipt")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('321')}> {t("Instruction Set for Online Submission of Building Pla")}n </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/Building/gotologin.do' target="_blank"> {t("Online Submission of Building Plan")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('322')}> {t("Online CC Circular")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Important_EODB_circulars'> {t("Important EODB Circulars")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('323')}> {t("Instruction set for Online Notice of Commencement and Notice of Completion Certificate")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp' target="_blank"> {t("Online Notice of Commencement/CC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('324')}> {t("MC Circular No.44 of 2021-22 Regarding Assessment Data at the time of CC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href="assets/img/Final_Format_Flat_Assessment.xls" download="Final_Format_Flat_Assessment.xls"><i className="bi bi-download"></i> {t("Download Standard(.xls) format for MC Circular No.44 of 2021-22")} </a>                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://docs.google.com/forms/d/e/1FAIpQLSfmcCELn86TXflHLGQo4ZbhOiGhbN93TTmulOf15G7O5rVIpw/closedform' target="_blank"> {t("Architect Enlistment and Renewal")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp' target="_blank"> {t("License Renewal for Technical Personnel")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('325')}> {t("Instruction set for Online Licence Renewal For Technical Personnel")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('326')}> {t("Instruction Set for preparation of AutoCAD Drawing for Online Submission")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingPlanCaseSearch.jsp' target="_blank"> {t("Sanctioned Plan Search")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingCompletionCertificateSearch.jsp' target="_blank"> {t("Completion Certificate Search")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingUnauthorisedCaseSearch.jsp' target="_blank"> {t("Unauthorised Case Search")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Faqs'> {t("FAQs")}</Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Officer_Order' > {t("Office Order")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Communique'> {t("Communique")}  </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('325')}> {t("DGBuilding Circular No 9(2013-2014)")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('327')}> {t("Empanelment of Geo-Technical")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('329')}> {t("West Bengal Act XIX of 2014 & The Kolkata Municipal Corporation(Amendment)Act,2014")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('328')}> {t("Check list for Sanction of Building Plan")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('330')}> {t("Building Rules 2009 including amendment")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://nocas2.aai.aero/nocas/' target="_blank"> {t("Airport Building Height Clearance")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('331')}> {t("Applications are invited from the Civil Engineers of Empanelment of Geo-Technical Engineers")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('66')}> {t("3rd Amendment of KMC Building Rules 2009 Geo Tech and Road width")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('67')}> {t("Communique(The Amendment of Building Rules,2009 video notification No 80/MA/O/C-4/3R-7/2017)")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('68')}> {t("Distribution of guideline among Building-owners/Constituted Attorney in different wards of KMC for prevention of mosquito breeding at construction sites on an urgent basis")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('69')}> {t("Notification No 445/MA/O/C-4/3R-3/2014")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Building_notice_28_05_2019.pdf' target="_blank"> {t("Building Notice")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/LicenseNoWiseSanctionSearch.jsp' target="_blank"> {t("LBS/CA/ESE/GTE/ESR WISE SANCTION SEARCH")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Notice_LBS_Architect_03_09_2019.rar' target="_blank"> {t("Notice for LBS/Architect")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('70')}> {t("EODB Proforma For Appointment of Technical Person")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('71')}> {t("EODB Standard Reasons Of Rejection")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('72')}> {t("EODB IGR Non-insisting of KMC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('73')}> {t("EODB Workflow")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('74')}> {t("FAQ for EODB")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('75')}> {t("User Manual for EODB")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('76')}> {t("Public Notice -- Pollutions at Construction Site")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('c16f7b2c-4d2b-4b8c-96fd-0b81ba0516ef')}> {t("Circulation of Administrative order for regulating sanction of building plan/construction")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href="assets/img/Enemy_Properties.xls" download="Enemy_Properties.xls"><i className="bi bi-download"></i> {t("List of Enemy Properties")} </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass"> {t("Building Sanction")}</h3>
            </div>
            <div className="col-lg-12 about-head1">
              <div className="unit-area-assessment">
                <p className="fontSizeClass">
                  <b>
                    {t("(a) The pre-conditions for an application for building sanction plan")} :
                  </b>
                </p>
                <p className="fontSizeClass">
                  {t("Person/persons having right of erection on a plot of land, or who is the owner of a plot of land having deed of conveyance and mutation certificate and has no dues towards property and other Municipal tax may apply for a building sanction plan subject to details covered in para (c)")}.
                </p>
                <br />
                &nbsp;
                <p className="fontSizeClass">&nbsp; </p> <br />
                <p className="fontSizeClass">
                  <b>
                    {t("(b) Parameter of rate chart on various items of building sanction")} :
                  </b>
                </p>
                <p className="fontSizeClass">
                  {t("This varies from time to time. Click here to view the schedule of fees for the current financial year")}.
                </p>
                <br />
                <p className="fontSizeClass">&nbsp; </p> <br />
                <p className="fontSizeClass">
                  <b>
                    {t("(c) Check list for application for building sanction for normal building premises and for high rise building premises including provision of lift")}.
                  </b>
                  <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1508')} target="_blank"> {t("Click here to view the checklist")}. </a>
                    </p>
                &nbsp; <br />
                <p className="fontSizeClass">
                  <b>
                    {t("(d) Occasions when orders for demolition or stoppage of building are issued")} :
                  </b>
                </p>
                <p className="fontSizeClass">
                  {t("Any construction work carried out without a valid sanction plan is liable to demolition. Initially, after detection of such illegal construction, a stop work notice is served. There after KMC authority deals it under section 400 (I) or 400 (8) of KMCAct 1980. U/s 400 (1) the case is disposed off by hearing where the structure may be demolished / part demolished / fully retained considering degree of violation of rule as well as structural safety. The cases where there is danger and / or violation of rule, KMC authority may directly demolish the structure without giving chance of hearing U/S 400(8)")}.
                </p>
                <br />
                <p className="fontSizeClass">&nbsp; </p> <br />
                <p className="fontSizeClass">
                  <b>{t("(e) Requirement and format of completion certificate")} :</b> 
                 <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1509')} target="_blank"> {t("Download")} </a>
                </p>
                <br /> <p className="fontSizeClass">&nbsp; </p> <br />
                <p className="fontSizeClass">
                  <b>
                    {t("(f) List of Registered License Building Surveyors/Empanelled Structural Engineer")} :
                  </b>
                </p>
                <p className="fontSizeClass">
                <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCPortalBuildingLBSDetails.jsp" target="_blank"> {t("View List")} </a>
                </p>
                <br /> <p className="fontSizeClass">&nbsp; </p> <br />
                <p className="fontSizeClass">
                  <b>{t("(g) Scope of appeal in Municipal Building Tribunal")} :</b>
                </p>
                <p className="fontSizeClass">
                  {t("Refer para (d). If a case of illegal construction is dealt u/s 400 (1) and a oeder of Special Officer (B) is passed. Being agreed by the order of SO (B), any appeal may be preferred U/s 415 of KMC Act 1980 to the Municipal Building Tribunal by the person responsible or complainant")}.
                </p>
                <br /> <p className="fontSizeClass">&nbsp; </p> <br />
                <p className="fontSizeClass">
                  <b>
                    {t("(h) Various regulations of Building uses as per provisions of the Act")}.
                  </b>
                </p>
                <p className="fontSizeClass">{t("As contained in the KMC Building Rules, 2009")}</p>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Building_Sanction_Building_Home