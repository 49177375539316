import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const RTI_List_of_1st_Appellate_Authorities  = () => {
  const { t, i18n, ready } = useTranslation();
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
                
              <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/RTI_List_of_State_Public_Information_Officers'>           
                    {t("List of State Public Information Officers")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/RTI_List_of_1st_Appellate_Authorities'>             
                    {t("List of 1st Appellate Authorities")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/RTI_Important_Points_for_the_RTI_Applicants'>                   
                    {t("Important Points for the RTI Applicants")}
                  </Link>
                </li>

                <li className="nav-item">                
                 <a className="nav-link fontSizeClass" target={'_blank'} href="https://www.kmcgov.in/KMCPortal/downloads/RTISPECIMEN_FORM.pdf">
                        {t("Specimen Form of Application Seeking Information")}
                  </a>               
                </li>

                <li className="nav-item">
                  <a className="nav-link fontSizeClass" target={'_blank'} href="https://www.kmcgov.in/KMCPortal/downloads/MC_Circular75_27_03_2018.pdf">
                        {t(" Mpl.Commissioner's Circular No. 75 of 2018 dated 24.03.2018 for mode of deposition of fees under RTI Act,2005 & WBRTI Rules 2006")}
                  </a>                  
                </li>

                <li className="nav-item">
                  <a className="nav-link fontSizeClass" target={'_blank'} href="https://www.kmcgov.in/KMCPortal/downloads/RTI_TR_FORM_7.pdf">
                        {t(" T.R Form No. 7 (Challan for Deposit of money in the account of GOVERNMENT OF WEST BENGAL)")}
                  </a>                  
                </li>              

                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/RTI_Act_2005'>                   
                    {t("Proactive disclosure of organization details as mandated u/s 4 of RTI Act,2005")}
                  </Link>
                </li>

                <li className="nav-item">
                  <a className="nav-link fontSizeClass" target={'_blank'} href="https://www.kmcgov.in/KMCPortal/downloads/WBRTI_RULES_2006.pdf">
                        {t(" Amendments in the WBRTI Rules 2006 ")}
                  </a>                  
                </li> 

                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/RTI_Office_Order_Circular'>                 
                    {t("OFFICE ORDER/ CIRCULAR")}
                  </Link>
                </li>

              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass">
                  
                  {t("List of First Appellate Authority (RTI) under the Kolkata Municipal Corporation")} :
                </h3>
              </div>
              <div className="mayor-table health-dept-table1">
                <table className="table table-bordered officials m-ta-2">
                  <tbody>
                    <tr bgcolor="#302ba0" align="center" valign="middle">
                      <td className="white-clr tble-brder-left">
                        {t("Sl. No")}
                      </td>
                      <td className="white-clr">
                        {t("Department")}
                      </td>
                      <td className="white-clr">
                        {t("Jurisdiction/Area Covered")}
                      </td>
                      <td className="white-clr">
                        {t("1st Appellate Authorities")}
                      </td>
                      
                    </tr>
                    <tr align="center" valign="middle">
                      <td>1.</td>
                      <td>CME (Civil)</td>
                      <td>Matters related to CME(C) Deptt.</td>
                      <td>Dy. Chief Engineer</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>2.</td>
                      <td>Building </td>
                      <td>Matters related to Building Deptt.</td>
                      <td>Dy. Chief Engineer</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>3.</td>
                      <td>Chief Valuer & Surveyor</td>
                      <td>Matters related to Chief Valuer & Surveyor Deptt.</td>
                      <td>Chief Valuer & Surveyor</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>4.</td>
                      <td>Sewerage & Drainage</td>
                      <td>Matters related to Sewerage & Drainage and Tollys & Nullahs Deptt. </td>
                      <td>Dy. Chief Engineer</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>5.</td>
                      <td>Lighting & Electricity</td>
                      <td>Matters related to Lighting & Electricity Deptt.</td>
                      <td>Dy. Chief Engineer</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>6.</td>
                      <td>Parks & Squares</td>
                      <td>Matters related to Parks & Squares Deptt.</td>
                      <td>Dy. Chief Engineer </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>7.</td>
                      <td>Roads</td>
                      <td>Matters related to Roads & Asphaltum (PG & GG) Deptt.</td>
                      <td>Dy. Chief Engineer</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>8.</td>
                      <td>SWM I & II</td>
                      <td>Matters related to Solid Waste Management (I & II) Deptt.</td>
                      <td>Dy. Chief Engineer </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>9.</td>
                      <td>TPDD</td>
                      <td>Matters related to Town Planning Development Deptt.</td>
                      <td>Dy. Chief Engineer</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>10.</td>
                      <td>PMU</td>
                      <td>Matters related to Project Management Unit</td>
                      <td>Dy. Chief Engineer</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>11.</td>
                      <td>Water Supply</td>
                      <td>Matters related to Water Supply Deptt.</td>
                      <td>Dy. Chief Engineer </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>12.</td>
                      <td>Supply & EWS</td>
                      <td>Matters related to Supply Deptt. & Entally Work Shop</td>
                      <td>Dy. Chief Engineer</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>13.</td>
                      <td>Bustee Services & SSEP</td>
                      <td>Matters related to Bustee Services & SSEP</td>
                      <td>Dy. Chief Engineer </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>14.</td>
                      <td>KEIIP</td>
                      <td>Matters related to Kolkata Environmental Improvement Investment Programme</td>
                      <td>Dy. Chief Engineer</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>15.</td>
                      <td>MPLAD & BEUP</td>
                      <td>Matters related to MPLAD & BEUP Deptt.</td>
                      <td>Dy. Chief Engineer</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>16.</td>
                      <td>Mpl. Secretary</td>
                      <td>Matters related to Mpl. Secretary's Deptt., Mpl. Authorities Deptt., Printing, I & PR and Central Records.</td>
                      <td>Addl. Municipal Secretary</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>17.</td>
                      <td>SW&UPA</td>
                      <td>Matters related to Social Welfare & Urban Poverty Alleviation Deptt.</td>
                      <td>Chief Manager</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>18.</td>
                      <td>Market</td>
                      <td>Matters related to Market Deptt.</td>
                      <td>Chief Manager</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>19.</td>
                      <td>Licence</td>
                      <td>Matters related to Licence Deptt.</td>
                      <td>Chief Manager</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>20.</td>
                      <td>Assessment & Collection (Revenue-South)</td>
                      <td>Matters related to Assessment & Collection (South) Deptt. & Assessment & Collection (TTD)</td>
                      <td>Chief Manager</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>21.</td>
                      <td>Assessment & Collection (Revenue-North)</td>
                      <td>MMatters related to Assessment & Collection (North) Deptt.</td>
                      <td>Chief Manager</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>22.</td>
                      <td>Assessment & Collection (Revenue-Added Area)</td>
                      <td>RTI matters related to AC (SSU), AC (GRU), AC (JU) and JOKA (Tax & Non Tax Revenues) Deptt.</td>
                      <td>Chief Manager</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>23.</td>
                      <td>IUM</td>
                      <td>Matters related to Institute of Urban Management</td>
                      <td>DG (IUM)</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>24.</td>
                      <td>Personnel</td>
                      <td>Matters related to Personnel Deptt.</td>
                      <td>Chief Manager</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>25.</td>
                      <td>IT</td>
                      <td>Matters related to Information</td>
                      <td>HOD (IT) </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>26.</td>
                      <td>SR & AC</td>
                      <td>Matters related to SRAC, PF, Pension, HBL, and Staff Quartet Deptt.</td>
                      <td>Chief Manager </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>27.</td>
                      <td>Finance & Accounts</td>
                      <td>Matters related to Finance & Accounts Deptt.</td>
                      <td>Special Controller of Municipal Finance & Accounts</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>28.</td>
                      <td>LAW</td>
                      <td>Matters related to LAW Deptt.</td>
                      <td>Deputy Chief Municipal Law Officer </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>29.</td>
                      <td>Education</td>
                      <td>Matters related to Education Deptt.</td>
                      <td>Senior Education Officer</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>30.</td>
                      <td>Health</td>
                      <td>Matters related to Health Deptt.</td>
                      <td>Chief Municipal Health Officer </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>31.</td>
                      <td>Internal Audit</td>
                      <td>Matters related to Internal Audit Deptt.</td>
                      <td>Chief Municipal Auditor </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>32.</td>
                      <td>Advertisement</td>
                      <td>IMatters related to Advertisement Deptt.</td>
                      <td>Chief Manager</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>33.</td>
                      <td> Parking & Amusement Tax Deptt </td>
                      <td>Matters related to Car Parking Amusement Deptt.</td>
                      <td>Chief Manager</td>
                    </tr>

                    <tr align="center" valign="middle">
                      <td>34.</td>
                      <td>Vigilance</td>
                      <td>Matters related to Vigilance Deptt.</td>
                      <td>Vigilance Authority</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>35.</td>
                      <td>Assessment & Collection (Revenue-TTD)</td>
                      <td>Matters related to Assessment & Collection (TTD) Deptt.</td>
                      <td> - </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>36.</td>
                      <td>LAW</td>
                      <td>Matters related to LAW Deptt. </td>
                      <td>Addl. CMLO (S)</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>37.</td>
                      <td>MPLAD - BEUP</td>
                      <td>Matters related to MPLAD - BEUP Deptt.</td>
                      <td>Controlling Officer</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>38.</td>
                      <td>Census</td>
                      <td>Matters related to Census under their jurisdiction</td>
                      <td>Chief Manager (Census) </td>
                    </tr>
                    
                  </tbody>
                </table>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default RTI_List_of_1st_Appellate_Authorities