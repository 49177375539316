import React from 'react'
import { Link } from "react-router-dom";

const KMC_Bustee_service_contact_details = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> KMC - Officials </h4>
        </div>
      </div>
    </div>
  </section>
 
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu   min-height-400">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Advertisement_Dept'> Advertisement Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Amusement Dept </Link> </li>  
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Assessment Dept </Link> </li>   
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Building Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/KMC_Bustee_service_contact_details'> Bustee Dept </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Car Parking Dept</Link> </li>    
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Chief Eng Dept</Link> </li>    
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/CH_VS_Dept_Contact_Details'> Ch. V&amp;S Dept</Link> </li>
                <div class="dropdown menu-show-more-btn">
                <button type="button" class="btn menu-show-more-btn dropdown-toggle" data-toggle="dropdown"> More </button>
                <div class="dropdown-menu dept-menu">
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Drainage_Contact_Details'> Sewerage Drainage Dept </Link> </li>	
                  <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Education_Dept_Contact_Details'> Education Dept </Link> </li>					
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Electricity Dept </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Finance Dept </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Health Dept </Link> </li>	
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Internal Audit Dept </Link> </li>					
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> I.T Dept </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Law Dept </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Licence Dept </Link> </li>	
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Lighting Dept </Link> </li>					
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Market Dept </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Mpl.Secretary's Dept </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Parks &amp; Square Dept </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Heritage_Contact_Details'> Personnel Dept </Link> </li>	
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Environment &amp; Heritage Dept </Link> </li>					
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Roads Dept </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Social Welfare &amp; Urban poverty Alleviation Dept </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Solid Waste Mgmt. Dept </Link> </li>	
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Town Planning &amp; Development Dept </Link> </li>					
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Treasury Dept </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/portal'> Water Supply Dept </Link> </li>
            </div>
                </div>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> Important Officials of Bustee Department</h3>
              </div>
              <div className="mayor-table">
                <table className="table table-bordered officials">
                  <tbody>
                    <tr bgcolor="#302ba0" tabIndex={0}>
                      <td
                        width="10%"
                        rowSpan={2}
                        style={{
                          color: "#fff",
                          borderLeft: "1px solid #302ba0"
                        }}
                        align="center"
                        valign="middle"
                      >
                        Sl. No.
                      </td>
                      <td width="23%" rowSpan={2} style={{ color: "#fff" }}>
                        Name
                      </td>
                      <td width="37%" rowSpan={2} style={{ color: "#fff" }}>
                        Designation
                      </td>
                      <td
                        colSpan={2}
                        rowSpan={2}
                        style={{
                          color: "#fff",
                          borderRight: "1px solid #302ba0"
                        }}
                      >
                        
                        Office Phone No.
                      </td>
                    </tr>
                    <tr>
                      {/* <td width="20%" style="padding:3">Office</td>
              <td width="13%" style="padding:3">Resi.</td> */}
                    </tr>
                    <tr
                      align="center"
                      valign="middle"
                      bgcolor="#fff"
                      tabIndex={0}
                    >
                      <td>1.</td>
                      <td align="left">Biswajit Majumder</td>
                      <td align="left">Director General(Bustee Services)</td>
                      <td align="left">2286-1280, Extn. 2598</td>
                    </tr>
                    <tr
                      align="center"
                      valign="middle"
                      bgcolor="#fff"
                      tabIndex={0}
                    >
                      <td>2.</td>
                      <td align="left">Swapan Karmakar</td>
                      <td align="left">
                        Deputy Chief Engineer(Bustee Services)
                      </td>
                      <td align="left">
                        2286-1280 <br />
                      </td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>3.</td>
                      <td align="left">Kollol Sarkar </td>
                      <td align="left">
                        Deputy Chief Engineer (Bustee Services)/South
                      </td>
                      <td align="left">2286-1280</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>4.</td>
                      <td align="left">Bibek Biswas</td>
                      <td align="left">
                        Executive Engineer(Bustee Services)/North
                      </td>
                      <td align="left">2286-1280 </td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>5.</td>
                      <td align="left">Sajal Haldar </td>
                      <td align="left">
                        Executive Engineer(Bustee Services)/HQ
                      </td>
                      <td align="left">2286-1280</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>6.</td>
                      <td align="left">Md. Ashraf Uddin </td>
                      <td align="left">Assitant Engineer (Bustee Services) </td>
                      <td align="left">2286-1280</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>7</td>
                      <td align="left">Sudipta Shome</td>
                      <td align="left">
                        Assistant Engineer (Bustee Services)
                      </td>
                      <td align="left">2286-1280</td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>5.</td>
                      <td align="left">Tamal Krishna Khara </td>
                      <td align="left">
                        Assistant Engineer (Bustee Services)
                      </td>
                      <td align="left">2286-1280</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


    </>
  )
}

export default KMC_Bustee_service_contact_details