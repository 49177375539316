import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Solid_waste_management_faqs  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>

<section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
        <div className="container"> <div className="section-main-heading2"></div></div>             
      </div>       	 		 
    </section>	  
    {/* End Slider */}

<section className="section inner-pad1">
  <div className="container">
    <div className="row">
      <div className="col-lg-3">
      <div className="site-menu ">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Solid_wastes_home'> {t("Solid Wastes Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Schedule_Of_Fees'> {t("Schedule of Fees")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Solid_waste_management_faqs'> {t("FAQs")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Solid_Waste_Dept'> {t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('842')}>{t("Status Report SWM Feb 2019")}</a>
              </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('843')}>{t("Gazette notification for KMC SWM Bye Laws 2020")}</a>
              </li>
              </ul>
            </nav>
          </div>
      </div>
      <div className="col-lg-9">
        <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass"> {t("Solid Waste Management Dept. :: FAQs")} </h3>
            </div>
            <div className="col-lg-6">
                <div id="accordion">
                    <div className="card">
                        <div className="card-header" id="heading1">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                            {t("What are the key activities of the department?")} 
                            </button>
                        </div>
                        <div id="collapse1" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                        <p className="fontSizeClass">
                            {t("The key activities of the department include,")} <br />
                            {t("a. Collection of waste from households, commercial and institutional areas")} <br />
                            {t("b. Transportation of waste to landfill sites")} <br />
                            {t("c. Maintenance of landfill sites ")}<br />
                            {t("d. Street sweeping and washing ")}<br />
                            {t("e. Removal of carcass from roads.")}
                        </p>                        </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading2">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                            {t("What permission is needed to erect scaffolding?")}</button>
                        </div>
                        <div id="collapse2" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">

                        <p className="fontSizeClass">
                            {t("In order to erect scaffolding or temporary structure on roads you will need to obtain permission from the Assistant Director of your borough. Please write a letter to the Assistant Director of your borough detailing the location, size and the duration for which the scaffolding needs to be erected. Please visit your local ward office or borough office as per your convenience and present this letter to conservancy officer at these locations. The conservancy officer will visit your proposed location for inspection &amp; will hand you a cash voucher. Please refer to Annex B (Available Online) for the schedule of fees for the same.")}
                        </p>
                        <p className="fontSizeClass">
                            {t("In most cases preparing the letter to the Assistant Director is not a requirement. You may visit your local borough or ward office and contact the Conservancy officer who will visit your premises &amp; request you to make a payment per schedule of fees. Upon payment you will be handed a cash voucher. This is deemed as your permission for scaffolding etc. You may pay to the CO by cash or DD in favor of KMC made payable at Kolkata.")}
                        </p>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading3">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                            {t("What time of the day does the KMC truck come to clear garbage?")}</button>
                        </div>
                        <div id="collapse3" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            {t("KMC trucks come for garbage collection between 6:30AM and 9:30 AM.")}
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading4">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                            {t("What are key locations for solid waste department landfills?")}</button>
                        </div>
                        <div id="collapse4" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">
                            {t("Key locations for solid waste management are Dhapa and Garden Reach. For questions regarding Dhapa, please contact Executive Engineer (C1), Solid Waste Department, 48 Market Street, Kolkata 400 087, Phone – 22861000, Extension 2655. For questions regarding Garden Reach please contact Assistant Director, E/3 Circular Garden Reach Road, Kolkata 700 024, Phone – 2469 6049")}
                        </p>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading5">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                            {t("Who do I contact if I see carcass not being cleared in a certain location?")}</button>
                        </div>
                        <div id="collapse5" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">{t("Please contact the Assistant Director of the borough that serves your ward. Please refer to the table below for contact details of borough offices")}. </p>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading12">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse12" aria-expanded="true" aria-controls="collapse12">
                            {t("Many a times the garbage trucks are not securely covered. In such cases garbage spills over onto the road and/or on passing vehicles and pedestrians. What does KMC do to prevent such a nauseating experience?")}</button>
                        </div>
                        <div id="collapse12" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass"> {t("KMC makes every effort that the garbage trucks are securely covered so that there is minimum inconvenience to Kolkatans. The solid waste management department ensures that covers of garbage carriers are replaced every 3 months. In case you notice a garbage spill over from trucks please contact the Deputy Chief Engineer, Solid Waste Management Department, 48 Market Street, Kolkata 400 087, Phone – 22861000, Extension 2649. Agency/contractors that do not maintain proper covers over garbage are fined Rs. 130 to Rs.160 if complaints received are found to be valid.")}
                      </p>
                      <p className="fontSizeClass">
                        <strong>{t("Address of Borough Offices")}</strong>
                      </p>
                      <div className="mayor-table2 depart">
                        <table className="table table-bordered">
                          <thead>
                            <tr className="table-warning table-bor-2">
                              <th className="bd-wid" scope="col">
                                
                                {t("Sl.No.")}
                              </th>
                              <th scope="col"> {t("Borough")} </th>
                              <th scope="col"> {t("Address")} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row"> 1 </td>
                              <td> I </td>
                              <td>
                                
                                {t("10, B.T. Road, Cossipore, Kolkata-700002")}
                              </td>
                            </tr>
                            <tr>
                              <td scope="row"> 2 </td>
                              <td> II </td>
                              <td>
                                
                                {t("79, Bidhan Sarani, Hatibagan, Kolkata-700006")}
                              </td>
                            </tr>
                            <tr>
                              <td scope="row"> 3 </td>
                              <td> III </td>
                              <td>
                                
                                {t("109, Narkeldanga Road, Fulbagan, Kolkata-700011")}
                              </td>
                            </tr>
                            <tr>
                              <td scope="row"> 4 </td>
                              <td> IV </td>
                              <td>
                                
                                {t("5, Azad Hind Bag, Hedua Park, Kolkata-700006")}
                              </td>
                            </tr>
                            <tr>
                              <td scope="row"> 5 </td>
                              <td> V </td>
                              <td>
                                
                                {t("22, Surya Sen Street, College Sqare, Kolkata-700012")}
                              </td>
                            </tr>
                            <tr>
                              <td scope="row"> 6 </td>
                              <td> VI </td>
                              <td> {t("1, Hogg Street, Kolkata-700087")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 7 </td>
                              <td> VII </td>
                              <td> {t("9/1, A.J.C. Bose Road, Kolkata-700017")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 8 </td>
                              <td> VIII </td>
                              <td> {t("Vivekananda Park, Kolkata-700029")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 9 </td>
                              <td> IX </td>
                              <td>
                                
                                {t("11, Belvedere Road, Alipore, Kolkata-700027")}
                              </td>
                            </tr>
                            <tr>
                              <td scope="row"> 10 </td>
                              <td> X </td>
                              <td> {t("28, Anoar Shah Road, Kolkata-700033")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 11 </td>
                              <td> XI </td>
                              <td> {t("Garia, Atabagan")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 12 </td>
                              <td> XII </td>
                              <td>{t(" 97, Garfa main Road, Kolkata-700075")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 13 </td>
                              <td> XIII </td>
                              <td> {t("516, D.H. Road, Br. Office, Behala")} </td>
                            </tr>
                            <tr>
                              <td scope="row"> 14 </td>
                              <td> XIV </td>
                              <td> {t("516, D.H. Road, Br. Office, Behala")}</td>
                            </tr>
                            <tr>
                              <td scope="row"> 15 </td>
                              <td> XV </td>
                              <td> {t("E/3, Garden Reach Br. Office")} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="col-lg-6">
                <div id="accordion">
                    <div className="card">
                        <div className="card-header" id="heading6">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                            {t("How does the solid waste department help during periods of natural calamities?")}</button>
                        </div>
                        <div id="collapse6" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                                    <p className="fontSizeClass">{t("The solid waste department makes every effort to be in the forefront of disaster management and recovery in case of natural calamities. The department ensures that it clears debris, clears carcasses, cleans roads and aids citizens with rescue operations.")}</p>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header" id="heading7">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                            {t("Who do I contact if the garbage collectors of the department misbehave or do not perform their duties well?")}</button>
                        </div>
                        <div id="collapse7" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                                    <p className="fontSizeClass">{t("The solid waste department is very keen that garbage collectors perform their duties to the satisfaction of citizens of Kolkata. Additionally, it is very important that garbage collectors behave well and help Kolkatans keep clean streets. If you are not happy with KMC’s garbage collectors please contact, <br /> Assistant Director, Solid Waste Management Department, 48 Market Street, Kolkata 400 087, Phone – 22861000, Extension 2655")}.</p>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading8">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
                            {t("How are high-speed roadways maintained by KMC?")}</button>
                        </div>
                        <div id="collapse8" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                                <p className="fontSizeClass">{t("KMC takes special care of it’s high speed road ways such as EM bypass, P.C. Connector and R.B. Connector. Mechanical sweepers clean these roadways on a daily basis")}.</p>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading9">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
                            {t("Who do I contact if I have storage material such as vegetable oil etc. that has expired?")}</button>
                        </div>
                        <div id="collapse9" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                                <p className="fontSizeClass">{t("If you have material that is not hazardous it can be disposed at KMC landfills. You can either use your own vehicle for the transportation of the same or can avail of KMC vehicles. Please take the following steps to dispose expired material, <br /> a. Write a letter addressed to Deputy Chief Engineer, Solid Waste Management Department (48 Market Street, Kolkata 400 087) detailing the nature of the waste and the weight of the same.")}
                        <br />
                        {t("b. Based on the schedule of charges in Annex B, you will need to make a payment at the Treasury department of the KMC headquarters.")}
                        <br />
                        {t("c. Upon showing the receipt of payment to Deputy Chief Engineer will provide you with a performa that authorizes garbage collection and disposal at one of the KMC landfills.")}
                        <br />
                        {t("It is recommended that you use your own vehicle for disposal. If you use your vehicle for disposal, you will be given a period of 7 days from the date of grant of permission to dispose off the material. If you request a KMC truck, you will be given garbage pick up date depending on availability of vehicles.")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading10">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse10" aria-expanded="true" aria-controls="collapse10">
                            {t("Who do I contact if I see miscreants create excess garbage at certain locations?")}</button>
                        </div>
                        <div id="collapse10" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">{t("A We strongly encourage responsible citizens to inform us in case they notice miscreants creating excess amounts of garbage. Your name and particulars will kept absolutely confidential. You may inform concerned Assistant Director at the borough offices as per addresses inthe table below.You may also drop in a letter or email directly to the Municipal Commissioner of Kolkata (email – mc@kmcgov.in).")} </p>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading11">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                            {t("What locations are vehicles parked once the solid waste department seizes them?")}</button>
                        </div>
                        <div id="collapse11" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3">
                            <p className="fontSizeClass">{t("Vehicles are parked within designated KMC designated parking garages.")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default Solid_waste_management_faqs