import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Building_Sanction_Ground_Covergae  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
      
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2 mb-4">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Building_Home'> {t("Building Home")}</Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Dashboard2' target="_blank"> {t("EODB Dashboard For Building Permit")} </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2022_23'> {t("Circular")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('319')}> {t("Building Demand Notice")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Floor_Area_Ratio'> {t("Floor Area Ratio")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Building_Sanction_Ground_Covergae'> {t("Ground Coverage")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Borough_Offices'> {t("Borough Offices")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('320')}> {t("Fees and Charges")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalBuildingLBSDetails.jsp' target="_blank"> {t("Find LBS")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalESESearch.jsp' target="_blank"> {t("Find ESE")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalBuildingGTDetails.jsp' target="_blank"> {t("Find GTE")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalBuildingESRDetails.jsp' target="_blank"> {t("Find ESR")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/HearingNotice.jsp' target="_blank"> {t("Check Hearing Notice")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Download_Forms'> {t("Download Forms")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/PlanProposalStatusSearch.jsp' target="_blank"> {t("Building Plan Proposal Status")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCViewBuildingDemand.jsp' target="_blank"> {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingDemandPrint.jsp' target="_blank"> {t("Print Unpaid Bill")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingPayment.jsp' target="_blank"> {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingReceiptReprint.jsp' target="_blank"> {t("Reprint e-Receipt")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('321')}> {t("Instruction Set for Online Submission of Building Pla")}n </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/Building/gotologin.do' target="_blank"> {t("Online Submission of Building Plan")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('322')}> {t("Online CC Circular")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Important_EODB_circulars'> {t("Important EODB Circulars")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('323')}> {t("Instruction set for Online Notice of Commencement and Notice of Completion Certificate")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp' target="_blank"> {t("Online Notice of Commencement/CC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('324')}> {t("MC Circular No.44 of 2021-22 Regarding Assessment Data at the time of CC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href="assets/img/Final_Format_Flat_Assessment.xls" download="Final_Format_Flat_Assessment.xls"><i className="bi bi-download"></i> {t("Download Standard(.xls) format for MC Circular No.44 of 2021-22")} </a>                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://docs.google.com/forms/d/e/1FAIpQLSfmcCELn86TXflHLGQo4ZbhOiGhbN93TTmulOf15G7O5rVIpw/closedform' target="_blank"> {t("Architect Enlistment and Renewal")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp' target="_blank"> {t("License Renewal for Technical Personnel")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('325')}> {t("Instruction set for Online Licence Renewal For Technical Personnel")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('326')}> {t("Instruction Set for preparation of AutoCAD Drawing for Online Submission")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingPlanCaseSearch.jsp' target="_blank"> {t("Sanctioned Plan Search")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingCompletionCertificateSearch.jsp' target="_blank"> {t("Completion Certificate Search")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingUnauthorisedCaseSearch.jsp' target="_blank"> {t("Unauthorised Case Search")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Faqs'> {t("FAQs")}</Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Building_Sanction_Circular_2021_22' > {t("Office Order")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Building_Sanction_Communique'> {t("Communique")}  </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('325')}> {t("DGBuilding Circular No 9(2013-2014)")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('327')}> {t("Empanelment of Geo-Technical")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('329')}> {t("West Bengal Act XIX of 2014 & The Kolkata Municipal Corporation(Amendment)Act,2014")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('328')}> {t("Check list for Sanction of Building Plan")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('330')}> {t("Building Rules 2009 including amendment")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://nocas2.aai.aero/nocas/' target="_blank"> {t("Airport Building Height Clearance")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('331')}> {t("Applications are invited from the Civil Engineers of Empanelment of Geo-Technical Engineers")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('332')}> {t("3rd Amendment of KMC Building Rules 2009 Geo Tech and Road width")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('333')}> {t("Communique(The Amendment of Building Rules,2009 video notification No 80/MA/O/C-4/3R-7/2017)")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('334')}> {t("Distribution of guideline among Building-owners/Constituted Attorney in different wards of KMC for prevention of mosquito breeding at construction sites on an urgent basis")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('335')}> {t("Notification No 445/MA/O/C-4/3R-3/2014")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Building_notice_28_05_2019.pdf' target="_blank"> {t("Building Notice")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/LicenseNoWiseSanctionSearch.jsp' target="_blank"> {t("LBS/CA/ESE/GTE/ESR WISE SANCTION SEARCH")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Notice_LBS_Architect_03_09_2019.rar' target="_blank"> {t("Notice for LBS/Architect")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('336')}> {t("EODB Proforma For Appointment of Technical Person")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('337')}> {t("EODB Standard Reasons Of Rejection")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('338')}> {t("EODB IGR Non-insisting of KMC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('339')}> {t("EODB Workflow")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('340')}> {t("FAQ for EODB")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('341')}> {t("User Manual for EODB")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('342')}> {t("Public Notice -- Pollutions at Construction Site")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('343')}> {t("Circulation of Administrative order for regulating sanction of building plan/construction")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href="assets/img/Enemy_Properties.xls" download="Enemy_Properties.xls"><i className="bi bi-download"></i> {t("List of Enemy Properties")} </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("Ground Coverage for Buildings")}</h3>
              </div>
              <p className="para_building">
                {t("Save as otherwise determined by the Mayor-in-Council, with regard to any specification made in this behalf under the West Bengal Town and Country (Planning and Development) Act, 1979, the Floor Area Ratios shall be as specified in Table 3 below")}:
              </p>
              <p className="fontSizeClass"></p>
              <div className="mayor-table">
                <table
                  width="95%"
                  border={0}
                  align="center"
                  className="table table-bordered officials"
                >
                  <tbody>
                    <tr bgcolor="#302ba0" align="center">
                      <td width="10%" rowSpan={2} className="text-white">
                        <div align="center">{t("Sl. No")}</div>
                      </td>
                      <td width="33%" rowSpan={2} className="text-white">
                        <div align="center">{t("Occupancy or user group")}</div>
                      </td>
                      <td colSpan={2} width="20%" className="text-white">
                        <div align="center">
                          {t("Max. Percentage ground coverage")}
                        </div>
                      </td>
                    </tr>
                    <tr bgcolor="#302ba0" align="center">
                      <td
                        width="20%"
                        align="center"
                        valign="middle"
                        className="text-white"
                      >
                        
                        {t("Plot size upto 200 sqm")}
                      </td>
                      <td width="20%" align="center" className="text-white">
                        
                        {t("Plot size 500 sqm or above")}
                      </td>
                    </tr>
                    <tr align="center">
                      <td width="3%">
                        <div align="center">1.</div>
                      </td>
                      <td>
                        <div className="fontSizeClass"> {t("Residential")} </div>
                      </td>
                      <td>
                        
                        <div align="center"> 60% </div>
                      </td>
                      <td>
                        
                        <div className="fontSizeClass"> 50% </div>
                      </td>
                    </tr>
                    <tr align="center">
                      <td width="3%" align="center">
                        <div align="center">2.</div>
                      </td>
                      <td>
                        
                        <div className="fontSizeClass"> {t("Educational")} </div>
                      </td>
                      <td>
                        <div className="fontSizeClass"> 50% </div>
                      </td>
                      <td>
                        
                        <div align="center"> 45% </div>
                      </td>
                    </tr>
                    <tr align="center">
                      <td width="3%" align="center">
                        <div align="center">3.</div>
                      </td>
                      <td>
                        <div className="fontSizeClass"> {t("Institutional")}</div>
                      </td>
                      <td>
                        <div className="fontSizeClass"> 40% </div>
                      </td>
                      <td>
                        
                        <div align="center"> 40% </div>
                      </td>
                    </tr>
                    <tr align="center">
                      <td>
                        <div align="center">4.</div>
                      </td>
                      <td>
                        <div className="fontSizeClass">{t("Assembly")}</div>
                      </td>
                      <td>
                        <div align="center"> 40%</div>
                      </td>
                      <td>
                        <div align="center">40%</div>
                      </td>
                    </tr>
                    <tr align="center">
                      <td align="center">
                        <div align="center">5.</div>
                      </td>
                      <td>
                        <div className="fontSizeClass"> {t("Mercantile (retail)")}</div>
                      </td>
                      <td>
                        <div className="fontSizeClass"> 40% </div>
                      </td>
                      <td>
                        
                        <div className="fontSizeClass">40% </div>
                      </td>
                    </tr>
                    <tr align="center">
                      <td align="center">
                        <div align="center">7.</div>
                      </td>
                      <td>
                        
                        <div className="fontSizeClass">{t("Industrial")}</div>
                      </td>
                      <td>
                        
                        <div className="fontSizeClass">40%</div>
                      </td>
                      <td>
                        <div className="fontSizeClass"> 40%</div>
                      </td>
                    </tr>
                    <tr align="center">
                      <td align="center">
                        <div align="center">8.</div>
                      </td>
                      <td>
                        
                        <div className="fontSizeClass">{t("Storage")} </div>
                      </td>
                      <td>
                        
                        <div className="fontSizeClass">40% </div>
                      </td>
                      <td>
                        <div className="fontSizeClass"> 40%</div>
                      </td>
                    </tr>
                    <tr align="center">
                      <td align="center">
                        <div align="center">10.</div>
                      </td>
                      <td>
                        <div className="fontSizeClass"> {t("Business")}</div>
                      </td>
                      <td>
                        
                        <div className="fontSizeClass">40% </div>
                      </td>
                      <td>
                        <div className="fontSizeClass"> 40% </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className="para_building">
                {t("For any other size of the plot in between plot size of 200 sq. m. and 500 sq. m., the percentage coverage shall be calculated by direct interpolation.")}
              </p>
              <p className="para_building">
                {t("Notwithstanding anything contained in sub-rule (1), for plots measuring more than 5000 sq. m. in area, the maximum permissible ground coverage shall be 45% for residential building/ buildings and 35% for building/ buildings with other occupancies including mixed occupancy.")}
              </p>
              <p className="para_building">
                {t("For buildings on plots measuring 5000 sq. m, additional ground coverage to the extent of 15% may be allowed for car parking and building services. The additional ground coverage of 15% will be exclusively utilized for car parking, ramps, staircase, lift for upper level car parking and building services such as A.C. plant room, generator room, fire fighting equipments, electrical equipments etc. not exceeding 5% out of such 15% subject to compliance of other relevant rules.")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Building_Sanction_Ground_Covergae