import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";

const Water_For_Fire_Fighter = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> Water For Fire Fighters </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
 <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link  fontSizeClass" to='/Water_Supply'> Water Supply Home</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/PlumberDetails.jsp'>Find Plumbers</a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass " to='/Download_Forms'>Download Forms </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass " to='/Water_Supply_Fee_Charges'>Fees and Charges</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WaterFee.jsp'> Check Demands Payable</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WaterSupplyDemandPrint.jsp'>Print Unpaid Bill</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/KMCPortalWaterSupplyPaymentAction.do'> Make Online Payment</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WSDuplicateReceipt.jsp'> Reprint e-Receipt</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WSPaymentStatus.jsp'>Check Demand Status </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass " to='/Water_Supply_Faqs'> FAQs </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Water_Supply_Dept'>Contact </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Water_For_Fire_Fighter'> Water For Fire Fighters</Link>
                </li>
                <li className="nav-item"><a className="fontSizeClass " onClick={(e)=>fileDownload('246')}> Action Plan to reduce water losses to less than 20% </a> </li>  
                
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass"> Water For Fire Fighters </h3>
              </div>
              <div className="col-lg-12 about-head1">
                <p className="fontSizeClass">
                  
                  A List of locations of water supply pumping stations is given
                  below where fire fighters may access to get water through
                  spout
                </p>
              </div>
              <div className="mayor-table min-height-400">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-warning table-bor-2">
                      <th width="10" className="bd-wid" scope="col"> Sl.</th>
                      <th width="35" scope="col"> Water Supply installations </th>
                      <th width="35" scope="col"> Location </th>
                      <th width="20" className="table-bor-3" scope="col">
                        View Map
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row"> (Filter water) </td>
                      <td> &nbsp; </td>
                      <td> &nbsp; </td>
                      <td> &nbsp; </td>
                    </tr>
                    <tr>
                      <td scope="row"> 1 </td>
                      <td> Tallah Pumping Station </td>
                      <td> The low level road of Tallah bridge </td>
                      <td>
                        <a href="https://www.kmcgov.in/KMCPortal/outside_images/Tallah_Pumping_Station.jpg" target="_blank">Click Here </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 2. </td>
                      <td> Jorabagan Pumping Station </td>
                      <td>
                        
                        Inside the pumping station beside Baishnab Sett Street
                      </td>
                      <td>
                        <a href="https://www.kmcgov.in/KMCPortal/outside_images/Jorabagan_Pumping_Station.jpg">Click Here </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 3. </td>
                      <td> Raja Subodh Mullick Sq. BPS </td>
                      <td>
                        
                        At the junction of Creek Row &amp; Raja Subodh Mullick
                        Sq.
                      </td>
                      <td>
                        <a href="https://www.kmcgov.in/KMCPortal/outside_images/Raja_Subodh_Mullick_Sq_%20BPS.jpg">
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 4. </td>
                      <td>Auckland Sq. BPS </td>
                      <td>
                        
                        At the junction of A.J.C. Bose Road &amp; Auckland
                        Place, near 'Mouchak'.
                      </td>
                      <td>
                        <a href="https://www.kmcgov.in/KMCPortal/outside_images/Auckland_Sq_BPS.jpg">
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 5 </td>
                      <td> Park Circus BPS </td>
                      <td>
                        
                        At the junction of Orient Row &amp; New Park Street.
                      </td>
                      <td>
                        <a href="https://www.kmcgov.in/KMCPortal/outside_images/Park_Circus_BPS.jpg">
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 6 </td>
                      <td> Kalighat BPS </td>
                      <td> Near Kalighat Tram Depot </td>
                      <td>
                        <a href="https://www.kmcgov.in/KMCPortal/outside_images/Kalighat_BPS.jpg">
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 7 </td>
                      <td> Kasba BPS </td>
                      <td>
                        
                        At the junction of Rashbehari Connector &amp; Bose Pukur
                        Road
                      </td>
                      <td>
                        <a href="https://www.kmcgov.in/KMCPortal/outside_images/Kasba_BPS.jpg">
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 8 </td>
                      <td>Ranikuthi BPS </td>
                      <td> Near Ranikuthi Telephone Exchange</td>
                      <td>
                        <a href="https://www.kmcgov.in/KMCPortal/outside_images/Ranikuthi_BPS.jpg">
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 9 </td>
                      <td> Behala BPS </td>
                      <td>
                        At the junction of Behala Chowrasta on Ho-Chi-Minh
                        Sarani
                      </td>
                      <td>
                        <a href="https://www.kmcgov.in/KMCPortal/outside_images/Behala_BPS.jpg">
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 10 </td>
                      <td> Garden Reach Water Works </td>
                      <td> Within the GRWW campus near Nature Park</td>
                      <td>
                        <a href="https://www.kmcgov.in/KMCPortal/outside_images/Garden_Reach_Water_Works.jpg">
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> (Unfilter water) </td>
                      <td> &nbsp; </td>
                      <td> &nbsp; </td>
                      <td> &nbsp; </td>
                    </tr>
                    <tr>
                      <td scope="row"> 11 </td>
                      <td> Mullick Ghat Pumping Station </td>
                      <td> Adjacent to Brabourne Road fly-over </td>
                      <td>
                        <a href="https://www.kmcgov.in/KMCPortal/outside_images/Mullick_Ghat_Pumping_Station.jpg">
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 12 </td>
                      <td> Prince Anwar Shah Road </td>
                      <td> Near South City </td>
                      <td>
                        <a href="https://www.kmcgov.in/KMCPortal/outside_images/Prince_Anwar_Shah_Road.jpg">
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 13 </td>
                      <td>
                        
                        Big Dia Meter Tubewell Spout Arupota, Dhapa, South
                        Sealdh
                      </td>
                      <td> South Part of EM Bypass (ward 57,58) </td>
                      <td>
                        <a href="https://www.kmcgov.in/KMCPortal/downloads/Spout_WD_57_58_Final.pdf">
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 14 </td>
                      <td>
                        Big Dia Meter Tubewell Spout CREMATORIUM STREET,SHARIF
                        LANE CROSSING
                      </td>
                      <td> Park Circus Tram Depo.(ward 61,62) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('845')}>
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 15 </td>
                      <td>
                        Big Dia Meter Tubewell Spout TILJALA, TOPSIA, G J KHAN
                        RD, CHOWBHAGA, KUSTIA
                      </td>
                      <td>
                        7 point Park Circus-EM By Pass Connector (Ward 66)
                      </td>
                      <td>
                        <a onClick={(e)=>fileDownload('846')}>
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 16 </td>
                      <td>
                        
                        Big Dia Meter Tubewell Spout PICNIC Garden, KASHBA BPS
                      </td>
                      <td> Rash Behari Bypass Connector(ward 67) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('847')}>
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 17 </td>
                      <td>Big Dia Meter Tubewell Spout EKDALIA, KAKULIA </td>
                      <td>Gariahat Area(ward : 68) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('848')}>
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 18 </td>
                      <td>
                        Big Dia Meter Tubewell Spout St. Lawrence High School
                      </td>
                      <td> Ballygunge Phari area(Ward 69) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('849')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 19 </td>
                      <td>
                        
                        Big Dia Meter Tubewell Spout Harish Chatterjee St.,
                        Kalighat
                      </td>
                      <td> Bhowbanipur Area(Ward 73) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('850')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 20 </td>
                      <td>
                        
                        Big Dia Meter Tubewell Spout DURGAPUR LANE, BELVEDERE
                        ROAD
                      </td>
                      <td>Alipore Area (Ward 74) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('851')}>
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 21 </td>
                      <td>Big Dia Meter Tubewell Spout EKBALPORE </td>
                      <td> EKBALPORE area(Ward 78) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('852')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 22 </td>
                      <td>
                        
                        Big Dia Meter Tubewell Spout JHALDAR MATH, CHETLA ,
                        KALIGHAT
                      </td>
                      <td> Chtla, Kalighat area.(Ward 81,82,83,88) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('853')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 23 </td>
                      <td>
                        
                        Big Dia Meter Tubewell Spout Charu Avenue, Swiss Park
                      </td>
                      <td> Charu Avenue, Swiss Park area(Ward : 89) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('854')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 24 </td>
                      <td>
                        
                        Big Dia Meter Tubewell Spout Kolupara, Bose Pukur
                      </td>
                      <td> Rash Behari Bypass Connector(ward 91) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('855')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 25 </td>
                      <td>
                        
                        Big Dia Meter Tubewell Spout SAHID NAGAR, New Land,
                        Nashkar Para
                      </td>
                      <td> Dhakuria Area(Ward 92) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('856')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 26 </td>
                      <td>
                        
                        Big Dia Meter Tubewell Spout Katjunagar, Lake Gardens
                      </td>
                      <td> Jodhpur Park Area(Ward 93) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('857')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 27 </td>
                      <td> Tubewell Fire Spout </td>
                      <td> NEW MARKET (Ward 46) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('858')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 28 </td>
                      <td> Tubewell Fire Spout </td>
                      <td> CHAPLIN SQUARE (Ward 46) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('858')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 29 </td>
                      <td> Tubewell Fire Spout </td>
                      <td> NANDARAM MARKET(Ward 45) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('859')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 30 </td>
                      <td> Tubewell Fire Spout </td>
                      <td> PARTUGUESE CHURCH STREET (Ward 45) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('860')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 31 </td>
                      <td> Tubewell Fire Spout</td>
                      <td> 18-RABINDRA SARANI(Ward 43) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('861')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 32 </td>
                      <td> Tubewell Fire Spout </td>
                      <td> TARACHAND DUTTA STREET(Ward 44) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('862')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 33 </td>
                      <td> Tubewell Fire Spout </td>
                      <td> ORPHANGUNGE MARKET(Ward 77) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('863')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 34 </td>
                      <td> Tubewell Fire Spout </td>
                      <td> RAJABAZAR CROSSING(Ward 36) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('864')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 35 </td>
                      <td> Tubewell Fire Spout </td>
                      <td> HATIBAGAN(Ward 11) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('865')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> 36 </td>
                      <td> Tubewell Fire Spout </td>
                      <td> AKHIL MISTRY LANE(Ward 49) </td>
                      <td>
                        <a onClick={(e)=>fileDownload('866')}>
                          
                          Click Here
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>
  )
}

export default Water_For_Fire_Fighter