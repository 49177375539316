import React from 'react'
import { Link } from "react-router-dom";
import axios from "axios";

const KMCFunctions = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const fileDownload = (fileStoreId) => {
    let url = `https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`;
    axios
      .post(url, { fileId: fileStoreId, userId: "392" })
      .then((res) => {
        console.log("response is --- ", res);

        if (res && res.data && res.status === 200) {
          const base64 = res.data[0] && res.data[0].base64_data;
          const pdfData = "data:application/pdf;base64," + base64;

          let html =
            "<html>" +
            "<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>" +
            "<body>" +
            '<iframe src="' +
            pdfData +
            '"></iframe>' +
            "</body></html>";
          console.log(html);
          let a = window.open();
          a.document.write(html);
        }
      })
      .catch((e) => {
        // setLoader(false);
        console.log("The error is ", e);
      });
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const [FinancialServices, setFinancialServices] = React.useState(false);
const [FinancialOneServices, setFinancialOneServices] = React.useState(false);
const [FinancialTwoServices, setFinancialTwoServices] = React.useState(false);
const [FinancialThreeServices, setFinancialThreeServices] = React.useState(false);
const [FinancialFourServices, setFinancialFourServices] = React.useState(false);
const [FinancialFiveServices, setFinancialFiveServices] = React.useState(false);
const [FinancialSixServices, setFinancialSixServices] = React.useState(false);
const [FinancialSevenServices, setFinancialSevenServices] = React.useState(false);

const FinancialServicesToggle = () => {
  setFinancialServices(!FinancialServices);
};
const FinancialOneServicesToggle = () => {
  setFinancialOneServices(!FinancialOneServices);
};
const FinancialTwoServicesToggle = () => {
  setFinancialTwoServices(!FinancialTwoServices);
};
const FinancialThreeServicesToggle = () => {
  setFinancialThreeServices(!FinancialThreeServices);
};
const FinancialFourServicesToggle = () => {
  setFinancialFourServices(!FinancialFourServices);
};
const FinancialFiveServicesToggle = () => {
  setFinancialFiveServices(!FinancialFiveServices);
};
const FinancialSixServicesToggle = () => {
  setFinancialSixServices(!FinancialSixServices);
};
const FinancialSevenServicesToggle = () => {
  setFinancialSevenServices(!FinancialSevenServices);
};
  return (
    <>
      {/* Slider */}
      <section id="hero" className="hero hero2">
        <div
          className="social-media wow slideInDown"
          style={{ visibility: "visible", animationName: "slideInDown" }}
        >
          <div className="facebook" rel="ebInfo" title="facebook">
            <a
              href="https://www.facebook.com/KolkataMunicipalCorporation.official"
              target="_blank"
            >
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a
              href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp"
              target="_blank"
            >
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>

        <div className="carousel-item active">
          <img
            src="assets/img/inner-bg2.png"
            className="img-fluid"
            alt="image"
          />
          <div className="container">
            <div className="section-main-heading2">
              <h4 className="fontSizeClass"> About Us - KMC Functions </h4>
            </div>
          </div>
        </div>
      </section>
      {/* End Slider */}

      <section className="section inner-pad1">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="site-menu  min-height-400">
                <nav className="sidebar card py-2">
                  <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item">
                      <Link
                        className="nav-link active1 fontSizeClass"
                        to="/AboutUs"
                      >
                        Vision
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Overview">
                        Overview
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1849")}
                      >
                        KMC Structure
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/KMCFunctions"
                      >
                        KMC Functions
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/KMC_Department"
                      >
                        KMC Department
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/EKolkataCenter"
                      >
                        e-Kolkata Centers
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Basic_statestics"
                      >
                        Basic Statistics
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Emblem">
                        Emblem
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/KMCBudget">
                        KMC Budget
                      </Link>
                    </li>
                    <li className="nav-item-list nav_dropdown">
                      <button
                        type="button"
                        className="btn"
                        data-toggle="collapse"
                        data-target="#demo"
                      >
                        KMC Financial Statements{" "}
                        <i className="bi bi-chevron-down" />
                      </button>
                      <div id="demo" className="collapse">
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo1"
                          >
                            2012- 2013
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo1" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1850")}>
                                Receipt and Payment Statement 2012-13
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1851")}>
                                Income & Expenditure 2012-13
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1852")}>
                                Balance Sheet 2012-13
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo2"
                          >
                            2013- 2014
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo2" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1853")}>
                                Annual Financial Statement 2013-14
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1854")}>
                                Audit Certificate for ELA 2013-14
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo3"
                          >
                            2014- 2015
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo3" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1855")}>
                                Receipt and Payment Statement 2014-15
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1858")}>
                                Income & Expenditure 2014-15
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1857")}>
                                Balance Sheet 2014-15
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1856")}>
                                Audit Report 2014-15
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo4"
                          >
                            2015- 2016
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo4" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1859")}>
                                Receipt and Payment Statement 2015-16
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1860")}>
                                Income & Expenditure 2015-16
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1861")}>
                                Balance Sheet 2015-16
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1862")}>
                                Audit Report 2015-16
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo5"
                          >
                            2016- 2017
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo5" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1863")}>
                                Receipt and Payment Statement 2016-17
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1864")}>
                                Income & Expenditure, 2016-17
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1865")}>
                                Balance Sheet 2016-17
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1866")}>
                                Audit Report 2016-12
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo6"
                          >
                            2017-2018
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo6" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1867")}>
                                Receipt and Payment Statement, 2017-18
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1868")}>
                                Income & Expenditure, 2017-18
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a> Balance Sheet 2017-18 </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1869")}>
                                Letter of MC to Examiner of Local Accounts, WB
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1870")}>
                                Audit Report, 2017-18
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo7"
                          >
                            2018-2019
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo7" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1871")}>
                                Receipt and Payment Statement 2018-19
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1872")}>
                                Income & Expenditure 2018-19
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1873")}>
                                Balance Sheet 2018-19
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1874")}>
                                Audit Report 2018-19
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo7"
                          >
                            2019-2020
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo8" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1875")}>
                                Receipt and Payment Statement 2019-20
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1876")}>
                                Income & Expenditure 2019-20
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1877")}>
                                Balance Sheet 2019-20
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1878")}>
                                Audit Report 2019-20
                              </a>
                            </li>
                          </div>
                        </li>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>{" "}
            <div className="col-lg-9">
              <div className="container">
                <div className="row">
                  <div className="inner-title">
                    <h3 className="fontSizeClass"> KMC Functions </h3>
                  </div>
                  <div className="about-head1">
                    <h4 className="fontSizeClass">
                      According to Section 29 of the KMC Act, KMC has the
                      following Obligatory Functions:
                    </h4>
                  </div>
                  <div className="col-lg-12 about-head1">
                    <ul className="kmc-function-list">
                      <li> Supply of drinking water in the KMC area. </li>
                      <li>
                        Cleaning and removal of filth, rubbish and other
                        obnoxious matters.
                      </li>
                      <li> Laying and maintaining Public Streets. </li>
                      <li> Provision of public lighting.</li>
                      <li> Sewerage and drainage provision in KMC area. </li>
                      <li> Naming and numbering of streets and premises.</li>
                      <li> Planting of trees on roadsides and elsewhere. </li>
                      <li>
                        The laying out or maintenance of public parks, gardens
                        or recreation grounds.
                      </li>
                      <li> Health and welfare functions.</li>
                      <li> Regulation of trade through licensing.</li>
                      <li> Regulation of trade through licensing.</li>
                      <li>
                        {" "}
                        Provision of municipal markets and slaughter-houses.
                      </li>
                      <li> Maintenance of heritage monuments.</li>
                      <li> Record keeping and statistics.</li>
                      <li>
                        Providing by itself or by an agency, means of supply of
                        water for fire-fighting.
                      </li>
                    </ul>
                    <h4 className="fontSizeClass">
                      According to Section 30 of the KMC Act, KMC has the
                      following Discretionary Functions:
                    </h4>
                    <ul style={{ display: "none" }}>
                      <li> Furtherance of education. </li>
                      <li>
                        Establishment and maintenance of libraries, museums,
                        zoological collections.
                      </li>
                      <li> Survey of building and lands. </li>
                      <li>
                        Providing music and entertainment in public places or
                        public resorts.
                      </li>
                      <li>
                        Organization and management of fairs and exhibitions.
                      </li>
                      <li>
                        Acquisition of movable and immovable property for
                        purpose mentioned before.
                      </li>
                      <li>
                        Construction of rest house, poor house, infirmaries,
                        children homes, house of deaf and dumb and disabled
                        persons.
                      </li>
                      <li> Construction and maintenance of cattle ponds. </li>
                      <li>
                        Purchase, construction and maintenance of house for
                        employees.
                      </li>
                      <li> Welfare of employees. </li>
                      <li>
                        Establishment and maintenance of hospitals and
                        dispensaries.
                      </li>
                      <li>
                        Provision of swimming pools, bathing place and other
                        institutions for improvement of community health.
                      </li>
                      <li>
                        Construction/maintenance of warehouse and garages sheds
                        and cattle biers.
                      </li>
                      <li>
                        {" "}
                        Provision of housing accommodation for inhabitants.{" "}
                      </li>
                      <li>
                        Any measures to promote public safety, health
                        sanitation.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default KMCFunctions