import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WaterStations = () => {
    const increaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
          var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
          var fontSize = parseFloat(style); 
          el[i].style.fontSize = (fontSize + 1) + 'px';
        }
      };
      const decreaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
        var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
        var fontSize = parseFloat(style); 
        el[i].style.fontSize = (fontSize - 1) + 'px'; 
        }
      };
      const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/Banner/portal-banner6.jpg" className="img-fluid" alt="image" />
    </div>
  </section>
  {/* End Slider */}
   <section className="section inner-pad1">
    <div className="container " >
              <div className="p-2">
                <h3 className="fontSizeClass"> {t("Water Station")} </h3>
              </div>
                <div id="accordion">
                    <div className="card">
                        <div className="card-header" id="heading5">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                            {t("Existing Water Treatment Plant")} :</button>
                        </div>
                        <div id="collapse5" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            <p className="fontSizeClass">1. {t("Indira Gandhi Water Treatment Plant - Palta")}</p>
                            <p className="fontSizeClass">2. {t("Garden Reach Treatment Plant")}</p>
                            <p className="fontSizeClass">3. {t("Jai Hind Jal Prakalpa - Dhapa")}</p>
                            <p className="fontSizeClass">4. {t("Jorabagan Water Treatment Plant")}</p>
                            <p className="fontSizeClass">5. {t("Watgunge Water Treatment Plant")}</p>
                        </div>
                        </div>
                    </div> 
                    <div className="card">
                        <div className="card-header" id="heading2">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                            {t("Water Treatment Plant (Under Construction)")} :</button>
                        </div>
                        <div id="collapse2" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            <p className="fontSizeClass">1. {t("Garden Reach Water Works - 25 MGD Capacity")} .</p>
                            <p className="fontSizeClass">2. {t("Indira Gandhi Water Treatment Plant - Palta - 20 MGD")}</p>
                        </div>
                        </div>
                    </div>  
                    <div className="card">
                        <div className="card-header" id="heading3">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                            {t("Existing Booster Pumping Station with Reservoirs")} :</button>
                        </div>
                        <div id="collapse3" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            <p className="fontSizeClass">{t("Tallah pumping station,Raja Subodh Mullick Square, Auckland Square, Behala, Md. Ali Park, Garfa, Park Circus, Ranikuthi, Kalighat, Bansdroni, Kasba, Bagmari, New Park, Daspara, Siriti, Anandapur, Gandhi Maidan, Telipara, Baishnabghata Patuli, Chetla, G J Khan Road, Khejurtala, Shanti Pally, Hatisur, Phool Bagan, Narkel Bagan, Lalgata, Mukundapur,Harish Park,Convent Park,Prafulla Park,Chaulpatty Road,Sen Pally,Parnashree Vivekananda Kanan,Layalka,Behala Chourastha,Bansdroni UGR Extension,Cocola Bagan,Agrashi Math,Hindustan Park,Nagendra Uddan,Airport Road,Poddar Nagar,Jodhpur Park Head Work,Golf Green Head Work,Ram Thakur Asram,Oposite Navina Badamtala,Near Burial Ground,Chandni Ghosh Road,Naktala,Netaji Nagar,Layelka Head Work,86 Pally Math,Rajani Mukherjee Rd,Parnasree Quarter")}.</p>
                        </div>
                        </div>
                    </div>  
                    <div className="card">
                        <div className="card-header" id="heading4">
                            <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                            {t("Booster Pumping Station (Under Construction)")} :</button>
                        </div>
                        <div id="collapse4" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            <p className="fontSizeClass"> {t("Anandapur Extension,Dhapa Water Work,C.N.Roy Road,Hatgachia Panchannagram,Panchasayar,Kayasthyapara, Achena Park,C.R.Colony,Subhash Pally,Garden Reach Water Work,Shakuntala Park,Bangur Park,Babu Bagan,Sachin Sengupta Park,Taramani Ghat,Deshpryia Park,Townshed(Golmath),4 No Bandh ,Park Near AMRI,Swamiji Sarak,Green Park,Jaysree Park,G.S.Bose Road")}.</p>
                        </div>
                        </div>
                    </div>
                </div>
    </div>
  </section>


  
  </>
  )
}

export default WaterStations