import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Birth_Death_Ragistration = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
     <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  

  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">           
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Birth_Death_Ragistration'> {t("Birth/Death Home")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Birth_Ragistration_center'> {t("Birth Registration Centres")} </Link> </li>  
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Death_Ragistration_center'> {t("Death Registration Centres")} </Link> </li>  
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBirthRecordSearch.jsp'> {t("Find Birth Records")} </a> </li>
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCDeathRecordSearch.jsp'> {t("Find Death Records")}</a> </li>  					
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title"><h3 className="fontSizeClass"> {t("Birth/Death Registration")} </h3></div>
                  <div className="container">
                    <div id="accordion">
                        <div className="card">
                          <div className="card-header" id="heading1">
                          <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                          I. {t("Important General Information")} 
                          </button>
                          </div>
                          <div id="collapse1" className="collapse show" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            <ol className="kmc-function-list">			 
                              <li>   {t("Birth or Death certificates can be obtained only if the event of birth or death is registered with the KMC")}. </li>
                              <li>   {t("Under the existing rules any event of the birth or death is to be compulsorily registered within 1 year of its occurrence")}. </li>
                              <li>   {t("Delayed registration beyond one year of occurrence of birth or death can only be done on the basis of orders of Executive / 1st Class Magistrate of the KMC area")}. </li>
                              <li>   {t("Births or deaths occurring in places within the area under KMC jurisdiction can only be registered by the KMC")}.</li>
                              <li>   {t("The entire KMC area is divided into 15 boroughs for administration and the office of the Executive Health Officer of each borough is the Registration Unit for all births occurring within the respective Borough area")}.</li>
                            </ol>  
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="heading2">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                            II. {t("Procedure To Obtain Birth Certificates")}                    </button>
                          </div>
                          <div id="collapse2" className="collapse" data-parent="#accordion">
                          <div className="card-body m-3">
                            <b>A. {t("For Birth events occurring in Private Hospitals / Nurshing Homes in Kolkata")}</b>
                            <ol className="kmc-function-list">
                              <li> {t("Birth certificates can be obtained from the concerned office of the Borough Ex. Health Officer usually after 6 weeks from the date of birth, subject to the condition that all birth events are reported in prescribed registration format by the private hospitals / nurshing homes within 21 days from the date of occurrence to concerned borough Ex. Health Officer as a mandatory requirement")}.</li>
                              <li>{t("Name of child can be inserted at the time of taking birth certificates by the parents or a relative authorised by the parents in writing")}.</li>
                            </ol>
                            <b> B. {t("For birth occurring in Govt. Hospitals in Kolkata")}<b>
                                <ol className="kmc-function-list">
                                  <li> {t("Birth certificates can be obtained from the concerned Borough Health Office upto a period of 1 (one) year from the date of birth on production of medical certificate of birth issued by the hospital")}.</li>
                                  <li>{t("Name of child can be inserted at the time of taking birth certificate by the parents or a relative authorised by the parents in writing")}.</li>
                                </ol>
                                <b> B. {t("For Domiciliary birth events")}:<b>
                                    <ol className="kmc-function-list">
                                      <li> {t("Domiciliary events of birth shall be reported by the Head of family to the concerned Ward Health Unit for registration within a month but not later than 1 year along with production of medical certificate of birth issued by a Medical Practitionar / Midwife / Traditional Birth Attendant. Birth certificate can be obtained on the spot from the Ward Health Unit")}.</li>
                                      <li>{t("Birth certificates in respect of domiciliary events not reported within one year can be obtained on delayed registration on the basis of orders of First Class Magistrate of KMC area from the concerned borough Ex. Health Officer [up to 20 years] and KMC Main Office [beyond 20 years]")}.</li>
                                    </ol>
                              </b></b></b></b>
                          </div>                      
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="heading3">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                            III. {t("Procedure To Obtain Death Certificates")}
                          </button>
                          </div>
                          <div id="collapse3" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            <ol className="kmc-function-list">			 
                                <li>  {t("In cases when dead bodies are brought to Crematorium or Burial Ground under KMC for disposal")} - <br />
                                  {t("Death events only in the KMC area are registered in the Crematorium or Burial Ground and death certificates are issued from there on the spot.4")}</li>
                                <li>  {t("In cases when dead bodies, death events of which have taken place in KMC area are disposed of in place outside KMC area")} = <br />
                                  {t("Death events are registered in the KMC Main Office, Health Department at 5, S.N. Banerjee Road, Kolkata - 700 013 and death certificate can be obtained from there on the spot on production of copy of certificate of death issued by institution or physician and original cremation certificate")}. </li>
                            </ol> 
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="heading4">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                            IV. {t("Non Availability Certificate")}  
                          </button>
                          </div>
                          <div id="collapse4" className="collapse" data-parent="#accordion">
                            <div className="card-body m-3 fontSizeClass">
                            {t("If an event of birth is found not registered, a Non-availability certificate can be obtained from KMC Main Office, Health Department at 5, S.N. Banerjee Road, Kolkata - 700 013 on production of supporting documents like Voter ID card, Educational Record, Ration Card etc")}.
                          </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="heading5">
                            <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                            V. {t("Charges Payable For Birth And Death Certificate")} 
                          </button>
                          </div>
                          <div id="collapse5" className="collapse" data-parent="#accordion">
                          <div className="card-body m-3">
                              <table width="100%" border={0} cellSpacing={1} cellPadding={1} className="table table-bordered cert-rates" tabIndex={0}>
                                  <tbody><tr align="left" valign="top" tabIndex={0}> 
                                      <td width="70%">1) {t("For first copy of Birth Certificate")} 
                                        {t("within a period of one Year from the date of birth")}</td>
                                      <td width="30%"><b>{t("Free of cost")}</b></td>
                                    </tr>
                                    <tr align="left" valign="top" tabIndex={0}> 
                                      <td width="70%">2) {t("Additional copies of birth certificates")}</td>
                                      <td width="30%"><b>{t("Rs. 100/- each copy")}</b></td>
                                    </tr>
                                    <tr align="left" valign="top" tabIndex={0}> 
                                      <td width="70%">3) {t("For first 2 copies of Death certificates issued from Crematorium or Burial Ground")}</td>
                                      <td width="30%"><b>{t("Free of cost")}</b></td>
                                    </tr>
                                    <tr align="left" valign="top" tabIndex={0}> 
                                      <td width="70%">4) {t("Additional copies of Death certificates")}</td>
                                      <td width="30%"><b>{t("Rs. 100/- each copy")}</b></td>
                                    </tr>
                                    <tr align="left" valign="top" tabIndex={0}> 
                                      <td width="70%">5) {t("Non-Availability Certificate")}</td>
                                      <td width="30%"><b>{t("Rs. 500/-")}</b></td>
                                    </tr>
                                  </tbody>
                              </table>
                          </div>
                            </div>
                        </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </section>

    </>
  )
}

export default Birth_Death_Ragistration