import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Amusement_Parks = () => { 
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  
  const [ProjectService, setProjectService] = React.useState(false);
  const [MoreOneService, setMoreOneService] = React.useState(false);
  const [MoreTwoService, setMoreTwoService] = React.useState(false);
  const [MoreThreeService, setMoreThreeService] = React.useState(false);

  const ProjectServiceToggle = () => {
    setProjectService(!ProjectService);
  };
  const MoreOneServiceToggle = () => {
    setMoreOneService(!MoreOneService);
  };
  const MoreTwoServiceToggle = () => {
    setMoreTwoService(!MoreTwoService);
  };
  const MoreThreeServiceToggle = () => {
    setMoreThreeService(!MoreThreeService);
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
        {/* Slider */}
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu">           
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/About_Kolkata'> {t("About Kolkata")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/map'> {t("Map")} </Link></li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/History_Of_Kolkata'> {t("History")}</Link></li>
                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Places of Interest")} <i className="bi bi-chevron-down" /></button>
                    <div id="demo" className="collapse show">                        
                        <Link className="nav-link fontSizeClass" to='/Art_Galleries'>1. {t("Art Galleries")}</Link>
                        <Link className="nav-link fontSizeClass active1" to='/Amusement_Parks'>2. {t("Amusement Park")} </Link>
                        <Link className="nav-link fontSizeClass" to='/Architecture'>3. {t("Architecture")} </Link>
                        <Link className="nav-link fontSizeClass" to='/Other_Noteable_place'> 4. {t("Other Notable Places")} </Link>
                    </div>
                </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Basic_statestics'> {t("Basic Statistics")} </Link> </li>  
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/UEVRP_Home'> {t("UEVRP")} </Link> </li>   
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="">		  
            <div className="inner-title"><h3 className="fontSizeClass">  {t("Amusement Parks / Auditoriums / Clubs")} </h3></div>		   		   
            <div className="col-lg-12 about-head1">
              <p className="fontSizeClass">
                {t("Life never stops in Kolkata. At any time of the year there is something going on - a festival, a wedding, a match, a demonstration, a concert, a vernissage or a party. For some, Calcutta - Kolkata is the city of joy, while for others it is dirty, crowded, and noisy")}.
              </p>
            </div>
            <div id="accordion">
                <div class="card">
                  <div class="card-header">
                    <a class="nav-link" data-toggle="collapse" href="#collapseOne">
                    {t("Agri Horticultural Garden")} </a>
                  </div>
                  <div id="collapseOne" class="collapse show" data-parent="#accordion">
                    <div class="card-body m-3">
                    {t("Amusement Department is for issuing permission under section 422 read with Sec.543 (2) of the KMC Act 1980 to amusement related businesses. Such amusement related businesses/activities include Theatres, Cinema Houses, Circuses, Fairs, Fetes, Exhibitions, Dancing Halls, Carnivals, Video Shows/Games, Cyber Cafes, Cable TV operators, Restaurants, Restaurants with Bar, Hotels, Planetariums, Pool Parlors, Clubs & similar public resorts")}. 
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="collapsed nav-link" data-toggle="collapse" href="#collapseTwo">
                    {t("What are the contact details of sub-departments within the assessment department")}?
                  </a>
                  </div>
                  <div id="collapseTwo" class="collapse" data-parent="#accordion">
                    <div class="card-body m-3">
                        <p className="fontSizeClass"> {t("Plunge into Aquatica where an ocean of undiluted excitement awaits you. Drive into Tornado, heart stoping 100ft. tunnel journey. Go wild on Mountain River, a thrilling ride through wilderness. Get into the swim of things with Aqua Dance, Island cafe & much more")}.</p>
                        <p className="fontSizeClass"> {t("Address: Kochpukur, 15 min. drive from Nicco Park")}. <br /> {t("Ph.: 22213626 / 27 , 2249 3248, 9830020520")} </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="collapsed nav-link" data-toggle="collapse" href="#collapseThree">
                    {t("Bana Bithi (Manohar Das Tank)")}
                    </a>
                  </div>
                  <div id="collapseThree" class="collapse" data-parent="#accordion">
                    <div class="card-body m-3">
                        <p className="fontSizeClass">{t("A beautification project of CMDA inaugurated in the year 2000. One will enjoy the charm of greenary in the midst of fountains & murals")}</p>
                        <p className="fontSizeClass"> {t("Address: At the crossing of Queen's way & Jawaharlal Nehru Road")}.</p>        
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="collapsed nav-link" data-toggle="collapse" href="#collapse4">
                    {t("Botanical Gardens")}
                    </a>
                  </div>
                  <div id="collapse4" class="collapse" data-parent="#accordion">
                    <div class="card-body m-3">
                          <p className="fontSizeClass"> {t("Situated at Shibpur area of Howrah District, it was laid out in 1787. This is the oldest of all the Botanical Gardens in India. Its cover area is 270 acres. Inside is an incredible variety of about 50,000 trees. The pivotal attraction here is the giant banyan tree which covers about 404 metres of land. It has 600 aerial roots, 157 offshoots hanging down from the original tree.There are innumerable orchids and colourful flowers. Boating takes place regularly on the lake. There is a precious library in the garden which comprises a rare collection of books on Botany. It remains opens from sunrise to sunset. This ethereal ambience is ideal for fun, fiesta and frolics")}.</p>
                          <p className="fontSizeClass">{t("Address: 9 km. from Kolkata across the Hooghly river")}. <br /> {t("Ph.: 2668 1466")} </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="collapsed nav-link" data-toggle="collapse" href="#collapse5">
                    {t("Do I need a license for a restaurant, cyber café, cinema hall etc")} ?
                    </a>
                  </div>
                  <div id="collapse5" class="collapse" data-parent="#accordion">
                    <div class="card-body m-3">
                    <p className="fontSizeClass"> {t("The Maidan is the most conspicuous vestige of British Calcutta. Ironically, what now serves as a heaven of tranquility and respite for Calcuttans, was originally intended as a ground to test - fire the guns of the Fort William. It is now a place for Migrants, travelling performers and trades people who exhibit their skills. One can have excellent view of Victoria Memorial, Red Road, Sahid Minar etc. from here")}. </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="collapsed nav-link" data-toggle="collapse" href="#collapse6">
                    {t("Eden Gardens")}
                    </a>
                  </div>
                  <div id="collapse6" class="collapse" data-parent="#accordion">
                    <div class="card-body m-3">
                          <p className="fontSizeClass"> {t("Situated at Shibpur area of Howrah District, it was laid out in 1787. This is the oldest of all the Botanical Gardens in India. Its cover area is 270 acres. Inside is an incredible variety of about 50,000 trees. The pivotal attraction here is the giant banyan tree which covers about 404 metres of land. It has 600 aerial roots, 157 offshoots hanging down from the original tree.There are innumerable orchids and colourful flowers. Boating takes place regularly on the lake. There is a precious library in the garden which comprises a rare collection of books on Botany. It remains opens from sunrise to sunset. This ethereal ambience is ideal for fun, fiesta and frolics")}.</p>
                          <p className="fontSizeClass">{t("Address: 9 km. from Kolkata across the Hooghly river")}. <br /> {t("Ph.: 2668 1466")} </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="collapsed nav-link" data-toggle="collapse" href="#collapse7">
                    {t("Swabhumi - The Heritage Park")}
                    </a>
                  </div>
                  <div id="collapse7" class="collapse" data-parent="#accordion">
                    <div class="card-body m-3">
                    <p className="fontSizeClass"> {t("Address: Eastern Metropolitan Bypass, 89C Moulana Abul Kalam Azad Sarani, Kolkata 700 054")} <br />{t("Ph.: 2321 5486 2334 3903")} </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="collapsed nav-link" data-toggle="collapse" href="#collapse8">
                    {t("Energy Park")}
                    </a>
                  </div>
                  <div id="collapse8" class="collapse" data-parent="#accordion">
                    <div class="card-body m-3">
                    <p className="fontSizeClass"> {t("Started in 2000, it is a joint venture of WEBREDA, Science Technology & Renewable Energy Deptt., Govt. Of W.B. It is open from 10 am. to 7 pm. Address: Eastern side of E.M. By - pass opposite to the Science City")}. </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="collapsed nav-link" data-toggle="collapse" href="#collapse9">
                    {t("Girish Mancha")}</a>
                  </div>
                  <div id="collapse9" class="collapse" data-parent="#accordion">
                    <div class="card-body m-3">
                        <p className="fontSizeClass"> {t("Named under the great actor of Bengal, Girish Ghosh, this auditorium is under the Information & Cultural Affairs Department")}. </p>
                        <p className="fontSizeClass">{t("Address: 76/1 Bagh Bazar Street. Kolkata - 700 003")} <br />{t("Ph.: 2554 4895")} </p>                     
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <a class="collapsed nav-link" data-toggle="collapse" href="#collapse10">
                    {t("Horticulture Garden")}
                    </a>
                  </div>
                  <div id="collapse10" class="collapse" data-parent="#accordion">
                    <div class="card-body m-3">
                    <p className="fontSizeClass"> {t("Founded by William Carey in the year 1820,this garden has collection of botanical varieties and promotes gardening & flower shows")}. </p>
                    <p className="fontSizeClass"> {t("Address : Adjacent to Alipore Zoo, Kolkata")} </p>                 </div>
                              
                  </div>
                </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Amusement_Parks