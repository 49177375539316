import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const MC_Circular_2020_21 = () => {
  const fileDownload=(fileStoreId)=>
        {
          
          let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
           axios
              .post(url,{"fileId": fileStoreId, "userId": "392" })
              .then((res) => {
                  console.log("response is --- ", res);
      
                  if(res && res.data && res.status === 200) {
                    const base64 = res.data[0] && res.data[0].base64_data;
                    const pdfData = 'data:application/pdf;base64,'+base64;
      
                    let html = '<html>' +
                        '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                        '<body>' +
                        '<iframe src="' + pdfData + '"></iframe>' +
                        '</body></html>';
                        console.log(html);
                    let a = window.open();
                    a.document.write(html);
                }
              })
              .catch((e) => {
                  // setLoader(false);
                  console.log("The error is ", e);
              })
        }
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
    const [DocumentService, setDocumentService] = React.useState(false);

const DocumentServiceToggle = () => {
    setDocumentService(!DocumentService);
    };
  return (
<>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
  <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      </div>
    </section>
    <section className="section inner-pad1">
      <div className="container " >
        <div className="row">
          <div className="col-lg-3">
          <div className="site-menu  min-height-400">
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <button type="button" class="btn nav-link pr-0" data-toggle="collapse" data-target="#demo">{t("Municipal Commissioner CIRCULAR")}<i className="bi bi-chevron-down " /></button>
                    <div id="demo" class="collapse">
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2024_25'> {t("MC's Circular")} 2024-25 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2023_24'> {t("MC's Circular ")}2023-24</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2022_23'> {t("MC's Circular")}2022-23</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2021_22'> {t("MC's Circular")} 2021-22</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass active1" to='/MC_Circular_2020_21'>{t("MC's Circular")} 2020-21</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2019_20'>{t("MC's Circular")} 2019-20</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2018_19'> {t("MC's Circular")} 2018-19</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2017_18'>{t("MC's Circular")} 2017-18</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2016_17'>{t("MC's Circular")} 2016-17</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2015_16'>{t("MC's Circular")} 2015-16</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2004_05'>{t("MC's Circular")} 2004-05</Link></li>
                    </div>
                  </li><li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Municipal_secretary_circular'>{t("MUNICIPAL SECRETARY'S CIRCULAR")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/CMF_Communique_2022_23'>{t("C M F & A's Circular and Communique - 2022-23")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Chief_Manager_Circular'>{t("Chief Manager (P)'s Circular")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Govt_memo'>{t("Govt Memo")}</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1558')}> {t("West Bengal Right to Public Service Act,2013")} </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1559')}>{t("Guidelines of Section 199(2) of KMC Act,1980 (as amended in 2019)")}  </a>
                  </li>
                  <li className="nav-item-list ">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("COMPENDIUM OF IMPORTANT DEPARTMENTAL CIRCULARS / ORDERS")}<i className="bi bi-chevron-down " /></button>
                      <div id="demo1" className="collapse">                        
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>1. Financial Year 2010-11 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>2. Financial Year 2011-12 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('870')}>3. Financial Year 2012-13 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('925')}>4. Financial Year 2013-14 </a>   
                      </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2 bas">
                  <h3 className="fontSizeClass"> {t("Circular")} </h3>
                </div>
                <div className="health-dept-table mayor-table">
                  <table className="table table-bordered officials" >
                    <tbody>
                      <tr
                        bgcolor="#302ba0"
                        tabIndex={0}
                        
                        valign="middle">
                        <td width="35%" className="text-white tble-brder-left">
                        {t("Circular No")}		
                        </td>
                        <td width="20%" className="text-white">
                        {t("Date")}		
                        </td>
                        <td width="45%" className="text-white tble-brder-right">
                        {t("Subject")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('990')}> {t("MC's Circular No.58 of 2021-22")} </a>
                        </td>
                        <td >
                        {t("25-March-2022")}	
                        </td>
                        <td >
                        {t("Sanction of Inrernal House Drainage during issuance of subesquent senctions under Rule 25(2A), 25(2B) Of the KMC Building Rules,1990 Or under Rule 26(2A),26(2B) Of the KMC Building Rules,2009 or retention allowed By KMC under section 400(1) and/or 416 of the KMC Act,1980 and/or before issuance of completion certificate")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('991')}>{t("MC's Circular No.54 of 2020-21")} </a>
                        </td>
                        <td >
                        {t("19-March-2021")}				
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 54 of 2020-21")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('992')}>{t("MC's Circular No.53 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("16-March-2021")}	
                        </td>
                        <td >
                        {t("Caution to be exercised for prevention of outbreak of fire in kmc building")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('993')}>{t("MC's Circular No.50 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("26-February-2021")}				
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 50 of 2020-21")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('995')}>{t("MC's Circular No.49 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("25-February-2021")}				
                        </td>
                        <td >
                        {t("Passing of Speaking Order by the Hearing Officer at the time of fixation of Annual valuation")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('996')}>{t("MC's Circular No.48 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("23-February-2021")}	
                        </td>
                        <td >
                        {t("Implementation of Notification No.201-F(Y) Dated 18/01/2021 on reduced Performance Security @3%")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('997')}>{t("MC's Circular No.47 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("20-February-2021")}			
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 47 of 2020-21")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('998')}>{t("Spl M.C(Rev) Office Order no 23 of 2020-2021")}</a>
                        </td>
                        <td >
                        {t("26-February-2021")}	                        </td>
                        <td >
                        {t("Spl M.C(Rev) Office Order no 23 of 2020-2021")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1000')}>{t("MC's Circular No.46 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("10-February-2021")}			
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 46 of 2020-21")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('999')}>{t("MC's Circular No.45 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("04-February-2021")}	                        </td>
                        <td >
                        {t("Verification procedure of tenant occupied area for proposals u/r 142 of the KMC Building Rules, 2009")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1001')}>{t("MC's Circular No.44 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("27-January-2021")}	                        </td>
                        <td >
                        {t("One time extension of time limit to apply for job under Special Regulation")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1002')}>{t("MC's Circular No.43 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("21-January-2021")}	                        </td>
                        <td >
                        {t("Inauguration of significant schemes of estimate of above 5 lakhs under 'Paray Samadhan' Campaign by Hon'ble CM")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1003')}>{t("MC's Circular No.42 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("13-January-2021")}	                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No 42 Of 2020-21")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1004')}>{t("MC's Circular No.41 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("11-January-2021")}	                        </td>
                        <td >
                        {t("Urgent submission of status report for schemes under 'Paray Samadhan Programme'")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1005')}>{t("MC's Circular No.40 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("06-January-20210")}	                        </td>
                        <td >
                        {t("Procedure for processing filesa requiring inter-departmental opinion")}</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1006')}>{t("MC's Circular No.39 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("06-January-2021")}			
                        </td>
                        <td >
                        {t("Listing of KMC employees for Covid-19 vaccination")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1007')}>{t("MC's Circular No.38 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("24-December-2020")}	
                        </td>
                        <td >
                        {t("Procedure for processing filesa requiring inter-departmental opinion")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1008')}>{t("MC's Circular No.37 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("16-December-2020")}				
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No 37 Of 2020-21")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1009')}>{t("MC's Circular No.36 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("11-December-2020")}		
                        </td>
                        <td >
                        {t("Full utilization of ERP")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1010')}>{t("MC's Circular No.35 of 2020-21")}	</a>
                        </td>
                        <td >
                        {t("28-November-2020")}		
                         </td>
                        <td >
                        {t("Office Procedure and writing Note in File")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1011')}>{t("MC's Circular No.34 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("26-November-2020")}	                        </td>
                        <td >
                        {t("Modification of the Municipal Commissioner's Circular no. 34 of 1976-77 with regard to celling of number of prayer for NOC in case of job appilication by the K.M.C. employees elsewhere in a calender year")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1012')}>{t("MC's Circular No.33 of 2020-21")}</a>
                        </td>
                        <td >
                          {t("25-November-2020")}	                        </td>
                        <td >
                        {t("Emergent And Superior-lavel Personnelarrangement During Festive Days (most Particulerly Form October 10th .2021, To October 20th, 2021.)")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1013')}> {t("MC's Circular No.36 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("23-September-2021")}	
                        </td>
                        <td >
                        {t("Prompt action on the direction of Hon'ble Courts")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1014')}>{t("MC's Circular No.32 of 2020-21")}	</a>
                        </td>
                        <td >
                        {t("10-November-2020")}				
                        </td>
                        <td >
                        {t("Deployment of officials at the different ghats on the river Hooghly and ponds in added areas")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1014')}>{t("MC's Circular No.31 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("03-November-2020")}					
                        </td>
                        <td >
                        {t("Prompt and regular follow up ofimportant matters")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1015')}>{t("MC's Circular No.30 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("03-November-2020")}				
                        </td>
                        <td >
                        {t("Review of DP & Suspension Cases")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1016')}>{t("MC's Circular No.29 of 2020-21")}	</a>
                        </td>
                        <td >
                        {t("03-November-2020")}                       </td>
                        <td >
                        {t("Composite proposal for sanction of capital works")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1017')}>{t("MC's Circular No.28 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("15-October-2020")}			
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No 28 Of 2020-21")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1018')}>{t("MC's Circular No.27 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("07-October-2020")}					
                        </td>
                        <td >
                        {t("Emergent instrument/apparafus arrangements during festive days (most particularly from (October, 19th 2020 to October, 30th,2020)) 24 X 7 arrangements")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1019')}>{t("MC's Circular No.26 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("07-October-2020")}	                        </td>
                        <td >
                        {t("Emergency and superior - Level personnel arrangements during festive day (most particularly from October 19th, 2020 to October 30th, 2020)")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1020')}>{t("MC's Circular No.25 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("26-September-2020")}		                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No 25 Of 2020-21")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1021')}>{t("MC's Circular No.24 of 2020-21")}	</a>
                        </td>
                        <td >
                        {t("25-September-2020")}					
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No 24 Of 2020-21")}                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1022')}>{t("MC's Circular No.23 of 2020-21")}	</a>
                        </td>
                        <td >
                        {t("23-September-2020")}					
                        </td>
                        <td >
                        {t("Clarification in format of MIC/BoA Agenda item for cases dealt under Section 400(1) & 416 of the KMC Act,1980")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1023')}>{t("MC's Circular No.22 of 2020-21")}	</a>
                        </td>
                        <td >
                        {t("23-September-2020")}						
                        </td>
                        <td >
                        {t("Directives to be followed by SO (Building) or any Other Officer dealing with Demolition, regularization and/ or change of use")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1024')}> {t("MC's Circular No.21 of 2020-21")}	</a>
                        </td>
                        <td >
                        {t("14-September-2020")}					
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 21 of 2020-21")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1025')}>{t("MC's Circular No.20 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("09-September-2020")}					
                        </td>
                        <td >
                        {t("Files to be created. transmittted and placed through the E - Office system")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1026')}>{t("MC's Circular No.19 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("22-August-2020")}						
                        </td>
                        <td >
                        {t("Daily Activity Updates and 2-Way Communication on Social Media")}
                       </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1027')}> {t("MC's Circular No.18 of 2020-21")}	</a>
                        </td>
                        <td >
                        {t("28-July-2020")}					
                        </td>
                        <td >
                        {t("Modification of point no. V of Mpl. Commissioner's Circular No.16 of 2020-'21 dated 20.07.2020")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1028')}>{t("MC's Circular No.17 of 2020-21")}	</a>
                        </td>
                        <td >
                        {t("22-July-2020")}					
                        </td>
                        <td >
                        {t("Modalities to be followed by KMC during this pandemic situation caused due to COVID-19:(a) Necessary Modification of the Municipal Commissioner's Circular No. 16 of 2020-21 dated 20.07.2020. (b) Issuance of matching Order in tune with the Order of the Chief Secretary to the Government of West Bengal vide Memo No. 283- CS/2020 dated 21/07/2020")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1029')}>{t("MC's Circular No.16 of 2020-21")}	</a>
                        </td>
                        <td >
                        {t("20-July-2020")}	</td>
                        <td >
                        {t("Attendance policy of permanent staff/Officer of KMC in order to avoid Congregation in Office premises")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1030')}>{t("MC's Circular No.15 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("17-July-2020")}		                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 15 of 2020-21")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1032')}>{t("MC's Circular No.14 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("13-July-2020")}		                        </td>
                        <td >
                        {t("Extension of period for submission of Declaration of Assets as stood on 1st January, 2020")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1033')}>{t("MC's Circular No.12 of 2020-21")}	</a>
                        </td>
                        <td >
                        {t("07-July-2020")}	</td>
                        <td >
                        {t("Amendment of Rule 22(C) of the GPF regulation of the Kolkata Municipal Corporation")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1034')}>{t("MC's Circular No.11 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("07-July-2020")}	</td>
                        <td >
                        {t("Work flow of files involving values higher than the power delegated to the Director General")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1035')}>{t("MC's Circular No.10 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("04-July-2020")}	</td>
                        <td >
                        {t("Clarification in format of MIC/BoA Agenda Item")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1036')}>{t("MC's Circular No.09 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("27-June-2020")}	</td>
                        <td >
                        {t("Reconstitution of The Tender Committee and Work Committee in KMC")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1037')}>{t("MC's Circular No.08 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("25-June-2020")}		 </td>
                        <td >
                        {t("Insurance Policy For Persons Deployed For COVID-19 Related Duties in KMC")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1038')}>{t("MC's Circular No.07 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("24-June-2020")}	</td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 07 of 2020-21")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1039')}>{t("MC's Circular No.06 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("24-June-2020")}	</td>
                        <td >
                        {t("Weekly Review Meeting with Urban Dev.& Mpl.Affairs Department,GoWB")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1040')}>{t("MC's Circular No.05 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("17-June-2020")}	 </td>
                        <td >
                        {t("Restriction of construction of masonry building in high security zone of Kolkata")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1041')}>{t("MC's Circular No.04 of 2020-21")}</a>
                        </td>
                        <td >
                        {t("11-June-2020")}	
                        </td>
                        <td >
                        {t("Adoption of Standard Operating Procedures in The Kolkata municipal Corporation to prevent the chances of spread of the disease COVID-19 during the ongoing covid-19 induced crisis period")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1042')}>{t("MC's Circular No.03 of 2020-21")}	</a>
                        </td>
                        <td >
                        {t("06-June-2020")}	</td>
                        <td >
                        {t("To ensure 100% attendance of entire staff strength of KMC of all the departments w.e.f. 08.06.2020")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1043')}>{t("MC's Circular No.02 of 2020-21")}	</a>
                        </td>
                        <td >{t("06-May-2020")}	</td>
                        <td >
                        {t("Emergency arrangements during the ensuing norwester/rainy season in the next few months of 2020")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1044')}>{t("MC's Circular No.01 of 2020-21")}</a>
                        </td>
                        <td >{t("02-May-2020")}</td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 01 of 2020-21")}
                        </td>
                      </tr>
                      </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>  )
}

export default MC_Circular_2020_21