import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Death_Record = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
        {/* Slider */}
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
  
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">           
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Birth_Death_Ragistration'> {t("Birth/Death Home")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Birth_Ragistration_center'> {t("Birth Registration Centres")} </Link> </li>  
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Death_Ragistration_center'> {t("Death Registration Centres")} </Link> </li>  
                <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBirthRecordSearch.jsp'> {t("Find Birth Records")} </a> </li>
                <li className="nav-item"><a className="nav-link active1" href='https://www.kmcgov.in/KMCPortal/jsp/KMCDeathRecordSearch.jsp'> {t("Find Death Records")}</a> </li>  					
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="dig-login1 dig-form">
            <form>
              <div className="log-form">
                <div className="container">
                  <div className="row">
                    <h3 className="fontSizeClass">{t("Death Registration Details")}</h3>
                    <p className="subtitle">{t("Find Death Registration Record")} </p>
                    <div className="col-xl-12 col-md-12 mb-12 form-group pt-2">  
                      <label className="form-label">{t("Deceased Name")}</label>
                      <input type="text" className="form-control" aria-label aria-describedby="basic-addon1" />
                      <p className="subheader">{t("(Atleast 3 chars)")}</p>
                    </div>			   
                    <div className="col-xl-12 col-md-12 mb-12 form-group pt-2"> 
                      <label className="form-label">{t("Date of Death")}</label>                   
                      <input type="date" className="form-control" aria-label aria-describedby="basic-addon1" />
                      <p className="subheader">{t("(dd/mm/yyyy)")}:</p>
                    </div>
                    <div className="login__field break"> 
                      <div className="form-group continu"> 
                        <Link to="index.html">  {t("Submit")} </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Death_Record