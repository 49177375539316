import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";


const Reprint_Eprint = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const fileDownload = (fileStoreId) => {
    let url = `https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`;
    axios
      .post(url, { fileId: fileStoreId, userId: "392" })
      .then((res) => {
        console.log("response is --- ", res);

        if (res && res.data && res.status === 200) {
          const base64 = res.data[0] && res.data[0].base64_data;
          const pdfData = "data:application/pdf;base64," + base64;

          let html =
            "<html>" +
            "<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>" +
            "<body>" +
            '<iframe src="' +
            pdfData +
            '"></iframe>' +
            "</body></html>";
          console.log(html);
          let a = window.open();
          a.document.write(html);
        }
      })
      .catch((e) => {
        // setLoader(false);
        console.log("The error is ", e);
      });
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
      <section id="hero" className="hero hero2">
        <div
          className="social-media wow slideInDown"
          style={{ visibility: "visible", animationName: "slideInDown" }}
        >
          <div className="facebook" rel="ebInfo" title="facebook">
            <a
              href="https://www.facebook.com/KolkataMunicipalCorporation.official"
              target="_blank"
            >
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a
              href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp"
              target="_blank"
            >
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>
        <div
          className="social-media wow slideInDown"
          style={{ visibility: "visible", animationName: "slideInDown" }}
        >
          <div className="facebook" rel="ebInfo" title="facebook">
            <a
              href="https://www.facebook.com/KolkataMunicipalCorporation.official"
              target="_blank"
            >
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a
              href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp"
              target="_blank"
            >
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>

        <div className="carousel-item active">
          <img
            src="assets/img/inner-bg2.png"
            className="img-fluid"
            alt="image"
          />
          <div className="container">
            <div className="section-main-heading2">
              <h4 className="fontSizeClass"> {t("Reprint e-Receipt")} </h4>
            </div>
          </div>
        </div>
      </section>
      {/* End Slider */}
      <section className="section inner-pad1">
        <div className="container ">
          <div className="row">
            <div className="col-lg-3">
              <div className="site-menu  min-height-400">
                <nav className="sidebar card py-2">
                  <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Water_Supply"
                      >
                        {t("Water Supply Home")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Find_Plumbers"
                      >
                        {t("Find Plumbers")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Download_Forms"
                      >
                        {t("Download Forms")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/portal">
                        {t("Fees and Charges")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Check_Demands_Payable"
                      >
                        {t("Check Demands Payable")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Print_Unpaid_Bill"
                      >
                        {t("Print Unpaid Bill")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Make_Online_Payment"
                      >
                        {t("Make Online Payment")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link active1 fontSizeClass"
                        to="/Reprint_Eprint"
                      >
                        {t("Reprint e-Receipt")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Check_Demands_Status"
                      >
                        {t("Check Demand Status")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/portal">
                        {t("FAQs")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Water_Supply_Dept"
                      >
                        {t("Contact Details")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Water_For_Fire_Fighter"
                      >
                        {t("Water For Fire Fighters")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        className="fontSizeClass "
                        onClick={(e) => fileDownload("1587")}
                      >
                        {" "}
                        {t(
                          "Action Plan to reduce water losses to less than 20%"
                        )}{" "}
                      </a>{" "}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="container">
                <div className="row">
                  <div className="inner-title2">
                    <h3 className="fontSizeClass">
                      {" "}
                      {t("Water Supply e-Receipts")}{" "}
                    </h3>
                  </div>
                  <div className="about-head1">
                    <div className="dig-login1 dig-login2">
                      <form>
                        <div className="log-form inner-log-form">
                          <h3 className="fontSizeClass">
                            {" "}
                            {t("List of WS e-Receipts")}{" "}
                          </h3>
                          <div className="row">
                            <div className="col-lg-6 col-md-12 form-group pt-2">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                {t("Receipt Date From* (dd/mm/yyyy):")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="13/12/2022"
                                aria-label="Username"
                              />
                            </div>
                            <div className="col-lg-6 col-md-12 form-group pt-2">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                {t("Account No")}*:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={1234567890}
                                aria-label="Username"
                              />
                            </div>
                            <div className="col-lg-6 col-md-12 form-group pt-2">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                {t("Receipt Date To* (dd/mm/yyyy):")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="13/12/2022"
                                aria-label="Username"
                              />
                            </div>
                            <div className="col-lg-6 col-md-12 form-group pt-2">
                              <div
                                className="form-group continu"
                                style={{ marginTop: "30px" }}
                              >
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  &nbsp;
                                </label>
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                >
                                  {t("Search Receipt")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Reprint_Eprint