import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Tender  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const [SexualService, setSexualService] = React.useState(false);
  const [SexualService1, setSexualService1] = React.useState(false);
  const [SexualService2, setSexualService2] = React.useState(false);

  const SexualServiceToggle = () => {
    setSexualService(!SexualService);
  };
  const SexualServiceToggle1 = () => {
    setSexualService1(!SexualService1);
  };
  const SexualServiceToggle2 = () => {
    setSexualService2(!SexualService2);
  };
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/Banner/portal-banner7.jpg" className="img-fluid" alt="image" />
      <div className="container">
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> </h4>
        </div>
      </div>
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass " to='/KMC_market_list'> {t("List of KMC Markets")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Download_market_fire_fighting_video'> {t("Download Market Fire-Fighting Video")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCCollegeStMarketList.jsp' target="_blank"> {t("College Street Market")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('668')}> {t("Rates & Charges")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/MarketDemandView.jsp' target="_blank"> {t("Check Demands Payable")} </a>
                  {/* <Link className="nav-link fontSizeClass" to='/Market_Check_demands_payable'> Check Demands Payable</Link> */}
                </li>
                <li className="nav-item">
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Check Demands Payable")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo" className="collapse">                        
                      <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/MarketPayment.jsp'> {t("Regular Demand")} </a>
                    <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/MarketMiscPayDemand.jsp'> {t("Misclennious Demand")} </a>
                      </div>
                </li>
                <li className="nav-item">
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("Make Online Payment")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo1" className="collapse">                        
                        <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/MarketPayment.jsp'> {t("Regular Demand")} </a>
                        <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/MarketMiscPayDemand.jsp'> {t("Misclennious Demand")} </a>
                      </div>
                </li>
              { /* <li className="nav-item">
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Reprint e-receipt")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo2" className="collapse">                        
                        <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/MarketDuplicateReceipt.jsp'><i className="bi bi-chevron-right" /> {t("Regular Demand")} </a>
                        <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/MarketMiscDuplicateReceipt.jsp'><i className="bi bi-chevron-right" /> {t("Misclennious Demand")} </a>
                      </div>
  </li> */}
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Market_list_of_e_receipts'> {t("Reprint e-Receipt")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('669')}> {t("Don't be cheated into the market")}. </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('670')}> {t("This is for you, kindly read this")}. </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Maps_of_market'> {t("Maps of deep tubewells situated in the market area for fire fighting")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Tender'> {t("Tender")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('671')}> {t("KMC Market Stall Details")}.. </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCMarketStallSearch.jsp' target="_blank"> {t("KMC Market Stall Search")} </a>
                 {/* <Link className="nav-link fontSizeClass" to='/Market_stall_search'> KMC Market Stall Search </Link> */}
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Market_Dept'>{t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Water_For_Fire_Fighter'> {t("Water For Fire Fighters")} </Link>              
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('672')}> {t("Notice regarding Demolition of Stalls in Marcus Squar")} </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass"> {t("Tenders")}</h3>
              </div>
              <div className="dig-login2">
                <div className="pdf-link1 p-3">
                <h6 className="fontSizeClass"><a className="fontSizeClass" onClick={(e)=>fileDownload('')}> 1.{t("Tender Document for Allotment of Stall at Sir Stuart Hogg Market (New Market)")}. </a></h6>
                <h6 className="fontSizeClass"><a className="fontSizeClass" onClick={(e)=>fileDownload('')}> 2.{t("Tender Document for Allotment of Stalls at Gariahat Market")}. </a></h6>
                <h6 className="fontSizeClass"><a className="fontSizeClass" onClick={(e)=>fileDownload('')}> 3.{t("Tender Document for Allotment of Vacant Stalls at Maniktala Kankurgachi (VIP) Municipal Market")}. </a></h6>
                <h6 className="fontSizeClass"><a className="fontSizeClass" onClick={(e)=>fileDownload('')}> 4. {t("Tender Document for allotment of stalls at Jadavgarh Market")}. </a></h6>
                <h6 className="fontSizeClass"><a className="fontSizeClass" onClick={(e)=>fileDownload('')}> 5. {t("Tender Document for Allotment of Stalls at Manton Super Market")}. </a></h6>              
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Tender