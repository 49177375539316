import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Places_Of_Interest = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
        {/* Slider */}
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"> <h4 className="fontSizeClass">  {t("Art Galleries / Museums / Libraries")} </h4></div></div>             
    </div>       	 		 
  </section>	  
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu">           
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/About_Kolkata'> {t("About Kolkata")} </Link> </li>
                <li className="nav-item"><Link className="nav-link act" to='/map'> {t("Map")} </Link></li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/History_Of_Kolkata'> {t("History")}</Link></li>
                <li className="nav-item">
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Places of Interest")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo2" className="collapse hide">                        
                            <Link className="nav-link fontSizeClass" to='/Art_Galleries'>1. {t("Art Galleries")}</Link>
                            <Link className="nav-link fontSizeClass" to='/Amusement_Parks'>2. {t("Amusement Park")} </Link>
                            <Link className="nav-link fontSizeClass" to='/Architecture'>3. {t("Architecture")} </Link>
                            <Link className="nav-link fontSizeClass" to='/Other_Noteable_place'>4. {t("Other Notable Places")} </Link>  
                      </div>
                  </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/BasicStatistics'> {t("Basic Statistics")} </Link> </li>  
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/UEVRP_Home'> {t("UEVRP")} </Link></li>   
              </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9 pb-0 pt-0">
        <div className="container">
          <div className="row">		  
            <div className="inner-title"><h3 className="fontSizeClass"> {t("Art Galleries / Museums / Libraries")} </h3></div>		   		   
            <div className="col-lg-12 about-head1">
              <p className="fontSizeClass">
                {t("Life never stops in Kolkata. At any time of the year there is something going on - a festival, a wedding, a match, a demonstration, a concert, a vernissage or a party. For some, Calcutta - Kolkata is the city of joy, while for others it is dirty, crowded, and noisy")}.
              </p>
            </div>
            <div className="col-lg-4 col-md-6">
          <section id="faq" className="faq">
            <div className="" >
              <div className="row gy-4">
                <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                  <div className="accordion accordion-flush" id="faqlist">

                    <div className="accordion-item "  data-aos-delay={400}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-18" aria-expanded="false">
                          {t("Asiatic Society")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-18" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                        <div className="accordion-body">
                          <p className="fontSizeClass"> {t("Located at 1 Park Street, Kolkata 700016, this institution is a famous centre of Indology, Literature and Scientific Research. It was founded by Sir William Jones in 1784 with Warren Hastings as its first patron. Its library contains about 20000 volumes including about 8000 rare Sanskrit, Arabic, Persian and Hindi manuscripts")}. </p>
                        </div>
                      </div>
                    </div>{/* # Faq item */}
                    <div className="accordion-item "  data-aos-delay={300}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3" aria-expanded="false">            
                          {t("Gurusaday Museum")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist" style={{}}>
                        <div className="accordion-body about-img4">					    
                          <p className="fontSizeClass"> {t("About 15km from Kolkata on the Diamond Harbour Road, this museum contains a small but rich collection of the folk arts and crafts of undivided Bengal. Seminars & demonstrations on folk art & culture are held in the annexe and the grounds of the museum")}. </p> <p className="fontSizeClass">{t("Address: Bratacharigram, Joka South 24 Parganas")}.</p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                    <div className="accordion-item "  data-aos-delay={400}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4" aria-expanded="false">
                          {t("Metcalfe Hall")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                        <div className="accordion-body">
                          <p className="fontSizeClass"> L{t("ocated in Strand Road, it is an imperial library with one hall. Its thirty column rising 3feet and resembling the ' Tower of Winds ' in Athens, homours Sir Charles Metcalfe's efforts towards a free press")}. </p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                    <div className="accordion-item "  data-aos-delay={400}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-5" aria-expanded="false">
                          {t("Netaji Research Bureau")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-5" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                        <div className="accordion-body">
                          <p className="fontSizeClass"> {t("Established in 1961 at the Bose family house on Elgin Road, this bureau holds the Netaji Subhas Ch. Bose archieval material including Freedom Library and his personal effects. It is open from 12 noon to 4 pm")}. </p>
                          <p className="fontSizeClass">{t("Address: Elgin Road , Lala Rajpat Rai Sarani Ph.: 2475 6139")}</p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                    <div className="accordion-item "  data-aos-delay={400}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-6" aria-expanded="false">
                          {t("Academy of Coins & History")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-6" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                        <div className="accordion-body">
                          <p className="fontSizeClass"> {t("Established in 1997, a voluntary organisation dealing in ancient Indian coins and history. It is opened on weekdays from 11 am to 1 pm. Address: 361/B, Rabindra Sarani")} </p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                    <div className="accordion-item "  data-aos-delay={400}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-7" aria-expanded="false">
                          {t("Birla Academy of Art & Culture")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-7" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                        <div className="accordion-body">
                          <p className="fontSizeClass"> {t("A well maintained state gallery with exhibits depicting the artistic and cultural heritage of India and a permanent exhibition of Indian contemporary art. There are also archaeological specimens there. It is open from 4 pm to 8 pm")}. </p>
                          <p className="fontSizeClass">{t("Address: 108 & 109 Dr. Meghnad Saha Sarani Kolkata - 700 029")} <br /> Ph.: 2466 2843 / 6802</p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                  </div>
                </div>
              </div>
            </div>
          </section>
            </div>
            <div className="col-lg-4 col-md-6">
          <section id="faq" className="faq">
            <div className="" >
              <div className="row gy-4">
                <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                  <div className="accordion accordion-flush" id="faqlist">
                    <div className="accordion-item "  data-aos-delay={400}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2" aria-expanded="false">
                          {t("Centres of International Modern Art (CIMA)")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                        <div className="accordion-body">
                          <p className="fontSizeClass"> {t("Establishes in 1993, the CIMA Art Gallery is situated at 'Sunny Tower' in Asutosh Chowdhury Avenue, near Birla Temple. It is the first Art Gallery in Calcutta matching international standards and its cover area is no less than 11,500 sq. ft.")}
                          </p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                    <div className="accordion-item "  data-aos-delay={300}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-8" aria-expanded="false">            
                          {t("Gandhi Smarak Sangrahalay")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-8" className="accordion-collapse collapse" data-bs-parent="#faqlist" style={{}}>
                        <div className="accordion-body about-img4">
                          <p className="fontSizeClass"> {t("Located in Barrakpore, this smarak sangrahalaya holds a library")} <br />
                            {t("and a museum on the life of Mahatma Gandhi")}. <br />
                            {t("Address: 14, Riverside Road Barrackpore - 743 101 West-Bengal Ph: 5600170")} </p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                    <div className="accordion-item "  data-aos-delay={400}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-9" aria-expanded="false">
                          {t("Indian Museum")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-9" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                        <div className="accordion-body">
                          <p className="fontSizeClass"> {t("Situated in Chowringhee on Jawaharlal Nehru Road, it was founded in 1814. It is the largest museum in the country and has rare collection of antiques, armour & ornaments, fossils, skeletons, mummies, mughal paintings etc. It remains opened everyday from 10 a.m. to 5 p.m. except Mondays during March to November & between 10 am - 4:30 pm during December to February")}.</p><p className="fontSizeClass">{t("Address: J.L.Nehru Road. Kolkata 700 071")} <br /> ph.: 2249 9902 / 8931</p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                    <div className="accordion-item "  data-aos-delay={400}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-10" aria-expanded="false">
                          {t("National Library")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-10" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                        <div className="accordion-body">
                          <p className="fontSizeClass"> {t("The 2nd largest library in Asia . Its collection is in excess of 1million books and 5,00,000 manuscripts, with a number of over 18,000 reader-members. Several notable persons have donated their invaluable collections to the Library at different times. Sadar – Ud-ddin, the Zaminder of Buhor, Sir Ashutosh Mukherjee, Surendra Nath Sen etc. are some famous donors. These have added to the value and content of books in this prestigious institution")}. </p>
                          <p className="fontSizeClass">{t("Address: Belvedere Road. Alipore. Kolkata 700 027")}. <br /> Ph.: 2479 1381</p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                    <div className="accordion-item "  data-aos-delay={400}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-11" aria-expanded="false">
                          {t("Rabindra Bharati Museum")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-11" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                        <div className="accordion-body">
                          <p className="fontSizeClass"> {t("The Museum contains vestiges of the Bengal Renaissance of the nineteenth century. Situated near Girish Park on Chittaranjan Avenue, it is open from 10.00 a.m. to 5.00 p.m. on weekdays (1.30 p.m. on Saturdays)")}.</p>
                          <p className="fontSizeClass"> Ph.: 2239 6601 / 6610 / 5241 </p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                    <div className="accordion-item "  data-aos-delay={400}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-12" aria-expanded="false">
                          {t("Arts Acre")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-12" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                        <div className="accordion-body">
                          <p className="fontSizeClass"> {t("A village for the visual arts with art gallery, artists in residence and artists at work. Address: Gopalpur , 24 Parganas (North), Pin - 743518")} </p> <p className="fontSizeClass">Ph.: 2331 1372 ({t("City Office")}) </p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                  </div>
                </div>
              </div>
            </div>
          </section>
            </div>
            <div className="col-lg-4 col-md-6">
          <section id="faq" className="faq">
            <div className="" >
              <div className="row gy-4">
                <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                  <div className="accordion accordion-flush" id="faqlist">
                   
                  <div className="accordion-item "  data-aos-delay={400}>
<h3 className="accordion-header">
  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-18" aria-expanded="false">
    {t("Academy of Fine Arts")} <i className="bi bi-chevron-down" />
  </button>
</h3>
<div id="faq-content-18" className="accordion-collapse collapse" data-bs-parent="#faqlist">
  <div className="accordion-body">
  <img src="assets/img/art.jpg" className="img-fluid" alt />
        <p className="fontSizeClass">{t("Established in 1933, the Academy is a place where the city's culturally conscious intellectuals converge. It's art galleries feature exhibitions by contemporary artists throughout the year. The Rabindra gallery contains personal belongings, manuscripts and paintings by Tagore. Situated near Victoria Memorial, its galleries are open daily from 3 pm to 8 pm (except the Rabindra Gallery; 12.00 noon to 6 pm daily, closed on Mondays)")}.</p>
        <p className="fontSizeClass"> {t("Address: 2, Cathedral Road, Kolkata 700016")}. <br /> Ph.: 2223 4302 /4303 </p>
     
  </div>
</div>
</div>{/* # Faq item*/}
                   
                   
                    <div className="accordion-item "  data-aos-delay={300}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-13" aria-expanded="false">            
                          {t("Birla Indst. & Tech Museum")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-13" className="accordion-collapse collapse" data-bs-parent="#faqlist" style={{}}>
                        <div className="accordion-body about-img4">
                          <p className="fontSizeClass"> {t("Established in 1966, a permanent exhibition of industrial and technological progress in India. A seat of learning of modern science and technology for students, research scholars etc. Has a workshop which designs and produces much of its exhibits. Closed on Mondays")}.</p>
                          <p className="fontSizeClass">{t("Address: 19A Gurusaday Road. Kolkata - 700019")}
                            Ph.: 2247 7241-43 / 6102 </p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                    <div className="accordion-item "  data-aos-delay={400}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-14" aria-expanded="false">
                          {t("Government College of Art & Crafts")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-14" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                        <div className="accordion-body">
                          <p className="fontSizeClass"> {t("An almamater of many artistes and sulptors of national and international fame")}.</p>
                          <p className="fontSizeClass">	{t("Address: 28 J.L.Nehru Rd. , Kolkata - 700071")}
                            Ph.:2249 2027 </p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                    <div className="accordion-item "  data-aos-delay={400}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-15" aria-expanded="false">
                          {t("Jorasanko Thakurbari")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-15" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                        <div className="accordion-body">
                          <p className="fontSizeClass"> {t("Situated near Girish Park on Chittaranjan Avenue, the birth place of National Poet Rabindra Nath Tagore. The home of the Tagore family is now the premises of Rabindra Bharati University and a museum of Tagore memorabilia")}.</p>
                          <p className="fontSizeClass">{t("Address: 286 Rabindra Sarani. North Kolkata")}
                            Ph.: 2239 5242 </p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                    <div className="accordion-item "  data-aos-delay={400}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-16" aria-expanded="false">
                          {t("Nehru Children Museum")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-16" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                        <div className="accordion-body">
                          <p className="fontSizeClass"> {t("It is a centre for children's activity, collection of puppets depicting the Ramayana & the Mahabharata, toys & dolls and a huge library for children. This is opened on all days except Mondays from 12-00 noon to 8 pm")}. </p>
                          <p className="fontSizeClass">{t("Address: 94/1 J.L. Nehru Road. Kolkata -20")}
                            Ph.: 2223 3517. </p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                    <div className="accordion-item "  data-aos-delay={400}>
                      <h3 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-17" aria-expanded="false">
                          {t("Ramakrishna Mission Institute of Culture")} <i className="bi bi-chevron-down" />
                        </button>
                      </h3>
                      <div id="faq-content-17" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                        <div className="accordion-body">
                          <p className="fontSizeClass"> {t("The Ramakrishna Mission Institute of Culture at Gol Park, Calcutta, is a branch of Ramakrishna Math and Ramakrishna Mission, Belur Math (Worldwide headquarters of all RKM centres and sub-centres.). The Institute is well known for its philanthropic, educational and cultural activities all over the world. The building is having a very good Auditorium with a very good Library which contains rare books on History, Religion, Philosophy etc")}.</p>
                          <p className="fontSizeClass">{t("Address: Gol Park, Calcutta - 700 029")}
                            Ph: 2464 1303/ 04/ 05 </p>
                        </div>
                      </div>
                    </div>{/* # Faq item*/}
                  </div>
                </div>
              </div>
            </div>
          </section>
            </div>
          </div>
          </div> 
        </div>
        
      </div>
    </div>
  </section>

    </>
  )
}

export default Places_Of_Interest