import './App.css';
import {
  BrowserRouter as Router,
  Routes,Navigate,
  Route 
} from 'react-router-dom';

// import {Helmet} from "react-helmet";
import { useEffect } from "react";
import { ScrollRestoration } from "react-router-dom";

import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import Adminstration from './pages/Adminstration'
import AboutUs from './pages/AboutUs';
import MayorsOfKolkata from './pages/MayorsOfKolkata';
import MunicipalHistory from './pages/MunicipalHistory';
import Overview from './pages/Overview';
import Power from './pages/Power';
import EKolkataCenter from './pages/EKolkataCenter';
import Fucntions from './pages/Fucntions';
import KMCFunctions from './pages/KMCFunctions';
import Revenue from './pages/Revenue';
import Emblem from './pages/Emblem';
import KMCBudget from './pages/KMCBudget';
import KMCDepartments from './pages/KMCDepartments';
import CitizenLogin from './logins/CitizenLogin';
import Advertisement_download_forms from './Advertisement/Advertisement_download_forms';
import Advertisement_important_official from './Advertisement/Advertisement_important_official';
import Advertisement_ListOf_Hoarding from './Advertisement/Advertisement_ListOf_Hoarding';
import Advertisement_rates from './Advertisement/Advertisement_rates';
import Amusement_download_forms from './Amusement/Amusement_download_forms';
import Amusement from './Amusement/Amusement';
import Amusement_important_officials from './Amusement/Amusement_important_officials';
import KMC_Birth_Records from './Amusement/KMC_Birth_Records';
import Birth_Ragistration_center from './Amusement/Birth_Ragistration_center';
import Birth_Death_Ragistration from './Amusement/Birth_Death_Ragistration';
import Death_Record from './Amusement/Death_Record';
import Death_Ragistration_center from './Amusement/Death_Ragistration_center';
import About_Kolkata from './pages/About_Kolkata';
import Amusement_Parks from './Amusement/Amusement_Parks';
import Basic_statestics from './Amusement/Basic_statestics';
import History_Of_Kolkata from './pages/History_Of_Kolkata';
import KMC_Department from './pages/KMC_Department';
import Map from './pages/Map';
import Architecture from './pages/Architecture';
import Art_Galleries from './pages/Art_Galleries';
import Other_Noteable_place from './pages/Other_Noteable_place'
import Places_Of_Interest from './pages/Places_Of_Interest'
import KMC_Central_records_Home from './pages/KMC_Central_records_Home'
import KMC_Central_records_Fees from './pages/KMC_Central_records_Fees'
import KMC_Bustee_service_contact_details from './pages/KMC_Bustee_service_contact_details'
import KMC_Bustee_services from './pages/KMC_Bustee_services'
import Check_Demands_Payable from './pages/Check_Demands_Payable'
import Check_Demands_Status from './pages/Check_Demands_Status'
import Download_Forms from './pages/Download_Forms'
import Find_Plumbers from './pages/Find_Plumbers'
import List_Of_Tenders from './pages/Tenders/List_Of_Tenders'
import Make_Online_Payment from './pages/Make_Online_Payment'
import Print_Unpaid_Bill from './pages/Print_Unpaid_Bill'
import Reprint_Eprint from './pages/Reprint_Eprint'
import Tender_Batra from './pages/Tenders/Tender_Batra'
import Water_For_Fire_Fighter from './pages/Water_For_Fire_Fighter'
import Water_Supply from './pages/Water_Supply'
import Water_Supply_Fee_Charges from './pages/Water_Supply_Fee_Charges'

import WaterStations from './pages/WaterStations'
import BSUP from './pages/BSUP'
import KMC_Bustee_Services_Check_Demands_Payable from './pages/KMC_Bustee_Services_Check_Demands_Payable'
import Bustee_Services_Make_Online_Payment from './pages/Bustee_Services_Make_Online_Payment'
import Central_Records_Fees from './pages/Central_Records_Fees'
import Drainage_Contact_Details from './pages/Drainage_Contact_Details'
import KMC_Drainage_Activity from './pages/KMC_Drainage_Activity'
import Drainage_Reprint_e_Receipt from './Drainage/Drainage_Reprint_e_Receipt'
import Drainage_Sewerage_Treatment_Plants from './pages/Drainage_Sewerage_Treatment_Plants'
import Citizen_services from './pages/Citizen_services'
import KMC_Advertisement from './pages/KMC_Advertisement'
import KMC_Advertisement_unpaid_advertisement_fee_search from './pages/KMC_Advertisement_unpaid_advertisement_fee_search'
import KMC_Advertisement_reprint_e_receipts from './pages/KMC_Advertisement_reprint_e_receipts'
import KMC_Advertisement_make_Online_Payment from './pages/KMC_Advertisement_make_Online_Payment'
import KMC_Advertisement_know_your_agent_code from './pages/KMC_Advertisement_know_your_agent_code'
import KMC_Advertisement_Indicative_tax_calculator from './pages/KMC_Advertisement_Indicative_tax_calculator'
import Advertisement_citizen_login from './pages/Advertisement_citizen_login'
import CH_VS_Dept_Contact_Details from './ch_vs_dept/CH_VS_Dept_Contact_Details'
import CH_VS_Dept_Download_Forms from './ch_vs_dept/CH_VS_Dept_Download_Forms'
import CH_VS_Dept_Make_online_payment from './ch_vs_dept/CH_VS_Dept_Make_online_payment'
import CH_VS_Dept_Print_Unpaid_Bill from './ch_vs_dept/CH_VS_Dept_Print_Unpaid_Bill'
import Ch_VS_Dept_Rates_Charges from './ch_vs_dept/Ch_VS_Dept_Rates_Charges'
import CH_VS_Dept_Reprint_e_Receipt_Development_Fee from './ch_vs_dept/CH_VS_Dept_Reprint_e_Receipt_Development_Fee'
import CH_VS_Dept_Reprint_e_Receipt_GIS_Map from './ch_vs_dept/CH_VS_Dept_Reprint_e_Receipt_GIS_Map'
import CH_VS_Dept_Reprint_e_Receipt_SOR from './ch_vs_dept/CH_VS_Dept_Reprint_e_Receipt_SOR'
import Drainage_Check_Demands_Payable from './Drainage/Drainage_Check_Demands_Payable'
import Drainage_Fees_Charges from './Drainage/Drainage_Fees_Charges'
import Drainage_Print_Unpaid_Bill from './Drainage/Drainage_Print_Unpaid_Bill'
import Drainage_Ward_wise_drainage_map from './Drainage/Drainage_Ward_wise_drainage_map'
import Education_Dept_Contact_Details from './pages/Education_Dept_Contact_Details'
import Engineering_Contact_Details from './pages/Engineering_Contact_Details'
import Engineering_Home from './Engineering/Engineering_Home'
import Engineering_Rates_Charges from './Engineering/Engineering_Rates_Charges'

import Engineering_Manhole_Desilting_Programme from './Engineering/Engineering_Manhole_Desilting_Programme'
import Engi_Man_Desil_Prog_civil_Bro_30_6_2024 from './Engineering/Engi_Man_Desil_Prog_civil_Bro_30_6_2024'
import Schedule_Of_Fees from './Solid_Waste_Management/Schedule_Of_Fees'
import Solid_waste_management_faqs from './Solid_Waste_Management/Solid_waste_management_faqs'
import Solid_wastes_home from './Solid_Waste_Management/Solid_wastes_home'
import Solid_Waste_water_supply_faqs from './Solid_Waste_Management/Solid_Waste_water_supply_faqs'
import Heritage_Conservation_Architect from './Environment_&_Heritage/Heritage_Conservation_Architect'
import Heritage_Contact_Details from './Environment_&_Heritage/Heritage_Contact_Details'
import Heritage_Exemption_of_Tax from './Environment_&_Heritage/Heritage_Exemption_of_Tax'

import HeritageBuildingHome from './Environment_&_Heritage/HeritageBuildingHome'
import HeritageBuildingHome2 from './Environment_&_Heritage/HeritageBuildingHome2'
import HeritageBuildingHome3 from './Environment_&_Heritage/HeritageBuildingHome3'
import HeritageBuildingHome4 from './Environment_&_Heritage/HeritageBuildingHome4'

import Heritage_Heritage_Buildings from './Environment_&_Heritage/Heritage_Heritage_Buildings'
import Heritage_Proceedings_of_HCC from './Environment_&_Heritage/Heritage_Proceedings_of_HCC'
import Heritage_Wardwise_Heritage_Buildings from './Environment_&_Heritage/Heritage_Wardwise_Heritage_Buildings'
import Heritage_Wardwise_Pond from './Environment_&_Heritage/Heritage_Wardwise_Pond'
import Market_Check_demands_payable from './Market/Market_Check_demands_payable'
import Download_market_fire_fighting_video from './Market/Download_market_fire_fighting_video'
import KMC_market_list from './Market/KMC_market_list'
import Market_stall_search from './Market/Market_stall_search'
import Market_list_of_e_receipts from './Market/Market_list_of_e_receipts'
import Maps_of_market from './Market/Maps_of_market'
import Misclennious_demand from './Market/Misclennious_demand'
import Regular_Demand from './Market/Regular_Demand'
import Tender from './Market/Tender'
import Office_Order from './Parks_Squares/Office_Order'
import Parks_and_gardens from './Parks_Squares/Parks_and_gardens'
import Social_welfare_Download_Forms from './Social_Welfare_Urban/Social_welfare_Download_Forms'
import Govt_Sponsored_Schemes from './Social_Welfare_Urban/Govt_Sponsored_Schemes'
import GR_Recipients from './Social_Welfare_Urban/GR_Recipients'
import Ward_Wise_BPL_List from './Social_Welfare_Urban/Ward_Wise_BPL_List'
import Housing_Urban_Poor_Form_and_Site_Plan from './Social_Welfare_Urban/Housing_Urban_Poor_Form_and_Site_Plan'
import Drainage_Home from './Drainage/Drainage_Home'
import Drainage_Faqs from './Drainage/Drainage_Faqs'
import List_of_KMC_Drainage_Activity from './Drainage/List_of_KMC_Drainage_Activity'
import List_of_Sewage_Treatment_Plants from './Drainage/List_of_Sewage_Treatment_Plants'
import Drainage_Make_Online_Payment from './Drainage/Drainage_Make_Online_Payment'
import Health_Ambulances from './Health/Health_Ambulances'
import Health_Chest_Clinincs from './Health/Health_Chest_Clinincs'
import Health_Maternity_Homes from './Health/Health_Maternity_Homes'
import Health_KMC_Dispensaries from './Health/Health_KMC_Dispensaries'
import Health_Malaria_CLinics from './Health/Health_Malaria_CLinics'
import Health_MTMTB_Hospital from './Health/Health_MTMTB_Hospital'
import Health_services_Health_Home from './Health/Health_services_Health_Home'
import Health_Antirabies_VACCINATION_Center from './Health/Health_Antirabies_VACCINATION_Center'
import Health_Burning_Ghat_Status from './Health/Health_Burning_Ghat_Status'
import Health_Desease_control from './Health/Health_Desease_control'
import Health_Designation_Activities from './Health/Health_Designation_Activities'
import Health_Fees_Charges from './Health/Health_Fees_Charges'
import Health_KMDA_Health_Projects from './Health/Health_KMDA_Health_Projects'
import Health_Slaughter_House from './Health/Health_Slaughter_House'
import Health_Water_Test_e_Receipt from './Health/Health_Water_Test_e_Receipt'

import Lighting_Fees from './Lighting/Lighting_Fees'
import Lighting_Home from './Lighting/Lighting_Home'

import Assessment_collection_Check_Mutaion_Status from './Assessment_Collection/Assessment_collection_Check_Mutaion_Status'
import Assessment_collection_Search_Hearing_Notice from './Assessment_Collection/Assessment_collection_Search_Hearing_Notice'
import Assessment_collection_Application_uptp_intrest_waiver from './Assessment_Collection/Assessment_collection_Application_uptp_intrest_waiver'
import Assessment_collection_download_forms from './Assessment_Collection/Assessment_collection_download_forms'
import Assessment_collection_Message_Board from './Assessment_Collection/Assessment_collection_Message_Board'
import Assessment_collection_Notice from './Assessment_Collection/Assessment_collection_Notice'
import Assessment_collection_Process_Muation from './Assessment_Collection/Assessment_collection_Process_Muation'
import Land_Records_Burial_Grounds from './Health/Land_Records_Burial_Grounds'
import Assessment_Collection_Assessment_Home from './Assessment_Collection/Assessment_Collection_Assessment_Home'
import Assessment_Collection_Unit_Area_Assessment from './Assessment_Collection/Assessment_Collection_Unit_Area_Assessment'
import Assessment_Collection_FAQ from './Assessment_Collection/Assessment_Collection_FAQ'
import Assessment_collection_Dwonload_NOC from './Assessment_Collection/Assessment_collection_Dwonload_NOC'
import Assessment_collection_Check_Payment_Status from './Assessment_Collection/Assessment_collection_Check_Payment_Status'
import Assessment_collection_Check_Demands_Payable from './Assessment_Collection/Assessment_collection_Check_Demands_Payable'
import Certificate_of_Enlistment from './Licence/Certificate_of_Enlistment'
import Licence_download_form from './Licence/Licence_download_form'
import Current_Circular_Notes_Guidelines from './Licence/Current_Circular_Notes_Guidelines'
import Duplicate_Demand_Issuing_Offices from './Licence/Duplicate_Demand_Issuing_Offices'
import List_of_unit_offices from './Licence/List_of_unit_offices'
import Requirements_For_Obtaining_CE from './Licence/Requirements_For_Obtaining_CE'
import Licence_Chec_Demands_Payable from './Licence/Licence_Chec_Demands_Payable'
import Licence_Make_Online_Payment from './Licence/Licence_Make_Online_Payment'
import Licence_E_Receipt_cum_CE from './Licence/Licence_E_Receipt_cum_CE'
import User_login_Online_Application_For_Change from './Licence/User_login_Online_Application_For_Change'
import Licence_View_CE_Information from './Licence/Licence_View_CE_Information'
import Car_Parking_Home from './Car_Parking/Car_Parking_Home'
import Car_Parking_Rate_Chart from './Car_Parking/Car_Parking_Rate_Chart'
import Car_Parking_FAQ from './Car_Parking/Car_Parking_FAQ'
import Building_Sanction_Building_Home from './Building_sanction/Building_Sanction_Building_Home'
import Building_Sanction_Circular_2013_14 from './Building_sanction/Building_Sanction_Circular_2013_14'
import Building_Sanction_Circular_2014_15 from './Building_sanction/Building_Sanction_Circular_2014_15'
import Building_Sanction_Circular_2015_16 from './Building_sanction/Building_Sanction_Circular_2015_16'
import Building_Sanction_Circular_2016_17 from './Building_sanction/Building_Sanction_Circular_2016_17'
import Building_Sanction_Circular_2017_18 from './Building_sanction/Building_Sanction_Circular_2017_18'
import Building_Sanction_Circular_2019_20 from './Building_sanction/Building_Sanction_Circular_2019_20'
import Building_Sanction_Circular_2021_22 from './Building_sanction/Building_Sanction_Circular_2021_22'
import Building_Sanction_Circular_2022_23 from './Building_sanction/Building_Sanction_Circular_2022_23'
import Building_Sanction_Circular_2020_21 from './Building_sanction/Building_Sanction_Circular_2020_21'
import Building_Sanction_Borough_Offices from './Building_sanction/Building_Sanction_Borough_Offices'
import Building_Sanction_Circular_2010_11 from './Building_sanction/Building_Sanction_Circular_2010_11'
import Building_Sanction_Circular_2011_12 from './Building_sanction/Building_Sanction_Circular_2011_12'
import Building_Sanction_Floor_Area_Ratio from './Building_sanction/Building_Sanction_Floor_Area_Ratio'
import Building_Sanction_Ground_Covergae from './Building_sanction/Building_Sanction_Ground_Covergae'
import Building_Sanction_Download_Forms from './Building_sanction/Building_Sanction_Download_Forms'
import Building_Sanction_Important_EODB_circulars from './Building_sanction/Building_Sanction_Important_EODB_circulars'
import Building_Sanction_Communique from './Building_sanction/Building_Sanction_Communique'
import Officer_Order from './Building_sanction/Officer_Order'
import Building_OfficeOreder_2020 from './Building_sanction/Office-Order/Building_OfficeOreder_2020'
import Building_OfficeOreder_2019 from './Building_sanction/Office-Order/Building_OfficeOreder_2019'
import Building_OfficeOreder_2018 from './Building_sanction/Office-Order/Building_OfficeOreder_2018'
import Building_OfficeOreder_2017 from './Building_sanction/Office-Order/Building_OfficeOreder_2017'
import Building_OfficeOreder_2016 from './Building_sanction/Office-Order/Building_OfficeOreder_2016'
import Building_OfficeOreder_2015 from './Building_sanction/Office-Order/Building_OfficeOreder_2015'
import Building_OfficeOreder_2014 from './Building_sanction/Office-Order/Building_OfficeOreder_2014'
import Building_OfficeOreder_2013 from './Building_sanction/Office-Order/Building_OfficeOreder_2013'
import Building_OfficeOreder_2012 from './Building_sanction/Office-Order/Building_OfficeOreder_2012'
import Building_OfficeOreder_2011 from './Building_sanction/Office-Order/Building_OfficeOreder_2011'
import Building_OfficeOreder_2010 from './Building_sanction/Office-Order/Building_OfficeOreder_2010'

import Central_records_home from './Central_records/Central_records_home'
import Central_records_fee from './Central_records/Central_records_fee'
import Kmc_school_home from './KMC_School/Kmc_school_home'
import Urgent_Notice from './KMC_School/Urgent_Notice'
import KMC_School_Faq from './KMC_School/KMC_School_Faq'
import Auction from './pages/Auction'
import Download_Form from './pages/Download_Form'
import KMC_Recruitments from './Recruitments/KMC_Recruitments'
import Borough_Committee from './Borough_Committee/Borough_Committee'
import Councillors from './Borough_Committee/Councillors'
import KMCCrematorium from './Borough_Committee/KMCCrematorium'
import KMC_Results from './Recruitments/KMC_Results'
import KMC_Training from './Recruitments/KMC_Training'
import RTI_List_of_1st_Appellate_Authorities from './RTI/RTI_List_of_1st_Appellate_Authorities'
import RTI_List_of_State_Public_Information_Officers from './RTI/RTI_List_of_State_Public_Information_Officers'
import RTI_Office_Order_Circular from './RTI/RTI_Office_Order_Circular'
import RTI_Act_2005 from './RTI/RTI_Act_2005'
import Rti_act_2005_ad from './RTI/Rti-act-2005/Rti_act_2005_ad'
import Rti_act_2005_amd from './RTI/Rti-act-2005/Rti_act_2005_amd'
import Rti_act_2005_md from './RTI/Rti-act-2005/Rti_act_2005_md'
import Rti_act_2005_msd from './RTI/Rti-act-2005/Rti_act_2005_msd'
import Rti_act_2005_ard from './RTI/Rti-act-2005/Rti_act_2005_ard'
import Rti_act_2005_psd from './RTI/Rti-act-2005/Rti_act_2005_psd'
import Rti_act_2005_acd from './RTI/Rti-act-2005/Rti_act_2005_acd'
import Rti_act_2005_pd from './RTI/Rti-act-2005/Rti_act_2005_pd'
import Rti_act_2005_bd from './RTI/Rti-act-2005/Rti_act_2005_bd'
import Rti_act_2005_prd from './RTI/Rti-act-2005/Rti_act_2005_prd'
import Rti_act_2005_bsd from './RTI/Rti-act-2005/Rti_act_2005_bsd'
import Rti_act_2005_pld from './RTI/Rti-act-2005/Rti_act_2005_pld'
import Rti_act_2005_cpd from './RTI/Rti-act-2005/Rti_act_2005_cpd'
import Rti_act_2005_ehd from './RTI/Rti-act-2005/Rti_act_2005_ehd'
import Rti_act_2005_cvs from './RTI/Rti-act-2005/Rti_act_2005_cvs'
import Rti_act_2005_pfd from './RTI/Rti-act-2005/Rti_act_2005_pfd'
import Rti_act_2005_cmatpd from './RTI/Rti-act-2005/Rti_act_2005_cmatpd'
import Rti_act_2005_rd from './RTI/Rti-act-2005/Rti_act_2005_rd'
import Rti_act_2005_cmed from './RTI/Rti-act-2005/Rti_act_2005_cmed'
import Rti_act_2005_sd from './RTI/Rti-act-2005/Rti_act_2005_sd'
import Rti_act_2005_ed from './RTI/Rti-act-2005/Rti_act_2005_ed'
import Rti_act_2005_swm from './RTI/Rti-act-2005/Rti_act_2005_swm'
import Rti_act_2005_fad from './RTI/Rti-act-2005/Rti_act_2005_fad'
import Rti_act_2005_swmII from './RTI/Rti-act-2005/Rti_act_2005_swmII'
import Rti_act_2005_gid from './RTI/Rti-act-2005/Rti_act_2005_gid'
import Rti_act_2005_swupad from './RTI/Rti-act-2005/Rti_act_2005_swupad'
import Rti_act_2005_health from './RTI/Rti-act-2005/Rti_act_2005_health'
import Rti_act_2005_srac from './RTI/Rti-act-2005/Rti_act_2005_srac'
import Rti_act_2005_itd from './RTI/Rti-act-2005/Rti_act_2005_itd'
import Rti_act_2005_sqd from './RTI/Rti-act-2005/Rti_act_2005_sqd'
import Rti_act_2005_kd from './RTI/Rti-act-2005/Rti_act_2005_kd'
import Rti_act_2005_spd from './RTI/Rti-act-2005/Rti_act_2005_spd'
import Rti_act_2005_wsd from './RTI/Rti-act-2005/Rti_act_2005_wsd'
import Rti_act_2005_led from './RTI/Rti-act-2005/Rti_act_2005_led'

import RTI_Important_Points_for_the_RTI_Applicants from './RTI/RTI_Important_Points_for_the_RTI_Applicants'
import MC_Circular_2022_23 from './Circular/MC_Circular_2022_23'
import MC_Circular_2023_24 from './Circular/MC_Circular_2023_24'
import MC_Circular_2021_22 from './Circular/MC_Circular_2021_22'
import MC_Circular_2020_21 from './Circular/MC_Circular_2020_21'
import MC_Circular_2019_20 from './Circular/MC_Circular_2019_20'
import MC_Circular_2018_19 from './Circular/MC_Circular_2018_19'
import MC_Circular_2017_18 from './Circular/MC_Circular_2017_18'
import Govt_memo from './Circular/Govt_memo'


import Advertisement_Dept from './Contact_Us/Advertisement_Dept'
import Amusement_Dept from './Contact_Us/Amusement_Dept'
import Assessment_Dept from './Contact_Us/Assessment_Dept'
import Bustee_Dept from './Contact_Us/Bustee_Dept'
import Car_Parking_Dept from './Contact_Us/Car_Parking_Dept'
import Chief_Eng_Dept from './Contact_Us/Chief_Eng_Dept'
import Ch_VS_Dept from './Contact_Us/Ch_VS_Dept'
import Contact_Us_Home from './Contact_Us/Contact_Us_Home'
import EWaste from './Contact_Us/EWaste'

import Education_Dept from './Contact_Us/Education_Dept'
import Sewerage_Drainage_Dept from './Contact_Us/Sewerage_Drainage_Dept'
import Mpl_Secretary_Dept from './Contact_Us/Mpl_Secretary_Dept'
import Parks_Square_Dept from './Contact_Us/Parks_Square_Dept'
import Social_Welfare_Urban_Dept from './Contact_Us/Social_Welfare_Urban_Dept'
import Town_Planning_Development_Dept from './Contact_Us/Town_Planning_Development_Dept'
import Treasury_Dept from './Contact_Us/Treasury_Dept'
import Drainage_Drainage_Pumping_station from './Drainage/Drainage_Drainage_Pumping_station'

import Electricity_Dept from './Contact_Us/Electricity_Dept'
import Environment_Heritage_Dept from './Contact_Us/Environment_Heritage_Dept'
import Finance_Dept from './Contact_Us/Finance_Dept'
import Health_Dept from './Contact_Us/Health_Dept'
import IT_Dept from './Contact_Us/IT_Dept'
import Internal_Audit_Dept from './Contact_Us/Internal_Audit_Dept'
import Law_Dept from './Contact_Us/Law_Dept'
import Licence_Dept from './Contact_Us/Licence_Dept'
import Lighting_Dept from './Contact_Us/Lighting_Dept'
import Market_Dept from './Contact_Us/Market_Dept'
import Personnel_Dept from './Contact_Us/Personnel_Dept'
import Roads_Dept from './Contact_Us/Roads_Dept'
import Solid_Waste_Dept from './Contact_Us/Solid_Waste_Dept'
import Water_Supply_Dept from './Contact_Us/Water_Supply_Dept'
import MC_Circular_2016_17 from './Circular/MC_Circular_2016_17'
import MC_Circular_2015_16 from './Circular/MC_Circular_2015_16'
import MC_Circular_2004_05 from './Circular/MC_Circular_2004_05'
import CMF_Communique_2022_23 from './Circular/CMF_Communique_2022_23'
import Chief_Manager_Circular from './Circular/Chief_Manager_Circular'
import Municipal_secretary_circular from './Circular/Municipal_secretary_circular'

import Procedure from './pages/Grievance Redressal/Procedure'
import TERM_Cell from './pages/Grievance Redressal/TERM_Cell'
import Pollution_Control_Board from './pages/Grievance Redressal/Pollution_Control_Board'

import CBP_Home from './Project/CBP_Home'
import Vision_Strategy from './Project/Vision_Strategy'
import Project_Faqs from './Project/Project_Faqs'
import Citizen_charter_Home from './Charter/Citizen_charter_Home'
import Citizen_Charter_Ads from './Charter/Citizen_Charter_Ads'
import President_with_Charters from './Charter/President_with_Charters'
import Contact_Us_Homes from './Borough_Committee/Contact_Us_Homes'
import Controlling_Officers from './Borough_Committee/Controlling_Officers'
import MMIC from './Borough_Committee/MMIC'

import Important_Contacts from './Borough_Committee/Important_Contacts'
import Know_Your_Ward from './Borough_Committee/Know_Your_Ward'
import Building_Faqs from './Building_sanction/Building_Faqs'
import Licence_Faq from './Licence/Licence_Faq'
import Faqs from './Footer_Links/Faqs'
import Disclaimer from './Footer_Links/Disclaimer'
import Quick_Links from './pages/Quick_Links'
import Water_Supply_Faqs from './pages/Water_Supply_Faqs'
import E_News_Letter from './Publication/E_News_Letter'
import E_Purashree from './Publication/E_Purashree'
import Archive from './Publication/Archive'
import Monsoon_Book from './Publication/Monsoon_Book'
import Disaster_Mgmt from './UEVRP/Disaster_Mgmt'
import Concept_Note from './UEVRP/Concept_Note'
import Guidelines_for_Safety from './UEVRP/Guidelines_for_Safety'
import Form_For_Inventory from './UEVRP/Form_For_Inventory'
import School_Safety_Booklet from './UEVRP/School_Safety_Booklet'
import Colouring_Activity_Book from './UEVRP/Colouring_Activity_Book'
import UEVRP_Home from './UEVRP/UEVRP_Home'
import UEVRP_Status from './UEVRP/UEVRP_Status'
import Earthquake_Leaflet from './UEVRP/Earthquake_Leaflet'
import EQ_Pocket_Guide from './UEVRP/EQ_Pocket_Guide'
import Dashboard from './UEVRP/Dashboard'
import Dashboard2 from './UEVRP/Dashboard2'
import Wathsapp from './pages/Wathsapp'
import Gallery from './pages/Gallery'

 import "./css/variables.css"
 import "./css/main.css"
 import "./css/style.css"
// import "./js/main.js"
//  import "./js/jquery_min.js"
import React from 'react';
import './App.css';

	
 //import "./vendor/bootstrap/css/bootstrap.min.css"
 import "./vendor/bootstrap-icons/bootstrap-icons.css"
 import "./vendor/aos/aos.css"
 import "./vendor/glightbox/css/glightbox.min.css"
 import "./vendor/swiper/swiper-bundle.min.css"
import NavBar from './components/NavBar';
import CarouselNav from './components/Carousel';
import { useLocation } from "react-router-dom";
import CMF_Communique_2022_24 from './Circular/CMF_Communique_2023_24';
import CMF_Communique_2023_24 from './Circular/CMF_Communique_2023_24';
import Rti_act_2005_ld from './RTI/Rti-act-2005/Rti_act_2005_ld';
import MC_Circular_2024_25 from './Circular/MC_Circular_2024_25';


function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

 function App() {
  useScrollToTop()
  return (
    <div className="App">
      <>
	<script src=
"https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"
	type="text/javascript" />

   <Header />
      <NavBar id="google_translate_element"/>
          <Routes>
            <Route path="/portal" element={<Home />} />
            <Route path="/" element={<Navigate to ="/portal" />} />
            <Route path="/Adminstration" element={<Adminstration />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/MayorsOfKolkata" element={<MayorsOfKolkata />} />
            <Route path="/MunicipalHistory" element={<MunicipalHistory />} />
            <Route path="/Overview" element={<Overview />} />
            <Route path="/Power" element={<Power />} />
            <Route path="/EKolkataCenter" element={<EKolkataCenter />} />
            <Route path="/Functions" element={<Fucntions />} />
            <Route path="/KMCFunctions" element={<KMCFunctions />} />
            <Route path="/Revenue" element={<Revenue />} />
            <Route path="/Emblem" element={<Emblem />} />
            <Route path="/KMCBudget" element={<KMCBudget />} />
            <Route path="/KMCDepartments" element={<KMCDepartments />} />
            <Route path="/Basic_statestics" element={<Basic_statestics />} />
            <Route path="/CitizenLogin" element={<CitizenLogin />} />
            <Route path="/Advertisement_download_forms" element={<Advertisement_download_forms />} /> 
            <Route path="/Advertisement_Dept" element={<Advertisement_Dept />} /> 
            <Route path="/Advertisement_ListOf_Hoarding" element={<Advertisement_ListOf_Hoarding />} /> 
            <Route path="/Advertisement_rates" element={<Advertisement_rates />} /> 
            <Route path="/Amusement_download_forms" element={<Amusement_download_forms />} /> 
            <Route path="/Amusement" element={<Amusement />} /> 
            <Route path="/Amusement_important_officials" element={<Amusement_important_officials />} /> 
            <Route path="/Birth_Ragistration_center" element={<Birth_Ragistration_center />} /> 
            <Route path="/Birth_Death_Ragistration" element={<Birth_Death_Ragistration />} /> 
            <Route path="/Death_Record" element={<Death_Record />} /> 
            <Route path="/Death_Ragistration_center" element={<Death_Ragistration_center />} /> 
            <Route path="/About_Kolkata" element={<About_Kolkata />} /> 
            <Route path="/Amusement_Parks" element={<Amusement_Parks />} /> 
            <Route path="/Basic_statestics" element={<Basic_statestics />} /> 
            <Route path="/History_Of_Kolkata" element={<History_Of_Kolkata />} /> 
            <Route path="/KMC_Department" element={<KMC_Department />} /> 
            <Route path="/Map" element={<Map />} /> 
            <Route path="/Architecture" element={<Architecture />} /> 
            <Route path="/Art_Galleries" element={<Art_Galleries />} />
            <Route path="/Other_Noteable_place" element={<Other_Noteable_place />} /> 
            <Route path="/Places_Of_Interest" element={<Places_Of_Interest />} /> 
            <Route path="/KMC_Central_records_Home" element={<KMC_Central_records_Home />} /> 
            <Route path="/KMC_Central_records_Fees" element={<KMC_Central_records_Fees />} /> 

            <Route path="/KMC_Bustee_service_contact_details" element={<KMC_Bustee_service_contact_details />} /> 
            <Route path="/KMC_Bustee_services" element={<KMC_Bustee_services />} /> 
            <Route path="/Check_Demands_Payable" element={<Check_Demands_Payable />} /> 
            <Route path="/Check_Demands_Status" element={<Check_Demands_Status />} /> 
            <Route path="/Download_Forms" element={<Download_Forms />} /> 
            <Route path="/Find_Plumbers" element={<Find_Plumbers />} /> 
            <Route path="/List_Of_Tenders" element={<List_Of_Tenders />} /> 
            <Route path="/Make_Online_Payment" element={<Make_Online_Payment />} /> 
            <Route path="/Print_Unpaid_Bill" element={<Print_Unpaid_Bill />} /> 
            <Route path="/Reprint_Eprint" element={<Reprint_Eprint />} /> 
            <Route path="/Tender_Batra" element={<Tender_Batra />} /> 
            <Route path="/Water_For_Fire_Fighter" element={<Water_For_Fire_Fighter />} /> 
            <Route path="/Water_Supply" element={<Water_Supply />} />
            <Route path="/Water_Supply_Fee_Charges" element={<Water_Supply_Fee_Charges />} />

            <Route path="/WaterStations" element={<WaterStations />} /> 
            <Route path="/BSUP" element={<BSUP />} /> 
 
            <Route path="/KMC_Bustee_Services_Check_Demands_Payable" element={<KMC_Bustee_Services_Check_Demands_Payable />} /> 
            <Route path="/Bustee_Services_Make_Online_Payment" element={<Bustee_Services_Make_Online_Payment />} /> 
            <Route path="/Central_Records_Fees" element={<Central_Records_Fees />} /> 
            <Route path="/Drainage_Contact_Details" element={<Drainage_Contact_Details />} /> 
            <Route path="/KMC_Drainage_Activity" element={<KMC_Drainage_Activity />} /> 
            <Route path="/Drainage_Make_Online_Payment" element={<Drainage_Make_Online_Payment />} />
            <Route path="/Drainage_Reprint_e_Receipt" element={<Drainage_Reprint_e_Receipt />} />
            <Route path="/Drainage_Sewerage_Treatment_Plants" element={<Drainage_Sewerage_Treatment_Plants />} />
            <Route path="/Citizen_services" element={<Citizen_services />} />
            <Route path="/KMC_Advertisement" element={<KMC_Advertisement />} />
            <Route path="/KMC_Advertisement_unpaid_advertisement_fee_search" element={<KMC_Advertisement_unpaid_advertisement_fee_search />} />
            <Route path="/KMC_Advertisement_reprint_e_receipts" element={<KMC_Advertisement_reprint_e_receipts />} />
            <Route path="/KMC_Advertisement_make_Online_Payment" element={<KMC_Advertisement_make_Online_Payment />} />
            <Route path="/KMC_Advertisement_know_your_agent_code" element={<KMC_Advertisement_know_your_agent_code />} />
            <Route path="/KMC_Advertisement_Indicative_tax_calculator" element={<KMC_Advertisement_Indicative_tax_calculator />} />
            <Route path="/Advertisement_citizen_login" element={<Advertisement_citizen_login />} />
            <Route path="/CH_VS_Dept_Contact_Details" element={<CH_VS_Dept_Contact_Details />} />
            <Route path="/CH_VS_Dept_Download_Forms" element={<CH_VS_Dept_Download_Forms />} />
            <Route path="/CH_VS_Dept_Make_online_payment" element={<CH_VS_Dept_Make_online_payment />} />
            <Route path="/CH_VS_Dept_Print_Unpaid_Bill" element={<CH_VS_Dept_Print_Unpaid_Bill />} />
            <Route path="/Ch_VS_Dept_Rates_Charges" element={<Ch_VS_Dept_Rates_Charges />} />
            <Route path="/CH_VS_Dept_Reprint_e_Receipt_Development_Fee" element={<CH_VS_Dept_Reprint_e_Receipt_Development_Fee />} />
            <Route path="/CH_VS_Dept_Reprint_e_Receipt_GIS_Map" element={<CH_VS_Dept_Reprint_e_Receipt_GIS_Map />} />
            <Route path="/CH_VS_Dept_Reprint_e_Receipt_SOR" element={<CH_VS_Dept_Reprint_e_Receipt_SOR />} />
            <Route path="/Drainage_Check_Demands_Payable" element={<Drainage_Check_Demands_Payable />} />
            <Route path="/Drainage_Fees_Charges" element={<Drainage_Fees_Charges />} />
            <Route path="/Drainage_Print_Unpaid_Bill" element={<Drainage_Print_Unpaid_Bill />} />
            <Route path="/Drainage_Ward_wise_drainage_map" element={<Drainage_Ward_wise_drainage_map />} />
            <Route path="/Education_Dept_Contact_Details" element={<Education_Dept_Contact_Details />} />
            <Route path="/Engineering_Contact_Details" element={<Engineering_Contact_Details />} />
            <Route path="/Engineering_Home" element={<Engineering_Home />} />
            <Route path="/Engineering_Rates_Charges" element={<Engineering_Rates_Charges />} />

            <Route path="/Engineering_Manhole_Desilting_Programme" element={<Engineering_Manhole_Desilting_Programme />} />
            <Route path="/Engi_Man_Desil_Prog_civil_Bro_30_6_2024" element={<Engi_Man_Desil_Prog_civil_Bro_30_6_2024 />} />
            <Route path="/Schedule_Of_Fees" element={<Schedule_Of_Fees />} />
            <Route path="/Solid_waste_management_faqs" element={<Solid_waste_management_faqs />} />
            <Route path="/Solid_wastes_home" element={<Solid_wastes_home />} />
            <Route path="/Solid_Waste_water_supply_faqs" element={<Solid_Waste_water_supply_faqs />} />
            <Route path="/Heritage_Conservation_Architect" element={<Heritage_Conservation_Architect />} />
            <Route path="/Heritage_Contact_Details" element={<Heritage_Contact_Details />} />
            <Route path="/Heritage_Exemption_of_Tax" element={<Heritage_Exemption_of_Tax />} />

            <Route path="/HeritageBuildingHome" element={<HeritageBuildingHome />} />
            <Route path="/HeritageBuildingHome2" element={<HeritageBuildingHome2 />} />
            <Route path="/HeritageBuildingHome3" element={<HeritageBuildingHome3 />} />
            <Route path="/HeritageBuildingHome4" element={<HeritageBuildingHome4 />} />


            <Route path="/Heritage_Heritage_Buildings" element={<Heritage_Heritage_Buildings />} />
            <Route path="/Heritage_Proceedings_of_HCC" element={<Heritage_Proceedings_of_HCC />} />
            <Route path="/Heritage_Wardwise_Heritage_Buildings" element={<Heritage_Wardwise_Heritage_Buildings />} />
            <Route path="/Heritage_Wardwise_Pond" element={<Heritage_Wardwise_Pond />} />
            <Route path="/Market_Check_demands_payable" element={<Market_Check_demands_payable />} />
            <Route path="/Download_market_fire_fighting_video" element={<Download_market_fire_fighting_video />} />
            <Route path="/KMC_market_list" element={<KMC_market_list />} />

            <Route path="/Market_stall_search" element={<Market_stall_search />} />
            <Route path="/Market_list_of_e_receipts" element={<Market_list_of_e_receipts />} />
            <Route path="/Maps_of_market" element={<Maps_of_market />} />
            <Route path="/Misclennious_demand" element={<Misclennious_demand />} />
            <Route path="/Regular_Demand" element={<Regular_Demand />} />
            <Route path="/Tender" element={<Tender />} />
            <Route path="/Office_Order" element={<Office_Order />} />
            <Route path="/Parks_and_gardens" element={<Parks_and_gardens />} />
            <Route path="/Social_welfare_Download_Forms" element={<Social_welfare_Download_Forms />} />
            <Route path="/Govt_Sponsored_Schemes" element={<Govt_Sponsored_Schemes />} />
            <Route path="/GR_Recipients" element={<GR_Recipients />} />
            <Route path="/Ward_Wise_BPL_List" element={<Ward_Wise_BPL_List />} />
            <Route path="/Housing_Urban_Poor_Form_and_Site_Plan" element={<Housing_Urban_Poor_Form_and_Site_Plan />} />
            <Route path="/Drainage_Home" element={<Drainage_Home />} />
            <Route path="/Drainage_Faqs" element={<Drainage_Faqs />} />
            <Route path="/List_of_KMC_Drainage_Activity" element={<List_of_KMC_Drainage_Activity />} />
            <Route path="/List_of_Sewage_Treatment_Plants" element={<List_of_Sewage_Treatment_Plants />} />
            <Route path="/Health_Ambulances" element={<Health_Ambulances />} />
            <Route path="/Health_Chest_Clinincs" element={<Health_Chest_Clinincs />} />
            <Route path="/Health_Maternity_Homes" element={<Health_Maternity_Homes />} />
            <Route path="/Health_KMC_Dispensaries" element={<Health_KMC_Dispensaries />} />
            <Route path="/Health_Malaria_CLinics" element={<Health_Malaria_CLinics />} />
            <Route path="/Health_MTMTB_Hospital" element={<Health_MTMTB_Hospital />} />
            <Route path="/Health_services_Health_Home" element={<Health_services_Health_Home />} />
            <Route path="/Health_Antirabies_VACCINATION_Center" element={<Health_Antirabies_VACCINATION_Center />} />
            <Route path="/Health_Burning_Ghat_Status" element={<Health_Burning_Ghat_Status />} />
            <Route path="/Health_Desease_control" element={<Health_Desease_control />} />
            <Route path="/Health_Designation_Activities" element={<Health_Designation_Activities />} />
            <Route path="/Health_Fees_Charges" element={<Health_Fees_Charges />} />
            <Route path="/Health_KMDA_Health_Projects" element={<Health_KMDA_Health_Projects />} />
            <Route path="/Health_Slaughter_House" element={<Health_Slaughter_House />} />
            <Route path="/Health_Water_Test_e_Receipt" element={<Health_Water_Test_e_Receipt />} />

            <Route path="/Lighting_Fees" element={<Lighting_Fees />} />
            <Route path="/Lighting_Home" element={<Lighting_Home />} />
            <Route path="/Assessment_collection_Search_Hearing_Notice" element={<Assessment_collection_Search_Hearing_Notice />} />
            <Route path="/Assessment_collection_Check_Mutaion_Status" element={<Assessment_collection_Check_Mutaion_Status />} />
            <Route path="/Assessment_collection_Application_uptp_intrest_waiver" element={<Assessment_collection_Application_uptp_intrest_waiver />} />
            <Route path="/Assessment_collection_download_forms" element={<Assessment_collection_download_forms />} />
            <Route path="/Assessment_collection_Message_Board" element={<Assessment_collection_Message_Board />} />
            <Route path="/Assessment_collection_Notice" element={<Assessment_collection_Notice />} />
            <Route path="/Assessment_collection_Process_Muation" element={<Assessment_collection_Process_Muation />} />
            <Route path="/Land_Records_Burial_Grounds" element={<Land_Records_Burial_Grounds />} />
            <Route path="/Assessment_Collection_Assessment_Home" element={<Assessment_Collection_Assessment_Home />} />
            <Route path="/Assessment_Collection_Unit_Area_Assessment" element={<Assessment_Collection_Unit_Area_Assessment />} />
            <Route path="/Assessment_Collection_FAQ" element={<Assessment_Collection_FAQ />} />
            <Route path="/Assessment_collection_Dwonload_NOC" element={<Assessment_collection_Dwonload_NOC />} />
            <Route path="/Assessment_collection_Check_Payment_Status" element={<Assessment_collection_Check_Payment_Status />} />
            <Route path="/Assessment_collection_Check_Demands_Payable" element={<Assessment_collection_Check_Demands_Payable />} />
            <Route path="/Certificate_of_Enlistment" element={<Certificate_of_Enlistment />} />
            <Route path="/Licence_download_form" element={<Licence_download_form />} />
            <Route path="/Current_Circular_Notes_Guidelines" element={<Current_Circular_Notes_Guidelines />} />
            <Route path="/Duplicate_Demand_Issuing_Offices" element={<Duplicate_Demand_Issuing_Offices />} />
            <Route path="/List_of_unit_offices" element={<List_of_unit_offices />} />
            <Route path="/Requirements_For_Obtaining_CE" element={<Requirements_For_Obtaining_CE />} />
            <Route path="/Licence_Chec_Demands_Payable" element={<Licence_Chec_Demands_Payable />} />
            <Route path="/Licence_Make_Online_Payment" element={<Licence_Make_Online_Payment />} />
            <Route path="/Licence_E_Receipt_cum_CE" element={<Licence_E_Receipt_cum_CE />} />
            <Route path="/User_login_Online_Application_For_Change" element={<User_login_Online_Application_For_Change />} />
            <Route path="/Licence_View_CE_Information" element={<Licence_View_CE_Information />} />
            <Route path="/Car_Parking_Home" element={<Car_Parking_Home />} />
            <Route path="/Car_Parking_Rate_Chart" element={<Car_Parking_Rate_Chart />} />
            <Route path="/Car_Parking_FAQ" element={<Car_Parking_FAQ />} />
            <Route path="/Building_Sanction_Building_Home" element={<Building_Sanction_Building_Home />} />
            <Route path="/Building_Sanction_Circular_2013_14" element={<Building_Sanction_Circular_2013_14 />} />
            <Route path="/Building_Sanction_Circular_2014_15" element={<Building_Sanction_Circular_2014_15 />} />
            <Route path="/Building_Sanction_Circular_2015_16" element={<Building_Sanction_Circular_2015_16 />} />
            <Route path="/Building_Sanction_Circular_2016_17" element={<Building_Sanction_Circular_2016_17 />} />
            <Route path="/Building_Sanction_Circular_2017_18" element={<Building_Sanction_Circular_2017_18 />} />
            <Route path="/Building_Sanction_Circular_2019_20" element={<Building_Sanction_Circular_2019_20 />} />
            <Route path="/Building_Sanction_Circular_2021_22" element={<Building_Sanction_Circular_2021_22 />} />
            <Route path="/Building_Sanction_Circular_2022_23" element={<Building_Sanction_Circular_2022_23 />} />
            <Route path="/Building_Sanction_Circular_2020_21" element={<Building_Sanction_Circular_2020_21 />} />
            <Route path="/Building_Sanction_Borough_Offices" element={<Building_Sanction_Borough_Offices />} />
            <Route path="/Building_Sanction_Circular_2010_11" element={<Building_Sanction_Circular_2010_11 />} />
            <Route path="/Building_Sanction_Circular_2011_12" element={<Building_Sanction_Circular_2011_12 />} />
            <Route path="/Govt_memo" element={<Govt_memo />} />

            <Route path="/Building_Sanction_Floor_Area_Ratio" element={<Building_Sanction_Floor_Area_Ratio />} />
            <Route path="/Building_Sanction_Ground_Covergae" element={<Building_Sanction_Ground_Covergae />} />
            <Route path="/Building_Sanction_Download_Forms" element={<Building_Sanction_Download_Forms />} />
            <Route path="/Building_Sanction_Important_EODB_circulars" element={<Building_Sanction_Important_EODB_circulars />} />
            <Route path="/Building_Sanction_Communique" element={<Building_Sanction_Communique />} />
            <Route path="/Officer_Order" element={<Officer_Order />} />
            <Route path="/Building_OfficeOreder_2020" element={<Building_OfficeOreder_2020 />} />
            <Route path="/Building_OfficeOreder_2019" element={<Building_OfficeOreder_2019 />} />
            <Route path="/Building_OfficeOreder_2018" element={<Building_OfficeOreder_2018 />} />
            <Route path="/Building_OfficeOreder_2017" element={<Building_OfficeOreder_2017 />} />
            <Route path="/Building_OfficeOreder_2016" element={<Building_OfficeOreder_2016 />} />
            <Route path="/Building_OfficeOreder_2015" element={<Building_OfficeOreder_2015 />} />
            <Route path="/Building_OfficeOreder_2014" element={<Building_OfficeOreder_2014 />} />
            <Route path="/Building_OfficeOreder_2013" element={<Building_OfficeOreder_2013 />} />
            <Route path="/Building_OfficeOreder_2012" element={<Building_OfficeOreder_2012 />} />
            <Route path="/Building_OfficeOreder_2011" element={<Building_OfficeOreder_2011 />} />
            <Route path="/Building_OfficeOreder_2010" element={<Building_OfficeOreder_2010 />} />

            <Route path="/Central_records_home" element={<Central_records_home />} />
            <Route path="/Central_records_fee" element={<Central_records_fee />} />
            <Route path="/Kmc_school_home" element={<Kmc_school_home />} />
            <Route path="/Urgent_Notice" element={<Urgent_Notice />} />
            <Route path="/KMC_School_Faq" element={<KMC_School_Faq />} />
            <Route path="/Auction" element={<Auction />} />
            <Route path="/Download_Form" element={<Download_Form />} />
            <Route path="/KMC_Recruitments" element={<KMC_Recruitments />} />
            <Route path="/Borough_Committee" element={<Borough_Committee />} />
            <Route path="/Councillors" element={<Councillors />} />
            <Route path="/KMCCrematorium" element={<KMCCrematorium />} />
            <Route path="/KMC_Results" element={<KMC_Results />} />
            <Route path="/KMC_Training" element={<KMC_Training />} />
            <Route path="/RTI_List_of_1st_Appellate_Authorities" element={<RTI_List_of_1st_Appellate_Authorities />} />
            <Route path="/RTI_List_of_State_Public_Information_Officers" element={<RTI_List_of_State_Public_Information_Officers />} />
            <Route path="/RTI_Office_Order_Circular" element={<RTI_Office_Order_Circular />} />
            <Route path="/RTI_Act_2005" element={<RTI_Act_2005 />} />
            <Route path="/Rti_act_2005_ad" element={<Rti_act_2005_ad />} />
            <Route path="/Rti_act_2005_amd" element={<Rti_act_2005_amd />} />
            <Route path="/Rti_act_2005_md" element={<Rti_act_2005_md />} />
            <Route path="/Rti_act_2005_msd" element={<Rti_act_2005_msd />} />
            <Route path="/Rti_act_2005_ard" element={<Rti_act_2005_ard />} />
            <Route path="/Rti_act_2005_psd" element={<Rti_act_2005_psd />} />
            <Route path="/Rti_act_2005_acd" element={<Rti_act_2005_acd />} />
            <Route path="/Rti_act_2005_pd" element={<Rti_act_2005_pd />} />
            <Route path="/Rti_act_2005_bd" element={<Rti_act_2005_bd />} />
            <Route path="/Rti_act_2005_prd" element={<Rti_act_2005_prd />} />
            <Route path="/Rti_act_2005_bsd" element={<Rti_act_2005_bsd />} />
            <Route path="/Rti_act_2005_pld" element={<Rti_act_2005_pld />} />
            <Route path="/Rti_act_2005_pld" element={<Rti_act_2005_cpd />} />
            <Route path="/Rti_act_2005_pld" element={<Rti_act_2005_ehd />} />
            <Route path="/Rti_act_2005_pld" element={<Rti_act_2005_cvs />} />
            <Route path="/Rti_act_2005_pfd" element={<Rti_act_2005_pfd />} />
            <Route path="/Rti_act_2005_cmatpd" element={<Rti_act_2005_cmatpd />} />
            <Route path="/Rti_act_2005_rd" element={<Rti_act_2005_rd />} />
            <Route path="/Rti_act_2005_cmed" element={<Rti_act_2005_cmed />} />
            <Route path="/Rti_act_2005_sd" element={<Rti_act_2005_sd />} />
            <Route path="/Rti_act_2005_ed" element={<Rti_act_2005_ed />} />
            <Route path="/Rti_act_2005_swm" element={<Rti_act_2005_swm />} />
            <Route path="/Rti_act_2005_fad" element={<Rti_act_2005_fad />} />
            <Route path="/Rti_act_2005_swmII" element={<Rti_act_2005_swmII />} />
            <Route path="/Rti_act_2005_gid" element={<Rti_act_2005_gid />} />
            <Route path="/Rti_act_2005_swupad" element={<Rti_act_2005_swupad />} />
            <Route path="/Rti_act_2005_health" element={<Rti_act_2005_health />} />
            <Route path="/Rti_act_2005_srac" element={<Rti_act_2005_srac />} />
            <Route path="/Rti_act_2005_itd" element={<Rti_act_2005_itd />} />
            <Route path="/Rti_act_2005_sqd" element={<Rti_act_2005_sqd />} />
            <Route path="/Rti_act_2005_kd" element={<Rti_act_2005_kd />} />
            <Route path="/Rti_act_2005_spd" element={<Rti_act_2005_spd />} />
            <Route path="/Rti_act_2005_ld" element={<Rti_act_2005_ld />} />
            <Route path="/Rti_act_2005_wsd" element={<Rti_act_2005_wsd />} />
            <Route path="/Rti_act_2005_led" element={<Rti_act_2005_led />} />

            <Route path="/RTI_Important_Points_for_the_RTI_Applicants" element={<RTI_Important_Points_for_the_RTI_Applicants />} />
            <Route path="/MC_Circular_2022_23" element={<MC_Circular_2022_23 />} />
            <Route path="/MC_Circular_2023_24" element={<MC_Circular_2023_24 />} />
            <Route path="/MC_Circular_2024_25" element={<MC_Circular_2024_25 />} />

            <Route path="/MC_Circular_2021_22" element={<MC_Circular_2021_22 />} />
            <Route path="/MC_Circular_2020_21" element={<MC_Circular_2020_21 />} />
            <Route path="/MC_Circular_2019_20" element={<MC_Circular_2019_20 />} />
            <Route path="/MC_Circular_2018_19" element={<MC_Circular_2018_19 />} />
            <Route path="/MC_Circular_2017_18" element={<MC_Circular_2017_18 />} />
            <Route path="/Amusement_Dept" element={<Amusement_Dept />} />
            <Route path="/Assessment_Dept" element={<Assessment_Dept />} />
            <Route path="/Bustee_Dept" element={<Bustee_Dept />} />
            <Route path="/Car_Parking_Dept" element={<Car_Parking_Dept />} />
            <Route path="/Chief_Eng_Dept" element={<Chief_Eng_Dept />} />
            <Route path="/Ch_VS_Dept" element={<Ch_VS_Dept />} />
            <Route path="/Contact_Us_Home" element={<Contact_Us_Home />} />
            <Route path="/EWaste" element={<EWaste />} />
            <Route path="/Education_Dept" element={<Education_Dept />} />
            <Route path="/Mpl_Secretary_Dept" element={<Mpl_Secretary_Dept />} />
            <Route path="/Parks_Square_Dept" element={<Parks_Square_Dept />} />
            <Route path="/Social_Welfare_Urban_Dept" element={<Social_Welfare_Urban_Dept />} />
            <Route path="/Town_Planning_Development_Dept" element={<Town_Planning_Development_Dept />} />
            <Route path="/Treasury_Dept" element={<Treasury_Dept />} />
            <Route path="/Drainage_Drainage_Pumping_station" element={<Drainage_Drainage_Pumping_station />} />

            <Route path="/Sewerage_Drainage_Dept" element={<Sewerage_Drainage_Dept />} />
            <Route path="/Electricity_Dept" element={<Electricity_Dept />} />
            <Route path="/Environment_Heritage_Dept" element={<Environment_Heritage_Dept />} />
            <Route path="/Finance_Dept" element={<Finance_Dept />} />
            <Route path="/Health_Dept" element={<Health_Dept />} />
            <Route path="/IT_Dept" element={<IT_Dept />} />
            <Route path="/Internal_Audit_Dept" element={<Internal_Audit_Dept />} />
            <Route path="/Law_Dept" element={<Law_Dept />} />
            <Route path="/Licence_Dept" element={<Licence_Dept />} />
            <Route path="/Lighting_Dept" element={<Lighting_Dept />} />
            <Route path="/Market_Dept" element={<Market_Dept />} />
            <Route path="/Personnel_Dept" element={<Personnel_Dept />} />
            <Route path="/Roads_Dept" element={<Roads_Dept />} />
            <Route path="/Solid_Waste_Dept" element={<Solid_Waste_Dept />} />
            <Route path="/Water_Supply_Dept" element={<Water_Supply_Dept />} />
            <Route path="/MC_Circular_2016_17" element={<MC_Circular_2016_17 />} />
            <Route path="/MC_Circular_2015_16" element={<MC_Circular_2015_16 />} />
            <Route path="/MC_Circular_2004_05" element={<MC_Circular_2004_05 />} />
            <Route path="/CMF_Communique_2022_23" element={<CMF_Communique_2022_23 />} />
            <Route path="/CMF_Communique_2023_24" element={<CMF_Communique_2023_24 />} />
            <Route path="/Chief_Manager_Circular" element={<Chief_Manager_Circular />} />
            <Route path="/Municipal_secretary_circular" element={<Municipal_secretary_circular />} />

            
            <Route path="/Procedure" element={<Procedure />} />
            <Route path="/TERM_Cell" element={<TERM_Cell />} />
            <Route path="/Pollution_Control_Board" element={<Pollution_Control_Board />} />

            <Route path="/CBP_Home" element={<CBP_Home />} />
            <Route path="/Vision_Strategy" element={<Vision_Strategy />} />
            <Route path="/Project_Faqs" element={<Project_Faqs />} />
            <Route path="/Citizen_charter_Home" element={<Citizen_charter_Home />} />
            <Route path="/Citizen_Charter_Ads" element={<Citizen_Charter_Ads />} />
            <Route path="/President_with_Charters" element={<President_with_Charters />} />
            <Route path="/Contact_Us_Homes" element={<Contact_Us_Homes />} />
            <Route path="/Controlling_Officers" element={<Controlling_Officers />} />
            <Route path="/MMIC" element={<MMIC />} />

            <Route path="/Important_Contacts" element={<Important_Contacts />} />
            <Route path="/Know_Your_Ward" element={<Know_Your_Ward />} />
            <Route path="/Building_Faqs" element={<Building_Faqs />} />
            <Route path="/Licence_Faq" element={<Licence_Faq />} />
            <Route path="/Faqs" element={<Faqs />} />
            <Route path="/Disclaimer" element={<Disclaimer />} />
            <Route path="/Quick_Links" element={<Quick_Links />} />
            <Route path="/Water_Supply_Faqs" element={<Water_Supply_Faqs />} />
            <Route path="/E_News_Letter" element={<E_News_Letter />} />
            <Route path="/E_Purashree" element={<E_Purashree />} />
            <Route path="/Archive" element={<Archive />} />
            <Route path="/Monsoon_Book" element={<Monsoon_Book />} />
            <Route path="/Disaster_Mgmt" element={<Disaster_Mgmt />} />
            <Route path="/Concept_Note" element={<Concept_Note />} />
            <Route path="/Guidelines_for_Safety" element={<Guidelines_for_Safety />} />
            <Route path="/Form_For_Inventory" element={<Form_For_Inventory />} />
            <Route path="/School_Safety_Booklet" element={<School_Safety_Booklet />} />
            <Route path="/Colouring_Activity_Book" element={<Colouring_Activity_Book />} />
            <Route path="/UEVRP_Home" element={<UEVRP_Home />} />
            <Route path="/UEVRP_Status" element={<UEVRP_Status />} />
            <Route path="/Earthquake_Leaflet" element={<Earthquake_Leaflet />} />
            <Route path="/EQ_Pocket_Guide" element={<EQ_Pocket_Guide />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/Dashboard2" element={<Dashboard2 />} />
            <Route path="/Wathsapp" element={<Wathsapp />} />
            <Route path="/Gallery" element={<Gallery />} />

          </Routes>
           <Footer /> 
        {/* </Router> */}
      </>
    </div>
  );
}

export default App;
