import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Tender_Batra = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    
    <>
  <section id="hero" className="hero hero2">
    <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
    </div>
  
    <div className="carousel-item active">
      <img src="assets/img/Banner/portal-banner7.jpg" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          <h4 className="fontSizeClass"> {t("Tender Barta")} </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
<section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-12">                   
            <div className="inner-title2">
              <h3 className="fontSizeClass">{("KMC Tender Barta")} </h3>
            </div>

              <div className="mayor-table depart Tender_batra">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-warning table-bor-2">
                      <th scope="col">{("Tender")} </th>
                      <th scope="col"> {("Tender Uploaded On")} </th>
                      <th scope="col"> {("Action")} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row"> {t("Tender Barta 2032(06/08/2024)")} </td>
                      <td> 06/08/2024 </td>
                      <td>
                          <a className="fontSizeClass" href='' target="_blank"> {t("Download")} </a>  
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> {t("Tender Barta 2031(02/08/2024)")} </td>
                      <td> 02/08/2024 </td>
                      <td>
                      <a className="fontSizeClass" href='' target="_blank"> 
                      {t("Download")} </a>   
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> {t("Tender Barta 2030(30/07/2024)")} </td>
                      <td> 30/07/2024 </td>
                      <td> 
                        <a className="fontSizeClass" href='' target="_blank"> {t("Download")} </a>  
                      </td>
                    </tr>
                    <tr>
                      <td scope="row"> {t("Tender Barta 2029(26/07/2024)")} </td>
                      <td> 26/07/2024 </td>
                      <td> 
                        <a className="fontSizeClass" href='' target="_blank"> {t("Download")} </a>  
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">{t("Tender Barta 2028(23/07/2024)")} </td>
                      <td>23/07/2022 </td>
                      <td> 
                         <a className="fontSizeClass" href='' target="_blank"> {t("Download")} </a>   
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">{t("Tender Barta 2027(19/07/2024)")} </td>
                      <td> 19/07/2022</td>
                      <td> 
                        <a className="fontSizeClass" href='' target="_blank"> {t("Download")} </a> 
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">{t("Tender Barta 2026(16/07/2024)")} </td>
                      <td>16/07/2024</td>
                      <td> 
                         <a className="fontSizeClass" href='' target="_blank"> {t("Download")} </a>  
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">{t("Tender Barta 2025(12/07/2024)")}</td>
                      <td>12/07/2022</td>
                      <td> 
                        <a className="fontSizeClass" href='' target="_blank"> {t("Download")} </a>   
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">{t("Tender Barta 2024(09/07/2024)")} </td>
                      <td>19/07/2024</td>
                      <td> 
                        <a className="fontSizeClass" href='' target="_blank"> {t("Download")} </a>   
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">{t("Tender Barta 2023(05/07/2024)")} </td>
                      <td>05/07/2024</td>
                      <td> 
                        <a className="fontSizeClass" href='' target="_blank"> {t("Download")} </a>  
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">{t("Tender Barta 2022(02/07/2024)")} </td>
                      <td>02/07/2024</td>
                      <td> 
                        <a className="fontSizeClass" href='' target="_blank"> {t("Download")} </a>  
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
        </div>
          
       
      </div>
    </div>
  </section>


    </>
  )
}

export default Tender_Batra