import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Solid_wastes_home  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
      <div className="carousel-item active">
        <img src="assets/img/Banner/portal-banner6.jpg" className="img-fluid" alt="image" />
        <div className="container">
          
          <div className="section-main-heading2">
            
            <h4 className="fontSizeClass"> </h4>
          </div>
        </div>
      </div>
    </section>
    {/* End Slider */}
    <section className="section inner-pad1">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
          <div className="site-menu ">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Solid_wastes_home'> {t("Solid Wastes Home")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Schedule_Of_Fees'> {t("Schedule of Fees")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Solid_waste_management_faqs'> {t("FAQs")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Solid_Waste_Dept'> {t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('842')}>{t("Status Report SWM Feb 2019")}</a>
              </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('843')}>{t("Gazette notification for KMC SWM Bye Laws 2020")}0</a>
              </li>
              </ul>
            </nav>
          </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2">
                  <h3 className="fontSizeClass"> {t("Solid Wastes/Refuses - where to dispose them?")} </h3>
                </div>
                <div className="col-lg-12 about-head1">
                  <p className="fontSizeClass">
                    
                    <strong>
                      
                      {t("Take a look at the steps to be taken by you")}
                    </strong>
                    <br />
                    {t("Cleaning your own garden?Renovating your house?Erecting a scaffolding,or making any other temporary structure on the footpath? Thinking about where to dispose off broken stems,leaves,roots,bricks,bits &amp; pieces of concrete or cement slabs and the leftovers of scaffolding like bamboos,mats and ropes?")}
                  </p>
                  <h5 className="fontSizeClass"> 
                    {t("As a responsible citizen,you should be aware of some of the following simple facts:")}
                  </h5>
                  <div className="row">
                    <div className="col-lg-6">
                        <div id="accordion">
                            <div className="card">
                                <div className="card-header" id="heading1">
                                    <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                    {("Do's")}</button>
                                </div>
                                <div id="collapse1" className="collapse" data-parent="#accordion">
                                    <div className="card-body m-3">
                                    <p className="fontSizeClass">
                                        {t("(1) To contact your local Solid Waste Management Dept.(SWM)Ward Office.")} <br />
                                        {t("(2) To deposit the required fee.")} <br />
                                        {t("(3) To obtain a receipt/permission from the KMC office.")}
                                        <br />
                                        {t("(4) To intimate the KMC office on the commencement of the work.")}
                                    </p>                       
                                </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div id="accordion">
                            <div className="card">
                                <div className="card-header" id="heading2">
                                    <button class="btn btn-link fontSizeClass " data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                                    {t("Don'ts")}</button>
                                </div>
                                <div id="collapse2" className="collapse" data-parent="#accordion">
                                    <div className="card-body m-3">
                                    <p className="fontSizeClass">
                                        {t("(1) Do not start the work before informing the local SWM Office.")} <br />
                                        {t("(2) Do not start the work before depositing the required fee at the KMC office. ")}<br />
                                        {t("(3) Do not start the work without a valid payment receipt.")} <br />
                                        {t("(4) Do not start the work before informing the KMC office.")}
                                        </p>
                                        <p className="fontSizeClass">
                                        
                                        <strong>
                                            {t(" Dispose off your daily garbage on time at the specified vats or in the handcrafts of the sweepers.")}
                                        </strong>
                                        </p>
                                        <p className="fontSizeClass">
                                        {t("Let not your problems hamper others.Please remember some of the simple but essential directions,mentioned above,to keep Kolkata clean. Help us to maintain a clean,green city. Remember,the city is yours. As a citizen,it is your moral duty to keep the environment clean and pollution-free.")}
                                        </p>
                                        <p className="fontSizeClass">
                                        <strong>
                                            {t("To serve you better <br /> Kolkata Municipal Corporation <br /> needs your continuous support and co-operation <br /> Control Room : 2286-1212/1313/1414")}
                                        </strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  
  )
}

export default Solid_wastes_home