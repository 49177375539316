import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MayorsOfKolkata = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
   
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
        <div className="container">
          
          <div className="section-main-heading2">
            
            <h4 className="fontSizeClass"> {t("Mayors of Kolkata")} </h4>
          </div>
        </div>
      </div>
    </section>
 
    <section className="section inner-pad1">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="site-menu  ">
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/MunicipalHistory'>
                      
                      {t("Municipal History")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link active1 fontSizeClass" to='/MayorsOfKolkata'>
                      
                      {t("Mayors of Kolkata")}
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
             <div className="row">            
                <div className="inner-title2">
                  <h3 className="fontSizeClass mx-3"> {t("Mayors of Kolkata")} </h3>
                </div>             
              <div className="mayor-table mayer-tb2">
                <div className="">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="table-warning table-bor-2">
                        <th scope="col">{t("Sl-No")}</th>
                        <th scope="col">{t("Date of Election")}</th>
                        <th scope="col">{t("Name")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={3}>
                          {t("(Under the Calcutta Municipal Act, 1923 - Bengal Act III of 1923)")}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">1</th>
                        <td>16/04/1924</td>
                        <td>{t("Deshbandhu Chittaranjan Das")}</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>01/04/1925</td>
                        <td>{t("Deshbandhu Chittaranjan Das")}</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>17/07/1925</td>
                        <td>{t("Deshapriya Jatindra Mohan Sengupta")}</td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td> 01/04/1926 </td>
                        <td> {t("Deshapriya Jatindra Mohan Sengupta")} </td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td> 27/04/1927 </td>
                        <td> {t("Deshapriya Jatindra Mohan Sengupta")} </td>
                      </tr>
                      <tr>
                        <th scope="row">6</th>
                        <td>02/04/1928 </td>
                        <td> {t("Sri Bijoy Kumar Basu")}</td>
                      </tr>
                      <tr>
                        <th scope="row">7</th>
                        <td> 10/04/1929 </td>
                        <td>{t("Deshapriya Jatindra Mohan Sengupta")} </td>
                      </tr>
                      <tr>
                        <th scope="row">8</th>
                        <td> 29/04/1930</td>
                        <td>{t("Deshapriya Jatindra Mohan Sengupta")} </td>
                      </tr>
                      <tr>
                        <th scope="row">9</th>
                        <td> 22/08/1930 </td>
                        <td> {t("Netaji Subhas Chandra Bose")} </td>
                      </tr>
                      <tr>
                        <th scope="row">10</th>
                        <td>15/04/1931 </td>
                        <td> {t("Dr. Bidhan Chandra Roy")} </td>
                      </tr>
                      <tr>
                        <th scope="row">11</th>
                        <td>11/04/1932 </td>
                        <td>{t("Dr. Bidhan Chandra Roy")} </td>
                      </tr>
                      <tr>
                        <th scope="row">12</th>
                        <td> 09/04/1933 </td>
                        <td> {t("Sri Santosh Kumar Basu")} </td>
                      </tr>
                      <tr>
                        <th scope="row">13</th>
                        <td> 04/07/1934 </td>
                        <td> {t("Sri Nalini Ranjan Sarkar")} </td>
                      </tr>
                      <tr>
                        <th scope="row">14</th>
                        <td> 30/04/1935 </td>
                        <td> {t("Sri Abul Kasem Fazlul Haque")} </td>
                      </tr>
                      <tr>
                        <th scope="row">15</th>
                        <td> 29/04/1936 </td>
                        <td> {t("Sir Harisankar Paul")} </td>
                      </tr>
                      <tr>
                        <th scope="row">16</th>
                        <td>28/041937 </td>
                        <td> {t("Sri Sanat K.Roy Chowdhury")} </td>
                      </tr>
                      <tr>
                        <th scope="row">17</th>
                        <td> 29/04/1938 </td>
                        <td> {t("Sri A.K.M. Zakariah")} </td>
                      </tr>
                      <tr>
                        <th scope="row">18</th>
                        <td> 26/04/1939</td>
                        <td>{t("Sri Nisith Chandra Sen")} </td>
                      </tr>
                      <tr>
                        <th scope="row">19</th>
                        <td> 24/04/1940 </td>
                        <td> {t("Sri Abdur Rahman Siddiqi")}</td>
                      </tr>
                      <tr>
                        <th scope="row">20</th>
                        <td> 28/04/1941 </td>
                        <td> {t("Sri Phanindra Nath Brahma")} </td>
                      </tr>
                      <tr>
                        <th scope="row">21</th>
                        <td>29/04/1942 </td>
                        <td> {t("Sri Hem Chandra Naskar")} </td>
                      </tr>
                      <tr>
                        <th scope="row">22</th>
                        <td>30/04/1943 </td>
                        <td> {t("Sri Syed Badrudduja")} </td>
                      </tr>
                      <tr>
                        <th scope="row">23</th>
                        <td> 26/04/1944 </td>
                        <td> {t("Sri Anandi Lal Poddar")} </td>
                      </tr>
                      <tr>
                        <th scope="row">24</th>
                        <td> 27/04/1945 </td>
                        <td> {t("Sri Debendra Nath Mukherjee")} </td>
                      </tr>
                      <tr>
                        <th scope="row">25</th>
                        <td> 29/04/1946 </td>
                        <td> {t("Sri Syed Mohammed Usman")} </td>
                      </tr>
                      <tr>
                        <th scope="row">16</th>
                        <td> 29/04/1947 </td>
                        <td> {t("Sri Sudhir Chandra Ray Chaudhuri")} </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="section-title title2">
                    
                    <h4 className="fontSizeClass">
                      
                      {t("(Under the Calcutta Municipal Act, West Bengal Act XXXIII of 1951)")}
                    </h4>
                  </div>
                  <table className="table table-bordered">
                    <thead>
                      <tr className="table-warning table-bor-2">
                        <th scope="col">{t("Sl-No")}</th>
                        <th scope="col">{t("Date of Election")}</th>
                        <th scope="col">{t("Name")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>01/05/1952</td>
                        <td>{t("Sri Nirmal Chandra Chunder")}</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>06/03/1953</td>
                        <td>{t("Sri Naresh Nath Mookherjee")}</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>02/04/1953</td>
                        <td>{t("Sri Naresh Nath Mookherjee")}</td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td> 28/04/1954 </td>
                        <td> {t("Sri Naresh Nath Mookherjee")} </td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td> 25/04/1955 </td>
                        <td> {t("Sri Satish Chandra Ghosh")} </td>
                      </tr>
                      <tr>
                        <th scope="row">6</th>
                        <td> 23/04/1956 </td>
                        <td> {t("Sri Satish Chandra Ghosh")} </td>
                      </tr>
                      <tr>
                        <th scope="row">7</th>
                        <td> 29/04/1957 </td>
                        <td> {t("Dr. Triguna Sen")} </td>
                      </tr>
                      <tr>
                        <th scope="row">8</th>
                        <td> 18/04/1958 </td>
                        <td> {t("Dr. Triguna Sen")} </td>
                      </tr>
                      <tr>
                        <th scope="row">9</th>
                        <td> 08/04/1959 </td>
                        <td> {t("Sri Bejoy Kumar Banerjee")} </td>
                      </tr>
                      <tr>
                        <th scope="row">10</th>
                        <td> 08/06/1960 </td>
                        <td> {t("Sri Keshab Chandra Basu")} </td>
                      </tr>
                      <tr>
                        <th scope="row">11</th>
                        <td> 28/04/1961 </td>
                        <td> {t("Sri Rajendranath Majumdar")} </td>
                      </tr>
                      <tr>
                        <th scope="row">12</th>
                        <td> 18/04/1962 </td>
                        <td> {t("Sri Rajendranath Majumdar")} </td>
                      </tr>
                      <tr>
                        <th scope="row">13</th>
                        <td> 08/04/1963 </td>
                        <td> {t("Sri Chittaranjan Chatterjee")} </td>
                      </tr>
                      <tr>
                        <th scope="row">14</th>
                        <td> 17/04/1964 </td>
                        <td> {t("Sri Chittaranjan Chatterjee")} </td>
                      </tr>
                      <tr>
                        <th scope="row">15</th>
                        <td> 26/04/1965 </td>
                        <td> {t("Dr. Priti Kumar Roy Chowdhury")} </td>
                      </tr>
                      <tr>
                        <th scope="row">16</th>
                        <td> 225/04/1966 </td>
                        <td> {t("Dr. Priti Kumar Roy Chowdhury")} </td>
                      </tr>
                      <tr>
                        <th scope="row">17</th>
                        <td> 24/04/1967 </td>
                        <td> {t("Sri Gobinda Chandra Dey")} </td>
                      </tr>
                      <tr>
                        <th scope="row">18</th>
                        <td> 19/04/1968 </td>
                        <td> {t("Sri Gobinda Chandra Dey")} </td>
                      </tr>
                      <tr>
                        <th scope="row">19</th>
                        <td> 13/06/1969 </td>
                        <td> {t("Sri Prasanta Kumar Sur")} </td>
                      </tr>
                      <tr>
                        <th scope="row">20</th>
                        <td> 24/04/1970 </td>
                        <td> {t("Sri Prasanta Kumar Sur")} </td>
                      </tr>
                      <tr>
                        <th scope="row">21</th>
                        <td> 23/04/1971 </td>
                        <td> {t("Sri Shyam Sundar Gupta")} </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="section-title title2">
                    
                    <h4 className="fontSizeClass">
                      
                      {t("(Under the Calcutta Municipal Corporation Act, 1980 West Bengal Act LIX of 1980)")}
                    </h4>
                  </div>
                  <table className="table table-bordered">
                    <thead>
                      <tr className="table-warning table-bor-2">
                        <th scope="col">{t("Sl-No")}</th>
                        <th scope="col">{t("Date of Election")}</th>
                        <th scope="col">{t("Name")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td> 30/07/1985 </td>
                        <td> {t("Sri Kamal Kumar Basu")} </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td> 30/07/1990 </td>
                        <td> {t("Sri Prasanta Chatterjee")} </td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td> 15/07/1995 </td>
                        <td> {t("Sri Prasanta Chatterjee")} </td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td> 12/07/2000 </td>
                        <td> {t("Sri Subrata Mookherjee")} </td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td> 05/07/2005 </td>
                        <td> {t("Sri Bikash Ranjan Bhattacharyya")} </td>
                      </tr>
                      <tr>
                        <th scope="row">6</th>
                        <td> 16/06/2010 </td>
                        <td> {t("Sri Sovan Chatterjee")} </td>
                      </tr>
                      <tr>
                        <th scope="row">7</th>
                        <td> 18/05/2015 </td>
                        <td> {t("Sri Sovan Chatterjee")} </td>
                      </tr>
                      <tr>
                        <th scope="row">8</th>
                        <td> 03/12/2018 </td>
                        <td> {t("Sri Firhad Hakim")} </td>
                      </tr>
                      <tr>
                        <th scope="row">9</th>
                        <td> 28/12/2021 </td>
                        <td> {t("Sri Firhad Hakim")} </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
          
        </div>
      </div>
    </section>
  </>

  )
}

export default MayorsOfKolkata