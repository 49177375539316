import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Land_Records_Burial_Grounds  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  }; 
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass">  </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
      <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link  fontSizeClass" to='/Health_services_Health_Home'> {t("Health Home")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('458')}> {t("Dengue - Status-Appeal to Citizens")} </a>
                 </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('459')}> {t("Immunisation centers")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('460')}> {t("Health Units")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Malaria_CLinics'> {t("Malaria Clinics")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Maternity_Homes'> {t("Maternity Homes")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_KMC_Dispensaries'> {t("KMC Dispensaries")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Chest_Clinincs'> {t("KMC Chest Clinics")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/WaterTestDuplicateReceipt.jsp'> {t("Water Test Reprint e-Receipt")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Desease_control'> {t("Desease Control")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Fees_Charges'> {t("Fees & Charges")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_MTMTB_Hospital'> {t("MTMTB Hospital")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Slaughter_House'> {t("Slaughter House")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Ambulances'> {t("Ambulance")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Antirabies_VACCINATION_Center'> {t("Antirabies Vaccination Centre")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Designation_Activities'>{t("Designation Activity")}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_KMDA_Health_Projects'> {t("KMDA Health Projects")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BurningGhatStatus.jsp'> {t("Burning Ghat Status")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Land_Records_Burial_Grounds'>{t("Land Records Of Burial Grounds")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Health_Dept'> {t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('461')}> {t("Information on FSS Act-License / Registration in KMC")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('462')}> {t("Locations of Slaughtering of Animals on the Occasion of ID-UD-ZOHA(BAKRI-ID) ON 10.07.2022, 11.07.2022 & 12.07.2022")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('469')}> {t("Swasthya Bandhab Sharad Samman")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('470')}> {t("Details of UPHC")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('466')}> {t("List of Enlisted Agencies for Carrying Covid Bodies")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('471')}> {t("Immersion training by ECHO India for KMC Health staff")}</a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('472')}> {t("KMC Participation and Experiences at ECHO India's IPC Dissemination Event : Mumbai")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'>
                    {t("Application/Payment for Potable Water Analysis")}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass">{t("Land Records Of Burial Grounds")} </h3>
              </div>
              <div className="about-head1">
                <div className="dig-login1 dig-login2">
                  <form>
                    <div className="log-form inner-log-form">
                      <h3 className="fontSizeClass"> {t("List of Burial Grounds")} </h3>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 form-group pt-2">
                          <div className="list-of-forms">
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/BAGMARI_BURIAL_GROUND.xlsx" download="BAGMARI_BURIAL_GROUND.xlsx"><i className="bi bi-download" target="_blank" /> {t(" Bagmari Burial Ground")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/BAGMARI_BURIAL_GROUND2.xlsx" download="BAGMARI_BURIAL_GROUND2.xlsx"><i className="bi bi-download" target="_blank" /> {t("Bagmari Burial Ground")} 2</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/BAGMARI_BURIAL_GROUND3.xlsx" download="BAGMARI_BURIAL_GROUND3.xlsx"><i className="bi bi-download" target="_blank" /> {t("Bagmari Burial Ground")} 3</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/Gobra_24_07_1953_18_02_1970.xlsx" download="Gobra_24_07_1953_18_02_1970.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 24.07.1953 to 18.02.1970")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/Gobra_03_04_1918_11_02_1949.xlsx" download="Gobra_03_04_1918_11_02_1949.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 03.04.1918 to 11.02.1949")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/Gobra_04_05_1926_06_03_1980.xlsx" download="Gobra_04_05_1926_06_03_1980.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 04.05.1926-06.03.1980")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/Gobra_04_05_1926_06_03_1980_Bagmari.xlsx" download="Gobra_04_05_1926_06_03_1980_Bagmari.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 04.05.1926 - 06.03.1980 (Bagmari)")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/Gobra_04_05_1926_06_03_1980_2.xlsx" download="Gobra_04_05_1926_06_03_1980_2.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 04.05.1926 - 06.03.1980")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/Gobra_06_04_1928_21_02_1934.xlsx" download="Gobra_06_04_1928_21_02_1934.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 06.04.1928-21.02.1934")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/Gobra_07_02_1969_20_02_1978.xlsx" download="Gobra_07_02_1969_20_02_1978.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 06.04.1928-21.02.1934")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/Gobra_08_03_1978_13_08_1979.xlsx" download="Gobra_08_03_1978_13_08_1979.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 07.02.1969-20.02.1978")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/Gobra_08_05_46_16_02_58.xlsx" download="Gobra_08_05_46_16_02_58.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 08.03.1978-13.08.1979")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/Gobra_10_01_1926_30_07_1928.xlsx" download="Gobra_10_01_1926_30_07_1928.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 08.05.46-16.02.58")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/Gobra_15_03_1958_25_10_1980.xlsx" download="Gobra_15_03_1958_25_10_1980.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 10.01.1926-30.07.1928")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/Gobra_16_03_1934_27_04_1946.xlsx" download="Gobra_16_03_1934_27_04_1946.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 15.03.1958-25.10.1980")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/Gobra_21_02_1973_31_12_1987.xlsx" download="Gobra_21_02_1973_31_12_1987.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 16.03.1934-27.04.1946")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/Gobra_29_11_1980_22_02_1983.xlsx" download="Gobra_29_11_1980_22_02_1983.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 21.02.1973-31.12.1987")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/Gobra_30_01_1966_09_11_1982.xlsx" download="Gobra_30_01_1966_09_11_1982.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 29.11.1980-22.02.1983")}</a></h6>
                                <h6 className="fontSizeClass"><a className="fontSizeClass" href="assets/img/GORE_GARIBAN_21_10_79_23_06_83.xlsx" download="GORE_GARIBAN_21_10_79_23_06_83.xlsx"><i className="bi bi-download" target="_blank" /> {t("Gobra 30.01.1966-09.11.1982")}</a></h6>
                              
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Land_Records_Burial_Grounds