import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Advertisement_rates = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  const [onlineApplication, setonlineApplication] = React.useState(false);
  const onlineApplicationToggle = () => {
    setonlineApplication(!onlineApplication);
  };
  const [onlineDeclaration, setonlineDeclaration] = React.useState(false);
  const onlineDeclarationToggle = () => {
    setonlineDeclaration(!onlineDeclaration);
  };
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"> </div></div>             
    </div>       	 		 
  </section>	  
  <section className="section inner-pad1">
    <div className="container"  id="scrl_id">
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu   min-height-400">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/KMC_Advertisement'> {t("Advertisement Home")} </Link>
                </li>
                <li className="nav-item">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Online Application")} <i className="bi bi-chevron-down" /></button>
                    <div id="demo" className="collapse">
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/AdvertisementOnlineApplication.jsp' target="_blank"> {t("Online Advertisement Application")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/AdvertisementOnlineRenewApplication.jsp' target="_blank"> {t("Online Advertisement Renew Application")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineTerminateApplication.jsp' target="_blank"> {t("Online Advertisement Terminate Application")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemandPrint_online.jsp' target="_blank">  {t("Online Advertisement Application Demand Print")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalAdvertisementPayment_online.jsp' target="_blank"> {t("Online Advertisement Application Demand Pay")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineApplicationNOC.jsp' target="_blank"> {t("Online Advertisement Application NOC Print")}</a>
                    </div>
                </li>
                <li className="nav-item">
                    <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Online Declaration")} <i className="bi bi-chevron-down" /></button>
                    <div id="demo2" className="collapse">
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineDeclaration.jsp'>1. {t("Online Advertisement Declaration")} </a>
                        <a className="nav-link fontSizeClass" style={{fontSize:"11px"}} href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementOnlineDeclarationStatus.jsp'>2. {t("Online Declaration Status")} </a>
                    </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Advertisement_rates'> {t("Rates")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AgentDetails.jsp'> {t("Know Your Agent Code")}</a>                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Advertisement_download_forms'> {t("Download Forms")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemand.jsp'>{t("Check Demands Payable")} </a>                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemandPrint.jsp'> {t("Print Unpaid Bill")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemand.jsp'> {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/AdvertisementDemand.jsp'> {t("Reprint e-Receipt")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCAdvertisementTaxCalculator.jsp'>{t("Advertisement Tax Calculator")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Advertisement_Dept'> {t("Contact Details")}</Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/ListHoardings1.jsp'> {t("List of Hoardings")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('240')}> {t("Empanelement Of Advertising Agencies")} </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">       
            <form>
              <div className="log-form">
                <div className="container">
                  <div className="row">
                    <div class="inner-title"> 
                    <h3 className="fontSizeClass">{t("Schedule of Rates, Taxes, Fees & Charges for the Year 2023 - 2024")} </h3>
                    <p className="subtt-ad">{t("ADVERTISEMENT DEPARTMENT")}</p> 
                    </div>
                    
                    <div className="table-responsive mayor-table2">
                      <table className="table table-sm table-bordered kmc-department table-scl-3a">                      
                        <tbody>                       
                         <tr class="table-warning table-bor-2a">
                           <td scope="row"> {t("Sl.No")}. </td>
                           <td className="col-td1"> {t("Advertisement Fees, Charges etc")}. </td>
                            <td className="">
                            <tbody className='tab-bord1'>
                            <tr><td colspan="3">{t("Rates of Fees, Charges & Security Deposit")}</td></tr>
                            <tr> 
                              <td> {t("u/s 202 read with Sec 543(2)")} </td> 
                              <td> {t("u/s 203")} </td>  
                              <td> {t("Remarks")} </td> 
                            </tr>
                            <tr> <td colspan="3">{t("Figures in Rupees")}.</td> </tr>                           
                            </tbody> 
                            </td>                         
                         </tr>
                         <tr>
                         <td> 1 </td>
                          <td> {t("For Hoarding / Post / Kiosks / Structures etc. per sq. ft. per year or part thereof")} </td>
                           <td>
                            <tbody>                          
                              <tr> 
                                <td> 50.00 </td> 
                                <td> 150.00 </td>  
                                <td> {t("See Note (xiv), (xv) & (xvi)")} </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr className='tex-col-1b'>
                         <td>  </td>
                         {/* <s> 1(b) </s> */}
                          <td>  </td>
                          {/* <s> For Hoarding/Post/Kiosks/Structures etc. per sq. ft. 
                            per year or part thereof (Above 800 sq. ft.) </s> */}
                          <td>
                            <tbody>                          
                              <tr> 
                                <td>  </td>
                                {/* <s> 50.00 </s>  */}
                                <td>  </td> 
                                {/* <s> 225.00 </s>  */}
                                <td>  </td>
                                {/* <s> See Note xiv </s>  */}
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>

                         <tr>
                         <td> 2 </td>
                          <td> {t("For Collapsible Kiosk / Canopy (5'x5') per spot per calendar month (per piece per location)")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 600.00 </td> 
                                <td> 2800.00 </td>  
                                <td> -- </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>

                         <tr>
                         <td> 3(a) </td>
                          <td> {t("For Vehicles, having own advertisement, own Delivery Van, Bus Back Panel, Bus Body Panel per sq. ft. per year except digital advertisement")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 40.00 </td> 
                                <td> 100.00 </td>  
                                <td> {t("See Note (v)")} </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>

                         <tr>
                         <td> 3(b) </td>
                          <td> {t("For Vehicles carrying Board / Painting etc. or any kind of advertisement on tableau per Sq. Ft. per month except digital advertisement (permission will be given for one month or end of the current month, whichever is earlier)")}. </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 40.00 </td> 
                                <td> 100.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>

                         <tr>
                         <td> 4 </td>
                          <td> {t("For Glow Sign Board (fixed on top of the shop) per sq. ft. per year or per thereof")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 39.00 </td> 
                                <td> 105.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>

                         <tr>
                         <td> 5 </td>
                          <td> {t("For Glow Sign Board on portico per sq.ft. per year or per thereof")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 39.00 </td> 
                                <td> 105.00 </td>  
                                <td> {t("See Note (viii)")} </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>

                         <tr>
                         <td> 6 </td>
                          <td> {t("For Cloth Banner (minimum 6'x3'), Flex Banner (minimum 3'x3'), Gate Frame Banner etc. Temporary per sq.ft. per calendar month")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> - </td> 
                                <td> 15.00 </td>  
                                <td> {t("See Note (vi)")} </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>

                         <tr>
                         <td> 7 </td>
                          <td> {t("For Leaflets / Handbills per calendar month per 1000 pieces")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 600.00 </td> 
                                <td> 600.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>

                         <tr>
                         <td> 8 </td>
                          <td> {t("For Umbrella per pieces per calendar month per location")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 300.00 </td> 
                                <td> 900.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>

                         <tr>
                         <td> 9 </td>
                          <td> {t("For Balloon per piece per calendar month per spot")}. </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 300.00 </td> 
                                <td> 1200.00 </td>  
                                <td> {t("See Note (vii)")} </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>

                         <tr className='tex-col-1b'>
                         <td> <s> 10 </s> </td>
                          <td> <s> {t("For Non-news Magazines & Yellow Pages/Magazines for advertisement only per year or part thereof for full page advertisement or part thereof for each issue")} </s> </td>
                          <td> <s>
                            <tbody>                          
                              <tr> 
                                <td> 25.00 </td> 
                                <td> 35.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </s> </td>   
                         </tr>

                         <tr>
                         <td> 10 </td>
                          <td> {t("For Cap / Sun Protector / T Shirt per month (1000 pieces)")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> - </td> 
                                <td> 1000.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 11 </td>
                          <td> {t("For Audio System (4 Speakers) per month per each set")}. </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 1600.00 </td> 
                                <td> 900.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 12 </td>
                          <td> {t("For Video Display in public place per TV Screen per month per spot")}. </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 5200.00 </td> 
                                <td> 4800.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 13 </td>
                          <td> {t("For Car Sticker (maximum 2'x1' per vehicle per year per logo per 1000 cars)")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 1700.00 </td> 
                                <td> 4300.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 14 (a) </td>
                          <td> {t("For Poster upto 6 sq.ft. (maximum 3000 pieces) per month")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 800.00 </td> 
                                <td> - </td>  
                                <td> {t("See Note (ii) & (iii)")} </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 14 (b) </td>
                          <td> {t("For Poster above 6 sq.ft. (maximum 4000 pieces) per month")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 1000.00 </td> 
                                <td> - </td>  
                                <td> {t("See Note (ii) & (iii)")} </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 15 </td>
                          <td> {t("For Indicator Board per sq.ft. per year or per thereof")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 50.00 </td> 
                                <td> 80.00 </td>  
                                <td> {t("See Note (viii)")} </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 16 </td>
                          <td> {t("For Cinema Slide - Rate per slide per month")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> - </td> 
                                <td> 200.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 17 </td>
                          <td> {t("For Cinema, Ad Film / Movie Film Show (Cinema Hall or Local Cable TV) per reel per month")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 60.00 </td> 
                                <td> 540.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 18(a) </td>
                          <td> {t("For Mobile Hoarding mounted on Heavy Vehicles (300 sq.ft.) per vehicle per year")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 3,00,000.00 </td> 
                                <td> - </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 18(b) </td>
                          <td> {t("For Mobile Hoarding mounted on Small Vehicles (like TATA 407) per vehicle per year")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 1,50,000.00 </td> 
                                <td> - </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 18(c) </td>
                          <td> {t("For Mobile Hoarding mounted on Small Vehicles (like TATA 407) per vehicle per year")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 1,00,000.00 </td> 
                                <td> - </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 19(a) </td>
                          <td> {t("For Bus Shelter on KMC Board (200 sq.ft. restricted)")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 30.00 </td> 
                                <td> 126.00 </td>  
                                <td> {t("See Note (xiii)")} </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 19(b) </td>
                          <td> {t("For Bus Shelter on other than KMC Board")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 30.00 </td> 
                                <td> 126.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 20 </td>
                          <td> {t("For V Shaped Hoarding for I&CA Deptt., Govt. of W.B.")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> 30.00 </td> 
                                <td> 126.00 </td>  
                                <td> {t("See Note (viii)")} </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 21 </td>
                          <td> {t("For Display on moving Human Body like RAN-PA etc. per person per month")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> - </td> 
                                <td> 1000.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 22(a) </td>
                          <td> {t("Display through Cut-Out (non illuminated) per sq.ft. per month")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> - </td> 
                                <td> 30.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 22(a) </td>
                          <td> {t("Display through Cut-Out (non illuminated) per sq.ft. per month")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> - </td> 
                                <td> 30.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 22(b) </td>
                          <td> {t("Display through Cut-Out (illuminated) per sq.ft. per month")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> - </td> 
                                <td> 60.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 23(a) </td>
                          <td> {t("For LED digital display mounted at the top of the shop / establishment / façade of a Mall displaying their name / brand / logo or promoting the products / services being sold by them per Sq. Ft. per year")}. </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> - </td> 
                                <td> 400.00 </td>  
                                <td> {t("See Note (ix)")} </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 23(b) </td>
                          <td> {t("For LED / digital displaying boards installed at private and / or Govt. premises for the purpose of displaying commercial advertisement per Sq. Ft. per year")}. </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> - </td> 
                                <td> 500.00 </td>  
                                <td> {t("See Note (ix)")} </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 24 </td>
                          <td> {t("For Temporary Lamp-Post Kiosk per sq.ft. per month")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> - </td> 
                                <td> 50.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 25 </td>
                          <td> {t("Display through pillars, Box Gate per Sq. ft. per calendar month")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> - </td> 
                                <td> 50.00 </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 26 </td>
                          <td> {t("For moving vehicles carrying digital Boards, paintings etc. or any kind of digital / LED advertisement or digital advertisement o, tableau per Sq. ft. per month (permission will be given maximum one month or end of the current month, whichever is earlier)")} </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> - </td> 
                                <td> 200.00 </td>  
                                <td> {t("See Note (xvi)")} </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>
                         <tr>
                         <td> 27 </td>
                          <td> {t("Advertisement Related to events Like Drama / Drum Beating / Singing Etc")}. </td>
                          <td>
                            <tbody>                          
                              <tr> 
                                <td> - </td> 
                                <td> 5,200.00 {t("For 15 Days")} </td>  
                                <td> - </td> 
                              </tr>                                                  
                            </tbody> 
                            </td>   
                         </tr>


                        </tbody>
                      </table>

                    </div>

                  </div>
                </div>
              </div>
            </form>
          
          <div className="list-items2a table-scl-3a" id="list-items">
            <b>{t("Notes")}:</b>
            <ul>
              <li>{t("Rates, Fees and Charges etc. for uncovered categories of medium of Advt. shall be decided by the Municipal Commissioner.")}.</li>
              <li>{t("Commercial Poster to be reinstated.")}.</li>
              <li>{t("Other Posters will not be allowed on certain restricted locations like walls of Heritage Buildings, Heritage zone & No Hoarding zone, Govt. Offices, Corporate House, Hospitals, Educational Institutions & any public utility institution etc.")}.</li>
              <li>{t("Wall painting is not permitted. If there is any, a fine of Rs. 400/- per Sq.ft. per month will be imposed. This fine may be imposed upon the Company whose product will be advertised by wall painting.")}.</li>             
              <li>{t("In case of back panel display on Bus/Mini Bus etc.: Minimum Size 6'x3'=18 Sq.ft.")}.</li>
              <li>{t("In case of cloth banner: Minimum Size 6'x3'=18 Sq.ft. and in case of Frame Flex banner: Minimum Size 3'x3'=9 Sq.ft.")}.</li>             
              <li>{t("In case of gas balloon, no objection certificate from the Airport Authority is required.")}.</li>             
              <li>{t("Base Land utilization charges will be collected @ Rs. 1870/- per sq. ft./per year or part thereof for permanent Indicator Board/Glow Sign Board on Footpath, Portico, Pillars & other permanent structure bearing advertisement fixed on KMC Land, if permitted by KMC (in case of Indicator / Glow Sign Board fixed on private premises no L. U. C. is to be charged subject to the permission of landlord).In case of permanent display Advertisement fees charges etc. will be charged proportionately on the basis of sanctioned date.")}.</li>             
              <li>{t("Rate for Laser / LED u/s 202 & 203 will be Rs. 400/- and or Rs. 500/- as the case may be per sq. ft. per year. Land Utilization Charges will be additionally charged when Advertisement is fixed on KMC Land.")}.</li>             
              <li>{t("For Tri display rate will be Double the usual rate u/s 203 (1)")}.</li>
              <li>{t("Advertisement on Gantry (across the road) : Twice the normal rate u/s 203. All demands will be raised quarterly and are to be paid quarter wise in advance within 10th of the 1st month of the quarter (i.e 10th April, 10th July, 10th October, 10th January).")}.</li> 
              <li>{t("Base Land Utilization Charges will be collected @ Rs. 1,10,000/- per year or part thereof for Bus Shelters, if permitted by KMC.")}.</li>            
              <li>{t("In case of existing Hoarding no permission fee is required.")}.</li>            
              <li>{t("Within Kolkata City Proper size of Hoarding is restricted upto 800 sq.ft.")}.</li>
              <li>{t("No Hoarding above 800 sq.ft. will be permitted within KMC area except E M Bye Pass including Service Road, P C Connector up to 4 No. Bridge, R B Connector up to Booster Pumping Station & Bosepukur.")}.</li>
              <li>{t("The vehicle are lowed to be remain standing or parked at a particular place for a duration of 30 minutes maximum. If any objection / complain received from the Traffic Police Authority regarding such standing /  parked vehicles causing traffic distraction etc. then the vehicles are required to be relocate any other place immediately and no refund of advertisement fees, charges etc. already deposited in this regard will be made.")}.</li>            
                          
            </ul>
            <strong>{t("Security Deposit")}</strong>
              <div className="table-responsive mayor-table2">
              <table class="table table-bordered kmc-department">                      
                <tbody>
                  <tr>
                    <td>{t("Sl. No")}.</td>
                    <td>{t("Description")}</td>
                    <td>{t("Security Deposit (in Rs.)")}</td>
                    <td>{t("Remarks")}</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>{t("Any kind of temporary advertisement for publicity / promotion of any social culture, environment or alike programmes")}</td>
                    <td>5000.00</td>
                    <td>{t("Security deposit to be deposited in the form of demand draft only during the application (*)")}</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>{t("Any kind of temporary commercial advertisement other than the programmes mentioned at Sl. No. 1 above")}.</td>
                    <td>20000.00</td>
                    <td> &nbsp </td>
                  </tr>
                </tbody>
               </table>
              </div>
              <p className='rts-p'>*	{t("Security deposit will be refunded within the 7 (seven) days after expiry of the permission and / or license upon application of the concerned agency provided that the advertisement for which permission is given have been erased, removed or taken down by the agency. If it is found that after 7 (seven) days of expiry of the permission, the advertisements are not erased, removed, taken down by the advertiser or advertisements have been put up beyond the permissible size and / or number of advertisement have been put up in streets / locations other than permissible streets / location, and / or number or advertisements have been mounted on trees or any kind of Flora and Fauna, then the security deposit will be forfeited by the KMC and an amount equivalent to the security deposit will be charged as a fine to the concerned agency. Failing to pay the fine, the agency and its proprietor / directors etc. will be blacklisted for participating in any advertisement related issues in the Kolkata Municipal Corporation with the approval of the competent authority")}.</p>
          </div>

          

        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Advertisement_rates