import CryptoJS from "crypto-js";


const generateHash = (key) => {
    let todaysDate = new Date();
    let currentDate = todaysDate.getDate();
    if (currentDate < 10) 
        currentDate = '0'+currentDate 
    let currentMonth = todaysDate.getMonth() + 1;
    if (currentMonth < 10) 
        currentMonth = '0'+currentMonth 
    let currentYear = todaysDate.getFullYear();
    todaysDate = currentYear+''+currentMonth+''+currentDate;

    let shaKey = key+'~'+todaysDate; // value to be hashed, key and current date in DDMMYYY separated by ~
    console.log("dataKey----", shaKey, todaysDate);
    let valueToHash = CryptoJS.SHA512(shaKey).toString();
    console.log("The hashed text is ", valueToHash);
    return valueToHash;
}

export {generateHash};