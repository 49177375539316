import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Engineering_Home  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
 
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
    </div>
  </section>
  {/* End Slider */}
  
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
        <div className="site-menu ">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Engineering_Home'>                    
                    {t("Engineering (Civil) Home")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Engineering_Rates_Charges'>        
                    {t("Rates & Charges")}
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCRoadDevelopmentDetails.jsp'> {t("KMC Road Development Scheme")} </a>
                </li>
                <li className="nav-item"> 
                  <Link className="nav-link fontSizeClass" to='/Engineering_Manhole_Desilting_Programme'>                   
                    {t("Manhole Desilting Programme of Borough-1 to XVI under Civil Dept")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Engi_Man_Desil_Prog_civil_Bro_30_6_2024'>                   
                    {t("Manh Desi Prog of Bor-1 to XVI under Civil Dept 30-6-24")}
                  </Link>
                </li>

                <li className="nav-item">
                  <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('284')}> {t("Tender Form Format")} </a> 
                </li>
                <li className="nav-item">
                    <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/ChiefEngContact.jsp'> {t("Contact Details")} </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass">{t("Manholes without Cover? Damaged Roads? Uprooted Trees?")} </h3>
              </div>
              <section id="faq" className="faq advertisement civil">
                <p className="fontSizeClass">
                  {t("As a citizen of Kolkata, you often face such civic problems. And you are unaware of what to do or where to go, to tackle them. Your awareness will not only save you from further trouble but will also help us to serve you better. Take a quick look at these problems and their solutions provided by the Engineering Department of KMC")}.
                </p>
                <h2 className="btm-pad">{t("Do's")}</h2>
                <div className="" >
                  <div className="row gy-4">
                    <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1 no-margin">
                     

                    <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="heading1">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                    {t("Stolen covers of manholes/gully-pits cover")}
                    </button>
                    </div>
                    <div id="collapse1" className="collapse show" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      {t("Immediately inform the KMC Control Room/Borough Office/local Ward Office")}.                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading2">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                      {t("Removal of House Drainage Chockage")}
                    </button>
                    </div>
                    <div id="collapse2" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      {t("Contact the Ward Office/Borough Office. Rs. 185 in favour of KMC")}.
                      </div>                      
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading3">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                      {t("Removal of Chockage of sewer on KMC thoroughfare")}.
                    </button>
                    </div>
                    <div id="collapse3" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      {t("Contact the local Ward Office/ Office/Control Room")}                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                      {t("Drainage connection for New House")}   
                    </button>
                    </div>
                    <div id="collapse4" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                      <ul className="kmc-function-list">
                                  <li>
                                      {t("Apply through enlisted plumber of KMC. List of such plumber will be available at the Borough Office")}.
                                  </li>
                                  <li>
                                      {t("Select a plumber from your own choice. The plumber would assist you for getting the sanction to the house drainage plan of your premises and he will execute the internal drainage system and effect the house drainage connection finally to municipal drain/sewer under supervision of Engineering Dept")}.
                                  </li>
                          </ul>    </div>                </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading5">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                      {t("Do I need a license for a restaurant, cyber café, cinema hall etc")}?
                    </button>
                    </div>
                    <div id="collapse5" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">
                        {t("Yes. Please write a letter on your business letterhead or on plain paper addressed to the Sr. Amusement Officer, KMC seeking a license for your concerned amusement activity. Please attach")},
                          <ul class="kmc-function-list">			 
                            <li>   {t("Recent rent bills")} </li>
                            <li>   {t("Electricity bills")}. </li>
                            <li>   {t("Rental agreement paper or purchase deed to prove lawful tenancy or ownership of proposed amusement site")}. </li>
                          </ul>
                          {t("Please refer to Schedule of Fees & Charges to determine applicable license fee")}.
                        </div>
                      </div>
                  </div>
                  <div className="card">
                  <div className="card-header" id="heading6">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                    {t("Documents required for new house drainage connection")} 
                  </button>
                  </div>
                  <div id="collapse6" className="collapse" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    <ul className="kmc-function-list">
                          <li> {t("Plumber Neogpatra/Letter of Engagement")}.</li>
                          <li> {t("Copy of Building Sanction Plan alongwith the original copy for verification purpose")}.</li>
                          <li> {t("Lay out of the proposed internal house drainage plan showing the proposed connection with the KMC sewer")}.</li>
                          <li> {t("Photo copy of the enlistment Certificate of Plumber")}</li>
                          <li> {t("Current Tax Bill")} </li>
                          <li> {t("NOC from Assessment & Collection Deptt. and LAw Deptt")}.</li>
                          <li> {t("Occupancy Certificate during effecting House Drainage Connection")}. </li>
                          </ul></div>  
                    </div>
                  </div>
                  <div className="card">
                  <div className="card-header" id="heading7">

                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                    {t("Carriageway sanction plan")}                  </button>
                  </div>
                  <div id="collapse7" className="collapse" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                      <ul className="kmc-function-list">
                        <li> {t("In case of new building sanction of carriageway is compulsory")}. </li>
                        <li> {t("Engage KMC enlisted LBA/LBS/Plumber/Enlisted P.I. Contractor for getting sanction of carriageway plan")}. </li>
                        <li> {t("Deposit the required fees")}. </li>
                        <li> {t("Submit the Neogpatra/Letter of Engagement of LBA/LBS/Plumber/Enlisted P.I. Contractor for getting the sanction of carriageway plan")}</li>
                        <li> {t("Copy of building sanction plan alongwith the original copy for verification purpose")}. </li>
                        <li> {t("Drawing of the proposed carriageway plan")}. </li>
                        <li> {t("Photo copy of the enlisted plumber/LBA/LBS/Enlisted P.I. Contractor")}.</li>
                        <li> {t("Current Tax Bill")}. </li>
                      </ul>                    </div>
                      </div>
                  </div>
                  <div className="card">
                  <div className="card-header" id="heading8">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
                    {t("Repairing of pot-holes on roads")}
                  </button>
                  </div>
                  <div id="collapse8" className="collapse" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    {t("Inform the local Ward Office/Borough Office/KMC Control Room")}.    </div>
                    </div>
                  </div>
                  <div className="card">
                  <div className="card-header" id="heading9">
                    <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
                    {t("Repairing of pot-holes on roads")}
                  </button>
                  </div>
                  <div id="collapse9" className="collapse" data-parent="#accordion">
                    <div className="card-body m-3 fontSizeClass">
                    {t("Inform the local Ward Office/Borough Office/KMC Control Room")}.    </div>
                    </div>
                  </div>
                  </div>
                    </div>
                  </div>
                </div>
                <br />
                <h2 className="fontSizeClass">{t("Don'ts")}</h2>
                <ul className="kmc-function-list">
                  <li> {t("Do not allow contaminated water on the roads")} </li>
                  <li>
                    
                    {t("Do not occupy any road/footpath to construct any structure/garden")}
                  </li>
                  <li>
                    
                    {t("Do not urinate in any open area other than the public urinals")}
                  </li>
                  <li>
                    
                    {t("Do not prune/cut off any tree/sapling in front of your premise without KMC's permission")}
                  </li>
                  <li>
                    
                    {t("Do not throw wastes/garbage on KMC roads. For this contact the Borough Office")}
                  </li>
                  <li> {t("Do not let your pet dog excrete on the roads")}.</li>
                  <p className="fontSizeClass">
                    {t("Help us to make Kolkata, a clean, green city. As a dutiful citizen, your responsibilities, awareness and civic knowledge are necessary. The above directions will definitely show you the right way")}.
                  </p>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Engineering_Home