import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";


const Engineering_Manhole_Desilting_Programme  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
 
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
    </div>
  </section>
  {/* End Slider */}
  

  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  ">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Engineering_Home'>                    
                    {t("Engineering (Civil) Home")}
                  </Link>
                </li>
                <li className="nav-item"> 
                  <Link className="nav-link fontSizeClass" to='/Engineering_Rates_Charges'>
                    
                    {t("Rates & Charges")}
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCRoadDevelopmentDetails.jsp'> {t("KMC Road Development Scheme")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Engineering_Manhole_Desilting_Programme'>       
                    {t("Manhole Desilting Programme of Borough-1 to XVI under Civil Dept")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Engi_Man_Desil_Prog_civil_Bro_30_6_2024'>                   
                    {t("Manh Desi Prog of Bor-1 to XVI under Civil Dept 30-6-24")}
                  </Link>
                </li>


                <li className="nav-item">
                  <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('284')}> {t("Tender Form Format")} </a> 
                </li>
                <li className="nav-item">
                    <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/ChiefEngContact.jsp'> {t("Contact Details")} </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
            <div className="container">
              <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass">{t("Manholes without Cover? Damaged Roads? Uprooted Trees?")} </h3>
              </div>
                <div className="about-head1">
                  <div className="dig-login1 dig-login2">
                    <form>
                      <div className="log-form inner-log-form">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 form-group pt-2">
                            <div className="list-of-forms">
                              <h6 className="fontSizeClass "><a className="fontSizeClass" onClick={(e)=>fileDownload('449')}> 1. {t("Manhole desilting Br-I")} </a> </h6>
                              <h6 className="fontSizeClass mt-3"><a className="fontSizeClass" onClick={(e)=>fileDownload('450')}> 2. {t("Manhole desilting Br-II")} </a> </h6>
                              <h6 className="fontSizeClass mt-3"><a className="fontSizeClass" onClick={(e)=>fileDownload('451')}> 3. {t("Manhole desilting Br")}-III </a> </h6>
                              <h6 className="fontSizeClass mt-3"><a className="fontSizeClass" href="assets/img/Manhole_desilting_Br_IV.xls" download="Manhole_desilting_Br_IV.xls"><i className="bi bi-download" target="_blank" /> 4. {t("Manhole desilting Br")}-IV </a></h6>

                              <h6 className="fontSizeClass mt-3"><a className="fontSizeClass" onClick={(e)=>fileDownload('452')}> 5. {t("Manhole desilting Br")}-V </a> </h6>
                              <h6 className="fontSizeClass mt-3"><a className="fontSizeClass" href="assets/img/Manhole_desilting_Br_VI.xls" download="Manhole_desilting_Br_VI.xls"><i className="bi bi-download" target="_blank" /> 6. {t("Manhole desilting Br")}-VI </a> </h6>
                              <h6 className="fontSizeClass mt-3"><a className="fontSizeClass" onClick={(e)=>fileDownload('453')}> 7. {t("Manhole desilting Br")}-VII </a> </h6>
                              <h6 className="fontSizeClass mt-3"><a className="fontSizeClass" onClick={(e)=>fileDownload('454')}> 8. {t("Manhole desilting Br")}-VIII </a> </h6>
                              <h6 className="fontSizeClass mt-3"><a className="fontSizeClass" href="assets/img/Manhole_desilting_Br_IX.xls" download="Manhole_desilting_Br_IX.xls"><i className="bi bi-download" target="_blank" /> 9. {t("Manhole desilting Br")}-IX </a> </h6>
                              <h6 className="fontSizeClass mt-3"><a className="fontSizeClass" href="assets/img/Manhole_desilting_Br_X_compressed.pdf" download="Manhole_desilting_Br_X_compressed.pdf"><i className="bi bi-download" target="_blank" /> 10. {t("Manhole desilting Br")}-X  </a> </h6>
                              <h6 className="fontSizeClass mt-3"><a className="fontSizeClass" href="assets/img/Manhole_desilting_Br_XI.xls" download="Manhole_desilting_Br_XI.xls"><i className="bi bi-download" target="_blank" /> 11. {t("Manhole desilting Br")}-XI  </a> </h6>
                              <h6 className="fontSizeClass mt-3"><a className="fontSizeClass" href="assets/img/Manhole_desilting_Br_XII.xls" download="Manhole_desilting_Br_XII.xls"><i className="bi bi-download" target="_blank" /> 12. {t("Manhole desilting Br")}-XII  </a> </h6>
                              <h6 className="fontSizeClass mt-3"><a className="fontSizeClass" onClick={(e)=>fileDownload('456')}> 13. {t("Manhole desilting Br")}-XIII </a> </h6>
                              <h6 className="fontSizeClass mt-3"><a className="fontSizeClass" href="assets/img/Manhole_desilting_Br_XIV.xls" download="Manhole_desilting_Br_XIV.xls"><i className="bi bi-download" target="_blank" /> 14. {t("Manhole desilting Br")}-XIV </a> </h6>
                              <h6 className="fontSizeClass mt-3"><a className="fontSizeClass" href="assets/img/Manhole_desilting_Br_XV.xls" download="Manhole_desilting_Br_XV.xls"><i className="bi bi-download" target="_blank" /> 15. {t("Manhole desilting Br")}-XV </a> </h6>
                              <h6 className="fontSizeClass mt-3"><a className="fontSizeClass" href="assets/img/Manhole_desilting_Br_XVI.xls" download="Manhole_desilting_Br_XVI.xls"><i className="bi bi-download" target="_blank" /> 16. {t("Manhole desilting Br")}-XVI </a> </h6>

                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Engineering_Manhole_Desilting_Programme