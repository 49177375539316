import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";


const BSUP = () => {
  const { t, i18n, ready } = useTranslation();
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
    const increaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
          var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
          var fontSize = parseFloat(style); 
          el[i].style.fontSize = (fontSize + 1) + 'px';
        }
      };
      const decreaseFontSizeBy1px =() => {
        var el = document.getElementsByClassName('fontSizeClass');
        for (let i = 0; i < el.length; i++) {
        var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
        var fontSize = parseFloat(style); 
        el[i].style.fontSize = (fontSize - 1) + 'px'; 
        }
      };
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
        <div className="container">
          
          <div className="section-main-heading2">
            
            <h4 className="fontSizeClass"> {t("BSUP")} </h4>
          </div>
        </div>
      </div>
    </section>
    {/* End Slider */}
   
    <section className="section inner-pad1">
      <div className="container " >
        <div className="row">
          <div className="col-lg-3">
          <div className="site-menu">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link  fontSizeClass" to='/KMC_Bustee_services'> {t("Bustee Services")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/BSUP'> {t("BSUP")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('247')}> {t("Result of Lottery held at Nonadanga")} </a>
                  
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BusteeDemandView.jsp'> {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BusteeDemandView.jsp'> {t("Print Unpaid Bill")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BusteePayment.jsp'> {t("Make Online Payment")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BusteeDuplicateReceipt.jsp'> {t("Reprint e-Receipt")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BSContact.jsp'> {t("Contact Details")}</a>
                </li>
              </ul>
            </nav>
          </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2">
                  <h3 className="fontSizeClass"> {t("BSUP")} </h3>
                </div>
                <div className="about-head1">
                  <div className="dig-login1 dig-login2">
                    <form>
                      <div className="log-form inner-log-form">
                        <h3 className="fontSizeClass"> {t("BENIFICIARY LIST - BSUP")}</h3>
                        <div className="row">
                             <div className="col-md-12 map2">
                                <a type="button" className="btn " data-toggle="collapse" data-target="#demo">{t("PACKAGE")} - I <i className="bi bi-chevron-down" /></a>
                                <div id="demo" className="collapse">
                                    <div className="mayor-table health-dept-table" >
                                            <table width="100%" border={0} align="center"
                                            cellPadding={2} cellSpacing={2} className="table table-bordered officials" >
                                            <tbody>
                                            <tr bgcolor="#302ba0" align="center" valign="middle">
                                                <td width="100%" className="text-white tble-brder-left"> {t("KACHARIPARA")} </td>
                                            </tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('248')}>1 - 249</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('249')}>250 - 429</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('250')}>430 - 529</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('251')}>530 - 759</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('252')}>760 - 864 & 1 - 94</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('253')}>Shop Owners</a></td></tr>
                                            </tbody>
                                            </table>
                                            <br />
                                            <table width="100%" border={0} align="center"
                                            cellPadding={2} cellSpacing={2} className="table table-bordered officials" >
                                            <tbody>
                                            <tr bgcolor="#302ba0" align="center" valign="middle">
                                                <td width="100%" className="text-white tble-brder-left"> {t("KACHARIPARA")} </td>
                                            </tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('254')}>1 - 142</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('255')}>143 - 393</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('256')}>394 - 623</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('257')}>624 - 947</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('258')}>948 - 1152</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('259')}>1153 - 1327</a></td></tr>

                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('260')}>1328 - 1479</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('261')}>1480 - 1704</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('262')}>1705 - 1813</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('263')}>1814 - 1901</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('264')}>1902 - 2050</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('265')}>2051 - 2181</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('266')}>2182 - 2313</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('267')}>2314 - 2488</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('268')}>2489 - 2613</a></td></tr>

                                            </tbody>
                                            </table>
                                            <br />
                                            <table width="100%" border={0} align="center"
                                            cellPadding={2} cellSpacing={2} className="table table-bordered officials" >
                                            <tbody>
                                            <tr bgcolor="#302ba0" align="center" valign="middle">
                                                <td width="100%" className="text-white tble-brder-left"> HATGACHIA </td>
                                            </tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('269')}>1 - 727</a></td></tr>
                                            </tbody>
                                            </table>
                                            <br />
                                    </div>
                                </div>
                              </div>
                              <div className="col-md-12 map2">
                                <a type="button" className="btn" data-toggle="collapse" data-target="#demo1">PACKAGE - II <i className="bi bi-chevron-down" /></a>
                                <div id="demo1" className="collapse">
                                <div className="card-body m-3 fontSizeClass">
                                            <table width="100%" border={0} align="center"
                                            cellPadding={2} cellSpacing={2} className="table table-bordered officials" >
                                            <tbody>
                                            <tr bgcolor="#302ba0" align="center" valign="middle">
                                                <td width="100%" className="text-white tble-brder-left"> ANANDA NAGAR </td>
                                            </tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('270')}>1 - 90</a></td></tr>
                                            </tbody>
                                            </table>
                                            <br />
                                            <table width="100%" border={0} align="center"
                                            cellPadding={2} cellSpacing={2} className="table table-bordered officials" >
                                            <tbody>
                                            <tr bgcolor="#302ba0" align="center" valign="middle">
                                                <td width="100%" className="text-white tble-brder-left"> JALPARA </td>
                                            </tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('271')}>1 - 218</a></td></tr>
                                            </tbody>
                                            </table>
                                            <br />
                                            <table width="100%" border={0} align="center"
                                            cellPadding={2} cellSpacing={2} className="table table-bordered officials" >
                                            <tbody>
                                            <tr bgcolor="#302ba0" align="center" valign="middle">
                                                <td width="100%" className="text-white tble-brder-left"> GARDENRICH </td>
                                            </tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('272')}>1 - 219</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('273')}>220 - 417</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('274')}>418 - 594</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('275')}>595 - 739</a></td></tr>

                                            </tbody>
                                            </table>
                                            <br />
                                        </div>
                                </div>
                              </div>
                              <div className="col-md-12 map2">
                                <a type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("PACKAGE")} - III <i className="bi bi-chevron-down" /></a>
                                <div id="demo2" className="collapse">
                                    <div className="mayor-table health-dept-table" >
                                            <table width="100%" border={0} align="center"
                                            cellPadding={2} cellSpacing={2} className="table table-bordered officials" >
                                            <tbody>
                                            <tr bgcolor="#302ba0" align="center" valign="middle">
                                                <td width="100%" className="text-white tble-brder-left"> PAGLADANGA - I </td>
                                            </tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('276')}>1 - 87</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('277')}>88 - 174</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('278')}>175 - 293</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('281')}>294 - 380</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('280')}>381 - 467</a></td></tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('279')}>468 - 582</a></td></tr>
                                            </tbody>
                                            </table>
                                            <br />
                                            <table width="100%" border={0} align="center"
                                            cellPadding={2} cellSpacing={2} className="table table-bordered officials" >
                                            <tbody>
                                            <tr bgcolor="#302ba0" align="center" valign="middle">
                                                <td width="100%" className="text-white tble-brder-left"> BANERJEE PARA </td>
                                            </tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('282')}>1 - 32</a></td></tr>
                                            </tbody>
                                            </table>
                                            <br />
                                            <table width="100%" border={0} align="center"
                                            cellPadding={2} cellSpacing={2} className="table table-bordered officials" >
                                            <tbody>
                                            <tr bgcolor="#302ba0" align="center" valign="middle">
                                                <td width="100%" className="text-white tble-brder-left"> SENPALLY </td>
                                            </tr>
                                            <tr><td><a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('283')}>1 - 32</a></td></tr>
                                            </tbody>
                                            </table>
                                    </div>
                                </div>
                             </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default BSUP