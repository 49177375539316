import React, { useState, useEffect } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
const ScrollNavbar = () => {
  const [scrollNav, setScrollNav] = useState("navbar-fixed");
  const { t, i18n, ready } = useTranslation();

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setScrollNav("navbar-fixed");
    } else if (window.scrollY > 70) {
      return setScrollNav("navbar-fixed2");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
    },
  };

  return (
    <div className={scrollNav} id="google_translate_element">
      <div className="w-100">
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={false}
          centerMode={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={1500}
          keyBoardControl={true}
          transitionDuration={50}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["desktop", "mobile"]}
          deviceType={"desktop"}
          itemClass="carousel-item-padding-40-px"
        >
          <li className="nav-item-list">
            <Link exact to="/" className="nav-scroll-link">
            {t("Menu_item1")}
            </Link>
          </li>
          <li className="nav-item-list">
            <Link
              exact
              to="/MunicipalHistory" className="nav-scroll-link">
              {t("Menu_item5")}
            </Link>
          </li>

          <li className="nav-item-list">
            <Link
              exact
              to="/Citizen_services"
              className="nav-scroll-link">
              {t("Menu_item3")}
            </Link>
          </li>
          <li className="nav-item-list">
            <Link
              exact
              to="/tender"
              className="nav-scroll-link" >
             {t("Menu_item4")}
            </Link>
          </li>
          <li className="nav-item-list">
            <Link
              exact
              to="/AboutUs"
              className="nav-scroll-link" >
              {t("Menu_item2")}
            </Link>
          </li>
        </Carousel>
      </div>
    </div>
  );
};
export default ScrollNavbar;
