import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";


const Engineering_Contact_Details  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
  
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass"> Ch V&amp;S Officials </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
 
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link active1 fontSizeClass" to='/Engineering_Home'>
                    
                    Engineering (Civil) Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Central_Records_Fees'>
                    
                    Rates &amp; Charges
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/portal'>
                    
                    KMC Road Development Scheme
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Engineering_Manhole_Desilting_Programme'>
                    
                    Manhole Desilting Programme of Borough-1 to XVI under Civil
                    Dept
                  </Link>
                </li>
                
                <li className="nav-item"> 
                  <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('284')}> Tender Form Format </a> 
                </li>
                
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Engineering_Contact_Details'>
                    Contact Details
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass"> Important Officials of Civil Department</h3>
              </div>
              <div className="mayor-table pump-list-tble">
                <table className="table table-bordered officials rm-mg">
                  <tbody>
                    <tr bgcolor="#302ba0" tabIndex={0}>
                      <td
                        width="10%"
                        rowSpan={2}
                        className="text-white tble-brder-left"
                        align="center"
                        valign="middle"
                      >
                        Sl. No.
                      </td>
                      <td
                        width="27%"
                        rowSpan={2}
                        align="center"
                        className="text-white"
                      >
                        Name
                      </td>
                      <td
                        width="27%"
                        rowSpan={2}
                        align="center"
                        className="text-white"
                      >
                        Designation
                      </td>
                      <td
                        colSpan={2}
                        rowSpan={2}
                        align="center"
                        className="text-white tble-brder-right"
                      >
                        
                        Office Phone No.
                      </td>
                    </tr>
                    <tr>
                      {/* <td width="20%" style="padding:3">Office</td>
                  <td width="13%" style="padding:3">Resi.</td> */}
                    </tr>
                    <tr align="center" valign="middle" tabIndex={0}>
                      <td>1.</td>
                      <td align="left">Pantu Kumar Dhua </td>
                      <td align="left">
                        O.S.D. &amp; E.O. <br />
                        Director General (Civil)
                      </td>
                      <td align="left">
                        2286-1249(D) <br />
                        2286-1000, Extn. 2444, I.C.-249
                      </td>
                    </tr>
                    <tr align="center" valign="middle" tabIndex={0}>
                      <td>2.</td>
                      <td align="left">Mridul Mondal</td>
                      <td align="left">Dy. Chief Engr. (Civil)/HQ-I</td>
                      <td align="left">
                        2286-1000, Extn. 2806 <br />
                      </td>
                    </tr>
                    <tr align="center" valign="middle" bgcolor="#fff">
                      <td>3.</td>
                      <td align="left">Anindya Kumar Ghosh </td>
                      <td align="left">Dy. Chief Engr. (Civil)/HQ-II</td>
                      <td align="left">2286-1000, Extn. 2223</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>4.</td>
                      <td align="left">Kamal Sarkar</td>
                      <td align="left">Dy. Chief Engr. (Civil/South) </td>
                      <td align="left">2286-1000, Extn. 2225 </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>5.</td>
                      <td align="left">Shubhasish Bhattacharjee </td>
                      <td align="left"> Dy. Chief Engr. (Civil)/Central </td>
                      <td align="left">2286-1000</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>6.</td>
                      <td align="left">Jyoti Prakash Sarkar </td>
                      <td align="left">Dy. Chief Engr. (Civil)/HQ-Project </td>
                      <td align="left">
                        2252-2535, <br />
                        2286-1000, Extn. 2882
                      </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>7.</td>
                      <td align="left">Dinabandhu Boral </td>
                      <td align="left">Ex. Officio Dy. C.E.(C)/Br-IX </td>
                      <td align="left">2479-1833</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>8.</td>
                      <td align="left">Santanu Dey </td>
                      <td align="left">A.C.E-II </td>
                      <td align="left">2286-1000, Extn. 2506</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>9.</td>
                      <td align="left">Ganapati Mandal </td>
                      <td align="left">Ex. Engr. (Civil/Br-I) </td>
                      <td align="left">2557-4232</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>10.</td>
                      <td align="left">Santunu Purokhayastha </td>
                      <td align="left">Ex. Engr. (Civil/Br-II) </td>
                      <td align="left">2555-9081</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>11.</td>
                      <td align="left">Subhashis Mondal </td>
                      <td align="left">Ex. Engr. (Civil/Br-III) </td>
                      <td align="left">2362-9955 (&amp; Fax)</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>12.</td>
                      <td align="left">Debashis Chattapadhyaya </td>
                      <td align="left">Ex. Engr. (Civil/Br-IV) </td>
                      <td align="left">2272-2933</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>13.</td>
                      <td align="left">Ashis Kumar Modak </td>
                      <td align="left">Ex. Engr. (Civil/Br-V) </td>
                      <td align="left">2241-3715 (&amp; Fax)</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>14.</td>
                      <td align="left">Debashis Sen </td>
                      <td align="left">Ex. Engr. (Civil/Br-VI) </td>
                      <td align="left">
                        2286-1216, <br />
                        2286-1000, Extn. 2536, I.C.-275
                      </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>15.</td>
                      <td align="left">Jayanta Sarkar </td>
                      <td align="left">Ex. Engr. (Civil/Br-VII) </td>
                      <td align="left">2290-6635</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>16.</td>
                      <td align="left">Papiya Chakrabarty </td>
                      <td align="left">Ex. Engr. (Civil/Br-VIII) </td>
                      <td align="left">
                        2466-6766, <br />
                        Fax-24652860
                      </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>17.</td>
                      <td align="left">Surojit Marik </td>
                      <td align="left">Ex. Engr. (Civil/Br-IX) </td>
                      <td align="left">
                        2479-1833 <br /> Fax-24568444
                      </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>18.</td>
                      <td align="left">Soumendra Nath Chandra </td>
                      <td align="left">Ex. Engr. (Civil/Br-X) </td>
                      <td align="left">2422-9043</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>19.</td>
                      <td align="left">Sunil Kumar Shaha </td>
                      <td align="left">Ex. Engr. (Civil/Br-XI) </td>
                      <td align="left">2425-8138</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>20.</td>
                      <td align="left">Pinaki Bhattacharya </td>
                      <td align="left">Ex. Engr. (Civil/Br-XII) </td>
                      <td align="left">2418-0646</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>21.</td>
                      <td align="left">Nurul Kader </td>
                      <td align="left">Ex. Engr. (Civil/Br-XIII) </td>
                      <td align="left">
                        2397-1102
                        <br />
                        Fax-23971053
                      </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>22.</td>
                      <td align="left">Jagannath Dey </td>
                      <td align="left">Ex. Engr. (Civil/Br-XIV) </td>
                      <td align="left">
                        2397-1103
                        <br />
                        Fax-23971476
                      </td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>23.</td>
                      <td align="left">Arup Kumar Mondal </td>
                      <td align="left">Ex. Engr. (Civil/Br-XV) </td>
                      <td align="left">2469-6049</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>24.</td>
                      <td align="left">Anupam Guha </td>
                      <td align="left">Ex. Engr. (Civil/Br-XVI) </td>
                      <td align="left">2467-0057 (&amp; Fax)</td>
                    </tr>
                    <tr align="center" valign="middle">
                      <td>25.</td>
                      <td align="left">Sibaji Bandapadhyay </td>
                      <td align="left">Ex. Engr. (Civil)/HQ </td>
                      <td align="left">
                        2252-2535
                        <br />
                        2286-1000, Extn. 2882
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Engineering_Contact_Details