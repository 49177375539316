import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";


const KMC_Results = () => {
  const { t, i18n, ready } = useTranslation();
  const increaseFontSizeBy1px = () => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style);
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const fileDownload = (fileStoreId) => {
    let url = `https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`;
    axios
      .post(url, { fileId: fileStoreId, userId: "392" })
      .then((res) => {
        console.log("response is --- ", res);

        if (res && res.data && res.status === 200) {
          const base64 = res.data[0] && res.data[0].base64_data;
          const pdfData = "data:application/pdf;base64," + base64;

          let html =
            "<html>" +
            "<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>" +
            "<body>" +
            '<iframe src="' +
            pdfData +
            '"></iframe>' +
            "</body></html>";
          console.log(html);
          let a = window.open();
          a.document.write(html);
        }
      })
      .catch((e) => {
        // setLoader(false);
        console.log("The error is ", e);
      });
  };
  const decreaseFontSizeBy1px = () => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style);
      el[i].style.fontSize = (fontSize - 1) + 'px';
    }
  };
  return (
    <>
      <section id="hero" className="hero hero2">
        <div
          className="social-media wow slideInDown"
          style={{ visibility: "visible", animationName: "slideInDown" }}
        >
          <div className="facebook" rel="ebInfo" title="facebook">
            <a
              href="https://www.facebook.com/KolkataMunicipalCorporation.official"
              target="_blank"
            >
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a
              href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp"
              target="_blank"
            >
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>

        <div className="carousel-item active">
          <img src="assets/img/inner-bg2.png" className="img-fluid" alt="img" />
        </div>
      </section>
      {/* End Slider */}
      <section className="section inner-pad1">
        <div className="container ">
          <div className="row">
            <div className="col-lg-3">
              <div className="site-menu  ">
                <nav className="sidebar card py-2">
                  <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/KMC_Recruitments"
                      >
                        {t("KMC Recruitments")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link active1 fontSizeClass"
                        to="/KMC_Results"
                      >
                        {t("KMC Results")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/KMC_Training"
                      >
                        {t("KMC Training")}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="">
                <div className="row">
                  <div className="inner-title2 bas">
                    <h3 className="fontSizeClass"> {t("KMC Results")} </h3>
                  </div>
                  <div className="mayor-table health-dept-table">
                    <table
                      width="95%"
                      border={0}
                      align="center"
                      cellPadding={2}
                      cellSpacing={2}
                      className="table table-bordered officials"
                    >
                      <tbody>
                        <tr
                          bgcolor="#302ba0"
                          tabIndex={0}
                          align="center"
                          valign="middle"
                        >
                          <td
                            width="90%"
                            className="text-white tble-brder-left"
                          >
                            {t("Description")}
                          </td>
                          <td width="10%" className="text-white">
                            {t("Date")}
                          </td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2033")}
                            >
                             List of selected candidates for the post of Medical Officer (contractual) under XV Finance Commission under Health Department of KMC. Ref: Adv. No H/03/KMC/2023 – 24. dated 07.08.2023
                            </a>
                          </td>
                          <td>27.09.2023</td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            {/* <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2028")}
                            >
                              4th list of selected candidates for the post of
                              Staff Nurse (contractual) for urban primary
                              health centers in Kolkata City NUHM Society
                              areas against Adv. No . 04/Kolkata City NUHM
                              Society/2022 – 23. Dated 06.09.2022
                            </a> */}
                             <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/StaffNurse_NUHM_22_09_2023.pdf" target={"_blank"}>
                              {t("4th list of selected candidates for the post of Staff Nurse (contractual) for urban primary health centers in Kolkata City NUHM Society areas against Adv. No . 04/Kolkata City NUHM Society/2022 – 23. Dated 06.09.2022")}</a>

                          </td>
                          <td>22.09.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2027")}
                            >
                              List of selected candidates for the post of
                              Specialist Medical Officer for Polyclinic
                              under Health Department KMC on contractual
                              basis Ref: Adv. No . H/02/KMC/2023 – 24. dated 15.07.2023
                            </a>
                          </td>
                          <td>20.09.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2026")}
                            >
                              4th list of selected candidates for the post
                              of Medical Officer for urban primary health
                              centers in Kolkata City NUHM Society areas on
                              contractual basis against Adv. No - 13/Kolkata
                              City NUHM Society/2022 – 23. dated 28.01.2023
                            </a>
                          </td>
                          <td>14.09.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2025")}
                            >
                              2nd list of selected candidates for the
                              post of Staff Nurse for Khidderpore U-CHC,
                              under Kolkata City NUHM Society on contractual
                              basis against Adv. No . 10 /Kolkata City NUHM
                              Society/2022 – 23. Dated 12.11.2022
                            </a>
                          </td>
                          <td>05.09.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2024")}
                            >
                              5th list of selected candidates for the post
                              of Medical Officer for Urban Health & Wellness
                              Centers under XV Finance Commission Health - Grant
                              under Health Department of Kolkata Municipal
                              Corporation Ref: Adv. No H/01/KMC/2022 – 23. Dated 20.10.2022
                            </a>
                          </td>
                          <td>05.09.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2023")}
                            >
                              3rd list of selected candidates for the post of
                              Staff Nurse for urban primary health centers under
                              Kolkata City NUHM Society on contractual basis
                              Ref: Adv. No . 04/Kolkata City NUHM Society/2022 – 23. Dated 06.09.2022
                            </a>
                          </td>
                          <td>26.08.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2022")}
                            >
                              List of selected candidates for the post of Medical Officer
                              (Part time) for urban primary health centers in Kolkata City
                              NUHM Society areas on contractual basis against Adv.
                              No - 04/Kolkata City NUHM Society/2023 – 24. dated 15.08.2023
                            </a>
                          </td>
                          <td>24.08.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2021")}
                            >
                              2nd list of selected candidate for the post of
                              Medical Officer for Khidderpore U-CHC under
                              Kolkata City NUHM Society on contractual basis
                              against Adv. No - 3/Kolkata City NUHM Society/2023 – 24. Dated 19.06.2023
                            </a>
                          </td>
                          <td>07.08.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            {/* <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2020")}
                            >
                              Interview on 11.08.2023,16.08.2023, 18.08.2023,
                              21.08.2023 to 23.08.2023, against Adv. No . No.
                              H/05/KMC/2022-23 dated 20.10.2022. for the post
                              of Staff Nurse (contractual) for Urban Health and
                              Wellness Centres under the XV th Finance Commission
                              Health Grant under the Health Department of Kolkata
                              Municipal Corporation
                            </a> */}
                             <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/StaffNurse_Health_03_08_2023.pdf" target={"_blank"}>
                              {t("Interview on 11.08.2023,16.08.2023, 18.08.2023, 21.08.2023 to 23.08.2023, against Adv. No . No. H/05/KMC/2022-23 dated 20.10.2022. for the post of Staff Nurse (contractual) for Urban Health and Wellness Centres under the XV th Finance Commission Health Grant under the Health Department of Kolkata Municipal Corporation")}</a>

                          </td>
                          <td>03.08.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            {/* <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2019")}
                            >
                              Interview on 07.08.2023 & 09.08.2023, against Adv. No . No.
                              H/06/KMC/2022-23 dated 20.10.2022. for the post of Staff Nurse
                              (contractual) for Polyclinic under the XV th Finance Commission
                              Health Grant under the Health Department of Kolkata Municipal Corporation
                            </a> */}
                             <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/StaffNurse_Polyclinic_03_08_2023.pdf" target={"_blank"}>
                              {t("Interview on 07.08.2023 & 09.08.2023, against Adv. No . No. H/06/KMC/2022-23 dated 20.10.2022. for the post of Staff Nurse (contractual) for Polyclinic under the XV th Finance Commission Health Grant under the Health Department of Kolkata Municipal Corporation")}</a>

                          </td>
                          <td>03.08.2023</td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2017")}
                            >
                              2nd list of selected candidate for the post of Laboratory
                              Technician for Khidderpore U-CHC under Kolkata City NUHM
                              Society on contractual basis against Adv. No - 8/Kolkata
                              City NUHM Society/2022 – 23. Dated 12.11.2022
                            </a>
                          </td>
                          <td>02.08.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2018")}
                            >
                              3rd List of selected candidates for the post
                              of Pharmacist for urban primary health centers
                              in Kolkata City NUHM Society areas on contractual
                              basis against Adv. No . 01/Kolkata City NUHM Society/2022 – 23.
                              dated 05.04.2022
                            </a>
                          </td>
                          <td>02.08.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            {/* <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2016")}
                            >
                              List of selected candidates for difference post, against Adv. No – KDTCS/Office/37/23 dated 03.06.2023
                            </a> */}
                             <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Result_KDTCS_28_07_2023.pdf" target={"_blank"}>
                              {t("List of selected candidates for difference post, against Adv. No – KDTCS/Office/37/23 dated 03.06.2023")}</a>

                          </td>
                          <td>28.07.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2015")}
                            >
                              List of selected candidates for the post of Medical
                              Officer & Specialist Medical Officer for Khiddrpore
                              (U-CHC) under Kolkata City NUHM Society on contractual
                              basis against Adv. No . 03/Kolkata City NUHM Society/2023 – 24.
                              dated 19.06.2023 and 02/Kolkata City NUHM Society/2023 – 24.
                              dated 06.06.2023
                            </a>
                          </td>
                          <td>25.07.2023</td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a style={{ cursor: "pointer" }}
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("2014")}
                            >
                              2nd List of selected candidates for the post of
                              Pharmacist for its urban primary health centers
                              in Kolkata City NUHM Society areas on contractual
                              basis against Adv. No . 01/Kolkata City NUHM Society/2022 – 23.
                            </a>
                          </td>
                          <td>19.07.2023</td>
                        </tr>



                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1764")}
                            >
                              List of selected candidate for the post of Quality
                              Manager on contractual basis against Adv. No -
                              12/Kolkata City NUHM Society/2022 – 23. dated
                              15.12.2022
                            </a>
                          </td>
                          <td>14.07.2023</td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1765")}
                            >
                              Corrigendum to Reference Memo No.
                              KDTCS/Office/68/23. dated 27.06.2023
                            </a>
                          </td>
                          <td>13.07.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1766")}
                            >
                              List of selected candidate for the post of Analyst
                              for Modified Food Safety on Wheels on Contractual
                              Basis, Under Health Department, Kolkata Municipal
                              Corporation against Adv. No H/04/KMC/2022-23 dt.
                              23.02.2023
                            </a>
                          </td>
                          <td>11.07.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1767")}
                            >
                              List of selected candidates for the post of
                              Community Health Assistants (Urban) under XV
                              Finance Commission Health Grant,Health Department
                              of Kolkata Municipal Corporation on contractual
                              basis against Adv. No . H/07/KMC/2022–23. Dated
                              20.10.2022
                            </a>
                          </td>
                          <td>11.07.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1768")}
                            >
                              List of selected candidates for the post of
                              Specialist Medical Officer for Polyclinic under
                              Health Department KMC on contractual basis against
                              on contractual basis against Ref. Adv. No .
                              H/01/KMC/2023 – 24. dated 06.06.2023
                            </a>
                          </td>
                          <td>10.07.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1769")}
                            >
                              3rd list of selected candidates for the post of
                              Medical Officer for urban primary health centers
                              in Kolkata City NUHM Society areas on contractual
                              basis against Adv. No - 13/Kolkata City NUHM
                              Society/2022 – 23. Dated 28.01.2023
                            </a>
                          </td>
                          <td>01.07.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1770")}
                            >
                              4th list of selected candidates for the post of
                              Medical Officer for Khidderpore U-CHC under
                              Kolkata City NUHM Society on contractual basis
                              against Adv. No - 7/Kolkata City NUHM Society/2022
                              – 23. Dated 12.11.2022
                            </a>
                          </td>
                          <td>01.07.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1771")}
                            >
                              4th list of selected candidates for the post of
                              Medical Officer under Health Department KMC on
                              contractual basis against Adv. No H/01/KMC/2022 –
                              23. Dated 20.10.2022
                            </a>
                          </td>
                          <td>01.07.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1772")}
                            >
                              List of selected candidates for the Computer Test
                              and Interview post of Laboratory Technician, STS,
                              STLS and TBHV against Memo. No.
                              KDTCS/Office/37/23. dated 03.06.2023.
                            </a>
                          </td>
                          <td>10.07.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1773")}
                            >
                              List of selected candidates for the post of
                              Medical Officer (Part time) for urban primary
                              health centers in Kolkata City NUHM Society areas
                              on contractual basis against Adv. No - 01/Kolkata
                              City NUHM Society/2023 – 24. Dated 12.04.2023.
                            </a>
                          </td>
                          <td>14.06.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1774")}
                            >
                              3rd list of selected candidates for the post of
                              Medical Officer under Health Department KMC on
                              contractual basis against Adv. No H/01/KMC/2022 –
                              23. Dated 20.10.2022.
                            </a>
                          </td>
                          <td>13.06.2023</td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1775")}
                            >
                              The Computer Test & Interview for the post of
                              Quality Manager against Adv. No . 12/Kolkata City
                              NUHM Society/2022 – 23. dated 15.12.2022 under
                              Kolkata City NUHM Society.
                            </a>
                          </td>
                          <td>09.06.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1776")}
                            >
                              List of selected candidates for the post of
                              Laboratory Technician, Pharmacist, Staff Nurse &
                              Ophthalmic Assistant for Khidderpore U-CHC, under
                              Kolkata City NUHM Society on contractual basis
                              against Adv. No . 08,9,10 & 11/Kolkata City NUHM
                              Society/2022 – 23. Dated 12.11.2022
                            </a>
                          </td>
                          <td>03.06.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1777")}
                            >
                              2nd list of selected candidates for the post of
                              Medical Officer under XV th Finance Commission,
                              Health Department KMC on contractual basis against
                              Adv. No H/01/KMC/2022 – 23. Dated 20.10.2022
                            </a>
                          </td>
                          <td>29.05.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1778")}
                            >
                              Written Test, Computer Skill Test & Interview
                              against Adv. No . 12/Kolkata City NUHM
                              Society/2022 – 23. dated 15.12.2022 for the post
                              of Quality Manager (contractual) under the Kolkata
                              City NUHM Society
                            </a>
                          </td>
                          <td>27.05.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1779")}
                            >
                              3rd List of selected candidates for the post of
                              Medical Officer for Khidderpore U-CHC under
                              Kolkata City NUHM Society on contractual basis
                              against Adv. No - 7/Kolkata City NUHM Society/2022
                              – 23. Dated 12.11.2022
                            </a>
                          </td>
                          <td>27.05.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1780")}
                            >
                              List of selected candidates for the Computer Test
                              on 18.05.2023 post of Pharmacist and Laboratory
                              Technician on contractual basis against Adv. No.
                              08/Kolkata City NUHM Society/2022 – 23. and
                              09/Kolkata City NUHM Society/2022 – 23. Dated
                              12.11.2023
                            </a>
                          </td>
                          <td>11.05.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td width="90%">
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1781")}
                            >
                              2nd list of selected candidates for the post of
                              Medical Officer for Khidderpore U-CHC under
                              Kolkata City NUHM Society on contractual basis
                              against Adv. No - 7/Kolkata City NUHM Society/2022
                              – 23. Dated 12.11.2022
                            </a>
                          </td>
                          <td width="10%">04.05.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1782")}
                            >
                              Interview on 04.05.2023 against Adv. No .
                              11/Kolkata City NUHM Society/2022 – 23. for the
                              post of Ophthalmic Assistant contractual for
                              Khidderpore U-CHC under the Kolkata City NUHM
                              Society
                            </a>
                          </td>
                          <td>28.04.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1783")}
                            >
                              Interview on 12.05.2023 & 15.05.2023, against Adv.
                              No . 10/Kolkata City NUHM Society/2022 – 23. for
                              the post of Staff Nurse contractual for
                              Khidderpore U-CHC under the Kolkata City NUHM
                              Society
                            </a>
                          </td>
                          <td>28.04.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1784")}
                            >
                              2nd List of selected candidates for the post of
                              Medical Officer for urban primary health centers
                              in Kolkata City NUHM Society areas on contractual
                              basis against Adv. No - 13/Kolkata City NUHM
                              Society/2022 – 23. Dated 28.01.2023
                            </a>
                          </td>
                          <td>18.04.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1785")}
                            >
                              List of selected candidates for the post of
                              Medical Officer for urban primary health centers
                              in Kolkata City NUHM Society areas on contractual
                              basis against Adv. No.13/Kolkata City NUHM
                              Society/2022 – 23. Dated 28.01.2023
                            </a>
                          </td>
                          <td>10.03.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1787")}
                            >
                              List of selected candidates for the post of Staff
                              Nurse for urban primary health centers in Kolkata
                              City NUHM Society areas on contractual basis
                              against Adv. No . 04/Kolkata City NUHM
                              Society/2022 – 23. dated 06.09.2022
                            </a>
                          </td>
                          <td>24.02.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1788")}
                            >
                              List of selected candidates for the post of
                              Medical Officer (Part time) for urban primary
                              health centers in Kolkata City NUHM Society areas
                              on contractual basis against Adv. No - 06/Kolkata
                              City NUHM Society/2022 – 23. Dated 13.01.2023
                            </a>
                          </td>
                          <td>22.02.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1789")}
                            >
                              List of selected candidates for the post of
                              Specialist Medical Officer for Polyclinic under
                              Health Department KMC on contractual basis against
                              on contractual basis against Adv. No .
                              H/02/KMC/2022 – 23. dated 20.10.2022
                            </a>
                          </td>
                          <td>01.02.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1790")}
                            >
                              List of selected candidates for the post of
                              Medical Officer under Health Department KMC on
                              contractual basis against Adv. No H/01/KMC/2022 –
                              23. Dated 20.10.2022
                            </a>
                          </td>
                          <td>01.02.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1791")}
                            >
                              List of selected candidates for the post of
                              Specialist Medical Officer Medical Officer for
                              Khiddrpore (U-CHC) under Kolkata City NUHM Society
                              on contractual basis against Adv. No . 07/Kolkata
                              City NUHM Society/2022 – 23. dated 12.11.2022
                            </a>
                          </td>
                          <td>30.01.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1792")}
                            >
                              List of selected candidates for the post of
                              Community Health Assistants (Urban) for its urban
                              primary health centers in Kolkata City NUHM
                              Society areas on contractual basis against Adv. No
                              . 03/Kolkata City NUHM Society/2022– 23. Dated
                              22.07.2022
                            </a>
                          </td>
                          <td>30.01.2023</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              href="https://www.kmcgov.in/KMCPortal/downloads/Staff_Nurse_NUHM_08_12_2022.pdf" target="_blank" >
                              An Interview for Staff Nurse on contractual basis
                              against Adv. No .04/Kolkata City NUHM Society/2022
                              – 23. Dated 06.09.2022
                            </a>
                          </td>
                          <td>08.12.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1793")}
                            >
                              6th List of selected candidates for the post of
                              Medical Officer for its urban primary health
                              centers in Kolkata City NUHM Society areas on
                              contractual basis against Adv. No . 02/Kolkata
                              City NUHM Society/2022 – 23. Dated 26.04.2022
                            </a>
                          </td>
                          <td>26.11.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1794")}
                            >
                              5th List of selected candidates for the post of
                              Medical Officer for its urban primary health
                              centers in Kolkata City NUHM Society areas on
                              contractual basis against Adv.No. 02/Kolkata City
                              NUHM Society/2022 – 23. Dated 26.04.2022
                            </a>
                          </td>
                          <td>05.11.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1795")}
                            >
                              List of shortlisted candidates called for
                              interview for the post of 32 nos. Community
                              Organiser as per Advt. No. KMC/NULM/01/2021-2022
                              dated 02.08.2021
                            </a>
                          </td>
                          <td>21.10.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1796")}
                            >
                              List of selected candidates for the post of
                              Medical Officer(part time) for its urban primary
                              health centers in Kolkata City NUHM Society areas
                              on contractual basis against Adv. No . 05/Kolkata
                              City NUHM Society/2022 – 23. Dated 09.09.2022
                            </a>
                          </td>
                          <td>18.10.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1797")}
                            >
                              4th List of selected candidates for the post of
                              Medical Officer for its urban primary health
                              centers in Kolkata City NUHM Society areas on
                              contractual basis against Adv. No . 02/Kolkata
                              City NUHM Society/2022 – 23. Dated 26.04.2022
                            </a>
                          </td>
                          <td>14.10.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1798")}
                            >
                              3rd List of selected candidates for the post of
                              Medical Officer for its urban primary health
                              centers in Kolkata City NUHM Society areas on
                              contractual basis against Adv. No . 02/Kolkata
                              City NUHM Society/2022 – 23. Dated 26.04.2022
                            </a>
                          </td>
                          <td>31.08.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1799")}
                            >
                              2nd List of selected candidates for the post of
                              Medical Officer for its urban primary health
                              centers in Kolkata City NUHM Society areas on
                              contractual basis against Adv. No . 02/Kolkata
                              City NUHM Society/2022 – 23. Dated 26.04.2022
                            </a>
                          </td>
                          <td>29.07.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1800")}
                            >
                              List of selected candidates for the Computer Test
                              post of Pharmacist on contractual basis against
                              Adv. No .01/Kolkata City NUHM Society/2022 – 23
                            </a>
                          </td>
                          <td>19.07.2022 </td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1801")}
                            >
                              4th list of selected candidates for the post of
                              Staff Nurse (contractual) for its urban primary
                              health centers in Kolkata City NUHM Society areas
                              on contractual basis against Adv. No .02/Kolkata
                              City NUHM Society/2021 – 22. Dated 04.05.2021
                            </a>
                          </td>
                          <td>23.06.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1802")}
                            >
                              List of selected candidates for the post of
                              Medical officer & Medical officer (part time) on
                              contractual basis for its urban primary health
                              centers in Kolkata City NUHM Society areas on
                              contractual basis against Adv. No. 02/Kolkata City
                              NUHM Society/2022 –23
                            </a>
                          </td>
                          <td>16.06.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1803")}
                            >
                              List of selected candidate for the post of
                              Accounts Assistant on contractual basis against
                              Adv. No 08/Kolkata City NUHM Society/2021-22.
                              Dated 18.11.2021
                            </a>
                          </td>
                          <td>05.04.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1804")}
                            >
                              List of selected candidates for the post of
                              Medical Officer & Medical Officer part time for
                              its urban primary health centers in Kolkata City
                              NUHM Society areas on contractual basis against
                              Adv. No . 10/Kolkata City NUHM Society/2021 – 22.
                            </a>
                          </td>
                          <td>05.04.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1805")}
                            >
                              2nd List of selected candidates for the post of
                              Pharmacist for its urban primary health centers in
                              Kolkata City NUHM Society areas on contractual
                              basis against Adv. No . 03/Kolkata City NUHM
                              Society/2021 – 22.dated 06.05.2021
                            </a>
                          </td>
                          <td>31.03.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1806")}
                            >
                              List of selected candidates for the post of
                              Laboratory Technician for its urban primary health
                              centers in Kolkata City NUHM Society areas on
                              contractual basis against Adv. No . 09/Kolkata
                              City NUHM Society/2021 – 22. Dated 22.11.2021.
                            </a>
                          </td>
                          <td>04.03.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1807")}
                            >
                              Interview on 08.03.2022, against Adv. No .
                              08/Kolkata City NUHM Society/2021 – 22. for the
                              post of Accounts Assistant contractual under the
                              Kolkata City NUHM Society.
                            </a>
                          </td>
                          <td>28.02.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1808")}
                            >
                              Medical Officer & Medical Officer part time for
                              its urban primary health centers in Kolkata City
                              NUHM Society areas on contractual basis against
                              Adv. No . 07/Kolkata City NUHM Society/2021 – 22.
                            </a>
                          </td>
                          <td>03.02.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1809")}
                            >
                              List of selected candidates for the Compute Test
                              post of Laboratory Technicians against Adv. No.
                              09/Kolkata City NUHM Society/2021-22. Under
                              Kolkata City NUHM Society.
                            </a>
                          </td>
                          <td>31.01.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1810")}
                            >
                              List of selected candidates for the Compute Test
                              post of Accounts Assistant against Adv. No.
                              08/Kolkata City NUHM Society/2021-22. Under
                              Kolkata City NUHM Society.
                            </a>
                          </td>
                          <td>31.01.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1811")}
                            >
                              List of selected candidates for the post of ANM
                              (contractual) for its urban primary health centers
                              in Kolkata City NUHM Society areas on contractual
                              basis against Adv. Memo No . 227/NUHM/KOL/19 –
                              20/Dated 14/06/2019.
                            </a>
                          </td>
                          <td>25.01.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1812")}
                            >
                              3rd list of selected candidates for the post of
                              Staff Nurse (contractual) for its urban primary
                              health centers in Kolkata City NUHM Society areas
                              on contractual basis against Adv. No . 02/Kolkata
                              City NUHM Society/2021 – 22.
                            </a>
                          </td>
                          <td>10.01.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1813")}
                            >
                              List of selected candidates for the post of
                              Pharmacist (on contractual basis) for its urban
                              primary health centers in Kolkata City NUHM
                              Society areas against Adv. No . 03/Kolkata City
                              NUHM Society/2021 – 22
                            </a>
                          </td>
                          <td>10.01.2022</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1814")}
                            >
                              list of selected candidate for the post of
                              Sub-Registrars on contractual basis for its
                              Burning Ghats/Burial Grounds in Kolkata Municipal
                              Corporation areas against Adv. No . H/01/KMC/2021
                              – 22. Dated 12.06.2021
                            </a>
                          </td>
                          <td>17.11.2021 </td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1815")}
                            >
                              List of selected candidates for the post of
                              Medical officer & Medical officer (part time) on
                              contractual basis for its urban primary health
                              centers in Kolkata City NUHM Society areas on
                              contractual basis against Adv. No . 06/Kolkata
                              City NUHM Society/2021 – 22. Dt. 28.08.2021
                            </a>
                          </td>
                          <td>08.10.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1816")}
                            >
                              2nd list of selected candidates for the post of
                              Staff Nurse (contractual) for its urban primary
                              health centers in Kolkata City NUHM Society areas
                              on contractual basis against Adv. No . 02/Kolkata
                              City NUHM Society/2021 – 22
                            </a>
                          </td>
                          <td>24.09.2021</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1817")}
                            >
                              Corrigendum to Memo No: 227/NUHM/KOL/19-20. Call
                              date is change from 31.09.2019 to 31.10.2019 and
                              01.10.2019 to 01.11.2019.
                            </a>
                          </td>
                          <td>31.10.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1818")}
                            >
                              List Of Selected Candidates For The Post Of (ANM)
                              Two years Training Course Under Kolkata City NUHM
                              Society as per Advertisement No-
                              227/NUMH/KOL/19-20 are requested to report on
                              31.10.2019 & 01.11.2019
                            </a>
                          </td>
                          <td>26.10.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1819")}
                            >
                              LIST OF SELECTED CANDIDATES FOR THE POST OF
                              MEDICAL OFFICER (FULL TIME) & (PART TIME).
                              ADVERTISEMENT NO- 05/KOLKATA CITY NUHM
                              SOCIETY/2019-20
                            </a>
                          </td>
                          <td>26.10.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass" >
                              LIST OF SELECTED CANDIDATES FOR THE POST OF LABORATORY TECHNICIAN. ADVERTISEMENT NO-
                              11/KOLKATA CITY NUHM SOCIETY/2018-19
                            </a>
                          </td>
                          <td>21.09.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1820")}
                            >
                              LIST OF SELECTED CANDIDATES FOR THE POST OF
                              MEDICAL OFFICER (FULL TIME) & (PART TIME).
                              ADVERTISEMENT NO- 03/KOLKATA CITY NUHM
                              SOCIETY/2019-20
                            </a>
                          </td>
                          <td>24.08.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1821")}
                            >
                              ENGAGEMENT & FINAL EXAMINATION RESULT OF TWO YEARS
                              ANM COURES, UNDER KOLKATA CITY NUHM SOCIETY . MEMO
                              NO:-523/2019/2742
                            </a>
                          </td>
                          <td>22.08.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1822")}
                            >
                              LIST OF SELECTED CANDIDATES FOR THE POST OF
                              LABORATORY TECHNICIAN. ADVERTISEMENT NO-
                              11/KOLKATA CITY NUHM SOCIETY/2018-19
                            </a>
                          </td>
                          <td>06.08.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1823")}
                            >
                              "Evaluation" of the candidates qualified for the
                              General (Non Technical) cadre
                            </a>
                          </td>
                          <td>03.08.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1824")}
                            >
                              Evaluation" of the candidates qualified for the
                              General (Technical) cadre
                            </a>
                          </td>
                          <td>03.08.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1825")}
                            >
                              LIST OF SELECTED CANDIDATES FOR THE POST OF
                              MEDICAL OFFICER (FULL TIME) & (PART TIME).
                              ADVERTISEMENT NO- 02/KOLKATA CITY NUHM
                              SOCIETY/2019-20
                            </a>
                          </td>
                          <td>09.07.2019</td>
                        </tr>

                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1826")}
                            >
                              LIST OF SELECTED CANDIDATES FOR THE POST OF
                              MEDICAL OFFICER (FULL TIME) & (PART TIME).
                              ADVERTISEMENT NO- 01/KOLKATA CITY NUHM
                              SOCIETY/2019-20
                            </a>
                          </td>
                          <td>25.06.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1827")}
                            >
                              CORRIGENDUM regarding Recruitment of Technical
                              Personnel ‘AutoCAD Professional’ & ‘Assistant
                              AutoCAD Professional’ (On contractual service
                              purely on temporary basis)
                            </a>
                          </td>
                          <td>12.03.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1828")}
                            >
                              List of Qualified Candidates for Personality Test
                              of AutoCAD Professional.{""}
                            </a>
                          </td>
                          <td>09.03.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1829")}
                            >
                              List of Qualified Candidates for Personality Test
                              of Assistant AutoCAD Professional.
                            </a>
                          </td>
                          <td>09.03.2019</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1830")}
                            >
                              3RD LIST OF SELECTED CANDIDATES (UR-25; OBC-B - 5)
                              FOR THE POST OF STAFF NURSE UNDER KOLKATA CITY
                              NUHM SOCIETY AGAINST ADVERTISEMENT NO. 3/KOLKATA
                              CITY NUHM SOCIETY/2018-2019
                            </a>
                          </td>
                          <td>12.12.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1831")}
                            >
                              LIST OF SELECTED CANDIDATES FOR THE POST OF
                              MEDICAL OFFICER (PART-TIME) ON CONTRACTUAL BASIS
                              UNDER KOLKATA CITY NUHM SOCIETY AGAINST
                              ADVERTISEMENT NO. 6/KOLKATA CITY NUHM
                              SOCIETY/2018-2019
                            </a>
                          </td>
                          <td>31.10.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1832")}
                            >
                              LIST OF SELECTED UR (FORTY-FOUR CANDIDATES), OBC-B
                              (SIX CANDIDATES) FOR THE POST OF STAFF NURSE UNDER
                              KOLKATA CITY NUHM SOCIETY; REF: ADVERTISEMENT NO.
                              3/KOLKATA CITY NUHM SOCIETY/2018-2019
                            </a>
                          </td>
                          <td>04.10.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1833")}
                            >
                              Answer keys of examinations under NULM vide Advt.
                              No. KMC/AMRUT/NULM-01/2016-17 dt. 16/06/16
                            </a>
                          </td>
                          <td>11.09.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1834")}
                            >
                              list of selected candidates for interview for the
                              post of Manager-MIS & ME under NULM
                            </a>
                          </td>
                          <td>11.09.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1835")}
                            >
                              Interview list for the post of AUTO-CAD
                              Professionals(Contractual)
                            </a>
                          </td>
                          <td>29.08.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1836")}
                            >
                              Interview list for the post of Assistant AUTO-CAD
                              Professionals(Contractual)
                            </a>
                          </td>
                          <td>29.08.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1837")}
                            >
                              CORRIGENDUM TO THE LIST OF SELECTED OBC-A (TWENTY
                              ONE CANDIDATES) FOR THE POST OF STAFF NURSE UNDER
                              KOLKATA CITY NUHM SOCIETY REF: ADVERTISEMENT NO.
                              3/KOLKATA CITY NUHM SOCIETY / 2018-2019, DATED -
                              02.06.2018
                            </a>
                          </td>
                          <td>14.08.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1838")}
                            >
                              LIST OF SELECTED (UR-61; OBC-A-21; OBC-B-17; SC-53
                              & ST-5) CANDIDATES FOR THE POST OF STAFF NURSE
                              UNDER KOLKATA CITY NUHM SOCIETY, AGAINST
                              ADVERTISEMENT NO. 3/KOLKATA CITY NUHM SOCIETY /
                              2018-2019, DATED - 02.06.2018
                            </a>
                          </td>
                          <td>10.08.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1839")}
                            >
                              List of successful candidates recommended for
                              engagement to the post of Veterinary Officer on
                              contractual basis
                            </a>
                          </td>
                          <td>17.08.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1840")}
                            >
                              LIST OF SELECTED CANDIDATES SC (ELEVEN
                              CANDIDATES), MERITORIOUS SPORTS PERSON (ONE
                              CANDIDATE), OBC-A (TWO CANDIDATES), OBC-B(ONE
                              CANDIDATE) FOR THE POST OF LABORATORY TECHNICIAN
                              UNDER KOLKATA CITY NUHM SOCIETY, AGAINST ADV. NO.
                              14/KOLKATA CITY NUHM SOCIETY/2017-2018, DATED -
                              02.08.2018
                            </a>
                          </td>
                          <td>04.08.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1841")}
                            >
                              LIST OF SELECTED CANDIDATES FOR THE POST OF
                              PHARMACISTS (UR-PH; UR-EX.SERVICEMAN; ST) AGAINST
                              ADV. NO. 13/KOLKATA CITY NUHM SOCIETY/ 2017-2018,
                              DATED. 09.02.2018
                            </a>
                          </td>
                          <td>27.07.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1842")}
                            >
                              LIST OF SELECTED CANDIDATES FOR COMPUTER TEST AND
                              INTERVIEW FOR THE POST OF LABORATORY TECHNICIAN,
                              AGAINST ADV. NO. 14/KOLKATA CITY NUHM SOCIETY/
                              2017/2018, DATED. 19.03.2018
                            </a>
                          </td>
                          <td>20.06.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1843")}
                            >
                              LIST OF SELECTED CANDIDATES FOR COMPUTER TEST AND
                              INTERVIEW FOR THE POST OF PHARMACIST UNDER KOLKATA
                              CITY NUHM SOCIETY, AGAINST ADV. NO. 13/KOLKATA
                              CITY NUHM SOCIETY/2017-2018, DATED - 09.02.2018
                            </a>
                          </td>
                          <td>04.06.2018</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1844")}
                            >
                              Selected Candidates for staff Nurses (contractual)
                              for Boral TB Hospital in interview (26.11.2015).
                            </a>
                          </td>
                          <td>02.12.2015 </td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1845")}
                            >
                              MERIT LIST OF MEDICAL OFFICER (RESERVED CATEGORY)
                              THROUGH WALK-IN INTERVIEW HELD ON 14/09/2015 UNDER
                              KOLKATA CITY NUHM SOCIETY, KMC.
                            </a>
                          </td>
                          <td>09.10.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1846")}
                            >
                              Merit list of Pharmacist (Contractual full time)
                              recruitment through walk-in interview held on
                              09/06/2015 and 12/06/2015 under Kolkata City NUHM
                              Society,KMC.
                            </a>
                          </td>
                          <td>30.07.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1847")}
                            >
                              Merit list of Staff Nurse (Contractual full time)
                              recruitment through walk-in interview held on
                              09/06/2015 under Kolkata City NUHM Society, KMC.
                            </a>
                          </td>
                          <td>22.07.2015</td>
                        </tr>
                        <tr tabIndex={0} align="left" valign="top">
                          <td>
                            <a
                              className="nav-link fontSizeClass"
                              onClick={(e) => fileDownload("1848")}
                            >
                              Result of Interview dated 30.01.2015 for
                              engagement of full time contractual Medical
                              Officers for Kolkata City NUHM Society .
                            </a>
                          </td>
                          <td>02.06.2015</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default KMC_Results