import React from 'react';
import { useTranslation } from "react-i18next";

const Disclaimer  = () => {
  const { t, i18n, ready } = useTranslation();
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
  <meta httpEquiv="Content-Type" content="text/html; charset=windows-1252" />
  <title>{t("Official Website of Kolkata Municipal Corporation")}</title>
  <table>
    <tbody >
      <tr >
        <td className='container'>
        <br /> <br /> <br /> <br />  <br /> <b>{t("COPYRIGHT")}</b>
          <br /> 
          <p align="justify">
            © {t("Copyright - 2010, Kolkata Municipal Corporation. All rights reserved. The contents hereof are the sole and exclusive property Kolkata Municipal Corporation (KMC). Unless otherwise indicated and may not be, for whatsoever purpose, reproduced, stored, copied or archived in any manner, format or medium whatsoever either in full or in parts without the prior express written consent of KMC. Copyright in the pages and in the screens displaying the pages and in the information and materials therein and in their arrangement are owned by KMC unless otherwise indicated. Any access of these materials outside the KMC amounts to an act of infringement of the intellectual and other proprietary rights of KMC and will attract legal liability of both civil and criminal nature. Within KMC, only the personnel authorized for the time being belonging to the KMC Portal Project ('Authorized Personnel') shall have the right to modify, alter or change these materials. No other person shall at any time modify, alter or change these materials (or any part thereof) nor attempt to do so under whatsoever circumstances. Any such act amounts to an act of infringement of the intellectual and other proprietary rights of KMC and shall attract the strictest action possible under law")}.
          </p>
          <br />
          <br />
          <b>{t("PRIVACY POLICY")}</b>
          <br />
          <p align="justify">
            {t("This Portal does not automatically capture any specific personal information from you (like name, phone number or e-mail address) that allows us to identify you individually")}.
          </p>
          <p align="justify">
            {t("If KMC or its Portal requests you to provide personal information, for the purpose of registration or other activities, you will be informed for the particular purpose for which the information is gathered and adequate security measures will be taken to protect your personal information")}.
          </p>
          <p align="justify">
            {t("We do not sell or share any personally identifiable information volunteered on KMC Portal to any third party (public/private). Any information provided to this Portal will be protected from loss, misuse, unauthorized access or disclosure, alteration, or destruction")}.
          </p>
          <p align="justify">
            {t("We may gather certain information about the User, such as Internet Protocol (IP) Addresses, Domain Name, Browser Type, Operating System, the Date and Time of the visit and the Pages visited. We make no attempt to link the information with the identity of individuals visiting our site unless an attempt to damage the site has been detected or KMC wants such information for any specific queries")}.
          </p>
          <br />
          <br />
          <b>{t("DISCLAIMER")}</b>
          <br />
          <p align="justify">
            {t("Though KMC has used its best endeavours to ensure that the information available on this site is in accordance with the KMC Act, it should not be regarded as being a complete source of the data available. Users are advised to verify/check any information with the relevant Department(s) of KMC and/or other source(s), and to obtain any appropriate professional advice before acting on the information provided in the Porta")}.
          </p>
          <br />
          <br />
          <b>{t("LINKS TO EXTERNAL WEBSITES")}</b>
          <br />
          <p align="justify">
            {t("This site and/or articles contained therein may contain links to websites controlled or offered by third parties/other government sites. KMC is not responsible for content or reliability of the linked websites. KMC hereby disclaims liability for any information, materials, products or services posted or offered at any third part site linked to this website. By creating a link to a third party website, KMC does not endorse or recommend any product or service offered or information contained at that website, nor is KMC liable for any failure of products or services offered or advertised at those sites. Such third party may have a Privacy Policy different from that of KMC and the third party website may provide less security than this site")}.
          </p>
          <br />
          <br />
          <b>{t("USE OF INFORMATION AND MATERIALS")}</b>
          <br />
          <p align="justify">
            {t("Information and materials contained in these pages and the terms, conditions and descriptions that appear are subject to change. Misuse of any information posted on this site is strictly prohibited and may attract both penal and punitive action")}.
          </p>
          <br />
          <br />
          <b>{t("LIMITATION OF LIABILITY")}</b>
          <br />
          <p align="justify">
            {t("In no event will KMC be liable for any loss or damage including, without limitation, direct or indirect, special, incidental or consequential damage, loss or expense arising in connection with this site or any linked site or use thereof or inability to use by any party or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure, even if KMC or authorised personnel of KMC thereof are advised of the possibility of such damage, loss or expense")}.
          </p>
          <br />
        </td>
      </tr>
    </tbody>
  </table>
</>

  )
}

export default Disclaimer