import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Auction  = () => {
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
        <div className="container"> <div className="section-main-heading2"></div></div>             
      </div>       	 		 
    </section>	  
    {/* End Slider */}
   
    <section className="section inner-pad1">
      <div className="container " >
        <div className="row">
          <div className="col-lg-3">
          <div className="site-menu ">
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item">
                    <Link className="nav-link active1 fontSizeClass" to='/Auction'> {t("Auction")} </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2 bas"><h3 className="fontSizeClass"> {t("List Of Auctions")} </h3></div>		   
                <div className="mayor-table" style={{border:"1px solid #eeeeee", overflow:"hidden"}}>
                  <table className="table table-bordered officials">
                    <tbody><tr bgcolor="#302ba0" tabIndex={0}>
                        <td width="10%" rowSpan={2} style={{"color":"#fff"}} align="center" valign="middle">{t("Auction")}</td>
                        <td width="23%" rowSpan={2} style={{"color":"#fff"}}>{t("Auction Uploaded On")}</td>
                        <td width="22%" rowSpan={2} style={{"color":"#fff"}}>{t("Action")}</td>
                      </tr>
                      <tr>
                        {/* <td width="20%" style="padding:3">Office</td>
                      <td width="13%" style="padding:3">Resi.</td> */}
                      </tr>
                      
                    </tbody></table>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </section>
      </>
  )
}

export default Auction