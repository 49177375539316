import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Vision_Strategy  = () => {
  const { t, i18n, ready } = useTranslation();
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>  
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      </div>       	 		 
    </section>	
    <section className="section inner-pad1">
      <div className="container " >
        <div className="row">
          <div className="col-lg-3">
            <div className="site-menu  ">           
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/CBP_Home'> {t("CBP Home")} </Link> </li>
                  <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/Vision_Strategy'> {t("Vision And Strategy")} </Link></li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Project_Faqs'> {t("FAQs")}</Link></li>
                </ul>
              </nav>	   
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2">
                  <h3 className="fontSizeClass">{t("Capacity Building Programme (CBP) :: Vision and Strategy")}</h3>
                </div>
                <div className="col-lg-12 about-head1">
                <h5 className="fontSizeClass"> <strong>{t("Foreword")}</strong> </h5>	
                  <p className='mt-2'>{t("We have launched a Capacity Building Programme in the Kolkata Municipal Corporation. It is meant to add strength and vigour to the civic body. Ours is an old organization, one of the oldest of its kind in the country. With age comes obsolescence. The Capacity Building Programme is meant to renew our energy and to re-invigorate our limbs. The Department for International Development (DFID), Government of United Kingdom, has been helping us in this programme and we are grateful to them")}.</p>
                  <p className='mt-2'>{t("We present now the vision and the strategy paper for this Capacity Building Programme. This is a road map for us. We present in this paper an omnibus perspective, as well as sectoral action plans. The paper provides the milestones for the future and provides the anchors and the moorings for our journey ahead")}.</p>
                  <p className='mt-2'>{t("Sri Alapan Bandyopadhyay, IAS, the Municipal Commissioner, has ably led the team that has prepared the document. Sri Sahidul Islam, the Joint Municipal Commissioner (Development) has worked painstakingly to produce the draft. Our consultants, the KPMG etc, have worked hard. The contributions from the different Members of the Mayor-in-Council, our Borough Chairmen and the Controlling Officers and staff across the organization are acknowledged. They have deliberated upon the contents of the document over the months. The DFID officials have also enriched us with their suggestions. It is a collective effort. We propose to steer ourselves with the vision and strategy that we now present")}.</p>
                  <p className='mt-2'>{t("Let this volume be treated as Volume-I of a series of booklets that we intend to publish one by one. In the succeeding volumes, we shall present our specific action programmes/circulars/orders/notifications in different directions")}.</p>

                </div>
                
              </div> 
            </div>
          </div>
          </div>
      </div>
    </section>
      </>
  )
}

export default Vision_Strategy