import React from 'react';
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Kmc_school_home  = () => {
  const { t, i18n, ready } = useTranslation();

  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
<>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      <div className="container"> <div className="section-main-heading2"></div></div>             
    </div>       	 		 
  </section>	  
 
  <section className="section inner-pad1">
    <div className="container " >
        <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">           
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link active1" to='/Kmc_school_home'> {t("KMC Schools Home")} </Link> </li>
                <li className="nav-item"><a className="nav-link" target="_blank" href='https://www.kmcgov.in/KMCPortal/jsp/KMCSchoolsList.jsp'> {t("List Of Schools")} </a> </li>
                <li className="nav-item"><Link className="nav-link" to='/Urgent_Notice'> {t("Urgent Notice")} </Link> </li>
                <li className="nav-item"><Link className="nav-link" to='/KMC_School_Faq'> {t("FAQs")} </Link> </li>
                <li className="nav-item"><Link className="nav-link" target="_blank" to='/Education_Dept'> {t("Contact Details")} </Link></li>
             </ul>
            </nav>	   
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
                <div className="row">
                  <div className="inner-title"><h3> {t("KMCP Schools")} </h3></div>
                  <div className="font-italic text-danger">{t("Information about KMC Primary School along with Schemes for the benefit of the Students")}</div>
                  <div className="my-3"><strong>{t("VARIOUS INFORMATION REGARDING KMC PRIMARY SCHOOL")}</strong></div>
                  <div id="accordion">
                    <div className="card">
                      <div className="card-header" id="heading1">
                        <button className="btn btn-link m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                          1. {t("No. of KMCP Schools at present are 263 including three streams i.e. Bengali, Hindi and Urdu (Boys & Girls)")}.
                        </button>
                      </div>
                      <div id="collapse1" className="collapse show" data-parent="#accordion">
                      <div className="card-body m-3">
                          {t("No. of Bengali Medium Schools are 154")}
                          {t("No. of Hindi Medium Schools are 46")}
                          {t("No. of Urdu Medium Schools are 56")}
                          {t("No. of Mixed (Bengali & Urdu) Medium Schools are 5")}
                          {t("No. of Mixed (Hindi & Urdu) Medium Schools are 2")}
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading2">
                      <button className="btn btn-link m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                      2.	{t("Stream wise present No. of teachers:")}
                    </button>
                    </div>
                    <div id="collapse2" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3">
                        {t("Bengali Medium - 328")}
                        {t("Hindi Medium - 100")}
                        {t("Urdu Medium - 111")}
                        {t("Mixed (Bengali & Urdu) - 18")}
                        {t("Mixed (Hindi & Urdu) - 12")}
                        {t("Music Teachers - 6")}
                        {t("Craft Teachers - 7")}
                      </div>                      
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading3">
                      <button className="btn btn-link m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                      3.	{t("Stream wise present no. of Students:")}
                    </button>
                    </div>
                    <div id="collapse3" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3">
                      {t("Bengali Medium: 13338")}
                      {t("Hindi Medium: 5611")}
                      {t("Urdu Medium: 8681")}
                      {t("Mixed (Bengali & Urdu): 681")}
                      {t("Mixed (Hindi & Urdu): 442")}
                      {t("Total No. of Students: 26971 ")}
                      </div>
                      </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <button className="btn btn-link m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                      4. {t("With the Financial assistance of Govt. of West Bengal K.M.C is organizing 102 centres of Child education (S.S.K) between 01 to 141 Ward in different streams.")}
                    </button>
                    </div>
                    <div id="collapse4" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3">
                      {t("Bengali Medium: 72 Centres")}
                      {t("Hindi Medium: 10 Centres")}
                      {t("Urdu Medium: 17 Centres")}
                      {t("Oria Medium: 01 Centre")}
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading5">
                      <button className="btn btn-link m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                      5. {t("The following schemes have been undertaken in KMCP Schools for the benefit of KMCP School Children and / or the children of the neighbouring areas.")}                    </button>
                    </div>
                    <div id="collapse5" className="collapse" data-parent="#accordion">
                      <div className="card-body m-3 fontSizeClass">                                                    
                      <div className="card-body m-3">                                                    
                          <li>{t("Annual Sports meet of KMCP School Children")}.</li>
                          
                          <li> {t("Participation in State Level Sports on regular basis")}</li>
                              
                          <li> {t("Distribution of national text books amongst the children of KMCP Schools and S.S.K. on free of cost")}.</li>
                              
                          <li> {t("Libraries for students")}</li>
                              
                          <li> {t("Participation in integrated learning project evaluation through Sarva Siksha Abhiyan (S.S.A.)")}.</li>
                              
                          <li> {t("Various training programme for development of scientific concepts of the children of KMCP Schools are arranged by Education Department, K.M.C with the financial assistance of UNICEF")}.</li>
                              
                          <li> {t("Celebration of International Literacy Day on 8th September every year")}.</li>
                              
                          <li> {t("Distribution of School Uniforms")}.</li>
                              
                          <li> {t("Distribution of academic prizes and sport prizes")}.</li>
                              
                          <li> {t("Distribute Mid-day-meal to all students of KMCP schools")}</li>
                              
                          <li> {t("Celebration of auspicious days namely Netaji's Birthday, Republic Day, Rabindra Jayanti, Independence Day etc")}.</li>
                              
                          <li> {t("Participation of School Children in sit & draw programme")}.</li>
                              
                          <li> {t("Participation of school children in educational tour to places of Historical interest")}.</li>
                              
                          <li> {t("Arrangement of school wise exhibition on improvised models")}.</li>
                              
                          <li> {t("Work Education and Physical Education")}.</li>
                      </div>
                    </div>
                  </div>
                  
                  </div>
                </div>
            </div>
      </div>
        </div>
    </div>
    </div>
  </section>
  
    </>
  )
}

export default Kmc_school_home