import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const MC_Circular_2021_22 = () => {
  const fileDownload=(fileStoreId)=>
        {
          
          let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
           axios
              .post(url,{"fileId": fileStoreId, "userId": "392" })
              .then((res) => {
                  console.log("response is --- ", res);
      
                  if(res && res.data && res.status === 200) {
                    const base64 = res.data[0] && res.data[0].base64_data;
                    const pdfData = 'data:application/pdf;base64,'+base64;
      
                    let html = '<html>' +
                        '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                        '<body>' +
                        '<iframe src="' + pdfData + '"></iframe>' +
                        '</body></html>';
                        console.log(html);
                    let a = window.open();
                    a.document.write(html);
                }
              })
              .catch((e) => {
                  // setLoader(false);
                  console.log("The error is ", e);
              })
        }
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
const [DocumentService, setDocumentService] = React.useState(false);

const DocumentServiceToggle = () => {
    setDocumentService(!DocumentService);
    };
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
  <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      </div>
    </section>
    <section className="section inner-pad1">
      <div className="container " >
        <div className="row">
          <div className="col-lg-3">
            <div className="site-menu  min-height-400">
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <button type="button" class="btn nav-link pr-0" data-toggle="collapse" data-target="#demo">{t("Municipal Commissioner CIRCULAR")}<i className="bi bi-chevron-down " /></button>
                    <div id="demo" class="collapse">
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2024_25'> {t("MC's Circular")} 2024-25 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2023_24'> {t("MC's Circular")} 2023-24 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2022_23'> {t("MC's Circular")} 2022-23 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass active1" to='/MC_Circular_2021_22'> {t("MC's Circular")} 2021-22</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2020_21'>{t("MC's Circular")} 2020-21</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2019_20'>{t("MC's Circular")} 2019-20</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2018_19'> {t("MC's Circular")} 2018-19</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2017_18'>{t("MC's Circular")} 2017-18 </Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2016_17'>{t("MC's Circular")} 2016-17</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2015_16'>{t("MC's Circular")} 2015-16</Link></li>
                        <li className="nav-item"><Link className="nav-link fontSizeClass" to='/MC_Circular_2004_05'>{t("MC's Circular")} 2004-05</Link></li>
                    </div>
                  </li><li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Municipal_secretary_circular'>{t("MUNICIPAL SECRETARY'S CIRCULAR")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/CMF_Communique_2022_23'>{t("C M F & A's Circular and Communique - 2022-23")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Chief_Manager_Circular'>{t("Chief Manager (P)'s Circular")}</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to='/Govt_memo'>{t("Govt Memo")}</Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1558')}> {t("West Bengal Right to Public Service Act,2013")} </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('1559')}>{t("Guidelines of Section 199(2) of KMC Act,1980 (as amended in 2019)")}  </a>
                  </li>
                  <li className="nav-item-list ">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">{t("COMPENDIUM OF IMPORTANT DEPARTMENTAL CIRCULARS / ORDERS")}<i className="bi bi-chevron-down " /></button>
                      <div id="demo1" className="collapse">                        
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>1. Financial Year 2010-11 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('869')}>2. Financial Year 2011-12 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('870')}>3. Financial Year 2012-13 </a>
                          <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('925')}>4. Financial Year 2013-14 </a>   
                      </div>
                  </li>
                  
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2 bas">
                  <h3 className="fontSizeClass"> {t("Circular")} </h3>
                </div>
                <div className="health-dept-table mayor-table m-ta-2">
                  <table
                    width="95%"
                    border={0}
                    
                    cellPadding={2}
                    cellSpacing={2}
                    className="table table-bordered officials" >
                    <tbody>
                      <tr
                        bgcolor="#302ba0"
                        tabIndex={0}
                        
                        valign="middle">
                        <td width="35%" className="text-white tble-brder-left">
                        {t("Circular No")}		
                        </td>
                        <td width="20%" className="text-white">
                        {t("Date")}		
                        </td>
                        <td width="45%" className="text-white tble-brder-right">
                        {t("Subject")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('926')}> {t("MC's Circular No.58 of 2021-22")} </a>
                        </td>
                        <td >
                        {t("25-March-2022")}	
                        </td>
                        <td >
                        {t("Sanction of Inrernal House Drainage during issuance of subesquent senctions under Rule 25(2A), 25(2B) Of the KMC Building Rules,1990 Or under Rule 26(2A),26(2B) Of the KMC Building Rules,2009 or retention allowed By KMC under section 400(1) and/or 416 of the KMC Act,1980 and/or before issuance of completion certificate")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('927')}> {t("MC's Circular No.57 of 2021-22")} </a>
                        </td>
                        <td >
                        {t("24-March-2022")}			
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 57 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('928')}> {t("MC's Circular No.56 of 2021-22")} </a>
                        </td>
                        <td >
                        {t("14-March-2022")}	
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 56 of 2021-22")}

                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('929')}> {t("MC's Circular No.55 of 2021-22")} </a>
                        </td>
                        <td >
                        {t("04-March-2022")}			
                        </td>
                        <td >
                        {t("Addendum to Mpl Commissioner's Circullar no. 52 of 2021- 2022, Regearding 'File Tracking System' for hardcopy File")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('930')}>{t("MC's Circular No.54 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("21-february-2022")}			
                        </td>
                        <td >
                        {t("Effective utilisation of furniture in KMC Offices")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('931')}>{t("MC's Circular No.53 of 2021-22")} </a>
                        </td>
                        <td >
                        {t("09-february-2022")}			
                        </td>
                        <td >
                        {t("Electronic Circulation Of Mic Agenda And Resolution")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('932')}>{t("MC's Circular No.52 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("07-february-2022")}			
                        </td>
                        <td >
                        {t("Continuation Of 'ile Tracking System' For Hardcopy Files")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('933')}>{t("MC's Circular No.51 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("02-february-2022")}			
                        </td>
                        <td >
                        {t("Avoiding Downloading And Printing Of E-office Files")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('934')}>{t("MC's Circular No.50 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("02-february-2022")}		
                        </td>
                        <td >
                        {t("File system with hard copies of voluminous document")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('935')}> {t("MC's Circular No.49 of 2021-22")}	</a>
                        </td>
                        <td >
                        {t("01-february-2022")}			
                        </td>
                        <td >
                        {t("Regarding Electronic Circulation Of Mic Agenda And Resolutions Mc's Circular No. 49 Of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('936')}>{t("MC's Circular No.48 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("15-January-2022")}			
                        </td>
                        <td >
                        {t("Upkeep Of Cmo Buildings")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('937')}>{t("MC's Circular No.47 of 2021-22")}	</a>
                        </td>
                        <td >
                        {t("31-december-2021")}	
                        </td>
                        <td >
                        {t("WORKS/ Project Review Meeting and Revanue Meeting Schedule")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('939')}> {t("MC's Circular No.46 of 2021-22")} </a>
                        </td>
                        <td >
                        {t("27-december-2021")}	
                        </td>
                        <td >
                        {t("All controlling Officers are hereby informed that it has been decided by authority to follow memorandum No. 1074 / UDMA")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('940')}>{t("MC's Circular No.45 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("16-december-2021")}		
                        </td>
                        <td >
                        {t("Constitution of committee for flood forecasting & early warning system of kolkata")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('941')}> {t("MC's Circular No.44 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("06-december-2021")}		
                        </td>
                        <td >
                        {t("REQUISITION OF Cirtificates from the applicant applying Completion Cirtificate under Schedule xii of kolkata Corporation Building Rules 2009")}</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('942')}>{t("MC's Circular No.43 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("13 Novembar 2021")}		
                        </td>
                        <td >
                        {t("Revised Delegation of Power of Assessment-Collection Department")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('943')}> {t("MC's Circular No.42 of 2021-22")} </a>
                        </td>
                        <td >
                        {t("12 Novembar 2021")}			
                        </td>
                        <td >
                        {t("Revised Citizens Charter of Assessment-Collection Department")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('944')}>{t("MC's Circular No.41 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("02 Novembar 2021")}			
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 41 of 2021-22")} .
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('945')}>{t("MC's Circular No.40 of 2021-22")}	</a>
                        </td>
                        <td >
                        {t("01 Novembar 2021")}	
                        </td>
                        <td >
                        {t("Deployment officials at the different ghats on the river Hooghly and ponds in added areas")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('946')}>{t("MC's Circular No.39 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("01-October-2021")}	
                         </td>
                        <td >
                        {t("Splitting of identical nature of works")}.
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('947')}> {t("MC's Circular No.38 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("29-September-2021")}	                        </td>
                        <td >
                        {t("Emergent And Instrument/apparatus Arrangement During Festive Days (most Particularly Form October 10th .2021, To October 20th, 2021.) 24*7 Arrangements")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('948')}> {t("MC's Circular No.37 of 2021-22")}	 </a>
                        </td>
                        <td >
                        {t("29-September-2021")}			
                        </td>
                        <td >
                        {t("Emergent And Superior-lavel Personnelarrangement During Festive Days (most Particulerly Form October 10th .2021, To October 20th, 2021.)")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('949')}> {t("MC's Circular No.36 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("23-September-2021")}	
                        </td>
                        <td >
                        {t("Prepartory Ativities And Arrangements And Related To Massive Rainfall/ Cycalone Expacted On 25 Th& 26th September ,2021")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('950')}> {t("MC's Circular No.35 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("21-September-2021")}			
                        </td>
                        <td >
                        {t("Constitution of high power Committee for planning of desilting and refurbishing of the trunk sewer")}

                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('951')}>{t("MC's Circular No.34 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("21-September-2021")}				
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 34 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('952')}>{t("MC's Circular No.33 of 2021-22")}	</a>
                        </td>
                        <td >
                        {t("14-September-2021")}				
                        </td>
                        <td >
                        {t("Fortniightly review meeting with UD & MA Deptt., Go.W.B on pending issues of KMC")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('953')}> {t("MC's Circular No.32 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("11-September-2021")}	                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 32 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('954')}>{t("MC's Circular No.31 of 2021-22")}	</a>
                        </td>
                        <td >
                        {t("10-September-2021")}		
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 31 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('955')}> {t("MC's Circular No.30 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("08-September-2021")}				
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 30 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('956')}>{t("MC's Circular No.29 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("07-September-2021")}				
                        </td>
                        <td >
                        {t("Ease of Doing Business for sanction of building plan")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('957')}> {t("MC's Circular No.28 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("01-September-2021")}	                        </td>
                        <td >
                        {t("Modification of Recruitment regulations for cadre/post of Executive Engineer(Civil/Mechanical/Electrical)")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('958')}> {t("MC's Circular No.27 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("27-July-2021")}				
                        </td>
                        <td >
                        {t("Functioning of the Bangla Sahayata Kendras")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('959')}>{t("MC's Circular No.26 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("28-July-2021")}				
                        </td>
                        <td >
                        {t("Implementation of 'Duare sarkar-government at doorstep' and 'paray samadhan' in KMC.")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('960')}> {t("MC's Circular No.25 of 2021-22")} </a>
                        </td>
                        <td >
                        {t("27-July-2021")}					
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 25 of 2021-22")}</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('961')}> {t("MC's Circular No.24 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("08-July-2021")}				
                        </td>
                        <td >
                        {t("Partial amendment of Municiapl Commissioner's Circular No.173 of 2019-2020 dated 01.03.2020")}
 
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('962')}>{t("MC's Circular No.23 of 2021-22")}	</a>
                        </td>
                        <td >
                        {t("08-July-2021")}				
                        </td>
                        <td >
                        {t("Monitoring of court cases")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('963')}>{t("MC's Circular No.22 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("06-July-2021")}					
                        </td>
                        <td >
                        {t("Simplification of the process for dealing with application for mutation-seperation/amalgamation/apportionment of any premises where 'Khatal is recorded' in Computer Data Base or Books of Record of the KMC against such premises.")}
                       </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('964')}>{t("MC's Circular No.21 of 2021-22")}	</a>
                        </td>
                        <td >
                        {t("06-July-2021")}	                        </td>
                        <td >
                        {t("ADDENDUM to Municipal Commissioner's Circular No.14 of 2021-22,dated:12/06/2021 regarding Modified Pension File Processing System.")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('965')}>{t("MC's Circular No.20 of 2021-22")}	</a>
                        </td>
                        <td >
                        {t("03-July-2021")}					
                        </td>
                        <td >
                        {t("Avoiding delay in tendering due to multiple causes")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('966')}>{t("MC's Circular No.19 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("02-July-2021")}					
                        </td>
                        <td >
                        {t("Restriction on entry & exit through the gates of CMO buildings, KMC")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td>
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('967')}> {t("MC's Circular No.18 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("02-July-2021")}					
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 18 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('968')}>{t("MC's Circular No.17 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("30-June-2021")}			
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 17 of 2021-22")}
                       </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('969')}> {t("MC's Circular No.16 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("22-June-2021")}				
                        </td>
                        <td >
                        {t("Job distribution amongst Special Municipal Commissioners")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('970')}> {t("MC's Circular No.15 of 2021-22")}	</a>
                        </td>
                        <td >
                        {t("14-June-2021")}					
                        </td>
                        <td >
                        {t("Ensuring full coverage of Covid vaccination and facilitating International Travel for genuine reasons")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('971')}> {t("MC's Circular No.14 of 2021-22")} </a>
                        </td>
                        <td >
                        {t("12-June-2021")}					
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 14 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('972')}>{t("MC's Circular No.13 of 2021-22")}	</a>
                        </td>
                        <td >
                        {t("12-June-2021")}	                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 13 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('973')}>{t("MC's Circular No.12 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("09-June-2021")}	                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 12 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('974')}> {t("MC's Circular No.11 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("08-June-2021")}	                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 11 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('975')}> {t("MC's Circular No.10 of 2021-22")}	</a>
                        </td>
                        <td >
                        {t("07-June-2021")}		                        </td>
                        <td >
                        {t("Emergency arrangements during the ensuing Nor’wester/Rainyseason in the next few months of 2021")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('976')}>{t("MC's Circular No.09 of 2021-22")}	</a>
                        </td>
                        <td >
                        {t("31-May-2021")}		                        </td>
                        <td >
                        {t("Task Force at KMC HQ for Duare Tran Camps in YAAS affected wards under KMC")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('977')}>{t("MC's Circular No.08 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("29-May-2021")}		                        </td>
                        <td >
                        {t("Duare Tran Camps in wards under KMC")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('978')}>{t("MC's Notice of 2021-22")}</a>
                        </td>
                        <td >
                        {t("03-June-2021")}		                        </td>
                        <td >
                        {t("Municipal Commissioner's Notice of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('979')}>{t("MC's Circular No.07 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("25-May-2021")}	 </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 07 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('980')}> {t("MC's Circular No.06 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("08-June-2021")}	                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 11 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('981')}>{t("MC's Circular No.05 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("19-May-2021")}		                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 05 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('982')}>{t("MC's Circular No.04 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("12-May-2021")}		                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 04 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('983')}>{t("MC's Circular No.03 of 2021-22")}</a>
                        </td>
                        <td >
                            {t("23-April-2021")}	
                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 03 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('984')}> {t("MC's Circular No.02 of 2021-22")}</a>
                        </td>
                        <td >
                        {t("20-April-2021")}		                        </td>
                        <td >
                        {t("Municipal Commissioner's Circular No. 02 of 2021-22")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  >
                        <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('985')}>{t("MC's Circular No.01 of 2021-22")}	</a>
                        </td>
                        <td >
                        {t("13-April-2021")}		                        </td>
                        <td >
                        {t("Emergency arrangement during the ensuing Norwester/rainy season in the next few months of 2021")}
                        </td>
                      </tr>
                      </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default MC_Circular_2021_22