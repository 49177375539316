import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";

const Download_Form  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>
      <section id="hero" className="hero hero2">
        <div
          className="social-media wow slideInDown"
          style={{ visibility: "visible", animationName: "slideInDown" }}
        >
          <div className="facebook" rel="ebInfo" title="facebook">
            <a
              href="https://www.facebook.com/KolkataMunicipalCorporation.official"
              target="_blank"
            >
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a
              href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp"
              target="_blank"
            >
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>

        <div className="carousel-item active">
          <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
          <div className="container">
            <div className="section-main-heading2"></div>
          </div>
        </div>
      </section>
      {/* End Slider */}

      <section className="section inner-pad1">
        <div className="container ">
          <div className="row">
            <div className="col-lg-3">
              <div className="site-menu ">
                <nav className="sidebar card py-2">
                  <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item">
                      <Link
                        className="nav-link active1 fontSizeClass"
                        to="/Download_Form"
                      >
                        Download Form
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="container">
                <div className="mayor-table m-ta-2">
                  <table>
                    <tbody>
                      <tr>
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table>
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: ADVERTISEMENT
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1880")}
                                  >
                                    Application Form for Empanelment of
                                    Advertising Agencies
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">2.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1881")}
                                  >
                                    Form for Application for Erection of
                                    Hoarding
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">3.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1882")}
                                  >
                                    Sample Affidavit Format
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr align="left" valign="top" className="head_line_text">
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table
                            width="100%"
                            border={0}
                            cellSpacing={2}
                            cellPadding={2}
                            className="rm-mg inner-tbl"
                          >
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: AMUSEMENT
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1883")}
                                  >
                                    Form for Permission under Section 422 of the
                                    C.M.C. Act,1980s
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr align="left" valign="top" className="head_line_text">
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table
                            width="100%"
                            border={0}
                            cellSpacing={2}
                            cellPadding={2}
                            className="rm-mg inner-tbl"
                          >
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: ASSESSMENT
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1884")}
                                  >
                                    Exemption Form
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">2.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1885")}
                                  >
                                    Form No.A-75 when tenant - lessee moves in
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">3.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1886")}
                                  >
                                    Form No.A-42 Application for Entry of Names
                                    in the Assessment Register U/S 183 and
                                    Separation/Amalgamation of numbers(s) U/S
                                    178(4) and Apportionment U/S 178-2--II-
                                    Proviso of the Kolkata Municipal Act, 1980.]
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">4.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1887")}
                                  >
                                    Form No. A - 75 when tenant - lessee moves
                                    out
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">5.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1888")}
                                  >
                                    SAF Form
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">6.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    href="https://www.kmcgov.in/KMCPortal/downloads/G_R_Form.pdf"
                                    target="_blank"
                                  >
                                    Property Information Form
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr align="left" valign="top" className="head_line_text">
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table
                            width="100%"
                            border={0}
                            cellSpacing={2}
                            cellPadding={2}
                            className="rm-mg inner-tbl"
                          >
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: BS & SSEP
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1888")}
                                  >
                                    IGNDPS Form
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">2.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1889")}
                                  >
                                    IGNOAPS Form
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">3.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1890")}
                                  >
                                    IGNWPS Form
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">4.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1891")}
                                  >
                                    III-U (NFSA/RKSY) Form
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">5.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1892")}
                                  >
                                    IV-U (NFSA/RKSY) Form
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">6.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1893")}
                                  >
                                    V-U (NFSA/RKSY) Form
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">7.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1895")}
                                  >
                                    VI-U (NFSA/RKSY) Form
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr align="left" valign="top" className="head_line_text">
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table
                            width="100%"
                            border={0}
                            cellSpacing={2}
                            cellPadding={2}
                            className="rm-mg inner-tbl"
                          >
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: Building
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="nav-link fontSizeClass"
                                    onClick={(e) => fileDownload("1896")}
                                  >
                                    Annex G
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">2.</td>
                                <td colSpan={2}>
                                  <a
                                    className="nav-link fontSizeClass"
                                    onClick={(e) => fileDownload("1897")}
                                  >
                                    Building Application Form
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">3.</td>
                                <td colSpan={2}>
                                  <a
                                    className="nav-link fontSizeClass"
                                    onClick={(e) => fileDownload("1898")}
                                  >
                                    Composite Declaration Form
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">4.</td>
                                <td colSpan={2}>
                                  <a
                                    className="nav-link fontSizeClass"
                                    onClick={(e) => fileDownload("1899")}
                                  >
                                    General Undertaking Form
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">5.</td>
                                <td colSpan={2}>
                                  <a
                                    className="nav-link fontSizeClass"
                                    onClick={(e) => fileDownload("1901")}
                                  >
                                    Indemnity Form
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">5.</td>
                                <td colSpan={2}>
                                  <a
                                    className="nav-link fontSizeClass"
                                    onClick={(e) => fileDownload("1902")}
                                  >
                                    Notice of Commencement Form
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">6.</td>
                                <td colSpan={2}>
                                  <a
                                    className="nav-link fontSizeClass"
                                    onClick={(e) => fileDownload("1903")}
                                  >
                                    Notice of Completion
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">7.</td>
                                <td colSpan={2}>
                                  <a
                                    className="nav-link fontSizeClass"
                                    onClick={(e) => fileDownload("1905")}
                                  >
                                    Notice of Completion(Upto plinth level)
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">8.</td>
                                <td colSpan={2}>
                                  <a
                                    className="nav-link fontSizeClass"
                                    onClick={(e) => fileDownload("1906")}
                                  >
                                    Notice of Inspection of Drainage Form
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr align="left" valign="top" className="head_line_text">
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table
                            width="100%"
                            border={0}
                            cellSpacing={2}
                            cellPadding={2}
                            className="rm-mg inner-tbl"
                          >
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: CHVS DEPARTMENT
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1906")}
                                  >
                                    Development Fees Application Form
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">2.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1907")}
                                  >
                                    Survey Observation Report (SOR) Application
                                    Form
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr align="left" valign="top" className="head_line_text">
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table
                            width="100%"
                            border={0}
                            cellSpacing={2}
                            cellPadding={2}
                            className="rm-mg inner-tbl"
                          >
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: CIVIL
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("284")}
                                  >
                                    Tender Form Format
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr align="left" valign="top" className="head_line_text">
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table
                            width="100%"
                            border={0}
                            cellSpacing={2}
                            cellPadding={2}
                            className="rm-mg inner-tbl"
                          >
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: Finance & Accounts
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1908")}
                                  >
                                    NEFT/RTGS/CBS MANDATE FORM
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr align="left" valign="top" className="head_line_text">
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table
                            width="100%"
                            border={0}
                            cellSpacing={2}
                            cellPadding={2}
                            className="rm-mg inner-tbl"
                          >
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: HERITAGE BUILDINGS
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1909")}
                                  >
                                    Application form for exemption of property
                                    tax for Heritage Building u/s 425K of KMC
                                    Act, 1980
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">2.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1910")}
                                  >
                                    Composition of the Heritage Conservation
                                    Committee
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr align="left" valign="top" className="head_line_text">
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table
                            width="100%"
                            border={0}
                            cellSpacing={2}
                            cellPadding={2}
                            className="rm-mg inner-tbl"
                          >
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: LICENSE
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1912")}
                                  >
                                    Ceremonial House Application Form &amp;
                                    Instruction
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">2.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1911")}
                                  >
                                    Application Form for Obtaining Of Enlistment
                                    (Trade Licence) For P/ Trades /Callings
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">3.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1913")}
                                  >
                                    Licence Self Declaration Format for
                                    Obtaining New C.E
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr align="left" valign="top" className="head_line_text">
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table
                            width="100%"
                            border={0}
                            cellSpacing={2}
                            cellPadding={2}
                            className="rm-mg inner-tbl"
                          >
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: LICENSE
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1914")}
                                  >
                                    Mediclaim Form
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">2.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1915")}
                                  >
                                    NEFT/RTGS/CBS Mandate Form
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr align="left" valign="top" className="head_line_text">
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table
                            width="100%"
                            border={0}
                            cellSpacing={2}
                            cellPadding={2}
                            className="rm-mg inner-tbl"
                          >
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: PENSION
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1916")}
                                  >
                                    ANNEXURE - III
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr align="left" valign="top" className="head_line_text">
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table
                            width="100%"
                            border={0}
                            cellSpacing={2}
                            cellPadding={2}
                            className="rm-mg inner-tbl"
                          >
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: PERSONNEL
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1917")}
                                  >
                                    (Annexure 'B' ) Form for issuing 'N.O.C' to
                                    obtain VISA for travelling abroad.
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1918")}
                                  >
                                    (Annexure 'E' ) Form to issue 'N.O.C' to
                                    obtaining International Passport
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass" >
                                    Format of Contractual Input Details
                                  </a>
                                </td>
                              </tr>
                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1919")}
                                  >
                                    Prayer for issuance of NOC to apply for
                                    employment
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr align="left" valign="top" className="head_line_text">
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table
                            width="100%"
                            border={0}
                            cellSpacing={2}
                            cellPadding={2}
                            className="rm-mg inner-tbl"
                          >
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: PF
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1920")}
                                  >
                                    Application Form For Withdrawal/Advance from
                                    G.P.F
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr align="left" valign="top" className="head_line_text">
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table
                            width="100%"
                            border={0}
                            cellSpacing={2}
                            cellPadding={2}
                            className="rm-mg inner-tbl"
                          >
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: UEVRP
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1921")}
                                  >
                                    FORM for Inventory of School for DM
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr align="left" valign="top" className="head_line_text">
                        <td
                          className="head_line_text"
                          style={{ border: "none !important" }}
                        >
                          <table
                            width="100%"
                            border={0}
                            cellSpacing={2}
                            cellPadding={2}
                            className="rm-mg inner-tbl"
                          >
                            <tbody>
                              <tr
                                bgcolor="302ba0"
                                tabIndex={0}
                                style={{ height: 38 }}
                                valign="middle"
                              >
                                <td
                                  colSpan={2}
                                  align="center"
                                  style={{ color: "#fff" }}
                                >
                                  &nbsp;
                                </td>
                                <td colSpan={2} style={{ color: "#fff" }}>
                                  Deparment: WATER SUPPLY
                                </td>
                                <td
                                  colSpan={2}
                                  style={{ color: "#fff" }}
                                  align="center"
                                >
                                  &nbsp;
                                </td>
                              </tr>

                              <tr tabIndex={0} align="left" valign="top">
                                <td align="right">1.</td>
                                <td colSpan={2}>
                                  <a
                                    className="fontSizeClass"
                                    onClick={(e) => fileDownload("1922")}
                                  >
                                    Application Form For Obtaining Private Water
                                    Supply Connection
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Download_Form