import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const KMC_School_Faq  = () => {
  const { t, i18n, ready } = useTranslation();
  const increaseFontSizeBy1px =() => {
      var el = document.getElementsByClassName('fontSizeClass');
      for (let i = 0; i < el.length; i++) {
        var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
        var fontSize = parseFloat(style); 
        el[i].style.fontSize = (fontSize + 1) + 'px';
      }
    };
    const decreaseFontSizeBy1px =() => {
      var el = document.getElementsByClassName('fontSizeClass');
      for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize - 1) + 'px'; 
      }
    };
  return (
    <>
    <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
        <div className="container"> <div className="section-main-heading2"></div></div>             
      </div>       	 		 
    </section>	  
    {/* End Slider */}
   
    <section className="section inner-pad1">
      <div className="container " >
          <div className="row">
          <div className="col-lg-3">
          <div className="site-menu ">           
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Kmc_school_home'> {t("KMC Schools Home")} </Link> </li>
                <li className="nav-item"><a className="nav-link fontSizeClass" target="_blank" href='https://www.kmcgov.in/KMCPortal/jsp/KMCSchoolsList.jsp'> {t("List Of Schools")} </a> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Urgent_Notice'> {t("Urgent Notice")} </Link> </li>
                <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/KMC_School_Faq'> {t("FAQs")} </Link> </li>
                <li className="nav-item"><Link className="nav-link fontSizeClass" target="_blank" to='/Education_Dept'> {t("Contact Details")} </Link> </li>
             </ul>
            </nav>	   
          </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title"><h3 className="fontSizeClass"> {t("Dept. of Education : FAQs")} </h3></div>
                    <div id="accordion">
                    <div className="card">
                      <div className="card-header" id="heading1">
                      <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                      1. {t("What are the key activities of the department")}?
                      </button>
                      </div>
                      <div id="collapse1" className="collapse show" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                        {t("The key activities of the department are")}:
                            a. {t("To extend primary education to poor children under the jurisdiction of KMC")}.
                            b. {t("To spread knowledge amongst children, irrespective of their caste, religion or community")}
                            c. {t("To arrange different programmes related to education")}.
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading2">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                        2. {t("What class can my child join to begin school")}?
                      </button>
                      </div>
                      <div id="collapse2" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                        {t("A child normally joins the pre-primary or class 1 at the age of 5 or 6")}
                        </div>                      
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading3">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                        3. {t("In which language-medium are KMC schools run?")}
                      </button>
                      </div>
                      <div id="collapse3" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                        {t("KMC schools impart education in Bengali, Hindi and Urdu.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading4">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                        4. {t("My child will not be able to avail of the day shift school. Do you run any morning shift or evening shift school?")}
                      </button>
                      </div>
                      <div id="collapse4" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">
                        {t("KMC runs both morning shift and day shift schools. Unfortunately, KMC does not run evening shift schools.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading5">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                        5. {t("What are school timings for morning and evening shift schools?")}
                         </button>
                      </div>
                      <div id="collapse5" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("Morning shift schools run from 7.00 am to 11.00 am while day shift schools run from 11:30 am to 4 pm.")}
                       </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading6">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                        6. {t("I cannot afford books for my children. Can books be made available free of cost?")}                         </button>
                      </div>
                      <div id="collapse6" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("Yes. KMC is committed to spreading education among all sections of society. With this in view, free textbooks are supplied to students in our schools.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading7">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                        7. {t("Is there any mid-day meal arrangement in KMCP School?")}
                         </button>
                      </div>
                      <div id="collapse7" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("Yes, as a part of nutritional support programme under the Government of India Scheme, every student is provided with a mid day meal. The government has allocated 3 kgs of rice per month per student.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading8">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
                        8. {t("Do children undergo health checkups free of cost at KMC schools? Are medicines provided to sick children?")}
                         </button>
                      </div>
                      <div id="collapse8" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("Yes, children undergo medical check ups free of cost. Medicines are also provided at free of cost and we are working towards providing more medicines.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading9">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
                        9. {t("Do KMC schools develop the socio cultural aspects of their students?")}
                         </button>
                      </div>
                      <div id="collapse9" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("Yes, music teachers of our schools take classes regularly. Our teachers train students for participating on special occasions such as 23rd January, 26th January, 15th August and 8th September.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading10">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse10" aria-expanded="true" aria-controls="collapse10">
                        10. {t("Is the Annual sports meet held every year?")}
                         </button>
                      </div>
                      <div id="collapse10" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("Yes, with exceptions under unavoidable circumstances.")}
                       </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading11">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                        11. {t("Is the Admission form available free of cost?")}
                         </button>
                      </div>
                      <div id="collapse11" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                            {t("Yes")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading12">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse12" aria-expanded="true" aria-controls="collapse12">
                        12. {t("Is there any admission fee?")}
                         </button>
                      </div>
                      <div id="collapse12" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                            {t("No")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading13">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse13" aria-expanded="true" aria-controls="collapse13">
                        13. {t("I would like to have my child admitted into your school. What papers and documents would be required?")}
                         </button>
                      </div>
                      <div id="collapse13" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                          {t("You would be required to produce:")}
                           {t("a. An admission Form duly filled")}
                          {t("b. Birth Certificate of child")}
                          {t("c. Residential Certificate")}
                          {t("d. Transfer Certificate is required in case of admission for class II and above.")}
                        {t("Provision for admission is also provided even if any of the above mentioned documents is absent.")}                      </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading14">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse14" aria-expanded="true" aria-controls="collapse14">
                        14. {t("My husband has gone abroad on business. Can I get my child admitted to a KMC school?")}
                         </button>
                      </div>
                      <div id="collapse14" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("Yes, definitely all mothers are welcome to approach KMC schools for their children's education.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading15">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse15" aria-expanded="true" aria-controls="collapse15">
                        15. {t("In which classes are students taught at KMCP schools?")}
                         </button>
                      </div>
                      <div id="collapse15" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("KMC has a majority of primary schools. In these schools students are taught in the following classes:")}
                         {t("a. Preparatory (P.P.) to class-IV")}
                         {t("b. Up to class-V in some schools")}                       </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading16">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse16" aria-expanded="true" aria-controls="collapse16">
                        16. {t("Can my child who belongs to a Bengali family be admitted to a Hindi medium school?")}
                         </button>
                      </div>
                      <div id="collapse16" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("Yes. KMC does not discriminate among children based on their backgrounds. Our goal is to reach out to as many children as possible irrespective of their backgrounds, caste, communities etc.")}
                       </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading17">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse17" aria-expanded="true" aria-controls="collapse17">
                        17. {t("How many classes are there in your school and how many teachers teach in these classes?")}
                         </button>
                      </div>
                      <div id="collapse17" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("Usually, four teachers teach students from classes Preparatory (P.P.) to V. In some schools there are 5 teachers. Also some Non-teaching staffs are there to help.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading18">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse18" aria-expanded="true" aria-controls="collapse18">
                        18. {t("How do you manage in instances where the number of sections/classes is much higher than the number of teachers?")}
                         </button>
                      </div>
                      <div id="collapse18" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("In such situations, combined classes are held. However, we have recruited more teachers to avoid combining classes.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading19">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse19" aria-expanded="true" aria-controls="collapse19">
                        19. {t("How often are examinations held in your school?")}
                         </button>
                      </div>
                      <div id="collapse19" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("Examinations are held three times in an academic year.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading20">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse20" aria-expanded="true" aria-controls="collapse20">
                        20. {t("It is January now. We have just shifted from another city. My child was a student of class-III in her previous school. Will you kindly admit him/her in your school now?")}
                         </button>
                      </div>
                      <div id="collapse20" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("Few classes are left in the current academic year. Kindly approach us after the annual examination is over along with the transfer certificate from previous school.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading21">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse21" aria-expanded="true" aria-controls="collapse21">
                        21. {t("Is there any provision for coaching?")}
                         </button>
                      </div>
                      <div id="collapse21" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("We have no provision for private coaching. But, if required our teachers teach the students during recesses when necessary.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading22">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse22" aria-expanded="true" aria-controls="collapse22">
                        22. {t("If I want to lodge any complain, then whom should I contact?")}
                         </button>
                      </div>
                      <div id="collapse22" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("Written complain may be submitted to,")}
                        {t("Education Department (HQ)., 1,Hogg street /Hogg Building(2nd floor), Kolkata-700087. Ph. No 22861000, Extn: 2492, 2534")}                       </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading23">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse23" aria-expanded="true" aria-controls="collapse23">
                        23. {t("Most of the inhabitants in our area are Hindi-speaking, but there is no Hindi school in our locality. How can we propose for a Hindi-medium school and to whom?")}
                         </button>
                      </div>
                      <div id="collapse23" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("You may prepare a mass proposal for a Hindi medium school at your locality and present the same to KMC authorities for consideration.")}
                       </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading24">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse24" aria-expanded="true" aria-controls="collapse24">
                        24. {t("Do teachers undergo training?")}
                         </button>
                      </div>
                      <div id="collapse24" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("Teachers usually undergo basic training from Government Primary Teachers Training Colleges. However, KMC provides training to teachers once they join the schools.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading25">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse25" aria-expanded="true" aria-controls="collapse25">
                        25. {t("How many primary schools are run by KMC? Can you give a language-wise breakup of these?")}
                         </button>
                      </div>
                      <div id="collapse25" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("There are 263 primary schools under the KMC. (Bengali-154, Hindi - 46 and Urdu – 56, Bengali plus Urdu mixed – 5, Hindi plus Urdu mixed - 2)")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading26">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse26" aria-expanded="true" aria-controls="collapse26">
                        26. {t("My child is 11 years old and is engaged in work during regular hours. Do you have any other arrangements for her education?")}
                         </button>
                      </div>
                      <div id="collapse26" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("Unfortunately, KMCP schools do not have evening classes, but the Shishu Shikhsa Kendras have such arrangements.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading27">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse27" aria-expanded="true" aria-controls="collapse27">
                        27. {t("In which classes are students taught in Shishu Shikhsa Kendras?")}
                         </button>
                      </div>
                      <div id="collapse27" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("At the Shishu Shikhsa Kendras, students from classes I to IV are taught.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading28">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse28" aria-expanded="true" aria-controls="collapse28">
                        28. {t("How many Shishu Shikhsa Kendras are there under the KMC?")}
                         </button>
                      </div>
                      <div id="collapse28" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        102 </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading29">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse29" aria-expanded="true" aria-controls="collapse29">
                        29. {t("Is there any scope to play in the schools?")}
                         </button>
                      </div>
                      <div id="collapse29" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("KMC has taken initiatives such as attaching parks and playgrounds to its schools in order to encourage students to play cricket, football and other extra curricular activities.")}
                     </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading30">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse30" aria-expanded="true" aria-controls="collapse30">
                        30. {t("Does KMC take up any plan or program to increase interest amongst school students?")}
                         </button>
                      </div>
                      <div id="collapse30" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("Besides providing extra curricular activities, KMC also provides its students with the opportunity to participate in activities involving a trip to Science City, the circus, the zoo and other such places.")}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading30">
                        <button className="btn btn-link fontSizeClass  m-2" data-toggle="collapse" data-target="#collapse30" aria-expanded="true" aria-controls="collapse30">
                        31. {t("How many students study in each medium of instruction")}?
                         </button>
                      </div>
                      <div id="collapse30" className="collapse" data-parent="#accordion">
                        <div className="card-body m-3 fontSizeClass">                                                    
                        {t("There are 13338 students under Bengali medium, 5611 students under Hindi medium and 6899 students under Urdu medium, 681 Bengali plus Urdu mixed, and 442 Hindi plus Urdu mixed.")}
                         </div>
                      </div>
                    </div>
                    
              </div> 
            </div>
          </div>
        </div>
          </div>
      </div>
    </section>
    
      </>
  )
}

export default KMC_School_Faq