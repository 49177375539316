import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const List_of_unit_offices  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
    </div>
  </section>
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
      <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2 mb-4">
              <ul className="nav flex-column" id="nav_accordion">
              <li className="nav-item">
                  <button type="button" className="btn" data-toggle="collapse" data-target="#demo">{t("Certificate of Enlistment")} <i class="bi bi-chevron-down"></i> </button>
                    <div id="demo" className="collapse">
                        <a href='https://www.kmcgov.in/KMCPortal/downloads/725_MA_Revised_Enlistment_Trade_Calling.pdf' target="_blank"> {t("Guidline")} </a>
                        <a href='https://www.kmcgov.in/KMCPortal/downloads/TradeLicenceEoDBProcedure.pdf' target="_blank"> {t("Procedure")} </a>               
                    </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Requirements_For_Obtaining_CE'> {t("Requirements for CE")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/List_of_unit_offices'> {t("List of Unit Offices (Licence)")} </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/TradeLicenseInformation.jsp'> {t("View CE Information")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Application Registration(For New CE)")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Application For Change(For Existing CE)")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Application For Closure(For Existing CE)")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Renewal Application Registration(For Existing Defaulter CE)")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'> {t("Online Application Search Status(For New CE)")}</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/LicenseDemand.jsp'> {t("Check Demands Payable")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/LicenseUnpaidDemandSearchPrint.jsp'> {t("Print Unpaid Demand")}</a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Current_Circular_Notes_Guidelines'>  {t("Current Circular Notes & Guidelines")} </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Duplicate_Demand_Issuing_Offices'>  {t("Duplicate Demand Issuing Offices")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('655')}> {t("Fees & Charges")} </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Licence_download_form'> {t("Download Forms")}</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" target="_blank" href='https://www.kmcgov.in/KMCPortal/KMCPortalLicensePaymentAction.do'> {t("Make Online Payment")} </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" target="_blank" href='https://www.kmcgov.in/KMCPortal/jsp/LicenseDuplicateReceipt.jsp'> {t("Reprint e-Receipt cum CE")} </a>
                </li>
                <li className="nav-item">
                <Link className="nav-link fontSizeClass" to='/Licence_Dept'>  {t("Contact Details")} </Link>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('656')}> {t("Online Survey for Industry Sector Key Performance Indicators under Vision, Mission Exercise")} </a>
                </li>
                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('657')}> {t("Policy for use of pet Coke and Furnace Oil as a Fuel in the State of West Bengal")} </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
         <div className="container">
          <div className="row">
            <div className="inner-title2">
              <h3 className="fontSizeClass"> {t("Requirements For Obtaining CE")} </h3>
            </div>
            <div className="col-lg-12 about-head1">
              <div className="mayor-table2 depart min-height-400">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-warning table-bor-2">
                      <th className="bd-wid" scope="col">
                        {t("Sl-No")}
                      </th>
                      <th scope="col"> {("Ward Nos.")} </th>
                      <th scope="col"> {t("Address")} </th>
                      <th scope="col"> {t("Telephone No.")} </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row"> 1 </th>
                      <td>001 to 009 </td>
                      <td>
                        {t("Cossipore Unit, 10, Barrackpore Trunk Road, 2nd Floor, Kol – 2")}
                      </td>
                      <td> 9830939170 </td>
                    </tr>
                    <tr>
                      <th scope="row">2 </th>
                      <td> 010 to 035, 038 &amp;039</td>
                      <td>
                        
                        {t("Hatibagan Unit, 79, Bidhan Sarani, Hatibagan Crossing, 2nd Floor, Kolkata – 6")}
                      </td>
                      <td> 9836451399 </td>
                    </tr>
                    <tr>
                      <th scope="row">3 </th>
                      <td>036, 037 &amp; 040 to 067 </td>
                      <td>
                        
                        {t("Head Quarter, C.M.O. Building, Ground Floor, 5, S.N. Benerjee Road, Kolkata – 13")}
                      </td>
                      <td>
                        
                        2286 1000 (Extn.2404/2859/2702/2582/2274/2585/2779)
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">4 </th>
                      <td> 068 to 100 </td>
                      <td>
                        
                        {t("Gariahat Office, K.M.C. Market, 3rd Floor, 212, Rash Behari Avenue, Kolkata – 19")}
                      </td>
                      <td> 2440 3925 Mob - 7980311140 </td>
                    </tr>
                    <tr>
                      <th scope="row">5 </th>
                      <td>101, 102 &amp; 110 to 114 </td>
                      <td>
                        {t("Jadavpore Office, Baghajatin Market Complex, 3rd Floor, Kolkata – 32")}
                      </td>
                      <td> 8420238261 </td>
                    </tr>
                    <tr>
                      <th scope="row">6 </th>
                      <td>103 to 109 </td>
                      <td>
                        {t("Santoshpur Office, Santoshpur K.M.C. Market Complex, 2nd Floor, Kolkata – 75")}
                      </td>
                      <td> 8420238261 </td>
                    </tr>
                    <tr>
                      <th scope="row">7 </th>
                      <td>115 to 122 127 to 132 </td>
                      <td>
                        
                        {t("S. S. Unit Office, 516, Diamond Harbour Road, 2nd Floor, Kolkata – 34")}
                      </td>
                      <td>9830797325 </td>
                    </tr>
                    <tr>
                      <th scope="row"> 8 </th>
                      <td> 133 to 141 </td>
                      <td>
                        
                        {t("Garden Reach Office, E-3, Circular Garden Reach Road, Kolkata – 24.")}
                      </td>
                      <td> 9836451399 </td>
                    </tr>
                    <tr>
                      <th scope="row"> 9 </th>
                      <td> 123 to 126 &amp; 142 to 144 </td>
                      <td> {t("Joka Office, 4, Diamond Park, Kolkata – 700104.")} </td>
                      <td> 9064511963 </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default List_of_unit_offices