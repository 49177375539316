import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Engineering_Rates_Charges  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
  </div>
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="image" />
    </div>
  </section>
  {/* End Slider */}
  
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="site-menu ">
            <nav className="sidebar card py-2">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Engineering_Home'>                    
                    {t("Engineering (Civil) Home")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass active1" to='/Engineering_Rates_Charges'>
                    
                    {t("Rates & Charges")}
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCRoadDevelopmentDetails.jsp'> {t("KMC Road Development Scheme")} </a>
                </li>

                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Engineering_Manhole_Desilting_Programme'>                   
                    {t("Manhole Desilting Programme of Borough-1 to XVI under Civil Dept")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/Engi_Man_Desil_Prog_civil_Bro_30_6_2024'>                   
                    {t("Manh Desi Prog of Bor-1 to XVI under Civil Dept 30-6-24")}
                  </Link>
                </li>

                <li className="nav-item">
                <a className="nav-link fontSizeClass" onClick={(e)=>fileDownload('284')}> {t("Tender Form Format")} </a> 
                </li>
                <li className="nav-item">
                    <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/ChiefEngContact.jsp'> {t("Contact Details")} </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              <div className="inner-title2">
                <h3 className="fontSizeClass">{t("Schedule of Fees & Charges for the Year 2023 - 2024")} </h3>
              </div>

              <div className="mayor-table">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-warning table-bor-2">
                    <th className='center1' colSpan={3}> {t("(I) House drainage connection fees)")} </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className='bd-wid'> {t("Sl. No")}. </td>
                      <td> {t("Items")} </td>
                      <td> {t("Figures in Rupees")} </td>
                    </tr>
                    
                    <tr>
                      <td> {t("1.")}. </td>
                      <td className='eng-tab-width1'> {t("For supply and laying S.W. pipe on concrete bed (measured from termination point of work done by the owner or occupier to the point of connection including the cost of temp. restoration of road surface) depth of invert upto 2 m. (Excluding shorting)")} </td>
                      <td> 
                        <table>
                          <tr> <td> Rs. 1,188/- per m. for 150 mm. internal diameter </td> </tr>
                          <tr> <td> Rs. 1,980/- per m. for 225 mm. internal diameter </td> </tr>
                          <tr> <td> Rs. 2,806/- per m. for 300 mm. internal diameter </td> </tr>
                        </table> 
                      </td>                 
                    </tr>

                    <tr>
                      <td> {t("2.")}. </td>
                      <td className='eng-tab-width1'> {t("-do- -do- depth of invert over 2 mtr. Upto 3 mtr. add for each additional depth of 0.50 m. or less.")} </td>
                      <td> 
                        <table>
                          <tr> <td> Rs. 396/- per m. for 150 mm. internal diameter </td> </tr>
                          <tr> <td> Rs. 494/- per m. for 225 mm. internal diameter </td> </tr>
                          <tr> <td> Rs. 594/- per m. for 300 mm. internal diameter </td> </tr>
                        </table> 
                      </td>                 
                    </tr>

                    <tr>
                      <td> {t("3.")} </td>
                      <td className='eng-tab-width1'> {t("–do– depth of invert over 3 m. and upto 4 m. add for each additional depth of 0.50 m. or less.")} </td>
                      <td> 
                        <table>
                          <tr> <td> Rs. 449/- per m. for 150 mm. internal diameter </td> </tr>
                          <tr> <td> Rs. 541/- per m. for 225 mm. internal diameter </td> </tr>
                          <tr> <td> Rs. 713/- per m. for 300 mm. internal diameter </td> </tr>
                        </table> 
                      </td>                 
                    </tr>
                    <tr>
                      <td> {t("4.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> For manhole lamphole, pit & brick sewer connection </td> </tr>
                          <tr> <td> (a) For internal dia. of pipes upto 225 mm. </td> </tr>
                          <tr> <td> (b) For internal dia. of pipes above 225 mm. but upto 450 mm. </td> </tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> - </td> </tr>
                          <tr> <td> Rs. 198/- each </td> </tr>
                          <tr> <td> Rs. 494/- each </td> </tr>
                        </table> 
                      </td>                 
                    </tr>
                    <tr>
                      <td> {t("5.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> If the connection crosses tram line extra fee for any depth </td> </tr>
                          <tr> <td> (a) For double line </td> </tr>
                          <tr> <td> (b) For Single line </td> </tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> - </td> </tr>
                          <tr> <td> Rs. 1,122/- (CTC charges to be paid extra) </td> </tr>
                          <tr> <td> Rs. 792/- (CTC charges to be paid extra) </td> </tr>
                        </table> 
                      </td>                 
                    </tr>

                    <tr>
                      <td> {t("6.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> Supply and Laying 150 mm. to 155 mm. dia C.I. Pipe including jointing, caulking, 
                            excavation, timbering, de-watering, earthfilling, ramming, soling, restoration and removal of 
                            spoils, depth of excavation </td> </tr>
                          <tr> <td> (a) Not exceeding 2 m. </td> </tr>
                          <tr> <td> (b) up to 3 m. (0 to 3 M.) </td> </tr>
                         
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> - </td> </tr>
                          <tr> <td> Rs. 3,994/- per m. </td> </tr>
                          <tr> <td> Rs. 4,752/- per m. </td> </tr>
                        </table> 
                      </td>                 
                    </tr>

                    <tr>
                      <td> {t("7.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> In case the S.W. Pipe, cement are not available in the Corporation Stores and 
                            the applicant is permitted by Executive Engineer to supply the same of approved 
                            quality for the work a deduction of the articles will be made as below :- </td> 
                          </tr>
                          <tr> <td> (a) For 150 mm. to 155 mm. dia S.W. pipe </td> </tr>
                          <tr> <td> (b) For 225 mm. to 230 mm. dia S.W. pipe </td> </tr>
                          <tr> <td> (c) For 300 mm. dia S.W. pipe </td> </tr>
                          <tr> <td> (d) For Cement in respective cases</td> </tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> - </td> </tr>
                          <tr> <td> Rs. 360/- per m. </td> </tr>
                          <tr> <td> Rs. 540/- per m. </td> </tr>
                          <tr> <td> Rs. 990/- per m. </td> </tr>
                          <tr> <td> Rs. 360/- per m. </td> </tr>
                          <tr> <td> Rs. 552/- per m. </td> </tr>
                          <tr> <td> Rs. 720/- per m. </td> </tr>
                        </table> 
                      </td>                 
                    </tr>
                    <tr>
                      <td> {t("8.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> C.I.T. leading connection to be made by the plumber. </td> </tr>
                          
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> Rs. 396/- per m. </td> </tr>                         
                        </table> 
                      </td>                 
                    </tr>
                    

                    

                   
                    

                  </tbody>
                </table>

                <table className="table table-bordered">
                  <thead>
                    <tr className="table-warning table-bor-2">
                    <th className='center1' colSpan={3}> {t("(II) Restoration of Roads")} </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className='bd-wid'> Sl. No. </td>
                      <td> Items </td>
                      <td> Figures in Rupees </td>
                    </tr>
                    
                    <tr>
                      <td> {t("1.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> Restoration of water bound macadam road including underbed :- </td> </tr>
                          <tr> <td> a) With brick metals </td> </tr>
                          <tr> <td> (i) depth of trench upto 1.00 m. </td> </tr>
                          <tr> <td> (ii) depth of trench upto 1.50 m. </td> </tr>
                          <tr> <td> b) Stone metal </td> </tr>
                          <tr> <td> (i) depth of trench upto 1.00 m. </td> </tr>
                          <tr> <td> (ii) depth of trench upto 1.50 m. </td> </tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> - </td> </tr>
                          <tr> <td> - </td> </tr>
                          <tr> <td> Rs. 1,083/- per sq.m. plus 25% Supervision charges </td> </tr>
                          <tr> <td> Rs. 1,395/- per sq.m. plus 25% Supervision charges </td> </tr>
                          <tr> <td> - </td> </tr>
                          <tr> <td> Rs. 1,194/- per sq.m. plus 25% Supervision charges </td> </tr>
                          <tr> <td> Rs. 1,604/- per sq.m. plus 25% Supervision charges </td> </tr>
                        </table> 
                      </td>                 
                    </tr>
                    <tr>
                      <td> {t("2.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> Restoration of tar/asphalt macadam road including underbed :- </td> </tr>
                          <tr> <td> a) Restoration of Arterial Roads </td> </tr>
                          <tr> <td>  (i) Using Dense Bituminous Macadam and Bituminous Concrete : </td> </tr>
                          <tr> <td>   (ii) Using Bituminous Macadam and Bituminous Hot-Mix. </td> </tr>
                          <tr> <td> b) For Non Arterial Roads  </td> </tr>
                          <tr> <td> (i) Using Bituminous Macadam and Bituminous Hot-Mix </td> </tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> - </td> </tr>
                          <tr> <td> - </td> </tr>
                          <tr> <td> Rs. 5,982.48 per sq.m. plus 25% Supervision charges </td> </tr>
                          <tr> <td> Rs. 5,852.57 per sq.m. plus 25% Supervision charges </td> </tr>
                          <tr> <td> - </td> </tr>
                          <tr> <td> Rs. 2,608.83/- per sq.m. plus 25% Supervision charges </td> </tr>
                        </table> 
                      </td>                 
                    </tr>

                    <tr>
                      <td> {t("3.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> Restoration of stone-set road including underbed :- </td> </tr>
                          <tr> <td> a) depth of trench upto 1.00 m. </td> </tr>
                          <tr> <td> b) depth of trench upto 1.50 m. </td> </tr>
                         
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> - </td> </tr>
                          <tr> <td> Rs. 2,583/- per sq.m. plus 25% Supervision charges </td> </tr>
                          <tr> <td> Rs. 2,956/- per sq.m. plus 25% Supervision charges </td> </tr>
                        </table> 
                      </td>                 
                    </tr>

                    <tr>
                      <td> {t("4.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> Restoration of I.P.S. pavement including bed :- </td> </tr>
                          <tr> <td> a) depth of trench upto 1.00 m. </td> </tr>
                          <tr> <td> b) depth of trench upto 1.50 m. </td> </tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> - </td> </tr>
                          <tr> <td> Rs. 1,395/- per sq.m. plus 25% Supervision charges </td> </tr>
                          <tr> <td> Rs. 1,970/- per sq.m. plus 25% Supervision charges </td> </tr>
                        </table> 
                      </td>                 
                    </tr>

                    <tr>
                      <td> {t("5.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> Restoration on B.O.E. paved road including underbed :- </td> </tr>
                          <tr> <td> a) depth of trench upto 1.00 m. </td> </tr>
                          <tr> <td> b) depth of trench upto 1.50 m. </td> </tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> - </td> </tr>
                          <tr> <td> Rs. 1,395/- per sq.m. plus 25% Supervision charges </td> </tr> 
                          <tr> <td> Rs. 1,970/- per sq.m. plus 25% Supervision charges </td> </tr>                         
                        </table> 
                      </td>                 
                    </tr>
                    
                    <tr>
                      <td> {t("6.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> Restoration of Stone Slab (Mirzapur Chunar) pavement :- </td> </tr>
                          <tr> <td> a) depth of trench upto 1.00 m. </td> </tr>
                          <tr> <td> b) depth of trench upto 1.50 m. </td> </tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> - </td> </tr>
                          <tr> <td> Rs. 1,066/- per sq.m. plus 25% Supervision charges </td> </tr> 
                          <tr> <td> Rs. 1,567/- per sq.m. plus 25% Supervision charges </td> </tr>                         
                        </table> 
                      </td>                 
                    </tr>
                    <tr>
                      <td> {t("7.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> Restoration of kerb & channel :-- </td> </tr>
                          <tr> <td> a) depth of trench upto 1.00 m. </td> </tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> - </td> </tr>
                          <tr> <td> Rs. 896/- per sq.m. plus 25% Supervision charges</td> </tr>                       
                        </table> 
                      </td>                 
                    </tr>
                    <tr>
                      <td> {t("8.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> Restoration of rubbish footpath or kancha road </td> </tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> Rs. 98/- per sq.m. </td> </tr>                       
                        </table> 
                      </td>                 
                    </tr>
                    <tr>
                      <td> {t("9.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> Restoration of Chequered tiles pavement </td> </tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> Rs. 2,389/- per sq.m.. </td> </tr>                       
                        </table> 
                      </td>                 
                    </tr>
                    <tr>
                      <td> {t("10.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> Restoration of paver block footpath </td> </tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> Rs. 2,716/- per sq.m. </td> </tr>                       
                        </table> 
                      </td>                 
                    </tr>
                    <tr>
                      <td> {t("11.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> Notification charges/service charge </td> </tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> Rs. 374/- </td> </tr>                       
                        </table> 
                      </td>                 
                    </tr>
                    <tr>
                      <td> {t("12.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> a) Restoration of mastic asphalt </td> </tr>
                          <tr> <td> b) Surfacing Cable Duct Manholes where necessary </td> </tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> Rs. 1,194/- per sq.m. + 25% supervision charge. 
                            (This is in addition to usual road restoration charges) </td> </tr>
                          <tr> <td> Rs. 1,493/- per sq.m. </td> </tr>                        
                        </table> 
                      </td>                 
                    </tr>
                    <tr>
                      <td> {t("13.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> a) Restoration charges of footpath by laying of cobble stones including 
                            preparation of under bed as per specification of existing footpath & Median </td> </tr>
                          <tr> <td> b) Restoration charges of footpath by laying of kuddappa stones including 
                            preparation of under bed as per specification of existing footpath </td> </tr>
                          <tr> <td> c) Restoration charges of footpath by laying of kerb & channel including preparation 
                            of under bed as per specification of existing footpath & Median</td> </tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> Rs. 10,296/- per sq.m. </td> </tr>
                          <tr> <td> Rs. 9,828/- per sq.m. </td> </tr>
                          <tr> <td> Rs. 6,386/- per sq.m. </td> </tr>                        
                        </table> 
                      </td>                 
                    </tr>
                    <tr>
                      <td> {t("14.")} </td>       
                      <td> 
                        <table>
                          <tr> <td> (a) Rest. of cement concrete road (75 mm thick) </td> </tr>
                          <tr> <td> (i) depth of trench up to 1.00 m. </td> </tr>
                          <tr> <td> (ii) depth of trench up to 1.50 m. </td> </tr>
                          <tr><td>(b) Rest. of cement concrete road (100 mm thick) </td></tr>
                          <tr><td> (i) depth of trench up to 1.00 m. </td></tr>
                          <tr><td>(ii) depth of trench up to 1.50 m. </td></tr>
                        </table> 
                      </td>
                      <td> 
                        <table>
                          <tr> <td> - </td> </tr>
                          <tr> <td> 1,477/- per sq.m. plus 25% Supervision charges </td> </tr>
                          <tr> <td> 1,757/- per sq.m. plus 25% Supervision charges </td> </tr>
                          <tr> <td> - </td> </tr>
                          <tr><td> 1,619/- per sq.m. plus 25% Supervision charges </td></tr>
                          <tr><td> 1,899/- per sq.m. plus 25% Supervision charges </td></tr>                         
                        </table> 
                      </td>                 
                    </tr>
                    <tr><td colSpan={2}>Note : Width of the road to be restored should be taken as 1.5 meter for IPS & paver 
                    block, 2.0 meter for mastic asphalt/asphalt road.</td></tr>
                   </tbody>
                </table>

                <table className="table table-bordered">
                  <thead>
                    <tr className="table-warning table-bor-2">
                    <th className='center1' colSpan={9}> {t("III) Basic Scale of House Drainage Inspection Fee")} </th>
                    </tr>
                  </thead>

                  <tbody>
                  <tr> <td className='center1' colSpan={9}> {t("1. House Drainage Inspection fees [Total Covered area in all floors (in sq.m.)]")} </td></tr>
                    <tr> <td className='center1' colSpan={9}> Table - 1 </td> </tr>
                    <tr>
                      <td className='bd-wid'> <strong> Sl. No. </strong> </td>
                      <td className='bd-wid'> <strong> No. of Storey</strong>  </td>
                      <td className='bd-wid'> <strong> Upto 50</strong> </td>
                      <td className='bd-wid'> <strong> Above 50 upto 100 </strong> </td>
                      <td className='bd-wid'> <strong> Above 100 upto 200 </strong> </td>
                      <td className='bd-wid'> <strong> Above 200 upto 300 </strong> </td>
                      <td className='bd-wid'> <strong> Above 300 upto 400 </strong> </td>
                      <td className='bd-wid'> <strong> Above 400 upto 500 </strong> </td>
                      <td className='bd-wid'> <strong> Above 500 for every 100 sq.m. to be 
                        added with column VI </strong> </td>
                    </tr>
                    <tr>
                    <td colSpan={2}> <strong>  </strong> </td>
                     <td className='bd-wid'> <strong> I </strong> </td>
                     <td className='bd-wid'> <strong> II </strong> </td>
                     <td className='bd-wid'> <strong> III </strong> </td>
                     <td className='bd-wid'> <strong> IV </strong> </td>
                     <td className='bd-wid'> <strong> V </strong> </td>
                     <td className='bd-wid'> <strong> VI </strong> </td>
                     <td className='bd-wid'> <strong> VII </strong> </td>
                    </tr> 
                    <tr>
                      <td className='bd-wid'> {t("1.")} </td>       
                      <td>I or II </td>
                      <td>799</td>
                      <td>1,090 </td>
                      <td>1,452 </td>
                      <td>2,178</td>
                      <td>5,808 </td>
                      <td>5,808 </td>
                      <td>726 </td>                                       
                    </tr>
                    <tr>
                      <td className='bd-wid'> {t("2.")} </td>       
                      <td> G + II </td>
                      <td> 871 </td>
                      <td> 1,162 </td>
                      <td> 1,742 </td>
                      <td> 2,904 </td>
                      <td> 7,814 </td>
                      <td> 10,890 </td>
                      <td> 871 </td>                                       
                    </tr>
                    <tr>
                      <td className='bd-wid'> {t("3.")} </td>       
                      <td> G + III </td>
                      <td> 1,452 </td>
                      <td> 2,178 </td>
                      <td> 3,630 </td>
                      <td> 9,438 </td>
                      <td> 10,890 </td>
                      <td> 21,780 </td>
                      <td> 1,162 </td>                                       
                    </tr>
                    <tr>
                      <td className='bd-wid'> {t("4.")} </td>       
                      <td> G + IV </td>
                      <td> 1,742 </td>
                      <td> 2,614 </td>
                      <td> 4,066 </td>
                      <td> 11,616 </td>
                      <td> 31,944 </td>
                      <td> 47,916 </td>
                      <td> 1,234 </td>                                       
                    </tr>
                    <tr>
                      <td className='bd-wid'> {t("5.")} </td>       
                      <td> G + V & above </td>
                      <td> 2,178 </td>
                      <td> 3,630 </td>
                      <td> 4,356 </td>
                      <td> 12,342 </td>
                      <td> 31,944 </td>
                      <td> 50,820 </td>
                      <td> 1,307 </td>                                       
                    </tr>
                    <tr>
                      <td colSpan={9}> <strong>Example</strong> </td>                                            
                    </tr>
                    <tr>
                      <td colSpan={9}> Considering a four storied residential building in Kasba locality to be constructed
                       with 4 block buildings in one premises. </td>                                            
                    </tr>
                    <tr>
                      <td colSpan={4}>Here land area</td>
                      <td className='center1'>=</td>
                      <td colSpan={4}>2409.97 sq.m. &</td>
                    </tr>
                    <tr>
                      <td colSpan={4}> Total floor area of all block Buildings </td>
                      <td className='center1'>=</td>
                      <td colSpan={4}> 4653.55 m2 </td>
                    </tr>

                    <tr>
                      <td colSpan={9}><strong> Inspection Fee </strong> </td>                                            
                    </tr>
                    <tr>
                      <td colSpan={4}> For covered area Up to 500 sq.m. </td>
                      <td className='center1'>=</td>
                      <td colSpan={4}> 21,780/- (as per Sl. No. 3 Col. VI Table 1) </td>
                    </tr>
                    <tr>
                      <td colSpan={4}> Area above 500 sq.m </td>
                      <td className='center1'>=</td>
                      <td colSpan={4}> 48,264/- [(4653.55 - 500) × 1162] ÷ 100 </td>
                    </tr>
                    <tr>
                      <td colSpan={4}> Total </td>
                      <td className='center1'>=</td>
                      <td colSpan={4}> 70,044 </td>
                    </tr>
                    
                   </tbody>
                </table>

                <table className="table table-bordered">
                  <tbody>
                    <tr> <td className='center1' colSpan={9}> Table - 2 </td> </tr>
                    <tr>
                      <td className='bd-wid'> <strong> Category </strong> </td>
                      <td className='bd-wid'> <strong> Use Group </strong>  </td>
                      <td className='eng-tab-width1'> <strong> Types of Use </strong> </td>
                      <td className='bd-wid'> <strong> Multiplying factor on scale of fees as per Table 1 </strong> </td>                     
                    </tr>
                    <tr>
                    <td className='bd-wid'>  A.  </td>
                     <td> Residential Bldg. </td>
                     <td> All Types </td>
                     <td> 1 time </td>             
                    </tr> 
                    <tr>
                    <td className='bd-wid'> B. </td>
                     <td className='bd-wid'> Educational Bldg. </td>
                     <td> i) Govt./ Govt. Aided School, College, Day Care Centre,  Libraries etc.
                      <hr></hr> ii) Not Govt./ Non Aided School, College etc. </td>
                     <td> 1 time <hr></hr> 1.2 time </td>             
                    </tr>
                    <tr>
                    <td className='bd-wid'> C.  </td>
                     <td> Institutional Bldg. </td>
                     <td> i) Govt. / Govt. Aided Hospital, Clinics, Dispensaries, Sanatorium, Custodial Institution, 
                      Mental Hospitals, Nursing Home & Pathological Lab etc. <hr></hr> ii) Non Govt./ Non Aided -do- </td>
                     <td> 1.2 times <hr></hr> 1.5 times </td>             
                    </tr>
                    <tr>
                    <td className='bd-wid'> D.  </td>
                     <td> Assembly Bldg. </td>
                     <td> Theatre Hall, Cinema Hall, City Hall, Drive in Theatres, Exhibition Hall, Museum, 
                      Skating Rink, Gymnasium, Restaurant, Eating House, Hotel, Dormitory 
                      (No. of beds more than 100), Dance Hall, Club-Room, Gymkhana, Passenger 
                      Terminal, Stadium, Recreation Pier, Yoga Centre, Pathological Lab. etc. 
                      <hr></hr> ii) Place of Worship. Govt. aided Gymnasium & Yoga Centre etc.</td>
                     <td> 1.6 times </td>             
                    </tr>

                    <tr>
                    <td className='bd-wid'> E.  </td>
                     <td> Business Bldg. </td>
                     <td> i) Office, Bank, Professional Establishment, Court-Houses, Telephone Room, Computer Office etc. 
                      <hr></hr> ii) Information Technology Centre, Call Centre </td>
                     <td> 1.8 times  <hr></hr> 1.2 times</td>             
                    </tr>
                    <tr>
                    <td className='bd-wid'> F.  </td>
                     <td> Mercantile Bldg. </td>
                     <td> Shops, Markets, Stores, Service Facilities, Transport Establishment etc. </td>
                     <td> 2 times </td>             
                    </tr>
                    <tr>
                    <td className='bd-wid'> G.  </td>
                     <td> Industrial Bldg. </td>
                     <td> Fabrication / Assembly Plants, Laboratories, Power Plants, Smoke Houses, Refinerie, 
                      Gas Plant, Mills, Dairy, Factory, Workshop, Motor Garage, Printing Press etc. </td>
                     <td> 1.5 times </td>             
                    </tr>

                    <tr>
                    <td className='bd-wid'> H.  </td>
                     <td> Storage Bldg. </td>
                     <td> Storage House, Ware House, Cold Storage, Freight Depot, Transit Dept, Storehouse, 
                      Public Garage, Truck Terminals, Stable, Barn etc. </td>
                     <td> 2 times </td>             
                    </tr>

                    <tr>
                    <td className='bd-wid'> I.  </td>
                     <td> Hazardous Bldg. </td>
                     <td> Storage of Highly Combustible and Explosive material, Chemical, 
                      Acid, Toxic material, Flyash, Dust etc. </td>
                     <td> 2 times </td>             
                    </tr>

                    <tr>
                     <td colSpan={3}> Note : Calculation is to be done by combining & comparing Table 1 & 2 </td>                
                    </tr>
                     
                    
                   </tbody>
                </table>

                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td className='bd-wid'> <strong> Sl. No. </strong> </td>
                      <td className='eng-tab-width1'> <strong> Items </strong>  </td>
                      <td className=''> <strong> Figures in Rupees </strong> </td>                    
                    </tr>
                    
                    <tr>
                    <td className='bd-wid'> 2.  </td>
                     <td> House drain flushing charges (Main line) </td>
                     <td> 330/- </td>           
                    </tr>
                    <tr>
                    <td className='bd-wid'> 3.  </td>
                     <td> House drain chockage removal fees </td>
                     <td> 300/- </td>           
                    </tr>
                    <tr>
                    <td className='bd-wid'> 4. (a)  </td>
                     <td> House drain chockage removal fees </td>
                     <td> 1,500/- </td>           
                    </tr>
                    <tr>
                    <td className='bd-wid'> 4. (b)  </td>
                     <td> Carriage way Inspection fees (business) </td>
                     <td> 5,000/- </td>           
                    </tr>
                    <tr>
                    <td className='bd-wid'> 5 </td>
                     <td> Culvert plan inspection fee </td>
                     <td> 660/- </td>           
                    </tr>
                    <tr>
                    <td className='bd-wid'> 6 </td>
                     <td> Certified copy of plans - House Drainage etc. </td>
                     <td> 1,596/- </td>           
                    </tr>

                    <tr>
                    <td className='bd-wid'> 7 </td>
                     <td> Searching fee for issue of certified copy of H.D. Plan upto ten years old (extra over fee shown at Sl. 6) </td>
                     <td> 990/- </td>           
                    </tr> 
                    <tr>
                    <td className='bd-wid'> 8 </td>
                     <td> Searching fee for issue of certified copy of H.D. Plan above ten years 
                      old (extra over fee shown at Sl. 6) </td>
                     <td> 1,650/- </td>           
                    </tr>
                    <tr>
                    <td className='bd-wid'> 9 </td>
                     <td> Renewal / Issue of plumbing Licence (3 years) </td>
                     <td> 1,990/- </td>           
                    </tr> 
                    <tr>
                    <td className='bd-wid'> 10 </td>
                     <td> Renewal/Issue of Licence for sinking Tube wels (3 years) </td>
                     <td> 1072/- </td>           
                    </tr>
                    <tr>
                    <td className='bd-wid'> 11 </td>
                     <td> Duplicate of copy of plumbing licence </td>
                     <td> 400/- </td>           
                    </tr>

                    <tr><td className='bd-wid' rowSpan={7}> 12 </td> </tr>
                    <tr>
                      <td> Delay fine for non-renewal of licence after expiry </td>
                      <td>  </td> 
                    </tr>
                    <tr>
                      <td> a) Within 7 Days </td>
                      <td> No fine </td> 
                    </tr>
                    <tr>
                      <td> b) Beyond 7 days upto 1 month </td>
                      <td> 150/- </td> 
                    </tr>
                    <tr>
                      <td> c) Beyond 1 month upto 6 months </td>
                      <td> 500/- </td> 
                    </tr>
                    <tr>
                      <td> d) Beyond 6 months upto 1 year </td>
                      <td> 1,000/- </td> 
                    </tr>
                    <tr>
                      <td> e) Beyond 1 year upto 2 years </td>
                      <td> 2,000/- </td> 
                    </tr>

                    <tr><td className='bd-wid' rowSpan={7}> 13 </td> </tr>
                    <tr>
                      <td> Renewal of lapsed plumbing licence :- </td>
                      <td>  </td> 
                    </tr>
                    <tr>
                      <td> a) Beyond 5 years from the issue date </td>
                      <td> 8,000/- </td> 
                    </tr>
                    <tr>
                      <td> b) For every 1 year in excess of 5 years </td>
                      <td> 8,000/-  + Rs. 3,000/- per year </td> 
                    </tr>

                    <tr>
                      <td>14</td>
                      <td> Road Opening Fees </td>
                      <td> 356/- </td> 
                    </tr>
                    <tr>
                      <td>14</td>
                      <td> Plan Inspection Fees (Per Plan) </td>
                      <td> 30/- </td> 
                    </tr>
                                
                    
                   </tbody>
                </table>

                <table className="table table-bordered">
                  <tbody>
                  <tr> <td colSpan={3}> IV. Charges for supplying slit to private parties for reclamation of land </td> </tr> 
                   
                    <tr>
                      <td className='bd-wid'> <strong> Sl. No. </strong> </td>
                      <td className='bd-wid'> <strong> Items </strong>  </td>
                      <td className='eng-tab-width1'> <strong> Rate in Rs. </strong> </td>                    
                    </tr>                   
                    <tr>
                    <td className='bd-wid'> (a)  </td>
                     <td> Per lorry load of slit </td>
                     <td> 330/- </td>           
                    </tr>
                    <tr>
                      <td colSpan={3}> <strong> Terms & Conditions :- </strong> </td>
                    </tr>
                    <tr>                   
                     <td colSpan={3}>(a) Lorry, loading, unloading clearing the site etc. are to be arranged by the party at his cost. </td>       
                    </tr>
                    <tr>                   
                     <td colSpan={3}> (b) Party will arrange dressing of the dumped slit at his own cost. </td>       
                    </tr>
                    <tr>                   
                     <td colSpan={3}>(c) Party will arrange for spreading disinfectant. </td>       
                    </tr>
                    <tr>                   
                     <td colSpan={3}> (d) Party will submit a no-objection certificate from the Health Officer of the area concerned. </td>       
                    </tr>
                    
                   </tbody>
                </table>

                <table className="table table-bordered">
                  <tbody>
                  <tr> <td colSpan={3}> V. Fees for Observation on Drainage </td> </tr> 
                                     
                  <tr>
                    <td> 1.  </td>
                     <td> Fees for Observation on Drainage </td>
                     <td> 660/- </td>           
                  </tr>
                  <tr>
                    <td> For Ward 7 to 100  </td>
                     <td> Rate = (A/10+30) Rupees per sq.m. of covered area subject to maximum of 
                     Rs. 100/- per sq.m. of covered area. </td>
                    <td rowSpan={2}>Where A is the total proposed Covered Area of the building or total land area whichever is higher.
Total payable amount of Drainage Development charge is (Rate X Total Covered Area).</td>                             
                  </tr>

                  <tr>
                    <td> For Ward 1 to 6 & 101 to 141  </td>
                     <td> Rate = (A/10+50) Rupees per sq.m. of covered area subject to maximum of 
                     Rs. 150/- per sq.m. of covered area. </td>                               
                  </tr>
                    
                    
                   </tbody>
                </table>

                <table className="table table-bordered">
                  <tbody>
                  <tr> <td> <strong> Note : </strong> </td> </tr>                                      
                  <tr><td> a) No Drainage Development fee shall be charged for the cases where building plan had 
                    been sanctioned before 9/7/1999. </td>   
                  </tr>                 
                  <tr>
                    <td> b) There shall be no Drainage Development Fee of the cases having sanctioned plan in 
                    respect of regulation of any building under Section 400(1) and 416 of the KMC Act., 
                    1980 and dealing under Rule 25(2)(a) and 25(2)(b) of Building Rules, 1990 and Rule 26 of
                     Building Rules 2009 and issuance of Occupancy Certificate and doing Renovation 
                     work (If no space is generated) if building plan was sanctioned before 9.7.1999. 
                    </td>  
                  </tr>
                  <tr> 
                    <td>  c) No drainage development fee will be charged in cases of renovation 
                    where no space is generated even if the sanction is accorded after 9.7.1999. </td> 
                  </tr>

                  <tr>
                    <td> of revalidation of building sanction plan full Drainage development fee shall be d) 
                      In cases charged at the current schedule rate. If paid earlier said amount will be 
                      deducted from current fees. No drainage development fee, however, is to be 
                      charged for the plan which expires before completing of five years from the date of 
                      sanction due to non commencement within two years.  </td>                                                
                  </tr>
                    
                    
                   </tbody>
                </table>

                <table className="table table-bordered">
                  <tbody>
                  <tr> 
                    <td> <strong> 3 </strong> </td> 
                    <td>  Sewerage and drainage service charges :-  </td> 
                    <td> <strong> Figures in Rupees </strong> </td> 
                  </tr>
                  <tr> 
                    <td>  (a)  </td> 
                    <td colSpan={2}>  25% of the amount of annual water charges for the consumers 
                      enjoying ICI Ferrule connections  </td> 
                    <td> - </td> 
                  </tr> 
                  <tr> 
                    <td rowSpan={5}> (b)  </td> 
                    <td>  Trade Effluent (Other than ICI ferrule connections) 
                      discharging into KMC sewerage and drainage  </td> 
                      <td> -  </td>                     
                  </tr>
                 
                  <tr> 
                    <td> i) Upto 10,000 Lt./day discharge </td> 
                    <td colSpan={2}> 10,670/-   </td> 
                    
                  </tr>
                  <tr> 
                    <td> ii) above 10,000 Lt. but upto 20,000 Lt./day </td> 
                    <td colSpan={2}> 19,965/-   </td> 
                    
                  </tr>
                  <tr> 
                    <td> iii) above 20,000 Lt. but upto 50,000 Lt./day </td> 
                    <td colSpan={2}> 33,275/-   </td> 
                    
                  </tr> 
                  <tr> 
                    <td> iv) above 50,000 Lt./day </td> 
                    <td colSpan={2}> 660/- for per addl. 1000 Ltrs.  </td>                   
                  </tr>

                  <tr> 
                    <td rowSpan={3}> (C)  </td> 
                    <td >Waste Water charges for using filtered / unfiltered water for 
                    construction purpose  during the construction period.  </td>
                    <td colSpan={2}> - </td>   
                    </tr>
                  <tr>                     
                    <td> i) Covered space from bottom upto 1st floor level in case of Masonry Building  </td> 
                      <td colSpan={2}> 8/- per sq.m. </td>                     
                  </tr> 
                  <tr> 
                    <td>ii) For every successive upper floor </td> 
                    <td colSpan={2}> 8/- per sq.m.  </td>                                      
                  </tr>                                          
                                     
                   </tbody>
                </table>

                <table className="table table-bordered">
                  <tbody>
                  <tr> <td colSpan={3}> <strong> VI. Charges for Micro Tunnelling </strong> </td> </tr>
                  <tr> 
                    <th> Sl. No.</th> 
                    <th> Items </th> 
                    <th> Rate in Rs. </th> 
                  </tr> 
                  <tr> 
                    <td> 1. </td> 
                    <td> Inspection charge for granting approval for the alignment and 
                      depth of laying of the cable. </td> 
                    <td> 390/- per mtr. </td> 
                  </tr>
                  <tr> 
                    <td> 3.  </td> 
                    <td> Cost of restoration of damaged footpath or road caused by open excavation. </td> 
                    <td> As per existing rates plus supervision charges @ 25% of the estimated cost </td> 
                  </tr>  
                  </tbody>
                </table>

                <table className="table table-bordered">
                  <tbody>
                  <tr> <td colSpan={3}> <strong> VII. Temporary Occupation inside Parks & Squares </strong> </td> </tr>
                  <tr> 
                    <th> <strong> Sl. No. </strong> </th> 
                    <th> <strong>  Items </strong> </th> 
                    <th colSpan={2}> <strong>  Rate in Rs. </strong> </th> 
                  </tr>
                  <tr> 
                    <td rowSpan={4}> 1. </td> 
                    <td colSpan={3}> For social festival & religious occasion / function etc. </td> 
                  </tr> 
                  <tr>
                    <td> a) </td>
                    <td>Notification Charges</td>
                    <td colSpan={2}>330/-</td> 
                  </tr>
                  <tr>
                    <td> b) </td>
                    <td> Restoration charges </td>
                    <td colSpan={2}> 10,000/- </td> 
                  </tr>
                  <tr>
                    <td> c) </td>
                    <td> For Installation of decorative gate </td>
                    <td colSpan={2}> 1,100/- </td> 
                  </tr>

                  <tr> 
                    <td rowSpan={4}> 2. </td> 
                    <td colSpan={3}> Ground rent for commercial / festival / carnivals and 
                    other social function etc. for temp stall. </td> 
                  </tr> 
                  <tr>
                    <td> a) </td>
                    <td> For 1 to 3 days </td>
                    <td colSpan={2}> 165/- per sq.m. </td> 
                  </tr>
                  <tr>
                    <td> b) </td>
                    <td> Above 3 days to 7 days </td>
                    <td colSpan={2}> 193/- per sq.m. </td> 
                  </tr>
                  <tr>
                    <td> c) </td>
                    <td> Above 7 days to 10 days </td>
                    <td colSpan={2}> 220/- per sq.m. </td> 
                  </tr>
                  <tr>
                    <td> d) </td>
                    <td> Above 10 days to 15 days </td>
                    <td colSpan={2}> 275/- per sq.m. </td> 
                  </tr>
                  <tr>
                    <td> e) </td>
                    <td> Above 15 days to 20 days </td>
                    <td colSpan={2}> 330/- per sq.m. </td> 
                  </tr>
                  <tr>
                    <td> f) </td>
                    <td> Above 20 days to 25 days </td>
                    <td colSpan={2}> 385/- per sq.m. </td> 
                  </tr>
                  <tr>
                    <td> g) </td>
                    <td> Above 25 days to onwards </td>
                    <td colSpan={2}> 440/- per sq.m. </td> 
                  </tr>
                  <tr>
                    <td colSpan={4}> Occupation charge for installation of Giant wheel, Dolna, Merry-go-round, 
                    Rassim, Jhula, Columbus Ship / Boat, Mini Toy Train etc. </td>        
                  </tr>                
                  <tr> 
                    <td rowSpan={6}> 3. </td> 
                    <td cla> a) </td>
                    <td> Above 3 days to 7 days </td> 
                    <td colSpan={2}> 5,500/- per sq.m. </td> 
                  </tr>
                  <tr>
                    <td> b) </td>
                    <td> Above 7 days to 10 days </td>
                    <td colSpan={2}> 8,250/- per sq.m. </td> 
                  </tr>
                  <tr>
                    <td> c) </td>
                    <td> Above 10 days to 15 days </td>
                    <td colSpan={2}> 11,000/- per sq.m. </td> 
                  </tr>
                  <tr>
                    <td> d) </td>
                    <td> Above 15 days to 20 days </td>
                    <td colSpan={2}> 16,500/- per sq.m. </td> 
                  </tr>
                  <tr>
                    <td> e) </td>
                    <td> Above 20 days to 25 days </td>
                    <td colSpan={2}> 22,000/- per sq.m. </td> 
                  </tr>
                  <tr>
                    <td> f) </td>
                    <td> Above 25 days to onwards </td>
                    <td colSpan={2}> 27,500/- per sq.m. </td> 
                  </tr>
                  <tr>
                    <td colSpan={4}> For installation of wooden Dolna (Wheel), 
                    Merry-go-round etc. operated manually </td>                  
                  </tr>
                  <tr> 
                    <td rowSpan={7}> 4. </td> 
                    <td> a) </td>
                    <td> For 1 to 3 days </td> 
                    <td colSpan={2}> 550/- for each item </td> 
                  </tr>
                  <tr>
                    <td> b) </td>
                    <td> Above 3 days to 7 days </td>
                    <td colSpan={2}> 825/- for each item </td> 
                  </tr>  
                  <tr>
                    <td> c) </td>
                    <td> Above 7 days to 10 days </td>
                    <td colSpan={2}> 1,100/- for each item </td> 
                  </tr>
                  <tr>
                    <td> d) </td>
                    <td> Above 10 days to 15 days</td>
                    <td colSpan={2}>1,320/- for each item  </td> 
                  </tr>
                  <tr>
                    <td> e) </td>
                    <td> Above 15 days to 20 days </td>
                    <td colSpan={2}> 1,650/- for each item  </td> 
                  </tr> 
                  <tr>
                    <td> f) </td>
                    <td> Above 20 days to 25 days </td>
                    <td colSpan={2}> 1,925/- for each item  </td> 
                  </tr> 
                  <tr>
                    <td> g) </td>
                    <td> Above 25 days to onwards </td>
                    <td colSpan={2}> 2,200/- for each item  </td> 
                  </tr>
                  <tr>
                    <td colSpan={4}> Besides above, Paddle Boat etc. in the Swimming Pool 
                    inside the Parks & Squares. </td>                  
                  </tr>
                  <tr> 
                    <td rowSpan={7}> 5. </td> 
                    <td> a) </td>
                    <td> For 1 to 3 days </td> 
                    <td colSpan={2}> 2,200/- for each item </td> 
                  </tr>
                  <tr>
                    <td> b) </td>
                    <td> Above 3 days to 7 days </td>
                    <td colSpan={2}> 2,750/- for each item  </td> 
                  </tr>
                  <tr>
                    <td> c) </td>
                    <td> Above 7 days to 10 days </td>
                    <td colSpan={2}> 3,300/- for each item  </td> 
                  </tr>
                  <tr>
                    <td> d) </td>
                    <td> Above 10 days to 15 days </td>
                    <td colSpan={2}> 3,850/- for each item  </td> 
                  </tr>
                  <tr>
                    <td> e) </td>
                    <td> Above 15 days to 20 days </td>
                    <td colSpan={2}> 4,400/- for each item  </td> 
                  </tr>
                  <tr>
                    <td> f) </td>
                    <td> Above 20 days to 25 days </td>
                    <td colSpan={2}> 4,950/- for each item  </td> 
                  </tr>
                  <tr>
                    <td> g) </td>
                    <td> Above 25 days to onwards </td>
                    <td colSpan={2}> 5,500/- for each item  </td> 
                  </tr>
                  <tr> 
                    <td rowSpan={5}> 6. </td> 
                    <td colSpan={4}>Annual Licence fees by diff. agencies other than KMC for beatification & maintenance of 
                    Parks & Squares, Boulevard Island etc. (To be renewed every year according to their performance of 
                    beautification & maintenance and also satisfaction of the Parks & Squares Deptt.) if prayed for :-  </td> 
                  </tr>
                  <tr>
                    <td> a) </td>
                    <td> Boulevard, Island etc. upto 100 sq.m. </td>
                    <td colSpan={2}> 1,100/- per annum  </td> 
                  </tr>
                  <tr>
                    <td> b) </td>
                    <td> Small Park </td>
                    <td colSpan={2}> 2,200/- per annum  </td> 
                  </tr>
                  <tr>
                    <td> c) </td>
                    <td> Medium Park </td>
                    <td colSpan={2}> 2,750/- per annum  </td> 
                  </tr>
                  <tr>
                    <td> d) </td>
                    <td> Big Park </td>
                    <td colSpan={2}> 3,300/- per annum  </td> 
                  </tr>
                  <tr>                   
                    <td colSpan={4}> Annual Licence fee by diff. agencies other than KMC for beautification & ma
                      enance of road side garden. (To be renewed every according to their performance of beautification 
                      and maintenance.) :- </td>     
                  </tr>
                  <tr> 
                    <td rowSpan={7}> 7. </td> 
                    <td> a) </td>
                    <td> Roadside Garden upto 50 sq.m. </td> 
                    <td colSpan={2}> 550/- per annum </td> 
                  </tr>
                  <tr>
                    <td> b) </td>
                    <td> Roadside Garden above 50 sq.m. upto 100 sq.m. </td>
                    <td colSpan={2}> 1,100/- per annum  </td> 
                  </tr>
                  <tr>
                    <td> c) </td>
                    <td> Roadside Garden above 100 sq.m. and onwards </td>
                    <td colSpan={2}> 2,200/- per annum </td> 
                  </tr>
                  
                  </tbody>
                </table>

              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Engineering_Rates_Charges